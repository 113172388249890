import client from '@repositories/client.js'

const BASE_URL = '/api/student/schedules'

export default new class {

  async fetchMeetingDates() {
		const data = await client.get(`${BASE_URL}/aggregate_meeting_dates_data`)
		return data.dates
  }

}
