<template>
  <horizon-modal id="unenroll-student-modal" modal-title="Unenroll student(s)">
    <template v-slot:content>
      <div class="form">
        <div class="form-row">
          <div class="col-md-12 col-xs-12 form-group student-form-group div-1">
            <!-- <select id="student-schedule"
              v-model="selectedScheduleId"
              :class="['student-form-input', { 'highlighter-red': $v.scheduleId.$error }]">
              <option value="none" v-if="scheduleOptions.length && !selectedScheduleId" selected>
                Select Schedule
              </option>
              <option value="none" v-if="!scheduleOptions.length" selected>
                No Schedule Available
              </option>
              <option
                v-for="schedule in scheduleOptions"
                :key="schedule.id"
                :value="schedule.id">
                {{ schedule.name }}
              </option>
            </select>
            <label>Course Schedule</label> -->
            <label>Course Schedule : {{ selectedSchedule.name }}</label>
          </div>
          <div class="col-md-12 col-xs-12 form-group student-form-group div-1" style="overflow-x:auto;">
            <div class="card">
              <table class="table">
              <thead class="bg-primary">
              <tr>
                <th scope="col" class="text-left">
                  Student ID
                </th>
                <th scope="col" class="text-left">
                  Last Name
                </th>
                <th scope="col" class="text-left">
                  First Name
                </th>
                 <th scope="col" class="text-left">
                  Current Enrollments
                </th>
                <th class="col" style="width: 2px">
                </th>
              </tr>
            </thead>
              <tbody>
                <tr
                  v-for="(student, idx) in students"
                  :key="idx"
                  :data-enrollment-id="student.id"
                  :class="rowParityClass(idx)">
                  <td class="fw-100 student-last-name text-left td-lastname">
                    {{ student.user.studentIdNumber }}
                  </td>
                  <td class="fw-100 student-last-name text-left td-lastname">
                    {{ student.user.lastName }}
                  </td>
                  <td class="fw-100 student-first-name text-left td-firstname">
                    {{ student.user.firstName }}
                  </td>
                   <td class="fw-100 student-first-name text-left td-firstname">
                    {{ courseNames(student) }}
                  </td>
                  <td>
                    <button class=" btn btn-outline-danger mx-1" @click="removeStudent(student.id)">
                      <i class="fa fa-trash"/>
                    </button>
                  </td>
                </tr>
              </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-end">
          <button class="btn btn-primary student-modal-button" @click="submit" :disabled="isDisabled">
            Unenroll Student(s)
          </button>
        </div>
      </div>
    </template>
  </horizon-modal>
</template>

<script>
import HorizonModal from '@shared/horizon_modal'
import GradeLevel from '@utils/grade_levels'
import { required, email } from 'vuelidate/lib/validators'

export default {
  components: {
    HorizonModal
  },
  props: {
    scheduleOptions: {
      type: Array,
      default() { return [] }
    },
    selectedSchedule: {
      type: Object,
      required: true
    },
    initialScheduleId: {
      type: Number,
      default () { return null },
      required: false
    },
    enrollmentRepository: {
      type: Object,
      required: true
    },
    scheduleRepository: {
      type: Object,
      required: true
    },
    students: {
      type: Array,
      default() { return [] }
    },
  },
  computed: {
    gradeLevelOptions() {
      return GradeLevel.values()
    },
    isDisabled() {
      var enableSubmit = false
      this.students.forEach((value,index) => {
        if (value.user.courseNames.length <= 1 ){
          enableSubmit = true
        }
      });
      return  enableSubmit ? true : false
    },
  },
  validations: {
    scheduleId: { required },
    studentId: { required }
  },
  data () {
    return {
      student: 'none',
      studList: [],
      selectedScheduleId: null,
      // selectedSchedule: [],
      concurrentSchedule: [],
      scheduleId: this.initialScheduleId != 0? this.initialScheduleId : null, // 0 is for entire school
    }
  },
  methods: {
    removeStudent(studentId) {
    const indx = this.students.findIndex(student => student.id === studentId)
    this.students.splice(indx, 1)
    this.$emit('removeStudent', studentId)
    },
    courseNames(student) {
      return student.user.courseNames.join(", ")
    },
    async submit () {
      //TODO: unenroll student
      const promises = this.students.map (async (student) => {
        // return this.enrollmentRepository.create({ scheduleId: this.selectedScheduleId, userId: student.userId})
        return this.enrollmentRepository.delete({ scheduleId: this.selectedSchedule.id, id: student.id})
      })
      let hasValidationError = false
      
      const unenroll = await Promise.all(promises).catch((error) => {
        if (error.message === "Validation Error") {
          hasValidationError = true
        }
      })
      if (hasValidationError) {
        return
      } else {
        this.$emit('created', unenroll)
        this.$nextTick(() => this.resetData())
      }
    },
    rowParityClass (idx) {
      return (idx % 2 === 0) ? 'odd' : 'even'
    },
    resetData () {
      this.student = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        studentIdNumber: '',
        userId: '',
        gradeLevel:  GradeLevel.GRADE_8.value
      }
      this.scheduleId = this.initialScheduleId
    }
  }
}
</script>

<style scoped lang='scss'>
@import '~@styles/variables';
@import "~@styles/shared/students/student-form.scss";

@media screen and (max-width: $mobileScreenMedium) {
  #unenroll-student-modal {
    .col-md-6 {
      padding-right: 5px;
      padding-left: 5px;
    }
  }
}

@media screen and (max-width: $mobileScreenExtraSmall) {
  .justify-content-end {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
}

div input#student-email {
  text-transform: lowercase
}

.th-check, .td-check {
  width: 50px;
}

thead {
  th {
    padding-left: 7px;
    padding-right: 7px;
  }
}

tbody {
  td,th {
    padding: 7px;
    vertical-align: middle;
  }
  tr {
    &.odd {
      background-color: #f7f7f7;
    }
    &.even {
      background-color: #ffffff;
    }
  }
}

.table-course-enrollment {
  .th-options, .td-options {
    width: 150px;
  }
}
</style>
