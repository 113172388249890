<!--
Please see file:

  `app/javascript/packs/components/student/course_schedule/list.vue`

for a sample on how to use this table.
-->
<template>
  <div class="table-responsive">
    <table :id="id" class="table">
      <thead>
        <tr>
          <th
            v-for="(column, cIndx) in columns"
            :key="cIndx"
            :class="column.class">
            {{ column.display }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(entry, eIndx) in data"
          :key="eIndx">
          <td
            v-for="(column, cIndx) in columns"
            :key="cIndx">
            <slot
              :name="`column:${column.name}`"
              :entry="entry"
              :column="column"
              :value="entry[column.name]">
              <div>{{ entry[column.name] }}</div>
            </slot>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: false,
      default: () => ''
    },
    columns: {
      default: () => [],
      type: Array
    },
    data: {
      default: () => [],
      type: Array
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@styles/variables.scss';

table {
  margin-bottom: 0;
}

table > * > tr > * {
  vertical-align: middle;
}

tr {
  height: 60px;
  td:nth-child(-n+10), th:nth-child(-n+10) {
    text-align: left;
  }
  &:first-of-type td {
    border-top: none;
  }
}

thead {
  tr {
    font-size: 14px;
    font-weight: 600;
  }
}

.table thead th {
  border: none;
}

thead {
  background-color: $primaryColor;
  th {
    border: none;
    font-size: 16px;
    &:first-child {
      border-radius:5px 0 0 0;
    }
    &:last-child {
      border-radius:0 5px 0 0;
    }
  }
}

td:first-child, th:first-child {
  padding-left: 20px;
}

td:last-child, th:last-child {
  padding-right: 20px;
}

tbody {
  box-shadow: inset 0px 0px 0px 1px #dee2e6;
}
tbody > * {
  font-size: 12px;
}

@media screen and (max-width: $mobileScreenLarge) {
  thead {
    th {
      font-size: 14px;
      text-align: center !important;
    }
  }
  tr {
    height: 40px;
  }
}

@media screen and (max-width: $mobileScreenMedium) {
  thead {
    th {
      font-size: 12px;
      text-align: center !important;
      padding: 8px;
    }
  }
  tr {
    height: 30px;

    td {
      font-size: 11px;
      padding: 8px;
    }
  }
}

@media screen and (max-width: 549px) {
  tr {
    height: 20px;

    td {
      padding: 8px 2px;
    }
  }   
}
</style>
