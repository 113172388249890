<template>
  <div class="header-course">
    <h2 class='text-center course-name' v-show="!editing">{{  courseActivity.name }}</h2>
    <h2><editable-text
      v-show="editing"
      :text-content="courseActivity.name"
      :header-one="true"
      :identity="buildIdentity('name')"
    @update="editableTextUpdate"></editable-text></h2>
  </div>
</template>
<script>
import EditableText from './editable_text.vue'

export default {
  components: {
    EditableText,
  },
  
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    courseActivity: {
      type: Object,
      default(){ return {} }
    },
  },
  methods: {
    buildIdentity(target) {
      return {
        'course_id': this.courseActivity.id,
        'field' : target
      }
    },
    editableTextUpdate(identity, text) {
      this.$emit('edit-text-update', identity, text)
    },
    triggerSelect() {
      this.selected = this.selected == true ? false : true
    },
  },
}
</script>
<style scoped lang="scss">
@import '~@styles/variables';
.header-course {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    margin: 0px 15px 0px 15px;
    margin-top: 0px;
    padding: 0px 0px 20px 0px;

    h2 {
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 36px;
      font-weight: 700;
      line-height: 150%;
      text-align: left;
    }

  }
</style>