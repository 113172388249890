<template>
  <div>
    <div class="content-heading mb-2 mt-2">
      <div class="pull-left">
        <h1>Assessment Results Upload</h1>
        <br>
        <template v-for="(assessmentType, idx) in practiceTestSets">
          <table class="table table-primary w-100">
            <thead>
              <tr>
                <th class="flex-fill h2 text-align-left text-white">
                  {{ getAssessmentLabel(assessmentType) }}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(practiceTestSet, idx) in assessmentType">
                <tr :key="idx">
                  <td class="fw-600">
                    {{ practiceTestSet.name }}
                  </td>
                  <td class="action-column">
                    <button 
                      title="Upload Assessment Results" 
                      class="btn btn-outline-secondary gray-hover float-right" 
                      @click="assessmentsUploadModal(practiceTestSet.id,practiceTestSet.assessment_course_id)">
                      <i class="fa fa-lg fa-upload"/>
                    </button>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </template>
        <upload-assessment-modal
          :practice-test-set-id-prop="practiceTestSetId"
          :assessment-course-id-prop="assessmentCourseId"
          :school-id-prop="schoolId"
          :course-type-prop="courseType"
          @assessmentUploaded="assessmentsUploaded"
          :cancel="closeAssessmentImportModal"/>
      </div>
    </div>
    <hr>
    <div class="container">
      <admin-student-results-pane v-if="satCourseEnabled"/>
      <br>
      <admin-psat-8-9-student-results-pane v-if="psat89CourseEnabled"/>
      <br>
      <admin-psat-10-student-results-pane v-if="psat10CourseEnabled"/>
      <br>
      <admin-act-student-results-pane v-if="actCourseEnabled"/>
      <br>
      <admin-pre-act-9-student-results-pane v-if="preact9CourseEnabled"/>
      <br>
      <admin-pre-act-10-student-results-pane v-if="preact10CourseEnabled"/>
    </div>
  </div>
</template>

<script>
import UploadAssessmentModal from './upload_assessment_modal.vue'

import AdminStudentResultsPane from './shared/student_results_pane.vue'
import AdminPsat89StudentResultsPane from './shared/psat_8_9_student_results_pane.vue'
import AdminPsat10StudentResultsPane from './shared/psat_10_student_results_pane'
import AdminActStudentResultsPane from './shared/act_student_results_pane'
import AdminPreAct9StudentResultsPane from './shared/preact9_student_results_pane'
import AdminPreAct10StudentResultsPane from './shared/preact10_student_results_pane'

export default {
  components: {
    UploadAssessmentModal,
    AdminStudentResultsPane,
    AdminPsat89StudentResultsPane,
    AdminPsat10StudentResultsPane,
    AdminActStudentResultsPane,
    AdminPreAct9StudentResultsPane,
    AdminPreAct10StudentResultsPane
  },
  computed:{
    schoolId() {
      return this.$route.params.id
    },
    computedAssessments () {
      let assessmentsFormatted = []
      if (this.assessments.length) {
        _.forEach(this.assessments, (assessment) => {
          assessmentsFormatted[assessment.id] = assessment.assessment_type
        })
      }
      return assessmentsFormatted
    },
    satCourseEnabled() {
      if (_.size(this.schoolinfo)) {
        return _.includes(this.schoolinfo.school.assessmentCourses, 'SAT')
      }
      return false
    },
    psat89CourseEnabled() {
      if (_.size(this.schoolinfo)) {
        return _.includes(this.schoolinfo.school.assessmentCourses, 'PSAT89')
      }
      return false
    },
    psat10CourseEnabled() {
      if (_.size(this.schoolinfo)) {
        return _.includes(this.schoolinfo.school.assessmentCourses, 'PSAT10')
      }
      return false
    },
    actCourseEnabled() {
      if (_.size(this.schoolinfo)) {
        return _.includes(this.schoolinfo.school.assessmentCourses, 'ACT')
      }
      return false
    },
    preact9CourseEnabled() {
      if (_.size(this.schoolinfo)) {
        return _.includes(this.schoolinfo.school.assessmentCourses, 'PREACT9')
      }
      return false
    },
    preact10CourseEnabled() {
      if (_.size(this.schoolinfo)) {
        return _.includes(this.schoolinfo.school.assessmentCourses, 'PREACT10')
      }
      return false
    },
  },
  data () {
    return {
      assessments: [],
      practiceTestSets: [],
      assessmentsUploaded: [],
      practiceTestSetId: '',
      assessmentCourseId: null,
      courseType:'',
      schoolinfo: [],
    }
  },
  created () {
    this.loadPracticeTestSets(),
    this.loadSchoolInformation()
  },
  methods: {
    loadPracticeTestSets () {
      this.$http.get(`/api/admin/schools/${this.schoolId}/practice_test_sets?type=all`).then((response) => {
        this.assessments = response.data.assessments
        this.practiceTestSets = response.data.practice_test_sets
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    },
    loadSchoolInformation () {
      this.$http.get(`/api/admin/schools/${this.schoolId}`).then((response) => {
        this.schoolinfo = response.data
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    },
    assessmentsUploadModal (practiceTestSetId, assessmentCourseId = null) {
      this.courseType = this.schoolinfo.school.courseType
      this.practiceTestSetId = practiceTestSetId
      this.assessmentCourseId = assessmentCourseId
      $('#upload-assessment-modal').modal('show')
    },
    closeAssessmentImportModal () {
      $('#upload-asssessment-modal').modal('hide')
    },
    getAssessmentLabel (assessmentType) {
      let first = _.head(assessmentType)
      if (typeof first !== "undefined") {
        if (typeof this.computedAssessments[first.assessment_course_id] !== "undefined") {
          return this.computedAssessments[first.assessment_course_id]
        }
      }

      return null
    },
  }

}
</script>

<style scoped lang="scss">
	.content-pane {
		min-height: 20vh;
	}
tr {
    th:nth-child(1) {
      width: 1000px;
    }
}
</style>
