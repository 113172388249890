<template>
  <div class="container">
    <div class="content-pane">
      <div class="pane-wrapper">
        <breadcrumbs class="breadcrumbs"/>
        <div class="content-heading underlined">
          <h1>Course Schedules </h1>
        </div>
        <div class="row">
          <div v-for="(schedule, idx) in schedules" :key="idx" class="col-md-4 col-xs-12 mt-3">
            <schedule-card
              :schedule="schedule"
              :card-title="schedule.name"
              :buttons="buttons"
              :show-wait-listed="true">
              <h4>{{ schedule.scheduleType }} School Day</h4>
              <h5>{{ schedule.primaryTeacherName }}</h5>
              <h5>{{ schedule.secondaryTeacherName }}</h5>
            </schedule-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ScheduleCard from '@shared/schedule_card'
import Breadcrumbs from '@shared/breadcrumbs'

export default {
  components: {
    Breadcrumbs,
    ScheduleCard
  },
  data () {
    return {
      schedules: [],
      buttons: [
        {
          color: 'secondary',
          onClick: (params) => { this.navTo({name: 'StaffScheduleEdit', params: params}) },
          text: 'Edit Schedule'
        },
        {
          color: 'secondary',
          onClick: (params) => { this.navTo({name: 'StaffScheduleShow', params: params}) },
          text: 'View Schedule'
        }
      ]
    }
  },
  created () {
    this.initData()
    this.setAllowableButtons()
  },
  methods: {
    initData () {
      this.$http.get('/api/staff/schedules').then(response => {
        this.schedules = response.data.schedules
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    },
    navTo (routeOptions) {
      this.$router.push(routeOptions)
    },
    setAllowableButtons() {
      if (!this.$store.getters.userPermit({controller: 'Schedule', action: 'update'})) {
        this.buttons.shift()
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import '~@styles/variables';

  @media screen and (max-width: $mobileScreenExtraLarge) {
    .col-md-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  @media screen and (max-width: $mobileScreenLarge) {
    .col-md-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  @media screen and (max-width: $mobileScreenSmall) {
    .container {
      padding: 0px;
      height: 100%;

      .breadcrumbs {
        display: none;
      }

      .content-pane {
        border-radius: 0px;
        height: 100%;

        .pane-wrapper {
          padding: 15px;
        }
      }
    }
  }
</style>
