<template>
  <div class="col-md-12">
    <div class="no-gutters row">
      <div class="col-md-12 p-0">
        <h1>Course Attendance</h1>
        <hr>
      </div>
      <div class="col-md-12 row">
        <div v-for="(schedule, idx) in schedules" :key="idx" class="col-md-4 col-xs-12 mt-3">
          <schedule-card
            :schedule="schedule"
            :card-title="schedule.name"
            :buttons="buttons"
            :show-wait-listed="true">
            <h4>{{ schedule.scheduleType }} School Day</h4>
            <h5>{{ schedule.primaryTeacherName }}</h5>
            <h5>{{ schedule.secondaryTeacherName }}</h5>
          </schedule-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ScheduleCard from '@shared/schedule_card'

export default {
  components: {
    ScheduleCard
  },
  computed: {
    schoolId() {
      return this.$route.params.id
    }
  },
  data () {
    return {
      schedules: [],
      buttons: [
        {
          color: 'secondary',
          onClick: (params) => {
            this.navTo({ name: 'AdminSchoolAttendanceShow', params: { schoolId: this.schoolId, scheduleId: params.schedule.id} })
          },
          text: 'View Attendance'
        }
      ]
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      this.$http.get(`/api/admin/schools/${this.schoolId}/schedules`).then(response => {
        this.schedules = response.data.schedules
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    },
    navTo (routeOptions) {
      this.$router.push(routeOptions)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
