<template>
  <div class="col-md-12 report-wrapper">
    <div class="row">
      <div class="col">
        <enrollment-pane
          :schedule-repository="scheduleRepository"
          :student-repository="studentRepository"
          :enrollment-repository="enrollmentRepository" 
          :school-info="school"
          :is-admin="true"/>
        </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@shared/breadcrumbs'
import Mixins from '@submodules/mixins/mixin.js'

import EnrollmentPane from '@components/modules/enrollment/pane'
import RepositoryFactory from '@repositories/factory'

export default {
  components: {
    Breadcrumbs,
    EnrollmentPane
  },
  mixins: [Mixins],
  data () {
    return {
      breadcrumbs: [],
      school: {name: ''},
      scheduleRepository: RepositoryFactory.admin.school(this.$route.params.id).get('schedule'),
      studentRepository: RepositoryFactory.admin.school(this.$route.params.id).get('student'),
      enrollmentRepository: RepositoryFactory.admin.school(this.$route.params.id).get('enrollment')

    }
  },
  computed: {
    schoolId() {
      return this.$route.params.id
    }
  },
  async created() {
    await this.fetchSchoolInformation()
  },
  methods: {
    async fetchSchoolInformation() {
      const response = await this.$http.get(`/api/admin/schools/${this.schoolId}`)
      this.school = response.data.school
    }
  }
}
</script>

<style scoped lang="scss">
.total-student-number {
  display: inline-block;
  margin: 5px 0px 5px 6px;
  clear: none;
  color: #333;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
}

.student-total-number {
  display: inline-block;
  color: #f4701f;
  font-size: 28px;
  font-weight: 700;
}
</style>

<style scoped lang="scss">
.school-admin-heading {
  padding-bottom: 1.5rem;
}
</style>
