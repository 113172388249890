import { render, staticRenderFns } from "./horizon_multilevel_table.vue?vue&type=template&id=8bb6a842&scoped=true&"
import script from "./horizon_multilevel_table.vue?vue&type=script&lang=js&"
export * from "./horizon_multilevel_table.vue?vue&type=script&lang=js&"
import style0 from "./horizon_multilevel_table.vue?vue&type=style&index=0&id=8bb6a842&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8bb6a842",
  null
  
)

export default component.exports