<template>
  <div class="header-buttons">
    <a class="btn btn-outline header-btn" @click="$emit('course-redirect')">
      <img class="btn-icon header-btn-icon" src="/images/open-book.png">
      <div class="header-btn-text">View Course</div>
    </a>
    <a class="btn btn-outline header-btn" @click="$emit('gradebook-export')">
      <img class="btn-icon header-btn-icon" src="/images/spreadsheet-ascending-order.png">
      <div class="header-btn-text">Export Gradebook</div>
    </a>
  </div>
</template>
<script>
export default {
}
</script>
<style scoped lang="scss">
  .header-buttons {
    display: flex;
    a {
      border-color: #c7c7c7;
      background-color: white;
    }
    a:hover {
      border-color: #345165;
      color: #345165;
    }
  }

  .header-btn-text {
    font-size: 12px;
    font-weight: 400;
  }

  .header-btn-icon {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }

 .header-btn-text {
    font-size: 12px;
    font-weight: 400;
  }

  .header-btn-icon {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }
</style>
