<template>
  <div class="primary-row-action text-right">
    <button class="btn btn-lg btn-outline-secondary gray-hover font-14 fw-400 settings-btn" @click="raiseEvent('cancel')">
      <span class="font-14 text-decoration-0">Cancel</span>
    </button>
    <!--
    <div class="btn btn-lg btn-outline-secondary gray-hover font-14 fw-400 settings-btn">
      <a href="#" class="text-decoration-0">
        <i class="fa fa-lg fa-external-link font-16" aria-hidden="true" />
        <span class="font-14">Download PDF Flyer</span>
      </a>
    </div>
    -->
    <button class="btn btn-primary settings-btn font-14 fw-400" @click="raiseEvent('save')">
      <span class="font-14">Save Settings</span>
    </button>
  </div>
</template>

<script>
export default {
  methods: {
    raiseEvent(eventName) {
      this.$emit(eventName);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@styles/variables';

.btn-outline-secondary.gray-hover:hover {
  background-color: rgb(240, 240, 240) !important;
  color: black !important;
}

@media screen and (max-width: 550px) {
  .primary-row-action {
    margin-top: 5px;
  }

  .btn:first-child {
    margin-left: 0px;
  }
}
</style>
