<template>
  <div class="d-inline" v-if="notEntireSchool">
    <span id="transfer-schedule-label">
      Transfer Students to:
    </span>
    <select
      id="transfer-schedule-select"
      :class="['btn',  'btn-light', { 'highlighter-red': $v.selectedScheduleId.$error } ]"
      v-model="selectedScheduleId">
      <option :value="null">
        Select Schedule
      </option>
      <option
        v-for="(selectSchedule, idx) in scheduleOptions"
        :key="idx"
        :value="selectSchedule.id">
        {{ selectSchedule.name }}
      </option>
    </select>
    <button
      id="transfer-schedule-button"
      class="btn btn-light enrollment-function-button"
      :disabled="disabled"
      @click="openConfirmTransferModal">
      Transfer Students
    </button>

    <confirm-modal
      :cancel="close"
      body-header="Are you sure you want to transfer the selected students to a new schedule?"
      body-paragraph="Students can be transferred to different schedules at any time."
      confirm-btn-text="Yes, Transfer Students"
      confirmed-action="transfer"
      @confirmed="submit"/>
  </div>
</template>

<script>
import ConfirmModal from './confirm_modal'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    ConfirmModal
  },
  props: {
    currentSchedule: {
      type: Object,
      default() { return {} }
    },
    schedules: {
      type: Array,
      default() { return [] }
    },
    students: {
      type: Array,
      default() { return [] }
    },
    enrollmentRepository: {
      type: Object,
      required: true,
      default() { return null }
    }
  },
  validations: {
    selectedScheduleId: { required }
  },
  computed: {
    scheduleOptions() {
      if(this.currentSchedule.name != "Entire School") {
        return this.schedules.filter(schedule => {
          return schedule.courseType == this.currentSchedule.courseType && schedule.id != this.currentSchedule.id
        })
      } else {
        return this.schedules
      }
    },
    disabled() {
      return this.students.length == 0
    }
  },
  data() {
    return {
      selectedScheduleId: null,
      emphasizeNoSchedule: false
    }
  },
  methods: {
    notEntireSchool() {
      if(this.currentSchedule.name !== "Entire School") { return true }
    },
    openConfirmTransferModal () {
      this.$v.$touch()
      if(this.$v.$invalid) {
        return
      }

      $('#transfer-students-modal').modal('show')
    },
    close() {
      $('#transfer-students-modal').modal('hide')
    },
    async submit() {
      const promises = this.students.map (async (student) => {
        const requestBody = { scheduleId: this.selectedScheduleId, originScheduleId: this.currentSchedule.id}
        await this.enrollmentRepository.update(student.id, requestBody)
      })

      await Promise.all(promises)
      this.$emit('transferred',this.students)
      this.close()
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~@styles/variables';

.enrollment-function-button {
  height: 40px;
  font-size: 12px;
}

@media screen and (max-width: $mobileScreenLarge) {
  #transfer-schedule-label, #transfer-schedule-select, #transfer-schedule-button {
    display: block;
    margin-left: 0px !important;
    margin-bottom: 5px;
  }

  #transfer-schedule-label {
    font-size: 12px;
    font-weight: 600;
  }

  #transfer-schedule-select {
    width: auto;
    font-size: 12px;
  }

  #transfer-schedule-button {
    width: 130px;
  }
}

@media screen and (max-width: $mobileScreenExtraSmall) {
  #transfer-schedule-select {
    width: 100%;
  }
}
</style>
