<template>
  <horizon-modal-full-width :id="id" modal-title="Review Questions" :on-close="modalClose">
    <template v-slot:content>
      <div :id="id + '-report-container'">
        <div :id="id + '-report'"></div>
      </div>
    </template>
  </horizon-modal-full-width>
</template>
<script>
import HorizonModalFullWidth from '@shared/horizon_modal_full_width'
export default {
  components: {
    HorizonModalFullWidth,
  },
  props: {
    id: {
      type: String,
      default: '',
      required: true
    },
    reportInitConfig: {
      type: Object,
      default () { return {} }
    },
    reportHtmlId: {
      type: String,
      default: '',
      required: true
    },
  },
  watch: {
    reportHtmlId: {
      immediate: true,
      handler(val, oldVal) {
        if (document.getElementById(this.id + "-report-container") == null) { return }
        this.clearReportContainer(val)
      }
    },
    reportInitConfig: {
      immediate: true,
      handler(val, oldVal) {
        if (Object.keys(val).length > 0) {
          this.loadReportHtml()
        }
      }
    }
  },
  methods: {
    modalClose() {
      this.$emit('modal-closed')
    },
    clearReportContainer(newId) {
      let container = document.getElementById(this.id + "-report-container")
      let old_div = container.firstElementChild
      if (old_div != null) {
        let new_div = document.createElement("div")
        new_div.id = newId
        old_div.parentNode.replaceChild(new_div, old_div)
      }
    },
    loadReportHtml() {
      setTimeout(() => {
        LearnosityReports.init(this.reportInitConfig)
      }, 500)
    }
  }
}
</script>
<style scoped lang='scss'>
@import '~@styles/variables';
</style>
