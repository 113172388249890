import { mapGetters, mapActions } from 'vuex'
import client from '@repositories/client.js'

export default {
  data: {
    hubspotChatLoaded: false
  },
  mounted() {
    this.loadHubspotChat()
  },
  methods: {
    ...mapGetters(['userAuthenticated', 'userEmail', 'hubspotToken']),
    ...mapActions(['setHubspotToken']),

    async loadHubspotChat() {
      if (this.userAuthenticated()) {
        let email = this.userEmail()

        if (!this.isHubspotTokenValid()) {
          var response = await this.fetchHubspotToken()
          this.setHubspotToken(response)
        }

        let token = this.hubspotToken()
        if (window.HubSpotConversations) {
          this.loadVisitorChat(token, email);
        } else {
          window.hsConversationsOnReady = [() => {
            this.loadVisitorChat(token, email)
          }];
        }
      } else {
        if (window.HubSpotConversations) {
          this.loadAnonymousChat();
        } else {
          window.hsConversationsOnReady = [this.loadAnonymousChat];
        }
      }


    },

    isHubspotTokenValid() {
      return Date.now() < this.getHubspotTokenExpiry()
    },

    getHubspotTokenExpiry() {
      if (this.hubspotToken() == null) {
        return new Date(0)
      }

      return new Date(this._parseJwt(this.hubspotToken()).exp * 1000)
    },

    async fetchHubspotToken() {
      var response = await client.get(`/api/hubspot_token`)
      return response.token
    },

    loadAnonymousChat() {
      window.hsConversationsSettings = {};
      const status = window.HubSpotConversations.widget.status();
      if (status.loaded) {
        window.HubSpotConversations.widget.refresh();
     } else {
        window.HubSpotConversations.widget.load();
      }
    },

    loadVisitorChat(hubspotToken, userEmail) {
      window.hsConversationsSettings = {
        identificationEmail: userEmail,
        identificationToken: hubspotToken
      };

      const status = window.HubSpotConversations.widget.status();
      if (status.loaded) {
        window.HubSpotConversations.widget.refresh();
     } else {
        window.HubSpotConversations.widget.load();
      }
    },

    _parseJwt (token) {
      return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
    }
  }
}