<template>
  <div class="container">
    <div class="content-pane">
      <div class="pane-wrapper">
        <breadcrumbs :breadcrumbs="breadcrumbs"/>
        <div class="content-heading underlined">
          <h1>PDF Report</h1>
        </div>
        <div class="report">
          <p>Your download will start automatically in {{ countdown }} seconds. If the download does not start, click on the link below.</p>
          <p><b><a :href="link" ref="downloadLink">Download</a></b></p>
        </div>
      </div>
    </div> 
  </div>
</template>

<script>
import Breadcrumbs from '@shared/breadcrumbs'
import CommonLoading from '@shared/common_loading'
import store from '../../../store/store'

export default {
  components: {
    Breadcrumbs,
    CommonLoading,
  },
  data () {
    return {
      breadcrumbs: [],
      link: '',
      countdown: 5
    }
  },
  props: {
    reportId: {
      type: [Number, String]
    }
  },
  mounted () {
    // Redirect students to their dashboard
    this.user = this.$store.state.user
    if (this.user.role == "Student") {
      this.$router.push({ name: "StudentDashboard" })
    }

    this.link = `/reports/assessments/${this.reportId}`
  },
  watch: {
    countdown: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.countdown--;
          }, 1000)
        } else {
          this.$refs.downloadLink.click()
        }
      }, immediate: true
    }
  }
}
</script>

<style>
.report {
  margin: 20px;
}
</style>