<template>
  <div class="col-md-12">
    <div class="no-gutters row">
      <div class="col-md-12 p-0">
        <div class="row">
          <div class="col-6">
            <h1>Course Schedules</h1>
          </div>
          <div class="col-6">
            <create-schedule-modal
              :schoolId ="schoolIdProp"
              :assessment="assessmentProp" />
            <button
              id="create-schedule"
              class="btn btn-primary float-right"
              @click="openCreateScheduleModal">
              <i class="fa fa-2x fa-plus-circle mr-1 text-orange v-align-middle"/>
              <span class="font-14 v-align-middle">
                Create New Schedule
              </span>
            </button>
          </div>
        </div>
        <hr>
      </div>
      <div class="col-md-12 row">
        <div v-for="(schedule, idx) in schedules" :key="idx" class="col-md-4 col-xs-12 mt-3">
          <schedule-card
            :schedule="schedule"
            :card-title="schedule.name"
            :buttons="activeButtons(schedule)"
            :show-wait-listed="true">
            <h4 v-if="schedule.scheduleType === 'Inside' || schedule.scheduleType === 'Outside'">
              {{ schedule.scheduleType }} School Day
            </h4>
            <h4 v-else>
              {{ schedule.scheduleType }}
            </h4>
            <h5>{{ schedule.primaryTeacherName }}</h5>
            <h5>{{ schedule.secondaryTeacherName }}</h5>
          </schedule-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreateScheduleModal from '@components/modules/schedule/create_modal'
import ScheduleCard from '@shared/schedule_card'

import RepositoryFactory from "@repositories/factory";

export default {
  components: {
    CreateScheduleModal,
    ScheduleCard
  },
  data () {
    return {
      assessmentProp: {},
      schedules: [],
      buttons: [
        {
          color: 'secondary',
          onClick: (params) => { this.navTo({name: 'AdminSchoolScheduleEdit', params: {id: this.schoolIdProp, scheduleId: params.schedule.id}}) },
          text: 'Edit Schedule',
          active: true
        },
        {
          color: 'secondary',
          onClick: (params) => { this.navTo({name: 'AdminSchoolScheduleView', params: {id: this.schoolIdProp, scheduleId: params.schedule.id}}) },
          text: 'View Schedule',
          active: true
        },
        {
          color: 'secondary',
          onClick: (params) => { this.deleteData(params.schedule.id) },
          text: 'Delete Schedule',
          active: false
        }
      ],
      schoolRepository: RepositoryFactory.admin.school(this.$route.params.id)
    }
  },
  created () {
    this.schoolIdProp = this.$route.params.id
    this.initData()
  },

  methods: {
    activeButtons (schedule) {
       return this.buttons.filter(function(button) {
         if (button.text === 'Delete Schedule' && schedule.studentsEnrolled === 0 ) { return button }
        if (button.active) { return button }
     })
    },
    deleteData (scheduleId) {
      console.log(('Delete data here...' + scheduleId))
      this.$http.delete(`/api/admin/schedules/${scheduleId}`).then((response) => {
        this.appendSuccess({ message: 'Schedule deleted successfully', notification: true })
        this.initData()
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    },
    async initData () {
      this.$http.get(`/api/admin/schools/${this.schoolIdProp}/schedules`).then(response => {
        this.schedules = response.data.schedules
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })

      const schoolInfo = await this.schoolRepository.info()
      this.assessmentProp = schoolInfo.assessment
    },
    navTo (routeOptions) {
      this.$router.push(routeOptions)
    },
    openCreateScheduleModal () {
      $('#create-schedule-modal').modal('show')
    }
  }
}
</script>

<style scoped lang="scss">

</style>
