import client from '@repositories/client.js'

export default class {

  async list(assessment) {
    const data = await client.get(`/api/admin/assessments/${assessment.id}/target_assessment_dates`);
    return data.targetAssessmentDates;
  }

}
