<template>
  <div>
    <div class="bg-primary card-header d-flex justify-content-between no-border-radius">
      <h1 class="text-white">
        Strength & Improvement Areas
      </h1>
    </div>
    <div v-if="loadingAssessmentStrengthAndImprovementAreas"
      class="card-body">
      <div class="row">
        <div class="col-12">
          <common-loading/>
        </div>
      </div>
    </div>
    <div v-else 
      class="card-body">
      <div class="mb-3 row strength-areas-header">
        <div class="col-12">
          <h2>
            Strength Areas
          </h2>
        </div>
      </div>
      <div class="pb-5 row strength-areas-data">
        <div class="col-7">
          <span v-for="(strength, iIndx) in assessmentStrengthAndImprovementAreas.strengthAreas" :key="iIndx">
            <div class="border-bottom d-inline-block font-14 fw-600 mb-1">
              <span>Highest {{ strength.categoryGroupName }} Category</span>
            </div>
            <div class="font-14 fw-600 mb-1 text-orange">
              {{ strength.subsectionAreaName }}
            </div>
            <div v-if="(scheduleProp.courseType == 'SAT')" class="font-14 mb-3">
              {{ strength.topicAreaName }}
            </div>
          </span>
        </div>
        <div class="col-5">
          <div class="font-14 fw-600 mb-1">
            Top 5 Correct Questions
          </div>
          <app-horizon-table
            v-if="assessmentStrengthAndImprovementAreas.topCorrect"
            :columns="correctColumns"
            :data="assessmentStrengthAndImprovementAreas.topCorrect"
            :small="true"
            :row-numbers="true"
            class="small-bordered table-striped questions-table"/>
        </div>
      </div>

      <div class="mb-3 row improvement-areas-header">
        <div class="col-12">
          <h2>
            Improvement Areas
          </h2>
        </div>
      </div>
      <div class="row improvement-areas-data">
        <div class="col-7">
          <span v-for="(improvement, iIndx) in assessmentStrengthAndImprovementAreas.weakestAreas" :key="iIndx">
            <div class="border-bottom d-inline-block font-14 fw-600 mb-1">
              <span>Lowest {{ improvement.categoryGroupName }} Category</span>
            </div>
            <div class="font-14 fw-600 mb-1 text-orange">
              {{ improvement.subsectionAreaName }}
            </div>
            <div v-if="scheduleProp.courseType == 'SAT'" class="font-14 mb-3">
              {{ improvement.topicAreaName }}
            </div>
          </span>
        </div>
        <div class="col-5">
          <div class="font-14 fw-600 mb-1">
            Top 5 Missed Questions
          </div>
          <app-horizon-table
            v-if="assessmentStrengthAndImprovementAreas.topIncorrect"
            :columns="incorrectColumns"
            :data="assessmentStrengthAndImprovementAreas.topIncorrect"
            :small="true"
            :row-numbers="true"
            class="small-bordered table-striped questions-table"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HorizonTable from '@shared/horizon_table.vue'
import CommonLoading from '@shared/common_loading'

export default {
  components: {
    'app-horizon-table': HorizonTable,
    'common-loading': CommonLoading
  },
  props: {
    assessmentIdProp: { default: '', type: [String,Number] },
    scheduleProp: { type: Object },
    requestUrlBaseProp: { type: String, required: true }
  },
  data () {
    return {
      assessmentStrengthAndImprovementAreas: {},
      correctColumns: [
        { column: 'rowNumbers', display: '' },
        { column: 'questionNumber', columnFn: this.formatQuestionField, display: 'Question' },
        { column: 'percentCorrect', columnFn: this.formatPercentField, display: '% Correct' }
      ],
      incorrectColumns: [
        { column: 'rowNumbers', display: '' },
        { column: 'questionNumber', columnFn: this.formatQuestionField, display: 'Question' },
        { column: 'percentIncorrect', columnFn: this.formatPercentField, display: '% Incorrect' }
      ],
      loadingAssessmentStrengthAndImprovementAreas: false,
    }
  },
  created () {
    this.initData()
  },
  methods: {
    formatPercentField (questionInfo) {
      return questionInfo.percentCorrect ? questionInfo.percentCorrect + '% Correct' : questionInfo.percentIncorrect + '% Incorrect'
    },
    formatQuestionField (questionInfo) {
      const formattedQuestion = [questionInfo.category, questionInfo.questionNumber].join(' - #')
      return `<span class="fw-600">${formattedQuestion}</span>`
    },
    initData () {
      this.loadingAssessmentStrengthAndImprovementAreas = true
      this.$http.get(`${this.requestUrlBaseProp}/strength_and_improvement_areas`).then((result) => {
        this.assessmentStrengthAndImprovementAreas = result.data.assessmentStrengthAndImprovementAreas
        this.loadingAssessmentStrengthAndImprovementAreas = false
      }, (responseError) => {
        this.appendError({error: responseError, notification: true})
        this.loadingAssessmentStrengthAndImprovementAreas = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@styles/variables';

@media screen and (max-width: $mobileScreenExtraLarge) {
  .strength-areas-data, .improvement-areas-data {
    .col-7, .col-5 {
      flex: 50%;
      max-width: 50%;
    }
  }
}

@media screen and (max-width: $mobileScreenMedium) {
  .card-header {
    h1 {
      width: 100%;
      text-align: center;
      font-size: 18px;
    }
  }

  .strength-areas-header, .improvement-areas-header {
    .col-12 {
      h2 {
        width: 100%;
        text-align: center;
      }
    }
  }

  .strength-areas-data, .improvement-areas-data {
    display: block;

    .col-7, .col-5 {
      flex: 100%;
      max-width: 100%;
    }

    .col-7 {
      span {
        div {
          text-align: center;
        }

        .border-bottom {
          border-bottom: 0px !important;

          span {
            border-bottom: 1px solid #dee2e6 !important;
            margin-bottom: 0.25rem !important;
          }
        }

        .d-inline-block {
          display: block !important;
        }
      }
    }

    .col-5 {
      div:first-child {
        width: 100%;
        text-align: center;
        padding-top: 15px;
      }
    }
  }

  .improvement-areas-data {
    margin-bottom: 25px;
  }

  .pb-5 {
    padding-bottom: 30px !important;
  }
}
</style>
