<template>
  <div class="card">
    <div class="card-body">
      <div class="pull-left">
        <h2 class="card-title">
          <slot name="header"/>
        </h2>
      </div>
      <div class="d-flex flex-row pull-right text-center">
        <slot name="body"/>
      </div>
    </div>
    <div class="card-footer">
      <slot name="footer"/>
    </div>
  </div>
</template>

<script>

export default {
  methods: {}
}
</script>

