<template>
  <table class="class-dates table table-primary">
    <thead>
      <tr>
        <th class="flex-fill h2 text-align-left text-white">
          {{ classHeaderName }}
        </th>
        <th class="h2 text-align-left text-white date-time-separated" scope="col">
          Date
        </th>
        <th class="h2 text-align-center text-white date-time-separated" scope="col">
          Start Time
        </th>
        <th class="h2 text-align-center text-white date-time-separated" scope="col">
          End Time
        </th>
        <th class="h2 text-align-center text-white date-time-combined" scope="col">
          Date & Time
        </th>
      </tr>
    </thead>
    <tbody>
      <template v-for="(scheduleDate, index) in classDates">
        <tr :key="index">
          <td class="fw-600">
            {{ formatClassName(scheduleDate,index) }}
          </td>
          <td class="fw-400 date-time-separated">
            {{ scheduleDate.meetingOn| date('dddd, MMMM D, YYYY') }}
          </td>
          <td class="fw-400 text-align-center date-time-separated">
            {{ scheduleDate.startAt }}
          </td>
          <td class="fw-400 text-align-center date-time-separated">
            {{ scheduleDate.endAt }}
          </td>
          <td class="text-align-center date-time-combined">
            {{ scheduleDate.meetingOn| date('dddd, MMMM D, YYYY') }}<br />
            {{ scheduleDate.startAt }} - {{ scheduleDate.endAt }}
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import moment from "moment";
export default {
  props: {
    schedule: {
      type: Object,
      required: true,
      default () { return {} }
    }
  },
  computed: {
    scheduleType () {
      return this.schedule.scheduleType
    },
    classDates () {
      const scheduleDatesList = this.schedule.scheduleDates
      scheduleDatesList.sort((a, b) => {
        return moment(a.meetingOn).diff(moment(b.meetingOn));
      });
      return scheduleDatesList;
    },
    classHeaderName() {
      if(this.scheduleType == 'Inside') {
        return 'Classes Start/End';
      }

      return 'Classes';
    }
  },
  methods: {
    formatClassName (classDate, index) {
      if (this.scheduleType == 'Inside' && this.classDates[0] == classDate) {
        return 'Classes Start'
      } else if (this.scheduleType == 'Inside' && this.classDates[1] == classDate) {
        return 'Classes End'
      }
      return 'Class #' + (Number(index) + 1)

    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@styles/modules/schedule/schedule_dates_table.scss';
</style>
