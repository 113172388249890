<template>
  <div class="table-responsive">
    <div v-if="tableSetup.header" class="table-header">
      <table>
        <thead class="override-header">
          <tr>
            <th
              v-for="(column, cIndx) in columns"
              :key="cIndx"
              :style="{ width: columnWidth(column) }"
              :class="{ 'table-title': column.title }">
              {{ column.display }}
            </th>
          </tr>
        </thead>
      </table>
    </div>
    <table
      v-for="(tableSection, tIndx) in data"
      :key="tIndx"
      class="h-table table"
      :class="{ 'small-table': small }">
      <thead>
        <tr>
          <th
            v-for="(column, cIndx) in columns"
            :key="cIndx"
            :style="{ width: columnWidth(column) }">
            {{ applyFormatter(tableSection[column.column],  column.formatter) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(entry, eIndx) in tableSection[subTableKey]"
          :key="eIndx">
          <td
            v-for="(column, cIndx) in columns"
            :key="cIndx">
            <template v-if="column.action">
              <div class="d-flex justify-content-end">
                <div class="column-button">
                  <img src="/images/delete.svg">
                </div>
              </div>
            </template>
            <template v-else-if="column.secondaryDisplay">
              <div v-html="entry[headerColumn(column)]"/>
              <div v-html="applyFormatters(entry[headerColumn(column.secondaryDisplay)])"/>
            </template>
            <template v-else>
              <div v-html="applyFormatter(entry[headerColumn(column)], column.formatter)"/>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="tableFooter" class="table-footer">
      <div class="column-button">
        <img src="/images/plus.svg">
        <span>Send Invite</span>
      </div>
    </div>
  </div>
</template>

<script>
// TODO combine this table and horizon_table to single component & refactor styles to tables.scss
export default {
  props: {
    columns: {
      default: () => [],
      type: Array
    },
    data: {
      default: () => [],
      type: Array
    },
    small: {
      type: Boolean,
      default: false
    },
    tableFooter: {
      type: Boolean,
      default: false
    },
    tableSetup: {
      type: Object,
      default: () => {}
    },
    subTableKey: {
      default: 'detail',
      type: String
    }
  },
  data () {
    return {
    }
  },
  methods: {
    applyFormatters (data, formatters) {
      if (this.isUndefined(formatters)) { return data }
      formatters.forEach((formatter) => {
        if (formatter == 'currency') { data = this.$options.filters.currency(data) }
        if (formatter == 'decimalToPercent') { data = this.$options.filters.decimalToPercent(data) }
        if (formatter == 'intToPercent') { data = this.$options.filters.intToPercent(data) }
        if (formatter == 'booleanYesNo') { data = this.$options.filters.booleanYesNo(data) }
      })
      return data
    },
    applyFormatter(data, formatter) {
      if (this.isUndefined(formatter)) { return data }
      if (formatter == 'decimalToPercent') { return this.$options.filters.decimalToPercent(data, 1) }
      if (formatter == 'currency') { return this.$options.filters.currency(data) }
      if (formatter == 'decimalToPercent') { return this.$options.filters.decimalToPercent(data) }
      if (formatter == 'intToPercent') { return this.$options.filters.intToPercent(data) }
      if (formatter == 'intToDecimal') { return parseFloat(data).toFixed(1) }
      if (formatter == 'booleanYesNo') { return this.$options.filters.booleanYesNo(data) }
    },
    columnWidth (column) {
      return column.width ? column.width + '%' : ''
    },
    headerColumn (column) {
      if (typeof column == 'string' || column instanceof String) { return column }
      if (column.rowOptions) { return '' }
      return column.column || ''
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@styles/variables.scss';
table {
  margin-bottom: 0;
}
table > * > tr > * {
  vertical-align: middle;
}

tr {
  height: 60px;
  td:nth-child(-n+2), th:nth-child(-n+2) {
    text-align: left;
  }
  &:first-of-type td {
    border-top: none;
  }
}

thead {
  background-color: $primaryColor;
  &.override-header {
    background-color: #F3F3F3;
    border-top: 1px solid #dee2e6;
  }
}

td:first-child, th:first-child {
  padding-left: 20px;
}
td:last-child, th:last-child {
  padding-right: 20px;
}

tbody {
  // box-shadow: inset 0px 0px 0px 1px #dee2e6;
}
tbody > * {
  font-size: 12px;
}
.column-button {
  min-width: 35px;
  height: 35px;
  padding: 10px;
  border: 1px solid #949494;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  transition: .2s;
  cursor: pointer;
  background-color: #fff;
  font-size: 12px;
  &:hover {
    background-color: #f0f0f0;
  }
  img {
    width: 14px;
    height: auto;
  }
  span {
    margin-left: 5px;
  }
}
.table-footer {
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: $themeLtGray;
  padding: 0 20px;
  width: 100%;
  border-radius: 0 0 5px 5px;
  border-right: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
}

.small-table {
  thead {
    tr {
      font-size: 14px;
      font-weight: 600;
    }
  }
  tr {
    height: 40px;
  }
}

.table thead th {
  border: none;
}

.table-header {
  table {
    width: 100%;
    thead {
      color: $primaryColor;
    .table-title {
      font-weight: 900;
      color: #f4701f;
      text-transform: uppercase;
      font-size: 16px;
      letter-spacing: 2px;
    }
    }
  }
  background-color: #F3F3F3;
  height: 60px;
}

.override-header th:not(.tab-title) {
  font-size: 14px;
  font-weight: 700;
}

</style>
