<template>
  <div class="container">
    <div class="content-pane">
      <div class="pane-wrapper">
        <breadcrumbs/>
        <div class="dashboard-student-heading">
          <img src="/images/assessment_results_icon.png" class="image-student-head">
          <h2 class="heading-student-name">
            Practice Assessments
          </h2>
        </div>
        <div v-if="computedHasOnlineAssessmentSchedules"
          class="pane-section-wrapper">
          <div class="report-wrapper">
            <div class="tabs-row">
              <div
                v-for="(assessment, assessmentIndex) in onlineAssessmentSchedules"
                :key="assessmentIndex"
                class="tab"
                :class="{ 'selected': assessment.selected }"
                @click="updatePracticeAssessment(assessment)">
                <div v-if="assessment.test_sections.length > 0">
                  {{ assessment.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane pb-2">
            <div
              v-for="(selectedAssessment, selectedAssessmentIndex) in computedTestSection"
              :key="selectedAssessmentIndex"
              class="primary-row practice-assessment-item mb-3"
              :class="{ active: isReleased(selectedAssessment) && !isCompleted(selectedAssessment) }">
              <div class="practice-assessment-info mb-2 mt-2">
                <h5 v-if="(selectedAssessment.timing_status)"
                  class="practice-assessment-time">
                  <strong class="bold-text">{{ selectedAssessment.duration_in_minutes }}</strong>
                  <br>
                  <small>Min </small>
                </h5>
                <h5 class="practice-assessment-title">
                  <strong class="bold-text">{{ selectedAssessment.name }}</strong>
                  <br>
                  <small>{{ selectedAssessment.questions_count }} Questions</small>
                </h5>
              </div>
              <div class="primary-row-action practice-assessment-action">
                <button v-if="isReleased(selectedAssessment) && (selectedAssessment.learnosity_assessment_status == 'not_started')"
                  class="btn btn-outline-primary"
                  @click="startSection(selectedAssessment)">
                  Start this section
                  &nbsp;
                  <img src="/images/right-arrow.png" class="btn-icon">
                </button>
                <button v-else-if="isReleased(selectedAssessment) && (selectedAssessment.learnosity_assessment_status == 'in_progress')"
                  class="btn btn-outline-primary"
                  @click="startSection(selectedAssessment)">
                  In Progress
                  &nbsp;
                  <img src="/images/right-arrow.png" class="btn-icon">
                </button>
                <button v-else-if="isReleased(selectedAssessment) && (selectedAssessment.learnosity_assessment_status == 'completed')"
                  class="btn btn-outline btn-disabled bg-white"
                  disabled>
                <img src="/images/release_button.png" class="btn-icon">
                &nbsp;
                Completed
                </button>
                <button v-else
                  class="btn btn-outline btn-disabled"
                  disabled>
                  <img src="/images/lock.png" class="btn-icon">
                  &nbsp;
                  Locked
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="fetchingOnlineAssessmentSchedules"></div>
        <div v-else>
          <br>
          <h2 class="text-muted"><center> No practice assessments available yet.</center> </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@shared/breadcrumbs'
import { mapGetters } from "vuex"

export default {
  components: {
    Breadcrumbs
  },
  data () {
    return {
      fetchingOnlineAssessmentSchedules: false,
      onlineAssessmentSchedules: [],
      activeAssessmentSchedule: {},
      studentId: ""
    }
  },
  created () {
    this.fetchOnlineAssessmentSchedules()
  },
  computed: {
    computedHasOnlineAssessmentSchedules () {
      var hasAssessment = false
      this.onlineAssessmentSchedules.forEach(value => { 
        if (value.test_sections.length > 0 ){ hasAssessment = true}
      });
      return hasAssessment;
    },
    computedTestSection () {
      return _.orderBy(this.activeAssessmentSchedule.test_sections, 'sort_order', 'asc')
    }
  },
  methods: {
    ...mapGetters(["activeCourse"] ),
    fetchOnlineAssessmentSchedules () {
      const urlActiveCourse = this.activeCourse()
      const url = urlActiveCourse ? `/api/student/online_assessment_schedules/course/${urlActiveCourse.id}`: `/api/student/online_assessment_schedules`
      this.fetchingOnlineAssessmentSchedules = true
      this.$http.get(url).then(response => {
        this.studentId = response.data.student_id
        this.onlineAssessmentSchedules = response.data.details.practice_test_sets
        this.activeAssessmentSchedule = this.onlineAssessmentSchedules[0]
        this.activeAssessmentSchedule.selected = true
        this.fetchingOnlineAssessmentSchedules = false
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
        this.fetchingOnlineAssessmentSchedules = false
      })
    },

    updatePracticeAssessment (assessment) {
      this.onlineAssessmentSchedules.forEach(function (assessment) {
        assessment.selected = false
      })
      assessment.selected = true
      this.activeAssessmentSchedule = assessment
    },
    startSection (assessment) {
      window.open(`/students/${this.studentId}/online_assessment_schedules/${assessment.id}?untimed=${(assessment.timing_status ? false : true)}`)
      this.fetchOnlineAssessmentSchedules()
    },
    isReleased (assessment) {
      if (assessment.status == "released") { return true }
    },
    isCompleted (assessment) {
      if (assessment.learnosity_assessment_status == "completed") { return true }
    },
    setAsSelected (assessment) {
      this.activeAssessmentSchedule = assessment
      this.activeAssessmentSchedule.selected = true
    }
  }
}
</script>

<style scoped lang="scss">
  .practice-assessment-item {
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    padding: 14px 20px;

    & > .practice-assessment-info {
      display: flex;
      & > .practice-assessment-time {
        display: inline-block;
        margin: 2px 15px 2px 0;
        color: #345165;
        font-size: 24px;
        line-height: 20px;
        font-weight: 400;
        text-align: center;
        border: 1px solid #e8e8e8;
        padding: 13px 15px;
        border-radius: 8px;
        width: 70px;
        height: 70px;

        & > small {
          font-size: 16px;
          color: #505050;
        }
      }

      & > .practice-assessment-title {
        display: inline-block;
        margin: 10px 5px 0 0;
        color: #345165;
        font-size: 24px;
        line-height: 28px;
        font-weight: 400;
        text-align: left;
        padding: 0;

        & > small {
          font-size: 16px;
          color: #505050;
        }
      }
    }
    .practice-assessment-action {
      & > button {
        font-size: 12px;
        &.btn-outline-primary {
          color: #345165;
          background-color: white;

          & > .btn-icon {
            width: 18px;
            height: auto;
          }
        }
        &.btn-disabled {
          opacity: 1;
          color: #345165;
          border-color: #f0f0f0;
          cursor: not-allowed;

          &:hover {
            background: none;
            color: #345165;
          }

          & > .btn-icon {
            width: 18px;
            height: auto;
          }
        }
      }
    }
    &.active {
      border-color: #345165;
      background: #f0f0f0;

      & .practice-assessment-time {
        background-color: white;
        border-color: #345165;
        color: #F79861;
      }
    }
  }
</style>
