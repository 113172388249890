<template>
  <staff-psat-10-student-results-pane
    :is-horizon-admin="true"/>
</template>

<script>
import StaffPsat10StudentResultsPane from '@staff/shared/psat_10_student_results_pane'

export default {
  components: {
    StaffPsat10StudentResultsPane,
  },
}
</script>