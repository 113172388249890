<template>
  <horizon-modal
    id="bulk-pdf-export-modal"
    modal-title="PDF Report Export Options">
    <template v-slot:content>

      <div v-if="!operationDone">
        <div class="horizon-form-row">
          <h2 class="d-block label flex-y-center">
            Export Type
          </h2>

          <common-dropdown
            :items="exportTypes"
            itemDisplayKey="name"
            :on-item-select="selectExportType">
            <img
              width="17"
              aria-hidden="true"
              class="date-icon">
            <span style="vertical-align: middle; padding-left: 5px;">{{ selectedExportType["name"] }}</span>
          </common-dropdown>


        </div>

        <div class="horizon-form-row">
          <h2 class="d-block label flex-y-center" style="flex-grow: 1">
            Sort Order
          </h2>

          <common-dropdown
            :items="sortOrders"
            itemDisplayKey="name"
            :on-item-select="selectSortOrder">
            <img
              width="17"
              aria-hidden="true"
              class="date-icon">
            <span style="vertical-align: middle; padding-left: 5px;">{{ selectedSortOrder["name"] }}</span>
          </common-dropdown>
        </div>


        <div class="button-row">
          <div
            class="btn btn-primary download-btn">
            <a @click="downloadPdf">Download</a>
          </div>
        </div>
      </div>

      <div v-else>
        <div class="body-larger">
          {{ message }}
        </div>
        <div class="button-row">
          <div
            class="btn btn-primary download-btn">
            <a @click="closeModal">Okay</a>
          </div>
        </div>
      </div>

    </template>
  </horizon-modal>
</template>

<script>
import Mixins from '@submodules/mixins/mixin.js'
import HorizonModal from '@shared/horizon_modal'
import CommonDropdown from '@shared/common_dropdown'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    HorizonModal,
    CommonDropdown
  },
  mixins: [Mixins],
  props: {
    practiceTestSetId: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data () {
    return {
      exportTypes: [
        {
          type: "pdf",
          name: "One PDF with all score reports" 
        },
        {
          type: "zip",
          name: "Zip file with PDF score report for each student"
        }
      ],
      sortOrders: [
        {
          type: "name",
          name: "Last Name" 
        },
        {
          type: "id",
          name: "Student ID"
        }
      ],
      selectedExportType: null,
      selectedSortOrder: null,
      message: '',
      operationDone: false
    }
  },
  methods: {
    selectExportType(value) {
      this.selectedExportType = value
    },

    selectSortOrder(value) {
      this.selectedSortOrder = value
    },

    downloadPdf() {
      let params = {
        sort_order: this.selectedSortOrder.type,
        container_type: this.selectedExportType.type
      }
      this.$http.get(`/api/exports/practice_test_sets/${this.practiceTestSetId}/export_scores/pdf`, { params: params }).then(response => {
        // show popup saying wait for email
        this.message = "This will take some time to process. We will email you with a download link when the report is ready."
        this.operationDone = true
      }, (error) => {
        if (error.response.data.error == "There is an ongoing job already.") {
          this.message = "You already have a pending request for this report."
        } else {
          this.message = "Something went wrong. Please try again."  
        }
        this.operationDone = true
      })
    },

    closeModal() {
      $('#bulk-pdf-export-modal').modal('hide')
      this.operationDone = false
    }
  },
  created() {
    this.selectedSortOrder = this.sortOrders[0]
    this.selectedExportType = this.exportTypes[0]
  }
}
</script>

<style scoped lang="scss">
.button-row {
  display: flex;
  justify-content: end;
  padding-top: 15px;
  padding-right: 16px;
}

.download-btn {
  font-size: 14px;

}
</style>
