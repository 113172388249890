<!--
Stop using me, use app/javascript/packs/shared/slotted_table.vue instead
-->
<template>
  <div class="table-responsive">
    <table class="h-table table" :class="{ 'small-table': small, 'numbered-table': rowNumbers }">
      <thead>
        <tr>
          <th
            v-for="(column, cIndx) in columns"
            :key="cIndx"
            :style="{ width: columnWidth(column) }">
            {{ column.display }}
          </th>
        </tr>
      </thead>
      <tbody :class="{ 'no-inset' : small }">
        <tr
          v-for="(entry, eIndx) in data"
          :key="eIndx"
          :class="{ 'clickable-row': clickable }"
          @click="clickable ? openQuestionModal(entry) : ''">
          <td
            v-for="(column, cIndx) in columns"
            :key="cIndx">
            <template v-if="column.action">
              <div class="d-flex justify-content-end">
                <button v-if="renderButtonContent(column)" class="btn btn-sm btn-sm-table btn-outline-primary" @click="column.action(entry)" v-html="column.buttonContentFn(entry)"/>
                <div v-else class="column-button" @click="column.action(entry)">
                  <img src="/images/delete.svg">
                </div>
              </div>
            </template>
            <template v-else-if="renderColumnFunction(column)">
              <div v-html="column['columnFn'](entry)"/>
            </template>
            <template v-else>
              <div v-html="entry[headerColumn(column)]"/>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="tableFooter" class="table-footer">
      <div @click="tableFooterAction">
        <a href=# class="btn btn-sm btn-outline-secondary" title="Send Invite"><span class="font-14">Send Invite </span></a>
      </div>
    </div>
    <app-question-modal
      v-if="clickable"
      id="question-modal"
      :data="selectedEntry"
      :section="section"/>
  </div>
</template>

<script>
import QuestionModal from './parent_student/question_modal.vue'

export default {
  components: {
    'app-question-modal': QuestionModal
  },
  props: {
    clickable: {
      type: Boolean,
      default: false
    },
    rowNumbers: {
      type: Boolean,
      default: false
    },
    section: {
      type: String,
      default: ''
    },
    columns: {
      default: () => [],
      type: Array
    },
    data: {
      default: () => [],
      type: Array
    },
    small: {
      type: Boolean,
      default: false
    },
    tableFooter: {
      type: Boolean,
      default: false
    },
    tableFooterAction: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      selectedEntry: {}
    }
  },
  methods: {
    applyFormatters (data, formatters) {
      if (this.isUndefined(formatters)) { return data }
      formatters.forEach((formatter) => {
        if (formatter == 'currency') { data = this.$options.filters.currency(data) }
        if (formatter == 'decimalToPercent') { data = this.$options.filters.decimalToPercent(data) }
        if (formatter == 'intToPercent') { data = this.$options.filters.intToPercent(data) }
        if (formatter == 'booleanYesNo') { data = this.$options.filters.booleanYesNo(data) }
      })
      return data
    },
    columnWidth (column) {
      return column.width ? column.width + '%' : ''
    },
    headerColumn (column) {
      if (typeof column == 'string' || column instanceof String) { return column }
      if (column.rowOptions) { return '' }
      return column.column || ''
    },
    openQuestionModal (entry) {
      this.selectedEntry = entry
      $('#question-modal').modal('show')
    },
    renderButtonContent (column) {
      return column.buttonContentFn instanceof Function
    },
    renderColumnFunction (column) {
      return column.columnFn instanceof Function
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@styles/variables.scss';
.no-inset {
  box-shadow: none;
}
table {
  margin-bottom: 0;
}
table > * > tr > * {
  vertical-align: middle;
}

tr {
  height: 60px;
  td:nth-child(-n+10), th:nth-child(-n+10) {
    text-align: left;
  }
  &:first-of-type td {
    border-top: none;
  }
}
tr {
  td:nth-child(-n+1) {
    font-weight: bolder;
  }
}

.students-indi-table {
  tr {
    td:nth-child(5) {
      background: #d1dae5;
      font-weight: 600;
      color: #505050;
    }
    td:nth-child(-n+2) {
      font-weight: 600;
    }
  }
}
.students-indi-table th, .students-indi-table td {
  padding-top: 0;
  padding-left: 8px;
  padding-bottom: 0px;
  padding-right: 8px;
}
.questions-table {
  th {
    padding: 15px;
    text-align: left;
  }


  tr {
     th:nth-child(1) {
      width: 10px !important;
    }
    th:nth-child(2) {
      width: 200px !important;
      padding-left: 5px;
    }
    td {
      line-height: 21px;
    }
    td:nth-child(1) {
      padding-left: 10px !important;
      padding-right: 0px !important;
    }
    td:nth-child(2) {
      padding-left: 5px;
    }
  }
}
.btn-sm-table {
  line-height: 0.5;
}
thead {
  tr {
    font-size: 14px;
    font-weight: 600;
  }
}

.table thead th {
  border: none;
}

thead {
  background-color: $primaryColor;
}

table:not(.small-table) {

    th {
      border: none;
      font-size: 16px;
      &:first-child {
        border-radius:5px 0 0 0;
      }
      &:last-child {
        border-radius:0 5px 0 0;
      }
    }

}
td:first-child, th:first-child {
  padding-left: 20px;
}
td:last-child, th:last-child {
  padding-right: 20px;
}

tbody {
  box-shadow: inset 0px 0px 0px 1px #dee2e6;
}
tbody > * {
  font-size: 12px;
}
.column-button {
  min-width: 35px;
  height: 35px;
  padding: 10px;
  border: 1px solid #949494;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  transition: .2s;
  cursor: pointer;
  background-color: #fff;
  font-size: 12px;
  &:hover {
    background-color: #f0f0f0;
  }
  img {
    width: 14px;
    height: auto;
  }
  span {
    margin-left: 5px;
  }
}
.table-footer {
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: $themeLtGray;
  padding: 0 20px;
  width: 100%;
  border-radius: 0 0 5px 5px;
  border-right: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
}

.small-table {
  tr {
    height: 40px;
  }
}

.table-header {
  background-color: #F3F3F3;
  height: 60px;
  border-top: 1px solid #e8e8e8;
}

.clickable-row {
  cursor: pointer;
  transition: .2s;
  &:hover {
    background-color: #f0f0f0;
  }
}

.numbered-table {
  counter-reset: rowNumber;
  > tbody > tr {
    counter-increment: rowNumber;
    > td:first-child::before {
      content: counter(rowNumber)'.';
      color: $themeOrange;
      font-weight: 700;
    }
  }
}

</style>
