<template>
  <div class="section-text-detail" :id="id">
    <span v-if="instructionalTime">
      <strong>Instructional Time:</strong>
      &nbsp; {{ instructionalTime.split('.')[0] }} Minutes
    </span>
    <br v-if="instructionalTime">
    <span v-if="independentPracticeTime">
      <strong>Independent Practice Time:</strong>
      &nbsp; {{ independentPracticeTime.split('.')[0] }} Minutes
    </span>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: String,
      default: '',
      required: true
    },
    instructionalTime: {
      type: String,
      default: ''
    },
    independentPracticeTime: {
      type: String,
      default: ''
    }
  }
}
</script>
<style scoped lang="scss">
@import '~@styles/variables';
.section-text-detail {
  color: #333333;
  padding-bottom: 15px;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  border-bottom: 1px solid #d8d8d8;
}
</style>
