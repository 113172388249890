<template>
  <div class="container login-padding-top">
    <div class="no-gutters row">
      <div class="bg-white card col-md-5 mx-auto p-4 session--card__border text-left">
        <div class="card-body">
          <div class="no-gutters row">
            <div class="col-md-12 pb-3">
              <h1 class="text-center">
                Activate Account
              </h1>
            </div>
            <div v-if="showFormSuccess" class="col-md-12 mx-auto text-center">
              <div>
                Your account has been activated.
                <br>
                Please set your password.
              </div>
              <br>
              <router-link
                :to="{ name: 'updatePassword', params: { token: token }}"
                tag="button"
                class="br-25 btn btn-theme col-md-12">
                Set Password
              </router-link>
            </div>

            <div v-else-if="showFormFailure" class="col-md-12 mb-3 text-center">
              Your account could not be activated.
              <br>
              Please try clicking the link you received again.
            </div>

            <div v-else class="mb-3">
              <div>
                Searching for account ....
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      token: '',
      showFormSuccess: false,
      showFormFailure: false
    }
  },

  created () {
    this.token = this.$route.params.token
    this.activateAccount()
  },
  methods: {
    activateAccount () {
      this.$http.get(`/api/account/${this.token}/activate`).then(() => {
        this.showFormSuccess = true
      }, () => {
        this.showFormFailure = true
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@styles/variables.scss';

.logoMaxWidth {
  max-width: 80%;
}
.login-padding-top {
  padding-top: 10rem;
}
.highlighter-red {
  border: 2px solid red !important;
}
.session--card__border {
  border-radius: 5px 0px;
}

.btn-theme {
  background-color: $themeOrange;
  color: #fff;
}

.primaryHighlight {
  background-color: $themeOrange;
  border-radius: 5px 5px 0px 0px;
  color: white;
}
</style>
