<template>
  <div>
    <label
      id="schedule-filter-label"
      for="schedule-filter">Schedule Filter:</label>
    <select
      id="schedule-filter"
      v-model="selectedScheduleId"
      class="btn"
      @change="change">
      <option
        v-for="(scheduleOption, idx) in scheduleOptions"
        :key="idx"
        :value="scheduleOption.id">
        {{ scheduleOption.name }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    scheduleOptions: {
      type: Array,
      required: true
    },
    initialScheduleId: {
      required: true
    },
    initialStudents: {
      type: Array,
      required: true
    },
    meta: {
      type: Object,
      default () {
        return {}
      }
    },
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      enrollmentList: {},
      enrollmentCache: {},
      selectedScheduleId: null
    }
  },
  watch: {
    initialScheduleId () {
      this.selectedScheduleId = this.initialScheduleId
    },
    initialStudents () {
      this.addToEnrollmentCache(this.selectedScheduleId, this.initialStudents)
    }
  },
  methods: {
    async change () {
      const enrollments = await this.fetchEnrollmentsForSchedule(this.selectedScheduleId)
      const schedule = this.scheduleOptions.find(schedule => schedule.id === this.selectedScheduleId)
      this.$emit('filtered', enrollments, {...schedule})
    },
    async fetchEnrollmentsForSchedule (scheduleId) {
      // if (scheduleId in this.enrollmentCache) {
      //   return this.enrollmentCache[this.selectedScheduleId]
      // }

      let params = {}
      let enrollmentsUrl = "/api/staff/enrollments" 
      if (this.isAdmin) {
        enrollmentsUrl = scheduleId ? `/api/admin/schedules/${scheduleId}/enrollments` : `/api/admin/schools/${this.$route.params.id}/enrollments`
      } else {
        if (scheduleId) {
          params.filters = { schedule: scheduleId }
        }
      }
      if (!_.isEmpty(this.meta)) {
        params.page = 1
        params.per_page = this.meta.perPage
        params.sort = this.meta.sort
        params.order = this.meta.order
      }
      const { data } = await this.$http.get(enrollmentsUrl, {
        params:params
      })
      const enrollments = data.enrollments
      this.addToEnrollmentCache(this.selectedScheduleId, enrollments)
      if (_.isEmpty(this.meta)) {
        return enrollments
      } else {
        const meta = data.meta
        return {
          enrollments: enrollments,
          meta: meta
        }
      }
    },
    addToEnrollmentCache (scheduleId, enrollments) {
      this.enrollmentCache[scheduleId] = enrollments
      // NOTE: Possible Optimization
      //  If enrollments to add is for entire school, we can just
      //  extract enrollments for other uncached schedules
      //  Conversely if last schedule is added, we can assemble
      //  the Entire School's enrollments
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@styles/variables';

#schedule-filter-label {
  margin: auto;
  font-weight: 600;
  font-size: 12px;
  color: #345165;
}

#schedule-filter {
  margin-left: 5px;
  width: 200px;
  border: 1px solid #cecece;
  font-size: 12px;
}

@media screen and (max-width: $mobileScreenExtraLarge) {
  #schedule-filter {
    width: auto;
  }
}

@media screen and (max-width: $mobileScreenLarge) {
  #schedule-filter-label {
    display: block;
  }

  #schedule-filter {
    display: block;
    margin-left: 0px;
  }
}

@media screen and (max-width: $mobileScreenExtraSmall) {
  #schedule-filter {
    width: 100%;
  }
}
</style>
