<template>
  <div>
    <div v-show="isBlank(attachment.displayData)" :class="alignmentClasses">
      <p :class="{ 'highlighter-red': $v.attachment.displayData.$error }">
        {{ title }}
      </p>
      <input type="file" class="file-input" style="display: none" :accept="fileType" @change="onImageFileSelected">
      <button type="button" class="btn btn-outline-primary" @click.prevent="openPickFileWindow">
        {{ chooseFileButtonText }}
      </button>
    </div>
    <div v-if="displayPreview" v-show="!isBlank(attachment.displayData)" :class="alignmentClasses">
      <div class="col-md-12 mb-1 row" :class="alignmentClasses">
        <div class="column-button" @click.prevent="removeImageFileFunction">
          <img src="/images/delete.svg">
        </div>
        <div v-if="downloadOptions.downloadable" class="column-button" @click.prevent="downloadFile">
          <img src="/images/download.svg">
        </div>
      </div>
      <object v-if="fileExtension == 'pdf' && pdfOptions.display" :data="attachment.displayData" type="application/pdf" :width="pdfOptions.width" :height="pdfOptions.height">
        alt : <a :href="attachment.file.url">test.pdf</a>
      </object>
      <img v-if="fileExtension != 'pdf'" :src="attachment.displayData" class="file-card-image" :class="alignmentClasses">
      <br>
    </div>
    <div v-else v-show="!isBlank(attachment.displayData)">
      File Uploaded: [{{ attachment.fileName }}]
      <br>
      <button v-if="downloadOptions.downloadable" class="btn btn-outline-primary" @click.prevent="downloadFile">
        {{ downloadOptions.buttonText }}
      </button>
    </div>
  </div>
</template>

<script>
import Mixins from '@submodules/mixins/mixin.js'
import { saveAs } from 'file-saver'
import { requiredIf } from 'vuelidate/lib/validators'

export default {
  mixins: [Mixins],

  props: {
    requiredFileUpload: { default: false, type: Boolean},
    pdfOptions: {
      type: Object,
      required: true,
      default () {
        return {
          display: false,
          height: 200,
          width: 300
        }
      }
    },
    downloadOptions: {
      type: Object,
      required: false,
      default () {
        return {
          buttonText: 'Download',
          downloadable: false,
          type: '',
          filename: ''
        }
      }
    },
    attachmentObject: {
      type: Object,
      required: true,
      default () {
        return {
          displayData: '',
          imageFile: '',
          imageFileName: ''
        }
      }
    },
    removeString: { default: 'Remove image', type: String },
    alignmentClasses: { default: 'mr-auto text-left', type: String },
    uniqueIndx: { default: 'New', type: String },
    title: { default: 'Add a new attachment', type: String },
    displayPreview: { default: true, type: Boolean },
    chooseFileButtonText: { default: 'Choose File', type: String },
    fileType: { default: 'image/*', type: String }
  },
  data () {
    return {
      fileExtension: '',
      regexCheckExtension: /(?:\.([^.]+))?$/,
      attachment: {
        displayData: '',
        imageFile: '',
        imageFileName: ''
      },
      removeImageFile: false
    }
  },
  validations: {
    attachment: {
      displayData: {
        required: requiredIf(function () { return this.requiredFileUpload })
      }
    }
  },
  watch: {
    attachmentObject: {
      immediate: true,
      deep: true,
      handler (newAttachedObject) {
        this.attachment = this.deepCloneObject(newAttachedObject)
        if (this.attachment.file && !this.isBlank(this.attachment.file.url)) {
          this.attachment.displayData = this.attachment.file.url
          this.fileExtension = this.regexCheckExtension.exec(this.attachment.file.url)[1]
        } else {
          this.fileExtension = this.regexCheckExtension.exec(this.attachment.fileName)[1]
        }
      }
    }
  },
  methods: {
    downloadFile () {
      saveAs(this.attachment.displayData, this.downloadOptions.filename)
    },
    onImageFileSelected (event) {
      if (event.target.files.length > 0) {
        this.attachment.file = event.target.files[0]
        this.attachment.fileName = this.attachment.file.name

        this.removeImageFile = false

        const reader = new FileReader()
        const vm = this
        reader.onload = (e) => {
          vm.attachment.displayData = e.target.result
          vm.$emit('fileChanged', vm.attachment)
        }
        reader.readAsDataURL(this.attachment.file)
      }
    },

    openPickFileWindow (event) {
      $(event.target.parentNode).find('.file-input')[0].click()
    },

    removeImageFileFunction () { // eslint-disable-line no-unused-vars
      this.attachment.file = ''
      this.attachment.fileName = ''

      this.attachment.displayData = ''
      this.removeImageFile = true
      this.$emit('fileChanged', this.attachment)
    },
    touch () {
      this.$v.$touch()
    },
    invalid () {
      return this.$v.$invalid
    }
  }
}
</script>

<style scoped lang="scss">
.file-card-image {
  object-fit: contain;
  max-height: 13rem;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.column-button {
  min-width: 35px;
  height: 35px;
  padding: 10px;
  border: 1px solid #949494;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  transition: .2s;
  cursor: pointer;
  background-color: #fff;
  font-size: 12px;
  &:hover {
    background-color: #f0f0f0;
  }
  img {
    width: 14px;
    height: auto;
  }
  span {
    margin-left: 5px;
  }
}
</style>
