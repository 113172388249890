import ParentDashboard from '@parent/dashboard/show.vue'
import ParentAssessmentResults from '@parent/assessment_results/show.vue'
import ParentCourseSchedule from '@parent/course_schedule/show.vue'
import ParentScoreReport from '@parent/score_report/show.vue'

export default [
  {
    path: '/parent',
    component: ParentDashboard,
    name: 'ParentDashboard',
    meta: { guestView: false }
  },
  {
    path: '/parent/assessment_results',
    component: ParentAssessmentResults,
    name: 'ParentAssessmentResults',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Dashboard', routerOptions: { name: 'ParentDashboard' } },
        { text: 'Assessment Results' }
      ]
    }
  },
  {
    path: '/parent/course_schedule',
    component: ParentCourseSchedule,
    name: 'ParentCourseSchedule',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Dashboard', routerOptions: { name: 'ParentDashboard' } },
        { text: 'Course Schedule' }
      ] }
  },
  {
    path: '/parent/score_report/:studentPracticeTestId',
    component: ParentScoreReport,
    name: 'ParentScoreReport',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Dashboard', routerOptions: { name: 'ParentDashboard' } },
        { text: 'AssessmentResults', routerOptions: { name: 'ParentAssessmentResults' } },
        { text: 'Score Report' }
      ]
    }
  }
]