<template>
  <horizon-modal
    id="assessment-date-modal"
    :modal-title="actionVerbiage + ' ' + assessmentProp.assessmentType + ' Assessment Date'"
    img-src="https://assets.website-files.com/5d6d56640b8020729636d3ce/5d6d56640b80206bae36d41c_Asset%204%402x.png">
    <template v-slot:content>
      <div class="flex-column form m-2 row">
        <h2 class="d-block mb-2">
          Assessment On:
        </h2>
        <div>
          <flat-pickr v-model="targetAssessmentDate.assessmentOn"/>
          <div class="d-flex justify-content-end mt-2">
            <slot name="buttons"/>
            <button
              class="btn btn-primary"
              style="min-width: 110px;"
              @click="saveTargetAssessmentDate">
              Done
            </button>
          </div>
        </div>
      </div>
    </template>
  </horizon-modal>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import Mixins from '@submodules/mixins/mixin.js'
import HorizonModal from '@shared/horizon_modal'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    flatPickr,
    HorizonModal
  },
  mixins: [Mixins],
  props: {
    targetAssessmentDateProp: {
      type: Object,
      default () {
        return {
          assessmentOn: '',
          assessmentId: ''
        }
      }
    },
    assessmentProp: {
      type: Object,
      default () {
        return {
          id: '',
          assessmentType: ''
        }
      }
    }
  },
  data () {
    return {
      actionVerbiage: '',
      targetAssessmentDate: {
        assessmentOn: '',
        assessmentId: ''
      }
    }
  },
  watch: {
    targetAssessmentDateProp: {
      immediate: true,
      handler () {
        this.targetAssessmentDate = this.deepCloneObject(this.targetAssessmentDateProp)
        if (this.isBlank(this.targetAssessmentDate.id)) {
          this.actionVerbiage = 'Create'
        } else {
          this.actionVerbiage = 'Update'
        }
      }
    }
  },
  validations: {
    targetAssessmentDate: {
      assessmentId: { required },
      assessmentOn: { required }
    }
  },
  methods: {
    createTargetAssessmentDate () {
      $('#assessment-date-modal').modal('hide')
      this.$emit('targetAssessmentDateAdded', this.formData())
    },
    monthName (dt) {
      const monthList = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      return monthList[dt.getMonth()]
    },
    formData () {
      return {
        month: this.monthName(new Date(this.targetAssessmentDate.assessmentOn)),
        year: new Date(this.targetAssessmentDate.assessmentOn).getFullYear(),
        assessmentOn: this.targetAssessmentDate.assessmentOn,
        assessmentId: this.assessmentProp.id,
        id: this.targetAssessmentDate.id
      }
    },
    updateTargetAssessmentDate () {
      $('#assessment-date-modal').modal('hide')
      this.$emit('targetAssessmentDateUpdated', this.formData())
    },
    saveTargetAssessmentDate () {
      if (this.targetAssessmentDate.id) {
        this.updateTargetAssessmentDate()
      } else {
        this.createTargetAssessmentDate()
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
