<template>
  <div class="container">
    <div class="content-pane">
      <div class="pane-wrapper">
        <breadcrumbs :breadcrumbs="breadcrumbs"/>
        <confirm-relock-test-section-modal
      v-if="selectedTestSection"
      :selectedTestSectionProp="selectedTestSection"
      @relockedTestSection="relockTestSection(selectedTestSection.id)"/>
      <!--ASSESSMENT SCHEDULES HEADER-->
        <div class="content-heading underlined " style="padding-bottom: 25px;">
          <h1 class="pull-left"> Online Assessment Administration </h1>
        </div>
      <!--TABS-->
        <div class="pane-section-wrapper">
          <div class="report-wrapper">
            <div class="tabs-row">
              <div
                v-for="(item, index) in onlineAssessmentSchedules"
                :key="index"
                class="tab"
                :class="{ 'selected': item.selected }"
                @click="selectAssessmentSchedule(item, index)">
                <div>{{ item.name }}</div>
              </div>
            </div>
          </div>
        <!--TAB PANE-->
          <!--ASSESSMENT SECTIONS-->
          <br>
          <div class="content-heading underlined" style="padding-bottom: 15px;">
            <h1 class="pull-left"> {{ activeSchedule.name }}</h1>
            <div class="pull-right">
              <h1 class="d-inline text-orange">{{ activeSchedule.studentsEnrolled }}</h1>
              <h1 class="d-inline fw-400">Students Enrolled</h1>
            </div>
          </div>
          <div
            v-for="(item, index) in activeOnlineAssessmentSchedule.test_sections"
            :key="index"
            class="card mb-3">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-2">
                  <!--TIME-->
                    <h5 v-if="!untimedLearnosityAssessment"
                      class="practice-assessment-time">
                      <strong class="bold-text">{{ item.duration_in_minutes }}</strong>
                      <br>
                      <small>Min</small>
                    </h5>
                  </div>
                  <div class="col-md-6">
                    <!--TITLE-->
                    <h5 class="practice-assessment-title">
                      <strong class="bold-text">{{ item.name }} </strong>
                      <br>
                      <small>{{ item.questions_count }} Questions</small>
                    </h5>
                  </div>
                <!--LOCK STATE-->
                  <div class="col-md-4 practice-assessment-action mt-3 pr-4">
                    <button v-if="isReleased(item)"
                      class="btn btn-outline-primary float-right"
                      :disabled="!canUpdateSchedule">
                      <img src="/images/release_button.png" class="btn-icon">
                      &nbsp;
                      Released
                    </button>
                    <button v-if="isReleased(item)"
                      class="btn btn-outline-danger float-right"
                      @click="openConfirmRelockTestSectionModal(item)"
                      :disabled="!canUpdateSchedule">
                      <span class="fa fa-lock btn-icon"></span>
                      &nbsp;
                      Relock
                    </button>
                    <button v-else
                      class="btn btn-outline-secondary pr-4 float-right"
                      @click="releaseOnlineAssessmentSchedule(item.id)"
                      :disabled="!canUpdateSchedule">
                      <img src="/images/lock.png" class="btn-icon">
                      &nbsp;
                      Locked
                    </button>
                  </div>
                </div>
              </div>
              <div class="card-footer show-question-and-answer-row">
                <div class="row">
                  <div class="col-md-10">
                    <p class="show-question-and-answer pt-3"> Reveal Question and Answer List on Student Score Report </p>
                  </div>
                  <div class="col-md-2">
                    <label id="online-assessment-schedule-show-question-and-answer" class="m-2 switch">
                      <input
                        :disabled="!canUpdateSchedule"
                        type="checkbox"
                        v-model="item.show_question_and_answer"
                        class="default"
                        @click="updateShowQuestionAndAnswer(item.id)"
                      />
                      <span class="round slider text-left" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
         <!--STUDENT LISTING-->
          <div class="card">
            <div class="bg-white card-header">
              <div class="content-heading underlined " style="padding-top: 15px; padding-bottom: 15px;">
                <h1 class="pull-left"> Status Monitoring - {{ activeOnlineAssessmentSchedule.name }} </h1>
              </div>
            </div>
            <template v-if="loadingData">
                <div class="col-12">
                  <common-loading/>
                </div>
              </template>
            <template v-else>
            <studentTable
                :student-responses="onlineAssessments"
                :show-pagination="showPagination"
                :meta="meta"
                :state-change-callback="updateTable"
                :can-update-schedule="canUpdateSchedule"
                @fetch-online-assessments = "fetchOnlineAssessments"
                @paginate-data="paginateData"
                @sort-data="sortData"/>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumbs from '@shared/breadcrumbs'
import StudentTable from './student_table.vue'
import CommonLoading from '@shared/common_loading'
import ConfirmRelockTestSectionModal from './confirm_relock_test_section_modal'
export default {
  components: {
    Breadcrumbs,
    StudentTable,
    CommonLoading,
    ConfirmRelockTestSectionModal
  },
  data () {
    return {
      breadcrumbs: [],
      activeSchedule: {},
      activeOnlineAssessmentSchedule: {},
      onlineAssessmentSchedules: [],
      untimedLearnosityAssessment: true,
      onlineAssessments: [],
      showPagination: false,
      loadingData: false,
      selectedTestSection: {},
      meta: {
        page: 1,
        currentPage: 1,
        perPage: 100,
        totalCount: 0,
        totalPages: 0,
        sort: 'lastName',
        order: 'asc'
      },
      canUpdateSchedule: false,
    }
  },
  created () {
    this.fetchSchedule()
    this.canUpdateSchedule = this.$store.getters.userPermit({controller: 'Schedule', action: 'update'})
  },
  methods: {
    setClass(toggle) {
      if (toggle == true) { return 'show-qa' }
      if (toggle == false) { return 'no-show-qa' }

    },
    updateShowQuestionAndAnswer(id) {
      this.$http.post(`/api/staff/online_assessment_schedules/${id}/show_question_and_answers`).then(() => {
      this.fetchSchedule()
      this.appendSuccess({ message: `Test section updated successfully`, notification: true })
      }, (responseError) => {
        this.appendError({error: responseError, notification: true })
      })
    },
    relockTestSection (id) {
      this.$http.post(`/api/staff/online_assessment_schedules/${id}/relocks`).then(() => {
      this.fetchSchedule()
      this.appendSuccess({ message: `${this.selectedTestSection.name} relocked successfully`, notification: true })
      }, (responseError) => {
        this.appendError({error: responseError, notification: true })
      })
    },
    openConfirmRelockTestSectionModal (testSection) {
      this.selectedTestSection = testSection
      this.$nextTick(() => { $('#confirm-relock-test-section-modal').modal('show') })
    },
    processItem(item) {
      //To-Do: process item release/reset
      //sample item: {item: "hasMathC", student_id: 1}
    },
    isReleased (assessment) {
      if (assessment.status == "released") { return true }
    },
    releaseOnlineAssessmentSchedule (activeOnlineAssessmentScheduleId) {
      this.$http.post(`/api/staff/online_assessment_schedules/${activeOnlineAssessmentScheduleId}/releasings`).then(() => {
        this.appendSuccess({ message: 'released successfully', notification: true })
        this.fetchOnlineAssessmentSchedules()
        this.fetchOnlineAssessments()
        }, (responseError) => {
          this.appendError({error: responseError, notification: true })
        })
    },
    selectAssessmentSchedule (assessment_schedule, index) {
      this.activeOnlineAssessmentSchedule = assessment_schedule
      this.onlineAssessmentSchedules.map((e) => e.selected = false)
      this.onlineAssessmentSchedules[index].selected = true
      this.$router.push({ name: 'StaffOnlineAssessmentScheduleShow', params: { practice_test_set_id: this.activeOnlineAssessmentSchedule.id }} )
      this.meta.page = 1
      this.meta.currentPage = 1
      this.fetchOnlineAssessments()
    },
    fetchSchedule () {
      const scheduleId = this.$route.params.id
      this.$http.get(`/api/staff/schedules/${scheduleId}`).then(response => {
        this.activeSchedule = response.data.schedule
        this.breadcrumbs = [{text: this.activeSchedule.name}]
        this.fetchOnlineAssessmentSchedules()
        this.fetchOnlineAssessments()
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    },
    fetchOnlineAssessments() {
      this.loadingData = true
      this.$http.get(`/api/staff/schedules/${this.activeSchedule.id}/practice_test_sets/${this.$route.params.practice_test_set_id}/online_assessments`, {
        params: {
          page: this.meta.currentPage,
          per_page: this.meta.perPage,
          sort: this.meta.sort,
          order: this.meta.order,
        }
      }).then(response => {
        this.onlineAssessments = response.data.onlineAssessments
        this.meta = response.data.meta
        this.showPagination = true
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
        this.loadingData = false
    },
    fetchOnlineAssessmentSchedules () {
      this.$http.get(`/api/staff/schedules/${this.activeSchedule.id}/online_assessment_schedules`).then(response => {
        const practiceTestSetId = this.$route.params.practice_test_set_id
        let practice_test_sets = response.data.practice_test_sets
        let hasSelected = false
        practice_test_sets.map((e, i) => {
          if (e.id == practiceTestSetId) {
            e.selected = true
            this.activeOnlineAssessmentSchedule = e
            hasSelected = true
          } else {
            e.selected = false
          }
          e.test_sections.sort((a, b) => (a.sort_order - b.sort_order))
          return e
        })
        if (!hasSelected) {
            practice_test_sets[0].selected = true
            this.activeOnlineAssessmentSchedule = this.onlineAssessmentSchedules[0]
        }
        this.onlineAssessmentSchedules = practice_test_sets
        this.untimedLearnosityAssessment = response.data.untimed_learnosity_assessment
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    },
    updateTable() {

    },
    paginateData(meta) {
      this.meta.currentPage = meta.currentPage
      this.fetchOnlineAssessments()
    },
    sortData(sortOptions) {
      this.meta.sort = sortOptions.field
      this.meta.order = sortOptions.direction
      this.fetchOnlineAssessments()
    }
  }
}
</script>
<style scoped lang="scss">
@import '~@styles/variables';
  .assessment-schedules-header {
    display: flex;
    height: auto;
    margin-top: 10px;
    margin-bottom: 30px;
    align-items: center;
	  justify-content: space-between;
	  flex-wrap: nowrap;
  }
  .total-students {
    display: inline-block;
    margin: 5px 0px 5px 6px;
    clear: none;
    color: #333;
    font-size: 24px;
    font-weight: 400;
    text-align: center;
  }

  .total-students-number {
    display: inline-block;
    color: #f4701f;
    font-size: 28px;
    font-weight: 700;
  }

  .practice-assessment-action {
    & > button {
      font-size: 12px;

      &.btn-outline-secondary {
        & > .btn-icon {
          width: 18px;
          height: auto;
        }
      }
      &.btn-outline-primary {
        & > .btn-icon {
          width: 18px;
          height: auto;
        }
      }
    }
  }

  .practice-assessment-title {
    margin: 10px 5px 0 0;
    color: #345165;
    font-size: 24px;
    line-height: 28px;
    font-weight: 400;
    text-align: left;
    padding: 0;

    & > small {
      font-size: 16px;
      color: #505050;
    }
  }

  .practice-assessment-time {
    margin: 2px 15px 2px 0;
    color: #345165;
    font-size: 24px;
    line-height: 20px;
    font-weight: 400;
    text-align: center;
    border: 1px solid #e8e8e8;
    padding: 13px 15px;
    border-radius: 8px;
    width: 70px;
    height: 70px;

    & > small {
      font-size: 16px;
      color: #505050;
    }
  }

  .student-data-head {
    margin-top: 0px;
    margin-bottom: 0px;
    color: #345165;
    font-size: 20px;
    font-weight: 700;
    margin-top: auto;
    margin-bottom: auto;
  }
  .show-question-and-answer {
    letter-spacing: 1px;
    color: #505050;
    font-weight: 600;
    text-transform: uppercase;
    vertical-align: middle;
    font-size: 12px;
  }
  .show-question-and-answer-row {
    border-top: none;
    padding: 0.25rem 1.25rem;
    background-color: #ececec;
  }
  .no-show-qa {
  }
  .show-qa {
    span .slider {
      background: #345165;
      border: 1px solid #e7e7e7 !important;
    }
  }
</style>
