import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'
import Vuelidate from 'vuelidate'
import axios from 'axios'
import Qs from 'qs' // Axios Dependent
import VueGtag from 'vue-gtag'
import gtag from '@shared/gtag.js'
import GoogleSignIn from '@shared/google_sign_in.js'

Vue.use(Vuex)
Vue.use(Vuelidate)
Vue.use(VueGtag,gtag)
Vue.use(GoogleSignIn)

// Used to send nested objects to server without encoding
axios.defaults.paramsSerializer = function (params) {
  return Qs.stringify(params, {
    arrayFormat: 'brackets',
    encode: false
  })
}

Vue.prototype.$http = axios
import '@node_modules/bootstrap/dist/js/bootstrap.bundle.min.js'
import '@node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css'
import '@node_modules/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
import '@node_modules/jquery/dist/jquery.slim.min.js'
import '@styles/theme.scss'
import '../../javascript/utils/font-awesome.js';
import router from '../../javascript/routes/routes.js'
import store from '../../javascript/store/store'
import '@submodules/filters/filter.js'
import App from './app.vue'
import Mixins from '@submodules/mixins/mixin.js'
Vue.mixin(Mixins)

import HorizonMixins from '@shared/horizon_mixins.js'
Vue.mixin(HorizonMixins)

import "ag-grid-enterprise";
import {LicenseManager} from "ag-grid-enterprise";
LicenseManager.setLicenseKey(process.env.AG_GRID_KEY)

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    el: '#vue_app',
    store,
    router,
    render: h => h(App)
  })
})
