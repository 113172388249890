<template>
  <horizon-modal id="create-student-modal" modal-title="Create Student">
    <template v-slot:content>
      <div class="form">
        <div class="form-row">
          <div class="col-md-6 col-xs-12 form-group student-form-group div-1">
            <input
              id="student-first-name"
              v-model="student.firstName"
              type="text"
              :class="['student-form-input', { 'highlighter-red': $v.student.firstName.$error }]">
            <label class="d-block">Student First Name</label>
          </div>
          <div class="col-md-6 col-xs-12 form-group student-form-group div-2">
            <input
              id="student-last-name"
              v-model="student.lastName"
              type="text"
              :class="['student-form-input', { 'highlighter-red': $v.student.lastName.$error }]">
            <label>Student Last Name</label>
          </div>
        </div>
        <div class="form-row">
          <div class="col-12 form-group student-form-group">
            <input
              id="student-email"
              v-model="student.email"
              type="text"
              :class="['student-form-input', { 'highlighter-red': $v.student.email.$error }]">
            <label>Student Email Address</label>
            <span
              v-if="$v.student.email.$error && !$v.student.email.email"
              class="text-danger ml-2">
              Email address should follow email format
            </span>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-6 col-xs-12 form-group student-form-group div-1">
            <input
              id="student-phone-number"
              v-model="student.phoneNumber"
              type="text"
              class="student-form-input">
            <label>Student Phone Number</label>
          </div>
          <div class="col-md-6 col-xs-12 form-group student-form-group div-2">
            <input
              id="student-id-number"
              v-model="student.studentIdNumber"
              type="text"
              :class="['student-form-input', { 'highlighter-red': $v.student.studentIdNumber.$error }]">
            <label>Student ID Number</label>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-6 col-xs-12 form-group student-form-group div-1">
            <select id="student-schedule"
              v-model="scheduleId"
              :class="['student-form-input', { 'highlighter-red': $v.scheduleId.$error }]">
              <option
                v-for="schedule in scheduleOptions"
                :key="schedule.id"
                :value="schedule.id">
                {{ schedule.name }}
              </option>
            </select>
            <label>Course Schedule</label>
          </div>
          <div class="col-md-6 col-xs-12 form-group student-form-group div-2">
            <select
              id="student-grade-level"
              v-model="student.gradeLevel"
              :class="['student-form-input', { 'highlighter-red': $v.student.gradeLevel.$error }]">
              <option
                v-for="gradeLevel in gradeLevelOptions"
                :key="gradeLevel.value"
                :value="gradeLevel.value">
                {{ gradeLevel.name }}
              </option>
            </select>
            <label>Grade Level</label>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-end">
          <button class="btn btn-primary student-modal-button" @click="submit">
            Create Student
          </button>
        </div>
      </div>
    </template>
  </horizon-modal>
</template>

<script>
import HorizonModal from '@shared/horizon_modal'
import GradeLevel from '@utils/grade_levels'
import { required, email } from 'vuelidate/lib/validators'

export default {
  components: {
    HorizonModal
  },
  props: {
    scheduleOptions: {
      type: Array,
      default () { return [] },
      required: true
    },
    initialScheduleId: {
      type: Number,
      default () { return null },
      required: false
    },
    studentRepository: {
      type: Object,
      required: true
    },
    enrollmentRepository: {
      type: Object,
      required: true
    }
  },
  computed: {
    gradeLevelOptions() {
      return GradeLevel.values()
    }
  },
  validations: {
    student: {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      studentIdNumber: { required },
      gradeLevel: { required }
    },
    scheduleId: { required }
  },
  data () {
    return {
      student: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        studentIdNumber: '',
        gradeLevel: GradeLevel.GRADE_8.value
      },
      scheduleId: this.initialScheduleId != 0? this.initialScheduleId : null, // 0 is for entire school
    }
  },
  methods: {
    async submit () {
      this.$v.$touch()
      if(this.$v.$invalid) {
        return
      }
      let hasValidationError = false
      const enrollment = await this.processEnrollment(this.student, this.scheduleId).catch((error) => {
        if (error.message === "Validation Error") {
          hasValidationError = true
        }
      })
      if (hasValidationError) {
        return
      } else {
        this.$emit('created', enrollment)
        this.$nextTick(() => this.resetData())
      }
    },
    async processEnrollment (studentParams, scheduleId) {
      try {
        const student = await this.studentRepository.create(studentParams)
        const enrollment = await this.enrollmentRepository.create({ scheduleId: this.scheduleId, userId: student.id })
        return enrollment
      } catch (error) {
        if (error.response.status === 422) {
          let errorMessage = []
          _.forEach(error.response.data.details, (detail) => {
            _.forEach(detail, (err) => {
              errorMessage.push(err.message)
            })
          })
          this.appendError({ error: errorMessage, notification: true })
          throw new Error('Validation Error')
        } else {
          // Possibly transfer error notifications on a single plac
          this.appendError({ error: error, notification: true })
          throw error
        }
      }
    },
    resetData () {
      this.student = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        studentIdNumber: '',
        gradeLevel:  GradeLevel.GRADE_8.value
      }
      this.scheduleId = this.initialScheduleId
    }
  }
}
</script>

<style scoped lang='scss'>
@import '~@styles/variables';
@import "~@styles/shared/students/student-form.scss";

@media screen and (max-width: $mobileScreenMedium) {
  #create-student-modal {
    .col-md-6 {
      padding-right: 5px;
      padding-left: 5px;
    }
  }
}

@media screen and (max-width: $mobileScreenExtraSmall) {
  .justify-content-end {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;    
  }
}

div input#student-email {
  text-transform: lowercase
}
</style>
