<template>
  <table class="table table-primary">
    <thead>
      <tr>
        <th class="flex-fill h2 text-align-left text-white">
          Classes Start/End
        </th>
        <th class="h2 text-align-left text-white" scope="col">
          Date
        </th>
        <th class="h2 text-align-center text-white" scope="col">
          Start Time
        </th>
        <th class="h2 text-align-center text-white" scope="col">
          End Time
        </th>
      </tr>
    </thead>
    <tbody>
      <tr id="schedule-class-start">
        <td class="fw-600">
          Classes Start
        </td>
        <td class="fw-400">
          <date-picker
            id="classes-start-meeting-on"
            v-model="classesStart.meetingOn"
            :config="datePickerConfig"
            :class="['horizon-input', 'text-left',
              {'highlighter-red': errors[0] && errors[0].meetingOn.$error}]"/>
        </td>
        <td class="fw-400 text-align-center">
          <date-picker
            id="classes-start-start-at"
            v-model="classesStart.startAt"
            :config="timePickerConfig"
            :class="['horizon-input', 'text-center',
              {'highlighter-red': errors[0] && errors[0].startAt.$error}]"/>
        </td>
        <td class="fw-400 text-align-center">
          <date-picker
            id="classes-start-end-at"
            v-model="classesStart.endAt"
            :config="timePickerConfig"
            :class="['horizon-input', 'text-center',
              {'highlighter-red': errors[0] && errors[0].endAt.$error}]"/>
        </td>
      </tr>
      <tr id="schedule-class-end">
        <td class="fw-600">
          Classes End
        </td>
        <td class="fw-400">
          <date-picker
            id="classes-end-meeting-on"
            v-model="classesEnd.meetingOn"
            :config="datePickerConfig"
            :class="['horizon-input', 'text-left',
              {'highlighter-red': errors[1] && errors[1].meetingOn.$error}]"/>
        </td>
        <td class="fw-400 text-align-center">
          <date-picker
            id="classes-end-start-at"
            v-model="classesEnd.startAt"
            :config="timePickerConfig"
            :class="['horizon-input', 'text-center',
              {'highlighter-red': errors[1] && errors[1].startAt.$error}]"/>
        </td>
        <td class="fw-400 text-align-center">
          <date-picker
            id="classes-end-end-at"
            v-model="classesEnd.endAt"
            :config="timePickerConfig"
            :class="['horizon-input', 'text-center',
              {'highlighter-red': errors[1] && errors[1].endAt.$error}]"/>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import DatePicker from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  components: {
    DatePicker
  },
  props: {
    classes: {
      type: Array,
      required: true
    },
    errors: {
      type: Object,
      required: false,
      default () { return {} }
    }
  },
  data () {
    return {
      datePickerConfig: {
        dateFormat: 'l, F j, Y'
      },
      timePickerConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'h:i K',
        time_24hr: false,
        static: true
      }
    }
  },
  computed: {
    classesStart () {
      if (this.classes.length >= 1) {
        return this.classes[0]
      }
      return { meetingOn: null, startAt: null, endAt: null }
    },
    classesEnd () {
      if (this.classes.length >= 2) {
        return this.classes[1]
      }
      return { meetingOn: null, startAt: null, endAt: null }
    }
  },
  created () {
    switch (this.classes.length) {
    case 0:
      this.classes.push({meetingOn: null, startAt: null, endAt: null })
    case 1:
      this.classes.push({meetingOn: null, startAt: null, endAt: null })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@styles/modules/schedule/schedule_dates_table.scss';
</style>
