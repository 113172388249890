<template>
  <horizon-modal id="instructor-modal" modal-title="Update Instructor">
    <template v-slot:content>
      <div class="form">
        <div class="form-row">
          <div class="col-md-6 col-xs-12 form-group div-1 student-form-group">
            <label for="instructor-first-name" class="d-block">First Name</label>

            <input
            id="instructor-first-name"
            v-model="selectedInstructor.firstName"
            :class="['student-form-input', { 'highlighter-red': $v.selectedInstructor.firstName.$error }]">
          </div>
          <div class="col-md-6 col-xs-12 form-group div-1 student-form-group">
            <label for="instructor-last-name" class="d-block">Last Name</label>
            <input
            id="instructor-last-name"
            v-model="selectedInstructor.lastName"
            type="text"
            :class="['student-form-input', { 'highlighter-red': $v.selectedInstructor.lastName.$error }]">
          </div>
          <div class="col-md-12 col-xs-12 form-group div-1 student-form-group">
            <label for="instructor-email-address" class="d-block">Email Address</label>
            <input
            id="instructor-email-address"
            v-model="selectedInstructor.email"
            type="text"
            :class="['student-form-input', { 'highlighter-red': $v.selectedInstructor.email.$error }]">
          </div>
          <div class="col-md-12 col-xs-12 form-group div-1 student-form-group">
            <label for="instructor-role" class="d-block">Account Type</label>
            <common-dropdown
              :items="instructorRoles"
              :item-display-key="'role'"
              :on-item-select="updateInstructorRole"
              dropdown-class="d-flex dropdown horizon-input justify-content-between no-hover"
              >
              <span class="font-14 text-truncate">
                {{ selectedInstructor.role }}
              </span>
            </common-dropdown>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-end">
          <button class="btn btn-primary student-modal-button" @click="updateInstructor">
            Update Instructor
          </button>
        </div>
      </div>
    </template>
  </horizon-modal>
</template>

<script>
import HorizonModal from '@shared/horizon_modal'
import { required, email } from 'vuelidate/lib/validators'
import CommonDropdown from '@shared/common_dropdown'
export default {
  components: {
    HorizonModal,
    CommonDropdown
  },
  props: {
    selectedInstructor: {
      type: Object,
      default () {
        return {
          firstName: '',
          lastName: '',
          email: '',
          role: '',
        }
      }
    }
  },
  data() {
    return {
      instructorRoles: [
        {role: 'Teacher'},
        {role: 'Teacher Admin'}
      ]
    }
  },
  validations: {
    selectedInstructor: {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      role: { required }
    }
  },
  methods: {
    updateInstructor () {
      this.$v.$touch()
      if(this.$v.$invalid) { return }

      $('#instructor-modal').modal('hide')
      this.$emit('instructorUpdated', this.selectedInstructor)
    },
    updateInstructorRole(role) {
      this.selectedInstructor.role = role.role
    }
  }
}
</script>

<style scoped lang='scss'>
@import "~@styles/shared/students/student-form.scss";
</style>
