 <template>
  <div>
    <schedule-date-modal
      :id="modal.id"
      :schedule-type="modal.type"
      :selectedScheduleDateProp="selectedParentNightDate"
      @scheduleDateUpdated="updateParentNightDate"
      @scheduleDateAdded="addParentNightDate"
    />

    <table id="parent-night-dates-table" class="table table-primary w-100">
      <thead>
        <tr>
          <th class="flex-fill h2 text-align-left text-white">Parent Night</th>
          <th class="h2 text-align-left text-white date-time-separated" scope="col">Date</th>
          <th class="h2 text-align-center text-white date-time-separated" scope="col">Start Time</th>
          <th class="h2 text-align-center text-white date-time-separated" scope="col">End Time</th>
          <th class="h2 text-align-center text-white date-time-combined" scope="col">Date & Time</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(parentNightDate, parentIndx) in activeParentNightDates"
          v-show="!parentNightDate._destroy"
          :key="parentIndx"
        >
          <td class="fw-600">Parent Night #{{ parentIndx + 1 }}</td>
          <td class="fw-400 date-time-separated">{{ parentNightDate.meetingOn| date('dddd, MMMM D, YYYY') }}</td>
          <td class="fw-400 text-align-center date-time-separated">{{ parentNightDate.startAt }}</td>
          <td class="fw-400 text-align-center date-time-separated">{{ parentNightDate.endAt }}</td>
          <td class="text-align-center date-time-combined">
              {{ parentNightDate.meetingOn| date('dddd, MMMM D, YYYY') }}<br />
              {{ parentNightDate.startAt }} - {{ parentNightDate.endAt }}
            </td>
          <td class="action-column">
            <button
              class="btn btn-outline-secondary gray-hover action-btn"
              @click="openEditParentNightDateModal(parentNightDate)"
            >
              <i class="fa fa-lg fa-pencil-alt" />
            </button>
            <button
              class="btn btn-outline-secondary gray-hover action-btn"
              @click="markParentNighDateDeleted(parentNightDate)"
            >
              <i class="fa fa-lg fa-trash" />
            </button>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th colspan="5" class="text-right">
            <button
              class="btn btn-outline-secondary gray-hover"
              @click="openAddParentNightDateModal"
            >
              <i class="fa fa-lg fa-plus-circle" />
              <span class="font-12">Add Parent Night</span>
            </button>
          </th>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import moment from "moment";
import ScheduleDateModal from "@components/modules/schedule/edit/schedule_date_modal";

export default {
  components: { ScheduleDateModal },
  props: {
    parentNightDates: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      selectedParentNightDate: {},
      modal: {
        id: "parent-night-modal",
        type: "Parent Night"
      }
    };
  },
  computed: {
    activeParentNightDates() {
      const activeParentNights = this.parentNightDates.filter(
        parentNightDate => !parentNightDate._destroy
      );
      activeParentNights.sort((a, b) => {
        return moment(a.meetingOn).diff(moment(b.meetingOn));
      });
      return activeParentNights;
    }
  },
  methods: {
    async openAddParentNightDateModal() {
      this.selectedParentNightDate = {};
      $(`#${this.modal.id}`).modal("show");
    },
    openEditParentNightDateModal(classDate) {
      this.selectedParentNightDate = classDate;
      $(`#${this.modal.id}`).modal("show");
    },
    updateParentNightDate(updatedParentNightDate) {
      this.selectedParentNightDate.meetingOn = updatedParentNightDate.meetingOn;
      this.selectedParentNightDate.startAt = updatedParentNightDate.startAt;
      this.selectedParentNightDate.endAt = updatedParentNightDate.endAt;
      this.selectedParentNightDate = {};
    },
    addParentNightDate(parentNightDate) {
      this.parentNightDates.push(parentNightDate);
    },
    markParentNighDateDeleted(targetParentNightDate) {
      if (targetParentNightDate.id) {
        targetParentNightDate._destroy = true;
      } else {
        const index = this.parentNightDates.findIndex(
          parentNightDate => parentNightDate.id == targetParentNightDate.id
        );
        this.parentNightDates.splice(index, 1);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@styles/modules/schedule/edit_schedule_dates_table.scss";
</style>
