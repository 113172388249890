import client from '@repositories/client.js'

const BASE_URL = '/api/staff/schedules'

export default new class {

  async list () {
    const data = await client.get(BASE_URL)
    return data.schedules
  }

  async fetch (scheduleId) {
    const data = await client.get(`${BASE_URL}/${scheduleId}`)
    return data.schedule
  }

  async update(scheduleId, schedule) {
    const requestBody = { ...schedule, schoolId: schedule.schoolId  };
    const data = await client.put(`${BASE_URL}/${scheduleId}`, { schedule: requestBody });
    return data.schedule;
  }
}
