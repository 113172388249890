import Vue from 'vue/dist/vue.esm'
import VueRouter from 'vue-router'
import store from '../store/store'
import { userRoles } from '@utils/userRoles'

Vue.use(VueRouter)

// import SignUp from '@components/session/sign_up.vue'
import AccountActivate from '@components/session/activate.vue'
import Logout from '@components/session/logout.vue'
import SessionLogin from '@components/session/login.vue'
import SessionForgotPassword from '@components/session/forgot_password.vue'
import SessionUpdatePassword from '@components/session/update_password.vue'

const baseRoutes = [
  { path: '/', component: SessionLogin, name: 'login', meta: { displayName: 'Login', guestView: true } },
  // { path: '/signup', component: SignUp, name: 'signUp', meta: { displayName: 'Sign Up', guestView: true } },
  { path: '/logout', component: Logout, name: 'logout', meta: { displayName: 'Logout', guestView: true } },
  { path: '/forgot_password', component: SessionForgotPassword, name: 'forgotPassword', meta: { displayName: 'Forgot Password', guestView: true } },
  { path: '/update_password/:token', component: SessionUpdatePassword, name: 'updatePassword', meta: { displayName: 'Update Password', guestView: true } },
  { path: '/account/:token/activate', component: AccountActivate, name: 'accountActivate', meta: { guestView: true } }
]
import AdminRoutes from './admin.js'
import StudentRoutes from './student.js'
import ParentRoutes from './parent.js'
import StaffRoutes from './staff.js'
import DistrictAdminRoutes from './district_admin.js'

const routes = baseRoutes.concat(AdminRoutes, StudentRoutes, ParentRoutes, StaffRoutes, DistrictAdminRoutes)

const router = new VueRouter({
  routes: routes,
  mode: 'history',
  scrollBehavior: function (to, from, savedPosition) { // eslint-disable-line no-unused-vars
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  }
})


const directToUserDashboard = function (next) {
  if (store.getters.userRole === userRoles.ROLE_PARENT) {
    next({ name: 'ParentDashboard' })
  } else if (store.getters.userRole === userRoles.ROLE_TEACHER_STAFF || store.getters.userRole === userRoles.ROLE_TEACHER_ADMIN) {
    next({ name: 'StaffDashboard' })
  } else if (store.getters.userRole === userRoles.ROLE_STUDENT) {
    next({ name: 'StudentDashboard' })
  } else if (store.getters.userRole === userRoles.ROLE_HORIZON_ADMIN) {
    next({ name: 'AdminEnrollmentList' })
  } else if (store.getters.userRole === userRoles.ROLE_DISTRICT_ADMIN) {
    next({ name: 'DistrictAdminDashboard' })
  }
}

router.beforeEach((to, from, next) => {
  if (to.query.url) {
    next(to.query.url)
  } else if (store.getters.userAuthenticated && to.path == '/') {
    directToUserDashboard(next)
  } else if (to.path == '/') {
    next()
  } else if (to.matched.some(route => route.meta.guestView)) {
    next()
  } else if (store.getters.userAuthenticated) {
    next()
  } else {
    if (to.fullPath == "/login") {
      // don't redirect to login page while on the login page
      next({ name: 'login' })
    } else {
      next({ name: 'login', query: { redirect: to.fullPath } })
    }
  }
})

export default router
