<template>
  <div class="pane-section-wrapper">
    <h1 class="tab-title">
      Individual Score Reports
    </h1>
    <div class="report-wrapper">
      <div class="tabs-row">
        <div
          v-for="(report, rIndx) in assessmentData"
          :key="rIndx"
          class="tab"
          :class="{ 'selected': report.selected }"
          @click="updateReport(report)">
          <div>
            {{ report.name }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="selectedReport" class="tab-pane">
      <div class="tab-header">
        <div class="header-info">
          <h1>{{ selectedReport.name }}</h1>
          <div class="report-date-wrapper">
            <img src="/images/bluecal.png">
            <div class="body-larger">
              Date: <span>{{ selectedReport.date|date('MM/DD/YYYY') }}</span>
            </div>
          </div>
        </div>
        <router-link class="btn btn-outline" :to="{ name: scoreReportRouteProp, params: {studentPracticeTestId: selectedReport.id} }">
          View Score Report
          <img class="btn-icon" src="/images/right-arrow.png">
        </router-link>
      </div>
      <composite-section-scores
        v-if="selectedReport.sections"
        :user-type-prop="userTypeProp"
        :assessment-type-prop="selectedReport.assessmentType"
        :composite-data="selectedReport.compositeScore"
        :section-data="selectedReport.sections"
        :composite-description="compositeDescription"/>
    </div>
  </div>
</template>

<script>
import CompositeSectionScores from '@shared/parent_student/composite_section_scores'

export default {
  components: {
    CompositeSectionScores
  },
  props: {
    scoreReportRouteProp: { type: String, required: true },
    compositeDescription: { type: Boolean, default: false },
    userTypeProp: { type: String, required: true },
    assessmentData: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      selectedReport: {}
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      // get assessment data
      this.updateReport(this.assessmentData[0])
    },

    updateReport (report) {
      this.assessmentData.forEach(function (report) {
        report.selected = false
      })
      report.selected = true
      this.fetchAssessmentScoring(report)
    },

    fetchAssessmentScoring (report) {
      if (!this.isBlank(report.scoreDetails)) {
        this.selectedReport = report.scoreDetails
        return
      }
      this.$http.get(`/api/${this.userTypeProp}/student_practice_tests/${report.id}/score_details`).then((response) => {
        report.scoreDetails = response.data.scoreDetails
        this.selectedReport = report.scoreDetails
      }, (responseError) => {
        this.appendError({error: responseError.body.error})
      })
    }
  }
}
</script>

<style scoped lang="scss">
.score-breakdown-row {
  padding: 20px 0 10px 20px;
  display: flex;
  > .w-50:last-child {
    padding-left: 20px;
    border-left: 1px solid #e8e8e8;
  }
}
</style>
