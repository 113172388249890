<template>
  <div class="full-width no-gutters primary-row row">
    <div class="grid-row">
      <div class="item-number">
        <div>{{ answerCategoryIndxProp }}.</div>
      </div>
      <div class="item-answer">
        <input
          v-model="answerEntry.answers.answer"
          type="text"
          class="form-control"
          @change="emitAnswerRow">
      </div>
      <div class="item-topic">
        <div>
          <label for="formTypeSelect">Topic Area</label>
          <select
            id="formTypeSelect"
            v-model="answerEntry.metaData.topicAreaId"
            class="form-control"
            @change="emitAnswerRow">
            <option v-for="(topicArea, topicAreaIndex) in topicAreasProp" :key="topicAreaIndex" :value="topicArea.id">
              {{ topicArea.letterCode }}
            </option>
          </select>
        </div>
      </div>
      <div class="item-image">
        <div>
          <label for="formTypeSelect">Image</label>
          <app-common-file-upload
            ref="answerUpload"
            :choose-file-button-text="'Upload Image'"
            :required-file-upload="true"
            :attachment-object="answerEntry"
            :alignment-classes="''"
            :title="''"
            :pdf-options="{display: true}"
            :file-type="'image/*,.pdf'"
            :remove-string="'Remove answer attachment'"
            :download-options="{downloadable: true, filename: 'answer'}"
            @fileChanged="fileChangedEmitted"/>
        </div>
      </div>
      <div class="item-difficulty">
        <div>
          <label for="formTypeSelect">Difficulty</label>
          <input
            v-model="answerEntry.metaData.difficulty"
            type="text"
            class="form-control"
            @change="emitAnswerRow">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from '@submodules/mixins/mixin.js'
import CommonFileUpload from '@shared/common_file_upload_horizon.vue'

export default {
  components: {
    'app-common-file-upload': CommonFileUpload
  },
  mixins: [Mixins],
  props: {
    answerCategoryIndxProp: {
      type: [Number, String],
      default: ''
    },
    answerEntryProp: {
      type: Object,
      default () {
        return {
          propObjectAttribute: ''
        }
      }
    },
    inputsSetProp: {
      type: Object,
      default () {
        return { propObjectAttribute: '' }
      }
    },
    subsectionAreasProp: {
      type: Array,
      default: () => []
    },
    topicAreasProp: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      answerEntry: {
        correctAnswer: '',
        topicArea: '',
        displayData: '',
        imageFile: '',
        imageFileName: ''
      }
    }
  },
  watch: {
    answerEntryProp: {
      deep: true,
      immediate: true,
      handler (newAnswerEntryProp) {
        this.answerEntry = this.deepCloneObject(newAnswerEntryProp)
      }
    }
  },
  created () {

  },
  mounted () {

  },
  methods: {
    emitAnswerRow () {
      this.$emit('answerRowUpdated', this.formData())
    },
    fileChangedEmitted (payload) {
      this.answerEntry = payload
      this.emitAnswerRow()
    },
    formData () {
      return {
        id: this.answerEntry.id,
        answerKeyId: '',
        categoryGroupId: '',
        question: '',
        answers: this.formDataAnswerEntryAnswers(),
        metaData: this.formDataAnswerEntryMeta(),
        file: this.answerEntry.displayData
      }
    },
    formDataAnswerEntryAnswers () {
      return {
        answer: this.answerEntry.answers.answer,
        opt1: this.answerEntry.answers.opt1,
        opt2: this.answerEntry.answers.opt2,
        opt3: this.answerEntry.answers.opt3,
        from: this.answerEntry.answers.from,
        to: this.answerEntry.answers.to
      }
    },

    formDataAnswerEntryMeta () {
      return {
        subcoreAreaId: this.answerEntry.metaData.subscoreAreaId,
        subcore1AreaId: this.answerEntry.metaData.subcore1AreaId,
        subscore2AreaId: this.answerEntry.metaData.subscore2AreaId,
        crossTest: this.answerEntry.metaData.crossTest,
        topicAreaId: this.answerEntry.metaData.topicAreaId,
        difficulty: this.answerEntry.metaData.difficulty,
        modeling: this.answerEntry.metaData.modeling
      }
    }
  }
}
</script>

<style scoped lang="scss">
.grid-row {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  > div {
    align-self: center;
  }
  .item-number, .item-answer {
    justify-self: center;
  }
  .item-number {
    grid-row: 1/1;
    grid-column: 1/1;
    font-weight: 700;
  }
  .item-answer {
    grid-row: 1/1;
    grid-column: 2/4;
    input {
      width: 60px;
    }
  }
  .item-topic {
    grid-row: 1/1;
    grid-column: 4/9;
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    select {
      width: 50%;
    }
  }
  .item-image {
    grid-row: 2/2;
    grid-column: 4/9;
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .item-difficulty {
    grid-row: 1/1;
    grid-column: 10/12;
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    input {
      width: 60px;
    }
  }
  label {
    color: #505050;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 0;
    text-transform: uppercase;
    font-size: 12px;
  }
}
</style>
