<template>
  <staff-act-student-results-pane
    :is-horizon-admin="true"/>
</template>

<script>
import StaffActStudentResultsPane from '@staff/shared/act_student_results_pane'

export default {
  components: {
    StaffActStudentResultsPane,
  },
}
</script>