 <template>
  <div>
    <practice-test-date-modal
      :id="modal.id"
      :schedule-type="modal.type"
      :selectedScheduleDateProp="selectedPracticeTestDate"
      :practiceTestSets="practiceTestSets"
      @scheduleDateUpdated="updatePracticeTestDate"
      @scheduleDateAdded="addPracticeTestDate"
    />
    <table id="practice-test-dates-table" class="table table-primary w-100">
      <thead>
        <tr>
          <th class="flex-fill h2 text-align-left text-white">Assessment</th>
          <th class="h2 text-align-left text-white date-time-separated" scope="col">Date</th>
          <th class="h2 text-align-center text-white date-time-separated" scope="col">Start Time</th>
          <th class="h2 text-align-center text-white date-time-separated" scope="col">End Time</th>
          <th class="h2 text-align-center text-white date-time-combined" scope="col">Date & Time</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <template
          v-for="(practiceAssessmentDate, practiceAssessmentIndx) in activePracticeTestDates"
        >
          <tr v-show="!practiceAssessmentDate._destroy" :key="practiceAssessmentIndx">
            <td class="fw-600">
              Practice Assessment #{{ practiceAssessmentIndx + 1 }}
            </td>
            <td class="fw-400 date-time-separated">{{ practiceAssessmentDate.meetingOn| date('dddd, MMMM D, YYYY') }}</td>
            <td class="fw-400 text-align-center date-time-separated">{{ practiceAssessmentDate.startAt }}</td>
            <td class="fw-400 text-align-center date-time-separated">{{ practiceAssessmentDate.endAt }}</td>
            <td class="text-align-center date-time-combined">
              {{ practiceAssessmentDate.meetingOn| date('dddd, MMMM D, YYYY') }}<br />
              {{ practiceAssessmentDate.startAt }} - {{ practiceAssessmentDate.endAt }}
            </td>
            <td class="action-column">
              <button
                class="btn btn-outline-secondary gray-hover action-btn"
                @click="openEditPracticeTestDateModal(practiceAssessmentDate)"
              >
                <i class="fa fa-lg fa-fw fa-pencil-alt" />
              </button>
              <button
                class="btn btn-outline-secondary gray-hover action-btn"
                @click="markPracticeTestDateDeleted(practiceAssessmentDate)"
              >
                <i class="fa fa-lg fa-trash" />
              </button>
            </td>
          </tr>
        </template>
      </tbody>
      <tfoot>
        <tr>
          <th colspan="5" class="text-right">
            <button
              class="btn btn-outline-secondary gray-hover"
              @click="openAddPracticeTestDateModal"
            >
              <i class="fa fa-lg fa-plus-circle" />
              <span class="font-12">Add Assessment</span>
            </button>
          </th>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import moment from "moment";
import { userRoles } from '@utils/userRoles'

import PracticeTestDateModal from "@components/modules/schedule/edit/practice_test_date_modal";
export default {
  components: {
    PracticeTestDateModal,
  },
  props: {
    schedule: {
      type: Object,
      required: true
    },
    practiceTestDates: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    },
    practiceTestRepository: {
      type: Object,
      required: false,
      default() { return null; }
    }
  },
  data() {
    return {
      modal: {
        id: "practice-assessment-date-modal",
        type: "Practice Assessment"
      },
      practiceTestSets: [],
      selectedPracticeTestDate: {}
    };
  },
  watch: {
    schedule: {
      immediate: true,
      handler() {
        if (Object.keys(this.schedule).length > 0) {
            const path = `/api/assessment_courses/${this.schedule.assessmentCourseId}/practice_test_sets`
            this.$http.get(path).then(response => {
              this.practiceTestSets = response.data.practiceTestSets;
            });
        }
      }
    }
  },
  computed: {
    activePracticeTestDates() {
      const activePracticeTests = this.practiceTestDates.filter(
        practiceTest => !practiceTest._destroy
      );
      activePracticeTests.sort((a, b) => {
        return moment(a.meetingOn).diff(moment(b.meetingOn));
      });
      return activePracticeTests;
    },
    allowImport () {
      return this.$store.getters.userRole == userRoles.ROLE_HORIZON_ADMIN
    }
  },
  methods: {
    async openAddPracticeTestDateModal() {
      this.selectedPracticeTestDate = {};
      $(`#${this.modal.id}`).modal("show");
    },
    openEditPracticeTestDateModal(practiceTestDate) {
      this.selectedPracticeTestDate = practiceTestDate;
      $(`#${this.modal.id}`).modal("show");
    },
    updatePracticeTestDate(updatedTestDate) {
      this.selectedPracticeTestDate.meetingOn = updatedTestDate.meetingOn;
      this.selectedPracticeTestDate.startAt = updatedTestDate.startAt;
      this.selectedPracticeTestDate.endAt = updatedTestDate.endAt;
      this.selectedPracticeTestDate.practiceTestSetId = updatedTestDate.practiceTestSetId;
      this.selectedPracticeTestDate = {};
    },
    addPracticeTestDate(practiceTestDate) {
      this.practiceTestDates.push(practiceTestDate);
    },
    markPracticeTestDateDeleted(practiceTestDate) {
      if (practiceTestDate.id) {
        practiceTestDate._destroy = true;
      } else {
        const index = this.practiceTestDates.findIndex(
          testDate => testDate.id == practiceTestDate.id
        );
        this.practiceTestDates.splice(index, 1);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@styles/modules/schedule/edit_schedule_dates_table.scss";
</style>
