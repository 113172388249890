<template>
  <div :class="containerClass" class="svg-container">
    <div
      style="position: absolute; width: 140px;height: 80px;border: 1px solid #cccccc;color: #333333;pointer-events: none; background-color: white;"
      :style="{ top: toolY, left: toolX, opacity: tooltipVisible }">
      <div style="text-align: center; padding-top: 10px;">
        <p style="margin-bottom: 0; font-weight: 700">
          {{ toolValue }}
        </p>
        <p style="font-size: .75rem; color:#777777;margin-bottom: 0;">
          <i>{{ toolLabel }}</i>
        </p>
      </div>
    </div>

    <svg
      :id="chartId"
      class="horizontal-bar-chart"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:svg="http://www.w3.org/2000/svg"/>
  </div>
</template>

<script>
import * as d3 from 'd3'

export default {
  props: {
    benchmarkPlacement: {
      type: Number,
      required: false
    },
    includeBenchmark: {
      type: Boolean,
      default: false
    },
    chartId: {
      type: String,
      required: true
    },
    mute: {
      type: Boolean,
      required: false,
      default: false
    },
    dataSet: {
      type: Array,
      required: true
    },
    gridLineColor: {
      type: String,
      default: '#E4E4E4'
    },
    tickRotation: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: () => '#F4701F'
    }
  },
  data () {
    return {
      properties: {},
      width: 0,
      height: 0,
      tooltipVisible: 0,
      toolX: '1px',
      toolY: '1px',
      toolValue: '',
      toolLabel: '',
      margin: {top: 0, right: 80, bottom: 50, left: 100}
    }
  },
  computed: {
    containerClass () {
      return this.chartId + 'Container'
    }
  },
  watch: {
    dataSet: {
      handler: function () {
        this.smartRender()
      },
      deep: true
    }
  },
  mounted () {
    this.smartRender()
    window.addEventListener('resize', this.onWindowResized)
  },
  destroyed () {
    window.removeEventListener('resize', this.onWindowResized)
  },
  methods: {
    onWindowResized () {
      this.smartRender()
    },

    smartRender () {
      this.setupDimensions()
      // const defaultBarWidth = 25 // TODO: likely need to make this configurable
      // const maxItemCount = Math.round( this.height/defaultBarWidth )
      // let filteredData = []
      // for( let i = 0; i < maxItemCount && i < this.dataSet.length; i++ ) {
      // filteredData.push( this.dataSet[i] )
      // }

      // this.render( filteredData )
      this.render(this.dataSet)
    },

    setupDimensions () {
      const chartContainer = d3.select('.' + this.containerClass)
      const containerWidth = chartContainer.node() ? chartContainer.node().getBoundingClientRect().width : false
      const containerHeight = chartContainer.node() ? chartContainer.node().getBoundingClientRect().height : false

      this.properties.containerWidth = containerWidth
      this.properties.containerHeight = containerHeight

      this.width = containerWidth - this.margin.left - this.margin.right
      this.height = containerHeight - this.margin.top - this.margin.bottom
      this.properties.width = this.width
      this.properties.height = this.height
    },

    render (data) {
      this.properties.count = data.length
      this.setupDimensions()

      const svg = d3.select('#' + this.chartId).html('')
        .attr('width', this.width + this.margin.left + this.margin.right)
        .attr('height', this.height + this.margin.top + this.margin.bottom)

      const x = d3.scaleLinear().range([0, this.width])

      const y = d3.scaleBand().range([this.height, 0])

      const container = svg.append('g')
        .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')')

      x.domain([0, d3.max(data, function (d) {
        return d.value
      })])

      y.domain(data.map(function (d) {
        return d.name
      }))

      this.renderHorizontalAxis(container, x)
      this.renderVerticalAxis(container, y)
      this.renderBackgroundLines(container, x)
      const bars = container.selectAll('.bar')
        .data(data)
        .enter()
        .append('g')
      this.renderBars(bars, x, y)
      // this.renderBarLabels(bars, x, y)
      this.properties.bandwidth = y.bandwidth()
      this.properties.measuredWidth = this.properties.count * this.properties.bandwidth
      if (this.includeBenchmark) {
        this.renderBenchmarkLines(container)
      }
   },

    renderHorizontalAxis (container, x) {
      const xAxis = container.append('g')
        .attr('class', 'axis axis-x')
        .attr('transform', 'translate(0,' + this.height + ')')
        .call(d3.axisBottom(x).ticks(4))

      // xAxis
      // .select('.domain')
      // .remove()

      const tickRotation = this.tickRotation
      xAxis.selectAll('.tick text')
        .attr('transform', function () {
          return `translate(${-this.getBBox().height + ((90 - tickRotation) / 90) * (this.getBBox().height)},${this.getBBox().height / (90 / tickRotation)}) rotate(-${tickRotation})`
        })
    },

    renderVerticalAxis (container, y) {
      container.append('g')
        .attr('class', 'axis axis-y')
        .call(d3.axisLeft(y))
        // .select(".domain")
        // .remove()
    },
    renderBackgroundLines (container, x) {
      const axisData = x.ticks()

      container.append('g')
        .selectAll('g')
        .data(axisData)
        .enter().append('line')
        .attr('y1', () => 10)
        .attr('y2', () => this.height)
        .attr('x1', (d) => x(d) * 2)
        .attr('x2', (d) => x(d) * 2)
        .attr('stroke', this.gridLineColor)
    },
    renderBenchmarkLines (container) {
      container.append('line')
        .attr('class', 'dashed')
        .attr('y1', this.height * this.benchmarkPlacement)
        .attr('y2', this.height * this.benchmarkPlacement)
        .attr('x1', 0)
        .attr('x2', this.width + this.margin.right)
        .attr('stroke', '#000')

      container.append('text')
        .attr('class', 'benchmark-text')
        .attr('y', (this.height * this.benchmarkPlacement) - 8)
        .attr('x', this.width + 10)
        .style('font-weight', '600')
        .style('font-size', '11px')
        .text('Benchmark')
    },

    renderBars (container, x, y) {
      const component = this
      const barColor = this.color
      const setColor = function (bar) {
        if (bar.aboveBenchmark === false) { return "#CECECE" }
        return barColor
      }
      container.append('rect')
        .attr('class', 'bar')
        .attr('x', 0)
        .attr('y', function (d) {
          return y(d.name) + 2
        })
        .attr('width', (d) => x(d.value))
        .attr('height', y.bandwidth() - 5)
        .style("fill", function(d) { return setColor(d) })
        .on('mouseover', () => {
          if (!this.mute) {
            component.tooltipVisible = 1
          }
        })
        .on('mouseout', () => {
          component.tooltipVisible = 0
        })
        .on('mousemove', function (d) {
          const mouseEvent = d3.mouse(this)
          // let x0 = x.invert(mouseEvent[0])
          component.toolValue = d3.format(',')(d.value)

          component.toolLabel = d.name

          const xValue = mouseEvent[0]
          const yValue = mouseEvent[1]
          component.toolY = '' + (yValue - 20) + 'px'
          component.toolX = '' + (xValue + 20) + 'px'
        })
    }
    },

    renderBarLabels (container, x, y) {
      container.append('text')
        .attr('class', 'chart-label')
        .attr('y', function (d) {
          return y(d.name) + y.bandwidth() / 2 + 4
        })
        .attr('x', function (d) {
          return x(d.value) + 10
        })
        .style('font-weight', 'bold')
        .text(function (d) {
          return d.name
        })
  }
}
</script>

<style lang="scss">
</style>
