import client from '@repositories/client.js'

const BASE_URL = '/api/staff/instructors'

export default new class {

  async list() {
    const data = await client.get(BASE_URL)
    return data.users
  }
}
