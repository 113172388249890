// DEPRECATED: Use app/javascript/packs/components/modules/enrollment/student_table.vue instead
import moment from 'moment'

const LAST_NAME = 'lastName'
const FIRST_NAME = 'firstName'
const SCHEDULE_NAME = 'scheduleName'
const GRADE_LEVEL = 'gradeLevel'
const SIGNUP_DATE= 'signupDate'

const ASCENDING = 'asc'
const DESCENDING = 'desc'

const _accessorMap = {}
_accessorMap[LAST_NAME] = (enrollment) => enrollment.user.lastName
_accessorMap[FIRST_NAME] = (enrollment) => enrollment.user.firstName
_accessorMap[SCHEDULE_NAME] = (enrollment) => enrollment.scheduleName
_accessorMap[GRADE_LEVEL] = (enrollment) => enrollment.user.gradeLevel
_accessorMap[SIGNUP_DATE] = (enrollment) => moment(enrollment.signupOn)
const accessorMap = Object.freeze(_accessorMap)

function stringComparator (string1, string2) {
  return string1.localeCompare(string2)
}
const _comparatorMap = {}
_comparatorMap[FIRST_NAME] = stringComparator
_comparatorMap[LAST_NAME] = stringComparator
_comparatorMap[SCHEDULE_NAME] = stringComparator
_comparatorMap[GRADE_LEVEL] = (number1, number2) => number1 - number2
_comparatorMap[SIGNUP_DATE] = (moment1, moment2) => moment1.diff(moment2)
const comparatorMap = Object.freeze(_comparatorMap)


export default class {

  static get fields () {
    return { LAST_NAME,FIRST_NAME, SCHEDULE_NAME, GRADE_LEVEL, SIGNUP_DATE }
  }
  static get directions () {
    return { ASCENDING, DESCENDING }
  }


  constructor () {
    this.sortOptions = {field: LAST_NAME, direction: ASCENDING}
  }

  sort (enrollments) {
    const attributeGetter = accessorMap[this.sortOptions.field]
    const comparator = comparatorMap[this.sortOptions.field]

    return enrollments.slice().sort( (enrollment1,enrollment2) => {
      const value1 = attributeGetter(enrollment1)
      const value2 = attributeGetter(enrollment2)

      if (this.sortOptions.direction == ASCENDING) {
        return comparator(value1, value2)
      }
      return comparator(value2, value1)
    })
  }

  toggleSort (field) {
    if (this.sortOptions.field != field) {
      this.sortOptions.field = field
      this.sortOptions.direction = ASCENDING
    } else if (this.sortOptions.direction == DESCENDING) {
      this.sortOptions.direction = ASCENDING
    } else {
      this.sortOptions.direction = DESCENDING
    }

    return this.sortOptions
  }

  sortIconClass (field) {
    if (this.sortOptions.field !== field) {
      return 'fa fa-sort'
    }

    if (this.sortOptions.direction == ASCENDING) {
      return 'fa fa-sort-up text-orange'
    }

    return 'fa fa-sort-down text-orange'
  }

  getOptions () {
    return this.sortOptions
  }

}
