<template>
  <staff-pre-act-10-student-results-pane
    :is-horizon-admin="true"/>
</template>

<script>
import StaffPreAct10StudentResultsPane from '@staff/shared/preact10_student_results_pane'

export default {
  components: {
    StaffPreAct10StudentResultsPane,
  },
}
</script>