<template>
  <div class="container">
    <ul class="justify-content-start nav pt-1">
      <router-link :to="{ name: 'StaffDashboard' }" class="nav-link">
        Dashboard
      </router-link>

      <router-link :to="{ name: 'StaffEnrollmentsScheduleList' }" class="nav-link">
        Enrollment
      </router-link>

      <router-link :to="{ name: 'StaffScheduleList' }" class="nav-link">
        Schedules
      </router-link>
      <router-link :to="{ name: 'StaffOnlineAssessmentAdministrationList' }" class="nav-link">
        Assessments
      </router-link>

      <router-link :to="{ name: 'StaffCourseActivities' }" class="nav-link" v-show="showRoute('CourseActivity')" >
        Course Activities
      </router-link>

      <router-link :to="{ name: 'StaffAttendanceList' }" class="nav-link">
        Attendance
      </router-link>

      <router-link :to="{ name: 'StaffMessageList' }" class="nav-link" v-show="showRoute('Message')" >
        Message Students
      </router-link>

      <router-link :to="{ name: 'StudentResultsDashboard' }" class="nav-link">
        Student Results
      </router-link>

      <a v-if="hasThinkificBundleId" :href="courseResourcesUrl" target="_blank" class="nav-link">
        Teacher Resources
      </a>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ExternalRoutes from '../routes/external_links'

export default {
  props: {
    hasThinkificBundleId: false,
  },
  computed: {
    ...mapState(['showCourseActivities']),
  },
  methods: {
    showRoute(route) {
      if (route == 'CourseActivity') {
        return this.$store.getters.userPermit({controller: route}) && this.showCourseActivities
      }

      return this.$store.getters.userPermit({controller: route, action: 'index'})
    },
    courseResourcesUrl() {
      return ExternalRoutes.STAFF.COURSE_RESOURCES
    },
  }
}
</script>
