import StudentRepository from '@repositories/staff/student'
import EnrollmentRepository from '@repositories/staff/enrollment'
import ScheduleRepository from '@repositories/staff/schedule'
import InstructorRepository from '@repositories/staff/instructor'
import AssessmentDateRepository from '@repositories/staff/assessment_date'
import SchoolRepository from '@repositories/staff/school'
import DashboardClassAttendanceRepository from '@repositories/staff/dashboard/class_attendance_repository'

export default {
  school: SchoolRepository,
  student: StudentRepository,
  enrollment: EnrollmentRepository,
  schedule: ScheduleRepository,
  dashboard_class_attendance: DashboardClassAttendanceRepository,
  instructor: InstructorRepository,
  target_assessment_date: AssessmentDateRepository
}
