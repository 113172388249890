import client from '@repositories/client.js'

export default new class {

	async resetPassword(email) {
		const data = await client.post('/api/forgot_password', { email })
		return data.success
	}

}
