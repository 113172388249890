<template>
  <div class="container">
    <div class="content-pane">
      <div class="pane-wrapper">
        <breadcrumbs class="breadcrumbs"/>
        <div class="content-heading underlined">
          <h1>Course Enrollment</h1>
        </div>
        <div>
          <enrollment-card-list
            :schedules="schedules"
            :buttons-configuration="buttonsConfiguration"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@shared/breadcrumbs'
import EnrollmentCardList from '@components/modules/enrollment/enrollment_card_list'

export default {
  components: {
    Breadcrumbs,
    EnrollmentCardList
  },
  data () {
    return {
      schedules: [],
      buttonsConfiguration: [
        {
          onClick: (params) => { this.$router.push({name: 'StaffWaitingListEntriesShow', params: params}) },
          color: 'secondary',
          text: 'Wait List'
        },
        {
          onClick: (params) => { this.$router.push({name: 'StaffEnrollmentsShow', params: {scheduleId: params.schedule.id}}) },
          color: 'secondary',
          text: 'Enrollment'
        }
      ]
    }
  },
  created () {
    this.fetchSchedules()
  },
  methods: {
    fetchSchedules () {
      this.$http.get('/api/staff/schedules').then(response => {
        this.schedules = response.data.schedules
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    }
  }
}
</script>

<style scoped lang="scss">
  @import '~@styles/variables';

  @media screen and (max-width: $mobileScreenSmall) {
    .container {
      padding: 0px;

      .breadcrumbs {
        display: none;
      }

      .content-pane {
        border-radius: 0px;

        .pane-wrapper {
          padding: 15px;
        }
      }
    }
  }
</style>
