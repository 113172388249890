<template>
  <div>
    <send-invite-modal
      @refreshList="loadInvites"/>
    <confirm-modal
      :invite="selectedDelete"
      @deleteInvite="deleteInvite"/>
    <horizon-table
      :columns="columns"
      :data="studentInvites"
      :table-footer="true"
      :table-footer-action="openSendInvite"/>
  </div>
</template>

<script>
import ConfirmModal from './confirm_modal'
import HorizonTable from '@shared/horizon_table'
import SendInviteModal from './send_invite_modal'

export default {
  components: { ConfirmModal, HorizonTable, SendInviteModal },
  data () {
    return {
      showDeleteOption: true,
      columns: [
        { display: 'Name', columnFn: this.displayFullName },
        { column: 'email', display: 'Email' },
        { display: 'Accepted', columnFn: this.displayAccepted },
        { column: 'action', display: '', action: this.openConfirmModal }
      ],
      studentInvites: [],
      selectedDelete: {}
    }
  },
  created () {
    this.initData()
  },
  methods: {
    deleteInvite (invite) {
      this.$http.delete(`/api/student/student_invites/${invite.id}`).then(() => {
        this.loadInvites()
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    },

    displayAccepted (invite) {
      return this.isBlank(invite.acceptedOn) ? 'No' : 'Yes'
    },

    displayFullName (invite) {
      return `${invite.firstName} ${invite.lastName}`
    },

    initData () {
      this.loadInvites()
    },

    loadInvites () {
      this.$http.get('/api/student/student_invites').then(response => {
        this.studentInvites = response.data.studentInvites
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    },

    openConfirmModal (invite) {
      this.selectedDelete = invite
      $('#confirm-delete-modal').modal('show')
    },

    openSendInvite () {
      $('#send-invite-modal').modal('show')
    },

    sendInvite () {

    }
  }
}
</script>

<style scoped lang="scss">

</style>
