<template>
  <staff-assessment-course-score-report-subsection-details
    :is-horizon-admin="true"
    :admin-assessment-type="school.courseType"/>
</template>

<script>
import StaffAssessmentCourseScoreReportSubsectionDetails from '@staff/student_results/score_report/assessment_course_subsection_details.vue'

export default {
  components: {
    StaffAssessmentCourseScoreReportSubsectionDetails,
  },
  data () {
    return {
      school: {},
    }
  },
  created () {
	this.$http.get(`/api/admin/schools/${this.$route.params.id}`).then((response) => {
		this.school = response.data.school
	}, (responseError) => {
		this.appendError({ error: responseError, notification: true })
	})
  }
}
</script>
