<template>
  <div class="container">
    <div class="content-pane">
      <div class="pane-wrapper">
        <breadcrumbs/>
        <div class="content-heading underlined">
          <h1>Settings - {{ assessment.assessmentType }}</h1>
        </div>

        <app-common-confirm
          :confirm-box-text="''"
          :confirm-callback="removeTargetAssessmentDate"
          :confirm-message="removeTargetAssessmentMessage"
          :confirm-modal-id="'confirm-delete-assessment-date'"
          :cancel-button-text="'Cancel'"
          :confirm-button-text="'Remove Assessment Date'"/>
        <assessment-date-modal
          :assessment-prop="assessment"
          :target-assessment-date-prop="selectedTargetAssessmentDateProp"
          @targetAssessmentDateUpdated="targetAssessmentDateUpdated"
          @targetAssessmentDateAdded="targetAssessmentDateAdded"/>

        <div class="bordered-card">
          <h2 class="primary-row py-3">
            ASSESSMENT SETTINGS
          </h2>
          <div class="form-group">
            <div class="horizon-form-column">
              <div class="horizon-form-row">
                <label for="thinkificCourseId">THINKIFIC PD COURSE ID</label>
                <input
                  id="thinkificCourseId"
                  v-model="assessment.thinkificPdCourseId"
                  type="text"
                  class="form-control"
                  placeholder=""
                  aria-label="Thinkific PD Course ID"
                  name="ThinkificPDCourseID"
                  required
                  @blur="$v.assessment.thinkificPdCourseId.$touch">
              </div>
            </div>
          </div>
          <div class="mb-3">
            <h2 class="primary-row py-3">
              TARGET ASSESSMENT DATES
            </h2>
            <table class="mt-3 table">
              <thead>
                <tr>
                  <th scope="col">
                    Month
                  </th>
                  <th scope="col">
                    Year
                  </th>
                  <th scope="col"/>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(date, dateIdx) in filteredTargetAssessmentDates" :key="dateIdx">
                  <td class="fw-600">
                    {{ date.month }}
                  </td>
                  <td>
                    {{ date.year }}
                  </td>
                  <td class="text-right">
                    <button class="btn btn-lg btn-outline-primary mx-2" @click="editDate(date)">
                      <i class="fa fa-edit"/>
                    </button>
                    <button class="btn btn-lg btn-outline-primary mx-2" @click="removeTargetAssessmentModal(date)">
                      <i class="fa fa-trash"/>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="table-footer">
              <button class="btn btn-lg btn-outline-secondary mx-2" @click="addTargetAssessmentDate">
                <i class="fa fa-plus-circle left"/>  Add Assessment Date
              </button>
            </div>
          </div>
          <div>
            <h2 class="primary-row py-3">
              CATEGORY GROUPS
            </h2>
            <div class="mt-3 report-wrapper">
              <div class="tabs-row">
                <div
                  v-for="(categoryGroup, sIndx) in assessment.categoryGroups"
                  :key="sIndx"
                  class="tab"
                  :class="{ 'selected': categoryGroup.selected }"
                  @click="selectCategoryGroup(categoryGroup)">
                  <div>{{ categoryGroup.name }}</div>
                </div>
              </div>
              <div class="tab-content">
                <div class="active fade pb-3 show tab-pane" role="tabpanel" aria-labelledby="home-tab">
                  <subsection-form
                    :category-group-prop="selectedCategoryGroup"
                    @updateCategoryGroup="updateCategoryGroup"/>
                </div>
              </div>
            </div>
          </div>
          <div class="no-gutters pt-3 row">
            <div class="col-md-12 text-right">
              <button
                class="btn btn-outline-primary mx-2"
                @click="backToAssessments">
                Cancel
              </button>
              <button
                class="btn btn-primary float-right"
                @click="saveAssessmentSettings">
                Save Settings
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AssessmentDateModal from './assessment_date_modal.vue'
import Mixins from '@submodules/mixins/mixin.js'
import Breadcrumbs from '@shared/breadcrumbs'
import SubsectionForm from './subsection/form'
import { required } from 'vuelidate/lib/validators'
import CommonConfirm from '@submodules/components/common_confirm.vue'

export default {
  components: {
    'app-common-confirm': CommonConfirm,
    AssessmentDateModal,
    Breadcrumbs,
    SubsectionForm
  },
  mixins: [Mixins],
  data () {
    return {
      selectedAssessmentDate: {},
      assessment: {
        targetAssessmentDates: []
      },
      selectedCategoryGroup: {},
      selectedTargetAssessmentDateProp: {}
    }
  },
  validations: {
    assessment: {
      thinkificPdCourseId: { required }
    }
  },
  computed: {
    filteredTargetAssessmentDates () {
      return this.assessment.targetAssessmentDates.filter(x => x._destroy != true)
    }
  },
  created () {
    this.fetchCategoryGroups()
  },
  mounted () {

  },
  methods: {
    addTargetAssessmentDate () {
      this.selectedTargetAssessmentDateProp = {}
      $('#assessment-date-modal').modal('show')
    },

    backToAssessments () {

    },

    deleteDate () {

    },

    fetchCategoryGroups () {
      const assessmentId = this.$route.params.id
      this.$http.get(`/api/admin/assessments/${assessmentId}`).then(response => {
        this.assessment = response.data.assessment
        this.selectCategoryGroup(this.assessment.categoryGroups[0])
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    },

    formData () {
      return {
        assessment: {
          thinkificPdCourseId: this.assessment.thinkificPdCourseId,
          targetAssessmentDatesAttributes: this.formDataTargetAssessmentDates(),
          categoryGroupsAttributes: this.formDataCategoryGroups()
        }
      }
    },

    formDataTargetAssessmentDates () {
      const targetAssessmentDates = []
      this.assessment.targetAssessmentDates.forEach((tad) => {
        targetAssessmentDates.push({
          id: this.toId(tad.id),
          assessmentOn: tad.assessmentOn,
          _destroy: this.toDestroy(tad._destroy)
        })
      })
      return targetAssessmentDates
    },

    formDataCategoryGroups () {
      const categoryGroups = []
      this.assessment.categoryGroups.forEach((cg) => {
        categoryGroups.push({
          id: this.toId(cg.id),
          subsectionAreasAttributes: this.formDataSubsectionAreas(cg)
        })
      })
      return categoryGroups
    },

    formDataSubsectionAreas (categoryGroup) {
      const subsectionAreas = []
      categoryGroup.subsectionAreas.forEach((sa) => {
        subsectionAreas.push({
          id: this.toId(sa.id),
          topicAreasAttributes: this.formDataTopicAreas(sa)
        })
      })
      return subsectionAreas
    },

    formDataTopicAreas (subsectionArea) {
      const topicAreas = []
      subsectionArea.topicAreas.forEach((ta) => {
        topicAreas.push({
          id: this.toId(ta.id),
          name: ta.name,
          letterCode: ta.letterCode,
          _destroy: this.toDestroy(ta._destroy)
        })
      })
      return topicAreas
    },

    editDate (selectedDate) {
      this.selectedTargetAssessmentDateProp = selectedDate
      $('#assessment-date-modal').modal('show')
    },

    removeTargetAssessmentDate () {
      this.selectedAssessmentDate._destroy = true
      const updateId = this.assessment.targetAssessmentDates.find(x => x.id == this.selectedAssessmentDate.id)
      this.assessment.targetAssessmentDates.splice(updateId, 1, this.selectedAssessmentDate)
    },

    removeTargetAssessmentMessage () {
      return `Are you sure you'd like to delete the Target Assessment for <b>${this.selectedAssessmentDate.month} ${this.selectedAssessmentDate.year}?</b>`
    },

    removeTargetAssessmentModal (targetDate) {
      this.selectedAssessmentDate = targetDate
      $('#confirm-delete-assessment-date').modal('show')
    },

    saveAssessmentSettings () {
      this.$http.put(`/api/admin/assessments/${this.assessment.id}`, this.formData()).then(() => {
        this.appendSuccess({message: 'Updated assessment successfully', notification: true})
        // this.backToAssessments()
      }, (responseError) => {
        this.appendError({error: responseError, notification: true})
      })
    },

    selectCategoryGroup (categoryGroup) {
      this.assessment.categoryGroups.forEach(function (group) { group.selected = false })
      categoryGroup.selected = true
      this.selectedCategoryGroup = categoryGroup
      console.log('Category Group', categoryGroup)
    },

    targetAssessmentDateUpdated (updatedTargetAssessment) {
      const updateId = this.assessment.targetAssessmentDates.findIndex(x => x.id == updatedTargetAssessment.id)
      this.assessment.targetAssessmentDates.splice(updateId, 1, updatedTargetAssessment)
    },

    targetAssessmentDateAdded (newTargetAssessment) {
      this.assessment.targetAssessmentDates.push(newTargetAssessment)
    },

    updateCategoryGroup (categoryGroupUpdated) { // eslint-disable-line no-unused-vars
      const updateCategoryId = this.assessment.categoryGroups.findIndex(x => x.id == categoryGroupUpdated.id)
      this.assessment.categoryGroups.splice(updateCategoryId, 1, categoryGroupUpdated)
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@styles/table_2.scss';
// .table thead th {
//   font-size: 16px;
//   padding: 20px 15px !important;
// }
// .table tbody th {
//   margin-left: 0px;
//   color: #333;
//   font-size: 12px;
//   font-weight: 600;
// }
</style>
