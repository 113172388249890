import { render, staticRenderFns } from "./confirm_relock_test_section_modal.vue?vue&type=template&id=7595e11f&scoped=true&"
import script from "./confirm_relock_test_section_modal.vue?vue&type=script&lang=js&"
export * from "./confirm_relock_test_section_modal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7595e11f",
  null
  
)

export default component.exports