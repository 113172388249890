var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card",attrs:{"data-schedule-id":_vm.schedule.id}},[_c('div',{staticClass:"card-body stat-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-9"},[_c('div',{staticClass:"pull-left"},[_c('h4',{staticClass:"font-14 lh-18 text-primary",staticStyle:{"padding-bottom":"4px"}},[_vm._v("\n            "+_vm._s(_vm.schedule.name)+"\n          ")]),_vm._v(" "),_c('h4',[_vm._v(_vm._s(_vm.schedule.scheduleType)+" School Day")]),_vm._v(" "),_c('h5',[_vm._v(_vm._s(_vm.schedule.primaryTeacherName))]),_vm._v(" "),_c('h5',[_vm._v(_vm._s(_vm.schedule.secondaryTeacherName))]),_vm._v(" "),_vm._t("default")],2)]),_vm._v(" "),_c('div',{staticClass:"col-3"},[_c('div',{staticClass:"d-flex flex-row pull-right text-center"},[_c('div',[_c('div',{staticClass:"enrollment-count bold-stat text-orange"},[_vm._v("\n              "+_vm._s(_vm.schedule.studentsEnrolled)+"\n            ")]),_vm._v(" "),_vm._m(0)])])])])]),_vm._v(" "),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"pull-right"},[_c('button',{class:"btn btn-outline-secondary",on:{"click":function($event){$event.preventDefault();return _vm.navTo({
          name: 'StaffCourseActivitiesCourse',
          query: _vm.schedule.courseActivity
        })}}},[_vm._v("\n        View Course Activity\n      ")]),_vm._v(" "),(_vm.hasGradebook)?_c('button',{class:"btn btn-outline-secondary",on:{"click":function($event){$event.preventDefault();return _vm.navTo({
          name: 'StaffGradebookShow',
          query: {
            course_name: _vm.schedule.name,
            course_id: _vm.schedule.courseActivity.courseId,
            schedule_id: _vm.schedule.courseActivity.scheduleId,
            template_id: _vm.schedule.courseActivity.templateId
            }
        })}}},[_vm._v("\n        Gradebook\n      ")]):_vm._e()])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stat-text"},[_vm._v("\n              Students"),_c('br'),_vm._v("Enrolled\n            ")])}]

export { render, staticRenderFns }