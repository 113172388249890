import AttendanceForm from '@staff/attendance/form.vue'
import AttendanceShow from '@staff/attendance/show.vue'
import AttendanceList from '@staff/attendance/list.vue'
import EnrollmentList from '@staff/enrollment/list.vue'
import EnrollmentShow from '@staff/enrollment/show.vue'
import MessageList from '@staff/message/list.vue'
import ScheduleList from '@staff/schedule/list.vue'
import ScheduleShow from '@staff/schedule/show.vue'
import ScheduleEdit from '@staff/schedule/edit.vue'
import StaffDashboard from '@staff/dashboard/show.vue'
import StudentResultsDashboard from '@staff/student_results/show.vue'
import StaffStudentScoreReport from '@staff/student_results/score_report/show.vue'
import StaffStudentScoreReportSubsectionDetails from '@staff/student_results/score_report/subsection_details.vue'
import StaffAssessmentCourseScoreReportSubsectionDetails from '@staff/student_results/score_report/assessment_course_subsection_details.vue'
import StaffAdvancedReporting from '@staff/student_results/score_report/advanced_reporting.vue'
import StaffStudentScoreReportIndividual from '@staff/student_results/score_report/individual/show.vue'
import WaitingListEntryShow from '@staff/waiting_list/show.vue'
import StaffOnlineAssessmentAdministrationList from '@staff/online_assessment_administration/list.vue'
import OnlineAssessmentScheduleShow from '@staff/online_assessment_schedules/show.vue'
import NewMassImportTempStudentList from '@staff/mass_import_temp_students/new.vue'
import MassImportTempStudentList from '@staff/mass_import_temp_students/list.vue'
import StaffCourseActivities from '@staff/course_activities/list.vue'
import StaffCourseActivitiesCourse from '@staff/course_activities/course/show.vue'
import StaffGradebookShow from '@staff/gradebook/show.vue'
import StaffReportDownload from '@staff/reports/download.vue'

export default [
  {
    path: '/staff/online_assessment_administration',
    component: StaffOnlineAssessmentAdministrationList,
    name: 'StaffOnlineAssessmentAdministrationList',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Dashboard', routerOptions: { name: 'StaffDashboard' } },
        { text: 'Online Assessment Administration' }
      ]
    }
  },
  {
    path: '/staff/dashboard',
    component: StaffDashboard,
    name: 'StaffDashboard',
    meta: { guestView: false }
  },
  {
    path: '/staff/student_results',
    component: StudentResultsDashboard,
    name: 'StudentResultsDashboard',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Dashboard', routerOptions: { name: 'StaffDashboard' } },
        { text: 'Student Results' }
      ]
    }
  },
  {
    path: '/staff/student_results/score_report/:scheduleId/:assessmentId',
    component: StaffStudentScoreReport,
    name: 'StaffStudentScoreReport',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Dashboard', routerOptions: { name: 'StaffDashboard' } },
        { text: 'Student Results'}
      ]
    }
  },
  {
    path: '/staff/student_results/score_report/:scheduleId/:assessmentId/subsection_details',
    component: StaffStudentScoreReportSubsectionDetails,
    name: 'StaffStudentScoreReportSubsectionDetails',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Dashboard', routerOptions: { name: 'StaffDashboard' } },
        { text: 'Student Results'}
      ]
    }
  },
  {
    path: '/staff/student_results/score_report/:scheduleId/:assessmentId/assessment_course_subsection_details',
    component: StaffAssessmentCourseScoreReportSubsectionDetails,
    name: 'StaffAssessmentCourseScoreReportSubsectionDetails',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Dashboard', routerOptions: { name: 'StaffDashboard' } },
        { text: 'Student Results'}
      ]
    }
  },
  {
    path: '/staff/student_results/score_report/:scheduleId/:assessmentId/advanced_reporting',
    component: StaffAdvancedReporting,
    name: 'StaffAdvancedReporting',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Dashboard', routerOptions: { name: 'StaffDashboard' } },
        { text: 'Student Results'}
      ]
    }
  },
  {
    path: '/staff/student_results/score_report/:assessmentId/student/:studentId',
    component: StaffStudentScoreReportIndividual,
    name: 'StaffStudentScoreReportIndividual',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Dashboard', routerOptions: { name: 'StaffDashboard' } },
        { text: 'Student Results'}
      ]
    }
  },
  {
    path: '/staff/messages',
    component: MessageList,
    name: 'StaffMessageList',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Dashboard', routerOptions: { name: 'StaffDashboard' } },
        { text: 'Message' }
      ]
    }
  },
  {
    path: '/staff/attendance',
    component: AttendanceList,
    name: 'StaffAttendanceList',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Dashboard', routerOptions: { name: 'StaffDashboard' } },
        { text: 'Attendance' }
      ]
    }
  },
  {
    path: '/staff/attendance/:scheduleId',
    component: AttendanceShow,
    props: true,
    name: 'StaffAttendanceShow',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Dashboard', routerOptions: { name: 'StaffDashboard' } },
        { text: 'Attendance', routerOptions: { name: 'StaffAttendanceList' } }
      ]
    }
  },
  {
    path: '/staff/schedules/:id/online_assessment_schedules/:practice_test_set_id',
    component: OnlineAssessmentScheduleShow,
    props: true,
    name: 'StaffOnlineAssessmentScheduleShow',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Dashboard', routerOptions: { name: 'StaffDashboard' } },
        { text: 'Online Assessment Administration', routerOptions: { name: 'StaffOnlineAssessmentAdministrationList' } }
      ]
    }
  },
  {
    path: '/staff/attendance/:scheduleId/:scheduleDateId',
    component: AttendanceForm,
    props: true,
    name: 'StaffAttendanceForm',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Dashboard', routerOptions: { name: 'StaffDashboard' } },
        { text: 'Attendance', routerOptions: { name: 'StaffAttendanceList' } }
      ]
    }
  },
  {
    path: '/staff/schedules',
    component: ScheduleList,
    name: 'StaffScheduleList',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Dashboard', routerOptions: { name: 'StaffDashboard' } },
        { text: 'Schedules' }
      ]
    }
  },{
    path: '/staff/schedules/:id?',
    component: ScheduleShow,
    props: true,
    name: 'StaffScheduleShow',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Dashboard', routerOptions: { name: 'StaffDashboard' } },
        { text: 'Schedules', routerOptions: { name: 'StaffScheduleList' } }
      ]
    }
  },{
    path: '/staff/schedules/:id/edit',
    component: ScheduleEdit,
    props: true,
    name: 'StaffScheduleEdit',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Dashboard', routerOptions: { name: 'StaffDashboard' } },
        { text: 'Schedules', routerOptions: { name: 'StaffScheduleList' } }
      ]
    }
  },{
    path: '/staff/enrollment_list',
    component: EnrollmentList,
    name: 'StaffEnrollmentsScheduleList',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Dashboard', routerOptions: { name: 'StaffDashboard' } },
        { text: 'Enrollment' }
      ]
    }
  },{
    path: '/staff/schedules/:id/new_mass_import_temp_students',
    component: NewMassImportTempStudentList,
    name: 'StaffNewMassImportTempStudentList',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Dashboard', routerOptions: { name: 'StaffDashboard' } },
        { text: 'New Mass Import' }
      ]
    }
  },{
    path: '/staff/schedules/:id/temp_students/:importId',
    component: MassImportTempStudentList,
    name: 'StaffMassImportTempStudentList',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Dashboard', routerOptions: { name: 'StaffDashboard' } },
        { text: 'Student Mass Import' }
      ]
    }
  },
  {
    path: '/staff/enrollments/:scheduleId?',
    component: EnrollmentShow,
    props: true,
    name: 'StaffEnrollmentsShow',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Dashboard', routerOptions: { name: 'StaffDashboard' } },
        { text: 'Enrollment', routerOptions: { name: 'StaffEnrollmentsScheduleList' } }
      ]
    }
  },
  {
    path: '/staff/waiting_list_entries/:id?',
    component: WaitingListEntryShow,
    props: true,
    name: 'StaffWaitingListEntriesShow',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Dashboard', routerOptions: { name: 'StaffDashboard' } },
        { text: 'Enrollment', routerOptions: { name: 'StaffEnrollmentsScheduleList' } }
      ]
    }
  },{
    path: '/staff/course_activities',
    component: StaffCourseActivities,
    name: 'StaffCourseActivities',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Dashboard', routerOptions: { name: 'StaffDashboard' } },
        { text: 'Course Activities' }
      ]
    }
  },{
    path: '/staff/course_activities/course/',
    component: StaffCourseActivitiesCourse,
    name: 'StaffCourseActivitiesCourse',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Dashboard', routerOptions: { name: 'StaffDashboard' } },
        { text: 'Course Activities', routerOptions: { name: 'StaffCourseActivities' } },
        { text: 'Course' }
      ]
    }
  },{
    path: '/staff/gradebook',
    component: StaffGradebookShow,
    name: 'StaffGradebookShow',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Dashboard', routerOptions: { name: 'StaffDashboard' } },
        { text: 'Courses', routerOptions: { name: 'StaffCourseActivities' } },
      ]
    }
  }, {
    path: '/staff/download/:reportId',
    component: StaffReportDownload,
    name: "StaffReportDownload",
    props: true,
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Dashboard', routerOptions: { name: 'StaffDashboard' } },
        { text: 'Student Results' }
      ]
    }
  }
]
