<template>
  <div>
    <div class="content-heading underlined">
      <div class="pull-left">
        <h1>SAT Student Results </h1>
      </div>
      <div class="pull-right">
        <h2 class="flex-y-center">
          <span class="font-24 fw-600 text-dk-gray">
            Schedule:
          </span>
          <common-dropdown
            :items="satScheduleOptions"
            :item-display-key="'name'"
            :on-item-select="loadSchedule"
            class="borderless schedule-select">
            <span class="font-24 text-dk-gray text-truncate">{{ activeSchedule.name }}</span>
          </common-dropdown>
        </h2>
      </div>
    </div>
    <div class="dashboard-l2-wrapper" 
      v-if="assessmentTabs.length">
      <div class="report-wrapper">
        <div class="tabs-row">
          <div
            v-for="(assessment, rIndx) in assessmentTabs"
            :key="rIndx"
            class="tab"
            :class="{ 'selected': assessment.selected }"
            @click.prevent="updateSelectedAssessment(assessment)">
            <div>{{ assessment.name }}</div>
          </div>
          <div class="w-tab-menu">
            <div
              v-for="(assessment, rIndx) in assessmentTabs"
              :key="rIndx"
              class="w-tab-menu-item"
              :class="{ 'selected': assessment.selected }"
              @click="updateSelectedAssessment(assessment)">
              {{ assessment.name }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="selectedAssessment" class="tab-pane">
        <div class="tab-header">
          <div class="header-info">
            <h1>{{ selectedAssessment.name }}</h1>
            <div class="report-date-wrapper">
              <div>
                <i class="fa fa-users mr-1 text-primary"/>
              </div>
              <div class="body-larger">
                Number of Students Assessed: <span class="fw-700 text-orange"><b>{{ selectedAssessment.studentCount }} Students</b></span>
              </div>
            </div>
          </div>
          <div class="view-report">
            <router-link
              v-if="isHorizonAdmin"
              class="btn btn-outline"
              :to="{ name: 'AdminStudentScoreReport', params: { assessmentId: selectedAssessment.practiceTestSetId, scheduleId: activeSchedule.id } }">
              View Report
              <img class="btn-icon" src="/images/right-arrow.png">
            </router-link>
            <router-link
              v-else
              class="btn btn-outline"
              :to="{ name: 'StaffStudentScoreReport', params: { assessmentId: selectedAssessment.practiceTestSetId, scheduleId: activeSchedule.id } }">
              View Report
              <img class="btn-icon" src="/images/right-arrow.png">
            </router-link>
          </div>
        </div>
        <br>
        <div class="chart-container row">
          <div class="col-5 donut-chart-dashboard">
            <donut-chart
              :chart-id="'test'"
              :average-score="selectedAssessment.averageScore"
              :min-score="scheduleAssessmentOverview.minScore"
              :max-score="scheduleAssessmentOverview.maxScore"/>
          </div>

          <div class="bar-chart-dashboard col-7">
            <bar-chart
              v-if="selectedAssessment.segments"
              :chart-id="'selectedAssessmentBar'"
              :data-set="selectedAssessment.segments"/>
          </div>
        </div>

        <div class="pb-3 row">
          <div class="col-12">
            <sat-assessment-results
              v-if="isSatAssessment"
              :assessments="tableData"
              :practiceTestSetId="selectedAssessment.practiceTestSetId"
              :active-schedule="activeSchedule"
              :is-horizon-admin="isHorizonAdmin"/>

            <act-assessment-results
              v-if="isActAssessment"
              :assessments="scheduleAssessments"
              :active-schedule="activeSchedule"/>
          </div>
        </div>
      </div>
    </div>
    <div class="dashboard-l2-wrapper"
      v-else-if="loadedScheduleAssessmentOverview">
      <p>No results found.</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CommonDropdown from '@shared/common_dropdown'
import BarChart from '@shared/charts/bar_chart.vue'
import DonutChart from '@shared/charts/donut_chart.vue'
import SatAssessmentResults from './sat_assessment_results'
import ActAssessmentResults from './act_assessment_results'
import CourseType from '@utils/course_types'

const ENTIRE_SCHOOL = {
  id: 0,
  name: 'Entire School'
}

export default {
  components: {
    BarChart,
    CommonDropdown,
    DonutChart,
    SatAssessmentResults,
    ActAssessmentResults
  },
  props: {
    isHorizonAdmin: {
      type: Boolean,
      default: false
    },
    adminAssessmentType: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
      scheduleAssessmentOverview: {
        scoreSets: [],
        maxScore: 0,
        minScore: 0
      },
      selectedAssessment: {
        id: '',
        averageScoreDecimal: 0,
        averageScore: 0
      },
      scheduleAssessments: [],
      activeSchedule: {},
      schedules: [],
      loadedScheduleAssessmentOverview: false
    }
  },
  computed: {
    isSatAssessment () {
      return this.assessmentType == CourseType.SAT || this.assessmentType == CourseType.PSAT89 || this.assessmentType == CourseType.PSAT10
    },
    isActAssessment () {
      return this.assessmentType == CourseType.ACT
    },
    satScheduleOptions () {
      return [...this.schedules.filter(schedule => schedule.courseType == "SAT"), ENTIRE_SCHOOL]
    },
    tableData () {
      if(this.scheduleAssessments.length > 0) {
        const practiceTestSetId = this.selectedAssessment.practiceTestSetId
        const scheduleAssessment = this.scheduleAssessments.find(assessment => assessment.practiceTestSetId == practiceTestSetId)
        if (typeof scheduleAssessment !== "undefined") {
          return scheduleAssessment.scoreSet
        }
      }
      return []
    },
    assessmentType () {
      if (this.isHorizonAdmin) {
        return this.adminAssessmentType
      }
      return this.userSchool().courseType;
    },
    assessmentTabs () {
      if (this.scheduleAssessmentOverview.scoreSets.length) {
        return _.filter(this.scheduleAssessmentOverview.scoreSets, (scoreSet) => { 
          return scoreSet.studentCount > 0 
        })
      }
      return [];
    }
  },
  created () {
    this.selectedAssessment.id = this.$route.params.assessmentId
    this.activeSchedule.id = this.$route.params.scheduleId
    this.loadSchedules()
  },
  methods: {
    ...mapGetters(['userSchool']),
    updateSelectedAssessment (assessment) {
      this.scheduleAssessmentOverview.scoreSets.forEach(function (asmt) {
        asmt.selected = false
      })
      assessment.selected = true
      this.selectedAssessment = assessment
    },
    loadSchedules () {
      let endpointURL = `/api/staff/sat_schedules`
      if (this.isHorizonAdmin) {
        endpointURL = `/api/admin/schools/${this.$route.params.id}/sat_schedules`
      }
      this.$http.get(endpointURL).then(response => {
        this.schedules = response.data.schedules
        if (this.isBlank(this.activeSchedule.id)) {
          this.activeSchedule = ENTIRE_SCHOOL
        }
        this.loadEntireSchoolAssessmentOverview(this.selectedAssessment)
        this.loadScheduleAssessments()
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    },
    loadSchedule (schedule) {
      if (schedule.id == 0) {
        // TODO: develop endpoint entire school
        this.activeSchedule = schedule
        this.loadEntireSchoolAssessmentOverview(this.selectedAssessment)
        return
      }

      let endpointURL = `/api/staff/schedules/${schedule.id}`
      if (this.isHorizonAdmin) {
        endpointURL = `/api/admin/schedules/${schedule.id}`
      }
      this.$http.get(endpointURL).then(response => {
        this.activeSchedule = response.data.schedule
        this.loadScheduleAssessmentOverview(this.selectedAssessment)
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    },
    loadScheduleAssessmentOverview (previousAssessment) {
      let endpointURL = `/api/staff/schedules/${this.activeSchedule.id}/assessment_overview`
      if (this.isHorizonAdmin) {
        endpointURL = `/api/admin/schedules/${this.activeSchedule.id}/assessment_overview`
      }
      this.$http.get(endpointURL).then(response => {
        this.scheduleAssessmentOverview = response.data.scheduleAssessments
        if (!previousAssessment) {
          this.updateSelectedAssessment(this.scheduleAssessmentOverview.scoreSets[0])
        } else {
          var i;
          var assessmentExist = 0;
          for (i = 0; i < this.scheduleAssessmentOverview.scoreSets.length; i++) {
            if (this.scheduleAssessmentOverview.scoreSets[i].practiceTestSetId == previousAssessment.practiceTestSetId) {
              this.updateSelectedAssessment(this.scheduleAssessmentOverview.scoreSets[i])
              assessmentExist++;
            }
          }
          if (assessmentExist == 0){
            this.updateSelectedAssessment(this.scheduleAssessmentOverview.scoreSets[0])
          }
        }
        this.loadedScheduleAssessmentOverview = true
      }, (responseError) => {
        this.loadedScheduleAssessmentOverview = true
        this.appendError({ error: responseError, notification: true })
      })
    },
    loadScheduleAssessments () {
      let endpointURL = `/api/staff/school/sat_assessment_results`
      if (this.isHorizonAdmin) {
        endpointURL = `/api/admin/schools/${this.$route.params.id}/sat_assessment_results`
      }
      this.$http.get(endpointURL).then(response => {
        this.scheduleAssessments = response.data.scheduleAssessments
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    },

    loadEntireSchoolAssessmentOverview (previousAssessment) {
      let endpointURL = `/api/staff/school/assessment_overview`
      if (this.isHorizonAdmin) {
        endpointURL = `/api/admin/schools/${this.$route.params.id}/assessment_overview`
      }
      this.$http.get(endpointURL).then(response => {
        this.scheduleAssessmentOverview = response.data.scheduleAssessments
        if(previousAssessment.averageScore == 0) {
          this.updateSelectedAssessment(this.scheduleAssessmentOverview.scoreSets[0])
        } else {
          var i;
          var assessmentExist = 0;
          for (i = 0; i < this.scheduleAssessmentOverview.scoreSets.length; i++) {
            if (this.scheduleAssessmentOverview.scoreSets[i].practiceTestSetId == previousAssessment.practiceTestSetId) {
              this.updateSelectedAssessment(this.scheduleAssessmentOverview.scoreSets[i])
              assessmentExist++;
            }
          }
          if (assessmentExist == 0){
            this.updateSelectedAssessment(this.scheduleAssessmentOverview.scoreSets[0])
          }
        }
        this.loadedScheduleAssessmentOverview = true
      }, (responseError) => {
        this.loadedScheduleAssessmentOverview = true
        this.appendError({ error: responseError, notification: true })
      })
    },
  }

}
</script>

<style scoped lang="scss">
@import '~@styles/variables.scss';

.schedule-select {
  margin-bottom: 1px;
  max-width: 300px;
  min-width: 200px;
}
.content-pane {
	min-height: 20vh;
}
.content-heading.underlined {
  padding-bottom: 5px;
  padding-top: 5px;
}
.bar-chart-dashboard {
  height: auto;
  padding-left: 0px;
  padding-right: 0px;

}
.donut-chart-dashboard {
  height: auto;
  padding-bottom: 20px;
}

.w-tab-menu {
  display: none;
}

@media screen and (max-width: $mobileScreenExtraLarge) {
  .content-heading {
    display: inline-block;

    .pull-left {
      float: left;
      width: 100%;
    }

    .pull-right {
      float: left;
      width: 100%;
    }

    .font-24 {
      font-size: 18px;
    }
  }

  .underlined {
    border-bottom: 0px;
  }
}

@media screen and (max-width: $mobileScreenLarge) {
  .chart-container {
    display: block;

    .col-5 {
      width: 100%;
      max-width: none;
    }
    .col-7 {
      width: 100%;
      max-width: none;
    }
  }

  .tab-header {
    display: block;
    margin-bottom: 0px;

    .header-info {
      width: 100%;
    }

    .view-report {
      width: 100%;
      margin-top: 15px;
    }
  }
}

@media screen and (max-width: $mobileScreenMedium) {
  .header-info {
    text-align: center;

    .report-date-wrapper {
      display: block;
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: $mobileScreenSmall) {
  .tab {
    display: none;
  }

  .w-tab-menu {
    display: block;
    width: 100%;

    .w-tab-menu-item {
      width: 100%;
      transition: .2s;
      cursor: pointer;
      color: $mutedText;
      padding: 13px 25px;
      text-align: center;
      border-radius: 7px;
      &.selected {
        background-color: $primaryColor;
        color: #fff;
      }
      &:not(.selected):hover {
        color: $themeGray;
      }
    }
  }
}

@media screen and (max-width: $mobileScreenExtraSmall) {
  .content-heading, .dashboard-l2-wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
