<template>
  <a :href="courseResourcesUrl" target="_blank" class="course-resources-link icon-pane-container">
    <img src="/images/students-1.svg" alt="" class="main-icon">
    <div class="title">
      <h2>Course Resources</h2>
      <img src="/images/right-arrow.png">
    </div>
    <p class="sub-text">Course Syllabus, Documents, Presentations</p>
  </a>
</template>

<script>
import ExternalRoutes from '../../../routes/external_links'

export default {
  computed: {
    courseResourcesUrl() {
      return ExternalRoutes.STUDENT.COURSE_RESOURCES
    }
  }
}
</script>

<style scoped lang="scss">
.course-resources-link {
  padding: 15px;
  border-radius: 5px;
  text-decoration: none;
  transition: box-shadow 200ms ease, background-color 200ms ease;
  &:hover {
    box-shadow: 0 1px 3px 3px #bbb;
  }
}
</style>
