<template>
  <div class="lesson-pane" :id="id">
    <div class="accordion-trigger flex-md-row">
      <div class="left-pane" @click.prevent="triggerSelect">
        <img v-if="dragging" class="drag-icon" src="/images/drag.png">
        <rotating-button :isActive="selected">
        </rotating-button>
        <div class="section-text">
          <h1>{{ lesson.name }}</h1>
          <h3 v-show="!editing">{{ lesson.memo }}</h3>
          <editable-text
            v-show="editing"
            :text-content="lesson.memo"
            :header-one="false"
            :identity="buildIdentity('memo')"
            @update="editableTextUpdate"></editable-text>
        </div>
      </div>
      <div class="right-pane mt-2 mt-md-0">
        <a v-if="isLocked()" class="btn btn-outline" @click="lock('unlock')">
          <img class="btn-icon" src="/images/lock.png">
          <div class="header-btn-text">Unlock Lesson</div>
        </a>
        <a v-else class="btn btn-outline" @click="lock('lock')">
          <img class="btn-icon" src="/images/release_button.png">
          <div class="header-btn-text">Relock Lesson</div>
        </a>
      </div>
    </div>
    <div class="lessons" :hidden="!selected">
      <text-detail
        v-if="hasTimeDetail()"
        :independent-practice-time="lesson.independent_practice_time"
        :instructional-time="lesson.instructional_time"
        :id="id+'-text-detail'"
        >
      </text-detail>
      <text-button-detail
        v-if="hasIndependentLessonActivity()"
        :id="id+'-independent-lesson'"
        :main_text="'Independent Lesson'"
        :btn_a="{text:'View Lesson',i_path:'/images/open-book.png'}"
        :btn_b="{text:'Activity Progress',i_path:'/images/checklist.png'}"
        :btn_c="{text:'Item Analysis',i_path:'/images/grade.png'}"
        :btn_a_fn="launchIndependentLessonPreview"
        :btn_b_fn="showIndependentLessonActivityProgressModal"
        :btn_c_fn="showIndependentLessonItemAnalysisModal"
        >
      </text-button-detail>
      <text-button-detail
        v-if="hasPracticeSetActivity()"
        :id="id+'-practice-set'"
        :main_text="'Practice Set'"
        :btn_a="{text:'View Practice Set',i_path:'/images/open-book.png'}"
        :btn_b="{text:'Activity Progress',i_path:'/images/checklist.png'}"
        :btn_c="{text:'Item Analysis',i_path:'/images/grade.png'}"
        :btn_a_fn="launchPracticeSetPreview"
        :btn_b_fn="showPracticeSetActivityProgressModal"
        :btn_c_fn="showPracticeSetItemAnalysisModal"
        >
      </text-button-detail>
      <div v-if="selected">
        <div v-for="(material, propName) in lesson.support_materials">
          <text-media-detail
            v-if="material.hasOwnProperty('url') && material.url"
            :id="propName+'-media-detail'"
            :main_text="material.name"
            :media_src="material.url"
            >
          </text-media-detail>
        </div>
      </div>
    </div>
    <report-modal
      :id="last_report_id"
      :name="report_name"
      :initConf="report_conf"
      :htmlTag="report_tag"
      :reportPages="report_pages"
      :reportLessonParams="report_lesson_params"
      @modal-closed="reportModalClosed"
    />
  </div>
</template>
<script>
import RotatingButton from '@shared/rotating_button.vue'
import TextDetail from './details/text_detail.vue'
import TextButtonDetail from './details/text_button_detail.vue'
import TextMediaDetail from './details/text_media_detail.vue'
import EditableText from '../show/editable_text.vue'
import ReportModal from './report_modal.vue'

export default {
  components: {
    RotatingButton,
    TextDetail,
    TextButtonDetail,
    TextMediaDetail,
    EditableText,
    ReportModal
  },
  props: {
    id: {
      type: String,
      default: '',
      required: true,
    },
    section_id: {
      type: Number,
      default: null
    },
    section_order: {
      type: Number,
      default: null
    },
    lesson: {
      type: Object,
      default() { return {} }
    },
    editing: {
      type: Boolean,
      default: false
    },
    dragging: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      selected: false,
      last_report_id: '',
      report_name: '',
      report_conf: {},
      report_pages: {},
      report_tag: '',
      report_conf_ready: false,
      report_lesson_params: {
        course_id: null,
        lesson_id: null,
        activity_id: null
      }
    }
  },
  created() {
    this.last_report_id = this.id + '-pre-report-id'
  },
  methods: {
    showPracticeSetProgressModal() {
      this.getProgressReportInitConfig(this.lesson.id,
         this.lesson.learnosity_activity_banks.practice_set)
      $('#' + this.id + '-activity-progress-modal').modal('show')
    },
    showIndependentLessonProgressModal() {
      this.getProgressReportInitConfig(this.lesson.id,
         this.lesson.learnosity_activity_banks.independent_lesson)
      $('#' + this.id + '-activity-progress-modal').modal('show')
    },
    getProgressReportInitConfig(lesson_id, activity_id) {
      this.$http.get(`/api/staff/course_activities/${this.$route.query.courseId}/lesson/progress_report/${lesson_id}/${activity_id}`).then((res) => {
        let config = JSON.parse(res.data.response)
        this.modal_report_html_tag = config.request.reports[0].id
        this.modal_report_init_config = config
      }, (responseError) => {
        this.appendError({error: responseError, notification: true})
      })
    },
    activityProgressModalClosed() {
     this.modal_report_html_tag = ''
     this.modal_report_init_config = {}
    },
    isLocked() {
       return this.lesson.is_locked == true || this.lesson.is_locked == null
    },
    buildIdentity(target) {
      return {
        'section_id': this.section_id,
        'section_order': this.section_order,
        'lesson_id': this.lesson.id,
        'lesson_order': this.lesson.order,
        'target': target
      }
    },
    editableTextUpdate(identity, text) {
      this.$emit('edit-text-update', identity, text)
    },
    triggerSelect() {
      this.selected = this.selected == true ? false : true
    },
    setReportModalId(new_id) {
      let e = document.getElementById(this.last_report_id)
      e.id = new_id
      this.last_report_id = new_id
    },
    showPracticeSetActivityProgressModal() {
      this.getActivityProgressConf(this.lesson.id,
        this.lesson.learnosity_activity_banks.practice_set).then((res) => {
        this.showActivityProgressModal()
      })
    },
    showIndependentLessonActivityProgressModal() {
      this.getActivityProgressConf(this.lesson.id,
        this.lesson.learnosity_activity_banks.independent_lesson).then((res) => {
        this.showActivityProgressModal()
      })
    },
    getActivityProgressConf(lesson_id, activity_id) {
      this.clearReportConf()
      return this.$http.get(`/api/staff/course_activities/${this.$route.query.courseId}/lesson/progress_report/${lesson_id}/${activity_id}`).then((res) => {
        let conf = JSON.parse(res.data.response)
        if (conf.request.reports[0].users.length > 0) {
          this.report_tag = conf.request.reports[0].id,
          this.report_conf = conf
          this.report_pages = res.data.pages
          this.report_conf_ready = true
        } else {
          this.appendError({error: 'No responses found.', notification: true})
        }
      }, (responseError) => {
        this.appendError({error: responseError, notification: true})
      })
    },
    showActivityProgressModal() {
      if (this.report_conf_ready) {
        let report_id = this.id + '-item-analysis-modal'
        this.setReportModalId(report_id)
        this.report_name ='Item Analysis by Student'

        $('#' + this.id + '-item-analysis-modal').modal('show')
      }
    },
    showPracticeSetItemAnalysisModal() {
      this.getItemAnalysisConf(this.lesson.id,
        this.lesson.learnosity_activity_banks.practice_set).then((res) => {
        this.showItemAnalysisModal()
      })
    },
    showIndependentLessonItemAnalysisModal() {
      this.getItemAnalysisConf(this.lesson.id,
        this.lesson.learnosity_activity_banks.independent_lesson).then((res) => {
        this.showItemAnalysisModal()
      })
    },
    showItemAnalysisModal() {
      if (this.report_conf_ready) {
        let report_id = this.id + '-item-analysis-modal'
        this.setReportModalId(report_id)
        this.report_name ='Item Analysis by Student'

        $('#' + this.id + '-item-analysis-modal').modal('show')
      }
    },
    reportModalClosed() {
      this.clearReportConf()
    },
    clearReportConf() {
      this.report_tag = ''
      this.report_conf = {}
      this.report_conf_ready = false
    },
    lock(mode) {
      let params = {
        schedule_id: this.$route.query.scheduleId,
        lesson_id: this.lesson.id,
        lock_mode: mode
      }
      this.$http.put(`/api/staff/course_activities/${this.$route.query.courseId}/lesson/lock`, params)
        .then((res) => {
          this.$emit('lesson-update')
          this.appendSuccess({message: 'Lock operation success.', notification: true})
        }, (responseError) => {
          this.appendError({error: responseError, notification: true})
        })
    },
    getItemAnalysisConf(lesson_id, activity_id) {
      this.clearReportConf()
      return this.$http.get(`/api/staff/course_activities/${this.$route.query.courseId}/lesson/item_analysis_report/${lesson_id}/${activity_id}`).then((res) => {
        let conf = JSON.parse(res.data.response)
        if (conf.request.reports[0].users.length > 0) {
          this.report_tag = conf.request.reports[0].id,
          this.report_conf = conf
          this.report_pages = res.data.pages
          this.report_conf_ready = true
          this.report_lesson_params.course_id = this.$route.query.courseId
          this.report_lesson_params.lesson_id = lesson_id
          this.report_lesson_params.activity_id = activity_id
        } else {
          this.appendError({error: 'No responses found.', notification: true})
        }
      }, (responseError) => {
        this.appendError({error: responseError, notification: true})
      })
    },
    launchIndependentLessonPreview() {
      window.open(`/course_activities/preview/${this.$route.query.scheduleId}/${this.$route.query.templateId}/${this.$route.query.courseId}/${this.lesson.learnosity_activity_banks.independent_lesson}`)
    },
    launchPracticeSetPreview() {
      window.open(`/course_activities/preview/${this.$route.query.scheduleId}/${this.$route.query.templateId}/${this.$route.query.courseId}/${this.lesson.learnosity_activity_banks.practice_set}`)
    },
    hasPracticeSetActivity() {
      return !!this.lesson.learnosity_activity_banks.practice_set
    },
    hasIndependentLessonActivity() {
      return !!this.lesson.learnosity_activity_banks.independent_lesson
    },
    hasTimeDetail() {
      return this.lesson.independent_practice_time || this.lesson.instructional_time
    }
  },
}
</script>
<style scoped lang="scss">
@import '~@styles/variables';
.lessons {
  width: 100%;
  margin-top: 9px;
  padding: 15px 65px 20px 65px;
  border-top: 1px solid #d8d8d8;
}

.accordion-trigger {
  display: flex;
  flex-direction: column;
  min-height: 70px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  .left-pane {
    display: flex;
    width: 100%;
    align-items: center;
    .section-text {
      margin-left: 15px;
    }
  }
  .right-pane {
    a {
      width: max-content;
      border-color: #c7c7c7;
      font-size: 12px;
      font-weight: 400;
      background-color: white;
      img {
        width: 18px;
        height: 18px;
        margin-right: 5px;
      }
    }
    a:hover {
      border-color: #345165;
      color: #345165;
    }
  }
}
.lesson-pane {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  cursor: auto;
  border-color: #c7c7c7;
  border-style: solid;
  border-width: 1px;
  border-radius: 14px;
  padding: 15px 25px 15px 25px;
  margin: 10px 0px 10px 0px;
  background-color: #fff;
  h1 {
    font-size: 18px;
    font-weight: 700;
  }
  h3 {
    font-size: 16px;
    font-weight: 400;
  }
}

.drag-icon {
  width: 25px;
  height: 25px;
  margin-left: -20px;
}

// Css xs => md
@media screen and (max-width: 769px) {
  .lessons {
    padding: 15px 20px 20px 20px;
  }
}

</style>
