<template>
  <horizon-modal id="create-administrator-modal" modal-title="Create Administrator">
    <template v-slot:content>
      <div class="form">
        <div class="form-row">
          <div class="col-md-6 col-xs-12 form-group div-1 student-form-group">
            <label for="administrator-first-name" class="d-block">First Name</label>
            <input
            id="administrator-first-name"
            v-model="administratorData.firstName"
            :class="['student-form-input', { 'highlighter-red': $v.administratorData.firstName.$error }]">
          </div>
          <div class="col-md-6 col-xs-12 form-group div-1 student-form-group">
            <label for="administrator-last-name" class="d-block">Last Name</label>
            <input
            id="administrator-last-name"
            v-model="administratorData.lastName"
            type="text"
            :class="['student-form-input', { 'highlighter-red': $v.administratorData.lastName.$error }]">
          </div>
          <div class="col-md-12 col-xs-12 form-group div-1 student-form-group">
            <label for="administrator-email-address" class="d-block">Email Address</label>
            <input
            id="administrator-email-address"
            v-model="administratorData.email"
            type="text"
            :class="['student-form-input', { 'highlighter-red': $v.administratorData.email.$error }]">
          </div>
        </div>
        <div class="col-12 d-flex justify-content-end">
          <button class="btn btn-primary student-modal-button" @click="createAdministrator">
            Create Administrator
          </button>
        </div>
      </div>
    </template>
  </horizon-modal>
</template>

<script>
import HorizonModal from '@shared/horizon_modal'
import { required, email } from 'vuelidate/lib/validators'
export default {
  components: {
    HorizonModal,
  },
  data () {
    return {
      administratorData: {
        firstName: '',
        lastName: '',
        email: '',
        role: 'District Admin',
      },
    }
  },
  validations: {
    administratorData: {
      firstName: { required },
      lastName: { required },
      email: { required, email },
    }
  },
  methods: {
    createAdministrator () {
      this.$v.$touch()
      if(this.$v.$invalid) {
        return
      }
      let administratorData = _.cloneDeep(this.administratorData)
      $('#create-administrator-modal').modal('hide')
      this.resetAdministratorData()
      this.$emit('administrator-added', administratorData)
    },
    resetAdministratorData() {
      this.administratorData = {
        firstName: '',
        lastName: '',
        email: '',
        role: 'District Admin'
      }
      this.$v.$reset()
    },
  }
}
</script>

<style scoped lang='scss'>
@import "~@styles/shared/students/student-form.scss";
</style>
