const MAXIMUM_VALUE_OFFSET = 100
const MINIMUM_VALUE_OFFSET = 100

export default class {

  constructor (studentPracticeTests) {
    this.studentPracticeTests = studentPracticeTests
  }

  dataSet () {
    return _.map(this.studentPracticeTests.sort((a,b) => (a.id - b.id)), (assessment) => ({ x: assessment.name, y: assessment.score }))
  }

  minimumScaleValue () {
    const minimumScore = _.min(_.map(this.studentPracticeTests, 'score'))
    const minimumYValue = minimumScore - MINIMUM_VALUE_OFFSET
    return _.ceil(minimumYValue, -2)
  }

  maximumScaleValue () {
    const maximumScore = _.max(_.map(this.studentPracticeTests, 'score'))
    const maximumYValue = maximumScore + MAXIMUM_VALUE_OFFSET
    return _.floor(maximumYValue, -2)
  }

}
