import { render, staticRenderFns } from "./english_answer_row.vue?vue&type=template&id=2a8dc968&scoped=true&"
import script from "./english_answer_row.vue?vue&type=script&lang=js&"
export * from "./english_answer_row.vue?vue&type=script&lang=js&"
import style0 from "./english_answer_row.vue?vue&type=style&index=0&id=2a8dc968&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a8dc968",
  null
  
)

export default component.exports