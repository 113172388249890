<template>
  <horizon-modal
    id="topic-area-form-modal"
    :modal-title="actionVerbiage + ' ' + subsectionArea.name + ' :: Topic Area'"
    img-src="https://assets.website-files.com/5d6d56640b8020729636d3ce/5d6d56640b80206bae36d41c_Asset%204%402x.png">
    <template v-slot:content>
      <div class="flex-column form m-2 row">
        <div class="col-sm-12 form-group">
          <label for="topicAreaName">Topic Area Name</label>
          <input
            id="topicAreaName"
            v-model="topicArea.name"
            type="text"
            :class="{ 'highlighter-red': $v.topicArea.name.$error }"
            class="form-control">
        </div>
        <div class="col-sm-6 form-group">
          <label for="topicAreaName">Topic Area Letter Code</label>
          <input
            id="topicAreaName"
            v-model="topicArea.letterCode"
            type="text"
            :class="{ 'highlighter-red': $v.topicArea.letterCode.$error }"
            class="form-control">
        </div>
        <div class="d-flex justify-content-end mt-2">
          <slot name="buttons"/>
          <button
            class="btn btn-primary"
            style="min-width: 110px;"
            @click="saveTopicArea">
            Done
          </button>
        </div>
      </div>
    </template>
  </horizon-modal>
</template>

<script>
import Mixins from '@submodules/mixins/mixin.js'
import HorizonModal from '@shared/horizon_modal'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    HorizonModal
  },
  mixins: [Mixins],
  props: {
    topicAreaProp: {
      type: Object,
      default () {
        return {
          name: '',
          categoryId: '',
          subsectionAreaId: '',
          letterCode: ''
        }
      }
    },
    subsectionAreaProp: {
      type: Object,
      default () {
        return {
          assessmentOn: '',
          categoryId: ''
        }
      }
    },
    categoryGroupProp: {
      type: Object,
      default () {
        return {
          id: '',
          name: ''
        }
      }
    }
  },
  data () {
    return {
      actionVerbiage: '',
      subsectionArea: {
        assessmentOn: '',
        categoryId: ''
      },
      topicArea: {
        name: '',
        categoryId: '',
        subsectionAreaId: '',
        letterCode: ''
      }
    }
  },
  watch: {
    topicAreaProp: {
      immediate: true,
      handler () {
        this.topicArea = this.deepCloneObject(this.topicAreaProp)
        if (this.isBlank(this.topicArea.id)) {
          this.actionVerbiage = 'Create'
        } else {
          this.actionVerbiage = 'Update'
        }
      }
    }
  },
  validations: {
    topicArea: {
      name: { required },
      categoryId: { required },
      letterCode: { required }
    }
  },
  methods: {
    createTopicAreaArea () {
      $('#topic-area-form-modal').modal('hide')
      this.$emit('topicAreaAdded', this.topicArea)
    },
    formData () {
      return {
        letterCode: this.topicArea.letterCode,
        name: this.topicArea.name,
        subsectionAreaId: this.subsectionArea.id,
        categoryId: this.categoryGroupProp.id,
        id: this.topicArea.id
      }
    },
    updateTopicArea () {
      $('#topic-area-form-modal').modal('hide')
      this.$emit('topicAreaUpdated', this.topicArea)
    },
    saveTopicArea () {
      if (this.topicArea.id) {
        this.updateTopicArea()
      } else {
        this.createTopicAreaArea()
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
