<template>
  <div class="content">
    <div class="bg-primary card-header no-border-radius d-flex justify-content-between">
      <h1 class="text-white">
        Individual Student Performance
      </h1>
    </div>
    <div v-if="loadingInitPracticeTestSetData || loadingInitSchoolData"
      class="card-body">
      <div class="row">
        <div class="col-12">
          <common-loading/>
        </div>
      </div>
    </div>
    <div v-else
      class="card-body">
      <div class="mb-3 row">
        <div class="col-12">
          <div class="table-responsive">
            <table class="table table-striped small-table students-perf-table">
              <thead>
                <tr>
                  <th
                    v-for="(column, cIndx) in tableColumns"
                    :key="cIndx"
                    class="cursor-hand"
                    @click="sort(column.column)">
                    {{ column.display }} <i :class="sortIconClass(column.column)"></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(entry, eIndx) in sortedStudents"
                  :key="eIndx">
                  <td
                    v-for="(column, cIndx) in tableColumns"
                    :class="compositeClass(cIndx)"
                    :key="cIndx">
                    <template v-if="column.action">
                      <div class="d-flex justify-content-end">
                        <button v-if="renderButtonContent(column, entry)" class="btn btn-sm btn-sm-table btn-outline-primary column-button" @click="column.action(entry)" v-html="column.buttonContentFn(entry)"/>
                      </div>
                    </template>
                    <template v-else-if="renderColumnFunction(column)">
                      <div v-html="column['columnFn'](entry)"/>
                    </template>
                    <template v-else>
                      <div v-html="entry[headerColumn(column)]"/>
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HorizonTable from '@shared/horizon_table.vue'
import CommonLoading from '@shared/common_loading'
export default {
  components: {
    'app-horizon-table': HorizonTable,
    'common-loading': CommonLoading
  },
  props: {
    assessmentIdProp: { default: '', type: [String,Number] },
    scheduleIdProp: { default: '', type: [String,Number] },
    assessmentType: {
      type: String,
      default: 'SAT'
    },
    isHorizonAdmin: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      students: [],
      tableColumns: [],
      assessmentNum: null,
      assessmentIndex: null,
      currentSort: 'lastName',
      currentSortDir: 'asc',
      loadingInitPracticeTestSetData: false,
      loadingInitSchoolData: false,
    }
  },
  created () {
    if(this.scheduleIdProp == 0) { return this.initSchoolData() }
    if(this.scheduleIdProp != 0) { return this.initPracticeTestSetData() }

    this.initData()
  },
  methods: {
     sort: function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc'
      }
      this.currentSort = s
    },

    displayButtonContent () {
      return 'View Score Report'
    },
    navTo (info) {
      const params = { assessmentId: info.studentPracticeTestId, studentId: info.studentId }
      if (this.isHorizonAdmin) {
        return this.$router.push({ name: 'AdminStudentScoreReportIndividual', params: params })
      } else {
        return this.$router.push({ name: 'StaffStudentScoreReportIndividual', params: params })
      }
    },
    initPracticeTestSetData () {
      this.loadingInitPracticeTestSetData = true
      this.$http.get(`/api/staff/schedules/${this.scheduleIdProp}/practice_test_dates/${this.assessmentIdProp}/student_performance_summary`).then((result) => {
        this.students        = result.data.studentPerformanceSummary
        this.assessmentNum   = result.data.assessmentCount
        this.assessmentIndex = result.data.assessmentIndex

        this.initTableColumns()
        this.loadingInitPracticeTestSetData = false
      }, (responseError) => {
        this.appendError({error: responseError.body.error, notification: true})
        this.loadingInitPracticeTestSetData = false
      })
    },
    initSchoolData () {
      this.loadingInitSchoolData = true
      let endpointURL = `/api/staff/school/student_performance_summary?practice_test_set_id=${this.assessmentIdProp}`
      if (this.isHorizonAdmin) {
        endpointURL = `/api/admin/schools/${this.$route.params.id}/student_performance_summary?practice_test_set_id=${this.assessmentIdProp}`
      }
      this.$http.get(endpointURL).then((result) => {
        this.students = result.data.studentPerformanceSummary
        this.assessmentNum = result.data.assessmentCount
        this.assessmentIndex = result.data.assessmentIndex

        this.initTableColumns()
        this.loadingInitSchoolData = false
      }, (responseError) => {
        this.appendError({error: responseError.body.error, notification: true})
        this.loadingInitSchoolData = false
      })
    },
    showColumn (type) {
      return type == 'SAT' || type == "PSAT89" || type == "PSAT10" ? true : false
    },
    formatCompositeDiff (composite, compositeDiff) {
      if (compositeDiff == 0) { return `<span class="fw-600 pl-1"> ${composite} ( - ) </span>` }
      return (compositeDiff > 0) ? `${composite}<span class="fw-600 text-success pl-1">(+${compositeDiff})</span>` : `${composite}<span class="fw-600 text-danger pl-1">(${compositeDiff})</span>`
    },
    formatAssessmentScoreCompositeOne (entry) {
      if (typeof entry.composite1 === 'undefined' || entry.composite1 === 0) { return '-' }
      return entry.composite1
    },
    formatAssessmentScoreCompositeTwo (entry) {
      if (typeof entry.composite2 === 'undefined' || entry.composite2 === 0) { return '-' }
      return this.formatCompositeDiff(entry.composite2, this.compositeDiff(entry.composite2, entry.composite1))
    },
    formatAssessmentScoreCompositeThree (entry) {
      if (typeof entry.composite3 === 'undefined' || entry.composite3 === 0) { return '-' }
      return this.formatCompositeDiff(entry.composite3, this.compositeDiff(entry.composite3, entry.composite2))
    },
    formatAssessmentScoreCompositeFour (entry) {
      if (typeof entry.composite4 === 'undefined' || entry.composite4 === 0) { return '-' }
      return this.formatCompositeDiff(entry.composite4, this.compositeDiff(entry.composite4, entry.composite3))
    },
    formatAssessmentScoreMath (entry) {
      if (this.isBlank(entry.latestMath) || entry.latestMath === 0 ) { return '-' }
      return entry.latestMath
    },
    formatAssessmentScoreRw (entry) {
      if (this.isBlank(entry.latestReadingWriting) || entry.latestReadingWriting === 0) { return '-' }
      return entry.latestReadingWriting
    },
    formatAssessmentScoreEla (entry) {
      if (this.isBlank(entry.latestEla) || entry.latestEla === 0) { return '-' }
      return entry.latestEla
    },
    formatAssessmentScoreStem (entry) {
      if (this.isBlank(entry.latestStem) || entry.latestStem === 0) { return '-' }
      return entry.latestStem
    },
    ellipsizeScheduleName (entry) {
      return this.$options.filters.ellipsize(entry.schedule, 18)
    },
    initTableColumns () {
      const methodPointers = [
        this.formatAssessmentScoreCompositeOne,
        this.formatAssessmentScoreCompositeTwo,
        this.formatAssessmentScoreCompositeThree,
        this.formatAssessmentScoreCompositeFour
      ]
      this.tableColumns = [
        { column: 'lastName', display: 'Last Name' },
        { column: 'firstName', display: 'First Name' },
        { column: 'schedule', display: 'Schedule', columnFn: this.ellipsizeScheduleName }
      ]
      for (let i=0; i<this.assessmentNum; i++) {
        this.tableColumns.push({
          column: `composite${i+1}`,
          display: `A. ${i+1}`,
          columnFn: methodPointers[i]
        })
      }
      if (this.assessmentType == 'SAT' || this.assessmentType == 'PSAT89' || this.assessmentType == 'PSAT10') {
        this.tableColumns.push({ column: 'latestMath', display: `A.${this.assessmentIndex + 1 }: Math`, columnFn: this.formatAssessmentScoreMath })
        this.tableColumns.push({ column: 'latestReadingWriting', display: `A.${this.assessmentIndex + 1 }: R&W`, columnFn: this.formatAssessmentScoreRw })
      } else if (this.assessmentType == 'ACT') {
        this.tableColumns.push({ column: 'latestEla', display: 'ELA Score', columnFn: this.formatAssessmentScoreEla })
        this.tableColumns.push({ column: 'latestStem', display: 'STEM Score', columnFn: this.formatAssessmentScoreStem })
      }
      this.tableColumns.push({ column: 'button', display: 'View Score Report', action: this.navTo, buttonContentFn: this.displayButtonContent })
    },
    headerColumn (column) {
      if (typeof column == 'string' || column instanceof String) { return column }
      if (column.rowOptions) { return '' }
      return column.column || ''
    },
    renderButtonContent (column, entry) {
      if(entry.completedAssessment == false || entry.studentPracticeTestId == null) { return }

      return column.buttonContentFn instanceof Function
    },
    renderColumnFunction (column) {
      return column.columnFn instanceof Function
    },
    compositeClass(number) {
      if (this.assessmentIndex + 3 == number) { return 'latest-composite' }
    },

    compositeDiff (latest_composite, previous_composite) {
      if (this.assessmentNum == 1) { return 0 }
      if (this.assessmentNum > 1) { return latest_composite - previous_composite }
    },

    sortIconClass (field) {
      if (field === "button") { return ""}
      if (this.currentSort !== field) { return 'fa fa-sort' }
      if (this.currentSort === field && this.currentSortDir == 'desc') { return 'fa fa-sort-up text-orange' }
      if (this.currentSort === field && this.currentSortDir == 'asc') { return 'fa fa-sort-down text-orange' }
    }
  },
  computed: {
    sortedStudents: function() {
      return this.students.sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier
        if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier
        return 0
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@styles/variables.scss';
table {
  margin-bottom: 0;
  td {
  padding-top: 0;
  padding-left: 10px;
  padding-bottom: 0px;
  padding-right: 8px;
  }
  th {
  height: 50px !important;
  }
}
table > * > tr > * {
  vertical-align: middle;
}

tbody > tr {
  &:first-of-type td {
    border-top: none;
  }
  td {
    border-color: rgb(240, 240, 240);
    height: 40px !important;

  }
  td.latest-composite {
    background: #d1dae5;
    color: #505050;
    font-weight: 600;
    text-align: left !important;
  }

  td:nth-child(1) {
    font-weight: 600;
    text-align: left !important;
    padding-left: 20px;
  }
  td:nth-child(2) {
    font-weight: 600;
    text-align: left !important;
  }
  td:nth-child(-n+3) {
    text-align: left !important;
  }
  td:nth-child(n+4) {
    text-align: center !important;
  }
  td:nth-child(4) {
    width: 90px;
  }
  td:nth-child(n-2) {
    text-align: center;
  }
  td:nth-child(n-33) {
    text-align: center;
  }
}

thead {
  background-color: $primaryColor;
  tr {
    font-size: 12px;
    font-weight: 600;
  }
  th {
    border: none;
  }
  th:nth-child(-n+3) {
    text-align: left;
  }
  th:nth-child(1) {
    padding-left: 20px;
  }
  th:last-child {
    text-align: right !important;
  }
}


tbody {
  box-shadow: inset 0px 0px 0px 1px #dee2e6;
}
tbody > * {
  font-size: 12px;
}
.column-button {
   padding: 4px 6px;
  border: 1px solid #345165;
  font-size: 10px;
  line-height: 14px;
  font-weight: 400;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  &:not(:hover) {
    background-color: #ffffff;
    color:  #696969;
  }
}

@media screen and (max-width: $mobileScreenLarge) {
  .content {
    display: none;
  }
}
</style>
