<template>
  <horizon-modal id="gradebook-response-delete-modal" modal-title="Delete Student Activity Response">
    <template v-slot:content>
      <div class="modal-message">
        <p><b>Important</b></p>
        <span class="text-block font-14">
          This action will remove the responses of the student for the selected lesson activity.
        </span>
        <br>
        <hr>
      </div>
      <div class="modal-buttons">
        <button class="btn btn-outline" @click="cancelDelete">
          <span class="font-14"> Cancel </span>
        </button>
        <button class="btn btn-danger" @click="deleteResponse">
          <span class="font-14"> Delete Student Activity Response </span>
        </button>
      </div>
    </template>
  </horizon-modal>
</template>
<script>
import HorizonModal from '@shared/horizon_modal'
export default {
  components: {
    HorizonModal
  },
  methods: {
    cancelDelete() {
      this.$emit('delete-approval', false)
      $('#gradebook-response-delete-modal').modal('hide')
    },
    deleteResponse() {
      this.$emit('delete-approval', true)
      $('#gradebook-response-delete-modal').modal('hide')
    }
  }
}
</script>
<style scoped lang='scss'>
.modal-buttons {
  display: flex;
  flex-direction: row-reverse;
}
</style>
