<template>
  <div class="card" :data-schedule-id="schedule.id">
    <div class="card-body stat-card">
      <div class="row">
        <div class="col-9">
          <div class="pull-left">
            <h4 class="font-14 lh-18 text-primary" style="padding-bottom: 4px;">
              {{ schedule.name }}
            </h4>
            <h4>{{ schedule.scheduleType }} School Day</h4>
            <h5>{{ schedule.primaryTeacherName }}</h5>
            <h5>{{ schedule.secondaryTeacherName }}</h5>
            <slot/>
          </div>
        </div>
        <div class="col-3">
          <div class="d-flex flex-row pull-right text-center">
            <div>
              <div class="enrollment-count bold-stat text-orange">
                {{ schedule.studentsEnrolled }}
              </div>
              <div class="stat-text">
                Students<br>Enrolled
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="pull-right">
        <button
          :class="`btn btn-outline-secondary`"
          @click.prevent="navTo({
            name: 'StaffCourseActivitiesCourse',
            query: schedule.courseActivity
          })">
          View Course Activity
        </button>
        <button
          v-if="hasGradebook"
          :class="`btn btn-outline-secondary`"
          @click.prevent="navTo({
            name: 'StaffGradebookShow',
            query: {
              course_name: schedule.name,
              course_id: schedule.courseActivity.courseId,
              schedule_id: schedule.courseActivity.scheduleId,
              template_id: schedule.courseActivity.templateId
              }
          })">
          Gradebook
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    schedule: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      hasGradebook: false,
    }
  },
  methods: {
    navTo (routeOptions) {
      this.$router.push(routeOptions)
    }
  },
  mounted () {
    this.$http.get(`/api/staff/course_activities/${this.schedule.courseActivity.courseId}/gradebook/data_ready/${this.schedule.courseActivity.scheduleId}`).then((res) => {
      this.hasGradebook = res.data.has_data
    }, (responseError) => {
      this.appendError({error: responseError, notification: true})
    })
  }
}
</script>
<style scoped lang='scss'>
  @import '~@styles/variables';

  .stat-card {
    min-height: 142px;
    margin-bottom: 5px;
    padding-right: 15px;
    padding-left: 15px;
  }

  .bold-stat {
		font-size: 18px;
		font-weight: 700;
  }

  .stat-text {
    color: #505050;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    padding-top: 4px;
  }

  .card-footer {
    padding: 12px 15px;
  }

</style>
