<template>
  <div class="container">
    <update-administrator-modal
      :selected-administrator="selectedAdministrator"
      @administrator-updated="updateAdministrator"/>
    <create-administrator-modal
      @administrator-added="addAdministrator"/>
    <div class="content-pane">
      <div class="pane-wrapper">
        <breadcrumbs/>
        <div class="content-heading underlined">
          <h1>View Settings</h1>
        </div>
        <div class="bordered-card">
          <h2 class="py-2">
            SETTINGS
          </h2>
          <div class="no-gutters row">
            <hr class="col-md-12">
            <div class="col-md-6 pr-3 row">
              <div class="col-md-6 my-auto">
                <label for="districtName">DISTRICT NAME</label>
              </div>
              <div class="col-md-6">
                <div class="input-group mb-3">
                  <input
                    id="districtName"
                    v-model="district.name"
                    :class="{ 'highlighter-red': $v.district.name.$error }"
                    type="text"
                    class="form-control"
                    placeholder=""
                    aria-label="District Name"
                    name="District Name"
                    required
                    @blur="$v.district.name.$touch">
                </div>
              </div>
            </div>
            <div class="col-md-6 pl-3 row">
              <div class="col-md-6 my-auto">
                <label for="districtState">STATE</label>
              </div>
              <div class="col-md-6">
                <select
                  id="districtState"
                  v-model="district.state"
                  placeholder="Select State"
                  :class="{ 'highlighter-red': $v.district.state.$error }"
                  required
                  class="form-control"
                  @blur="$v.district.state.$touch">
                  <option value="" disabled>
                    Select State
                  </option>
                  <option v-for="(state, stateIndx) in states" :key="stateIndx" :value="state">
                    {{ state }}
                  </option>
                </select>
              </div>
            </div>
            <hr class="col-md-12">
          </div>
          <div class="no-gutters row">
            <div class="col-md-12 py-2">
              <h2 class="text-uppercase">Schools</h2>
            </div>
            <hr class="col-md-12">
            <div class="col-md-12">
              <table class="schools-table">
                <tbody>
                  <tr v-for="(school, schoolIndx) in memberSchools"
                    :data-school-id="school.id"
                    :key="schoolIndx">
                    <td> {{ school.name }} </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr class="col-md-12">
          </div>
          <div class="no-gutters row">
            <div class="col-md-12 py-2">
              <h2 class="text-uppercase">Administrators</h2>
            </div>
            <hr class="col-md-12">
            <div class="col-md-12 table-responsive">
              <table class="table administrators-table">
                <thead class="bg-primary">
                  <tr>
                    <th scope="col">
                      First Name
                    </th>
                    <th scope="col">
                      Last Name
                    </th>
                    <th scope="col">
                      Email
                    </th>
                    <th scope="col"/>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(administrator, administratorIndx) in administrators"
                  :data-administrator-id="administrator.id"
                  :key="administratorIndx">
                      <td> {{ administrator.firstName }} </td>
                      <td> {{ administrator.lastName }} </td>
                      <td> {{ administrator.email }} </td>
                      <td class="action-column">
                        <button
                          class="btn btn-outline-secondary gray-hover action-btn"
                          title="Edit Administrator"
                          :id="buttonId(administrator.id)"
                          @click="showUpdateAdministratorModal(administratorIndx)">
                          <i class="fa fa-lg fa-pencil-alt"></i>
                        </button>
                        <button
                          type="button"
                          class="btn btn-outline-secondary gray-hover action-btn"
                          title="Delete Administrator"
                          @click="deleteAdministrator(administratorIndx)">
                          <i class="fa fa-2x fa-trash"></i>
                        </button>
                      </td>
                  </tr>
                  <tr class="bg-light">
                    <th/>
                    <th/>
                    <th class="text-right" colspan="2">
                      <button class="btn btn-outline-primary" style="margin-left:6px;" @click="showCreateAdministratorModal">
                        <span class="font-14">Add Administrator</span>
                      </button>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="no-gutters row">
            <div v-show="!editView" class="col-md-12 text-right">
              <button class="btn btn-outline-primary mx-2" @click="backToDistrictList">
                Cancel
              </button>
              <button class="btn btn-primary float-right" @click="saveDistrict">
                <i class="fa fa-plus-circle orange-icon"/> Create District
              </button>
            </div>
            <div v-show="editView" class="col-md-12 text-right district-edit-controls">
              <button class="btn btn-outline-primary mx-2" @click="destroyDistrict">
                <i class="fa fa-trash"/> Delete District
              </button>
              <button class="btn btn-outline-primary mx-2" @click="backToDistrictList">
                Cancel
              </button>
              <button class="btn btn-primary float-right" @click="saveDistrict">
                Save Settings
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreateAdministratorModal from './create_administrator_modal.vue'
import UpdateAdministratorModal from './update_administrator_modal.vue'
import Mixins from '@submodules/mixins/mixin.js'
import Breadcrumbs from '@shared/breadcrumbs'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    Breadcrumbs,
    CreateAdministratorModal,
    UpdateAdministratorModal
  },
  mixins: [Mixins],
  data: function () {
    return {
      district: {
        id: '',
        name: '',
        state: ''
      },
      states: ('AL AK AZ AR CA CO CT DE FL GA HI ID IL IN IA KS KY LA ME MD MA MI MN MS ' +
                'MO MT NE NV NH NJ NM NY NC ND OH OK OR PA RI SC SD TN TX UT VT VA WA WV WI ' +
                'WY').split(' '),
      schoolDistricts: [],
      editView: false,
      administrators: [],
      selectedAdministrator: {},
      selectedAdministratorId: null,
      memberSchools: []
    }
  },

  validations: {
    district: {
      name: { required },
      state: { required }
    }
  },

  created () {
    if (this.$route.params.id != undefined ) {
      this.district.id = this.$route.params.id
      this.editView = true
      this.loadDistrict()
      this.fetchAdministrators()
      this.loadMemberSchools()
    }
  },
  methods: {
    loadMemberSchools() {
      this.$http.get(`/api/admin/school_districts/${this.district.id}/schools`).then(response => {
         this.memberSchools = response.data.schools
      }, (responseError) => {
         this.appendError({ error: responseError, notification: true })
      })
    },

    backToDistrictList () {
      this.$router.push({ name: 'AdminDistrictList' })
    },

    createDistrict () {
      this.$http.post('/api/admin/school_districts', this.formData()).then(() => {
        this.appendSuccess({message: 'Created new district successfully.', notification: true})
        this.backToDistrictList()
      }, (responseError) => {
        this.appendError({error: responseError, formData: this.formData(), notification: true})
      })
    },

    destroyDistrict () {
      this.$http.delete(`/api/admin/school_districts/${this.district.id}`).then(() => {
        this.appendSuccess({message: 'Destroyed district successfully.', notification: true})
        this.backToDistrictList()
      }, (responseError) => {
        this.appendError({error: responseError, notification: true})
      })
    },

    updateDistrict () {
      this.$http.put(`/api/admin/school_districts/${this.district.id}`, this.formData()).then(() => {
        this.appendSuccess({message: 'Updated district successfully.', notification: true})
        this.backToDistrictList()
      }, (responseError) => {
        this.appendError({error: responseError, formData: this.formData(), notification: true})
      })
    },

    formData () {
      return {
        schoolDistrict: {
          id: this.district.id,
          name: this.district.name,
          state: this.district.state
        }
      }
    },

    saveDistrict () {
      this.$v.$touch()
      if (this.$v.$invalid) { return }
      if (this.district.id == '') {
        this.createDistrict()
      } else {
        this.updateDistrict()
      }
    },

    loadDistrict () {
      this.$http.get(`/api/admin/school_districts/${this.district.id}`).then((result) => {
        this.district = result.data.schoolDistrict
      }, (responseError) => {
        this.appendError({error: responseError, notification: true})
      })
    },

    fetchAdministrators () {
      this.$http.get(`/api/admin/school_districts/${this.district.id}/administrators`).then(response => {
        this.administrators = response.data.users
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    },

    buttonId (administratorId) {
      return `edit-administrator-${administratorId}`
    },

    showCreateAdministratorModal() {
      $('#create-administrator-modal').modal('show')
    },

    showUpdateAdministratorModal(administratorIndex) {
      this.selectedAdministratorId = this.administrators[administratorIndex].id
      this.selectedAdministrator = JSON.parse(JSON.stringify(this.administrators[administratorIndex]))
      $('#update-administrator-modal').modal('show')
    },

    deleteAdministrator(administratorIndex) {
      let id = this.administrators[administratorIndex].id
      this.$http.delete(`/api/admin/school_districts/${this.district.id}/administrators/${id}`).then((res) => {
          this.fetchAdministrators()
        }, (responseError) => {
          this.appendError({error: responseError, notification: true})
        })
    },

    updateAdministrator(administrator) {
      this.$http.put(`/api/admin/school_districts/${this.district.id}/administrators/${this.selectedAdministratorId}`,
        {administrator: administrator}).then((res) => {
          this.fetchAdministrators()
        }, (responseError) => {
          this.appendError({error: responseError, notification: true})
        })
    },

    addAdministrator(administrator) {
      this.$http.post(`/api/admin/school_districts/${this.district.id}/administrators`,
        {administrator: administrator}).then((res) => {
          this.fetchAdministrators()
        }, (responseError) => {
          this.appendError({error: responseError, notification: true})
        })
    }

  }
}
</script>

<style scoped lang="scss">
@import '~@styles/variables.scss';

  thead {
    height: 60px;
    border-bottom: none;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  tr {
    th {
      text-align: left;
      font-size: 16px;
      font-weight: 700;
      padding-left: 15px;
      vertical-align: middle;
    }

    td.action-column {
      text-align: right !important;
      .btn {
        margin-left: 0px !important;
        height: 40px !important;
        width: 40px !important;
        font-size: 10px;
      }
    }

    td {
      text-align: left;
      font-weight: 400px;
      font-size: 14px;
      border-top: 1px solid $themeLtGray;
      padding-left: 10px;
    }
  }

  div.district-edit-controls > button {
    font-size: 14px;
  }

table.schools-table > tbody > tr {
  height: 35px;

  td {
    float: left;
    margin-left: 20px;
    font-size: 16px;
    font-weight: 400;
    border: 0px;
  }
}
</style>
