<template>
  <div>
    <div class="content-heading underlined">
      <div class="pull-left">
        <h1>View Schedule - {{ schedule.name }}</h1>
      </div>
      <div class="pull-right total-student-number">
        <span class="d-inline text-orange student-total-number">{{ schedule.studentsEnrolled }}</span>
        <span class="d-inline fw-400">Students Enrolled</span>
      </div>
    </div>

    <div class="bordered-card">
      <div class="primary-row pt-0">
        <div>
          <h2 class="text-uppercase">Schedule Settings</h2>
        </div>
        <div class="primary-row-action">
          <!--
          <div class="btn btn-lg btn-outline-secondary font-14 fw-400 gray-hover settings-btn">
            <a href="#" class="text-decoration-0">
              <i class="fa fa-external-link fa-lg font-16" aria-hidden="true" />
              <span class="font-14">Download PDF Flyer</span>
            </a>
          </div>
          -->
          <button v-if="canUpdate" class="btn btn-primary fw-400 settings-btn">
            <span class="font-14" @click="navToEditSchedule">Edit Schedule</span>
          </button>
        </div>
      </div>

      <schedule-information-section :schedule="schedule" />
    

    <div class="pb-4 primary-row">
      <h2 class="text-uppercase">Schedule Dates</h2>
    </div>

    <classes-table-section 
      v-if="schedule.scheduleType === 'Inside' || schedule.scheduleType === 'Outside'" 
      :schedule="schedule" />
    <practice-tests-table-section :schedule="schedule" />
    <parent-nights-table-section 
      v-if="schedule.scheduleType === 'Inside' || schedule.scheduleType === 'Outside'"
      :schedule="schedule" />
  </div>
  </div>
</template>

<script>
import Mixins from "@submodules/mixins/mixin.js";
import ScheduleInformationSection from "@components/modules/schedule/view/schedule_information_section";
import ClassesTableSection from "@components/modules/schedule/view/classes_table_section";
import PracticeTestsTableSection from "@components/modules/schedule/view/practice_tests_table_section";
import ParentNightsTableSection from "@components/modules/schedule/view/parent_nights_table_section";

export default {
  components: {
    ScheduleInformationSection,
    ClassesTableSection,
    PracticeTestsTableSection,
    ParentNightsTableSection
  },
  mixins: [Mixins],
  props: {
    schedule: {
      type: Object,
      required: true,
      default() {
        return {};
      }
    },
    editScheduleRoute: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      canUpdate: false,
    }
  },
  created() {
    this.canUpdate = this.$store.getters.userPermit({controller: 'Schedule', action: 'update'})
  },
  computed: {
    classDates() {
      return this.schedule ? this.schedule.scheduleDates : [];
    }
  },
  methods: {
    navToEditSchedule() {
      this.$router.replace(this.editScheduleRoute);
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@styles/variables';

.total-student-number {
  display: inline-block;
  margin: 5px 0px 5px 6px;
  clear: none;
  color: #333;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
}

.student-total-number {
  display: inline-block;
  color: #f4701f;
  font-size: 28px;
  font-weight: 700;
}

.bordered-card {
  margin-bottom: 15px;
  padding-bottom: 0px;
}

.pb-4 {
  padding-bottom: 15px !important;
}

@media screen and (max-width: $mobileScreenExtraLarge) {
  .total-student-number {
    font-size: 20px !important;
  }

  .student-total-number {
    font-size: 22px !important;
  }

  .content-heading {
    display: inline-block;

    .pull-left {
      width: 100%;
    }

    .pull-right {
      width: 100%;
      float: left;
      text-align: left;
      margin-left: 0px;
    }
  }

  .underlined {
    border-bottom: 0px;
    padding-bottom: 0px;
  }

  .bordered-card {
    margin-top: 10px;
  }
}

@media screen and (max-width: $mobileScreenLarge) {
  .bordered-card {
    padding-bottom: 0px;

    .primary-row {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

@media screen and (max-width: $mobileScreenExtraSmall) {
  .bordered-card {
    .primary-row {
      display: block;

      div {
        width: 100%;

        .btn {
          margin-top: 5px;
          margin-left: 0px;
        }
      }
    }
  }
}
</style>
