import client from '@repositories/client.js'

const BASE_URL = '/api/admin/students'

export default class {

  constructor (schoolId) {
    this.schoolId = schoolId
  }

  async create (student) {
    const body = extractStudentInformation(student)
    body.schoolId = this.schoolId

    const data = await client.post(`${BASE_URL}`, { student: body })
    return data.student
  }

  async update (id, student) {
    const body = extractStudentInformation(student)
    const data = await client.put(`${BASE_URL}/${id}`, {student: body})
    return data.student
  }

}


function extractStudentInformation (parameters) {
  const requestFields = [
    'firstName',
    'lastName',
    'email',
    'phoneNumber',
    'studentIdNumber',
    'gradeLevel'
  ]

  return _.pick(parameters, requestFields)
}
