<template>
  <table class="parent-night-dates table table-primary w-100">
    <thead>
      <tr>
        <th class="flex-fill h2 text-align-left text-white">
          Parent Night
        </th>
        <th class="h2 text-align-left text-white date-time-separated">
          Date
        </th>
        <th class="h2 text-align-center text-white date-time-separated">
          Start Time
        </th>
        <th class="h2 text-align-center text-white date-time-separated">
          End Time
        </th>
        <th class="h2 text-align-center text-white date-time-combined" scope="col">
          Date & Time
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(parentNightDate, index) in parentNightDates"
        :key="index">
        <td class="fw-600">
          Parent Night #{{ index + 1 }}
        </td>
        <td class="fw-400 date-time-separated">
          {{ parentNightDate.meetingOn| date('dddd, MMMM D, YYYY') }}
        </td>
        <td class="fw-400 text-align-center date-time-separated">
          {{ parentNightDate.startAt }}
        </td>
        <td class="fw-400 text-align-center date-time-separated">
          {{ parentNightDate.endAt }}
        </td>
        <td class="text-align-center date-time-combined">
          {{ parentNightDate.meetingOn| date('dddd, MMMM D, YYYY') }}<br />
          {{ parentNightDate.startAt }} - {{ parentNightDate.endAt }}
        </td>
      </tr>
    </tbody>
  </table>
</template>


<script>
export default {
  props: {
    schedule: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    }
  },
  computed: {
    parentNightDates () {
      return this.schedule.parentNightDates
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@styles/modules/schedule/schedule_dates_table.scss";
</style>
