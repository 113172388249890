<template>
  <horizon-modal id="update-student-modal" modal-title="Update Student Account">
    <template v-slot:content>
      <div class="form">
        <div class="form-row">
          <div class="col-md-6 col-xs-12 div-1 form-group student-form-group">
            <input
              id="student-first-name"
              v-model="student.firstName"
              type="text"
              :class="['student-form-input', { 'highlighter-red': $v.student.firstName.$error }]">
            <label>Student First Name</label>
          </div>
          <div class="col-md-6 col-xs-12 div-2 form-group student-form-group">
            <input
              id="student-last-name"
              v-model="student.lastName"
              type="text"
              :class="['student-form-input', { 'highlighter-red': $v.student.lastName.$error }]">
            <label>Student Last Name</label>
          </div>
        </div>
        <div class="form-row">
          <div class="col-12 form-group student-form-group">
            <input
              id="student-email"
              v-model="student.email"
              type="text"

              :class="['student-form-input', { 'highlighter-red': $v.student.email.$error }]">
            <label>Student Email Address</label>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-6 col-xs-12 div-1 form-group student-form-group">
            <input
              id="student-phone-number"
              v-model="student.phoneNumber"
              type="text"
              class="student-form-input">
            <label>Student Phone Number</label>
          </div>
          <div class="col-md-6 col-xs-12 div-2 form-group student-form-group">
            <input
            id="student-id-number"
            v-model="student.studentIdNumber"
            type="text"
            :class="['student-form-input', { 'highlighter-red': $v.student.studentIdNumber.$error }]">
            <label>Student ID Number</label>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-6 col-xs-12 div-1 form-group student-form-group">
            <select
              id="student-schedule"
              v-model="scheduleId"
              :class="['student-form-input', { 'highlighter-red': $v.scheduleId.$error }]">
              <option
                v-for="schedule in schedules"
                :key="schedule.id"
                :selected="schedule.id === scheduleId"
                :value="schedule.id">
                {{ schedule.name }}
              </option>
            </select>
            <label>Course Schedule</label>
          </div>
          <div class="col-md-6 col-xs-12 div-2 form-group student-form-group">
            <select
              id="student-grade-level"
              v-model="student.gradeLevel"

              :class="['student-form-input', { 'highlighter-red': $v.student.gradeLevel.$error }]">
              <option
                v-for="gradeLevel in gradeLevelOptions"
                :key="gradeLevel.value"
                :value="gradeLevel.value">
                {{ gradeLevel.name }}
              </option>
            </select>
            <label>Grade Level</label>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-end">
          <common-button
            class-list="btn btn-primary student-modal-button"
            :on-click="confirmDelete"
            button-text="Delete Student"/>
          <common-button
            style="margin-left: 15px;"
            class-list="btn btn-primary student-modal-button"
            :on-click="submit"
            button-text="Update Student"/>
        </div>
      </div>
    </template>
  </horizon-modal>
</template>

<script>
import HorizonModal from '@shared/horizon_modal'
import CommonButton from '@shared/common_button'
import GradeLevel from '@utils/grade_levels'
import { required, email } from 'vuelidate/lib/validators'

export default {
  components: {
    HorizonModal,
    CommonButton
  },
  props: {
    schedules: {
      type: Array,
      required: true
    },
    initialEnrollment: {
      type: Object,
      required: true
    },
    triggerDeleteModal: {
      type: Function,
      required: true
    },
    studentRepository: {
      type: Object,
      required: true
    },
    enrollmentRepository: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      student: {
        firstName: this.initialEnrollment.user.firstName,
        lastName: this.initialEnrollment.user.lastName,
        email: this.initialEnrollment.user.email,
        phoneNumber: this.initialEnrollment.user.phoneNumber,
        studentIdNumber: this.initialEnrollment.user.studentIdNumber,
        gradeLevel: this.initialEnrollment.user.gradeLevel
      },
      scheduleId: this.initialEnrollment.scheduleId
    }
  },
  validations: {
    student: {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      studentIdNumber: { required },
      gradeLevel: { required }
    },
    scheduleId: { required }
  },
  computed: {
    gradeLevelOptions () {
      return GradeLevel.values()
    }
  },
  watch: {
    initialEnrollment () {
      this.student = {
        firstName: this.initialEnrollment.user.firstName,
        lastName: this.initialEnrollment.user.lastName,
        email: this.initialEnrollment.user.email,
        phoneNumber: this.initialEnrollment.user.phoneNumber,
        studentIdNumber: this.initialEnrollment.user.studentIdNumber,
        gradeLevel: this.initialEnrollment.user.gradeLevel
      }
      this.scheduleId = this.initialEnrollment.scheduleId
    }
  },
  methods: {
    async submit () {
      this.$v.$touch()
      if(this.$v.$invalid) {
        return
      }
      let hasValidationError = false
      await this.updateEnrollmentInformation().catch(error => {
        if (error.response.status === 422) {
          let errorMessage = []
          _.forEach(error.response.data.error, (err) => {
            errorMessage.push(err)
          })
          hasValidationError = true
          this.appendError({ error: errorMessage, notification: true })
          // throw new Error("Validation Error")
        }
      })
      if (hasValidationError) {
        return
      } else {
        this.$emit('updated')
      }
    },
    async updateEnrollmentInformation () {
      const student = await this.studentRepository.update(
        this.initialEnrollment.userId,
        { ...this.student }
      )
      if (this.scheduleId !== this.initialEnrollment.scheduleId) {
        await this.enrollmentRepository.update(this.initialEnrollment.id, {
          scheduleId: this.scheduleId
        })
      }
    },
    confirmDelete() {
      // tentative until we get to find a way to drive modals via data
      $('#update-student-modal').modal('hide');
      this.triggerDeleteModal()
    }
  }
}
</script>

<style scoped lang='scss'>
@import '~@styles/variables';
@import "~@styles/shared/students/student-form.scss";

@media screen and (max-width: $mobileScreenMedium) {
  #update-student-modal {
    .col-md-6 {
      padding-right: 5px;
      padding-left: 5px;
    }
  }
}

@media screen and (max-width: $mobileScreenSmall) {
  .justify-content-end {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;    

    .btn {
      margin-left: 0px;
    }
  }
}

@media screen and (max-width: $mobileScreenExtraSmall) {
  .student-modal-button {
    height: auto;
  }
}

div input#student-email {
  text-transform: lowercase
}
</style>
