<template>
  <div>
    <!-- Composite Scores -->
    <div class="section-header">
      <h1>Composite Score</h1>
    </div>
    <div class="composite-score report-section">
      <div class="score-breakdown-row">
        <div class="donut-chart-wrapper">
          <donut-chart
            :chart-id="'composite-score'"
            :chart-title="'Composite Score'"
            :average-score="compositeData.studentScore"
            :is-average-score="false"
            :min-score="compositeData.minScore"
            :max-score="compositeData.maxScore"
            :color="'#f4701f'"/>
        </div>
        <div class="composite-stat-wrapper my-auto">
          <div class="composite-stats">
            <div class="stat-text-wrapper">
              <div class="stat-lg">
                {{ compositeData.nationalPercentile }}<span class="superscript-lg">{{ getOrdinalSuffix(compositeData.nationalPercentile) }}</span>
              </div>
              <div class="stat-sub-text">
                Nationally Representative Sample Percentile
              </div>
            </div>
            <div v-if="evalAssessmentType" class="stat-text-wrapper">
              <div class="stat-lg">
                {{ compositeData.userPercentile }}<span class="superscript-lg">{{ getOrdinalSuffix(compositeData.userPercentile) }}</span>
              </div>
              <div class="stat-sub-text">
                SAT User Percentile
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Section Scores -->
    <div class="section-header">
      <h1>Section Scores</h1>
    </div>
    <div class="section-scores report-section">
      <div class="no-gutters row score-breakdown-row">
        <!-- ACT: 'English', 'Math', 'Reading', 'Science'
        {{assessmentTypeProp}}: 'Reading & Writing' 'Math' -->
        <div v-for="(section, sIndx) in sectionData" :key="sIndx" class="col-6">
          <div class="section-header-sm">
            <h2>{{ section.sectionName }} Score</h2>
            <div class="muted-text">
              Benchmark Score: {{ section.benchmarkScore }}
            </div>
          </div>
          <div class="stat-row">
            <div class="donut-chart-wrapper w-50">
              <donut-chart
                :chart-id="section.sectionName.replaceAll(' ','-')+'-score'"
                chart-title-font-size="42px"
                :average-score="section.studentScore"
                :is-average-score="false"
                :min-score="section.minScore"
                :max-score="section.maxScore"
                :color="getCategoryColor(section.sectionName)"/>
            </div>
            <div class="stat-wrapper w-50">
              <div class="benchmark-reached-wrapper">
                <span class="benchmark-reached"
                  v-show="benchmarkReached(section)">
                    You've Reached the Benchmark Score!
                  </span>
              </div>
              <div class="stats">
                <div class="stat-text-wrapper">
                  <div class="stat">
                    {{ section.nationalPercentile }}<span class="superscript">{{ getOrdinalSuffix(section.nationalPercentile) }}</span>
                  </div>
                  <div class="sub-text">
                    Nationally Representative Sample Percentile
                  </div>
                </div>
                <div v-if="evalAssessmentType" class="stat-text-wrapper ml-3">
                  <div class="stat">
                    {{ section.userPercentile }}<span class="superscript">{{ getOrdinalSuffix(section.userPercentile) }}</span>
                  </div>
                  <div class="sub-text">
                    SAT User Percentile
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DonutChart from '@shared/charts/donut_chart'

export default {
  components: {
    DonutChart
  },
  props: {
    assessmentTypeProp: { type: String, required: true },
    compositeData: {
      type: Object,
      required: true
    },
    sectionData: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      data: {}
    }
  },
  computed: {
    evalAssessmentType: function () {
      return this.assessmentTypeProp == 'SAT'
    }
  },
  methods: {
    benchmarkReached (section) {
      return section.studentScore >= section.benchmarkScore
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@styles/variables';
  .section-scores {
    .donut {
      .inner-donut-text {
        font-size: 48px !important;
      }
    }
  }

.report-section {
  padding-bottom: 10px;
}

.score-breakdown-row {
  padding: 20px 0 10px 20px;
  display: flex;
  > .col-6:nth-child(even) {
    padding-left: 20px;
    border-left: 1px solid #e8e8e8;
  }
  > .col-6:nth-child(odd) {
    padding-right: 20px;
  }
}

.composite-score-description {
  padding-top: 10px;
  .composite-description-header {
    font-size: 14px;
    font-weight: 700;
    color: $primaryColor;
  }
  .composite-description-body {
    font-size: 12px;
    line-height: 18px;
    padding-top: 10px;
    color: $themeGray;
    &.sm {
      font-size: 10px;
      line-height: 16px;
    }
    .highlight {
      color: $primaryColor;
      font-weight: 700;
    }
  }
}

.benchmark-reached {
  color: $themeOrange;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
}

// override from statistics.scss
.stat-row .donut-chart-wrapper {
  padding-top: 30px;
}

.stat-wrapper {
  margin-left: 20px;
  padding-left: inherit;
}

.stat-wrapper .stats {
  padding: 0;
}

.stat-row {
  padding: 0;
}

.composite-score {
  .score-breakdown-row {
    display: flex;

    .inner-donut-text {
      font-size: 72px;
    }

    .donut-chart-wrapper {
      width: 40%;
    }

    .composite-stat-wrapper {
      width: 60%;
      padding: 0px 0px 0px 40px;
      margin: 0px;

      .stat-text-wrapper {
        width: 50%;
        padding: 0px 25px;
        margin: 0px;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: $mobileScreenExtraLarge) {
  .composite-score {
    .score-breakdown-row {
      padding-left: 0px;

      .inner-donut-text {
        font-size: 50px;
      }
    }
  }

  .composite-stat-wrapper {
    .stat-text-wrapper {
      .stat-lg {
        font-size: 50px;
        line-height: 57px;
      }
    }
  }

  .section-scores {
    .score-breakdown-row {
      padding-left: 0px;

      .col-6:nth-child(even) {
        padding-left: 10px;
      }
      .col-6:nth-child(odd) {
        padding-right: 10px;
      }
      .stat-wrapper {
        margin-left: 15px;

        .mr-3 {

        }
      }
    }
  }
}

@media screen and (max-width: $mobileScreenLarge) {
  .composite-score {
    .score-breakdown-row {
      display: block;

      .donut-chart-wrapper {
        width: 100%;
        margin-bottom: 20px;
      }

      .composite-stat-wrapper {
        width: 100%;
        padding: 0px;

        .stat-text-wrapper {
          .stat-lg {
            font-size: 80px;
            line-height: 87px;
          }
        }
      }
    }
  }
  .section-scores {
    .score-breakdown-row {
      display: block;

      .col-6 {
        display: block;
        width: 100%;
        max-width: 100%;
        border: 0;
        padding: 0;
      }
    }
  }
}

@media screen and (max-width: $mobileScreenMedium) {
  h1 {
    font-size: 20px;
  }
}

@media screen and (max-width: $mobileScreenSmall) {
  h1 {
    font-size: 16px;
  }
}

@media screen and (max-width: $mobileScreenExtraSmall) {
  h1 {
    font-size: 14px;
  }

  .composite-stat-wrapper {
    .stat-text-wrapper {
      .stat-lg {
        font-size: 50px !important;
        line-height: 57px !important;
      }
    }
  }

  .section-scores {
    .stat {
      font-size: 25px;
    }
  }
}

@media screen and (max-width: 319px) {
  .composite-stat-wrapper {
    .stat-text-wrapper {
      .stat-lg {
        font-size: 30px !important;
        line-height: 37px !important;
      }
    }
  }
}
</style>
