<template>
  <div class="container">
    <div class="content-pane">
      <div class="pane-wrapper">
        <breadcrumbs/>
        <div class="content-heading underlined">
          <h1>{{ assessment.assessmentType }} - Answer Keys</h1>
          <button class="btn btn-primary float-right" @click="newAnswerKey">
            <i class="fa fa-plus-circle orange-icon"/> Create Answer Key
          </button>
        </div>
        <div class="row">
          <div
            v-for="answerKey in answerKeys"
            :key="answerKey.id"
            class="col-md-4 col-xs-12 mt-3"
            :v-if="answerKeys.length > 0">
            <slotted-card>
              <template v-slot:header>
                <h2>Assessment #{{ answerKey.answerKeyTestId }}</h2>
                <h3>{{ answerKey.answerKeyName }}</h3>
              </template>
              <template v-slot:footer>
                <div class="float-right text-right">
                  <button class="btn btn-outline-secondary" @click="editAnswerKey(answerKey)">
                    Settings
                  </button>
                </div>
              </template>
            </slotted-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from '@submodules/mixins/mixin.js'
import Breadcrumbs from '@shared/breadcrumbs'
import SlottedCard from '@shared/slotted_card'

export default {
  components: {
    Breadcrumbs,
    SlottedCard
  },
  mixins: [Mixins],
  data () {
    return {
      assessmentId: '',
      assessment: {},
      answerKeys: []
    }
  },
  created () {
    if (this.$route.params.id != undefined ) {
      this.assessmentId = this.$route.params.id
      this.initData()
    }
  },
  methods: {
    fetchAnswerKeys () {
      this.$http.get(`/api/admin/assessments/${this.assessmentId}/answer_keys`).then(response => {
        console.log('Assessment Keys', response)
        this.answerKeys = response.data.answerKeys
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    },
    fetchCategoryGroups () {
      const assessmentId = this.$route.params.id
      this.$http.get(`/api/admin/assessments/${assessmentId}`).then(response => {
        this.assessment = response.data.assessment
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    },
    initData () {
      this.fetchAnswerKeys()
      this.fetchCategoryGroups()
    },
    editAnswerKey (answerKey) {
      this.$router.push({name: 'AdminAssessmentAnswerKeyEdit', params: { assessmentId: this.assessmentId, answerKeyId: answerKey.id } })
    },
    newAnswerKey () {
      // This is so we don't have a user adding answer rows
      this.$http.post(`/api/admin/assessments/${this.assessmentId}/answer_keys`, this.formData()).then(() => {

      }, (responseError) => {
        this.appendError({error: responseError.body.error, formData: this.formData() })
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
