<template>
  <div class="nav-menu-mobile-container">
    <router-link :to="{ name: 'ParentDashboard' }" class="menu-link-mobile head">
      <div class="nav-menu-div">
        <div class="nav-menu-title-text">Dashboard</div>
      </div>
    </router-link>
    <router-link :to="{ name: 'ParentAssessmentResults' }" class="menu-link-mobile head">
      <div class="nav-menu-div">
        <div class="nav-menu-title-text">Assessment Results</div>
      </div>
    </router-link>
    <router-link :to="{ name: 'ParentCourseSchedule' }" class="menu-link-mobile head">
      <div class="nav-menu-div">
        <div class="nav-menu-title-text">Course Schedule</div>
      </div>
    </router-link>
    <a :href="courseResourcesUrl" target="_blank" class="menu-link-mobile head">
      <div class="nav-menu-div">
        <div class="nav-menu-title-text">Course Resources</div>
      </div>
    </a>
  </div>
</template>

<script>
import ExternalRoutes from '../routes/external_links'

export default {
  computed: {
    courseResourcesUrl() {
      return ExternalRoutes.PARENT.COURSE_RESOURCES
    }
  }
}
</script>

<style scoped lang='scss'>
  @import '~@styles/nav.scss';
</style>
