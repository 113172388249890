<template>
  <div class="container">
    <ul class="justify-content-start nav pt-1">
      <li class="nav-item">
        <router-link :to="{ name: 'ParentDashboard' }" class="logout logout nav-link text-primary">
          Dashboard
        </router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'ParentAssessmentResults' }" class="logout logout nav-link text-primary">
          Assessment Results
        </router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'ParentCourseSchedule' }" class="logout logout nav-link text-primary">
          Course Schedule
        </router-link>
      </li>
      <li class="nav-item">
        <a :href="courseResourcesUrl" target="_blank" class="logout logout nav-link text-primary">
          Course Resources
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import ExternalRoutes from '../routes/external_links'

export default {
  computed: {
    courseResourcesUrl() {
      return ExternalRoutes.PARENT.COURSE_RESOURCES
    }
  }
}
</script>

<style scoped lang='scss'>
  @import '~@styles/nav.scss';
</style>
