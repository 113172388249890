<template>
  <div class="row">
    <confirm-reset-modal
      v-if="selectedLearnosityAssessment"
      :learnosityAssessmentProp ="selectedLearnosityAssessment"
      :selectedTestSectionProp="selectedTestSection"
      :selectedTestSectionStatusProp="selectedTestSectionStatus"
      @resetted="resetAssessment(learnosityAssessmentId)"/>
    <div class="col-12">
      <div class="card">
        <div>
          <table class="table">
            <thead>
              <tr>
                <th scope="col" class="cursor-hand text-left" @click="toggleSort('lastName')">
                  Last Name <i :class="sortIconClass('lastName')"/>
                </th>
                <th scope="col" class="cursor-hand text-left" @click="toggleSort('firstName')">
                  First Name <i :class="sortIconClass('firstName')"/>
                </th>
                <th scope="col" class="cursor-hand text-left">
                  Student ID
                </th>
                <th scope="col" class="cursor-hand text-left">
                  Timing
                </th>
                <th scope="col" class="cursor-hand text-left"
                  v-for="(item,index) in courseSectionsHeader"
                  :key="index"
                >
                 {{ item.name }}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in studentResponses"
                :key="index"
                :class="rowParityClass(index)">
                <td class="text-left fw-600">
                  {{ item.lastName }}
                </td>
                <td class="text-left fw-600">
                  {{ item.firstName }}
                </td>
                <td class="text-left">
                  {{ item.studentIdNumber }}
                </td>
                <td class="text-left">
                  <p class="timing-text"> {{ timingName(item.timing) }} </p>
                  <label class="switch switch-left">
                      <input
                        :disabled="!canUpdateSchedule"
                        v-model="item.timing"
                        type="checkbox"
                        :class="setTimingClass(item.timing)"
                        @click="setTiming(item.studentPracticeTestId)"
                        >
                      <span class="round slider text-left"/>

                    </label>


                </td>
                <td class="text-left test-item" v-if="item.englishStatus" :class="(itemStatus(item.englishStatus))">
                  {{ item.englishStatus }}
                  <span v-if="showResetButton(item.englishStatus)" class="fa fa-trash text-danger cursor-hand" @click="openConfirmResetModal(item, 'English', item.englishStatus, item.englishLearnosityAssessmentId)"></span>
                </td>
                 <td class="text-left test-item" v-if="item.mathStatus" :class="(itemStatus(item.mathStatus))">
                  {{ item.mathStatus }}
                  <span v-if="showResetButton(item.mathStatus)" class="fa fa-trash text-danger cursor-hand" @click="openConfirmResetModal(item, 'Math', item.mathStatus, item.mathLearnosityAssessmentId)"></span>
                </td>
                <td class="text-left test-item" v-if="item.readingStatus" :class="(itemStatus(item.readingStatus))">
                  {{ item.readingStatus }}
                  <span v-if="showResetButton(item.readingStatus)" class="fa fa-trash text-danger cursor-hand" @click="openConfirmResetModal(item, 'Reading', item.readingStatus, item.readingLearnosityAssessmentId)"></span>
                </td>
                <td class="text-left test-item" v-if="item.scienceStatus" :class="(itemStatus(item.scienceStatus))">
                  {{ item.scienceStatus }}
                  <span v-if="showResetButton(item.scienceStatus)" class="fa fa-trash text-danger cursor-hand" @click="openConfirmResetModal(item, 'Science', item.scienceStatus, item.scienceLearnosityAssessmentId)"></span>
                </td>
                <td class="text-left test-item" v-if="item.writingStatus" :class="(itemStatus(item.writingStatus))">
                  {{ item.writingStatus }}
                  <span v-if="showResetButton(item.writingStatus)" class="fa fa-trash text-danger cursor-hand" @click="openConfirmResetModal(item, 'Writing', item.writingStatus, item.writingLearnosityAssessmentId)"></span>
                </td>
                <td class="text-left test-item" v-if="item.mathNoCalcStatus" :class="(itemStatus(item.mathNoCalcStatus))">
                  {{ item.mathNoCalcStatus }}
                  <span v-if="showResetButton(item.mathNoCalcStatus)" class="fa fa-trash text-danger cursor-hand" @click="openConfirmResetModal(item, 'Math No Calc', item.mathNoCalcStatus, item.mathNoCalcLearnosityAssessmentId)"></span>
                </td>
                <td class="text-left test-item" v-if="item.mathCalcStatus" :class="(itemStatus(item.mathCalcStatus))">
                  {{ item.mathCalcStatus }}
                  <span v-if="showResetButton(item.mathCalcStatus)" class="fa fa-trash text-danger cursor-hand" @click="openConfirmResetModal(item, 'Math Calc', item.mathCalcStatus, item.mathCalcLearnosityAssessmentId)"></span>
                </td>
                <td>
                  <button v-if="(item.completedAssessment)" class="btn btn-outline-secondary show-score-btn" @click="navToScoreReport(item)">View Score Report </button>
                </td>
              </tr>
            </tbody>
          </table>
          <common-table-pagination
            v-if="showPagination"
            :meta="meta"
            :state-change-callback="updateTable"
            @paginate-data="paginateData"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import CommonTablePagination from '@submodules/components/common_table/pagination'
import ConfirmResetModal from './confirm_reset_modal'
export default {
  components: {
    CommonTablePagination,
    ConfirmResetModal
  },
  props: {
    studentResponses: {
      type: Array,
      required: false,
      default: [],
    },
    showPagination: {
      type: Boolean,
      default: true
    },
    meta: {
      type: Object,
      default () {
        return {}
      }
    },
    stateChangeCallback: {
      type: Function,
      default: function () { return '' },
      required: false
    },
    canUpdateSchedule: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      sortBy: {field: '', direction: ''},
      studentData: [],
      showConfirmResetModal: false,
      selectedLearnosityAssessment: {},
      selectedTestSection: "",
      selectedTestSectionStatus: "",
      learnosityAssessmentId: "",
      courseSectionsHeader: [],
      canReset: false,
    }
  },

  created () {
    this.setData()
    this.courseSections();
    this.canReset = this.$store.getters.userPermit({controller: 'LearnosityAssessmentsResetting', action: 'create'})

  },
  methods: {
    ...mapGetters(['userSchool']),
    setTimingClass (timing) {
      if (timing == true )   { return 'timed' }
      if (timing == false ) { return "untimed" }
    },
    timingName (timing) {
      if (timing == true) { return "Timed"
      } else { return "Untimed" }
    },
    setTiming (studentPracticeTestId) {
      this.$http.post(`/api/staff/student_practice_tests/${studentPracticeTestId}/timings`).then(() => {
      this.$emit('paginate-data', this.meta)
      }, (responseError) => {
        this.appendError({error: responseError, notification: true })
      })
    },
    courseSections(){
      if(this.userSchool().actRelated){
        this.courseSectionsHeader = [
          {name:'English'},
          {name:'Math'},
          {name:'Reading'},
          {name:'Science'},
        ]
      } else if(this.userSchool().satRelated) {
        this.courseSectionsHeader = [
          {name: 'Reading'},
          {name: 'Writing'},
          {name: 'Math (No Calc)'},
          {name: 'Math (Calc)'},
        ]
      }
    },
    navToScoreReport (item) {
      const params = { assessmentId: item.studentPracticeTestId, studentId: item.studentId }
      return this.$router.push({ name: 'StaffStudentScoreReportIndividual', params: params })
    },

    itemStatus (status) {
      if(status == 'Completed') return 'text-success'
      if(status == 'In Progress') return 'text-warning'
    },
    setData() {
      if (_.isEmpty(this.meta)) {
        const data = this.sort(this.studentResponses)
        this.studentData = data
      } else {
        this.sortBy.field = this.meta.sort
        this.sortBy.direction = this.meta.order
      }
    },
    rowParityClass(idx) {
      return (idx % 2 === 0) ? 'even' : 'odd'
    },
    toggleSort(field) {
      if (this.sortBy.field != field) {
        this.sortBy.field = field
        this.sortBy.direction = 'asc'
      } else if (this.sortBy.direction == 'desc') {
        this.sortBy.direction = 'asc'
      } else {
        this.sortBy.direction = 'desc'
      }
      if (_.isEmpty(this.meta)) {
        this.setData()
      } else {
        this.$emit('sort-data', this.sortBy)
      }
    },
    sortIconClass(field) {
      if (this.sortBy.field !== field) {
        return 'fa fa-sort'
      }
      if (this.sortBy.direction == 'asc') {
        return 'fa fa-sort-up text-orange'
      }
      return 'fa fa-sort-down text-orange'
    },
    sort(data) {
      let sorted = []
      if (this.sortBy.field == '') {
        this.sortBy.field = 'lastName'
        this.sortBy.direction = 'desc'
      }
      if (typeof data[0][this.sortBy.field] == 'string') {
        if (this.sortBy.direction == 'desc') {
          sorted = data.sort((a, b) => (a[this.sortBy.field].localeCompare(b[this.sortBy.field])))
        } else {
          sorted = data.sort((a, b) => (b[this.sortBy.field].localeCompare(a[this.sortBy.field])))
        }
      }
      if (typeof data[0][this.sortBy.field] == 'number') {
        if (this.sortBy.direction == 'desc') {
          sorted = data.sort((a, b) => (a[this.sortBy.field] - b[this.sortBy.field]))
        } else {
          sorted = data.sort((a, b) => (b[this.sortBy.field] - a[this.sortBy.field]))
        }
      }
      return sorted
    },
    updateTable () {
      try {
        this.stateChangeCallback()
      } catch (e) {
        console.log('updateTable was called but stateChangeCallback was not set')
      }
    },
    paginateData(meta) {
      this.$emit('paginate-data', meta)
    },
    openConfirmResetModal (item, testSection, status, id) {
      this.showConfirmResetModal = true
      this.selectedLearnosityAssessment = item
      this.selectedTestSectionStatus = status
      this.selectedTestSection = testSection
      this.learnosityAssessmentId = id
      this.$nextTick(() => { $('#confirm-reset-modal').modal('show') })
    },
    resetAssessment (id) {
      this.$http.post(`/api/learnosity_assessments/${id}/resettings`).then(() => {
      this.$emit('fetch-online-assessments')
      this.appendSuccess({ message: `${this.selectedTestSection} section assessment reset successfully`, notification: true })
      }, (responseError) => {
        this.appendError({error: responseError, notification: true })
      })
    },
    showResetButton(status) {
      if (!this.canReset) { return false }
      if (status == 'Completed' || status == 'In Progress') { return true }
    }
  }
}
</script>

<style lang="scss" scoped>
.show-score-btn {
  padding: 4px 6px;
  border: 1px solid #345165;
  font-size: 10px;
  line-height: 14px;
  font-weight: 400;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  &:not(:hover) {
    background-color: #ffffff;
    color:  #696969;
  }
}

thead {
  th {
    padding-left: 7px;
    padding-right: 7px;
  }
}

tbody {
  td,th {
    padding: 7px;
    vertical-align: middle;
  }
  tr {
    &.odd {
      background-color: #f7f7f7;
    }
    &.even {
      background-color: #ffffff;
    }
  }
}
  td.untimed {
    background: white;
    border: 1px solid #e7e7e7 !important;

  }
  .timing-text {
    position: absolute !important;
    margin-top: 28px;
  }
  .switch-left {
    float: left !important;
  }

</style>
