<template>
  <horizon-modal
    id="send-sms-modal"
    modal-title="Send SMS Message to Students"
    img-src="https://assets.website-files.com/5d6d56640b8020729636d3ce/5d6d56640b80206bae36d41c_Asset%204%402x.png">
    <template v-slot:content>
      <div class="flex-column form m-2 row">
        <h2 class="d-block mb-2">
          Course Schedule: {{ schedule.name }}
        </h2>
        <span v-show="!showThankYou">
          <textarea
            v-model="messageContent"
            class="col-md-12"
            :class="{ 'highlighter-red': $v.messageContent.$error }"
            rows="6"/>
          <p v-show="$v.messageContent.$error" class="vuelidate-message">
            * Please enter message
          </p>
          <div class="d-flex justify-content-end mt-2">
            <slot name="buttons"/>
            <button class="btn btn-primary" style="min-width: 110px;" @click="submit">
              Send
            </button>
          </div>
        </span>
        <div v-show="showThankYou">
          <p>
            Thank you! Your submission has been received!
          </p>
        </div>
      </div>
    </template>
  </horizon-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import HorizonModal from '@shared/horizon_modal'

export default {
  components: {
    HorizonModal
  },
  props: {
    schedule: {
      type: Object,
      required: true,
      default () {
        return { id: '', name: 'NOT SET' }
      }
    },
    schoolLevel: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      showThankYou: false,
      messageContent: ''
    }
  },
  validations: {
    messageContent: { required }
  },
  mounted () {
    const self = this
    $('#send-sms-modal').on('hide.bs.modal', function () {
      self.resetMessage()
    })
  },
  methods: {
    submit () {
      this.$v.$touch()
      if (this.$v.$invalid) { return }
      if (this.schoolLevel) {
        // Post to endpoint which sends message to entire school (all schedules in a school)
      } else {
        this.$http.post(`/api/staff/schedules/${this.schedule.id}/messages`, this.formData()).then(() => {
          this.showThankYou = true
        }, (responseError) => {
          this.appendError({error: responseError, formData: this.formData(), notification: true })
        })
      }
    },
    formData () {
      return {
        message: {
          message: this.messageContent,
          sms: true
        }
      }
    },
    resetMessage () {
      this.showThankYou = false
      this.$v.$reset()
      this.messageContent = ''
    }
  }
}
</script>

<style scoped lang='scss'>

</style>
