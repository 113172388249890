<template>
  <div
    :id="modalId"
    class="fade hide horizon-modal modal"
    role="dialog"
    tabindex="-1"
    aria-labelledby="confirm"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div
          class="close-modal"
          data-dismiss="modal"
          data-ix="close-message"
          style="transition: background-color 0.25s ease 0s;"
          @click="closeModal">
          <div class="close-x">
            X
          </div>
        </div>
        <div class="modal-header">
          <h1 class="modal-heading">
            {{ modalTitle }}
          </h1>
        </div>
        <div class="modal-body">
          <div class="modal-container">
            <div class="modal-left">
              <img
                :src="imgSrc"
                alt="">
            </div>
            <div class="modal-right">
              <slot name="content"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    onClose: {
      type: Function,
      default: () => {}
    },
    imgSrc: {
      type: String,
      required: false,
      default: 'https://assets.website-files.com/5d6d56640b8020729636d3ce/5d6fc69e00df07d17d4c1276_students-1.svg'
    },
    modalTitle: {
      type: String,
      required: true
    },
    modalId: {
      type: String,
      required: false,
      default: 'horizon-modal'
    }
  },
  methods: {
    closeModal () {
      this.onClose()
    }
  }
}
</script>

<style scoped lang='scss'>
@import '~@styles/variables';

.horizon-modal {
  .modal-dialog {
    max-width: 700px;
    width: 700px;
    top: 0;
    right: 0;
    margin: 15% auto !important;

    .modal-content {
      .modal-body {
        .modal-container {
          .modal-right {
            min-height: auto !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $mobileScreenLarge) {
  .horizon-modal {
    .modal-dialog {
      margin-top: 20% !important;

      .modal-content {
        .modal-body {
          .modal-container {
            .modal-left {
              display: none;
            }

            .modal-right {
              border-left: 0px;
              width: 100%;
              padding: 0px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $mobileScreenMedium) {
 .horizon-modal {
    .modal-dialog {
      max-width: 450px;
      width: 450px;
      
      .modal-content {
        max-width: 450px;
        width: 450px;
      }
    }
  } 
}

@media screen and (max-width: 492px) {
 .horizon-modal {
    .modal-dialog {
      max-width: 350px;
      width: 350px;
      
      .modal-content {
        max-width: 350px;
        width: 350px;
      }
    }
  }   
}

@media screen and (max-width: $mobileScreenExtraSmall) {
 .horizon-modal {
    .modal-dialog {
      margin-top: 15% !important;
      max-width: 280px;
      width: 280px;
      
      .modal-content {
        max-width: 280px;
        width: 280px;
      }
    }
  }    
}
</style>
