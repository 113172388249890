<template>
  <horizon-modal
    id="confirm-delete-modal"
    modal-title="Delete Invite"
    :on-close="closeModal">
    <template v-slot:content>
      <div v-if="!showConfirmation" class="confirm-body">
        <p>
          You are about to delete your invite to {{ invite.firstName }} {{ invite.lastName }}. Are you sure you want to delete this invite?
        </p>
        <div class="d-flex justify-content-end w-100">
          <button class="btn btn-outline mr-3" @click="confirm">
            Delete Invite
          </button>
          <button class="btn btn-outline" @click="closeModal">
            Cancel
          </button>
        </div>
      </div>
      <div v-else>
        <p>
          Your invite to {{ invite.firstName }} {{ invite.lastName }} has been deleted.
        </p>
      </div>
    </template>
  </horizon-modal>
</template>

<script>
import HorizonModal from '@shared/horizon_modal'

export default {
  components: {
    HorizonModal
  },
  props: {
    invite: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      showConfirmation: false
    }
  },
  methods: {
    confirm () {
      this.$emit('deleteInvite', this.invite)
      this.showConfirmation = true
    },
    closeModal () {
      $('#confirm-delete-modal').modal('hide')
      this.showConfirmation = false
    }
  }
}
</script>

<style scoped lang='scss'>
.confirm-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  justify-content: space-between;
}
</style>
