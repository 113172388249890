<template>
  <div class="row">
    <div class="col-md-4 col-xs-12 mt-3">
      <schedule-card
        :schedule="entireSchool"
        :card-title="entireSchool.name"
        :buttons="buttonsConfiguration"
        :show-wait-listed="true">
        <h4>{{ activeSchoolYear() }}</h4>
      </schedule-card>
    </div>
    <div
      v-for="schedule in schedules"
      :key="schedule.id"
      class="col-md-4 col-xs-12 mt-3"
      :v-if="schedules.length > 0">
      <schedule-card
        :schedule="schedule"
        :card-title="schedule.name"
        :buttons="buttonsConfiguration"
        :show-wait-listed="true">
        <h4 class="schedule-stat schedule-type">
          {{ schedule.scheduleType }} School Day
        </h4>
        <h5 class="primary-teacher schedule-stat">
          {{ schedule.primaryTeacherName }}
        </h5>
        <h5 class="schedule-stat secondary-teacher">
          {{ schedule.secondaryTeacherName }}
        </h5>
      </schedule-card>
    </div>
  </div>
</template>

<script>
import ScheduleCard from '@shared/schedule_card'
import { mapGetters } from 'vuex'

export default {
  components: {
    ScheduleCard
  },
  props: {
    schedules: {
      type: Array,
      required: true,
      default () { return [] }
    },
    buttonsConfiguration: {
      type: Array,
      required: false,
      default() { return [] }
    }
  },
  computed: {
    entireSchool () {
      const enrolleeCountPerSchedule = this.schedules.map((schedule) => schedule.studentsEnrolled)
      const totalStudentsEnrolled = _.sum(enrolleeCountPerSchedule )

      return {
        name: 'Entire School',
        studentsEnrolled: totalStudentsEnrolled
      }
    }
  },
  methods: {
    ...mapGetters(['activeSchoolYear'])
  }
}
</script>

<style scoped lang="scss">
  @import '~@styles/variables';

  .schedule-stat {
    line-height: 14px;
    font-size: 10px;
  }

  @media screen and (max-width: $mobileScreenExtraLarge) {
    .col-md-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  @media screen and (max-width: $mobileScreenLarge) {
    .col-md-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
</style>
