 <template>
  <div>
    <schedule-date-modal
      :id="modal.id"
      :schedule-type="modal.type"
      :selectedScheduleDateProp="selectedClassDate"
      @scheduleDateUpdated="updateClassDate"
      @scheduleDateAdded="addClassDate"
    />
    <table id="class-dates-table" class="table table-primary">
      <thead>
        <tr>
          <th class="flex-fill h2 text-align-left text-white">Classes</th>
          <th class="h2 text-align-left text-white date-time-separated" scope="col">Date</th>
          <th class="h2 text-align-center text-white date-time-separated" scope="col">Start Time</th>
          <th class="h2 text-align-center text-white date-time-separated" scope="col">End Time</th>
          <th class="h2 text-align-center text-white date-time-combined" scope="col">Date & Time</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(classSchedule, idx) in activeClassDates">
          <tr :key="idx">
            <td class="fw-600">{{ `Class #${idx + 1}` }}</td>
            <td class="fw-400 date-time-separated">{{ classSchedule.meetingOn| date('dddd, MMMM D, YYYY') }}</td>
            <td class="fw-400 text-align-center date-time-separated">{{ classSchedule.startAt }}</td>
            <td class="fw-400 text-align-center date-time-separated">{{ classSchedule.endAt }}</td>
            <td class="text-align-center date-time-combined">
              {{ classSchedule.meetingOn| date('dddd, MMMM D, YYYY') }}<br />
              {{ classSchedule.startAt }} - {{ classSchedule.endAt }}
            </td>
            <td class="action-column">
              <button
                class="btn btn-outline-secondary gray-hover action-btn"
                @click="openEditClassDateModal(classSchedule)"
              >
                <i class="fa fa-lg fa-pencil-alt" />
              </button>
              <button
                class="btn btn-outline-secondary gray-hover action-btn"
                @click="markClassDateDeleted(classSchedule)"
              >
                <i class="fa fa-lg fa-trash" />
              </button>
            </td>
          </tr>
        </template>
      </tbody>
      <tfoot>
        <tr>
          <th colspan="5">
            <button
              id="add-class-schedule-button"
              class="btn btn-outline-secondary float-right gray-hover"
              @click="openAddClassDateModal"
            >
              <i class="fa fa-lg fa-plus-circle" />
              <span class="font-12">Add Class</span>
            </button>
          </th>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import moment from "moment";
import ScheduleDateModal from "@components/modules/schedule/edit/schedule_date_modal";

export default {
  components: { ScheduleDateModal },
  props: {
    classes: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      selectedClassDate: {},
      modal: {
        id: "class-date-modal",
        type: "Class"
      }
    };
  },
  computed: {
    activeClassDates() {
      const activeClasses = this.classes.filter(
        classDate => !classDate._destroy
      );
      activeClasses.sort((a, b) => {
        return moment(a.meetingOn).diff(moment(b.meetingOn));
      });
      return activeClasses;
    }
  },
  methods: {
    async openAddClassDateModal() {
      this.selectedClassDate = {};
      $(`#${this.modal.id}`).modal("show");
    },
    openEditClassDateModal(classDate) {
      this.selectedClassDate = classDate;
      $(`#${this.modal.id}`).modal("show");
    },
    updateClassDate(updatedClassDate) {
      this.selectedClassDate.meetingOn = updatedClassDate.meetingOn;
      this.selectedClassDate.startAt = updatedClassDate.startAt;
      this.selectedClassDate.endAt = updatedClassDate.endAt;
      this.selectedClassDate = {};
    },
    addClassDate(classDate) {
      this.classes.push(classDate);
    },
    markClassDateDeleted(classSchedule) {
      if (classSchedule.id) {
        classSchedule._destroy = true;
      } else {
        const index = this.classes.findIndex(
          classDate => classDate.id == classSchedule.id
        );
        this.classes.splice(index, 1);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@styles/modules/schedule/edit_schedule_dates_table.scss';
</style>
