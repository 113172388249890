<template>
  <div class="container">
    <div class="content-pane">
      <div v-if="scoreDetails && user" class="pane-wrapper">
        <breadcrumbs v-if="!isHorizonAdmin"/>
        <div class="report-header">
          <div class="report-header-wrapper">
            <img src="/images/HorizonEducation_Logo.jpg">
            <div class="detail-wrapper">
              <div class="font-weight-bold report-detail">
                {{ student.firstName }} {{ student.lastName }}
              </div>
              <div class="report-detail">
                Student ID: {{ student.studentIdNumber}}
              </div>
              <div class="report-detail">
                <button type="button" class="btn btn-download" @click="generateReport">Download Report</button>
              </div>
            </div>
          </div>
          <div class="report-title">
            <h1>{{ scoreDetails.name }}</h1>
            <h1>Test Date: <span class="font-lightest">{{ scoreDetails.date }}</span></h1>
          </div>
        </div>
        <composite-section-scores
          v-if="scoreDetails.compositeScore"
          :composite-description="true"
          :assessment-type-prop="scoreDetails.assessmentType"
          :composite-data="scoreDetails.compositeScore"
          :section-data="scoreDetails.sections"/>
        <detailed-score-breakdown
          v-if="scoreDetails.assessmentType == 'SAT'"
          :request-url-base-prop="requestUrlBase()"
          :practice-test-id="assessmentId"/>

        <detailed-score-breakdown-act
          v-if="scoreDetails.assessmentType == 'ACT'"
          :request-url-base-prop="requestUrlBase()"
          :practice-test-id="assessmentId"/>

        <top-opportunities
          :request-url-base-prop="requestUrlBase()"
          :practice-test-id="assessmentId"/>
        <subsection-score-details
          v-if="scoreDetails.sections"
          :request-url-base-prop="requestUrlBase()"
          :practice-test-id="assessmentId"
          :sections-data-prop="scoreDetails.sections"
          :individual-view="true"
          :is-horizon-admin="isHorizonAdmin"
          :assessment-type="scoreDetails.assessmentType"/>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@shared/breadcrumbs'
import CompositeSectionScores from '@shared/parent_student/composite_section_scores'
import DetailedScoreBreakdown from '@shared/score_report/detailed_score_breakdown'
import DetailedScoreBreakdownAct from '@shared/score_report/detailed_score_breakdown_act'
import SubsectionScoreDetails from '@shared/score_report/subsection_score_details'
import TopOpportunities from '@shared/score_report/top_opportunities'
import { mapGetters } from 'vuex'

export default {
  components: {
    Breadcrumbs,
    DetailedScoreBreakdownAct,
    DetailedScoreBreakdown,
    CompositeSectionScores,
    SubsectionScoreDetails,
    TopOpportunities
  },
  props: {
    isHorizonAdmin: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      student: {},
      studentId: '',
      user: {},
      assessmentId: '',
      scoreDetails: {},
      userType: 'staff'
    }
  },
  created () {
    if (this.$route.params.assessmentId) {
      this.assessmentId = this.$route.params.assessmentId
    }
    if (this.$route.params.studentId) {
      this.studentId = this.$route.params.studentId
    }
    this.initData()
    this.fetchStudent()
  },
  methods: {
    ...mapGetters(['userFullName']),
    
    requestUrlBase () {
      if (this.isHorizonAdmin) {
        return `/api/admin/students/${this.studentId}/assessments/${this.assessmentId}`   
      }
      return `/api/staff/students/${this.studentId}/assessments/${this.assessmentId}`
    },
    
    initData () {
      this.$http.get(`${this.requestUrlBase()}/score_details`).then((response) => {
        this.scoreDetails = response.data.scoreDetails
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    },

    fetchStudent () {
      let endpointURL = `/api/staff/students/${this.studentId}`
      if (this.isHorizonAdmin) {
        endpointURL = `/api/admin/students/${this.studentId}`
      }
      this.$http.get(endpointURL).then((result) => {
        this.student = result.data.student
      }, (responseError) => {
        this.appendError({error: responseError, notification: true})
      })
    },

    generateReport() {
      let url = `/api/staff/students/${this.studentId}/assessments/${this.assessmentId}/generate_individual_report`
      this.appendSuccess({message: 'Generating PDF report...', notification: true})

      this.$http.get(url, { responseType: 'arraybuffer' }).then((result) => {
        var blob = new Blob([result.data])
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var header = result.headers['content-disposition'];
        var filename = header.match(/filename\*?=((['"])[\s\S]*?\2|[^;\n]*)/)[1];
        link.download = filename;
        link.click();
      }, (responseError) => {
        this.appendError({error: responseError, notification: true})
      })
    },

  }
}
</script>

<style scoped lang="scss">
@import '~@styles/variables';
.report-header {
  border-top: 1px solid $themeLtGray;
  margin-top: 15px;
  .report-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px 10px 0;
    margin-bottom: 10px;
    img {
      width: 250px;
    }
    .detail-wrapper {
      padding: 5px 0;
      text-align: right;
      .report-detail {
        font-size: 18px;
        line-height: 150%;
        margin: 5px 0;
        color: $primaryColor;
      }
    }
  }
  .report-title {
    height: 60px;
    padding: 0 20px;
    background-color: $primaryColor;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h1 {
      color: #fff;
    }


  }
}

.btn-download {
  font-size: 14px;
  border: 1px solid #212529;
}
</style>
