<template>
  <div class="dashboard-pane">
    <div class="content-heading">
      <div class="pull-left">
        <h1>Course Schedules</h1>
      </div>
      <div class="pull-right">
        <h1 class="d-inline text-orange fw-700">
          {{ enrollees }}
        </h1>
        <h1 class="d-inline fw-400">
          Students Enrolled
        </h1>
      </div>
    </div>
    <app-schedules-list
      :schedules="schedules"
      :staff-view="true"/>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import SchedulesList from '@shared/schedules_list.vue'

export default {
  components: {
    'app-schedules-list': SchedulesList
  },
  props: {},
  data () {
    return {
      enrollments: [],
      schedules: [],
      enrollees: 0,
    }
  },
  created () {
    this.loadEnrollments()
    this.loadSchedules()
    this.loadEnrollees()
    this.loadCourseActivities()
  },
  methods: {
    ...mapActions(["setShowCourseActivitiesLink"]),
    loadEnrollments () {
      this.$http.get('/api/staff/enrollments').then(response => {
        this.enrollments = response.data.enrollments
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    },
    loadSchedules () {
      this.$http.get('/api/staff/schedules').then(response => {
        this.schedules = response.data.schedules
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    },
    loadEnrollees () {
      this.$http.get('/api/staff/enrollees').then(response => {
        this.enrollees = response.data.enrolleesCount
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    },
    loadCourseActivities () {
      if (this.$store.getters.userPermit({controller: 'CourseActivity'})) {
        this.$http.get('/api/staff/course_activities').then(response => {
          this.setShowCourseActivitiesLink(response.data.schedules.length > 0)
        })
      }
    }
  }

}
</script>

<style scoped lang="scss">
@import '~@styles/variables.scss';

@media screen and (max-width: $mobileScreenExtraLarge) {
  .content-heading {
    display: block;

    .pull-left {
      float: left;
      width: 100%;
    }

    .pull-right {
      float: left;
      width: 100%;

      h1 {
        font-size: 18px;
      }
    }
  }
}

@media screen and (max-width: $mobileScreenExtraSmall) {
  .content-heading {
    padding-left: 0px;
    padding-right: 0px;
  }
}
</style>
