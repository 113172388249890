<template>
  <tr>
    <td>
      <div class="input-group mb-3">
        <input
          v-model="staff.firstName"
          :class="{ 'highlighter-red': $v.staff.firstName.$error }"
          type="text"
          class="form-control"
          placeholder="First Name"
          aria-label="First Name"
          name="First Name"
          required
          @change="emitChangeUp"
          @blur="$v.staff.firstName.$touch">
      </div>
    </td>
    <td>
      <div class="input-group mb-3">
        <input
          v-model="staff.lastName"
          :class="{ 'highlighter-red': $v.staff.lastName.$error }"
          type="text"
          class="form-control"
          placeholder="Last Name"
          aria-label="Last Name"
          name="Last Name"
          required
          @change="emitChangeUp"
          @blur="$v.staff.lastName.$touch">
      </div>
    </td>
    <td>
      <div class="input-group mb-3">
        <input
          v-model="staff.email"
          :class="{ 'highlighter-red': $v.staff.email.$error }"
          type="text"
          class="form-control"
          placeholder="Email Address"
          aria-label="Email Address"
          name="Email Address"
          required
          @change="emitChangeUp"
          @blur="$v.staff.email.$touch">
      </div>
    </td>
    <td>
      <div class="input-group mb-3">
        <common-dropdown
          :items="instructorRoles"
          :item-display-key="'role'"
          :on-item-select="updateInstructorRole"
          dropdown-class="form-control d-flex dropdown justify-content-between no-hover"
          >
          <span class="font-14 text-truncate">
            {{ instructorRole }}
          </span>
        </common-dropdown>
      </div>
    </td>
    <td>
      <button
        type="button"
        class="btn btn-lg btn-outline-primary">
        <i class="fa fa-2x fa-trash"/>
      </button>
    </td>
  </tr>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import Mixins from '@submodules/mixins/mixin.js'
import CommonDropdown from '@shared/common_dropdown'

export default {
  components: {
    CommonDropdown
  },
  mixins: [Mixins],
  props: {
    staffProp: {
      type: Object,
      default () {
        return {
          firstName: '',
          lastName: '',
          email: '',
          role: ''
        }
      }
    }
  },
  data () {
    return {
      staff: {
        firstName: '',
        lastName: '',
        email: '',
        role: '',
      },
      instructorRoles: [
        {role: 'Teacher'},
        {role: 'Teacher Admin'}
      ],
      instructorRole: 'Select Instructor Role'
    }
  },
  watch: {
    staffProp: {
      immediate: true,
      handler (newStaffProp) {
        this.staff = this.deepCloneObject(newStaffProp)
      }
    }
  },
  validations: {
    staff: {
      firstName: {required},
      lastName: {required},
      email: {required, email},
      role: {required}
    }
  },
  methods: {
    emitChangeUp () {
      this.$emit('staff-added', this.staff, this.$vnode.key)
    },
    updateInstructorRole(role) {
      this.staff.role = role.role
      this.instructorRole = this.staff.role
      this.emitChangeUp()
    }
  }
}
</script>

<style scoped lang='scss'>

</style>
