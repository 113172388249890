<template>
  <div class="container">
    <div class="content-pane">
      <div class="pane-wrapper">
        <breadcrumbs/>
        <div class="dashboard-student-heading">
          <img src="/images/assessment_results_icon.png" class="image-student-head">
          <h2 class="heading-student-name">
            My Assessment Results
          </h2>
        </div>
        <course-progress
          :property-provider="courseProgressProvider"/>
        <individual-score-reports
          v-if="assessmentData && assessmentData.length > 0"
          :user-type-prop="userType"
          :score-report-route-prop="'ParentScoreReport'"
          :assessment-data="assessmentData"/>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@shared/breadcrumbs'
import CourseProgress from '@shared/parent_student/course_progress'
import IndividualScoreReports from '@shared/parent_student/individual_score_reports'

import CourseProgressProviderFactory from '@shared/parent_student/course_progress_property_provider_factory'
import RepositoryFactory from '@repositories/factory'
const AssessmentResultRepository = RepositoryFactory.parent.get('assessment_result')

export default {
  components: {
    Breadcrumbs,
    CourseProgress,
    IndividualScoreReports
  },
  data () {
    return {
      breadcrumbs: [],
      assessmentData: [],
      userType: 'parent',
      courseProgressProvider: {}
    }
  },
  async created () {
    await this.initializeCourseProgressProvider()
  },
  methods: {
    async initializeCourseProgressProvider () {
      const scoresSummary = await AssessmentResultRepository.fetchCourseProgress()
      this.assessmentData = scoresSummary.scores
      this.courseProgressProvider = CourseProgressProviderFactory.get(scoresSummary.assessmentType, this.assessmentData)
    }
  }
}
</script>

<style scoped lang="scss">
.assessment-results-wrapper {
  padding: 20px 30px 30px 30px;
}

</style>
