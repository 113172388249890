import { render, staticRenderFns } from "./sat_assessment_results.vue?vue&type=template&id=be6f8dbe&scoped=true&"
import script from "./sat_assessment_results.vue?vue&type=script&lang=js&"
export * from "./sat_assessment_results.vue?vue&type=script&lang=js&"
import style0 from "./sat_assessment_results.vue?vue&type=style&index=0&id=be6f8dbe&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be6f8dbe",
  null
  
)

export default component.exports