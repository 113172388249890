<template>
  <header id="horizon-primary-navbar" class="header">
    <nav class="bg-primary navbar">
      <div class="container">
        <div class="navbar-holder">
          <!-- eslint-disable max-len -->
          <img
            src="/images/HorizonEducation_Logo_White.png"
            width="151"
            srcset="/images/HorizonEducation_Logo_White-p-500.png 500w, /images/HorizonEducation_Logo_White-p-800.png 800w, /images/HorizonEducation_Logo_White-p-1080.png 1080w, /images/HorizonEducation_Logo_White-p-1600.png 1600w, /images/HorizonEducation_Logo_White-p-2000.png 2000w, /images/HorizonEducation_Logo_White-p-2600.png 2600w, /images/HorizonEducation_Logo_White-p-3200.png 3200w, /images/HorizonEducation_Logo_White.png 5000w"
            sizes="(max-width: 479px) 34.15625px, (max-width: 991px) 15vw, 151px"
            alt="Horizon Education Logo"
            class="horizon-logo-white">
          <!-- eslint-enable max-len -->
          <h1 v-if="userSchool()" class="d-inline-block school-name text-white">
            {{ userSchool().name }}
          </h1>
        </div>
        <ul class="nav nav-settings">
          <li class="dropdown nav-item py-2">
            <a
              id="settingsDropdown"
              class="dropdown-text nav-link text-decoration-0 text-white"
              href="#"
              role="button"
              aria-haspopup="true"
              aria-expanded="false">
              <img
                src="/images/cog.png"
                width="17"
                aria-hidden="true"
                class="profile-icon">
              {{ userFullName() }}

              <i class="dropdown-icon fa fa-chevron-down text-orange" aria-hidden="true"/>
            </a>
            <div class="dropdown-menu" aria-labelledby="settingsDropdown">
              <a class="dropdown-item justify-content-center text-decoration-0" href="#">
                <h3>Settings</h3>
              </a>
              <a class="dropdown-item justify-content-center text-decoration-0" href="#" @click="routeCourse" v-if="isStudent()">
                <h3>Courses</h3>
              </a>
              <router-link :to="{ name: 'logout' }" v-if="!isMasqIdent()" tag="a" class="cursor-hand dropdown-item justify-content-center">
                <h3>Log Out</h3>
              </router-link>
              <a class="dropdown-item justify-content-center text-decoration-0" v-if="isMasqIdent()" href="#" @click="unmasq">
                <h3 v-if="userDistrictMasq()">Back to School Selection</h3>
                <h3 v-else>Back to Admin</h3>
              </a>
            </div>
          </li>
        </ul>
        <div @click="toggleMobileMenu" class="ham-menu w-hidden-main" data-ix="ham-open">
          <div class="top-line" data-ix="ham-open"></div>
          <div class="middle-line"></div>
          <div class="bottom-line"></div>
        </div>
      </div>
    </nav>
    <nav id="horizon-secondary-navbar" class="bg-white navbar">
      <AdminSubheader v-if="isAdmin()"/>
      <StudentSubheader v-if="isStudent()" :hasThinkificBundleId="hasThinkificBundleId()"/>
      <StaffSubheader v-if="isStaff()" :hasThinkificBundleId="hasThinkificBundleId()"/>
      <ParentSubheader v-if="isParent()"/>
    </nav>
    <nav id="horizon-mobile-navbar">
      <div
        class="nav-menu-mobile"
        :style="
          showMobileMenu ?
          'transform-style: preserve-3d; display: flex; transition: opacity 400ms ease 0s, transform 400ms ease 0s; opacity: 1; transform: translateX(0px) translateY(0px) translateZ(0px);' :
          'transform-style: preserve-3d; display: none; transition: opacity 400ms ease 0s, transform 400ms ease 0s; opacity: 0; transform: translateX(0px) translateY(-105%) translateZ(0px);'
        "
        @click="toggleMobileMenu"
      >
        <AdminMobileMenu v-if="isAdmin()"/>
        <StudentMobileMenu v-if="isStudent()"/>
        <StaffMobileMenu v-if="isStaff()"/>
        <ParentMobileMenu v-if="isParent()"/>
        <div class="nav-menu-mobile-container">
          <a href="#" class="menu-link-mobile head">
            <div class="nav-menu-div">
              <div class="nav-menu-title-text">Settings</div>
            </div>
          </a>
          <router-link :to="{ name: 'logout' }" class="menu-link-mobile head">
            <div class="nav-menu-div">
              <div class="nav-menu-title-text">Log Out</div>
            </div>
          </router-link>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { userRoles } from '../utils/userRoles'

import AdminSubheader from '@shared/admin_subheader'
import AdminMobileMenu from '@shared/admin_mobile_menu'
import ParentSubheader from '@shared/parent_subheader'
import ParentMobileMenu from '@shared/parent_mobile_menu'
import StudentSubheader from '@shared/student_subheader'
import StudentMobileMenu from '@shared/student_mobile_menu'
import StaffSubheader from '@shared/staff_subheader'
import StaffMobileMenu from '@shared/staff_mobile_menu'
import HubspotMixin from "@submodules/mixins/hubspot.js";

export default {
  data() {
    return {
      showMobileMenu: false
    }
  },
  components: {
    StaffSubheader: StaffSubheader,
    StaffMobileMenu: StaffMobileMenu,
    AdminSubheader: AdminSubheader,
    AdminMobileMenu: AdminMobileMenu,
    ParentSubheader: ParentSubheader,
    ParentMobileMenu: ParentMobileMenu,
    StudentSubheader: StudentSubheader,
    StudentMobileMenu: StudentMobileMenu,
  },
  mixins: [HubspotMixin],
  methods: {
    ...mapActions(['logout', 'changeSchoolYear','setActiveCourse', 'loadUserSession']),
    ...mapGetters(['userFullName', 'userRole', 'userSchool', 'activeSchoolYear','userSchedules','activeCourse', 'userMasqIdent', 'userMasqId', 'userDistrictMasq']),
    resetSession () {
      this.logout().then(() => {
        this.$router.push({ name: 'login' })
      })
    },
    isParent () {
      return this.userRole() === userRoles.ROLE_PARENT
    },
    isStaff () {
      return this.userRole() === userRoles.ROLE_TEACHER_STAFF || this.userRole() === userRoles.ROLE_TEACHER_ADMIN
    },
    isStudent () {
      const headerActiveCourse = this.activeCourse()
      return this.userRole() === userRoles.ROLE_STUDENT && headerActiveCourse.courseType
    },
    isAdmin () {
      return this.userRole() === userRoles.ROLE_HORIZON_ADMIN
    },
    toggleMobileMenu() {
      this.showMobileMenu = !this.showMobileMenu
    },
    routeCourse () {
      this.setActiveCourse('')
      this.$router.push({ name: "StudentCourses" })
    },
    hasThinkificBundleId() {
      return !this.userSchool().thinkificBundleId == ""
    },
    isMasqIdent () {
      return this.userMasqIdent()
    },
    unmasq() {
      let payload = {
        password: this.userFullName(),
        masqid: this.userMasqId()
      }
      this.$http
        .post("/api/login", { login: payload })
        .then(() => {
          this.loadUserSession().then(() => {
            if (this.userRole() === userRoles.ROLE_DISTRICT_ADMIN) {
              this.$router.push({ name: "DistrictAdminDashboard" })
            } else {
              this.$router.push({ name: "AdminEnrollmentList" })
            }
          })
        })
        .catch(({ response }) => {
          this.appendError({ error: response })
        })
    },
  }
}
</script>

<style scoped lang='scss'>

</style>
