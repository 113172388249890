<template>
  <horizon-modal id="update-student-modal" modal-title="Update Student Account">
    <template v-slot:content>
      <div class="form">
        <div class="form-row">
          <div class="col-md-6 col-xs-12 div-1 form-group student-form-group">
            <input
              id="student-first-name"
              v-model="student.firstName"
              type="text"
              :class="['student-form-input', { 'highlighter-red': $v.student.firstName.$error }]">
            <label>Student First Name</label>
          </div>
          <div class="col-md-6 col-xs-12 div-2 form-group student-form-group">
            <input
              id="student-last-name"
              v-model="student.lastName"
              type="text"
              :class="['student-form-input', { 'highlighter-red': $v.student.lastName.$error }]">
            <label>Student Last Name</label>
          </div>
        </div>
        <div class="form-row">
          <div class="col-12 form-group student-form-group">
            <input
              id="student-email"
              v-model="student.email"
              type="text"

              :class="['student-form-input', { 'highlighter-red': $v.student.email.$error }]">
            <label>Student Email Address</label>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-6 col-xs-12 div-1 form-group student-form-group">
            <input
              id="student-phone-number"
              v-model="student.phoneNumber"
              type="text"
              class="student-form-input">
            <label>Student Phone Number</label>
          </div>
          <div class="col-md-6 col-xs-12 div-2 form-group student-form-group">
            <input
            id="student-id-number"
            v-model="student.studentIdNumber"
            type="text"
            :class="['student-form-input', { 'highlighter-red': $v.student.studentIdNumber.$error }]">
            <label>Student ID Number</label>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-end">
          <common-button
            style="margin-left: 15px;"
            class-list="btn btn-primary student-modal-button"
            :on-click="submit"
            button-text="Update Student"/>
        </div>
      </div>
    </template>
  </horizon-modal>
</template>

<script>
import HorizonModal from '@shared/horizon_modal'
import CommonButton from '@shared/common_button'
import GradeLevel from '@utils/grade_levels'
import { required, email } from 'vuelidate/lib/validators'

export default {
  components: {
    HorizonModal,
    CommonButton
  },
  props: {
     scheduleIdProp: { default: '', type: [String,Number] },
    initialEnrollment: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      student: {
        firstName: this.initialEnrollment.firstName,
        lastName: this.initialEnrollment.lastName,
        email: this.initialEnrollment.email,
        phoneNumber: this.initialEnrollment.phoneNumber,
        studentIdNumber: this.initialEnrollment.studentIdNumber,
        gradeLevel: this.initialEnrollment.gradeLevel
      }
    }
  },
  validations: {
    student: {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      studentIdNumber: { required },
      gradeLevel: { required }
    }
  },
  computed: {
    gradeLevelOptions () {
      return GradeLevel.values()
    }
  },
  watch: {
    initialEnrollment () {
      this.student = {
        id: this.initialEnrollment.id,
        firstName: this.initialEnrollment.firstName,
        lastName: this.initialEnrollment.lastName,
        email: this.initialEnrollment.email,
        phoneNumber: this.initialEnrollment.phoneNumber,
        studentIdNumber: this.initialEnrollment.studentIdNumber,
        gradeLevel: this.initialEnrollment.gradeLevel
      }
    }
  },
  methods: {
    formData () {
      return {
        temp_student: {
          id: this.student.id,
          firstName: this.student.firstName,
          lastName: this.student.lastName,
          email: this.student.email,
          phoneNumber: this.student.phoneNumber,
          studentIdNumber: this.student.studentIdNumber,
          gradeLevel: this.student.gradeLevel
        }
      }
    },
    updateTempStudent () {
     this.$http.put(`/api/staff/schedules/${this.scheduleIdProp}/temp_students/${this.student.id}`, this.formData()).then(() => {
        this.$emit('updated-student')
        this.appendSuccess({message: 'Updated student successfully.', notification: true})

      }, (responseError) => {
        this.appendError({error: responseError, formData: this.formData(), notification: true})
      })
    },
    submit () {
      this.$v.$touch()
      if(this.$v.$invalid) { return }
        this.updateTempStudent()
      }
    }
  }
</script>

<style scoped lang='scss'>
@import '~@styles/variables';
@import "~@styles/shared/students/student-form.scss";

@media screen and (max-width: $mobileScreenMedium) {
  #update-student-modal {
    .col-md-6 {
      padding-right: 5px;
      padding-left: 5px;
    }
  }
}

@media screen and (max-width: $mobileScreenSmall) {
  .justify-content-end {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;

    .btn {
      margin-left: 0px;
    }
  }
}

@media screen and (max-width: $mobileScreenExtraSmall) {
  .student-modal-button {
    height: auto;
  }
}
</style>
