<template>
  <div class="container">
    <div class="content-pane">
      <div class="pane-wrapper">
        <div class="dashboard-student-heading">
          <h2>
            {{ title }}
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@shared/breadcrumbs'

export default {
  components: {
    Breadcrumbs
  },
  data () {
    return {
      title: null,
    }
  },
  created () {
    this.title = this.$route.query.title
  }
}
</script>