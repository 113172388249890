<template>
  <div class="d-flex"
    :class="classList">
    <div class="spinner-border m-3" 
      role="status">
      <span class="sr-only">
        {{ loadingText }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    classList: {
      type: String,
      required: false,
      default: 'justify-content-center'
    },
    loadingText: {
      type: String,
      required: false,
      default: 'Loading...'
    },
  }
}
</script>
