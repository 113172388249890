<template>
  <div class="container">
    <div class="content-pane">
      <div class="pane-wrapper">
        <breadcrumbs class="breadcrumbs"/>
        <div class="content-heading underlined">
          <h1>Online Assessment Administration </h1>
        </div>
        <div class="row">
          <div v-for="(schedule, idx) in schedules"
            :key="idx"
            class="col-md-4 col-xs-12 mt-3">
            <section-card :schedule="schedule"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumbs from '@shared/breadcrumbs'
import SectionCard from './list/card.vue'

export default {
  components: {
    Breadcrumbs,
    SectionCard
  },
  data () {
    return {
      schedules: {},
    }
  },
  methods: {
    loadSections() {
      this.$http.get(`/api/staff/course_activities`).then((res) => {
        this.schedules = res.data.schedules
      }, (responseError) => {
        this.appendError({error: responseError, notification: true})
      })
    }
  },
  created () {
    this.loadSections()
  }
}
</script>
<style scoped lang="scss">
  @import '~@styles/variables';

  @media screen and (max-width: $mobileScreenExtraLarge) {
    .col-md-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  @media screen and (max-width: $mobileScreenLarge) {
    .col-md-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  @media screen and (max-width: $mobileScreenSmall) {
    .container {
      padding: 0px;
      height: 100%;

      .breadcrumbs {
        display: none;
      }

      .content-pane {
        border-radius: 0px;
        height: 100%;

        .pane-wrapper {
          padding: 15px;
        }
      }
    }
  }
</style>
