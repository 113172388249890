<template>
  <div>
    <schedule-information-form
      :schedule="schedule"
      :errors="errors"
      :target-assessment-date-repository="targetAssessmentDateRepository"
      :school-repository="schoolRepository"
      :instructor-repository="instructorRepository"
      />

    <div class="primary-row">
      <h2 class="text-uppercase">
        Schedule Dates
      </h2>
    </div>

    <div class="mt-3 row">
      <div class="col-12">
        <classes-table-form
          :classes="schedule.classes"
          :errors="errors.classes"/>
        <practice-assessments-table-form
          :scheduleProp="schedule"
          :courseType="schedule.courseType"
          :practiceTestDates="schedule.practiceTestDates"
          :errors="errors.practiceTestDates"/>
        <parent-nights-table-form
          :parentNightDates="schedule.parentNightDates"
          :errors="errors.parentNightDates"/>
      </div>
    </div>

  </div>
</template>

<script>
import ScheduleInformationForm from '@components/modules/schedule/schedule_info_form'
import ClassesTableForm from '@components/modules/schedule/inside_schedule_classes_table_form'
import PracticeAssessmentsTableForm from '@components/modules/schedule/schedule_practice_assessments_table_form'
import ParentNightsTableForm from '@components/modules/schedule/schedule_parent_nights_table_form'

export default {
  components: {
    ScheduleInformationForm,
    ClassesTableForm,
    PracticeAssessmentsTableForm,
    ParentNightsTableForm
  },
  props: {
    schedule: {
      type: Object,
      required: false,
      default () { return {} }
    },
    errors: {
      type: Object,
      required: false,
      default () { return {} }
    },
    instructorRepository: {
      type: Object,
      required: true
    },
    targetAssessmentDateRepository: {
      type: Object,
      required: true
    },
    schoolRepository: {
      type: Object,
      required: true
    }
  },
  methods: {
    changeScheduleAttribute(name, value) {
      this.$emit('change-attribute', name, value);
    }
  }
}
</script>

<style lang="scss" scoped>
.schedule-section-header {
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 18px;
}
</style>
