<template>
  <div class="container">
    <div class="dashboard-district-admin">
      <div class="dashboard-district-admin-pane">
        <div class="dashboard-district-admin-header course-schedule">
          <h2 class="heading-school-selector">
            Select School Below
          </h2>
        </div>
        <div v-for="(school, schoolIndex) in memberSchools" :key="schoolIndex" :id="school.id" @click="visitSchool(school)" class="school-select-button w-inline-block">
          <div class="div-block-61">
            <h2 class="heading-school-name">{{ school.name }}</h2>
          </div>
          <img src="/images/course_type_right-arrow.png" width="37" alt class="image">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { mapActions } from "vuex"

export default {
  components: {
  },
  data () {
    return {
      memberSchools: [],
    }
  },
  created () {
    this.loadMemberSchools()
  },
  methods: {
    ...mapActions(["loadUserSession"]),
    ...mapGetters(["userMasqId"]),

    loadMemberSchools() {
      this.$http.get(`/api/district_admin/schools`).then(response => {
         this.memberSchools = response.data.schools
      }, (responseError) => {
         this.appendError({ error: responseError, notification: true })
      })
    },

    visitSchool(school) {
      if (school['district_staff_email']) {
        let payload = {
          email: school['district_staff_email'],
          password: school['district_staff_email'],
          masqid: this.userMasqId()
        }
        this.$http
          .post("/api/login", { login: payload })
          .then((response) => {
            this.loadUserSession().then(() => {
              this.$router.push({ name: "StaffDashboard" })
            })
          })
          .catch(({ response }) => {
            this.appendError({ error: 'Could not login District Admin staff account. Please contact a Horizon Education administrator.', notification: true })
          })
      } else {
            this.appendError({ error: 'Missing District Admin staff account. Please contact a Horizon Education administrator.', notification: true })
      }
    }

  }
}
</script>

<style scoped lang="scss">
@import '~@styles/variables.scss';

.dashboard-district-admin {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.dashboard-district-admin-pane {
  width: 100%;
  height: auto;
  min-height: 770px;
  padding: 10px 15px 18px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 1px 3px 3px #e7e7e7;
}

.div-block-61 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.heading-school-name {
  margin-top: 0px;
  margin-bottom: 4px;
  font-size: 24px;
  font-weight: 700;
}

.heading-school-selector {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #345165;
  font-size: 32px;
  font-weight: 700;
}

.dashboard-district-admin-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  padding: 10px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
}

.dashboard-district-admin-header.course-schedule {
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-bottom-color: #dbdbdb;
}

.school-select-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  margin-top: 15px;
  margin-bottom: 20px;
  padding: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #dbdbdb;
  border-radius: 10px;
  background-color: #fff;
  text-decoration: none;
}

.school-select-button:hover {
  border-color: #9b9b9b;
  cursor: pointer;
}

</style>
