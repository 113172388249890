<template>
  <horizon-modal id="update-user-modal" modal-title="Update User">
    <template v-slot:content>
      <div class="form">
        <div class="form-row">
          <div class="col-md-6 col-xs-12 form-group div-1 student-form-group">
            <label for="user-first-name" class="d-block">First Name</label>
            <input
            id="user-first-name"
            v-model="userEdits.firstName"
            :class="['student-form-input', { 'highlighter-red': $v.userEdits.firstName.$error }]">
          </div>
          <div class="col-md-6 col-xs-12 form-group div-1 student-form-group">
            <label for="user-last-name" class="d-block">Last Name</label>
            <input
            id="user-last-name"
            v-model="userEdits.lastName"
            type="text"
            :class="['student-form-input', { 'highlighter-red': $v.userEdits.lastName.$error }]">
          </div>
          <div class="col-md-12 col-xs-12 form-group div-1 student-form-group">
            <label for="user-email-address" class="d-block">Email Address</label>
            <input
            id="user-email-address"
            v-model="userEdits.email"
            type="text"
            :class="['student-form-input', { 'highlighter-red': $v.userEdits.email.$error }]">
          </div>
        </div>
        <div v-if="selectedUser.role == 'Student'">
          <div class="form-row" >
            <div class="col-md-6 col-xs-12 div-1 form-group student-form-group">
              <label>Student Phone Number</label>
              <input
                id="student-phone-number"
                v-model="userEdits.phoneNumber"
                type="text"
                class="student-form-input">
            </div>
            <div class="col-md-6 col-xs-12 div-2 form-group student-form-group">
              <label>Student ID Number</label>
              <input
              id="student-id-number"
              v-model="userEdits.studentIdNumber"
              type="text"
              class="student-form-input">
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6 col-xs-12 div-1 form-group student-form-group">
              <label>Course Schedule</label>
              <select
                id="student-schedule"
                v-model="userEdits.scheduleId"
                class="student-form-input">
                <option
                  v-for="schedule in schedules"
                  :key="schedule.id"
                  :selected="schedule.id === userEdits.scheduleId"
                  :value="schedule.id">
                  {{ schedule.name }}
                </option>
              </select>
            </div>
            <div class="col-md-6 col-xs-12 div-2 form-group student-form-group">
              <label>Grade Level</label>
              <select
                id="student-grade-level"
                v-model="userEdits.gradeLevel"
                class="student-form-input">
                <option
                  v-for="gradeLevel in gradeLevelOptions"
                  :key="gradeLevel.value"
                  :value="gradeLevel.value">
                  {{ gradeLevel.name }}
                </option>
              </select>
            </div>
          </div>

        </div>

        <div class="col-12 d-flex justify-content-end">
          <button class="btn btn-primary student-modal-button" @click="updateUser">
            Update
          </button>
        </div>
      </div>
    </template>
  </horizon-modal>
</template>

<script>
import HorizonModal from '@shared/horizon_modal'
import RepositoryFactory from '@repositories/factory'
import GradeLevel from '@utils/grade_levels'
import { required, email } from 'vuelidate/lib/validators'

export default {
  components: {
    HorizonModal,
  },
  props: {
    selectedUser: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data() {
    return {
      userEdits: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        studentIdNumber: '',
        enrollmentId: '',
        scheduleId: '',
      },
      schedules: []
    }
  },
  validations: {
    userEdits: {
      firstName: { required },
      lastName: { required },
      email: { required, email },
    }
  },
  watch: {
    selectedUser: {
      immediate: true,
      handler(val, oldVal) {
        this.userEdits = {
          firstName: this.selectedUser.firstName,
          lastName: this.selectedUser.lastName,
          email: this.selectedUser.email,
          phoneNumber: this.selectedUser.phoneNumber,
          studentIdNumber: this.selectedUser.studentIdNumber,
        }

        if (this.selectedUser.role == "Student") {
          this.schedules = []
          this.userEdits.scheduleId = this.selectedUser.schedules[0].id
          this.userEdits.gradeLevel = this.selectedUser.gradeLevel
          this.fetchSchedules()
        }

      }
    },
  },
  computed: {
    gradeLevelOptions () {
      return GradeLevel.values()
    }
  },
  methods: {
    fetchSchedules() {
      // fetch enrollment information
      this.$http.get(`/api/admin/schedules/${this.userEdits.scheduleId}/enrollments`).then(response => {
        if (response.data.enrollments.length > 0) {
          this.userEdits.enrollmentId = response.data.enrollments.filter( e => e.userId == this.selectedUser.id )[0].id
        }
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })

      // fetch available schedules
      this.$http.get(`/api/admin/schools/${this.selectedUser.schoolId}/schedules`).then(response => {
        this.schedules = response.data.schedules
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    },

    updateUser () {
      this.$v.$touch()
      if(this.$v.$invalid) { return }

      $('#update-user-modal').modal('hide')
      this.$emit('user-updated', this.userEdits)

      this.schedules = []
    },
  }
}
</script>

<style scoped lang='scss'>
@import "~@styles/shared/students/student-form.scss";
</style>
