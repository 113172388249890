<template>
  <confirm-modal
    :cancel="close"
    body-header="Are you sure you want to delete the selected student?"
    body-paragraph="Deleting student will permanently erase their accounts and corresponding scoring data including: (Student data record, Student practice tests, Login entries, Assessment scores, Answered questions)."

    confirm-btn-text="Yes, delete student."
    confirmed-action="delete"
    @confirmed="deleteEnrollment"/>
</template>

<script>
import ConfirmModal from './confirm_modal'

export default {
  components: {
    ConfirmModal
  },
  props: {
    enrollment: {
      type: Object,
      default () { return {} }
    },
    enrollmentRepository: {
      type: Object,
      required: true,
      default() { return null }
    }
  },
  methods: {
    async deleteEnrollment () {
      await this.enrollmentRepository.delete(this.enrollment)
      this.$emit('deleted', this.enrollment)
      this.close()
    },
    close () {
      $('#delete-students-modal').modal('hide')
    }
  }
}
</script>

<style scoped lang='scss'>
</style>
