<template>
  <div class="section-text-button-detail" :id="id">
    <h3>{{ main_text }}</h3>
    <div class="detail-buttons">
      <a class="btn btn-outline" @click.prevent="btn_a_fn">
        <img class="btn-icon" :src="btn_a.i_path">
        <div class="header-btn-text d-none d-md-block">{{ btn_a.text }}</div>
      </a>
      <!-- Disabled until further updates. Ref: https://horizon-dev.atlassian.net/browse/HE-1064
      <a class="btn btn-outline" @click.prevent="btn_b_fn">
        <img class="btn-icon" :src="btn_b.i_path">
        <div class="header-btn-text">{{ btn_b.text }}</div>
      </a>
      -->
      <a class="btn btn-outline" @click.prevent="btn_c_fn">
        <img class="btn-icon" :src="btn_c.i_path">
        <div class="header-btn-text d-none d-md-block">{{ btn_c.text }}</div>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: String,
      default: '',
      required: true
    },
    main_text: {
      type: String,
      default: ''
    },
    btn_a: {
      type: Object,
      default: {}
    },
    btn_b: {
      type: Object,
      default: {}
    },
    btn_c: {
      type: Object,
      default: {}
    },
    btn_a_fn: {
      type: Function,
      default: () => {}
    },
    btn_b_fn: {
      type: Function,
      default: () => {}
    },
    btn_c_fn: {
      type: Function,
      default: () => {}
    },
  }
}
</script>
<style scoped lang="scss">
@import '~@styles/variables';
.section-text-button-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px 15px 0px;
  border-bottom: 1px solid #d8d8d8;
  color: #345165;
  h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
  }
  .detail-buttons {
    display: flex;
    a {
      border-color: #c7c7c7;
      font-size: 12px;
      font-weight: 400;
      background-color: white;
      height: 44px;
      width: auto;
      padding: 9px 13px 9px 13px;
      img {
        width: 18px;
        height: 18px;
        margin-right: 5px;
      }
    }
    a:hover {
      border-color: #345165;
      color: #345165;
    }
  }
}
</style>
