<template>
  <div class="container">
    <ul class="container justify-content-start nav pt-1">
      <li v-for="(navItem, navIndx) in navItems" :key="navIndx" class="nav-item">
        <router-link :to="{ name: navItem.route }" class="logout logout nav-link text-primary" v-if="navItem.show">
          {{ navItem.name }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data () {
    return {
      navItems: [
        { name: 'Enrollment', route: 'AdminEnrollmentList', show: true },
        { name: 'School', route: 'AdminSchoolList', show: true },
        { name: 'Districts', route: 'AdminDistrictList', show: true },
        { name: 'Assessments', route: 'AdminAssessmentList', show: true },
        { name: 'Course Activities', route: 'AdminCourseActivitiesShow', show: true },
        { name: 'User Search', route: 'GlobalSearch', show: true }
      ]
    }
  }
}

</script>

<style scoped lang='scss'>
  @import '~@styles/nav.scss';
</style>
