<template>
  <div>
    <div class="section-header">
      <h1>Top Opportunities</h1>
    </div>
    <div class="score-breakdown-row vertical">
      <div v-for="(opportunity, oIndx) in opportunitiesData" :key="oIndx" class="opportunity">
        <div class="number-circle">
          {{ oIndx+1 }}
        </div>
        <!-- Not sure how these messages are coming in - may need more keys to style the messages out properly -->
        <div class="body-larger">
          Work on <span class="call-out">{{ opportunity.subsectionAreaName }}</span> - {{ opportunity.topicAreaName }} in the <span class="call-out">{{ opportunity.categoryGroupName }}</span> section.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    practiceTestId: { type: [Number, String], required: true },
    requestUrlBaseProp: { type: String, required: true }
  },
  data () {
    return {
      opportunitiesData: []
    }
  },
  watch: {
    practiceTestId: {
      immediate: true,
      handler () {
        this.getScoreOpportunities()
      }
    }
  },
  methods: {
    getScoreOpportunities () {
      this.$http.get(`${this.requestUrlBaseProp}/score_opportunities`).then((response) => {
        this.opportunitiesData = response.data.scoreOpportunities
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@styles/variables';

.opportunity {
  display: flex;
  align-items: center;
  padding-bottom: 11px;
  .number-circle {
    background-color: $primaryColor;
    width: 50px;
    min-width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 30px;
    font-weight: 700;
    margin-right: 15px;
  }
}

@media screen and (max-width: $mobileScreenSmall) {
  .score-breakdown-row {
    padding-left: 0px;
  }

  .opportunity {
    .number-circle {
      width: 40px;
      min-width: 40px;
      height: 40px;
      min-height: 40px;
      font-size: 24px;
    }

    .body-larger {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

@media screen and (max-width: $mobileScreenExtraSmall) {
  .section-header {
    h1 {
      font-size: 18px !important;
    }
  }
}
</style>
