<template>
  <div class="container">
    <div class="content-pane">
      <div class="pane-wrapper">
        <breadcrumbs/>
        <div class="content-heading no-gutters row underlined">
          <div class="col-md-12 p-0">
            <h1>Create School</h1>
          </div>
        </div>
        <br>
        <div class="card card-body">
          <h2 class='settings-heading'>SETTINGS</h2>
          <br>
          <div class="row pt-4">
            <div class="col-md-6 pr-3 row">
              <div class="col-md-6">
                <label for="schoolName" class="form-label mt-3">School Name</label>
              </div>
              <div class="col-md-6">
                <div class="input-group mb-3">
                  <input
                    id="schoolName"
                    v-model="school.name"
                    :class="{ 'highlighter-red': $v.school.name.$error }"
                    type="text"
                    class="form-control"
                    placeholder=""
                    aria-label="District Name"
                    name="District Name"
                    required
                    @blur="$v.school.name.$touch">
                </div>
              </div>
            </div>
            <div class="col-md-6 pr-3 row">
              <div class="col-md-6">
                <label class="form-label mt-3">Assessment Course</label>
              </div>
              <div class="col-md-6">
                <div class="input-group mb-3">
                  <button
                    id="schoolassessmentCourse"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    class="btn btn-outline-secondary ml-0 d-flex dropdown form-control horizon-input justify-content-between no-hover courseTypeSelect">
                    <span class="margin-top-auto margin-bottom-auto font-12">
                      {{ school.courseType }}</span>
                    <i class="dropdown-icon fa fa-chevron-down text-orange margin-top-auto margin-bottom-auto" aria-hidden="true"/>

                    <div
                      class="dropdown-menu text-left">
                      <span
                        v-for="(courseType, courseTypeIndx) in courseTypes"
                        :key="courseTypeIndx"
                        :class="{ 'highlighter-red': $v.school.courseType.$error }"
                        :value="courseType"
                        @click="school.courseType=courseType"
                        class="dropdown-item text-decoration-0">
                        <a href="#" class="font-12 text-decoration-0">{{ courseType }}</a>
                      </span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-6 pr-3 row">
              <div class="col-md-6 my-auto">
                <label for="schoolAddress" class="form-label">Street Address</label>
              </div>
              <div class="col-md-6">
                <div class="input-group mb-3">
                  <input
                    id="schoolAddress"
                    v-model="school.address"
                    type="text"
                    class="form-control"
                    placeholder=""
                    aria-label="School Address"
                    name="School Address">
                </div>
              </div>
            </div>
            <div class="col-md-6 pr-3 row">
              <div class="col-md-6 my-auto">
                <label for="schoolBundleId" class="form-label">Thinkific Bundle Id</label>
              </div>
              <div class="col-md-6">
                <div class="input-group mb-3">
                  <input
                    id="schoolBundleId"
                    v-model="school.thinkificBundleId"
                    type="text"
                    class="form-control"
                    placeholder=""
                    aria-label="Thinkific Bundle Id"
                    name="Thinkific Bundle Id">
                </div>
              </div>
            </div>
            <div class="col-md-6 pr-3 row">
              <div class="col-md-6 my-auto">
                <label for="schoolCity" class="form-label">City</label>
              </div>
              <div class="col-md-6">
                <div class="input-group mb-3">
                  <input
                    id="schoolCity"
                    v-model="school.city"
                    type="text"
                    class="form-control"
                    placeholder=""
                    aria-label="School City"
                    name="School City">
                </div>
              </div>
            </div>
            <div class="col-md-6 pr-3 row">
              <div class="col-md-6 my-auto">
                <label for="schoolSeatsPurchased" class="form-label"># of Seats Purchased</label>
              </div>
              <div class="col-md-6">
                <div class="input-group mb-3">
                  <input
                    id="schoolSeatsPurchased"
                    v-model="school.seatsPurchased"
                    type="number"
                    min="0"
                    class="form-control"
                    placeholder=""
                    aria-label="School Seats Purchased"
                    name="School Seats Purchased">
                </div>
              </div>
            </div>
            <div class="col-md-6 pr-3 row">
              <div class="col-md-6">
                <label class="form-label mt-3">State</label>
              </div>
              <div class="col-md-6">
                <div class="input-group mb-3">
                  <button
                    id="schoolState"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    :class="['btn btn-outline-secondary ml-0 d-flex dropdown schoolStateSelect form-control horizon-input justify-content-between no-hover', { 'highlighter-red': $v.school.state.$error }]">
                    <span class="margin-top-auto margin-bottom-auto font-12">
                      {{ school.state }}</span>
                    <i class="dropdown-icon fa fa-chevron-down text-orange margin-top-auto margin-bottom-auto" aria-hidden="true"/>

                    <div
                      class="dropdown-menu text-left">
                      <span
                        v-for="(state, stateIndx) in states"
                        :key="stateIndx"
                        :value="state"
                        @click="school.state=state"
                        class="dropdown-item text-decoration-0">
                        <a href="#" class="font-12 text-decoration-0">{{ state }}</a>
                      </span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-6 pr-3 row">
              <div class="col-md-6 my-auto">
                <label for="schoolEnrollmentCap" class="form-label">School Enrollment Cap</label>
              </div>
              <div class="col-md-6">
                <div class="input-group mb-3">
                  <input
                    id="schoolEnrollmentCap"
                    v-model="school.maxEnrollment"
                    type="text"
                    class="form-control"
                    placeholder=""
                    aria-label="School Max Enrollment"
                    name="School Max Enrollment">
                </div>
              </div>
            </div>
            <div class="col-md-6 pr-3 row">
              <div class="col-md-6 my-auto">
                <label for="schoolZipCode" class="form-label">Zip Code</label>
              </div>
              <div class="col-md-6">
                <div class="input-group mb-3">
                  <input
                    id="schoolZipCode"
                    v-model="school.postalCode"
                    type="text"
                    class="form-control"
                    placeholder=""
                    aria-label="School Postal Code"
                    name="School Postal Code">
                </div>
              </div>
            </div>
            <div class="col-md-6 pr-3 row">
              <div class="col-md-6">
                <label class="form-label mt-3">Waitlist Available</label>
              </div>
              <div class="col-md-6">
                <label class="m-2 switch">
                  <input v-model="school.waitlistAvailable" type="checkbox" class="default">
                  <span class="round slider"/>
                </label>
              </div>
            </div>
            <div class="col-md-6 pr-3 row">
              <div class="col-md-6">
                <label class="form-label mt-3">District</label>
              </div>
              <div class="col-md-6">
                <div class="input-group mb-3">
                  <button
                    id="schoolDistrict"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    :class="['btn btn-outline-secondary ml-0 d-flex dropdown schoolDistrictSelect form-control horizon-input justify-content-between no-hover', { 'highlighter-red': $v.school.schoolDistrictId.$error }]">
                    <span class="margin-top-auto margin-bottom-auto font-12">
                      {{ school.schoolDistrictName }}</span>
                    <i class="dropdown-icon fa fa-chevron-down text-orange margin-top-auto margin-bottom-auto" aria-hidden="true"/>

                    <div :v-model="school.schoolDistrictId"
                      class="dropdown-menu text-left">
                      <span
                        v-for="(district, districtIndx) in schoolDistricts"
                        :key="districtIndx"
                        :value="district"
                        @click="(school.schoolDistrictId=district.id, school.schoolDistrictName=district.name)"
                        class="dropdown-item text-decoration-0">
                        <a href="#" class="font-12 text-decoration-0">{{ district.name }}</a>
                      </span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div v-if="hideForACT" class="col-md-6 pr-3 row">
              <div class="col-md-6">
                <label class="form-label mt-3">SAT Online Available</label>
              </div>
              <div class="col-md-6">
                <label class="m-2 switch">
                  <input v-model="school.satOnlineAvailable" type="checkbox" class="default">
                  <span class="round slider"/>
                </label>
              </div>
            </div>
            <div class="col-md-6 pr-3 row">
            </div>
            <div v-if="hideForACT" class="col-md-6 pr-3 row">
              <div class="col-md-6">
                <label class="form-label mt-3">PSAT 8/9 Available</label>
              </div>
              <div class="col-md-6">
                <label class="m-2 switch">
                  <input v-model="school.psat89Available" type="checkbox" class="default">
                  <span class="round slider"/>
                </label>
              </div>
            </div>
            <div class="col-md-6 pr-3 row">
            </div>
            <div v-if="hideForACT" class="col-md-6 pr-3 row">
              <div class="col-md-6">
                <label class="form-label mt-3">PSAT 10 Available</label>
              </div>
              <div class="col-md-6">
                <label class="m-2 switch">
                  <input v-model="school.psat10Available" type="checkbox" class="default">
                  <span class="round slider"/>
                </label>
              </div>
            </div>

            <hr class="col-md-12">
          </div>
          <div class="no-gutters row">
            <div class="col-md-12 py-2">
              <h2 class="text-uppercase">Instructors</h2>
            </div>
            <hr class="col-md-12">
            <div class="col-md-12 table-responsive">
              <table class="table">
                <thead class="bg-primary">
                  <tr>
                    <th scope="col">
                      First Name
                    </th>
                    <th scope="col">
                      Last Name
                    </th>
                    <th scope="col">
                      Email
                    </th>
                    <th scope="col">
                      Account Type
                    </th>
                    <th scope="col"/>
                  </tr>
                </thead>
                <tbody>
                  <school-staff-row v-for="(staff, staffIndx) in school.staff" :key="staffIndx" :staff-prop="staff" @staff-added="addSchoolStaff(...arguments)"/>
                  <tr class="bg-light">
                    <th/>
                    <th/>
                    <th/>
                    <th/>
                    <th class="text-right">
                      <button class="btn btn-lg btn-outline-primary" @click="addStaff">
                        Add Instructor
                      </button>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="no-gutters row">
            <div class="col-md-12 text-right">
              <button class="btn btn-outline-secondary" @click="backToSchoolList">
                <span class="btn-create-school">Cancel</span>
              </button>
              <button class="btn btn-primary float-right" @click="saveSchool" id="create-school">
            <i class="fa fa-2x fa-plus-circle text-orange mr-1 v-align-middle"/> <span class="font-14 v-align-middle"> Create School </span>
          </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from '@submodules/mixins/mixin.js'
import Breadcrumbs from '@shared/breadcrumbs'
import SchoolStaffRow from './school_staff_row.vue'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    SchoolStaffRow,
    Breadcrumbs
  },
  mixins: [Mixins],
  data: function () {
    return {
      school: {
        id: '',
        schoolDistrictId: '',
        schoolDistrictName: '',
        address: '',
        city: '',
        state: '',
        postalCode: '',
        courseType: 'SAT',
        thinkificBundleId: '',
        maxEnrollment: '',
        staff: [
          { firstName: '', lastName: '', email: '', role: '' }
        ],
        instructorsAttributes: []
      },
      courseTypes: ['ACT', 'SAT'],
      states: ('AL AK AZ AR CA CO CT DE FL GA HI ID IL IN IA KS KY LA ME MD MA MI MN MS ' +
                'MO MT NE NV NH NJ NM NY NC ND OH OK OR PA RI SC SD TN TX UT VT VA WA WV WI ' +
                'WY').split(' '),
      schoolDistricts: [],
      editView: false
    }
  },

  validations: {
    school: {
      name:             { required },
      state:            { required },
      courseType:       { required },
      schoolDistrictId: { required }
    }
  },

  created () {
    this.listDistricts()
  },
  computed: {
    hideForACT () {
      if (this.school.courseType === "SAT") {
       return true
        } else {
        return false
        }
    }
  },
  methods: {
    addStaff () {
      if (this.isBlank(this.school.staff)) { this.school.staff = [] }
      this.school.staff.push(
        { firstName: '', lastName: '', email: '', role: '' }
      )
    },
    addSchoolStaff: function (staff, key) {

      if (typeof this.school.instructorsAttributes[key] === 'undefined') {
        this.school.instructorsAttributes.push(
          { firstName: staff.firstName,
            lastName: staff.lastName,
            email: staff.email,
            role: staff.role
          }
        )
      } else {
        this.school.instructorsAttributes[key] = {
          firstName: staff.firstName,
          lastName: staff.lastName,
          email: staff.email,
          role: staff.role
        }
      }
    },

    backToSchoolList () {
      this.$router.push({ name: 'AdminSchoolList' })
    },

    createSchool () {
      this.$http.post('/api/admin/schools', this.formData()).then(() => {
        this.appendSuccess({message: 'Created new school successfully.', notification: true})
        this.backToSchoolList()
      }, (responseError) => {
        this.appendError({error: responseError, formData: this.formData(), notification: true})
      })
    },

    formData () {
      return {
        school: {
          name: this.school.name,
          schoolDistrictId: this.school.schoolDistrictId,
          address: this.school.address,
          city: this.school.city,
          state: this.school.state,
          postalCode: this.school.postalCode,
          courseType: this.school.courseType,
          thinkificBundleId: this.school.thinkificBundleId,
          maxEnrollment: this.school.maxEnrollment,
          waitlistAvailable: this.school.waitlistAvailable,
          satOnlineAvailable: this.school.satOnlineAvailable,
          psat89Available: this.school.psat89Available,
          psat10Available: this.school.psat10Available,
          seatsPurchased: this.school.seatsPurchased,
          instructorsAttributes: this.school.instructorsAttributes
        }
      }
    },

    saveSchool () {
      this.$v.$touch()
      console.log(this.$v.$invalid);
      if (this.$v.$invalid) { return }
      this.createSchool()
    },

    listDistricts () {
      this.$http.get('/api/resource_lists/districts').then((result) => {
        this.schoolDistricts = result.data.districts
      }, (responseError) => {
        this.appendError({error: responseError, notification: true})
      })
    }
  }
}
</script>

<style scoped lang="scss">
  @import '~@styles/variables.scss';
  thead {
    height: 60px;
    border-bottom: none;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

  }
  tr {
    th {
      text-align: left;
      font-size: 16px;
      font-weight: 700;
      padding-left: 15px;
      vertical-align: middle;
    }
  }
.settings-heading {
  padding-top: 18px;
  padding-bottom: 20px;
  padding-left: 10px;
  border-bottom: 1px solid $themeLtGray;

}
.form-label {
  font-size: 12px;
  color: #505050;
  font-weight: 600;
  text-transform: uppercase;
  vertical-align: middle;
  padding-left: 10px;
}
.form-control {
  height: 46px;
}

.btn-cancel {
  padding-bottom: 10px;
  padding-right: 5px;
  font-size: 14px;
}


.margin-top-auto {
    margin-top: auto;
  }
.margin-bottom-auto {
  margin-bottom: auto;
}
</style>
