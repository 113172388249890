<template>
  <div>
    <div class="section-header">
      <h1>Detailed Score Breakdown</h1>
    </div>
    <div class="top-test-scores score-breakdown-row">
      <div class="test-scores">
        <div class="p-0 section-header-sm">
          <h2>Test Scores <span class="call-out pl-2">(10-40)</span></h2>
        </div>
        <div class="score-detail-row">
          <div v-for="(testScore, cIndx) in detailedScoreData.testScores" :key="cIndx" class="stat-text-wrapper" :style="{ width: calcWidth(detailedScoreData.testScores) + '%'}">
            <div class="stat">
              {{ testScore.correct }}
            </div>
            <div class="sub-text">
              {{ testScore.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="cross-test-scores">
        <div class="p-0 section-header-sm">
          <h2>Cross-Test Scores <span class="call-out pl-2">(10-40)</span></h2>
        </div>
        <div class="score-detail-row">
          <div v-for="(crossTestScore, cIndx) in detailedScoreData.crossTestScores" :key="cIndx" class="stat-text-wrapper" :style="{ width: calcWidth(detailedScoreData.crossTestScores) + '%'}">
            <div class="stat">
              {{ crossTestScore.correct }}
            </div>
            <div class="sub-text">
              {{ crossTestScore.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sub-scores score-breakdown-row">
      <div class="w-100">
        <div class="p-0 section-header-sm">
          <h2>Sub-Scores <span class="call-out pl-2">(1-15)</span></h2>
        </div>
        <div class="no-gutters row score-detail-row">
          <div v-for="(subscore, cIndx) in detailedScoreData.subscores" :key="cIndx" class="stat-text-wrapper">
            <div class="stat">
              {{ subscore.correct }}
            </div>
            <div class="sub-text">
              {{ subscore.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    practiceTestId: { type: [Number, String], required: true },
    requestUrlBaseProp: { type: String, required: true }
  },
  data () {
    return {
      detailedScoreData: {}
    }
  },
  watch: {
    practiceTestId: {
      immediate: true,
      handler () {
        this.getDetailedScoreData()
      }
    }
  },
  methods: {
    calcWidth (data) {
      return 100 / data.length
    },
    getDetailedScoreData () {
      this.$http.get(`${this.requestUrlBaseProp}/score_breakdown`).then((response) => {
        this.detailedScoreData = response.data.scoreBreakdown
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@styles/variables';

.score-breakdown-row {
  padding: 20px 0 10px 20px;
  display: flex;
  > .w-50:last-child {
    padding-left: 20px;
    border-left: 1px solid #e8e8e8;
  }
}
.score-detail-row {
  margin: 10px 0;
  display: flex;
}

.stat-text-wrapper {
  width: calc(100% / 7);
}

.test-scores {
  width: 50%;
}

.cross-test-scores {
  width: 50%;
  padding-left: 20px;
  border-left: 1px solid #e8e8e8;
}

@media screen and (max-width: $mobileScreenLarge) {
  .sub-scores {
    .score-detail-row {
      .stat-text-wrapper {
        .stat {
          font-size: 28px;
        }
      }
    }
  }
}

@media screen and (max-width: $mobileScreenMedium) {
  .top-test-scores{
    display: block !important;
    padding-bottom: 0px !important;
    padding-left: 10px !important;

    .test-scores {
      width: 100%;

      .stat-text-wrapper {
        width: calc(100% / 3) !important;
      }
    }

    .cross-test-scores {
      width: 100%;
      padding: 20px 0px 0px 0px !important;
      border-left: 0;

      .stat-text-wrapper {
        width: calc(100% / 3) !important;
        padding: 0px 5px;
      }
    }
  }

  .sub-scores {
    padding-left: 10px;

    .score-detail-row {
      .stat-text-wrapper {
        padding: 0px 3px;

        .stat {
          font-size: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .sub-scores {
    .score-detail-row {
      display: block;

      .stat-text-wrapper {
        width: 100%;
        text-align: center;

        .stat {
          font-size: 28px;
        }
      }
    }
  }
}

@media screen and (max-width: $mobileScreenExtraSmall) {
  .section-header {
    h1 {
      font-size: 18px !important;
    }
  }
}
</style>
