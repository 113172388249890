<template>
  <div class="nav-menu-mobile-container">
    <router-link :to="{ name: 'StaffDashboard' }" class="menu-link-mobile head">
      <div class="nav-menu-div">
        <div class="nav-menu-title-text">Dashboard</div>
      </div>
    </router-link>
    <router-link :to="{ name: 'StaffEnrollmentsScheduleList' }" class="menu-link-mobile head">
      <div class="nav-menu-div">
        <div class="nav-menu-title-text">Enrollment</div>
      </div>
    </router-link>
    <router-link :to="{ name: 'StaffScheduleList' }" class="menu-link-mobile head">
      <div class="nav-menu-div">
        <div class="nav-menu-title-text">Schedules</div>
      </div>
    </router-link>
    <router-link :to="{ name: 'StaffOnlineAssessmentAdministrationList' }" class="menu-link-mobile head">
      <div class="nav-menu-div">
        <div class="nav-menu-title-text">Assessments</div>
      </div>
    </router-link>
    <router-link :to="{ name: 'StaffCoursesList' }" class="menu-link-mobile head" v-if="showCoursesLink()" >
      <div class="nav-menu-div">
        <div class="nav-menu-title-text">Courses</div>
      </div>
    </router-link>
    <router-link :to="{ name: 'StaffMessageList' }" class="menu-link-mobile head">
      <div class="nav-menu-div">
        <div class="nav-menu-title-text">Message Students</div>
      </div>
    </router-link>
    <router-link :to="{ name: 'StudentResultsDashboard' }" class="menu-link-mobile head">
      <div class="nav-menu-div">
        <div class="nav-menu-title-text">Student Results</div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  methods: {
    showCoursesLink() {
      //LMS GATEKEEPER: REMOVE AT RELEASE
      if (this.$route.query.showCourses == 1) return true
      return false
    }
  }
}
</script>
