<template>
  <div class="col-md-12">
    <instructor-modal
      :selected-instructor="selectedInstructor"
      @instructorUpdated="instructorUpdated"/>
    <create-instructor-modal
      @staff-added="addSchoolStaff"/>
    <import-instructors-modal
      :instructor-repository="instructorRepository"
      @imported="instructorsImported"/>
    <common-confirm
      :confirm-box-text="''"
      :confirm-callback="removeInstructorCallback"
      :confirm-message="removeInstructorMessage"
      :confirm-modal-id="'confirm-delete-instructor'"
      :cancel-button-text="'Cancel'"
      :confirm-button-text="'Delete'"/>
    <common-confirm
      :confirm-box-text="''"
      :confirm-callback="deleteSchoolCallback"
      :confirm-message="deleteSchoolMsg"
      :confirm-modal-id="'confirm-delete-school'"
      :cancel-button-text="'Cancel'"
      :confirm-button-text="'Delete'"/>
    <div class="no-gutters row">
      <div class="col-md-12 p-0">
        <h1>School Settings</h1>
        <hr>
      </div>
      <div class="card card-body no-gutters row">
        <h2 class="settings-heading">SETTINGS</h2>
        <br>
        <div class="row pt-4">
          <div class="col-md-6 pr-3 row">
            <div class="col-md-6 my-auto">
              <label for="schoolName" class="form-label">School Name</label>
            </div>
            <div class="col-md-6">
              <div class="input-group mb-3">
                <input
                  id="schoolName"
                  v-model="school.name"
                  :class="{ 'highlighter-red': $v.school.name.$error }"
                  type="text"
                  class="form-control"
                  placeholder=""
                  aria-label="District Name"
                  name="District Name"
                  required
                  @blur="$v.school.name.$touch">
              </div>
            </div>
          </div>
          <div class="col-md-6 pr-3 row">
            <div class="col-md-6">
              <label class="form-label mt-3">Assessment Course</label>
            </div>
            <div class="col-md-6">
              <div class="input-group mb-3">
                <button
                  id="schoolassessmentCourse"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  class="btn btn-outline-secondary ml-0 d-flex dropdown form-control horizon-input justify-content-between no-hover courseTypeSelect">
                  <span class="margin-top-auto margin-bottom-auto font-12">
                    {{ school.courseType }}</span>
                  <i class="dropdown-icon fa fa-chevron-down text-orange margin-top-auto margin-bottom-auto" aria-hidden="true"></i>
                  <div
                    class="dropdown-menu text-left">
                    <span
                      v-for="(courseType, courseTypeIndx) in courseTypes"
                      :key="courseTypeIndx"
                      :class="{ 'highlighter-red': $v.school.courseType.$error }"
                      :value="courseType"
                      @click="school.courseType=courseType"
                      class="dropdown-item text-decoration-0">
                      <a href="#" class="font-12 text-decoration-0">{{ courseType }}</a>
                    </span>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-6 pr-3 row">
            <div class="col-md-6 my-auto">
              <label for="schoolAddress" class="form-label">Street Address</label>
            </div>
            <div class="col-md-6">
              <div class="input-group mb-3">
                <input
                  id="schoolAddress"
                  v-model="school.address"
                  type="text"
                  class="form-control"
                  placeholder=""
                  aria-label="School Address"
                  name="School Address">
              </div>
            </div>
          </div>
          <div class="col-md-6 pr-3 row">
            <div class="col-md-6 my-auto">
              <label for="schoolBundleId" class="form-label">Thinkific Bundle Id</label>
            </div>
            <div class="col-md-6">
              <div class="input-group mb-3">
                <input
                  id="schoolThinkificBundleId"
                  v-model="school.thinkificBundleId"
                  type="text"
                  class="form-control"
                  placeholder=""
                  aria-label="Thinkific Bundle Id"
                  name="Thinkific Bundle Id">
              </div>
            </div>
          </div>
          <div class="col-md-6 pr-3 row">
            <div class="col-md-6 my-auto">
              <label for="schoolCity" class="form-label">City</label>
            </div>
            <div class="col-md-6">
              <div class="input-group mb-3">
                <input
                  id="schoolCity"
                  v-model="school.city"
                  type="text"
                  class="form-control"
                  placeholder=""
                  aria-label="School City"
                  name="School City">
              </div>
            </div>
          </div>
          <div class="col-md-6 pr-3 row">
            <div class="col-md-6 my-auto">
              <label for="schoolSeatsPurchased" class="form-label"># of Seats Purchased</label>
            </div>
            <div class="col-md-6">
              <div class="input-group mb-3">
                <input
                  id="schoolSeatsPurchased"
                  v-model="school.seatsPurchased"
                  type="number"
                  min="0"
                  class="form-control"
                  placeholder=""
                  aria-label="School Seats Purchased"
                  name="School Seats Purchased">
              </div>
            </div>
          </div>
          <div class="col-md-6 pr-3 row">
            <div class="col-md-6 my-auto">
              <label for="schoolEnrollmentCap" class="form-label">School Enrollment Cap</label>
            </div>
            <div class="col-md-6">
              <div class="input-group mb-3">
                <input
                  id="schoolEnrollmentCap"
                  v-model="school.maxEnrollment"
                  type="text"
                  class="form-control"
                  placeholder=""
                  aria-label="School Max Enrollment"
                  name="School Max Enrollment">
              </div>
            </div>
          </div>
          <div class="col-md-6 pr-3 row">
            <div class="col-md-6">
              <label class="form-label mt-3">State</label>
            </div>
            <div class="col-md-6">
              <div class="input-group mb-3">
                <button
                  id="schoolState"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  class="btn btn-outline-secondary ml-0 d-flex dropdown schoolStateSelect form-control horizon-input justify-content-between no-hover">
                  <span class="margin-top-auto margin-bottom-auto font-12">
                    {{ school.state }}</span>
                  <i class="dropdown-icon fa fa-chevron-down text-orange margin-top-auto margin-bottom-auto" aria-hidden="true"></i>
                  <div
                    class="dropdown-menu text-left">
                    <span
                      v-for="(state, stateIndx) in states"
                      :key="stateIndx"
                      :value="state"
                      @click="school.state=state"
                      class="dropdown-item">
                      <a href="#" class="font-12 text-decoration-0">{{ state }}</a>
                    </span>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-6 pr-3 row">
            <div class="col-md-6 my-auto">
              <label for="schoolZipCode" class="form-label">Zip Code</label>
            </div>
            <div class="col-md-6">
              <div class="input-group mb-3">
                <input
                  id="schoolZipCode"
                  v-model="school.postalCode"
                  type="text"
                  class="form-control"
                  placeholder=""
                  aria-label="School Postal Code"
                  name="School Postal Code">
              </div>
            </div>
          </div>
          <div class="col-md-6 pr-3 row">
            <div class="col-md-6">
              <label class="form-label mt-3">Waitlist Available</label>
            </div>
            <div class="col-md-6">
              <label class="m-2 switch">
                <input v-model="school.waitlistAvailable" type="checkbox" class="default">
                <span class="round slider"/>
              </label>
            </div>
          </div>
          <div class="col-md-6 pr-3 row">
              <div class="col-md-6">
                <label class="form-label mt-3">District</label>
              </div>
              <div class="col-md-6">
                <div class="input-group mb-3">
                  <button
                    id="schoolDistrict"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    class="btn btn-outline-secondary ml-0 d-flex dropdown schoolDistrictSelect form-control horizon-input justify-content-between no-hover">
                    <span class="margin-top-auto margin-bottom-auto font-12">
                      {{ school.schoolDistrict.name }}</span>
                    <i class="dropdown-icon fa fa-chevron-down text-orange margin-top-auto margin-bottom-auto" aria-hidden="true"></i>
                    <div :v-model="school.schoolDistrictId"
                      class="dropdown-menu text-left">
                      <span
                        v-for="(district, districtIndx) in schoolDistricts"
                        :key="districtIndx"
                        :value="district"
                        @click="(school.schoolDistrict=district, school.schoolDistrictId=district.id)"
                        class="dropdown-item text-decoration-0">
                        <a href="#" class="font-12 text-decoration-0">{{ district.name }}</a>
                      </span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div v-if="hideForACT" class="col-md-6 pr-3 row">
              <div class="col-md-6">
                <label class="form-label mt-3">SAT Online Available</label>
              </div>
              <div class="col-md-6">
                <label class="m-2 switch">
                  <input v-model="school.satOnlineAvailable" type="checkbox" class="default">
                  <span class="round slider"/>
                </label>
              </div>
            </div>
            <div class="col-md-6 pr-3 row">
            </div>
            <div v-if="hideForACT" class="col-md-6 pr-3 row">
              <div class="col-md-6">
                <label class="form-label mt-3">PSAT 8/9 Available</label>
              </div>
              <div class="col-md-6">
                <label class="m-2 switch">
                  <input v-model="school.psat89Available" type="checkbox" class="default">
                  <span class="round slider"/>
                </label>
              </div>
            </div>
            <div class="col-md-6 pr-3 row">
            </div>
            <div v-if="hideForACT" class="col-md-6 pr-3 row">
              <div class="col-md-6">
                <label class="form-label mt-3">PSAT 10 Available</label>
              </div>
              <div class="col-md-6">
                <label class="m-2 switch">
                  <input v-model="school.psat10Available" type="checkbox" class="default">
                  <span class="round slider"/>
                </label>
              </div>
            </div>
          <hr class="col-md-12">
        </div>
        <div class="no-gutters row">
          <div class="col-md-12 py-2">
            <h2 class="text-uppercase">Instructors</h2>
          </div>
          <hr class="col-md-12">
          <div class="col-md-12 table-responsive">
            <table class="table instructors-table">
              <thead class="bg-primary">
                <tr>
                  <th scope="col">
                    First Name
                  </th>
                  <th scope="col">
                    Last Name
                  </th>
                  <th scope="col">
                    Email
                  </th>
                  <th scope="col">
                    Account Type
                  </th>
                  <th scope="col"/>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(instructor, instructorIndx) in instructors"
                :data-instructor-id="instructor.id"
                :key="instructorIndx">
                    <td> {{ instructor.firstName }} </td>
                    <td> {{ instructor.lastName }} </td>
                    <td> {{ instructor.email }} </td>
                    <td> {{ instructor.role }} </td>
                    <td class="action-column">
                      <button
                        v-if="isMasqer()"
                        class="btn btn-outline-secondary gray-hover action-btn"
                        title="Login As User"
                        :id="buttonId(instructor.id)"
                        @click="masqAsInstructor(instructorIndx)">
                        <i class="fa fa-lg fa-people-arrows"></i>
                      </button>

                      <button
                        class="btn btn-outline-secondary gray-hover action-btn"
                        title="Edit Instructor"
                        :id="buttonId(instructor.id)"
                        @click="editInstructor(instructorIndx)">
                        <i class="fa fa-lg fa-pencil-alt"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-secondary gray-hover action-btn"
                        title="Delete Instructor"
                        @click="deleteInstructor(instructorIndx)">
                        <i class="fa fa-2x fa-trash"></i>
                      </button>
                    </td>
                </tr>
                <tr class="bg-light">
                  <th/>
                  <th/>
                  <th/>
                  <th class="text-right" colspan="2">
                    <button class="btn btn-outline-primary" style="margin-right:0px;" @click="importStaff">
                      <span class="font-14">Import Instructors</span>
                    </button>

                    <button class="btn btn-outline-primary" style="margin-left:6px;" @click="addStaff">
                      <span class="font-14">Add Instructor</span>
                    </button>
                  </th>
                </tr>

                <tr>
                  <td colspan="4" class="pagination-row">
                    <common-table-pagination
                      v-if="showPagination"
                      :meta="meta"
                      @paginate-data="paginateData" />
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
        <div class="no-gutters row">
          <div class="col-md-12 text-right">
            <button class="btn btn-outline-secondary" @click="backToSchoolList">
                <span class="btn-update-school">Cancel</span>
              </button>
            <button class="btn btn-primary" @click="saveSchool" id="update-school">
              <i class="fa fa-plus-circle fa-2x text-orange mr-1 v-align-middle"></i> <span class="font-14 v-align-middle"> Update School </span>
            </button>
            <button class="btn btn-primary float-right" @click="deleteSchool" id="delete-school">
              <i class="fa fa-trash fa-2x text-orange mr-1 v-align-middle"></i> <span class="font-14 v-align-middle"> Delete School </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InstructorModal from './instructor_modal.vue'
import CreateInstructorModal from './create_instructor_modal.vue'
import ImportInstructorsModal from './import_instructors_modal.vue'
import Mixins from '@submodules/mixins/mixin.js'
import { required } from 'vuelidate/lib/validators'
import RepositoryFactory from '@repositories/factory'
import CommonConfirm from '@submodules/components/common_confirm.vue'
import CommonTablePagination from '@submodules/components/common_table/pagination'
import { mapGetters } from "vuex"
import { mapActions } from "vuex"

export default {
  components: {
    InstructorModal,
    CreateInstructorModal,
    ImportInstructorsModal,
    CommonConfirm,
    CommonTablePagination
  },
  mixins: [Mixins],
  computed: {
    schoolId() {
      return this.$route.params.id
    },
    hideForACT () {
      if (this.school.courseType === "SAT") {
       return true
        } else {
        return false
        }
    }
  },
  data: function () {
    return {
      selectedInstructor: {},
      selectedInstructorId: '',
      forDeleteInstructorId: '',
      instructorRepository: RepositoryFactory.admin.school(this.$route.params.id).get('instructor'),
      school: {
        id: '',
        schoolDistrictId: '',
        address: '',
        city: '',
        state: '',
        postalCode: '',
        courseType: 'SAT',
        thinkificBundleId: '',
        maxEnrollment: '',
        newStaff: { firstName: '', lastName: '', email: '' },
        schoolDistrict: {}
      },
      courseTypes: ['ACT', 'SAT'],
      states: ('AL AK AZ AR CA CO CT DE FL GA HI ID IL IN IA KS KY LA ME MD MA MI MN MS ' +
                'MO MT NE NV NH NJ NM NY NC ND OH OK OR PA RI SC SD TN TX UT VT VA WA WV WI ' +
                'WY').split(' '),
      schoolDistricts: [],
      editView: false,
      instructors: [],
      showPagination: false,
      meta: {
        page: 1,
        currentPage: 1,
        perPage: 100,
        totalCount: 0,
        totalPages: 0,
        sort: null,
        order: null,
      }
    }
  },

  validations: {
    school: {
      name:             { required },
      state:            { required },
      courseType:       { required }
    }
  },

  created () {
    this.listDistricts()
    this.getSchool()
  },
  methods: {
    ...mapActions(["loadUserSession"]),
    ...mapGetters(["userMasqId"]),
    buttonId (instructorId) {
      return `edit-instructor-${instructorId}`
    },
    fetchInstructors () {
      this.$http.get(`/api/admin/schools/${this.schoolId}/instructors`, { params: this.meta }).then(response => {
        this.instructors = response.data.users
        this.meta = response.data.meta
        this.showPagination = this.meta.totalPages > 1
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    },

    deleteSchool () {
      $('#confirm-delete-school').modal('show')
    },

    deleteSchoolMsg () {
      return `<b>Are you sure you'd like to delete school?</b> <br/> <h4>This  will permanently delete everything associated with the school.</h4>`
    },

    deleteSchoolCallback () {
       this.$http.delete('/api/admin/schools/' + this.school.id).then(() => {
        this.$router.push({ name: 'AdminSchoolList' })
      }, (responseError) => {
        this.appendError({error: responseError, notification: true})
      })
    },

    addStaff () {
      $('#create-instructor-modal').modal('show')
    },

    editInstructor (instructorId) {
      this.selectedInstructorId = instructorId
      this.selectedInstructor = this.instructors[this.selectedInstructorId]
      $('#instructor-modal').modal('show')
    },

    masqAsInstructor (instructorId) {
      let selectedInstructor = this.instructors[instructorId]
      let payload = {
        email: selectedInstructor.email,
        password: selectedInstructor.email,
        masqid: this.userMasqId()
      }
      this.$http
        .post("/api/login", { login: payload })
        .then(() => {
          this.loadUserSession().then(() => {
            this.$router.push({ name: "StaffDashboard" })
          })
        })
        .catch(({ response }) => {
          this.appendError({ error: response })
        })
    },

    isMasqer () {
      if (this.userMasqId() == null) {
        return false
      }
      return true
    },

    deleteInstructor (instructorId) {
      this.forDeleteInstructorId = instructorId
      $('#confirm-delete-instructor').modal('show')
    },

    instructorUpdated (updatedInstructor) {
     if (this.isBlank(this.selectedInstructorId)) {
        this.instructors.push(updatedInstructor)
      } else {
        this.$set(this.instructors, this.selectedInstructorId, updatedInstructor)
      }
    },

    async addSchoolStaff (staff) {
      await this.instructorRepository.create(staff)
      this.fetchInstructors()
    },

    backToSchoolList () {
      this.$router.push({ name: 'AdminSchoolDashboard', params: { id: this.schoolId }})
    },

    getSchool () {
      this.$http.get(`/api/admin/schools/${this.schoolId}`).then((result) => {
        this.school = result.data.school
        this.fetchInstructors()
      }, (responseError) => {
        this.appendError({error: responseError, notification: true})
      })
    },

    updateSchool () {
      this.$http.put(`/api/admin/schools/${this.school.id}`, this.formData()).then(() => {
        this.appendSuccess({message: 'Updated school successfully.', notification: true})
        this.backToSchoolList()
        this.$router.go()
      }, (responseError) => {
        this.appendError({error: responseError, formData: this.formData(), notification: true})
      })
    },

    formData () {
      return {
        school: {
          name: this.school.name,
          id: this.school.id,
          schoolDistrictId: this.school.schoolDistrictId,
          address: this.school.address,
          city: this.school.city,
          state: this.school.state,
          postalCode: this.school.postalCode,
          courseType: this.school.courseType,
          thinkificBundleId: this.school.thinkificBundleId,
          maxEnrollment: this.school.maxEnrollment,
          waitlistAvailable: this.school.waitlistAvailable,
          satOnlineAvailable: this.school.satOnlineAvailable,
          psat89Available: this.school.psat89Available,
          psat10Available: this.school.psat10Available,
          seatsPurchased: this.school.seatsPurchased,
          instructorsAttributes: this.instructors,
        }
      }
    },

    saveSchool () {
      this.$v.$touch()
      if (this.$v.$invalid) { return }
      this.updateSchool()
    },

    listDistricts () {
      this.$http.get('/api/resource_lists/districts').then((result) => {
        this.schoolDistricts = result.data.districts
      }, (responseError) => {
        this.appendError({error: responseError, notification: true})
      })
    },

    removeInstructorCallback () {
      const baseUrl = this.instructorRepository.getBaseUrl()
      this.$http.delete(`${baseUrl}/${this.instructors[this.forDeleteInstructorId].id}`).then(() => {
        this.appendSuccess({message: 'Successfully Deleted Instructor.', notification: true})
        this.fetchInstructors()
        this.forDeleteInstructorId = ""
      }, (responseError) => {
        this.forDeleteInstructorId = ""
        this.appendError({error: responseError, notification: true})
      })
    },

    removeInstructorMessage () {
      let forDeleteInstructor = this.instructors[this.forDeleteInstructorId]
      return `Are you sure you'd like to delete <b>${forDeleteInstructor.firstName} ${forDeleteInstructor.lastName}?</b>`
    },

    paginateData(meta) {
      this.meta.currentPage = meta.currentPage
      this.fetchInstructors()
    },

    importStaff () {
      $('#import-instructors-modal').modal('show')
    },

    instructorsImported(result) {
      this.appendSuccess({ message: `${result.imported_count} of ${result.importable_count} instructors imported successfully`, notification: true })
      this.fetchInstructors()
      $('#import-instructors-modal').modal('hide')
    }

  }
}
</script>

<style scoped lang="scss">
  @import '~@styles/variables.scss';
  thead {
    height: 60px;
    border-bottom: none;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

  }
  tr {
    th {
      text-align: left;
      font-size: 16px;
      font-weight: 700;
      padding-left: 15px;
      vertical-align: middle;
    }
    td {
      text-align: left;
      font-weight: 400px;
      font-size: 14px;
      border-top: 1px solid $themeLtGray;
      padding-left: 10px;
    }
  }

  .pagination-row {
    padding: 0 0 5px 0;
  }

.settings-heading {
  padding-top: 18px;
  padding-bottom: 20px;
  padding-left: 10px;
  border-bottom: 1px solid $themeLtGray;

}
.form-label {
  font-size: 12px;
  color: #505050;
  font-weight: 600;
  text-transform: uppercase;
  vertical-align: middle;
  padding-left: 10px;
}
.form-control {
  height: 46px;
}

.btn-update-school {
  vertical-align: middle;
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 10px;
  padding-right: 5px;
  border-radius: 5px;
}
.margin-top-auto {
  margin-top: auto;
}
.margin-bottom-auto {
  margin-bottom: auto;
}
.action-column {
    text-align: right !important;
    .btn {
      margin-left: 0px !important;
      height: 40px !important;
      width: 40px !important;
    }
  }
</style>
