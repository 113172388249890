<template>
  <horizon-modal id="confirm-reset-modal" modal-title="Reset Learnosity Assessment" :close="close">
    <template v-slot:content>
      <p><b>Important</b></p>
      <span class="text-block font-12">
        The following irreversible tasks will be run when you reset this assessment.<br />
        1. Answered questions for {{ selectedTestSectionProp }} section will be permanently deleted.<br />
        2. {{ selectedTestSectionProp }} section  status will be updated to "Not Started". <br />
        3. Assessment scores will be re run to reflect deleted responses for {{ selectedTestSectionProp }} Section. <br />
      </span>
      <br>
      <div class="form-row">
          <div class="col-md-6 col-xs-12 form-group student-form-group div-1">
            <span> Student Name </span>
          </div>
          <div class="col-md-6 col-xs-12 form-group student-form-group div-2">
             <b> {{ learnosityAssessmentProp.firstName }} {{ learnosityAssessmentProp.lastName }} </b>
          </div>
          <div class="col-md-6 col-xs-12 form-group student-form-group div-1">
            <span> Student ID Number </span>
          </div>
          <div class="col-md-6 col-xs-12 form-group student-form-group div-2">
            <b> {{ learnosityAssessmentProp.studentIdNumber }} </b>
          </div>
          <div class="col-md-6 col-xs-12 form-group student-form-group div-1">
            <span> Test Section </span>
          </div>
          <div class="col-md-6 col-xs-12 form-group student-form-group div-2">
            <b> {{ selectedTestSectionProp }} </b>
          </div>

           <div class="col-md-6 col-xs-12 form-group student-form-group div-1">
            <span> Status </span>
          </div>
          <div class="col-md-6 col-xs-12 form-group student-form-group div-2">
            <b> {{ selectedTestSectionStatusProp }} </b>
          </div>
        </div>
      <hr>
          <div class="col-12 d-flex justify-content-end">
          <button class="btn btn-danger student-modal-button" @click="resetAssessment">
            <span class="font-14"> Reset {{ selectedTestSectionProp }} Section </span>
          </button>
        </div>
    </template>
  </horizon-modal>
</template>

<script>
import HorizonModal from '@shared/horizon_modal'

export default {
  components: {
    HorizonModal
  },
  props: {
    learnosityAssessmentProp: {
      type: Object,
      default () { return {} }
    },
    selectedTestSectionProp: {
      type: String
    },
     selectedTestSectionStatusProp: {
      type: String
    },
  },
  methods: {
    async resetAssessment () {
      this.$emit('resetted', this.learnosityAssessment)
      this.close()
    },
    close () {
      $('#confirm-reset-modal').modal('hide')
    }
  }
}
</script>

<style scoped lang='scss'>
</style>
