import { mapGetters, mapActions } from 'vuex'

export default {
	data() {
		return {
      practiceTestDates: [],
      activePracticeTestDate: {},
      selectedAssessmentId: null,
      activeSchedule: {
        targetAssessmentDate: {}
      }
		}
	},
  computed:{
    assessmentType () {
      if (this.isHorizonAdmin) {
        return this.adminAssessmentType;
      } else {
        return this.userSchool().courseType;
      }
    },
    entireSchool () {
      return { id: 0, name: 'Entire School', courseType: this.assessmentType }
    }
  },
	methods: {
    ...mapGetters(['userSchool']),

    async loadScheduleAndAssessment() {
	    this.selectedAssessmentId = this.$route.params.assessmentId
    	this.activeSchedule.id = this.$route.params.scheduleId

    	await this.loadSchedule(this.activeSchedule)
    },

    initData () {
      this.loadSchedule(this.activeSchedule)
    },

    async loadSchedule (schedule) {
      if(schedule.id == 0) {
        this.activeSchedule = this.entireSchool
        await this.loadSchoolPracticeTestSets()
      } else {
        let endpointURL = `/api/staff/schedules/${schedule.id}`
        if (this.isHorizonAdmin) {
          endpointURL = `/api/admin/schools/${this.$route.params.id}/schedules/${schedule.id}`
        }

        try {
        	const response = await this.$http.get(endpointURL)
          this.activeSchedule = response.data.schedule
          await this.loadPracticeTests()
        } catch (err) {
        	this.appendError({ error: err, notification: true })
        }
      }
    },

    async loadSchoolPracticeTestSets() {
      let endpointURL = `/api/staff/school/practice_test_sets/${this.selectedAssessmentId}`
      if (this.isHorizonAdmin) {
        endpointURL = `/api/admin/schools/${this.$route.params.id}/practice_test_sets/${this.selectedAssessmentId}`
      }

      try {
	      const response = await this.$http.get(endpointURL)
	      this.practiceTestDates = response.data.practiceTestSets
	      this.activePracticeTestDate = this.practiceTestDates.find(e => e.id == this.$route.params.assessmentId);
	    } catch (err) {
	    	this.appendError({ error: err, notification: true })
	    }
    },

 	  async loadPracticeTests () {
      let endpointURL = `/api/staff/schedules/${this.activeSchedule.id}/practice_test_dates`
      if (this.isHorizonAdmin) {
        endpointURL = `/api/admin/schools/${this.$route.params.id}/schedules/${this.activeSchedule.id}/practice_test_dates`
      }

      try {
	      const response = await this.$http.get(endpointURL)
        this.practiceTestDates = response.data.practiceTestDates
        this.activePracticeTestDate = this.practiceTestDates.find(practiceTest => practiceTest.id == this.selectedAssessmentId)
        if (this.isBlank(this.activePracticeTestDate)) {
          this.activePracticeTestDate = this.practiceTestDates[0]
          this.selectedAssessmentId = this.activePracticeTestDate.id
        }
	    } catch(err) {
	    	this.appendError({ error: responseError, notification: true })
	    }
    },

    requestUrlBase () {
      if(this.activeSchedule.id == 0)  {
        if (this.isHorizonAdmin) {
          return `/api/admin/schools/${this.$route.params.id}/practice_test_dates/${this.selectedAssessmentId}`
        } else {
          return `/api/staff/school/practice_test_dates/${this.selectedAssessmentId}`
        }
      } else {
        if (this.isHorizonAdmin) {
          return `/api/admin/schools/${this.$route.params.id}/schedules/practice_test_dates/${this.selectedAssessmentId}`
        } else {
          return `/api/staff/schedules/${this.activeSchedule.id}/practice_test_dates/${this.selectedAssessmentId}`
        }
      }
    },

	}
}