<template>
  <div>
    <div class="content-heading underlined">
      <div class="pull-left">
        <h1>Edit Schedule - {{ schedule.name }}</h1>
      </div>
      <div class="pull-right total-student-number">
        <span class="d-inline text-orange student-total-number">{{ schedule.studentsEnrolled }}</span>
        <span class="d-inline fw-400">Students Enrolled</span>
      </div>
    </div>

    <div class="bordered-card">
      <schedule-info-form
        :schedule="scheduleFormData"
        :errors="errors"
        :instructor-repository="instructorRepository"
        :target-assessment-date-repository="targetAssessmentDateRepository"
        :school-repository="schoolRepository"
      >
        <template v-slot:schedule-header-buttons>
          <action-button-group @save="save" @cancel="navToViewSchedule"></action-button-group>
        </template>

        <template
          v-if="scheduleFormData.scheduleType == 'outside'"
          v-slot:class-settings-form-group
        >
          <div class="horizon-form-row">
            <label>Class SMS Reminders</label>
            <div>
              <label id="schedule-class-sms-reminders-display" class="m-2 switch">
                <input
                  type="checkbox"
                  v-model="scheduleFormData.enableClassSmsReminders"
                  class="default"
                />
                <span class="round slider" />
              </label>
            </div>
          </div>

          <div class="horizon-form-row">
            <label>Class Email Reminders</label>
            <div>
              <label id="schedule-class-email-reminders-display" class="m-2 switch">
                <input
                  type="checkbox"
                  v-model="scheduleFormData.enableClassEmailReminders"
                  class="default"
                />
                <span class="round slider" />
              </label>
            </div>
          </div>

          <div class="horizon-form-row">
            <label for="class-location">Class Location</label>
            <input
              id="class-location"
              v-model="scheduleFormData.classLocation"
              type="text"
              class="horizon-input"
              placeholder
            />
          </div>
        </template>

        <template
          v-if="scheduleFormData.scheduleType === 'PSAT 8/9 Assessment' ||
                scheduleFormData.scheduleType === 'PSAT 10 Assessment'"
          v-slot:class-settings-form-group>
          <div class="horizon-form-row">
            <label for="schedule-enable-waitlist">Waitlist Available</label>
            <div>
              <label class="m-2 switch">
                <input
                  id="schedule-enable-waitlist"
                  v-model="scheduleFormData.enableWaitlist"
                  type="checkbox"
                  class="default">
                <span class="round slider"/>
              </label>
            </div>
          </div>
        </template>
      </schedule-info-form>

      <div class="pb-4 primary-row schedule-dates-header">
        <h2 class="text-uppercase">Schedule Dates</h2>
      </div>

      <div class="mt-3 row">
        <div class="col-12">
          <template v-if="scheduleFormData.scheduleType == 'Inside' || scheduleFormData.scheduleType == 'Outside'">
            <inside-schedule-class-dates-form
              v-if="scheduleFormData.scheduleType == 'Inside'"
              :classes="classDates"
            ></inside-schedule-class-dates-form>
            <outside-schedule-class-dates-form v-else :classes="classDates"></outside-schedule-class-dates-form>
          </template>

          <practice-test-dates-form
            :schedule="schedule"
            :practice-test-dates="practiceTestDates"
            :practice-test-repository="practiceTestRepository"
          ></practice-test-dates-form>
          <parent-night-dates-form
            v-if="scheduleFormData.scheduleType == 'Inside' || scheduleFormData.scheduleType == 'Outside'"
            :parent-night-dates="parentNightDates">
          </parent-night-dates-form>
        </div>
      </div>

      <div class="primary-row schedule-section-footer">
        <div></div>
        <action-button-group @save="save" @cancel="navToViewSchedule"></action-button-group>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ScheduleInfoForm from "@components/modules/schedule/schedule_info_form";
import ActionButtonGroup from "@components/modules/schedule/edit/action_button_group";
import InsideScheduleClassDatesForm from "@components/modules/schedule/edit/inside_schedule_classes_form";
import OutsideScheduleClassDatesForm from "@components/modules/schedule/edit/outside_schedule_classes_form";
import PracticeTestDatesForm from "@components/modules/schedule/edit/practice_tests_form";
import ParentNightDatesForm from "@components/modules/schedule/edit/parent_nights_form";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    ScheduleInfoForm,
    ActionButtonGroup,
    InsideScheduleClassDatesForm,
    OutsideScheduleClassDatesForm,
    PracticeTestDatesForm,
    ParentNightDatesForm
  },
  props: {
    schedule: {
      type: Object,
      required: true
    },
    schoolRepository: {
      type: Object,
      required: true
    },
    scheduleRepository: {
      type: Object,
      required: true
    },
    instructorRepository: {
      type: Object,
      required: true
    },
    targetAssessmentDateRepository: {
      type: Object,
      required: true
    },
    practiceTestRepository: {
      type: Object,
      required: false,
      default() { return null; }
    },
    viewScheduleRoute: {
      type: Object,
      required: true
    }
  },
  validations: {
    schedule: {
      name: { required },
      scheduleType: { required }
    }
  },
  data() {
    return {
      scheduleFormData: {
        name: "",
        assessmentCourseId: "",
        scheduleType: "",
        courseType: "",
        schoolAssessment: null,
        targetAssessmentDate: null,
        primaryTeacher: null,
        secondaryTeacher: null,
        enableParentNight: false,
        enableLiveEnrollment: false,
        enrollmentCap: 0,
        enableClassSmsReminders: false,
        enableClassEmailReminders: false,
        classLocation: "",
        enableAssessmentSmsReminders: false,
        enableAssessmentEmailReminders: false,
        assessmentLocation: "",
        zoomUrl: "",
        onlineAssessmentEnabled: false,
        untimedLearnosityAssessment: false,
        courseTemplate: null
      },
      classDates: [],
      practiceTestDates: [],
      parentNightDates: []
    };
  },
  watch: {
    schedule() {
      this.scheduleFormData.assessmentCourseId = this.schedule.assessmentCourseId;
      this.scheduleFormData.name = this.schedule.name;
      this.scheduleFormData.courseType = this.schedule.courseType;
      this.scheduleFormData.schoolAssessment = this.schedule.schoolAssessment;
      this.scheduleFormData.scheduleType = this.schedule.scheduleType
        ? this.schedule.scheduleType
        : null;
      this.scheduleFormData.targetAssessmentDate = this.schedule.targetAssessmentDate;
      this.scheduleFormData.primaryTeacher = this.schedule.primaryTeacher;
      this.scheduleFormData.secondaryTeacher = this.schedule.secondaryTeacher;
      this.scheduleFormData.enableParentNight = this.schedule.parentNight;
      this.scheduleFormData.enableLiveEnrollment = this.schedule.enrollmentLive;
      this.scheduleFormData.enrollmentCap = this.schedule.maxEnrollment;
      this.scheduleFormData.enableClassSmsReminders = this.schedule.classSmsReminders;
      this.scheduleFormData.enableClassEmailReminders = this.schedule.classEmailReminders;
      this.scheduleFormData.classLocation = this.schedule.classLocation;
      this.scheduleFormData.enableAssessmentSmsReminders = this.schedule.assessmentSmsReminders;
      this.scheduleFormData.enableAssessmentEmailReminders = this.schedule.assessmentEmailReminders;
      this.scheduleFormData.assessmentLocation = this.schedule.assessmentLocation;
      this.scheduleFormData.zoomUrl = this.schedule.zoomUrl;
      this.scheduleFormData.onlineAssessmentEnabled = this.schedule.onlineAssessmentEnabled;
      this.scheduleFormData.untimedLearnosityAssessment = this.schedule.untimedLearnosityAssessment;
      this.scheduleFormData.enableWaitlist = this.schedule.waitingListEnabled;
      this.scheduleFormData.courseTemplate = this.schedule.heCourseTemplates.length > 0
        ? this.schedule.heCourseTemplates[0].heCourse[0] : null

      this.classDates = this.schedule.scheduleDates.map(classDate => {
        return {
          id: classDate.id,
          meetingOn: classDate.meetingOn,
          startAt: classDate.startAt,
          endAt: classDate.endAt,
          _destroy: false
        };
      });

      this.practiceTestDates = this.schedule.practiceTestDates.map(testDate => {
        return {
          id: testDate.id,
          meetingOn: moment(testDate.meetingOn).format("YYYY-MM-DD"),
          startAt: testDate.startAt,
          endAt: testDate.endAt,
          questionsCount: testDate.questionsCount,
          practiceTestSetId: testDate.practiceTestSetId,
          _destroy: false
        };
      });

      this.parentNightDates = this.schedule.parentNightDates.map(
        parentNightDate => {
          return {
            id: parentNightDate.id,
            meetingOn: moment(parentNightDate.meetingOn).format("YYYY-MM-DD"),
            startAt: parentNightDate.startAt,
            endAt: parentNightDate.endAt,
            _destroy: false
          };
        }
      );
    }
  },
  computed: {
    errors() {
      return {
        schedule: {
          name: this.$v.schedule.name.$error
        }
      };
    }
  },

  methods: {
    async save() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const requestData = {
        assessmentCourseId:          this.scheduleFormData.assessmentCourseId,
        name:                        this.scheduleFormData.name,
        courseType:                  this.scheduleFormData.courseType,
        scheduleType:                this.$options.filters.capitalize(this.scheduleFormData.scheduleType),
        targetAssessmentDateId:      this.scheduleFormData.targetAssessmentDate ? this.scheduleFormData.targetAssessmentDate.id : null,
        primaryTeacherId:            this.scheduleFormData.primaryTeacher ? this.scheduleFormData.primaryTeacher.id : null,
        secondaryTeacherId:          this.scheduleFormData.secondaryTeacher ? this.scheduleFormData.secondaryTeacher.id : null,
        parentNight:                 this.scheduleFormData.enableParentNight,
        enrollmentLive:              this.scheduleFormData.enableLiveEnrollment,
        maxEnrollment:               this.scheduleFormData.enrollmentCap,
        waitingListEnabled:          this.scheduleFormData.enableWaitlist,
        classSmsReminders:           this.scheduleFormData.enableClassSmsReminders,
        classEmailReminders:         this.scheduleFormData.enableClassEmailReminders,
        classLocation:               this.scheduleFormData.classLocation,
        assessmentSmsReminders:      this.scheduleFormData.enableAssessmentSmsReminders,
        assessmentEmailReminders:    this.scheduleFormData.enableAssessmentEmailReminders,
        assessmentLocation:          this.scheduleFormData.assessmentLocation,
        onlineAssessmentEnabled:     this.scheduleFormData.onlineAssessmentEnabled,
        untimedLearnosityAssessment: this.scheduleFormData.untimedLearnosityAssessment,
        scheduleDatesAttributes:     this.classDates,
        practiceTestDatesAttributes: this.practiceTestDates,
        parentNightDatesAttributes:  this.parentNightDates,
        schoolId:                    this.schedule.schoolId,
        zoomUrl:                     this.scheduleFormData.zoomUrl,
        courseTemplateId:            this.scheduleFormData.courseTemplate ? this.scheduleFormData.courseTemplate.he_course_template_id : null
      };

      await this.scheduleRepository.update(this.schedule.id, requestData);
      this.navToViewSchedule();
    },
    navToViewSchedule() {
      this.$router.push(this.viewScheduleRoute);
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@styles/variables';

.total-student-number {
  display: inline-block;
  margin: 5px 0px 5px 6px;
  clear: none;
  color: #333;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
}

.student-total-number {
  display: inline-block;
  color: #f4701f;
  font-size: 28px;
  font-weight: 700;
}

.schedule-section-footer {
  padding-top: 0px;
  padding-bottom: 0px;
}

.pb-4 {
  padding-bottom: 15px !important;
  border-bottom: 0px !important;
}

.mt-3 {
  margin-top: 0px !important;
}

@media screen and (max-width: $mobileScreenExtraLarge) {
  .total-student-number {
    font-size: 20px !important;
  }

  .student-total-number {
    font-size: 22px !important;
  }

  .content-heading {
    display: inline-block;

    .pull-left {
      width: 100%;
    }

    .pull-right {
      width: 100%;
      float: left;
      text-align: left;
      margin-left: 0px;
    }
  }

  .underlined {
    border-bottom: 0px;
    padding-bottom: 0px;
  }

  .bordered-card {
    margin-top: 10px;
  }

}

@media screen and (max-width: $mobileScreenLarge) {
  .schedule-dates-header {
    padding-left: 0px;
  }
}
</style>
