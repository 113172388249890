<template>
  <table class="table table-primary">
    <thead>
      <tr>
        <th class="flex-fill h2 text-align-left text-white">
          Classes
        </th>
        <th class="h2 text-align-left text-white" scope="col">
          Date
        </th>
        <th class="h2 text-align-center text-white" scope="col">
          Start Time
        </th>
        <th class="h2 text-align-center text-white" scope="col">
          End Time
        </th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <template v-for="(classSchedule, idx) in classes">
        <tr :key="idx">
          <td class="fw-600">
            {{ `Class #${idx + 1}` }}
          </td>
          <td class="fw-400">
            <date-picker
              v-model="classSchedule.meetingOn"
              :config="datePickerConfig"
              :class="['class-meeting-on', 'horizon-input', 'text-left', {'highlighter-red': errors[idx].meetingOn.$error}]"/>
          </td>
          <td class="fw-400 text-align-center">
            <date-picker
              v-model="classSchedule.startAt"
              :config="timePickerConfig"
              :class="['class-start-at', 'horizon-input', 'text-center', {'highlighter-red': errors[idx].startAt.$error}]"/>
          </td>
          <td class="fw-400 text-align-center">
            <date-picker
              v-model="classSchedule.endAt"
              :config="timePickerConfig"
              :class="['class-end-at', 'horizon-input', 'text-center', {'highlighter-red': errors[idx].startAt.$error}]"/>
          </td>
          <td>
            <button
              type="button"
              class="btn btn-lg btn-outline-primary"
              @click="removeScheduleAt(idx)">
              <i class="fa fa-2x fa-trash"/>
            </button>
          </td>
        </tr>
      </template>
    </tbody>
    <tfoot>
      <tr>
        <th colspan="5">
          <button
            id="add-class-schedule-button"
            class="btn btn-outline-secondary float-right gray-hover"
            @click="appendNewClassRow">
            <i class="fa fa-lg fa-plus-circle"/> <span class="font-12">Add Class </span>
          </button>
        </th>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import DatePicker from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'


export default {
  components: {
    DatePicker
  },
  props: {
    classes: {
      type: Array,
      required: true,
      default() { return []; }
    },
    errors: {
      type: Object,
      required: false,
      default() { return []; }
    },
  },
  data() {
    return {
      datePickerConfig: {
        dateFormat: 'l, F j, Y',
      },
      timePickerConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'h:i K',
        time_24hr: false,
        static: true
      }
    }
  },
  methods: {
    appendNewClassRow() {
      this.classes.push(
        {
          meetingOn: null,
          startAt: null,
          endAt: null
        })
    },
    removeScheduleAt(index) {
      this.classes.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@styles/modules/schedule/schedule_dates_table.scss';
</style>
