<template>
  <div class="container">
    <div class="content-pane">
      <div class="pane-wrapper">
        <breadcrumbs v-if="breadcrumbs.length > 0" :breadcrumbs="breadcrumbs" class="breadcrumbs"/>
        <schedule-edit-pane
          :schedule="schedule"
          :school-repository="schoolRepository"
          :schedule-repository="scheduleRepository"
          :instructor-repository="instructorRepository"
          :target-assessment-date-repository="targetAssessmentDateRepository"
          :view-schedule-route="viewScheduleRoute"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ScheduleEditPane from "@components/modules/schedule/edit/pane";
import Breadcrumbs from "@shared/breadcrumbs";
import Mixins from "@submodules/mixins/mixin.js";
import { mapGetters } from "vuex";

import RepositoryFactory from "@repositories/factory";

export default {
  components: {
    Breadcrumbs,
    ScheduleEditPane
  },
  mixins: [Mixins],
  data() {
    return {
      breadcrumbs: [],
      schedule: {},
      schoolRepository: RepositoryFactory.staff.get("school"),
      instructorRepository: RepositoryFactory.staff.get("instructor"),
      scheduleRepository: RepositoryFactory.staff.get("schedule"),
      targetAssessmentDateRepository: RepositoryFactory.staff.get(
        "target_assessment_date"
      ),
      viewScheduleRoute: {}
    };
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      const school = await this.schoolRepository.info();

      const scheduleId = this.$route.params.id;
      this.schedule = await this.scheduleRepository.fetch(scheduleId);
      this.schedule.courseType = school.assessment; //set correct course type object

      this.viewScheduleRoute = {
        name: "StaffScheduleShow",
        params: { id: scheduleId }
      };

      this.breadcrumbs = [{ text: this.schedule.name }];
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@styles/variables';

@media screen and (max-width: $mobileScreenMedium) {
  .container {
    .breadcrumbs {
      display: none;
    }
  }
}

@media screen and (max-width: $mobileScreenSmall) {
  .container {
    padding: 0px;
    height: 100%;

    .content-pane {
      border-radius: 0px;
      height: 100%;

      .pane-wrapper {
        padding: 15px;
      }
    }
  }
}
</style>
