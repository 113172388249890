<template>
  <div v-if="calendarData" class="calendar-container icon-pane-container">
    <img class="main-icon" src="/images/calendar-icon.png">
    <h2>Course Schedule</h2>
    <div class="course-schedule-wrapper">
      <div class="info-row">
        <span class="font-weight-bold">Instructor: </span>{{ calendarData.primaryInstructorName }}
      </div>
      <div class="info-row">
        <span class="font-weight-bold">Location: </span>{{ calendarData.location.school }}
      </div>
      <div class="info-row">
        <span class="font-weight-bold">Course Start:</span>{{ calendarData.startDate }}
      </div>
      <div class="info-row">
        <span class="font-weight-bold">Course End: </span>{{ calendarData.endDate }}
      </div>
      <div v-if="calendarData.targetAssessmentDate" class="info-row">
        <span class="font-weight-bold">Target SAT: </span>{{ calendarData.targetAssessmentDate.name }}
      </div>
      <div class="info-row key-items-wrapper">
        <div v-for="(key, kIndx) in calendarKeyItems" :key="kIndx" class="key-item">
          <div class="key-circle" :style="{ backgroundColor: key.color }"/>
          {{ key.text }}
        </div>
      </div>
    </div>
    <div class="calendar-wrapper">
      <vue-cal
        xsmall
        events-count-on-year-view
        default-view="month"
        :disable-views="['years', 'week', 'day']"
        :events="calendarData.events">
        <template v-slot:cell-content="{ cell, view, events }">
          <span class="vuecal__cell-date">{{ cell.content }}</span>
          <span
            v-if="['month'].includes(view.id) && events.length"
            class="vuecal__cell-events-count"
            :class="events[0].classes[0]"/>
          <template v-if="['year'].includes(view.id)">
            <div class="year-test-wrapper">
              <div
                v-for="(event, eIndx) in events"
                :key="eIndx"
                class="vuecal__cell-events-count"
                :class="event.class"/>
            </div>
          </template>
        </template>
      </vue-cal>
    </div>
  </div>
</template>

<script>
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'

export default {
  components: {VueCal},
  props: {
    userTypeProp: { type: String, required: true }
  },
  data () {
    return {
      calendarKeyItems: [
        {text: 'Course Start', color: '#949494'},
        {text: 'Course End', color: '#345165'},
        {text: 'Assessment', color: '#f4701f'},
        {text: 'Target SAT', color: '#2cbc3b'}
      ],
      calendarData: null
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      this.$http.get(`/api/${this.userTypeProp}/schedules/calendar_data`).then((response) => {
        this.calendarData = response.data.calendarData

        this.calendarData.events = []
        if(this.calendarData.startDate) {
          this.calendarData.events.push({
            start: this.calendarData.startDate,
            end: this.calendarData.startDate,
            class: 'start'
          })
        }

        if(this.calendarData.endDate) {
          this.calendarData.events.push({
            start: this.calendarData.endDate,
            end: this.calendarData.endDate,
            class: 'end'
          })
        }

        if(this.calendarData.targetAssessmentDate) {
          this.calendarData.events.push({
            start: this.calendarData.targetAssessmentDate.name,
            end: this.calendarData.targetAssessmentDate.name,
            class: 'sat'
          })
        }

        this.calendarData.practiceTestDates.forEach(date => {
          this.calendarData.events.push({
            start: date,
            end: date,
            class: 'assessment'
          })
        })

        // Add check of class Zoom Url
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@styles/variables.scss';

	.calendar-container {
		display: flex;
		justify-content: center;
		padding: 10px 15px 15px 15px;

		h2 {
			margin-top: -10px;
			line-height: 150%;
		}

    @media screen and (max-width: $mobileScreenLarge) {
      h2 {
        margin-top: 0px;
        margin-bottom: 5px;
        text-align: center;
        line-height: 100%;
      }
    }

		.course-schedule-wrapper {
			font-size: 12px;
			display: flex;
			flex-direction: column;
			width: 100%;

			.info-row {
				margin: 3px 0;
				line-height: 150%;
				justify-content: center;
        text-align: center !important;
				color: #505050;
				display: flex;
				flex-wrap: wrap;

				.font-weight-bold {
					margin-right: 3px;
				}

				.key-item {
					width: 40%;
					font-size: 10px;
					display: flex;
					align-items: center;
					padding-left: 0px;
					padding-bottom: 4px;

					.key-circle {
						width: 10px;
						height: 10px;
						border-radius: 50%;
						margin-right: 5px;
					}
				}

        @media screen and (max-width: $mobileScreenExtraLarge) {
          .key-item {
            width: 60%;
            padding-left: 14px;
          }
        }

        @media screen and (max-width: $mobileScreenLarge) {
          .key-item {
            width: 80%;
          }
        }

        @media screen and (max-width: $mobileScreenMedium) {
          .key-item {
            width: 40%;
          }
        }
			}

      @media screen and (max-width: $mobileScreenMedium) {
        .key-items-wrapper {
          padding-left: 20%;
          padding-right: 20%;
        }
      }

      @media screen and (max-width: $mobileScreenExtraSmall) {
        .key-items-wrapper {
          padding-left: 10%;
          padding-right: 10%;
        }
      }
		}
	}

	.calendar-wrapper {
		margin-top: 8px;
		height: 400px;
		width: 100%;
	}
</style>

<style lang="scss">
  @import '~@styles/variables.scss';
  
  @media screen and (max-width: $mobileScreenLarge) {
    #horizaon_portal_app .vuecal__title-bar button {
      font-size: 11px;
    }
  }

  @media screen and (max-width: $mobileScreenMedium) {
    #horizaon_portal_app .vuecal__title-bar button {
      font-size: 1.3em;
    }
  }
</style>