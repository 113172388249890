var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-responsive"},[_c('horizon-table',{attrs:{"id":"meeting-dates-table","columns":_vm.columns,"data":_vm.meetingDates},scopedSlots:_vm._u([{key:"column:type",fn:function(ref){
var value = ref.value;
return [_c('span',{staticClass:"fw-600"},[_vm._v("\n        "+_vm._s(value)+"\n      ")])]}},{key:"column:meetingOn",fn:function(ref){
var value = ref.value;
return [_c('span',{staticClass:"short-date"},[_vm._v("\n        "+_vm._s(_vm._f("date")(value,'ddd, MMM D, YYYY'))+"\n      ")]),_vm._v(" "),_c('span',{staticClass:"long-date"},[_vm._v("\n        "+_vm._s(_vm._f("date")(value,'dddd, MMMM D, YYYY'))+"\n      ")])]}},{key:"column:startAt",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"text-center"},[_vm._v("\n        "+_vm._s(value)+"\n      ")])]}},{key:"column:endAt",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"text-center"},[_vm._v("\n        "+_vm._s(value)+"\n      ")])]}},{key:"column:attended",fn:function(ref){
var value = ref.value;
return [_c('div',{class:['fw-600', 'text-center', _vm.attendanceClass(value)]},[_vm._v("\n        "+_vm._s(value)+"\n      ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }