<template>
  <table class="h-table table table-striped">
    <thead>
      <tr>
			  <template v-for="(tableHeader, tIndx) in assessmentTableHeaders">
          <th :key="tIndx"> {{ tableHeader }} </th>
			  </template>
			  <th  width="140"></th>
			</tr>
    </thead>
    <tbody>
      <template v-for="(entry, eIndx) in assessmentDataProp">
        <tr v-for="(assessment, assessmentIndx) in entry.assessments" :key="eIndx + 'assessment' + assessmentIndx">
          <td v-if="assessmentIndx === 0" :rowspan="entry.assessments.length" class="with-rowspan">
            <b>{{ entry.scheduleName|ellipsize(30) }}</b>
            <span v-if="!isBlank(entry.primaryTeacherFullName)" class="text-orange">
              <br>
              {{ entry.primaryTeacherFullName}}
            </span>
            <span v-if="!isBlank(entry.secondaryTeacherFullName)">
              <br>
              {{ entry.secondaryTeacherFullName }}
            </span>
          </td>
          <td :class="setClass(assessment, entry)">{{ assessment.assessmentName }}</td>
          <td class="text-center" :class="setClass(assessment, entry)">
            <i class="fa fa-users pr-1 text-primary"/>{{ assessment.studentsAssessed }} ({{ assessment.studentsAssessedPercent}}%)
          </td>
          <td :class="setClass(assessment, entry)">
            <span v-if="!isBlank(assessment.averageScores)" class="text-orange fw-600">{{ assessment.averageScores.composite }}</span>
            <span v-else>---</span>
          </td>
				  <td :class="setClass(assessment, entry)">
						<span v-if="(assessment.totalStudentsImproved > 0)">{{ assessment.totalStudentsImproved }} ({{improvedStudentsPercent(assessment)}}%) </span>
					  <span v-else>---</span>
					</td>
					<td :class="setClass(assessment, entry)">
						<span class="fw-600" v-if="(assessment.averageScoreImprovement > 0)"> <span class="fw-600 text-success pl-1"> +{{ assessment.averageScoreImprovement }}</span> </span>
            <span v-else> --- </span>
					</td>
          <template v-if="satAssessment">
						<td :class="setClass(assessment, entry)">
							<span v-if="!isBlank(assessment.averageScores)">{{ assessment.averageScores.math }}</span>
							<span v-else>---</span>
						</td>
						<td :class="setClass(assessment, entry)">
							<span v-if="!isBlank(assessment.averageScores)">{{ assessment.averageScores.readingWriting }}</span>
							<span v-else>---</span>
						</td>
					</template>
					<template v-if="actAssessment">
						<td :class="setClass(assessment, entry)">
							<span v-if="!isBlank(assessment.averageScores)">{{ assessment.averageScores.ela }}</span>
							<span v-else>---</span>
						</td>
						<td :class="setClass(assessment, entry)">
							<span v-if="!isBlank(assessment.averageScores)">{{ assessment.averageScores.stem }}</span>
							<span v-else>---</span>
						</td>
					</template>
          <td :class="setClass(assessment, entry)">
            <router-link
              class="btn btn-outline-secondary report-button"
              :to="scoreReportLinkParams(assessment, entry)">
              View Report
            </router-link>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
export default {
	components: {},
  props: {
		assessmentTableHeaders: { type: Array },
		activeSchedule: { type: Object },
		activeAssessmentId: { default: '', type: [String,Number] },
    assessmentDataProp: {
      default: () => [],
      type: Array
    }
	},
  methods: {
    scoreReportLinkParams(assessment, entry){
      if(entry.scheduleId == 0) {
				return { name: 'StaffStudentScoreReport', params: {scheduleId: entry.scheduleId, assessmentId: assessment.practiceTestSetId} }
      } else {
				return { name: 'StaffStudentScoreReport', params: {scheduleId: entry.scheduleId, assessmentId: assessment.assessmentId} }
      }
    },
		setClass: function(assessment, entry) {
			if (assessment.assessmentId == this.activeAssessmentId && entry.scheduleId == this.activeSchedule.id) { return 'active-schedule' }
		},
		improvedStudentsPercent: function(assessment) {
      if (assessment.totalStudentsImproved && assessment.studentsAssessed)  { return ((assessment.totalStudentsImproved / assessment.studentsAssessed) * 100.0).toFixed() }
		}
	},
	computed: {
	  satAssessment () {
			if(this.activeSchedule.courseType === "SAT") { return true }
		},
		actAssessment () {
			if(this.activeSchedule.courseType === "ACT") { return true }
		}
	}
}
</script>

<style scoped lang="scss">
	@import '~@styles/variables.scss';

	.no-inset {
		box-shadow: none;
	}

	table {
		margin-bottom: 0;
	}

	table > * > tr > * {
		vertical-align: middle;
	}
  td {
		height: 38px;
	}
	tr {
		height: 38px;
		th:nth-child(1) {
			padding: 0.5em;
			width: 200px !important;
		}
    th:nth-child(2) {
			width: 180px !important;
		}
		th:nth-child(3) {
			width: 150px !important;
		}
		th:nth-child(5) {
			width: 180px !important;
		}
		th:nth-child(6) {
			width: 150px !important;
		}
		td:nth-child(-n+2), th:nth-child(-n+2) {
			text-align: left;
		}

		&:first-of-type td {
			border-top: none;
		}
	}

	thead {
		tr {
			font-size: 12px !important;
			font-weight: 600;
		}
	}

	.table thead th {
		border: none;
	}

	thead {
		background-color: $primaryColor;
	}

	table:not(.small-table) {

		th {
			border: none;

			&:first-child {
				border-radius: 5px 0 0 0;
			}

			&:last-child {
				border-radius: 0 5px 0 0;
			}
		}

	}

	tbody {
		box-shadow: inset 0px 0px 0px 1px #dee2e6;
	}

	tbody > * {
		font-size: 12px;
	}


	.table-footer {
		height: 38px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		background-color: $themeLtGray;
		padding: 0 20px;
		width: 100%;
		border-radius: 0 0 5px 5px;
		border: 1px solid #dee2e6;
	}

	.small-table {
		tr {
			height: 40px;
		}
	}

	.table-header {
		background-color: #F3F3F3;
		height: 60px;
		border-top: 1px solid #e8e8e8;
	}

	tr {
  td.active-schedule {
    background: #d1dae5 !important;
  }
}

.report-button {
  padding: 4px 6px;
  border: 1px solid #345165;
  font-size: 10px;
  line-height: 14px;
  font-weight: 400;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  &:not(:hover) {
    background-color: #ffffff;
    color:  #696969;
  }
}
.with-rowspan {
	border: 1px solid #dee2e6;
	background-color: white !important;
}

</style>
