<template>
  <div class="row">
    <div class="col-12">
      <table id="attendance-table" class="small-table table">
        <thead>
          <tr>
            <th scope="col" class="user-header"/>
            <th v-for="(entryHeader, idx) in attendanceList.entryHeaders" :key="idx" scope="col">
              <router-link
                v-if="canTakeAttendance(entryHeader)"
                :to="{ name: 'AdminSchoolAttendanceForm', params: {scheduleId: entryHeader.scheduleId, scheduleDateId: entryHeader.scheduleDateId} }">
                {{ entryHeader.title }}
              </router-link>
              <span v-else-if="futureAttendance(entryHeader)" class="text-muted"> {{ entryHeader.title }} </span>
              <span v-else class="text-orange">{{ entryHeader.title }}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(student, idx) in attendanceList.students" :key="idx">
            <td class="user-column">
              {{ student.studentName }}
            </td>
            <td v-for="(entryValue, entryIdx) in student.entriesValues" :key="entryIdx" :class="attendanceDisplayClass(entryValue)">
                {{ entryValue }}
            </td>
            <td class="border-right-0 border-left-0 border-bottom-0"></td>
            <td class="border-right-0 border-left-0 border-bottom-0"></td>
            <td class="border-right-0 border-left-0 border-bottom-0"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Mixins from '@submodules/mixins/mixin.js'

export default {
  mixins: [Mixins],
  props: {
    attendanceList: {
      type: Object,
      required: true
    }
  },
  methods: {
    attendanceDisplayClass (attendanceValue) {
      if (attendanceValue == 'P') {
        return 'present-attendance'
      } else if (attendanceValue == 'A') {
        return 'absent-attendance'
      } else if (attendanceValue == 'T') {
        return 'tardy-attendance'
      }
      else if (Number.isInteger(attendanceValue)) {
        return 'test-score'
      }
      else if (attendanceValue == '-') {
        return 'no-test-score'
      }
      return 'no-attendance-entry'
    },
    canTakeAttendance (entry) {
      if ( entry.attendance && new Date() >= new Date(entry.date) ) { return true }
    },
    futureAttendance (entry) {
      if ( entry.attendance && new Date() < new Date(entry.date) ) { return true }
    },
  },
  computed: {

  }
}
</script>

<style scoped lang="scss">
@import '~@styles/table_2.scss';

	.user-header {
		width: 200px !important;
	}

	td.user-column {
		text-align: right !important;
		background-color: #345165;
		color: #fff;
		font-size: 12px;
		font-weight: 600;
	}

	table {
		margin-top: 100px;
		table-layout: fixed;
    border-right: solid white;

    border-collapse: collapse;
	}

	thead {
		background-color: #fff;
		width: 100%;
		height: auto;
	}

	th {
		font-size: 12px;
		line-height: 150%;
		padding-bottom: 10px;
		font-weight: 700;
		width: 45px !important;
		column-width: 45px !important;
		transform: rotate(-45deg);
		border-top: none;
		border-bottom: none;
    white-space: nowrap;
	}

	td {
    border-top: 1px solid #e7e7e7;
    border-right: 1px solid #e7e7e7;
    border-left: 1px solid #e7e7e7;
		align-items: center;
    text-align: center;
    &:nth-child(-n+2) {
      text-align: center;
    }
    &:last-child {
      padding-right: .75rem;
    }
	}

	td.test-score {
		background-color: #fafafa;
		color: #546e80;
    font-weight: 600;
    border: 1px solid #e7e7e7 !important;

	}

	td.present-attendance {
    color: #76b848;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: center !important;

	}

	td.absent-attendance {
    color: red;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: center !important;
	}

	td.tardy-attendance {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: center !important;
  }
  td.no-attendance-entry {
    background: white;
    border: 1px solid #e7e7e7 !important;

  }
  td.no-test-score {
    background: white;
    border: 1px solid #e7e7e7 !important;

  }

</style>
