import client from '@repositories/client.js'

export default class {

  constructor(schoolId) {
    this.schoolId = schoolId
  }

  async create(schedule) {
    const requestBody = { ...schedule, schoolId: this.schoolId }
    const data = await client.post(`/api/admin/schedules`, { schedule: requestBody });
    return data.schedules
  }

  async get(scheduleId) {
    const data = await client.get(`/api/admin/schedules/${scheduleId}`);
    return data.schedule
  }

  async list() {
    const data = await client.get(`/api/admin/schools/${this.schoolId}/schedules`)
    return data.schedules
  }

  async update(scheduleId, schedule) {
    const requestBody = { ...schedule, schoolId: this.schoolId };
    const data = await client.put(`/api/admin/schedules/${scheduleId}`, { schedule: requestBody });
    return data.schedule;
  }

  async delete(scheduleId) {
    const data = await client.delete(`/api/admin/schedules/${scheduleId}`);
    return data.schedule
  }
}
