<template>
  <div class="container">
    <div class="content-pane">
      <div class="pane-wrapper">
        <div class="full-width primary-row no-underline">
          <breadcrumbs/>
          <div class="primary-row-action">
            <common-search 
              :options="schools"
              :optionsDisabled="true"
              @filter="searchChanged"
              placeholder="Search Schools"
            />
          </div>
        </div>
        <div class="content-heading underlined">
          <h1>School Name</h1>
          <h1 class="text-orange">
            {{ totalStudentsEnrolled }} <span class="fw-400 text-black">Students Enrolled</span>
          </h1>
        </div>
        <div v-for="school in schools" :key="school.id" class="full-width primary-row school">
          <h2 class="font-12 fw-600">
            {{ school.name }}
          </h2>
          <div class="primary-row-action">
            <div class="px-2">
              <h2 class="text-center text-dk-gray">
                {{ school.seatsPurchased }}
              </h2>
              <div class="font-12">
                Seats purchased
              </div>
            </div>
            <div class="px-2">
              <h2 class="text-center text-orange">
                {{ school.studentsEnrolled }}
              </h2>
              <div class="font-12">
                Students Enrolled
              </div>
            </div>
            <div class="pl-2">
              <button class="btn btn-outline-secondary text-small" @click="viewSchoolDashboard(school)">
                Admin Dashboard
              </button>
            </div>
          </div>
        </div>
        <common-table-pagination
          v-if="showPagination"
          :meta="meta"
          @paginate-data="paginateData"/>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from '@submodules/mixins/mixin.js'
import Breadcrumbs from '@shared/breadcrumbs'
import CommonTablePagination from '@submodules/components/common_table/pagination'
import CommonSearch from '@shared/common_search'

export default {
  components: {
    Breadcrumbs,
    CommonTablePagination,
    CommonSearch
  },
  mixins: [Mixins],
  data: function () {
    return {
      schools: [],
      totalStudentsEnrolled: '',
      showPagination: false,
      meta: {
        page: 1,
        currentPage: 1,
        perPage: 100,
        totalCount: 0,
        totalPages: 0,
        sort: null,
        order: null,
      }
    }
  },

  created () {
    this.listSchools()
    this.getStudentsEnrolled()
  },
  methods: {
    getStudentsEnrolled () {
      this.$http.get('/api/resource_lists/total_students_enrolled').then((result) => {
        this.totalStudentsEnrolled = result.data.studentsEnrolled
      })
    },
    newSchool () {
      this.router.push({ name: 'schoolNew' })
    },

    editSchool (school) {
      this.router.push({ name: 'schoolEdit', params: { id: school.id }})
    },

    removeSchool (school) {
      this.$http.delete('/api/admin/schools/' + school.id).then(() => {
        this.listSchools()
      }, (responseError) => {
        this.appendError({error: responseError, notification: true})
      })
    },

    searchChanged(query) {
      this.meta.search = query
      this.listSchools()
    },

    listSchools () {
      this.$http.get('/api/admin/schools', { params: this.meta }).then((result) => {
        this.schools = result.data.schools
        this.meta = result.data.meta
        this.showPagination = true
      }, (responseError) => {
        this.appendError({error: responseError, notification: true})
        this.showPagination = false
      })
    },

    viewSchoolDashboard (school) {
      this.$router.push({ name: 'AdminSchoolDashboard', params: { id: school.id }})
    },

    paginateData(meta) {
      this.meta.currentPage = meta.currentPage
      this.listSchools()
    },
  }
}
</script>

<style scoped lang="scss">
</style>
