<template>
  <div class="container">
    <div class="content-pane">
      <div class="pane-wrapper">
        <breadcrumbs v-if="breadcrumbs.length > 0" :breadcrumbs="breadcrumbs"/>
        <div class="content-heading underlined">
          <h1>View Attendance</h1>
        </div>
        <div class="attendance-info-container">
          <div class="attendance-left">
            <h2 class="pb-2">
              Overall Attendance Report - {{ activeSchedule.name }}
            </h2>
            <div class="students-enrolled">
              <h2 class="d-inline fw-400">
                <i class="fa fa-users"/> Number of Students Enrolled:
              </h2>
              <h2 class="d-inline fw-700 text-orange">
                {{ totalEnrollmentCount }} Students
              </h2>
            </div>
          </div>
          <div class="attendance-right">
            <common-dropdown :items="schedules" :item-display-key="'name'" :on-item-select="loadSchedule">
              <img
                src="/images/blue_calendar.png"
                width="17"
                aria-hidden="true"
                class="date-icon">
              <span style="vertical-align: middle; padding-left: 5px; font-size: 14px;">{{ truncateString(activeSchedule.name || "", 17) }}</span>
            </common-dropdown>
            <div class="attendance-export-button btn btn-outline-secondary">
              <a href="#" class="text-decoration-0" @click="downloadAttendanceExport">
                <i class="fa fa-external-link" aria-hidden="true"/>
                Export Report
              </a>
            </div>
          </div>
        </div>
        <attendance-table v-if="attendance" :attendance-list="attendance"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AttendanceTable from './attendance_table'
import Breadcrumbs from '@shared/breadcrumbs'
import Mixins from '@submodules/mixins/mixin.js'
import CommonDropdown from '@shared/common_dropdown'

export default {
  components: {
    Breadcrumbs,
    AttendanceTable,
    CommonDropdown
  },
  mixins: [Mixins],
  data () {
    return {
      scheduleId: null,
      activeSchedule: {},
      totalEnrollmentCount: 0,
      attendance: null,
      breadcrumbs: [],
      schedules: []
    }
  },
  computed: {},
  created () {
    this.scheduleId = this.$route.params.scheduleId
    this.schoolId = this.$route.params.schoolId
    this.initData()
  },
  methods: {
    truncateString(str, num) {
      if (str.length <= num) {
        return str
      }
      return str.slice(0, num) + '...'
    },
    ...mapGetters(['userSchool']),
    downloadAttendanceExport () {
      window.location = `/api/admin/schedules/${this.scheduleId}/export_attendance_report.csv`
    },
    loadSchedule (schedule) {
      if (schedule) {
        this.scheduleId = schedule.id
      }
      this.$http.get(`/api/admin/schedules/${this.scheduleId}`).then(response => {
        this.activeSchedule = response.data.schedule
        this.breadcrumbs = [{ text: this.activeSchedule.name }]
        this.$http.get(`/api/resource_lists/total_students_enrolled/${this.schoolId}/${this.scheduleId}`).then(response => {
          this.totalEnrollmentCount = response.data.studentsEnrolled
        })

        this.$http.get(`/api/admin/schedules/${this.scheduleId}/attendance_report`, this.indexParams()).then(response => {
          this.attendance = response.data
        })
      })
    },
    fetchAllData () {
      this.loadSchedule()
      this.$http.get(`/api/resource_lists/schedules/${this.schoolId}`).then(response => {
        this.schedules = response.data.schedules
      })
    },
    initData () {
      this.fetchAllData()
    },
    indexParams () {
      if (this.scheduleId) {
        return { params: { filters: { schedule: this.scheduleId } } }
      }
      return {}
    }
  }
}
</script>

<style scoped lang="scss">
	.attendance-export-button {
    height: 50px;
    padding: 13px 15px;
    min-width: 160px;
    font-size: 14px;
  }
  .attendance-export-button:hover {
    background-color:rgb(240, 240, 240) !important;
  }

  .attendance-info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    .attendance-right {
      display: flex;
    }
    .students-enrolled > h2 {
      font-size: 16px;
    }
  }
</style>
