import SatPropertyProvider from './sat_course_progress_property_provider'
import ActPropertyProvider from './act_course_progress_property_provider'

const PROPERTY_PROVIDER_CLASSES = {
  SAT: SatPropertyProvider,
  PSAT89: SatPropertyProvider,
  PSAT10: SatPropertyProvider,
  ACT: ActPropertyProvider,
  PREACT9: ActPropertyProvider,
  PREACT10: ActPropertyProvider,
}

export default {
  get (type, assessmentData) {
    const providerClass = PROPERTY_PROVIDER_CLASSES[type]
    return new providerClass(assessmentData)
  }
}
