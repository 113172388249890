<template>
    <horizon-modal
        id="edit-lesson-modal"
        modal-title="Edit lesson"
    >
        <template class="create-lesson-form w-form" v-slot:content>
            <div class="form" role="form">
                <input type="text" class="text-field w-input" maxlength="256" v-model="lesson.name">
                <label class="create-lesson-field-label">Lesson Name</label>
                <input type="text" class="text-field w-input" maxlength="256" v-model="lesson.memo">
                <label class="create-lesson-field-label">Lesson Memo Text</label>
                <div class="lesson-form-row w-100">
                    <div class="create-school-form-div _1">
                        <input class="text-field half w-input" maxlength="256" v-model="lesson.instructional_time">
                        <label class="create-lesson-field-label">Instructional Time</label>
                    </div>
                    <div class="create-school-form-div _2">
                        <input class="text-field half w-input" maxlength="256" v-model="lesson.independent_practice_time">
                        <label class="create-lesson-field-label">Independent Practice Time</label>
                    </div>
                </div>
                <select class="select-course w-select" v-model="lesson.independent_lesson">
                    <option v-for="activity in learnosityActivity"
                        :key="activity.id"
                        :selected="activity.id === lesson.independent_lesson"
                        :value="activity.id">{{ activity.name }}</option>
                </select>
                <label class="create-lesson-field-label">Independent Lesson Learnosity Activity</label>
                <select class="select-course w-select" v-model="lesson.practice_set">
                    <option v-for="activity in learnosityActivity"
                        :key="activity.id"
                        :selected="activity.id === lesson.practice_set"
                        :value="activity.id">{{ activity.name }}</option>
                </select>
                <label class="create-lesson-field-label">Practice Set Learnosity Activity</label>
                <input type="text" class="text-field w-input" maxlength="256" v-model="lesson.slide_presentation_link">
                <label class="create-lesson-field-label">Google Slides Presentation Link</label>
                <input type="text" class="text-field w-input" maxlength="256" v-model="lesson.support_video_link">
                <label class="create-lesson-field-label">Support Video Link</label>
            </div>
            <div class="col-12 d-flex justify-content-end pt-2">
                <button class="btn btn-primary text-block-2" @click="editLesson">
                    Update Lesson
                </button>
            </div>
        </template>
    </horizon-modal>
</template>
<script>
import HorizonModal from '@shared/horizon_modal'

export default {
    components: {
        HorizonModal
    },
    props: {
        lesson: Object
    },
    data () {
        return {
            learnosityActivity: Object,
        }
    },
    methods: {
        getLearnosityActivity (){
            this.$http.get(`/api/admin/course_activities/lessons/learnosity_activity`).then((result) => {
                this.learnosityActivity = result.data
            }, (responseError) => {
                this.appendError({error: responseError, notification: true})
            })
        },
        editLesson () {
            this.$emit('editLesson', this.formatEditLesson())
            $('#edit-lesson-modal').modal('hide')
        },
        formatEditLesson () {
            this.formatLearnosityBanks();
            this.formatSupportMaterials();
            return {
                he_course_section_id : this.lesson.he_course_section_id,
                course_lesson_id: this.lesson.id,
                lesson_name: this.lesson.name,
                lesson_memo: this.lesson.memo,
                order: this.lesson.order,
                instructional_time: this.lesson.instructional_time,
                independent_practice_time: this.lesson.independent_practice_time,
                learnosity_activity_banks: this.lesson.learnosity_activity_banks,
                support_materials: this.lesson.support_materials,
            }
        },
        formatLearnosityBanks () {
            this.lesson.learnosity_activity_banks.practice_set = this.lesson.practice_set
            this.lesson.learnosity_activity_banks.independent_lesson = this.lesson.independent_lesson
        },
        formatSupportMaterials () {
            this.lesson.support_materials.google.url = this.lesson.slide_presentation_link
            this.lesson.support_materials.support_videos.url = this.lesson.support_video_link
        },
    },
    mounted () {
        this.getLearnosityActivity();
    }
}
</script>
<style scoped lang="scss">
@import '~@styles/variables';
.create-lesson-form {
    margin-bottom: 0px;
}
.w-form {
    margin: 0 0 15px;
}
.form {
    margin-top: 5px;
    padding-right: 15px;
}
.lesson-form-row {
    display: flex;
    height: 70px;
    padding: 0 0px;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    margin-bottom: 10px;
}
.text-field, .select-course {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 5px;
    padding: 0px 15px;
    border-radius: 5px;
    -webkit-transition: border 175ms ease;
    transition: border 175ms ease;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    line-height: 24px;
}
.text-field.half {
        width: 100%;
        margin-top: 10px;
        font-family: 'Open Sans', sans-serif;
        color: #333;
        font-size: 12px;
}
.w-input, .w-select {
    display: block;
    width: 100%;
    height: 38px;
    padding: 8px 12px;
    margin-bottom: 0px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333333;
    vertical-align: middle;
    background-color: #ffffff;
    border: 1px solid #cccccc;
}
.create-lesson-field-label {
    margin-bottom: 3px;
    font-family: 'Open Sans', sans-serif;
    color: #345165;
    font-size: 12px;
    font-weight: 600;
    text-transform: none;
}
.create-school-form-div._1 {
    padding-right: 10px;
}
.create-school-form-div._2 {
    padding-left: 10px;
}
.create-school-form-div {
    display: inline-block;
    width: 50%;
    padding-right: 0px;
}
.lesson-drop {
    display: inline-block;
    width: 100%;
    padding-right: 0px;
}
.text-block-2 {
    font-size: 14px;
}
</style>
