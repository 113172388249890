<template>
  <horizon-modal :id="`${confirmedAction}-students-modal`" :modal-title="formatTitle() + ' Students'">
    <template v-slot:content>
      <div class="d-flex flex-column justify-content-end">
        <div class="col-12">
          <span class="body-header text-primary">
            {{ bodyHeader }}
          </span>
          <p class="body-paragraph">
            {{ bodyParagraph }}
          </p>
        </div>
        <div class="d-flex justify-content-end">
          <common-button
            class-list="btn btn-outline-primary btn-cancel"
            :on-click="cancel"
            button-text="Cancel"/>
          <common-button
            style="margin-left: 15px;"
            class-list="btn btn-primary btn-action"
            :on-click="submit"
            :button-text="confirmBtnText"/>
        </div>
      </div>
    </template>
  </horizon-modal>
</template>

<script>
import HorizonModal from '@shared/horizon_modal'
import CommonButton from '@shared/common_button'
export default {
  components: {
    HorizonModal,
    CommonButton
  },
  props: {
    cancel: {
      type: Function,
      required: true
    },
    bodyHeader: {
      type: String,
      required: true
    },
    bodyParagraph: {
      type: String,
      required: true
    },
    confirmBtnText: {
      type: String,
      required: true
    },
    confirmedAction: {
      type: String,
      required: true
    }
  },
  methods: {
    submit () {
      this.$emit('confirmed', this.confirmedAction)
    },
    formatTitle () {
      return this.confirmedAction.charAt(0).toUpperCase() + this.confirmedAction.substring(1)
    }
  }
}
</script>

<style scoped lang='scss'>
@import '~@styles/variables';

.btn-action {
  font-size: 14px;
  font-weight: 400;
}

.btn-cancel {
  font-size: 14px;
  font-weight: 400;
}

.body-paragraph {
  color: #333;
}

@media screen and (max-width: $mobileScreenSmall) {
  .justify-content-end {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;

    .btn {
      margin-left: 0px;
    }
  }
}
</style>
