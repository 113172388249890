<template>
  <div class="container">
    <div class="content-pane">
      <div class="pane-wrapper">
        <breadcrumbs class="breadcrumbs"/>
        <div class="content-heading underlined">
          <h1>Online Assessment Administration </h1>
        </div>
        <div class="row" v-if="computedSchedules.length > 0">
          <div v-for="(schedule, idx) in computedSchedules" :key="idx" class="col-md-4 col-xs-12 mt-3">
            <div class="card" :data-schedule-id="schedule.id">
              <div class="card-body stat-card">
                <div class="row">
                  <div class="col-6">
                    <div class="pull-left">
                      <h4 class="font-14 lh-18 text-primary" style="padding-bottom: 4px;">
                        {{ schedule.name }}
                      </h4>
                      <slot/>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="d-flex flex-row pull-right text-center">
                      <div v-if="schedule.studentsWaitListed > 0" class="d-flex flex-column pr-3">
                        <div class="bold-stat text-primary">
                          {{ schedule.studentsWaitListed }}
                        </div>
                        <div class="stat-text">
                          Students<br>Waitlisted
                        </div>
                      </div>
                      <div>
                        <div class="enrollment-count bold-stat text-orange">
                          {{ schedule.studentsEnrolled }}
                        </div>
                        <div class="stat-text">
                          Students<br>Enrolled
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="pull-right">
                  <div v-if="schedule.practiceTestDates.length > 0">
                    <button
                      v-for="(practiceTestDate, ipdx) in schedule.practiceTestDates"
                      :key="ipdx"
                      :class="`btn btn-outline-secondary`"
                      @click.prevent="navTo({
                        name: 'StaffOnlineAssessmentScheduleShow',
                        params: {
                            id: schedule.id,
                            practice_test_set_id: practiceTestDate.practiceTestSetId
                            }
                        })">
                      {{ getCourseTypeName(schedule.courseType) }} #{{ practiceTestDate.assessmentNumber}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <br>
          <h2 class="text-muted"> No online assessments available yet. </h2>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import ScheduleCard from '@shared/schedule_card'
import Breadcrumbs from '@shared/breadcrumbs'

const COURSE_TYPE_NAME_MAP = {
  'PSAT89': 'PSAT8/9'
}

export default {
  components: {
    Breadcrumbs,
    ScheduleCard
  },
  data () {
    return {
      schedules: []
    }
  },
  created () {
    this.fetchSchedules()
  },
  computed: {
    computedSchedules () {
      if (typeof this.schedules === "undefined") {
        return []
      }

      return _.map(this.schedules, (schedule) => {
        if (schedule.practiceTestDates.length > 0){
          schedule.practiceTestDates = _.orderBy(schedule.practiceTestDates, 'assessmentNumber', 'asc')
        }
        return schedule
      })
    }
  },
  methods: {
    fetchSchedules () {
      this.$http.get('/api/staff/schedules').then(response => {
        this.schedules = response.data.schedules
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    },
    navTo(routeOptions) {
      this.$router.push(routeOptions)
    },
    getCourseTypeName(courseType) {
      if (courseType in COURSE_TYPE_NAME_MAP) {
        return COURSE_TYPE_NAME_MAP[courseType]
      }
      return courseType
    }
  }
}
</script>
<style scoped lang='scss'>
  @import '~@styles/variables';

  .stat-card {
    min-height: 130px;
    margin-bottom: 5px;
    padding-right: 15px;
    padding-left: 15px;
  }
  .bold-stat {
		font-size: 18px;
		font-weight: 700;
  }
  .stat-text {
    color: #505050;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    padding-top: 4px;
  }

  @media screen and (max-width: $mobileScreenExtraLarge) {
    .col-md-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  @media screen and (max-width: $mobileScreenLarge) {
    .col-md-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  @media screen and (max-width: $mobileScreenSmall) {
    .container {
      padding: 0px;
      height: 100%;

      .breadcrumbs {
        display: none;
      }

      .content-pane {
        border-radius: 0px;
        height: 100%;

        .pane-wrapper {
          padding: 15px;
        }
      }
    }
  }
</style>
