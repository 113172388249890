<template>
  <div class="card" :data-schedule-id="schedule.id">
    <div class="card-body stat-card">
      <div class="row">
        <div :class="showWaitListed && schedule.studentsWaitListed > 0 ? 'col-6' : 'col-9'">
          <div class="pull-left">
            <h4 class="font-14 lh-18 text-primary" style="padding-bottom: 4px;">
              {{ cardTitle }}
            </h4>
            <slot/>
          </div>
        </div>
        <div :class="showWaitListed && schedule.studentsWaitListed > 0 ? 'col-6' : 'col-3'">
          <div class="d-flex flex-row pull-right text-center">
            <div v-if="showWaitListed && schedule.studentsWaitListed > 0" class="d-flex flex-column pr-3">
              <div class="bold-stat text-primary">
                {{ schedule.studentsWaitListed }}
              </div>
              <div class="stat-text">
                Students<br>Waitlisted
              </div>
            </div>
            <div>
              <div class="enrollment-count bold-stat text-orange">
                {{ schedule.studentsEnrolled }}
              </div>
              <div class="stat-text">
                Students<br>Enrolled
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="pull-right">
        <div v-if="buttons.length > 0">
          <button
            v-for="(button, idx) in buttons"
            :key="idx"
            :class="`btn btn-outline-${button.color}`"
            @click="onClick(button)">
            {{ button.text }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    cardTitle: {
      type: String,
      required: true
    },
    schedule: {
      type: Object,
      required: true
    },
    buttons: {
      type: Array,
      required: false,
      default: function () { return [] }
    },
    showWaitListed: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    entireSchool () {
      return !this.schedule.id
    }
  },
  methods: {
    onClick (button) {
      if ( this.entireSchool ) {
        button.onClick({schedule: {name: 'Entire School'}})
      } else {
        button.onClick({ id: this.schedule.id, schedule: this.schedule })
      }
    }
  }
}
</script>
<style scoped lang='scss'>
  @import '~@styles/variables';

  .stat-card {
    min-height: 142px;
    margin-bottom: 5px;
    padding-right: 15px;
    padding-left: 15px;
  }

  .bold-stat {
		font-size: 18px;
		font-weight: 700;
  }
  
  .stat-text {
    color: #505050;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    padding-top: 4px;
  }

  .card-footer {
    padding: 12px 15px;
  }
</style>
