<template>
  <div class="container">
    <div class="row">
      <div class="col-md-9">
        <div class="content-pane">
          <div class="assessment-results-wrapper">
            <div class="dashboard-student-heading">
              <img src="/images/assessment_results_icon.png" class="image-student-head">
              <h2 class="heading-student-name">
                My Assessment Results
              </h2>
            </div>
            <course-progress
              :property-provider="courseProgressProvider"/>
            <individual-score-reports
              v-if="assessmentData.length > 0"
              :score-report-route-prop="'ParentScoreReport'"
              :user-type-prop="userType"
              :assessment-data="assessmentData"/>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="content-pane h-col-mb">
          <course-resources/>
        </div>
        <div class="content-pane h-col-mb">
          <course-schedule
            :user-type-prop="userType"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CourseProgress from '@shared/parent_student/course_progress'
import CourseResources from './course_resources'
import CourseSchedule from '@shared/parent_student/course_schedule'
import IndividualScoreReports from '@shared/dashboard_widget/individual_score_report'

import CourseProgressProviderFactory from '@shared/parent_student/course_progress_property_provider_factory'
import RepositoryFactory from '@repositories/factory'
const AssessmentResultRepository = RepositoryFactory.parent.get('assessment_result')


export default {
  components: {
    CourseProgress,
    CourseResources,
    CourseSchedule,
    IndividualScoreReports
  },
  data () {
    return {
      userType: 'parent',
      assessmentData: [],
      courseProgressProvider: {}
    }
  },
  async created () {
    await this.initializeCourseProgressProvider()
  },
  methods: {
    async initializeCourseProgressProvider () {
      const scoresSummary = await AssessmentResultRepository.fetchCourseProgress()
      this.assessmentData = scoresSummary.scores
      this.courseProgressProvider = CourseProgressProviderFactory.get(scoresSummary.assessmentType, this.assessmentData)
    }
  }
}
</script>

<style scoped lang="scss">
.assessment-results-wrapper {
  padding: 10px 15px 18px;
}

.heading-student-name {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #345165;
  font-size: 32px;
  font-weight: 700;
}

.dashboard-student-heading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  padding: 10px;
  margin-bottom: 12px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
}

.image-student-head {
  width: 86px;
  height: auto;
  margin-top: 0px;
  margin-right: 17px;
  margin-bottom: 0px;
}
.col-9 {
  padding-right: 7.5px;
}
.col-3 {
  padding-left: 7.5px;
}
</style>
