<template>
  <staff-student-score-report
    :is-horizon-admin="true"/>
</template>

<script>
import StaffStudentScoreReport from '@staff/student_results/score_report/show'

export default {
  components: {
    StaffStudentScoreReport,
  },
}
</script>