<template>
  <div class="create-section-pane section-pane">
    <create-section-modal @newSection="createSection($event)"></create-section-modal>
    <div class="card card-body add-section-body">
      <button class="btn action-btn" @click="showCreateSectionModal">
        <img class="btn-icon" src="/images/round-add-button.png"><span class="add-section ml-2">Add Section</span>
      </button>
    </div>
  </div>
</template>
<script>
import CreateSectionModal from './modals/create_section_modal.vue'

export default {
  components: {
    CreateSectionModal,
  },
  props: {
    courseActivity: Object
  },
  methods: {
    showCreateSectionModal(){
     $('#create-section-modal').modal('show')
    },
    newSectionFormatData(newSection){
      return {
        section_name: newSection.name,
        section_memo: newSection.memo,
        order: this.courseActivity.he_course_section.length++
      }
    },
    createSection: function(newSection){
      this.$http.post(`/api/admin/course_activities/${this.courseActivity.id}/sections`, this.newSectionFormatData(newSection)).then((result) => {
        this.$emit('load-course')
        this.appendSuccess({ message: 'Created Section successfully', notification: true })
      }), (responseError) => {
        this.appendError({error: responseError.body.error, notification: true})
      }
    },
  }
}
</script>
<style lang="scss" scope>
.add-section {
    font-size: 24px;
    font-weight: 700;
    color: #345165;
    margin-bottom: 0;
    vertical-align: middle;
}
.add-section-body {
  border-color: #c7c7c7;
  border-style: solid;
  border-width: 1px;
  border-radius: 14px;
}
</style>