<template>
  <div>
    <div class="courses-container">
      <div v-for="(schedule, idx) in schedules" :key="idx" class="primary-row">
        <div class="course-info">
          <div class="info" :class="[ !staffView ? 'w-60' : 'w-70' ]">
            <h4 class="font-14 lh-16 text-primary">
              {{ schedule.name }}
            </h4>
            <template v-if="staffView">
              <h4 class="font-weight-bold lh-16">
                {{ schedule.scheduleType }} School Day
              </h4>
              <h4 class="font-weight-bold lh-16">
                Target Date: {{ schedule.targetAssessmentDate }}
              </h4>
              <h4 class="lh-16 text-orange">
                {{ schedule.primaryTeacherName }}
              </h4>
              <h4 class="lh-16">
                <div v-if="schedule.secondaryTeacherName">
                  {{ schedule.secondaryTeacherName }}
                </div>
              </h4>
            </template>
          </div>
          <div class="stats" :class="[ !staffView ? 'w-40' : 'w-30' ]">
            <div v-if="!staffView" class="row-stat-container">
              <h2 class="text-center text-primary">
                {{ schedule.maxEnrollment }}
              </h2>
              <div class="font-12">
                Enrollment Cap
              </div>
            </div>
            <div class="row-stat-container">
              <h2 class="text-center text-orange fw-700">
                {{ schedule.studentsEnrolled }}
              </h2>
              <div class="font-12">
                Students Enrolled
              </div>
            </div>
          </div>
        </div>
        <div class="primary-row-action">
          <button
            class="btn btn-outline"
            @click="enrollmentRoute(schedule, staffView)">
            Enrollment
          </button>
          <button
            class="btn btn-outline"
            @click="scheduleRoute(schedule, staffView)">
            Schedule
          </button>
          <button
            v-if="canSMS"
            class="btn btn-outline"
            @click="sendSmsModal(schedule)">
            Send SMS
          </button>
          <button
            v-if="canEmail"
            class="btn btn-outline"
            @click="sendEmailModal(schedule)">
            Send Email
          </button>
        </div>
      </div>
    </div>
    <app-email-modal
      v-if="selectedSchedule"
      :schedule="selectedSchedule"
      :school-level="false"/>
    <app-sms-modal
      v-if="selectedSchedule"
      :schedule="selectedSchedule"
      :school-level="false"/>
  </div>
</template>

<script>
import EmailModal from '@shared/send_email_modal.vue'
import SmsModal from '@shared/send_sms_modal.vue'
// import SchedulesList from '@shared/schedules_list.vue'

export default {
  components: {
    'app-email-modal': EmailModal,
    'app-sms-modal': SmsModal
    // 'app-schedules-list': SchedulesList
  },
  props: {
    schedules: {
      type: Array,
      default: () => []
    },
    staffView: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      selectedSchedule: {},
      canEmail: false,
      canSMS: false,
    }
  },
  created() {
    this.canEmail = this.$store.getters.userPermit({controller: 'EmailMessage', action: 'create'})
    this.canSMS = this.$store.getters.userPermit({controller: 'Message', action: 'create'})
  },
  methods: {
    enrollmentRoute (schedule, userType) {
      const routeName = userType ? 'StaffEnrollmentsShow' : 'SchoolEnrollmentShow'
      this.navTo(schedule, routeName)
    },
    scheduleRoute (schedule, userType) {
      const routeName = userType ? 'StaffScheduleShow' : 'AdminSchoolScheduleView'
      const params = userType ? {} : {id: schedule.schoolId}
      this.navTo(schedule, routeName, params)
    },
    navTo (schedule, routeName, params = {}) {
      const routeParams = Object.assign({id: schedule.id, scheduleId: schedule.id, schedule: schedule}, params)
      const routeOptions = { name: routeName, params: routeParams}
      this.$router.push(routeOptions)
    },
    sendEmailModal (schedule) {
      this.selectedSchedule = schedule
      $('#send-email-modal').modal('show')
    },
    sendSmsModal (schedule) {
      this.selectedSchedule = schedule
      $('#send-sms-modal').modal('show')
    }
  }

}
</script>

<style scoped lang="scss">
@import '~@styles/variables.scss';

.row-stat-container {
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
  justify-content: center;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.course-info {
  display: flex;
  width: 60%;

  .info {

  }

  .stats {
    display: flex;

    .row-stat-container {
      text-align: center;
      padding: 0px 7px;
    }
  }
}

.primary-row-action {
  width: 40%;
}

@media screen and (max-width: $mobileScreenExtraLarge) {
  .primary-row {
    display: block;
    padding-top: 15px;
    padding-bottom: 15px;

    .course-info {
      width: 100%;
    }

    .primary-row-action {
      width: 338px;
      margin-top: 10px;

      .btn {
        margin-left: 0px;
        margin-right: 10px;
      }
    }
  }
}

@media screen and (max-width: 426px) {
  .primary-row {
    .row-stat-container {
      text-align: right;
    }

    .primary-row-action {
      width: 100%;

      .btn {
        width: 22%;
        margin-right: 5px;
      }

      .btn:last-child {
        margin-right: 0px;
      }
    }
  }
}

@media screen and (max-width: $mobileScreenExtraSmall) {
  .primary-row {
    .primary-row-action {
      display: block;
      width: 170px;
      margin-left: auto;
      margin-right: auto;

      .btn {
        width: 170px;
        margin-top: 2px;
        margin-left: auto ;
        margin-right: auto;
        height: 30px !important;
      }
    }
  }
}
</style>
