<template>
  <div class="container">
    <div class="content-pane">
      <div class="pane-wrapper">
        <breadcrumbs/>
        <div class="content-heading underlined">
          <h1>District Name</h1>
          <button class="btn btn-primary float-right" @click="createNewDistrict">
            <i class="fa fa-plus-circle orange-icon"/> Create District
          </button>
        </div>
        <div v-for="district in schoolDistricts" :key="district.id" class="full-width primary-row">
          <h2 class="font-12 fw-600">
            {{ district.name }}
          </h2>
          <div class="primary-row-action">
            <button class="btn btn-outline-secondary text-small" @click="editDistrict(district)">
              View Settings
            </button>
          </div>
        </div>
      </div>
      <common-table-pagination
        v-if="showPagination"
        :meta="meta"
        @paginate-data="paginateData"/>
    </div>
  </div>
</template>

<script>
import Mixins from '@submodules/mixins/mixin.js'
import Breadcrumbs from '@shared/breadcrumbs'
import CommonTablePagination from '@submodules/components/common_table/pagination'

export default {
  components: {
    Breadcrumbs,
    CommonTablePagination
  },
  mixins: [Mixins],
  data: function () {
    return {
      schoolDistricts: [],
      meta: {},
      showPagination: false,
      meta: {
        page: 1,
        currentPage: 1,
        perPage: 50,
        totalCount: 0,
        totalPages: 0,
        sort: null,
        order: null,
      }
    }
  },
  created () {
    this.listDistricts()
  },
  methods: {

    createNewDistrict () {
      this.$router.push({ name: 'AdminDistrictNew' })
    },

    editDistrict (district) {
      this.$router.push({ name: 'AdminDistrictEdit', params: { id: district.id }})
    },

    listDistricts () {
      this.$http.get('/api/admin/school_districts', { params: this.meta }).then((result) => {
        this.schoolDistricts = result.data.schoolDistricts
        this.meta = result.data.meta
        this.showPagination = true
      }, (responseError) => {
        this.appendError({error: responseError, notification: true})
        this.showPagination = false
      })
    },

    paginateData(meta) {
      this.meta.currentPage = meta.currentPage
      this.listDistricts()
    },
  }
}
</script>

<style scoped lang="scss">
</style>
