<template>
  <div class="search-result">
    <div class="content-heading no-gutters row underlined mb-4">
      <div class="col-md-12 p-0">
        <h1>Search Results {{ searchResultsCount }}</h1>
      </div>
    </div>

    <common-confirm
      :confirm-box-text="''"
      :confirm-callback="removeUserCallback"
      :confirm-message="removeUserMessage"
      :confirm-modal-id="'confirm-delete-user'"
      :cancel-button-text="'Cancel'"
      :confirm-button-text="'Delete'"/>

    <update-user-modal
      :selected-user="userToEdit"
      @user-updated="userUpdated"/>

    <!-- loop users here -->
    <div v-for="user in users" :key="user.id">
      <div class="card card-body mt-2">
      <div class="row">
        <div class="col-md-12">
          <div><h1>{{ user.firstName + ' ' + user.lastName}}</h1></div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 pt-2">
          <div>{{ user.role }}</div>
          <div>{{ user.email }}</div>
          <div v-if="user.school != null">{{ user.school.name }}</div>
          <div v-if="user.schoolDistrict != null">{{ user.schoolDistrict.name }}</div>
        </div>

        <div class="col-md-4 pt-2">
          <div v-if="user.role == 'Student'">
            <div><strong>Student ID:&nbsp;</strong>{{ user.studentIdNumber }}</div>
            <div><strong>Schedule:&nbsp;</strong>{{ getSchedule(user.schedules) }}</div>
          </div>
        </div>


        <div class="col-md-4">
          <div class="row float-right pr-5 pt-2">
            <button
              v-if="canMasqueradeAs(user)"
              class="btn btn-outline-secondary gray-hover action-btn"
              title="Login As User"
              @click="masqAsUser(user)"
              >
              <i class="fa fa-lg fa-people-arrows"/>
            </button>
            <button
              class="btn btn-outline-secondary gray-hover action-btn"
              title="Edit User"
              @click="editUser(user)"
              >
              <i class="fa fa-lg fa-pencil-alt"/>
            </button>
            <button
              type="button"
              class="btn btn-outline-secondary gray-hover action-btn"
              title="Delete User"
              @click="showRemoveUserDialog(user)"
              >
              <i class="fa fa-2x fa-trash"/>
            </button>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import UpdateUserModal from './modal/update_user_modal.vue'
import CommonConfirm from '@submodules/components/common_confirm.vue'
import { mapGetters } from "vuex"
import { mapActions } from "vuex"

export default {
  components: {
    CommonConfirm,
    UpdateUserModal
  },
  props: {
    users: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      userToRemove: null,
      userToEdit: {},
    }
  },
  computed: {
    searchResultsCount() {
      if (this.users == null) {
        return ""
      } else {
        return `(${this.users.length})`
      }
    },
  },
  methods: {
    ...mapActions(["loadUserSession"]),
    ...mapGetters(["userMasqId"]),
    masqAsUser (user) {
      let selectedUser = user
      let payload = {
        email: selectedUser.email,
        password: selectedUser.email,
        masqid: this.userMasqId()
      }
      this.$http
        .post("/api/login", { login: payload })
        .then(() => {
          this.loadUserSession().then(() => {
            if (selectedUser.role == "Student") {
              this.$router.push({ name: "StudentCourses" })
            } else if (selectedUser.role == "Teacher" || selectedUser.role == "Teacher Admin") {
              this.$router.push({ name: "StaffDashboard" })
            }
          })
        })
        .catch(({ response }) => {
          this.appendError({ error: response })
        })
    },

    canMasqueradeAs(user) {
      if (user.role == "District Admin" || user.role == "Horizon Admin") {
        return false
      } else {
        return true
      }
    },

    getSchedule(schedules){
      const sched = schedules[0]
      return sched ? sched.name : "None"
    },

    async editUser(user) {
      this.userToEdit = user
      $('#update-user-modal').modal('show')
    },

    userUpdated(user) {
      var index = this.users.indexOf(this.userToEdit)
      this.$http.patch(`/api/admin/users/${this.userToEdit.id}`, { user: user }).then((response) => {
        // this.users[index] = response.data.user
        // this.users.$set(index, response.data.user)
        this.users.splice(index, 1, response.data.user)
        this.appendSuccess({message: 'Successfully updated user.', notification: true})
      }, (responseError) => {
        this.appendError({error: responseError, notification: true})
      })

      if (user.scheduleId != null) {
        let originScheduleId = this.userToEdit.schedules[0].id
        if (user.scheduleId != originScheduleId) {
          // update student's enrollment
          let enrollment = {
            originScheduleId: originScheduleId,
            scheduleId: user.scheduleId
          }
          this.$http.patch(`/api/admin/schedules/${originScheduleId}/enrollments/${user.enrollmentId}`, { enrollment: enrollment }).then(() => {
            this.appendSuccess({message: 'Successfully updated enrollment.', notification: true})
          }, (responseError) => {
            this.appendError({error: responseError, notification: true})
          })

        }
      }

      this.userToEdit = {}

    },

    showRemoveUserDialog(user) {
      this.userToRemove = user
      $('#confirm-delete-user').modal('show')
    },

    removeUserMessage() {
      return `Are you sure you'd like to delete <b>${this.userToRemove.firstName} ${this.userToRemove.lastName}?</b>`
    },

    removeUserCallback() {
      this.$http.delete(`/api/admin/users/${this.userToRemove.id}`).then(() => {
        this.appendSuccess({message: 'Successfully deleted user.', notification: true})
        var index = this.users.indexOf(this.userToRemove)
        this.users.splice(index, 1)
        this.userToRemove = null
      }, (responseError) => {
        this.userToRemove = null
        this.appendError({error: responseError, notification: true})
      })
    }

  },
}
</script>
<style scoped lang="scss">
  .btn-outline-secondary {
    padding: 10px 14px;
  }
</style>
