<template>
  <div class="container">
    <div class="content-pane">
      <div class="pane-wrapper">
        <breadcrumbs class="breadcrumbs"/>
        <desktop-only-view>
          <div class="course-pane">
            <div class="course-header">
              <img src="/images/assessment_results_icon.png" class="image-student-head">
              <h2> {{ courseActivity.name }} </h2>
            </div>
            <div class="sections-container">
              <div v-for="(section, index) in courseActivity.sections" :key="'section'+index">
                <section-pane v-if="sectionHasUnlockedLessons(index)" :id="'section-'+section.id" :section="section" :docVisibility="docVisibility"></section-pane>
              </div>
            </div>
          </div>
        </desktop-only-view>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumbs from '@shared/breadcrumbs'
import SectionPane from './section.vue'
import DesktopOnlyView from '@shared/desktop_only'
import { mapGetters } from "vuex"

export default {
  components: {
    Breadcrumbs,
    SectionPane,
    DesktopOnlyView
  },
  data() {
    return {
      courseActivity: {},
      docVisibility: '',
    }
  },
  methods: {
    ...mapGetters(["activeCourse"]),
    loadCourseActivity() {
      this.$http.get(`/api/student/course_activities/activity/${this.activeCourse().id}`).then((res) => {
        this.courseActivity = res.data.course_activity
      }, (responseError) => {
        this.appendError({error: responseError, notification: true})
      })
    },
    docVisibilityChanged() {
      this.docVisibility = document.visibilityState
    },
    sectionHasUnlockedLessons(index) {
      let unlocked_count = this.courseActivity.sections[index].lessons.filter(less => less.is_locked === false).length
      return unlocked_count > 0
    }

  },
  created() {
    this.loadCourseActivity()
  },
  mounted() {
    let learnosityScript = document.createElement('script')
    learnosityScript.setAttribute('src', 'https://reports.learnosity.com/')
    document.head.appendChild(learnosityScript)
    document.addEventListener('visibilitychange', this.docVisibilityChanged, false)
  },
}
</script>
<style scoped lang="scss">
  @import '~@styles/variables';

  .sections-container {
  }

  .course-header {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    margin: 0px 15px 0px 15px;
    margin-top: 0px;
    padding: 20px 0px 20px 0px;

    h2 {
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 36px;
      font-weight: 700;
      line-height: 150%;
      text-align: left;
    }

    .header-buttons {
      display: flex;
      a {
        border-color: #c7c7c7;
        background-color: white;
      }
      a:hover {
        border-color: #345165;
        color: #345165;
      }
    }

    .header-btn-text {
      font-size: 12px;
      font-weight: 400;
    }

    .header-btn-icon {
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }

  }

  .course-pane {
    border-color: #c7c7c7;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    padding: 10px 15px 15px;
  }

</style>
