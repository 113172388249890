<template>
  <div class="container">
    <div class="content-pane">
      <div class="pane-wrapper">
        <breadcrumbs/>
        <div class="content-heading underlined">
          <h1>
            {{ assessment.assessmentType }} - {{ actionVerb }} Answer Key
          </h1>
        </div>
        <div class="bordered-card">
          <h2 class="primary-row py-3">
            SETTINGS
          </h2>
          <div class="form-group">
            <div class="horizon-form-column">
              <div class="horizon-form-row">
                <label for="answerKeyName">Answer Key Name</label>
                <div class="input-group w-50">
                  <input
                    id="answerKeyName"
                    v-model="answerKey.answerKeyName"
                    type="text"
                    class="form-control"
                    placeholder=""
                    aria-label="Answer Key Name"
                    name="Answer Key Name"
                    required>
                </div>
              </div>
            </div>
            <div class="horizon-form-column">
              <div class="horizon-form-row">
                <label for="answerKeyId">ANSWER KEY TEST ID</label>
                <div class="input-group w-50">
                  <input
                    id="answerKeyId"
                    v-model="answerKey.answerKeyTestId"
                    type="number"
                    class="form-control"
                    placeholder=""
                    aria-label="Answer Key Test Id"
                    name="Answer Key Test Id"
                    required>
                </div>
              </div>
            </div>
          </div>
          <h2 class="primary-row py-3">
            ANSWER KEY
          </h2>
          <div class="mt-3 report-wrapper">
            <div class="tabs-row">
              <div
                v-for="(categoryGroup, categoryGroupIndx) in categoryGroups"
                :key="categoryGroupIndx"
                class="tab"
                :class="{ 'selected': categoryGroup.selected }"
                @click="updateCategoryGroup(categoryGroup)">
                <div>{{ categoryGroup.name }}</div>
              </div>
            </div>
            <table class="table">
              <thead class="bg-primary text-left">
                <tr class="no-gutters row">
                  <th scope="col" class="col-md-1 py-3 text-center">
                    Question
                  </th>
                  <th scope="col" class="col-md-2 py-3 text-center">
                    Correct Answer
                  </th>
                  <th scope="col" class="col-md-9 py-3">
                    Category Assignments
                  </th>
                </tr>
              </thead>
              <tbody>
                <td id="home" colspan="6" class="active fade show tab-pane" role="tabpanel" aria-labelledby="home-tab">
                  <act-english-answer-row
                    v-for="(answer, answerIndx) in selectedCategory.answerEntries"
                    :key="answerIndx"
                    :answer-category-indx-prop="answerIndx + 1"
                    :inputs-set-prop="answerKeyAttributes"
                    :topic-areas-prop="computedTopicAreasAcrossSubsections"
                    :answer-entry-prop="answer"
                    @answerRowUpdated="answerRowUpdated"/>
                </td>
              </tbody>
            </table>
          </div>
          <div class="no-gutters pt-3 row text-right">
            <div class="col-md-12 text-right">
              <button class="btn btn-outline-primary float-right mx-2" @click="backToAnswerKeyList">
                Cancel
              </button>
              <button class="btn btn-primary float-right" @click="saveAnswerKey">
                Save Answer Key
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Mixins from '@submodules/mixins/mixin.js'
import Breadcrumbs from '@shared/breadcrumbs'
import ActEnglishAnswerRow from './act/english_answer_row.vue'

export default {
  components: {
    ActEnglishAnswerRow,
    Breadcrumbs
  },
  mixins: [Mixins],
  data () {
    return {
      selectedCategory: {
        answerEntries: []
      },
      categoryGroups: [],
      answerKeyAttributes: {
        correctAnswers: true,
        subscoreArea: true,
        modeling: true,
        image: true,
        topicArea: true,
        difficulty: true
      },
      answerKeyId: '',
      actionVerb: '',
      answerKey: {},
      assessment: {},
      assessmentId: ''
    }
  },
  computed: {
    computedTopicAreasAcrossSubsections () {
      // Concat all topic areas across subsections and flatten the array
      return [].concat(...this.selectedCategory.subsectionAreas).map(x => x.topicAreas).flat()
    }
  },
  created () {
    if (this.$route.params.assessmentId != undefined) {
      this.assessmentId = this.$route.params.assessmentId
      this.fetchAssessment()
    }
    if (this.$route.params.answerKeyId != undefined) {
      this.answerKeyId = this.$route.params.answerKeyId
      this.actionVerb = 'Edit'
      this.fetchAnswerKey()
    } else {
      this.actionVerb = 'New'
    }
  },
  methods: {
    backToAnswerKeyList () {
      this.$router.push({name: 'AdminAssessmentAnswerKeyList', params: { id: this.assessmentId } })
    },
    createAnswerKey () {
      this.$http.post(`/api/admin/assessments/${this.assessment.id}/answer_keys`, this.formData()).then(() => {
        this.appendSuccess({message: 'Created New Answer Key Successfully', notification: true})
        this.backToAnswerKeyList()
      }, (responseError) => {
        this.appendError({error: responseError, notification: true})
      })
    },
    fetchAnswerKey () {
      this.$http.get(`/api/admin/assessments/${this.assessmentId}/answer_keys/${this.answerKeyId}`).then(response => {
        this.answerKey = response.data.answerKey
        this.categoryGroups = response.data.answerKey.categoryGroups
        this.updateCategoryGroup(this.categoryGroups[0])
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    },
    fetchAssessment () {
      this.$http.get(`/api/admin/assessments/${this.assessmentId}`).then(response => {
        this.assessment = response.data.assessment
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    },
    fetchAnswerEntries (category) {
      if (!this.isBlank(category.answerEntries)) {
        this.selectedCategory = category
        return
      }

      this.$http.get(`/api/admin/assessments/${this.assessmentId}/answer_keys/${this.answerKey.id}/answer_entries/${category.id}`).then(response => {
        category.answerEntries = response.data.answerEntries
        this.selectedCategory = category
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    },
    formData () {
      return {
        answerKey: {
          id: this.answerKeyId,
          answerKeyName: this.answerKey.answerKeyName,
          answerKeyTestId: this.answerKey.answerKeyTestId,
          answerEntriesAttributes: this.formDataAnswerEntries()
        }
      }
    },
    formDataAnswerEntries () {
      const answerEntriesArray = []
      this.categoryGroups.forEach((category) => {
        if (this.isBlank(category.answerEntries)) { return }

        category.answerEntries.forEach((entry) => {
          answerEntriesArray.push({
            id: this.toId(entry.id),
            _destroy: this.toDestroy(entry._destroy),
            file: entry.file,
            answers: this.formDataAnswerEntryAnswers(entry),
            metaData: this.formDataAnswerEntryMeta(entry)
          })
        })
      })
      return answerEntriesArray
    },

    //     :id,
    // :answerKeyId,
    // :categoryGroupId,
    // :question,
    // :file,
    // :_destroy,
    // answers: %i[answer opt1 opt2 opt3 from to],
    // metaData: %i[
    //   subcoreAreaId subcore1AreaId subscore2AreaId
    //   crossTest topicAreaId difficulty modeling
    // ]

    formDataAnswerEntryAnswers (entry) {
      return {
        answer: entry.answers.answer,
        opt1: entry.answers.opt1,
        opt2: entry.answers.opt2,
        opt3: entry.answers.opt3,
        from: entry.answers.from,
        to: entry.answers.to
      }
    },

    formDataAnswerEntryMeta (entry) {
      return {
        subcoreAreaId: entry.metaData.subscoreAreaId,
        subcore1AreaId: entry.metaData.subcore1AreaId,
        subscore2AreaId: entry.metaData.subscore2AreaId,
        crossTest: entry.metaData.crossTest,
        topicAreaId: entry.metaData.topicAreaId,
        difficulty: entry.metaData.difficulty,
        modeling: entry.metaData.modeling
      }
    },

    saveAnswerKey () {
      if (this.answerKeyId) {
        this.updateAnswerKey()
      } else {
        this.createAnswerKey()
      }
    },
    addNewAnswer (newAnswer) {
      this.answerEntries.push(newAnswer)
    },

    answerRowUpdated (answerUpdated) {
      const categoryIndx = this.categoryGroups.findIndex(x => x.id == this.selectedCategory.id)
      const updatedAnswerIndx = this.categoryGroups[categoryIndx].answerEntries.findIndex(x => x.id == answerUpdated.id)
      this.categoryGroups[categoryIndx].answerEntries.splice(updatedAnswerIndx, 1, answerUpdated)
    },
    updateAnswerKey () {
      this.$http.put(`/api/admin/assessments/${this.assessment.id}/answer_keys/${this.answerKeyId}`, this.formData()).then(() => {
        this.appendSuccess({message: 'Created New Answer Key Successfully', notification: true})
        this.backToAnswerKeyList()
      }, (responseError) => {
        this.appendError({error: responseError, notification: true})
      })
    },
    updateCategoryGroup (categoryGroup) {
      this.categoryGroups.forEach(function (category) {
        category.selected = false
      })
      categoryGroup.selected = true
      this.fetchAnswerEntries(categoryGroup)
    }
  }
}
</script>

<style scoped lang="scss">
.table > tbody > td {
  padding: 0;
}
.table > thead > tr > th {
  border-bottom: none;
}
</style>
