<!--- DEPRECATED: Use app/javascript/packs/components/modules/enrollment/modal/delete_modal.vue instead -->
<template>
  <confirm-modal
    :cancel="close"
    body-header="Are you sure you want to delete the selected student?"
    body-paragraph="Deleting student will permanently erase their accounts and corresponding scoring data."
    confirm-btn-text="Yes, delete student."
    confirmed-action="delete"
    @confirmed="deleteStudent"/>
</template>

<script>
import ConfirmModal from './confirm_modal'

import RepositoryFactory from '@repositories/factory'
const EnrollmentRepository = RepositoryFactory.staff.get('enrollment')

export default {
  components: {
    ConfirmModal
  },
  props: {
    student: {
      type: Object,
      default () { return null }
    }
  },
  methods: {
    async deleteStudent () {
      await EnrollmentRepository.delete(this.student.id)
      this.$emit('deleted', this.student)
      this.close()
    },
    close () {
      $('#delete-students-modal').modal('hide')
    }
  }
}
</script>

<style scoped lang='scss'>
</style>
