<template>
  <div>
    <table class="table table-striped table-full">
      <thead>
        <tr>
          <th>Schedule</th>
          <th># of Students Assessed</th>
          <th>Overall Score</th>
          <th>English Score</th>
          <th>Reading Score</th>
          <th>Math Score</th>
          <th>Science Score</th>
          <th class="options"/>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(entry, eIndx) in assessmentRows"
          :key="eIndx"
          :class="tableRowClass(entry)">
          <td class="schedule text-truncate">
            {{ entry.schedule }}
          </td>
          <td>
            <i class="fa fa-users text-primary"/>
            {{ `${entry.studentsAssessed} (${entry.studentsAssessedPercent.toFixed(1)}%)` }}
          </td>
          <td>
            <span class="fw-600 text-orange">
              {{ entry.composite }}
            </span>
          </td>
          <td>{{ entry.english }}</td>
          <td>{{ entry.reading }}</td>
          <td>{{ entry.math }}</td>
          <td>{{ entry.science }}</td>
          <td>
           <router-link
              class="btn btn-outline-primary schedule-button"
              :to="scoreReportPageLink(entry)">
              View Report
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
    <table class="table-mini">
      <tr v-for="(entry, eIndx) in assessmentRows" :key="eIndx" :class="tableRowClass(entry)">
        <td>
          <table class="table table-striped table-mini-entry" width="100%">
            <thead>
              <tr>
                <th colspan="2">{{ entry.schedule }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="table-mini-label"># of Students Assessed</td>
                <td class="table-mini-value"><i class="fa fa-users text-primary"/> {{ `${entry.studentsAssessed} (${entry.studentsAssessedPercent.toFixed(1)}%)` }}</td>
              </tr>
              <tr>
                <td class="table-mini-label">Overall Score</td>
                <td class="table-mini-value"><span class="fw-600 text-orange">{{ entry.composite }}</span></td>
              </tr>
              <tr>
                <td class="table-mini-label">English Score</td>
                <td class="table-mini-value">{{ entry.english }}</td>
              </tr>
              <tr>
                <td class="table-mini-label">Reading Score</td>
                <td class="table-mini-value">{{ entry.reading }}</td>
              </tr>
              <tr>
                <td class="table-mini-label">Math Score</td>
                <td class="table-mini-value">{{ entry.math }}</td>
              </tr>
              <tr>
                <td class="table-mini-label">Science Score</td>
                <td class="table-mini-value">{{ entry.science }}</td>
              </tr>
              <tr>
                <td colspan="2"><router-link class="btn btn-outline-primary schedule-button" :to="scoreReportPageLink(entry)">View Report</router-link></td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    assessments: {
      default: () => [],
      type: Array
    },
    practiceTestSetId: {
      type: Number,
      default: () => null
    },
    activeSchedule: {
      type: Object,
      default () { return null },
      required: false
    },
    isHorizonAdmin: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    entireSchoolAssessment () {
      const totalStudents = _.sumBy(this.assessments, assessment => assessment.students)
      const totalStudentsAssessed = _.sumBy(this.assessments, assessment => assessment.studentsAssessed)
      const totalComposite = _.sumBy(this.assessments, assessment => assessment.studentsAssessed * assessment.composite)
      const totalEnglish = _.sumBy(this.assessments, assessment => assessment.studentsAssessed * assessment.english)
      const totalReading = _.sumBy(this.assessments, assessment => assessment.studentsAssessed * assessment.reading)
      const totalMath = _.sumBy(this.assessments, assessment => assessment.studentsAssessed * assessment.math)
      const totalScience = _.sumBy(this.assessments, assessment => assessment.studentsAssessed * assessment.science)

      const studentsAssessedPercent = totalStudentsAssessed/totalStudents * 100
      const averageComposite = (totalComposite / totalStudentsAssessed).toFixed(1)
      const averageEnglish = (totalEnglish/totalStudentsAssessed).toFixed(1)
      const averageMath = (totalMath / totalStudentsAssessed).toFixed(1)
      const averageReading = (totalReading/totalStudentsAssessed).toFixed(1)
      const averageScience = (totalScience/totalStudentsAssessed).toFixed(1)

      return {
        schedule: 'Entire School',
        scheduleId: 0,
        assessmentId: 0,
        students: totalStudents,
        studentsAssessed: totalStudentsAssessed,
        studentsAssessedPercent: studentsAssessedPercent,
        composite: averageComposite,
        english: averageEnglish,
        reading: averageReading,
        math: averageMath,
        science: averageScience
      }
    },
    assessmentRows () {
      return [...this.assessments, this.entireSchoolAssessment]
    }
  },
  methods: {
    tableRowClass (entry) {
      return [
        { 'active-schedule': entry.scheduleId == this.activeSchedule.id },
        'text-dk-gray'
      ]
    },
    scoreReportPageLink(entry) {
      const params = {
        assessmentId: this.practiceTestSetId,
        scheduleId: entry.scheduleId
      }
      if (this.isHorizonAdmin) {
        return {
          name: 'AdminStudentScoreReport',
          params: params
        }
      } else {
        return {
          name: 'StaffStudentScoreReport',
          params: params
        }
      }
    }
  }

}
</script>

<style scoped lang="scss">
@import '~@styles/variables.scss';
@import '~@styles/staff/shared/assessment-results.scss';

td.schedule {
  min-width: 160px;
  width: 160px;
}

.table-full {
  display: table;
}

.table-mini {
  display: none;
  width: 100%;
  border: 0;
  padding: 0;
  margin: 0;

  td {
    padding: 0;
  }

  .table-mini-entry {
    border-top: 0;
    border-bottom: 0;

    th {
      border-radius: 0px;
    }

    td {
      border-top: none;
    }

    td, th {
      text-align: center;
    }

    .table-mini-label {
      width: 50%;
      border-right: 1px solid #D3D7DA;
    }

    .table-mini-value {
      width: 50%;
    }

    .schedule-button {
      width: 150px;
    }
  }
}

@media screen and (max-width: $mobileScreenExtraLarge) {
  .table-full {
    display: none;
  }

  .table-mini {
    display: table;
  }
}
</style>
