import client from '@repositories/client.js'

export default class {
  constructor (schoolId) {
    this.schoolId = schoolId
  }

  getBaseUrl() {
    return `/api/admin/schools/${this.schoolId}/instructors`
  }

  async list () {
    const url  = this.getBaseUrl()
    const data = await client.get(url)
    return data.users
  }

  async create (instructor) {
    const requestFields = [
      'firstName',
      'lastName',
      'email',
      'role'
    ]
    const body = _.pick(instructor, requestFields)
    body.schoolId = this.schoolId
    const url  = this.getBaseUrl()
    const data = await client.post(url, { instructor: body })
    return data.instructor
  }

  async massImport (file) {
    file.schoolId = this.schoolId
    const data = await client.post(`/api/admin/staff_imports`, { import: file }, { emulateJSON: true })
    return data.import
  }

}
