import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'
import Axios from 'axios'
import createPersistedState from 'vuex-persistedstate'
// import { ROUTES } from '@utils/routes'

Vue.use(Vuex)

function initialState () {
  return {
    settings: {},
    user: {},
    schoolYear: '2018-2019',
    permissions: {},
    userAuthenticated: false,
    userActiveCourse: '',
    notificationMessages: {
      successes: [],
      errors: []
    },
    showCourseActivities: true,
    hubspotToken: null
  }
}

const store = new Vuex.Store({
  state: initialState(),
  plugins: [createPersistedState()],
  getters: {
    userAuthenticated: (state) => state.userAuthenticated,
    permissionCheck: (state) => (permName) => {
      if (state.permissions == '' || state.permissions == {} || state.permissions == undefined || state.permissions == null) { return false }
      const foundPermission = state.permissions.find((perm) => perm.name.toLowerCase().replace(/ +/g, '-') == permName // some-permission-name
      )
      return foundPermission !== undefined
    },
    userFullName: (state) => {
      if (state.user == {}) { return '' }
      return [state.user.firstName, state.user.lastName].join(' ')
    },
    userEmail: (state) => state.user.email,
    userRole: (state) => state.user.role,
    userSchool: (state) => state.user.school,
    userSchedules: (state) => state.user.schedules,
    activeCourse: (state) => state.userActiveCourse,
    activeSchoolYear: (state) => state.schoolYear,
    notificationErrors: state => state.notificationMessages.errors,
    notificationSuccesses: state => state.notificationMessages.successes,
    userMasqId: (state) => {
      if (state.user.hasOwnProperty('masqId')) {
        return state.user.masqId
      }
      return null
    },
    userMasqIdent: (state) => {
      if (state.user.hasOwnProperty('masqIdent')) {
        return state.user.masqIdent
      }
      return false
    },
    userDistrictMasq: (state) => {
      if (state.user.hasOwnProperty('masqIdent')) {
        return state.user.districtAdminMasq
      }
      return false
    },
    userPermit: (state) => (permissible) => {

      // for now, no permission check for certain roles
      let permiPass = ['Horizon Admin', 'Student', 'Parent' ]
      if (permiPass.includes(state.user.role)) {
        return true
      }

      let controller = state.user.permissibles.find(e => e.name === permissible.controller)
      if (controller) {
        if (permissible.action) {
          return controller.permissions[permissible.action]
        }
        return true
      } else {
        return false
      }
    },
    showCourseActivities: (state) => {
      return state.showCourseActivities
    },

    hubspotToken: (state) => {
      return state.hubspotToken 
    }
  },
  mutations: {
    resetState (state) {
      // acquire initial state
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
    updateSessionInfo: (state, payload) => {
      state.user = payload
      state.permissions = state.user.permissions
      delete state.user.permissions

      state.userAuthenticated = payload.userId != undefined
    },
    updateSchoolYear: (state, payload) => {
      state.schoolYear = payload
    },
    updateActiveCourse: (state,payload) => {
      state.userActiveCourse = payload
    },
    updateShowCourseActivities: (state, payload) => {
      state.showCourseActivities = payload
    },
    updateHubspotToken: (state, payload) => {
      state.hubspotToken = payload
    },
    ADD_NOTIFICATION_ERRORS: (state, payload) => {
      state.notificationMessages.errors.push(payload)
    },
    ADD_NOTIFICATION_SUCCESSES: (state, payload) => {
      state.notificationMessages.successes.push(payload)
    },
    REMOVE_NOTIFICATION_ERROR_BY_INDX: (state, payload) => {
      state.notificationMessages.errors.splice(payload, 1)
    },
    REMOVE_NOTIFICATION_SUCCESS_BY_INDX: (state, payload) => {
      state.notificationMessages.successes.splice(payload, 1)
    },
    RESET_NOTIFICATION_MESSAGES: state => {
      state.notificationMessages.errors = []
      state.notificationMessages.successes = []
    }
  },
  actions: {
    resetNotificationMessages: ({ commit }) => {
      commit('RESET_NOTIFICATION_MESSAGES')
    },
    spliceNotificationError: ({ commit }, payload) => {
      commit('REMOVE_NOTIFICATION_ERROR_BY_INDX', payload)
    },
    spliceNotificationSuccess: ({ commit }, payload) => {
      commit('REMOVE_NOTIFICATION_SUCCESS_BY_INDX', payload)
    },
    setNotificationErrors: ({ commit }, payload) => {
      commit('ADD_NOTIFICATION_ERRORS', payload)
      setTimeout(() => {
        commit('REMOVE_NOTIFICATION_ERROR_BY_INDX', 0)
      }, 7000)
    },
    setNotificationSuccesses: ({ commit }, payload) => {
      commit('ADD_NOTIFICATION_SUCCESSES', payload)
      setTimeout(() => {
        commit('REMOVE_NOTIFICATION_SUCCESS_BY_INDX', 0)
      }, 7000)
    },
    loadUserSession: ({ commit }) => new Promise((resolve, reject) => {
      Axios.get('/api/me').then(response => {
        commit('updateSessionInfo', response.data)
        resolve(response.data)
      }).catch(error => {
        reject(error)
        console.log(error.response.data.error)
      })
    }),
    logout: ({ commit }) => Axios.delete('/api/logout').then(() => {
      commit('updateSessionInfo', {})
      commit('resetState')
      commit('RESET_NOTIFICATION_MESSAGES')
    }).catch(error => {
      console.log(error.response.data.error)
    }),
    changeSchoolYear: ({ commit }, yearString) => {
      // commit('updateSchoolYear', yearString)
      console.log(commit)
      console.log(yearString)
    },
    setActiveCourse: ({ commit }, activeCourse) => {
      commit('updateActiveCourse', activeCourse)
    },
    setShowCourseActivitiesLink: ({ commit }, showCourseActivities) => {
      commit('updateShowCourseActivities', showCourseActivities)
    },
    setHubspotToken: ({ commit }, hubspotToken) => {
      commit('updateHubspotToken', hubspotToken)
    }
  }
})

export default store
