<template>
  <div class="lesson-pane" :id="id">
    <div class="accordion-trigger flex-md-row">
      <div class="left-pane" @click.prevent="triggerSelect">
        <rotating-button :isActive="selected">
        </rotating-button>
        <div class="lesson-text">
          <h1>{{ lesson.name }}</h1>
          <h3>{{ lesson.memo }}</h3>
        </div>
      </div>
      <div class="right-pane">
        <h2 v-if="isLocked()">
          <img src="/images/lock.png">
          Lesson Locked
        </h2>
        <h2 v-else-if="activitiesCount() > 0">
          <strong>{{ activitiesCompleted() }}/{{ activitiesCount() }}</strong>
          Activities Complete
        </h2>
      </div>
    </div>
    <div class="lessons" :hidden="!selected">

      <text-detail
        v-if="hasTimeDetail()"
        :independent-practice-time="lesson.independent_practice_time"
        :instructional-time="lesson.instructional_time"
        :id="id+'-text-detail'"
        >
      </text-detail>

      <text-button-detail
        v-if="hasIndependentLessonActivity()"
        :id="id+'-independent-lesson'"
        :title="'Independent Lesson'"
        :locked="isLocked()"
        :completed=independentLessonComplete()
        :percentCorrect=independentLessonPercentCorrect()
        :reviewQuestionsFn="showIndependentLessonReviewModal"
        :launchActivityFn="launchIndependentLesson"
        >
      </text-button-detail>

      <text-button-detail
        v-if="hasPracticeSetActivity()"
        :id="id+'-practice-set'"
        :title="'Practice Set'"
        :locked="isLocked()"
        :completed=practiceSetComplete()
        :percentCorrect=practiceSetPercentCorrect()
        :reviewQuestionsFn="showPracticeSetReviewModal"
        :launchActivityFn="launchPracticeSet"
        >
      </text-button-detail>

      <div v-if="selected">
        <div v-for="(material, propName) in lesson.support_materials">
          <text-media-detail
            v-if="material.hasOwnProperty('url') && material.url"
            :id="propName+'-media-detail'"
            :main_text="material.name"
            :media_src="material.url"
            >
          </text-media-detail>
        </div>
      </div>

    </div>

    <activity-progress-modal
      :id="id+'-activity-progress-modal'"
      :reportInitConfig="modal_report_init_config"
      :reportHtmlId="modal_report_html_tag"
      @modal-closed="activityProgressModalClosed"
    />
  </div>
</template>
<script>

import RotatingButton from '@shared/rotating_button.vue'
import TextDetail from './details/text_detail.vue'
import TextButtonDetail from './details/text_button_detail.vue'
import TextMediaDetail from './details/text_media_detail.vue'
import ActivityProgressModal from './activity_progress_modal.vue'

export default {
  components: {
    RotatingButton,
    TextDetail,
    TextButtonDetail,
    TextMediaDetail,
    ActivityProgressModal
  },
  props: {
    id: {
      type: String,
      default: '',
      required: true,
    },
    lesson: {
      type: Object,
      default() { return {} }
    },
    docVisibility: {
      type: String,
      default: '',
    },
    sectionSelected: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      selected: false,
      responses: {},
      pending_responses: false,
      modal_report_init_config: {},
      modal_report_html_tag: '',
    }
  },
  watch: {
    docVisibility: {
      immediate: true,
      handler(val, oldVal) {
        if (val == 'visible' && val != oldVal) {
          if (this.pending_responses) { this.getResponses() }
        }
      }
    },
    sectionSelected: {
      immediate: true,
      handler(val, oldVal) {
        if (val == true && val != oldVal && this.activitiesCompleted() < this.activitiesCount()) {
          this.getResponses()
        }
      }
    }
  },
  created () {
    this.getResponses()
  },
  methods: {
    triggerSelect() {
      this.selected = this.selected == true ? false : true
    },
    getResponseReviewInitConfig(lesson_id, activity_id) {
      this.$http.get(`/api/student/course_activities/review_questions/${lesson_id}/${activity_id}`).then((res) => {
        let config = JSON.parse(res.data.response)
        this.modal_report_html_tag = config.request.reports[0].id
        this.modal_report_init_config = config
      }, (responseError) => {
        this.appendError({error: responseError, notification: true})
      })
    },
    showIndependentLessonReviewModal() {
      this.getResponseReviewInitConfig(this.lesson.id,
        this.lesson.learnosity_activity_banks.independent_lesson)
      $('#' + this.id + '-activity-progress-modal').modal('show')
    },
    showPracticeSetReviewModal() {
      this.getResponseReviewInitConfig(this.lesson.id,
        this.lesson.learnosity_activity_banks.practice_set)
      $('#' + this.id + '-activity-progress-modal').modal('show')
    },
    activityProgressModalClosed() {
      this.modal_report_html_tag = ''
      this.modal_report_init_config = {}
    },
    activitiesCount() {
      let count = 0
      if (this.lesson.learnosity_activity_banks.hasOwnProperty('independent_lesson')
        && this.lesson.learnosity_activity_banks.independent_lesson) { count += 1 }
      if (this.lesson.learnosity_activity_banks.hasOwnProperty('practice_set')
        && this.lesson.learnosity_activity_banks.practice_set) { count += 1 }
      return count
    },
    activitiesCompleted() {
      let count = 0
      if (this.responses.hasOwnProperty('independent_lesson')
        && this.responses.independent_lesson.status == 'Completed') { count += 1 }
      if (this.responses.hasOwnProperty('practice_set')
        && this.responses.practice_set.status == 'Completed') { count += 1 }
      return count
    },
    independentLessonComplete() {
      if (this.responses.hasOwnProperty('independent_lesson')) {
        return (this.responses.independent_lesson.status == 'Completed')
      }
      return false
    },
    independentLessonPercentCorrect() {
      if (this.responses.hasOwnProperty('independent_lesson')) {
        if (this.responses.independent_lesson.max_score == 0 && this.responses.independent_lesson.status == 'Completed') {
          return 100
        }
        if (this.responses.independent_lesson.score > 0) {
          return Math.round((this.responses.independent_lesson.score/this.responses.independent_lesson.max_score) * 100)
        }
      }
      return 0
    },
    practiceSetComplete() {
      if (this.responses.hasOwnProperty('practice_set')) {
        return (this.responses.practice_set.status == 'Completed')
      }
      return false
    },
    practiceSetPercentCorrect() {
      if (this.responses.hasOwnProperty('practice_set')) {
        if (this.responses.practice_set.max_score == 0 && this.responses.practice_set.status == 'Completed') {
          return 100
        }
        if (this.responses.practice_set.score > 0) {
          return Math.round((this.responses.practice_set.score/this.responses.practice_set.max_score) * 100)
        }
      }
      return 0
    },
    launchIndependentLesson() {
      this.pending_responses = true
      window.open(`/course_activities/activity/${this.lesson.id}/${this.lesson.learnosity_activity_banks.independent_lesson}`)
    },
    launchPracticeSet() {
      this.pending_responses = true
      window.open(`/course_activities/activity/${this.lesson.id}/${this.lesson.learnosity_activity_banks.practice_set}`)
    },
    isLocked() {
      return this.lesson.is_locked == true || this.lesson.is_locked == null
    },
    getResponses() {
      if (!this.isLocked()) {
        this.$http.get(`/api/student/course_activities/learnosity_responses/${this.lesson.id}`).then((res) => {
          this.responses = res.data.responses
          this.pending_responses = false
        }, (responseError) => {
          this.appendError({error: responseError, notification: true})
        })
      }
    },
    hasPracticeSetActivity() {
      return !!this.lesson.learnosity_activity_banks.practice_set
    },
    hasIndependentLessonActivity() {
      return !!this.lesson.learnosity_activity_banks.independent_lesson
    },
    hasTimeDetail() {
      return this.lesson.independent_practice_time || this.lesson.instructional_time
    }
  }
}
</script>
<style scoped lang="scss">
@import '~@styles/variables';
.lessons {
  width: 100%;
  margin-top: 9px;
  padding: 15px 65px 20px 65px;
  border-top: 1px solid #d8d8d8;
}

.accordion-trigger {
  display: flex;
  flex-direction: column;
  min-height: 70px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  .left-pane {
    display: flex;
    width: 100%;
    align-items: center;
    .lesson-text {
      margin-left: 15px;
    }
  }
  .right-pane {
    h2 {
      width: max-content;
      font-size: 18px;
      font-weight: 400;
      strong {
        color: #f4701f;
        font-weight: 700;
      }
      img {
        width: 20px;
        margin-right: 5px;
      }
    }
  }
}
.lesson-pane {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  cursor: auto;
  border-color: #c7c7c7;
  border-style: solid;
  border-width: 1px;
  border-radius: 14px;
  padding: 15px 25px 15px 25px;
  margin: 10px 0px 10px 0px;
  background-color: #fff;
  h1 {
    font-size: 18px;
    font-weight: 700;
  }
  h3 {
    font-size: 16px;
    font-weight: 400;
  }
}

// Css xs => md
@media screen and (max-width: 769px) {
  .lessons {
    padding: 15px 20px 20px 20px;
  }
}
</style>
