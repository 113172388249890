const SAT = 'SAT'
const ACT = 'ACT'
const PSAT89 = 'PSAT89'
const PSAT10 = 'PSAT10'

export default {

  SAT,
  ACT,
  PSAT89,
  PSAT10,

  values () {
    return [ SAT, ACT, PSAT89, PSAT10 ]
  }

}
