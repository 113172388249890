(function () {
  function installClient() {
    var apiUrl = 'https://apis.google.com/js/api:client.js'
    return new Promise((resolve) => {
      var script = document.createElement('script')
      script.src = apiUrl
      script.onreadystatechange = script.onload = function() {
        if (!script.readyState || /loaded|compvare/.test(script.readyState)) {
          setTimeout(function() {
            resolve()
          }, 500)
        }
      }
      document.getElementsByTagName('head')[0].appendChild(script)
    })
  }

  function install (Vue) {
    Vue.component('google-signin', {
      name: 'google-signin',
      render (createElement) {
        return createElement('div', {
          attrs: {
            class: 'google-signin'
          },
          ref: 'signinBtn'
        }, this.$slots.default)
      },
      props: {
        params: {
          type: Object,
          required: true,
          default () {
            return {}
          }
        }
      },
      mounted () {
        installClient()
          .then(() => {
            window.gapi.load('auth2', () => {
              const auth2 = window.gapi.auth2.init(this.params)
              auth2.attachClickHandler(this.$refs.signinBtn, {}, googleUser => {
                this.$emit('success', googleUser)
              }, error => {
                this.$emit('error', error)
                this.$emit('failure', error)
              })
            })
          })
      }
    })
  }

  if (typeof exports === 'object') {
    module.exports = install
  } else if (typeof define === 'function' && define.amd) {
    define([], function () { return install })
  } else if (window.Vue) {
    window.Vue.use(install)
  }
})()