<template>
  <div>
    <div class="bg-primary card-header no-border-radius d-flex justify-content-between">
      <h1 class="text-white">
        Section Scores
      </h1>
    </div>
    <div v-if="isLoading"
      class="card-body">
      <div class="row">
        <div class="col-12">
          <common-loading/>
        </div>
      </div>
    </div>
    <div v-else
      class="card-body">
      <div v-for="(testSection,idx) in testSections" :key="idx">
        <div v-if="notComposite(testSection)" class="row card-body-info">
          <div class="col-8">
            <h2 class="font-18 fw-700">
              {{ testSection.name }} Score
            </h2>
            <div class="body-larger">
              Benchmark Score: {{ testSection.benchmarkScore }}
            </div>
          </div>
        </div>
        <div v-if="notComposite(testSection)" class="mt-4 row test-section-padding card-body-charts">
          <div class="col-4 donut-chart-dashboard">
            <donut-chart
              :chart-id="(`section-${testSection.id}-donut-chart`)"
              :average-score="testSection.averageScore"
              :min-score="testSection.minScore"
              :max-score="testSection.maxScore"
              :color="setColor(testSection)"/>
          </div>
          <div class="col-8 bar-chart-dashboard">
            <bar-chart
              :include-benchmark="true"
              :chart-id="(`section-${testSection.id}-bar-chart`)"
              :data-set="testSection.segments"
              :benchmark-placement="testSection.benchmarkPlacement"
              :color="setColor(testSection)"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from '@shared/charts/bar_chart.vue'
import DonutChart from '@shared/charts/donut_chart.vue'
import CommonLoading from '@shared/common_loading'

export default {
  components: {
    BarChart,
    DonutChart,
    CommonLoading
  },
  props: {
    testSections: {},
    isLoading: {
      type: Boolean,
      required: true
    },
  },
  data () {
    return {}
  },
  created () {
  },
  methods: {
    //TODO: Temp solution
    // Have a matching TestSection :reading_writing key  to match StudentPracticeTests[:scores] that usues :reading_writing as key
    // But we have to render the correct name here
     notComposite: function(section) {
      if (section.name != "Composite") { return true }
    },
    setColor: function(section) {
      let greenCharts = ["Evidence-Based Reading and Writing","English", "Reading"]
      let blueCharts  = ["Math", "Science"]

      if (greenCharts.includes(section.name)) { return "#32c443" }
      if (blueCharts.includes(section.name))  { return "#6199d4" }
    }
  },
  computed: {

  }
}
</script>

<style scoped lang="scss">
@import '~@styles/variables';

.test-section-padding {
  padding-bottom: 20px;
}

.bar-chart-dashboard {
  height: auto;
  padding-left: 0px;
  padding-right: 0px;

}

.donut-chart-dashboard {
  height: auto;
  padding-bottom: 20px;
}

@media screen and (max-width: $mobileScreenMedium) {
  .card-header {
    h1 {
      width: 100%;
      text-align: center;
      font-size: 18px;
    }
  }

  .card-body {
    padding-top: 25px;

    .card-body-info {
      .col-8 {
        flex: 100%;
        width: 100%;
        max-width: 100%;
        text-align: center;
      }
    }

    .card-body-charts {
      padding-bottom: 0px;
      display: block;

      .col-4, .col-8 {
        width: 100%;
        max-width: 100%;
      }

      .bar-chart-dashboard {
        padding-right: 30px;
      }
    }
  }
}
</style>
