<template>
    <horizon-modal
        id="duplicate-course-modal"
        modal-title="Duplicate Course"
    >
        <template v-slot:content>
            <div class="form" role="form">
                <div class="horizon-form-row w-100">
                    <div class="col-5 text-uppercase">
                        <label for="course-name">Course Name</label>
                    </div>
                    <div class="col-7">
                        <input id="course-name" v-model="course.name" type="text">
                    </div>
                </div>
            </div>
            <div class="col-12 d-flex justify-content-end pt-2">
                <button class="btn btn-primary" @click="duplicateCourse">
                    Duplicate
                </button>
            </div>
        </template>
    </horizon-modal>
</template>
<script>
import HorizonModal from '@shared/horizon_modal'
import { required } from 'vuelidate/lib/validators'

export default {
    components: {
        HorizonModal
    },
    data () {
        return {
            course: {
                name: null,
            }
        }
    },
    validations: {
        course: {
            name: { required }
        },
    },
    methods: {
        duplicateCourse () {
            this.$emit('setDuplicate',this.course.name)
            $('#duplicate-course-modal').modal('hide')
            this.course.name = ''
        },
    },
}
</script>
