<template>
  <div class="container">
    <div class="content-pane">
      <div class="pane-wrapper">
        <breadcrumbs/>
        <div class="pt-2 row">
          <div class="col">
            <results-list/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@shared/breadcrumbs'
import ResultsList from './list'

export default {
  components: {
    Breadcrumbs,
    ResultsList
  },
  data () {
    return {
      breadcrumbs: []
    }
  }
}
</script>

<style scoped lang="scss">

</style>
