<template>
  <div class="gradebook-table-container">
    <table class="table table-primary">
      <thead>
        <tr class="table-header-row-0">
          <th :colspan=3 class="table-header-spacer table-col-sticky">
          </th>
          <th v-for="section in tableHeaders.sections" :colspan="section.span">
            {{ section.title }}
          </th>
        </tr>
        <tr class="table-header-row-1">
          <th :colspan=3 class="table-header-spacer table-col-sticky">
          </th>
          <th v-for="lesson in tableHeaders.lessons" :colspan="lesson.span">
            {{ lesson.title }}
          </th>
        </tr>
        <tr class="table-header-row-2">
          <th v-for="(title, idx) in tableHeaders.summary" class="table-col-sticky" :class="'table-col-'+idx">
            {{ title }}
          </th>
          <th v-for="(activity, idx) in tableHeaders.activities" :class="'table-col-'+(parseInt(idx)+3)">
            {{ snakeToHuman(activity) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(data, idx) in tableData" :class="'table-data-row-'+idx">
          <td class="table-col-0 table-col-sticky">
            {{ data.student.name }}
          </td>
          <td class="table-col-1 table-col-sticky">
            {{ data.summary.activities_completed }}
          </td>
          <td class="table-col-2 table-col-sticky">
            {{ sumScorePercentAverage(data.summary.sum_score_percentage, data.summary.activities_completed) }}
          </td>
          <td v-for="(response, idx) in data.responses" :class="'table-col-'+(parseInt(idx)+3)">
            {{ responseShow(response) }}
            <span v-if="hasResponse(response)" class="fa fa-trash text-danger cursor-hand" @click="confirmDeleteResponse(response)"></span>
          </td>
        </tr>
      </tbody>
    </table>
    <remove-response-modal @delete-approval="responseDelete"/>
  </div>
</template>
<script>
import RemoveResponseModal from './remove_response_modal'
export default {
  components: {
    RemoveResponseModal
  },
  props: {
    gradebookTableData: {
      type: Object,
      default() { return {} }
    }
  },
  data() {
    return {
      courseId: 0,
      tableHeaders: [],
      tableData: [],
      responseToDelete: {},
    }
  },
  methods: {
    responseDelete(delete_approval) {
      if (delete_approval === true) {
        this.$http.delete(`/api/staff/course_activities/${this.courseId}/gradebook/response/${this.responseToDelete.he_course_student_response_id}`).then(() => {
          this.appendSuccess({message: 'Student Response Deleted', notification: true})
        }, (responseError) => {
          this.appendError({error: responseError, notification: true})
        }).then(() => {
          this.$emit('table-update')
        })
      }
      this.responseToDelete = {}
    },
    confirmDeleteResponse(response) {
      this.responseToDelete = response
      $('#gradebook-response-delete-modal').modal('show')
    },
    hasResponse(response) {
      return (response.he_course_student_response_id && response.status == 'Completed')
    },
    responseShow(response) {
      let show = '-'
      if (response.he_course_student_response_id && response.status == 'Completed') {
        if (response.max_score > 0) {
          show = Math.round(response.score_percentage) + '%'
        } else {
          show = "100%"
        }
      }
      return show
    },
    sumScorePercentAverage(sum_score_percent, activities_completed) {
      if (activities_completed == 0) return '0%'
      return String(Math.round(sum_score_percent / activities_completed)) + '%'
    },
    snakeToHuman(str) {
      let pcs = str.split('_')
      pcs = pcs.map(pc => pc.charAt(0).toUpperCase() + pc.substring(1))

      return pcs.join(' ')
    },
  },
  mounted () {
    this.courseId = parseInt(this.$route.query.course_id)
    this.tableHeaders = this.gradebookTableData.table_headers
    this.tableData = this.gradebookTableData.table_data
  }
}
</script>
<style scoped lang="scss">
  .table {
    border-collapse: separate;
    border-spacing: 0;
    border: none;
    th, td {
      border: none;
    }
  }
  .gradebook-table-container {
    position: relative;
    overflow: auto;
  }
  .table-header-spacer {
    min-width:400px;
    max-width:400px;
    width:400px;
    left:0px;
  }
  .table-col-0 {
    min-width:200px;
    max-width:200px;
    width:200px;
    left:0px;
  }
  .table-col-1 {
    min-width:110px;
    max-width:110px;
    width:110px;
    left:200px;
  }
  .table-col-2 {
    min-width:110px;
    max-width:110px;
    width:110px;
    left:310px;
  }
  .table-col-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
  .table-header-spacer {
    border: none;
    background-color: white;
  }
  .table-header-row-0 {
    .table-header-spacer {
      border-right: 2px solid black;
    }
    th:not(.table-header-spacer) {
      font-size: 20px;
      border-right: 1px solid #696969;
      border-top: 1px solid black;
      border-bottom: 1px solid black;
    }
  }
  .table-header-row-1 {
    background-color: #bbb;
    color: black;
    .table-header-spacer {
      border-bottom: 1px solid black;
      border-right: 2px solid black;
    }
    th:not(.table-header-spacer) {
      font-size: 14px;
      font-weight: 600;
      vertical-align: middle;
      border-right: 1px solid #696969;
      border-bottom: 1px solid black;
    }
  }
  .table-header-row-2 {
    background-color: #e9e9e9;
    color: black;
    .table-col-sticky.table-col-2 {
      border-right: 2px solid black;
    }
    .table-col-sticky {
      font-weight: 600;
      background-color: #e9e9e9;
      color: black;
    }
    th {
      min-width:110px;
      max-width:110px;
      width:110px;
      font-weight: 400;
      vertical-align: middle;
      font-size: 14px;
      padding: 10px 5px 10px 5px;
      border-right: 1px solid #696969;
      border-bottom: 2px solid black;
    }
  }
  tr[class^='table-data-row-'] {
    background-color: white;
    color: black;
    span {
      font-size: 10px;
    }
    td {
      height: 30px;
      padding: 5px 10px 5px 10px;
      vertical-align: middle;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      border-right: 1px solid #696969;
      border-bottom: 1px solid #696969;
    }
    .table-col-sticky.table-col-2 {
      border-right: 2px solid black;
    }
    .table-col-sticky.table-col-0 {
      text-align: right;
      color: #345165;
    }
    .table-col-sticky {
      background-color: white;
      color: black;
      font-weight: 600;
    }
  }
</style>
