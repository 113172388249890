<template>
  <div class="container">
    <div class="dashboard-course-type">
      <div class="dashboard-score-student">
        <div class="dashboard-student-heading course-schedule">
          <h2 class="heading-student-name">
            Select Course Schedule Below
          </h2>
        </div>
        <div v-for="(courseSchedule, courseScheduleIndex) in courseSchedules" :key="courseScheduleIndex" :id="courseSchedule.id" @click="checkCourseSchedule(courseSchedule)" class="course-type-button w-inline-block">
          <div class="div-block-61">
            <h2 class="score-rep-title">{{ courseSchedule.name }}</h2>
            <div class="text-block-7"> Schedule Type: {{ courseSchedule.courseType }}</div>
          </div>
          <img src="/images/course_type_right-arrow.png" width="37" alt class="image">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RepositoryFactory from '@repositories/factory'
import { mapGetters, mapActions } from "vuex"
const AssessmentResultRepository = RepositoryFactory.student.get('assessment_result')

export default {
  components: {
  },
  data () {
    return {
      userType: 'student',
      assessmentData: [],
      courseProgressProvider: {},
      courseSchedules: [],
      state: this.state,
    }
  },
  async created () {
    this.checkStudentCourses(); 
  },
  methods: {
    ...mapActions(["setActiveCourse", "setShowCourseActivitiesLink"]),
    ...mapGetters(["userSchedules","activeCourse"] ),
    checkStudentCourses () {
      this.courseSchedules = this.userSchedules()
      //TODO: HE-744 checek student courses. If multiple route to StudentCourse else StudentDashboard
    },
    checkCourseSchedule (courseSchedule) {
      this.setActiveCourse(courseSchedule)
      this.setShowCourseActivitiesLink(courseSchedule.hasAttachedCourseTemplate)
      this.$router.push({ name: "StudentDashboard" })
    }
  },
  // watch for changes from the API
  computed: {
    courses() {
      return this.userSchedules()
    }
  },
  watch: {
    courses (newCourses, oldCourses) {
      this.courseSchedules = newCourses
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@styles/variables.scss';


.join-class-btn {
  background-color: #f4701f;
  border: none;
  color: black;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 20px;
  cursor: pointer;
  border-radius: 30px;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-weight: 700;

}

.zoom-banner-text > h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 32px;
  font-weight: 700;
  color: #ffffff ;
}

.zoom-image > .img-thumbnail {
  background-color: #345165;
  border: 1px solid #345165;
}

.assessment-results-wrapper {
  padding: 10px 15px 18px;
}

.dashboard-course-type {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.dashboard-score-student {
  width: 100%;
  height: auto;
  min-height: 770px;
  padding: 10px 15px 18px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 1px 3px 3px #e7e7e7;
}

.div-block-61 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.score-rep-title {
  margin-top: 0px;
  margin-bottom: 4px;
  font-size: 24px;
  font-weight: 700;
}

.text-block-7 {
  color: #333;
}

.image {
  margin-left: 7px;
}

.heading-student-name {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #345165;
  font-size: 32px;
  font-weight: 700;
}

.dashboard-student-heading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  padding: 10px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
}

.dashboard-student-heading.course-schedule {
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-bottom-color: #dbdbdb;
}

.course-type-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  margin-top: 15px;
  margin-bottom: 20px;
  padding: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #dbdbdb;
  border-radius: 10px;
  background-color: #fff;
  text-decoration: none;
}

.course-type-button:hover {
  border-color: #9b9b9b;
}


.image-student-head {
  width: 86px;
  height: auto;
  margin-top: 0px;
  margin-right: 17px;
  margin-bottom: 0px;
}

.col-9 {
  padding-right: 7.5px;
}

.col-3 {
  padding-left: 7.5px;
}

@media screen and (max-width: $mobileScreenLarge) {
  h2 {
    font-size: 28px !important;
  }

  .col-md-3 {
    padding-right: 0px;
    padding-left: 0px;
  }
}

@media screen and (max-width: $mobileScreenMedium) {
  .col-md-3 {
    margin-top: 15px;
    padding-right: 15px;
    padding-left: 15px;
  }

  h2 {
    font-size: 23px !important;
  }
}

@media screen and (max-width: $mobileScreenSmall) {
  .col-md-9 {
    padding: 0px;
  }

  .col-md-3 {
    padding: 0px;
    margin-top: 15px;
    margin-bottom: 0px;
  }

  .h-col-mb {
    margin-bottom: 0px;
  }


  .course-resources-pane {
    margin-bottom: 15px;
  }

  .student-faq-pane {
    margin-bottom: 15px;
  }

  h2 {
    font-size: 18px !important;
  }
}

@media screen and (max-width: $mobileScreenExtraSmall) {
  h2 {
    font-size: 14px !important;
  }
}
</style>
