<template>
    <div v-if="validUrl(this.zoomLinkUrl)" class="zoom-url mb-4 d-flex rounded bg-primary p-3 flex-wrap align-items-center justify-content-center justify-content-md-between" >
      <a class="zoom-image col-md-3"><img src="/images/60946c548714498ab9017ec5_zoom.png" alt="/images/zoom_meetings.png" class="img-thumbnail p-3"></a>
      <a class="zoom-banner-text text-white col-12 col-md-auto mb-5 justify-content-center mb-md-0"><h2>Zoom Class Link</h2></a>
      <a class="zoom-button-link align-items-center col-md-3 text-end"><button type="button" class="join-class-btn btn btn-primary btn-lg text-white p-3" aria-pressed="true" @click="zoomClass">Join Class</button></a>
    
    </div>
</template>
<script>
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
export default {
  components: {VueCal},
  props: {
    userType: { type: String, required: true },
  },
  data () {
    return {
      calendarData: this.calendarData
    }
  },
  async created () {
    this.initData()
  },
  methods: {
    initData () {
      this.$http.get(`/api/${this.userType}/schedules/calendar_data`).then((response) => {
        this.calendarData = response.data.calendarData
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    },
    validUrl (url) {
      const regex = RegExp('(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+@]*)*(\\?[;&a-z\\d%_.~+=-@]*)?(\\#[-a-z\\d_@]*)?$', 'i');
      return url == '' ? url.match(regex) : false
    },
    zoomClass(){
      const url = this.zoomLinkUrl
      if (url.indexOf("http://") == 0 || url.indexOf("https://") == 0) {
          window.open(url,'_blank')
      } else {
          window.open('http://' + url,'_blank')
      }
    },
    zoomLinkUrl(){
      return this.calendarData.zoomUrl ? this.calendarData.zoomUrl : ''
    },
  },
}
</script>

<style scoped lang="scss">
@import '~@styles/variables.scss';


.join-class-btn {
  background-color: #f4701f;
  border: none;
  color: black;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 20px;
  cursor: pointer;
  border-radius: 30px;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-weight: 700;

}

.zoom-banner-text > h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 32px;
  font-weight: 700;
  color: #ffffff ;
}

.zoom-image > .img-thumbnail {
  background-color: #345165;
  border: 1px solid #345165;
  pointer-events: none;
}

</style>