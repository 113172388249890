<template>
  <table class="table table-primary w-100">
    <thead>
      <tr>
        <th class="flex-fill h2 text-align-left text-white">
          Parent Night
        </th>
        <th class="h2 text-align-left text-white">
          Date
        </th>
        <th class="h2 text-align-center text-white">
          Start Time
        </th>
        <th class="h2 text-align-center text-white">
          End Time
        </th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(parentNightDate, idx) in parentNightDates"
        :key="idx">
        <td class="fw-600">
          Parent Night #{{ idx + 1 }}
          </td>
          <td class="fw-400">
            <date-picker
              v-model="parentNightDate.meetingOn"
              :config="datePickerConfig"
              :class="['parent-night-meeting-on', 'horizon-input', 'text-left', { 'highlighter-red': errors[idx].meetingOn.$error }]"/>
          </td>
          <td class="fw-400 text-align-center">
            <date-picker
              v-model="parentNightDate.startAt"
              :config="timePickerConfig"
              :class="['parent-night-start-at', 'horizon-input', 'text-center', { 'highlighter-red': errors[idx].startAt.$error }]"/>
          </td>
          <td class="fw-400 text-align-center">
            <date-picker
              v-model="parentNightDate.endAt"
              :config="timePickerConfig"
              :class="['parent-night-end-at', 'horizon-input', 'text-center', { 'highlighter-red': errors[idx].endAt.$error }]"/>
          </td>
          <td>
            <button
              type="button"
              class="btn btn-lg btn-outline-primary"
              @click="removeScheduleAt(idx)">
              <i class="fa fa-2x fa-trash"/>
            </button>
          </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <th class="text-right" colspan="5">
          <button
            class="btn btn-outline-secondary gray-hover"
            @click="appendNewParentNightDate">
            <i class="fa fa-lg fa-plus-circle"/><span class="font-12"> Add Parent Night</span>
          </button>
        </th>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import DatePicker from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  components: {
    DatePicker
  },
  props: {
    parentNightDates: {
      type: Array,
      required: true,
      default() { return [] }
    },
    errors: {
      type: Object,
      required: false,
      default() { return {} }
    }
  },
  data () {
    return {
      datePickerConfig: {
        dateFormat: 'l, F j, Y',
      },
      timePickerConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'h:i K',
        time_24hr: false,
        static: true
      }
    }
  },
  methods: {
    appendNewParentNightDate() {
      this.parentNightDates.push({
        meetingOn: null,
        startAt: null,
        endAt: null
      })
    },
    removeScheduleAt(index) {
      this.parentNightDates.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@styles/modules/schedule/schedule_dates_table.scss';
</style>
