<template>
<div>
  <div class='table table-responsive'>
    <table class='table h-table'>
      <thead>
        <tr>
          <th> Type </th>
          <th> Date </th>
          <th> Start Time </th>
          <th> End Time  </th>
          <th> Attendance </th>
        </tr>
      </thead>
      <tbody>
          <tr
            v-for="(entry, eIndx) in calendarData"
            :key="eIndx">
            <td> <b>{{ entry.type }}</b> </td>
            <td> {{ entry.meetingOn }} </td>
            <td> {{ entry.startAt }} </td>
            <td> {{ entry.endAt }} </td>
            <td> <span :class="setClass(entry.attended)"> {{ entry.attended }} </span> </td>
          </tr>
      </tbody>
    </table>
  </div>
</div>
</template>

<script>

export default {
  data () {
    return {
      calendarData: []
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      this.$http.get('/api/parent/schedules/aggregate_meeting_dates_data').then((response) => {
        this.calendarData = response.data.dates
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    },
    setClass(entry) {
      if (entry == 'Absent')  { return 'text-danger' }
      if (entry == 'Present') { return 'text-success' }

    }
  }
}
</script>

<style scoped lang="scss">
@import '~@styles/variables.scss';
.no-inset {
  box-shadow: none;
}
table {
  margin-bottom: 0;
}
table > * > tr > * {
  vertical-align: middle;
}

tr {
  height: 60px;
  td:nth-child(-n+10), th:nth-child(-n+10) {
    text-align: left;
  }
  &:first-of-type td {
    border-top: none;
  }
}
tr {
  td:nth-child(-n+1) {
    font-weight: bolder;
  }
}

.students-indi-table {
  tr {
    td:nth-child(5) {
      background: #d1dae5;
      font-weight: 600;
      color: #505050;
    }
    td:nth-child(-n+2) {
      font-weight: 600;
    }
  }
}
.students-indi-table th, .students-indi-table td {
  padding-top: 0;
  padding-left: 8px;
  padding-bottom: 0px;
  padding-right: 8px;
}
.questions-table {
  th {
    padding: 15px;
    text-align: left;
  }


  tr {
     th:nth-child(1) {
      width: 10px !important;
    }
    th:nth-child(2) {
      width: 200px !important;
      padding-left: 5px;
    }
    td {
      line-height: 21px;
    }
    td:nth-child(1) {
      padding-left: 10px !important;
      padding-right: 0px !important;
    }
    td:nth-child(2) {
      padding-left: 5px;
    }
  }
}
.btn-sm-table {
  line-height: 0.5;
}
thead {
  tr {
    font-size: 14px;
    font-weight: 600;
  }
}

.table thead th {
  border: none;
}

thead {
  background-color: $primaryColor;
}

table:not(.small-table) {

    th {
      border: none;
      font-size: 16px;
      &:first-child {
        border-radius:5px 0 0 0;
      }
      &:last-child {
        border-radius:0 5px 0 0;
      }
    }

}
td:first-child, th:first-child {
  padding-left: 20px;
}
td:last-child, th:last-child {
  padding-right: 20px;
}

tbody {
  box-shadow: inset 0px 0px 0px 1px #dee2e6;
}
tbody > * {
  font-size: 12px;
}

.table-header {
  background-color: #F3F3F3;
  height: 60px;
  border-top: 1px solid #e8e8e8;
}

</style>
