<template>
  <div class="container">
    <div class="content-pane">
      <div class="pane-wrapper">
        <pdf-export-modal
          :practice-test-set-id="activePracticeTestSet.id" />
        <breadcrumbs v-if="!isHorizonAdmin" :breadcrumbs="breadcrumbs" class="breadcrumbs" />
        <div class="content-heading underlined result-content-heading">
          <h1 class="pull-left">
            {{ activeSchedule.name }}
          </h1>
          <div class="pull-right">
            <h1 class="d-inline text-orange">
              <span v-if='activeSchedule'>{{ totalEnrollmentsCount(activeSchedule) }}</span>
            </h1>
            <h1 class="d-inline fw-400">
              Students Enrolled
            </h1>
          </div>
        </div>
        <div class="report-wrapper">
          <div class="tabs-row">
            <div v-for="(item, index) in navTabs"
              :key="'tab-' + index"
              class="tab"
              :class="{ selected: item.active }"
              @click="tabClicked(item.component, item.active)">
              <div>{{ item.name }}</div>
            </div>
            <div class="w-tab-menu">
              <div
                v-for="(item, index) in navTabs"
                :key="'w-tab-menu-item-' + index"
                class="w-tab-menu-item"
                :class="{ selected: item.active }"
                @click="tabClicked(item.component, item.active)">
                {{ item }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="activePracticeTestSet" class="card score-report-container">
          <div class="bg-primary card-header score-report-heading flex-y-center justify-content-between">
            <h1 class="text-white">
              {{ activePracticeTestSet.assessmentName }}
            </h1>
            <h2 v-if="activeSchedule.targetAssessmentDate" class="fw-400 text-white">
              Test Date: {{ activeSchedule.targetAssessmentDate.name }}
            </h2>
            <div style="flex-grow: 1"></div>
            <div class="common-dropdown-wrapper">
              <common-dropdown
                v-if="schedules"
                :items="schedules"
                :item-display-key="'name'"
                :on-item-select="loadSchedule">
                <img
                  src="/images/blue_calendar.png"
                  width="17"
                  aria-hidden="true"
                  class="date-icon">
                <span
                  style="vertical-align: middle; padding-left: 5px;">{{ activeSchedule.name }}</span>
              </common-dropdown>
            </div>
            <div class="common-dropdown-wrapper">
              <common-dropdown
                v-if="practiceTestSets"
                :items="filterPracticeTestSets"
                :item-display-key="'assessmentName'"
                :on-item-select="loadPracticeTest">
                <img
                  src="/images/exam.svg"
                  width="17"
                  aria-hidden="true"
                  class="date-icon">
                <span style="vertical-align: middle; padding-left: 5px;">{{ activePracticeTestSet.assessmentName }}</span>
              </common-dropdown>
            </div>
          </div>
          <div class="card-body">
            <div class="overall-assessment-header flex-y-center no-underline primary-row">
              <div class="overall-assessment-info">
                <h2>
                  Overall Assessment Performance
                </h2>
                <div class="body-larger pt-1">
                  <i class="fa fa-users text-primary"/> Number of Students Assessed: <span v-if="activeSchedule"
                    class="font-weight-bold text-orange">{{ assessedCount(activeSchedule) }} Students</span>
                </div>
              </div>
              <div class="overall-assessment-action primary-row-action">
                <div
                  v-if="notHorizonAdmin"
                  class="btn btn-outline-secondary flex-y-center">
                  <a @click="showPdfReportModal">PDF Report Export</a>
                </div>
                <div
                  v-if="notHorizonAdmin"
                  class="btn btn-outline-secondary flex-y-center">
                  <a @click="scoreExport">Score Export</a>
                </div>
                <div
                  v-if="canExport"
                  class="btn btn-outline-secondary flex-y-center">
                  <a @click="responsesExport">Responses Export</a>
                </div>
                <!--
                <div id="export-button" class="btn btn-outline-secondary flex-y-center">
                  <a
                    href="#"
                    class="text-decoration-none"
                    @click="downloadPracticeTestExport">
                    <i class="fa fa-external-link" aria-hidden="true"/>
                    Export Report
                  </a>
                </div>
                -->
              </div>
            </div>
            <div class="mt-3 row overall-assessment-charts">
              <template v-if="loadingSectionData">
                <div class="col-12">
                  <common-loading/>
                </div>
              </template>
              <template v-else>
                <div class="col-4">
                  <br>
                  <donut-chart
                    v-if="sectionData.sections.composite"
                    :chart-id="'test'"
                    :average-score="sectionData.sections.composite.averageScore"
                    :min-score="sectionData.sections.composite.minScore"
                    :max-score="sectionData.sections.composite.maxScore"/>
                </div>
                <div class="col-8" style="height: 300px;">
                  <bar-chart
                    v-if="sectionData.sections.composite"
                    :chart-id="'testBar'"
                    :data-set="sectionData.sections.composite.segments"/>
                </div>
              </template>
            </div>
          </div>
          <section-scores
            v-if="sectionData.sections"
            :test-sections="testSections"
            :section-data="sectionData"
            :is-loading="loadingSectionData"/>
          <!-- <performance-comparison
            :active-schedule="activeSchedule"
            :assessment-table-headers="assessmentTableHeaders"
            :schedule-table-headers="scheduleTableHeaders"
            :active-assessment-id="activePracticeTestSet.id"/> -->
          <strength-and-improvement-areas
            v-if="activePracticeTestSet.id"
            :key="'saia-' + activeSchedule.id"
            :request-url-base-prop="requestUrlBase()"
            :assessment-id-prop="activePracticeTestSet.id"
            :schedule-prop="activeSchedule"/>
          <individual-student-performance
            v-if="activePracticeTestSet.id"
            :key="'isp-' + activeSchedule.id"
            :assessment-id-prop="activePracticeTestSet.id"
            :schedule-id-prop="activeSchedule.id"
            :assessment-type="activeSchedule.courseType"
            :is-horizon-admin="isHorizonAdmin"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumbs from '@shared/breadcrumbs'
import Mixins from '@submodules/mixins/mixin.js'
import BarChart from '@shared/charts/bar_chart.vue'
import DonutChart from '@shared/charts/donut_chart.vue'
import CommonDropdown from '@shared/common_dropdown'
import SectionScores from './section_scores.vue'
import PerformanceComparison from './performance_comparison/show.vue'
import StrengthAndImprovementAreas from './strength_and_improvement_areas.vue'
import SubsectionAnalysis from '@shared/score_report/subsection_score_details.vue'
import IndividualStudentPerformance from './individual_student_performance.vue'
import CommonLoading from '@shared/common_loading'
import PdfExportModal from './pdf_export_modal'


export default {
  components: {
    BarChart,
    Breadcrumbs,
    CommonDropdown,
    DonutChart,
    SectionScores,
    PerformanceComparison,
    StrengthAndImprovementAreas,
    SubsectionAnalysis,
    IndividualStudentPerformance,
    CommonLoading,
    PdfExportModal
  },
  mixins: [Mixins],
  props: {
    isHorizonAdmin: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      sectionData: {
        sections: {}
      },
      testSections: [],
      assessmentTableHeaders: [],
      scheduleTableHeaders: [],
      assessmentId: '',
      breadcrumbs: [],
      schedules: [],
      practiceTestSets: [],
      activeSchedule: {
        targetAssessmentDate: {}
      },
      activePracticeTestSet: {},
      subsectionAnalysisData: {},
      loadingSectionData: false,
      navTabs: [],
      selectedAssessmentId: null
    }
  },
  watch: {
    '$route.params': {
      handler() {
        this.selectedAssessmentId = this.$route.params.assessmentId
        this.activeSchedule.id = this.$route.params.scheduleId
        this.initData()
      },
      deep: true
    }
  },
  created () {
    this.selectedAssessmentId = this.$route.params.assessmentId
    this.activeSchedule.id = this.$route.params.scheduleId
    this.initData()
  },
  computed:{
    canExport() {
      if(this.activeSchedule.name == "Entire School") { return true }
    },

    notHorizonAdmin() {
      if (this.isHorizonAdmin) { return false }
      return true
    },

    assessmentType () {
      if (this.isHorizonAdmin) {
        return this.activeSchedule.courseType
      } else {
        return this.userSchool().courseType;
      }
    },
    entireSchool () {
      return { id: 0, name: `Entire School`, courseType: this.assessmentType }
    },
    filterPracticeTestSets () {
      if (this.practiceTestSets.length) {
        return _.filter(this.practiceTestSets, (practiceTestSet) => {
          return practiceTestSet.studentPracticeTestCount > 0
        })
      }
      return [];
    }
  },
  methods: {
    ...mapGetters(['userSchool']),
    initData () {
      this.loadSchedules()
      this.loadNavTabs()
      // this.loadingSectionData = true
    },
    assessedCount (schedule) {
      if(schedule.id === 0 ) {
        if (typeof this.activePracticeTestSet !== "undefined") {
          return this.activePracticeTestSet.studentPracticeTestCount
        }
      } else {
        if (this.filterPracticeTestSets.length) {
          if (typeof schedule.practiceTestDates !== "undefined") {
            return schedule.practiceTestDates.find(e => e.practiceTestSetId == this.selectedAssessmentId).studentPracticeTestCount
          }
        }
      }
      return 0
    },
    totalEnrollmentsCount (schedule) {
      if(schedule.id === 0 ) {
        if (typeof this.activePracticeTestSet !== "undefined") {
          return this.activePracticeTestSet.studentEnrollmentsCount
        }
      } else {
        if (this.filterPracticeTestSets.length) {
          if (typeof schedule.practiceTestDates !== "undefined") {
            return schedule.practiceTestDates.find(e => e.practiceTestSetId == this.selectedAssessmentId).studentEnrollmentsCount
          }
        }
      }
      return 0
    },
    downloadPracticeTestExport () {
      console.log('NOT IMPLEMENTED YET')
    },
    loadNavTabs() {
      if (this.activeSchedule.id != 0)  {
        if (this.isHorizonAdmin) {
          this.navTabs = [
            {
              active: true,
              name: "Summary",
              component: "AdminStudentScoreReport",
            },
            {
              active: false,
              name: "Subsection Details",
              component: "AdminStudentScoreReportSubsectionDetails",
            },
            {
              active: false,
              name: "Advanced Reporting (Beta)",
              component: "StaffAdvancedReporting",
            }
          ]
        } else {
          this.navTabs = [
            {
              active: true,
              name: "Summary",
              component: "StaffStudentScoreReport",
            },
            {
              active: false,
              name: "Subsection Details",
              component: "StaffStudentScoreReportSubsectionDetails",
            },
            {
              active: false,
              name: "Advanced Reporting (Beta)",
              component: "StaffAdvancedReporting",
            }
          ]
        }
      } else {
          if (this.isHorizonAdmin) {
            this.navTabs = [
              {
                active: true,
                name: "Summary",
                component: "AdminStudentScoreReport",
              },
              {
                active: false,
                name: "Subsection Details",
                component: "AdminAssessmentCourseScoreReportSubsectionDetails",
              },
              {
                active: false,
                name: "Advanced Reporting (Beta)",
                component: "StaffAdvancedReporting",
              }
            ]
          } else {
           this.navTabs = [
            {
              active: true,
              name: "Summary",
              component: "StaffStudentScoreReport",
            },
            {
              active: false,
              name: "Subsection Details",
              component: "StaffAssessmentCourseScoreReportSubsectionDetails",
            },
            {
              active: false,
              name: "Advanced Reporting (Beta)",
              component: "StaffAdvancedReporting",
            }
          ]
        }
      }
    },
    loadSchedules () {
      let endpointURL = `/api/staff/schedules?practice_test_set_id=${this.selectedAssessmentId}`
      if (this.isHorizonAdmin) {
        endpointURL = `/api/admin/schools/${this.$route.params.id}/schedules?practice_test_set_id=${this.selectedAssessmentId}`
      }
      this.$http.get(endpointURL).then(response => {
        this.schedules = [...response.data.schedules, this.entireSchool ]
        this.loadSchedule(this.activeSchedule)
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    },
    async loadSchedule (schedule) {
      this.activeSchedule.id = schedule.id
      if(schedule.id == 0) {
        this.activeSchedule = _.clone(this.entireSchool)
        this.loadSchoolPracticeTestSets()
        this.loadingSchedule = false
      } else {
        let endpointURL = `/api/staff/schedules/${schedule.id}`
        if (this.isHorizonAdmin) {
          endpointURL = `/api/admin/schools/${this.$route.params.id}/schedules/${schedule.id}`
        }
        this.$http.get(endpointURL).then(response => {
          this.activeSchedule = response.data.schedule
          this.loadPracticeTests()
          this.loadingSchedule = false
        }, (responseError) => {
          this.appendError({ error: responseError, notification: true })
        })
      }

      this.setAssessmentComparisonTableHeaders()
      this.setScheduleComparisonTableHeaders()
    },
    async loadSchoolPracticeTestSets() {
      let endpointURL = `/api/staff/school/practice_test_sets/${this.selectedAssessmentId}`
      if (this.isHorizonAdmin) {
        endpointURL = `/api/admin/schools/${this.$route.params.id}/practice_test_sets/${this.selectedAssessmentId}`
      }
      const response = await this.$http.get(endpointURL)
      this.practiceTestSets = response.data.practiceTestSets
      this.activePracticeTestSet = this.filterPracticeTestSets.find(e => e.id == this.selectedAssessmentId);
      if (_.size(this.activePracticeTestSet)) {
        this.breadcrumbs =[{text: this.activePracticeTestSet.assessmentName}]
        this.loadSchoolSectionData()
      }
    },
    loadPracticeTests () {
      let endpointURL = `/api/staff/school/practice_test_sets/${this.selectedAssessmentId}`
      if (this.isHorizonAdmin) {
        endpointURL = `/api/admin/schools/${this.$route.params.id}/practice_test_sets/${this.selectedAssessmentId}`
      }
      this.$http.get(endpointURL).then(response => {
        this.practiceTestSets = response.data.practiceTestSets
        this.activePracticeTestSet = this.filterPracticeTestSets.find(practiceTest => practiceTest.id == this.$route.params.assessmentId)
        if (this.isBlank(this.activePracticeTestSet) && this.filterPracticeTestSets.length) {
          this.activePracticeTestSet = this.practiceTestSets[0]
          this.selectedAssessmentId = this.activePracticeTestSet.id
        }
        if (_.size(this.activePracticeTestSet)) {
          this.breadcrumbs =[{text: this.activePracticeTestSet.assessmentName}]
          this.loadSectionData()
        }
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    },
    loadSectionData () {
      // this.loadingSectionData = true
      let endpointURL = `/api/staff/schedules/${this.activeSchedule.id}/assessment_scores/${this.activePracticeTestSet.id}`
      if (this.isHorizonAdmin) {
        endpointURL = `/api/admin/schools/${this.$route.params.id}/schedules/${this.activeSchedule.id}/assessment_scores/${this.activePracticeTestSet.id}`
      }
      this.$http.get(endpointURL).then(response => {
        this.sectionData = response.data.scheduleAssessmentScores
        this.testSections = response.data.scheduleAssessmentScores.sections
        // this.loadingSectionData = false
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
        // this.loadingSectionData = false
      })
    },
    async loadSchoolSectionData () {
      try {
        let endpointURL = `/api/staff/school/assessment_scores/${this.activePracticeTestSet.id}`
        if (this.isHorizonAdmin) {
          endpointURL = `/api/admin/schools/${this.$route.params.id}/assessment_scores/${this.activePracticeTestSet.id}`
        }
        const response = await this.$http.get(endpointURL)
        this.sectionData = response.data.scheduleAssessmentScores
        this.testSections = response.data.scheduleAssessmentScores.sections
      } catch(responseError) {
        this.appendError({ error: responseError, notification: true })
      }
    },
    async loadSubsectionAnalysis () {
      const response = await this.$http.get(`${this.requestUrlBase()}/score_details`)
      this.subsectionAnalysisData = response.data.scoreDetails
    },
    loadPracticeTest (practiceTestSet) {
      if (this.isHorizonAdmin) {
        this.$router.push({ name: 'AdminStudentScoreReport', params: { scheduleId: this.activeSchedule.id, assessmentId: practiceTestSet.id } })
      } else {
        this.$router.push({ name: 'StaffStudentScoreReport', params: { scheduleId: this.activeSchedule.id, assessmentId: practiceTestSet.id } })
      }
    },
    requestUrlBase () {
      if (this.isHorizonAdmin) {
        return `/api/admin/schools/${this.$route.params.id}/practice_test_dates/${this.selectedAssessmentId}`
      } else {
        if(this.activeSchedule.id == 0)  {
          return `/api/staff/school/practice_test_dates/${this.selectedAssessmentId}`
        } else {
          return `/api/staff/schedules/${this.activeSchedule.id}/practice_test_dates/${this.selectedAssessmentId}`
        }
      }
    },

    setAssessmentComparisonTableHeaders () {
      if(!this.isBlank(this.assessmentTableHeaders)) { return this.assessmentTableHeaders }

			if(this.assessmentType == "ACT") {
			  return this.assessmentTableHeaders = [
			    "Schedule",
  				"Assessment",
  				"# of Students Assessed",
          "Overall Score",
          "# of Students Improved Score",
          "Average Score Improvement",
          "ELA Score", "STEM Score"
        ]
      }

      if(this.assessmentType == "SAT" || this.assessmentType =="PSAT89" || this.assessmentType == "PSAT10") {
        return this.assessmentTableHeaders = [
          "Schedule",
  				"Assessment",
          "# of Students Assessed",
          "Overall Score",
          "# of Students Improved Score",
          "Average Score Improvement",
          "Math Score", "R&W Score"
        ]
      }
    },

    setScheduleComparisonTableHeaders () {
      if(!this.isBlank(this.scheduleTableHeaders)) { return this.scheduleTableHeaders }

			if(this.assessmentType == "ACT") {
			  return this.scheduleTableHeaders = [
			    "Assessment",
  				"Schedule",
  				"# of Students Assessed",
          "Overall Score",
          "# of Students Improved Score",
          "Average Score Improvement",
          "ELA Score",
          "STEM Score"
        ]
      }

      if(this.assessmentType == "SAT" || this.assessmentType == "PSAT89" || this.assessmentType == "PSAT10") {
        return this.scheduleTableHeaders = [
          "Assessment",
  				"Schedule",
  				"# of Students Assessed",
          "Overall Score",
          "# of Students Improved Score",
          "Average Score Improvement",
          "Math Score",
          "R&W Score"
        ]
      }
		},

    tabClicked(component, active) {
      if (!active) {
        this.$router.push({
          name: component,
          params: {
            assessmentId: this.selectedAssessmentId,
            scheduleId: this.activeSchedule.id
          }
        })
      }
    },

    scoreExport(){
      window.open(`/api/exports/practice_test_sets/${this.selectedAssessmentId}/export_scores`)
    },

    responsesExport () {
      window.open(`/api/exports/practice_test_sets/${this.selectedAssessmentId}/export_responses`)
    },

    showPdfReportModal() {
      $('#bulk-pdf-export-modal').modal('show')
    }

  }
}
</script>

<style scoped lang="scss">
  @import '~@styles/variables';

  .underlined {
    border-bottom: 0px;
  }

  .no-border-radius {
    border-radius: 0;
  }

  .score-report-heading {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .result-content-heading {
    padding-bottom: 30px;
  }

  .score-report-container {
    border-top: 0px !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;

    .common-dropdown-wrapper {
      .btn {
        margin-right: auto !important;
        margin-left: auto !important;
      }

      .btn:first-child {
        margin-bottom: 5px;
      }
    }

  }

  .card-header:first-child {
    border-radius: 0 !important;
  }

  .tabs-row {
    padding: 0px 1px;
  }

  .w-tab-menu {
    display: none;
  }

  @media screen and (max-width: $mobileScreenExtraLarge) {
    .overall-assessment-header {
      display: block;

      .overall-assessment-info, .overall-assessment-action {
        width: 100%;
      }

      .overall-assessment-info {
        margin-bottom: 10px;
      }
    }
  }

  @media screen and (max-width: $mobileScreenLarge) {
    .content-heading {
      display: inline-block;

      .pull-left {
        width: 100%;
      }

      .pull-right {
        width: 100%;
        float: left;
        text-align: left;
        margin-left: 0px;
      }
    }

    .underlined {
      border-bottom: 0px;
    }
  }

  @media screen and (max-width: $mobileScreenMedium) {
    .container {
      .content-pane {
        .pane-wrapper {
          padding: 20px 10px;
        }
      }
    }

    .breadcrumbs {
      display: none;
    }

    .tab {
      padding: 13px 20px;
      font-size: 16px;
    }

    .score-report-heading {
      display: block;
      text-align: center;

      h1 {
        font-size: 18px;
      }

      h2 {
        margin-top: 5px;
        font-size: 14px;
      }
    }

    .overall-assessment-header {
      .overall-assessment-info {
        text-align: center;
      }

      .overall-assessment-action {
        display: block;


      }
    }

    .overall-assessment-charts {
      display: block;

      .col-4, .col-8 {
        width: 100%;
        max-width: 100%;
      }

      .col-4 {
        margin-bottom: 20px;
      }
    }

    .mt-3 {
      margin-top: 0px !important;
    }
  }

  @media screen and (max-width: $mobileScreenSmall) {
    .container {
      padding: 0px;
      height: 100%;

      .content-pane {
        border-radius: 0px;
        height: 100%;
      }
    }

    .tab {
      display: none;
    }

    .w-tab-menu {
      display: block;
      width: 100%;

      .w-tab-menu-item {
        width: 100%;
        transition: .2s;
        cursor: pointer;
        color: $mutedText;
        padding: 13px 25px;
        text-align: center;
        border-radius: 7px;
        &.selected {
          background-color: $primaryColor;
          color: #fff;
        }
        &:not(.selected):hover {
          color: $themeGray;
        }
      }
    }
  }
</style>

<style lang="scss">
  @import '~@styles/variables';

  @media screen and (max-width: $mobileScreenMedium) {
    .score-report-container {
      .subsection-score-details-header {
        h1 {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
</style>
