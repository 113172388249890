<template>
  <div class="container py-5">
    <div class="col-md-6 mx-auto">
      <div class="card gsni-top-border">
        <div class="card-body">
          You have been logged out.  Click
          <router-link :to="{ name: 'login' }">
            here
          </router-link>
          to login again
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  mounted () {
    this.logout().then(() => {
      this.$router.push({ name: 'login' }).catch(err => {}) // eslint-disable-line no-unused-vars
    })
  },
  methods: {
    ...mapActions(['logout'])
  }
}
</script>

<style scoped>
</style>
