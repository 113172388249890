<template>
  <div>
    <div class="form-group">
      <div class="horizon-form-column">
        <div class="horizon-form-row">
          <label for="scheduleName">Schedule Name</label>
          <span id="scheduleName">{{ schedule.name }}</span>
        </div>

        <div class="horizon-form-row">
          <label for="schedule-course-type">Course Type</label>
          <span id="schedule-course-type">{{ schedule.courseType }}</span>
        </div>

        <div class="horizon-form-row">
          <label for="schedule-type">Schedule Type</label>
          <span v-if="schedule.scheduleType === 'Inside' || schedule.scheduleType === 'Outside'"
            id="schedule-type">
            {{ schedule.scheduleType }} School Day
          </span>
          <span v-else
            id="schedule-type">
            {{ schedule.scheduleType }}
          </span>
        </div>

        <div v-if="schedule.scheduleType === 'Inside' || schedule.scheduleType === 'Outside'" 
          class="horizon-form-row">
          <label for="targetAssessmentDate">Target Assessment Date</label>
          <span id="targetAssessmentDate" v-if="schedule.targetAssessmentDate">{{ schedule.targetAssessmentDate.name }}</span>
        </div>
      </div>

      <div class="horizon-form-column">
        <div class="horizon-form-row">
          <label for="schedule-primary-teacher">Instructor #1</label>
          <span id="schedule-primary-teacher" v-if="schedule.primaryTeacher">
            {{ schedule.primaryTeacher.firstName }} {{ schedule.primaryTeacher.lastName }}
          </span>
        </div>

        <div class="horizon-form-row">
          <label for="schedule-secondary-teacher">Instructor #2</label>
          <span id="schedule-secondary-teacher" v-if="schedule.secondaryTeacher">
            {{ schedule.secondaryTeacher.firstName }} {{ schedule.secondaryTeacher.lastName }}
          </span>
        </div>

        <div v-if="schedule.scheduleType === 'Outside'" class="horizon-form-row">
          <label for="schedule-class-location">Class Location</label>
          <span id="schedule-class-location">{{ schedule.classLocation }}</span>
        </div>

        <div v-if="schedule.scheduleType === 'Inside' || schedule.scheduleType === 'Outside'"
          class="horizon-form-row">
          <label for="schedule-assessment-location">Assessment Location</label>
          <span id="schedule-assessment-location">{{ schedule.assessmentLocation }}</span>
        </div>

        <div class="horizon-form-row">
          <label for="schedule-zoom-url">Zoom Url</label>
          <span id="schedule-zoom-url">{{ schedule.zoomUrl }}</span>
        </div>

        <div class="horizon-form-row">
          <label for="schedule-zoom-url">Course Template</label>
          <span id="schedule-zoom-url">{{ courseTemplateName(schedule) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from '@submodules/mixins/mixin.js'

export default {
  mixins: [Mixins],
  props: {
    schedule: {
      type: Object,
      required: true,
      default() { return {} }
    }
  },
  methods: {
    courseTemplateName(schedule) {
      if (schedule.heCourseTemplates != null && schedule.heCourseTemplates.length > 0) {
        return schedule.heCourseTemplates[0].heCourse[0].name
      } else {
        return ""
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~@styles/variables';

  .btn-outline-secondary.gray-hover:hover {
    background-color:rgb(240, 240, 240) !important;
    color: black !important;
  }
  .settings-btn {
    padding: 14px;
  }

  .action-btn {
    font-size: 12px !important;
  }

  tr {
    height: 60px;
  }
  tr {
    th:nth-child(1) {
      width: 20%;
    }
    th:nth-child(2) {
      width: 30%;
    }
    th:nth-child(3) {
      width: 15%;
    }
    th:nth-child(4) {
      width: 15%;
    }
    th:nth-child(5) {
      width: 20%;
    }
    td:nth-child(1) {
      font-weight: 600;
      font-size: 12px;
    }
    td {
      border-color: rgb(240, 240, 240);
    }
  }

  .table thead th {
    vertical-align: middle;
    border: none;
  }
  tfoot {
    background-color: rgb(248, 248, 248);
    border-top: none !important;
    th {
    border-top: none;
    }
  }
  .action-column {
    text-align: right !important;
    .btn {
      margin-left: 0px !important;
      height: 40px !important;
      width: 40px !important;
    }
  }
  .fa-fw {
    width: auto;
  }
  .margin-top-auto {
    margin-top: auto;
  }
  .margin-bottom-auto {
     margin-bottom: auto;
  }

  .horizon-form-row {
    label {
      width: 40%;
    }

    span {
      width: 60%;
      text-align: right;
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  @media screen and (max-width: $mobileScreenLarge) {
    .form-group {
      display: block;

      .horizon-form-column {
        width: 100%;

        .horizon-form-row {
          padding: 0px;
        }
      }
    }
  }
</style>
