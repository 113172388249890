<template>
  <div class="dropdown" v-if="options">
    <div :class="dropdownClass">
      <i v-if="searchIconVisible" 
        class="fa fa-search"></i>
	    <input class="search-input"
	      :name="name"
	      @focus="showOptions()"
	      @keyup="keyMonitor"
	      v-model="searchFilter"
	      :disabled="disabled"
	      :placeholder="placeholder" />
    </div>

    <!-- Dropdown Menu -->
    <div class="dropdown-content"
      v-show="optionsShown">
      <div
        class="dropdown-item font-12 margin-bottom-auto margin-top-auto text-truncate"
        @mousedown="selectOption(option)"
        v-for="(option, index) in filteredOptions"
        :key="index">
          {{ option.name || option.id || '-' }}
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'

  export default {
    name: 'CommonSearch',
    template: 'CommonSearch',
    props: {
      searchIconVisible: {
        type: Boolean,
        default: true
      },
      name: {
        type: String,
        required: false,
        default: 'dropdown',
        note: 'Input name'
      },
      options: {
        type: Array,
        required: false,
        default: [],
        note: 'Options of dropdown. An array of options with id and name',
      },
      optionsDisabled: {
        type: Boolean,
        required: false,
        default: false,
        note: 'Whether to show the options array or not',
      },
      placeholder: {
        type: String,
        required: false,
        default: 'Please select an option',
        note: 'Placeholder of dropdown'
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false,
        note: 'Disable the dropdown'
      },
      maxItem: {
        type: Number,
        required: false,
        default: 6,
        note: 'Max items showing'
      },
      dropdownClass: {
	      type: String,
	      required: false,
	      default() { return 'd-flex flex-row align-items-center btn-outline d-flex d-inline dropdown horizon-dropdown horizon-input justify-content-between mr-2 no-hover' }
	    }
    },
    data() {
      return {
        selected: {},
        optionsShown: false,
        searchFilter: ''
      }
    },
    created() {
      this.$emit('selected', this.selected);
    },
    computed: {
      filteredOptions() {
        const filtered = [];
        const regOption = new RegExp(this.searchFilter, 'ig');
        for (const option of this.options) {
          if (this.searchFilter.length < 1 || option.name.match(regOption)){
            if (filtered.length < this.maxItem) filtered.push(option);
          }
        }
        return filtered;
      }
    },
    methods: {
      selectOption(option) {
        this.selected = option;
        this.optionsShown = false;
        this.searchFilter = this.selected.name;
        this.$emit('selected', this.selected);
      },
      showOptions(){
        if (!this.optionsDisabled) {
          this.searchFilter = '';
          this.optionsShown = true;
        }
      },
      exit() {
        if (!this.selected.id) {
          this.selected = {};
          this.searchFilter = '';
        } else {
          this.searchFilter = this.selected.name;
        }
        this.$emit('selected', this.selected);
        this.optionsShown = false;
      },
      // Selecting when pressing Enter
      keyMonitor: function(event) {
        if (event.key === "Enter") {
        	if (this.filteredOptions[0]) {
            if (this.searchFilter.length === 0) {
              this.$emit('cleared')
              this.optionsShown = false;
            } else {
  	          this.selectOption(this.filteredOptions[0])
            }
	        } else {
	        	this.updateSearch()
	        }
        }
      },
      updateSearch: debounce(function (e) {
	      this.$emit('filter', this.searchFilter)
	    }, 500),
      clearQuery() {
        this.selected = {};
        this.searchFilter = '';
      }
    },
    watch: {
      searchFilter() {
        if (this.filteredOptions.length === 0) {
          this.selected = {};
        } else {
          this.selected = this.filteredOptions[0]
        }
        this.updateSearch()
      }
    }
  };
</script>


<style lang="scss" scoped>
.dropdown {
  width: 100%;
}
	.search-input {
    width: 100%;
		border: none;
	}
  .horizon-input {
    width: 100%;
  }
  input {border:0;outline:0;}
  input:focus {outline:none!important;}

  .dropdown-content {
    position: absolute;
    background-color: #fff;
    width: 100%;
    //min-width: 240px;
    //max-width: 240px;
    max-height: 248px;
    border: 1px solid #e7ecf5;
    box-shadow: 0px -8px 34px 0px rgba(0,0,0,0.05);
    overflow: auto;
    z-index: 1;
    .dropdown-item {
      color: black;
      text-decoration: none;
      display: block;
      cursor: pointer;
      &:hover {
        background-color: #e7ecf5;
      }
    }
  }
  .dropdown:hover .dropdowncontent {
    display: block;
  }
  
</style>
