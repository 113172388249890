<template>
  <div class="d-inline">
    <button
      class="btn btn-light enrollment-function-button"
      :disabled="disabled"
      @click="openModal">
      Delete Students
    </button>

    <confirm-modal
      :cancel="close"
      body-header="Are you sure you want to delete the selected students?"
      body-paragraph="Deleting student(s) will permanently erase their accounts and corresponding scoring data."
      confirm-btn-text="Yes, delete students."
      confirmed-action="mass-delete"
      @confirmed="massDelete"/>
  </div>
</template>

<script>
import ConfirmModal from '@shared/students/confirm_modal'

export default {
  components: {
    ConfirmModal
  },
  props: {
    students: {
      type: Array,
      required: true
    },
    enrollmentRepository: {
      type: Object,
      required: true
    }
  },
  computed: {
    disabled () {
      return this.students.length == 0
    }
  },
  methods: {
    openModal () {
      $('#mass-delete-students-modal').modal('show')
    },
    async massDelete () {
      const students = await this.deleteStudents(this.students)
      this.$emit('deleted', students)
      this.close()
    },
    close () {
      $('#mass-delete-students-modal').modal('hide')
    },
    async deleteStudents (students) {
      const deleteRequests = students.map(student => this.enrollmentRepository.delete(student))
      return await Promise.all(deleteRequests)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@styles/variables';

.enrollment-function-button {
  height: 40px;
  font-size: 12px;
}

@media screen and (max-width: $mobileScreenLarge) {
  .btn {
    margin-left: 0px;
    width: 130px;
  }
}
</style>
