<template>
  <div class="container">
    <div class="row" >
      <div class="col-md-9">
        <div class="content-pane">
          <div class="assessment-results-wrapper">
            <zoom-link
             :user-type="userType"
            />
            <div class="dashboard-student-heading">
              <img src="/images/assessment_results_icon.png" class="image-student-head">
              <h2 class="heading-student-name">
                My Assessment Results
              </h2>
            </div>
            <course-progress
              :property-provider="courseProgressProvider"/>
            <individual-score-reports
              v-if="assessmentData.length > 0"
              :score-report-route-prop="'StudentScoreReport'"
              :user-type-prop="userType"
              :assessment-data="assessmentData"/>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div v-if="hasThinkificBundleId" class="content-pane h-col-mb course-resources-pane">
          <course-resources/>
        </div>
        <div class="content-pane h-col-mb student-faq-pane">
          <student-faq/>
        </div>
        <div class="content-pane h-col-mb">
          <course-schedule
            :user-type-prop="userType"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CourseProgress from '@shared/parent_student/course_progress'
import CourseResources from './course_resources'
import StudentFaq from './student_faq'
import CourseSchedule from '@shared/parent_student/course_schedule'
import ZoomLink from '@shared/parent_student/zoom_link'
import IndividualScoreReports from '@shared/dashboard_widget/individual_score_report'

import CourseProgressProviderFactory from '@shared/parent_student/course_progress_property_provider_factory.js'
import RepositoryFactory from '@repositories/factory'
import StudentCourses from './courses.vue'
import { mapGetters } from "vuex"
const AssessmentResultRepository = RepositoryFactory.student.get('assessment_result')

export default {
  components: {
    CourseProgress,
    CourseResources,
    StudentFaq,
    CourseSchedule,
    IndividualScoreReports,
    ZoomLink,
    StudentCourses
  },
  data () {
    return {
      userType: 'student',
      assessmentData: [],
      courseProgressProvider: {}
    }
  },
  async created () {
    await this.initializeCourseProgressProvider()
  },
  methods: {
    ...mapGetters(["userSchedules","activeCourse", "userSchool"] ),
    async initializeCourseProgressProvider () {
      const studentCourse = this.activeCourse()
      // const scoresSummary = await AssessmentResultRepository.fetchCourseProgress(studentCourse.courseType.toLowerCase())
      const scoresSummary = await AssessmentResultRepository.fetchCourseProgress(studentCourse.id)
      this.assessmentData = scoresSummary.scores
      if (scoresSummary.assessmentType != "") {
        this.courseProgressProvider = CourseProgressProviderFactory.get(scoresSummary.assessmentType, this.assessmentData)
      }
    },
    checkStudentCourses () {
      //TODO: HE-744 checek student courses. If multiple route to StudentCourse else StudentDashboard
    },
  },
  computed: {
    hasThinkificBundleId() {
      return !this.userSchool().thinkificBundleId == ""
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@styles/variables.scss';


.join-class-btn {
  background-color: #f4701f;
  border: none;
  color: black;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 20px;
  cursor: pointer;
  border-radius: 30px;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-weight: 700;

}

.zoom-banner-text > h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 32px;
  font-weight: 700;
  color: #ffffff ;
}

.zoom-image > .img-thumbnail {
  background-color: #345165;
  border: 1px solid #345165;
}



.assessment-results-wrapper {
  padding: 10px 15px 18px;
}

.heading-student-name {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #345165;
  font-size: 32px;
  font-weight: 700;
}

.dashboard-student-heading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  padding: 10px;
  margin-bottom: 12px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
}

.image-student-head {
  width: 86px;
  height: auto;
  margin-top: 0px;
  margin-right: 17px;
  margin-bottom: 0px;
}

.col-9 {
  padding-right: 7.5px;
}

.col-3 {
  padding-left: 7.5px;
}

@media screen and (max-width: $mobileScreenLarge) {
  h2 {
    font-size: 28px !important;
  }

  .col-md-3 {
    padding-right: 0px;
    padding-left: 0px;
  }
}

@media screen and (max-width: $mobileScreenMedium) {
  .col-md-3 {
    margin-top: 15px;
    padding-right: 15px;
    padding-left: 15px;
  }

  h2 {
    font-size: 23px !important;
  }
}

@media screen and (max-width: $mobileScreenSmall) {
  .col-md-9 {
    padding: 0px;
  }

  .col-md-3 {
    padding: 0px;
    margin-top: 15px;
    margin-bottom: 0px;
  }

  .h-col-mb {
    margin-bottom: 0px;
  }

  .content-pane {
    border-radius: 0px;
  }

  .course-resources-pane {
    margin-bottom: 15px;
  }

  .student-faq-pane {
    margin-bottom: 15px;
  }

  h2 {
    font-size: 18px !important;
  }
}

@media screen and (max-width: $mobileScreenExtraSmall) {
  h2 {
    font-size: 14px !important;
  }
}
</style>
