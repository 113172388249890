<template>
  <div id="horizaon_portal_app">
    <app-common-notifications/>
    <div v-if="userAuthenticated()">
      <app-header class="d-print-none"/>
      <div class="wrapper">
        <div id="content">
          <div class="container-fluid padding-top">
            <router-view/>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="login-page page">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@shared/header.vue'
import CommonNotifications from '@submodules/components/common_notifications.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    'app-common-notifications': CommonNotifications,
    appHeader: Header
  },
  created () {
    this.loadUserSession()
  },
  methods: {
    ...mapGetters(['userAuthenticated']),
    ...mapActions(['loadUserSession'])
  }
}
</script>

<style scoped lang="scss">
@import '~@styles/variables.scss';


.padding-top {
	padding-top: 15px;
}

.login-page {
  min-height: 100vh;
  height: 100%;
	background-color: #E9EAEE;
}
#content {
	background-color: $themeLtGray;
  width: 100%;
  min-height: 100vh;
  transition: all 0.3s;
  padding-bottom: 18px;
}

.wrapper {
	display: flex;
	width: 100%;
	align-items: stretch;
}

@media screen and (max-width: $mobileScreenLarge) {
  #horizon-primary-navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1048;
  }
  .wrapper {
    margin-top: 67px;
  }
}

@media screen and (max-width: $mobileScreenSmall) {
  .container-fluid {
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: 100%;
  }

  #content {
    padding-bottom: 0px;
  }
}
</style>
