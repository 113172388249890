<template>
  <horizon-modal-full-width
    :id="id"
    :modal-title="name"
    :on-close="modalClose"
    :pages="reportPages"
    :current-page="current_page"
    @prev-page="prevPage"
    @next-page="nextPage">
    <template v-slot:content>
      <div :id="id + '-report-container'">
        <div :id="id + '-report'"></div>
      </div>
    </template>
  </horizon-modal-full-width>
</template>
<script>
import HorizonModalFullWidth from '@shared/horizon_modal_full_width'
export default {
  components: {
    HorizonModalFullWidth,
  },
  props: {
    id: {
      type: String,
      default: '',
      required: true
    },
    name: {
      type: String,
      default: '',
      required: true
    },
    initConf: {
      type: Object,
      default () { return {} }
    },
    reportPages: {
      type: Object,
      default () { return {} }
    },
    reportLessonParams: {
      type: Object,
      default () { return {} }
    },
    htmlTag: {
      type: String,
      default: '',
      required: true
    },
  },
  data () {
    return {
      current_page: 1,
    }
  },
  watch: {
    htmlTag: {
      immediate: true,
      handler(val, oldVal) {
        if (document.getElementById(this.id + "-report-container") == null) { return }
        this.clearReportContainer(val)
      }
    },
    initConf: {
      immediate: true,
      handler(val, oldVal) {
        if (Object.keys(val).length > 0) {
          this.loadReport()
        }
      }
    }
  },
  methods: {
    modalClose() {
      this.$emit('modal-closed')
    },
    clearReportContainer(newId) {
      let container = document.getElementById(this.id + "-report-container")
      let old_div = container.firstElementChild
      if (old_div != null) {
        let new_div = document.createElement("div")
        new_div.id = newId
        old_div.parentNode.replaceChild(new_div, old_div)
      }
    },
    loadReport() {
      setTimeout(() => {
        LearnosityReports.init(this.initConf)
        this.current_page = 1
      }, 500)
    },
    loadReportPage() {
      this.$http.get(`/api/staff/course_activities/${this.reportLessonParams.course_id}/lesson/item_analysis_report/${this.reportLessonParams.lesson_id}/${this.reportLessonParams.activity_id}/${this.current_page}`).then((res) => {
        let conf = JSON.parse(res.data.response)
        if (conf.request.reports[0].users.length > 0) {
          setTimeout(() => {
            LearnosityReports.init(conf)
          }, 500)
        } else {
          this.appendError({error: 'No responses found.', notification: true})
        }
      }, (responseError) => {
        this.appendError({error: responseError, notification: true})
      })
    },
    prevPage() {
      if (this.current_page > 1 ) {
        this.current_page -= 1
        this.loadReportPage()
      }
    },
    nextPage() {
      if (this.current_page < this.reportPages.page_count) {
        this.current_page += 1
        this.loadReportPage()
      }
    }
  }
}
</script>
<style scoped lang='scss'>
@import '~@styles/variables';
</style>
