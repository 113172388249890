<template>
  <div>
    <div class="container">
      <div class="content-pane">
        <div class="pane-wrapper">
          <breadcrumbs/>
          <div class="school-admin-heading">
            <h1>{{ school.name }}</h1>
          </div>
          <div class="row">
            <div class="col-md-12 report-wrapper">
              <div class="sub-nav-container">
                <div
                  v-for="subsection in subsectionData"
                  :key="subsection.category">
                    <router-link
                      :to="{ name: subsection.routeName, id: schoolId }"
                      v-slot="{ href, navigate, isActive }">
                      <div class="mr-4 pb-2 selectable-tab text-center"
                        :class="{ 'selected': isActive }"
                        :href="href" @click="navigate">
                        {{ subsection.category }}
                      </div>
                    </router-link>
                </div>
              </div>
              <div class="row">
                <router-view></router-view>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@shared/breadcrumbs'
import Mixins from '@submodules/mixins/mixin.js'

export default {
  components: {
    Breadcrumbs,
  },
  mixins: [Mixins],
  data () {
    return {
      school: {
        name: ''
      },
      subsectionData: [{
        category: 'Session Overview',
        routeName: 'AdminSchoolSessionOverview'
      },{
        category: 'Schedules',
        routeName: 'AdminSchoolScheduleList'
      }, {
        category: 'Enrollment',
        routeName: 'AdminSchoolEnrollmentList'
      },{
        category: 'Attendance',
        routeName: 'AdminSchoolAttendanceList'
      },{
        category: 'Student Results',
        routeName: 'AdminSchoolStudenResults'
      },{
        category: 'School Settings',
        routeName: 'AdminSchoolSettings'
      }]
    }
  },
  async created() {
    await this.getSchool()
  },
  computed: {
    schoolId() {
      return this.$route.params.id
    }
  },
  methods: {
    async getSchool () {
      const response = await this.$http.get(`/api/admin/schools/${this.schoolId}`)
      this.school = response.data.school
    }
  }
}
</script>

<style scoped lang="scss">
.school-admin-heading {
  padding-bottom: 1.5rem;
}
</style>
