// You should be retrieving all repositories from this factory

import StudentScheduleDateRepository from '@repositories/student/schedule_date'
import StudentAssessmentResultRepository from '@repositories/student/assessment_result'

import adminRepositories from '@repositories/admin/factory'
import staffRepositories from '@repositories/staff/factory'
import guestRepositories from '@repositories/guest/factory'


const studentRepositories = {
  schedule_date: StudentScheduleDateRepository,
  assessment_result: StudentAssessmentResultRepository
}

import ParentAssessmentResultRepository from '@repositories/parent/assessment_result'
const parentRepositories = {
  assessment_result: ParentAssessmentResultRepository
}

export default {
  admin: {
    get: name => adminRepositories[name],
    school: (id) => adminRepositories.school(id)
  },
  staff: {
    get: name => staffRepositories[name]
  },
  student: {
    get: name => studentRepositories[name]
  },
  parent: {
    get: name => parentRepositories[name]
  },
  guest: {
    get: name => guestRepositories[name]
  }
}
