<!-- DEPRECATED: use app/javascript/packs/components/modules/enrollment/modal/mass_import.vue instead -->
<template>
  <horizon-modal id="mass-import-modal" modal-title="Mass Import Students" :on-close="resetImport">
    <template v-slot:content>
      <div class="d-flex flex-column justify-content-end">
        <h5 class="model-para-heading">
          How to mass import students to the Horizon Education Portal
        </h5>
        <h5 class="modal-para">
          To import students and automatically create their accounts, please download the
          <a href="#" class="modal-para-link"> Student Import Template</a> and format your student
          list to the fields provided. Once completed, click the 'Upload Student List' button below.
          <br>
          <br>
          Once the list is uploaded, every student will have an account automatically created for them.
          <strong class="modal-para-bold"> When logging in for the first time, students username will be the
            email address provided and their password will be the provided student identification number.
          </strong>
          Students will be prompted to create a custom password after logging in for the first time.
          <br>
          ‍<br>
          <em>
            *Note - Students need to be uploaded to an already created schedule. Please
            segment your spreadsheets by schedule before uploading.
          </em>
        </h5>

        <div v-if="filePresent" class="d-flex flex-row justify-content-end text-right" style="margin-top: 10px;">
          <div class="d-flex flex-column my-auto">
            {{ fileUpload.fileName }}
          </div>
          <button class="btn btn-outline-primary mx-2" @click="removeFile">
            <span class="m-left-two-px">
              <i class="fa fa-trash"/>
            </span>
          </button>
          <button class="btn btn-primary" @click="saveImport">
            <img src="https://assets.website-files.com/5d6d56640b8020729636d3ce/5d6d56640b80204eac36d41e_001-spreadsheet-ascending-order.png" width="19" alt="" class="image-4">
            <span class="m-left-two-px">Save Import</span>
          </button>
        </div>

        <div v-else class="d-flex flex-row justify-content-around" style="margin-top: 10px;">
          <button class="btn btn-primary" @click="downloadTemplate">
            <img src="https://assets.website-files.com/5d6d56640b8020729636d3ce/5d6d56640b80204eac36d41e_001-spreadsheet-ascending-order.png" width="19" alt="" class="image-4">
            <span class="m-left-two-px">Student Import Template</span>
          </button>
          <div class="file-input-wrapper">
            <button class="btn btn-primary">
              <img src="https://assets.website-files.com/5d6d56640b8020729636d3ce/5d6d56640b802048cb36d41f_002-upload.png" width="19" alt="" class="image-4">
              <span class="m-left-two-px">Upload Student List</span>
            </button>
            <input
              type="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              @change="onFileChange">
          </div>
        </div>
      </div>
    </template>
  </horizon-modal>
</template>

<script>
import HorizonModal from '@shared/horizon_modal'

export default {
  components: {
    HorizonModal
  },
  props: {
    cancel: {
      type: Function,
      required: true
    },
    scheduleId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      fileUpload: {
        file: '',
        fileName: ''
      },
      fileSizeInfo: '0 kbytes'
    }
  },
  computed: {
    filePresent () {
      return !this.isBlank(this.fileUpload.file)
    }
  },
  methods: {
    attachImage (file) {
      // const image = new Image() // eslint-disable-line no-unused-vars
      if (this.isBlank(this.fileUpload.fileName)) {
        this.fileUpload.fileName = file.name.replace(/\.[^/.]+$/, '')
      }
      const reader = new FileReader()
      const vm = this
      reader.onload = (e) => { vm.fileUpload.file = e.target.result }
      reader.readAsDataURL(file)
    },

    downloadTemplate () {
      window.location = '/downloads/student_import_sample.csv'
    },

    onFileChange (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) { return }
      this.fileSizeInfo = files[0].size/1000 + ' KB'
      this.attachImage(files[0])
    },

    removeFile (e) { // eslint-disable-line no-unused-vars
      this.resetImport()
    },

    resetImport () {
      this.fileUpload = { file: '', fileName: '' }
    },

    saveImport () {
      this.$http.post(`/api/staff/schedules/${this.scheduleId}/imports`, { import: this.fileUpload }, { emulateJSON: true }).then(() => {
        this.appendSuccess({ message: 'Created import successfully', notification: true })
        this.resetImport()
        this.cancel()
      }, (responseError) => {
        this.appendError({error: responseError.body.error, notification: true})
      })
    }
  }
}
</script>

<style scoped lang="scss">
  @import '~@styles/variables.scss';
  h5 {
    font-size: 12px;
    line-height: 150%;
  }
  .model-para-heading {
    margin-top: 0px;
    margin-bottom: 6px;
    font-family: 'Open Sans', sans-serif;
    color: #345165;
    font-weight: 700;
  }
  .model-para {
    margin-top: 0px;
    margin-bottom: 0px;
    color: #505050;
    font-weight: 400;
  }
  .file-input-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer !important;

    input[type=file] {
      font-size: 100px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
    }
  }
  .m-left-two-px {
    margin-left: 2px;
  }
</style>
