<template>
  <div class="container">
    <div class="content-pane">
      <div class="pane-wrapper">
        <breadcrumbs/>
        <div class="content-heading underlined course">
          <h1>Courses</h1>
          <create-course-modal @newCourseName="addCourse($event)"/>
          <button class="btn btn-primary float-right" @click="openCreateCourseModal" id="create-course">
            <i class="fa fa-2x fa-plus-circle text-orange mr-1 v-align-middle"/> <span class="font-14 v-align-middle"> Create New Course </span>
          </button>
        </div>
        <li v-for="course in courses" :key="course.id" class="full-width primary-row">
          <h2 class="font-12 fw-600">
            {{ course.name }}
          </h2>
          <duplicate-course-modal @setDuplicate="setDuplicate($event)"/>
          <div class="primary-row-action">
            <button class="btn btn-outline-secondary text-small" @click="openDuplicateCourseModal(course)">
              Duplicate Course
            </button>
            <button class="btn btn-outline-secondary text-small" @click="editCourse(course)">
              Edit Course
            </button>
            <button class="btn btn-outline-secondary text-small" @click="deleteCourse(course)">
              Delete Course
            </button>
          </div>
        </li>
        <common-table-pagination
          v-if="showPagination"
          :meta="meta"
          @paginate-data="paginateData"/>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from '@submodules/mixins/mixin.js'
import Breadcrumbs from '@shared/breadcrumbs'
import CommonTablePagination from '@submodules/components/common_table/pagination'
import CreateCourseModal from '@components/admin/course_activities/create_course_modal'
import DuplicateCourseModal from '@components/admin/course_activities/duplicate_course_modal'

export default {
  components: {
    Breadcrumbs,
    CommonTablePagination,
    CreateCourseModal,
    DuplicateCourseModal,
  },
  mixins: [Mixins],
  data: function () {
    return {
      courses: [],
      duplicate: {},
      showPagination: false,
      meta: {
        page: 1,
        currentPage: 1,
        perPage: 100,
        totalCount: 0,
        totalPages: 0,
        sort: null,
        order: null,
      }
    }
  },

  created () {
    this.listCourses()
  },
  methods: {
    listCourses () {
     this.$http.get('/api/admin/course_activities/templates').then((result) => {
        this.courses = result.data.courseTemplates
      })
      this.meta = []
      this.showPagination = false
    },
    newCourseFormatDAta (courseName) {
      return {
        name: courseName,
      }
    },
    addCourse: function (newCourse) {
      this.$http.post('/api/admin/course_activities/templates', this.newCourseFormatDAta(newCourse)).then((result) => {
        this.listCourses()
        this.appendSuccess({ message: 'Created Course successfully', notification: true })
      }), (responseError) => {
        this.appendError({error: responseError.body.error, notification: true})
      }
    },
    openCreateCourseModal () {
      $('#create-course-modal').modal('show')
    },
    openDuplicateCourseModal (course) {
      this.duplicateCourseFormatData(course)
      $('#duplicate-course-modal').modal('show')
    },
    duplicateCourseFormatData (course) {
      this.duplicate = {
        id: course.id,
        name: course.name,
        template: course.he_course_template_id
      }
    },
    setDuplicate(duplicate_name){
      this.duplicate.name = duplicate_name
      this.duplicateCourse(this.duplicate)
    },
    duplicateCourse (course) {
      this.$http.post('/api/admin/course_activities/templates/duplicate',course).then((result) => {
        this.listCourses()
        this.appendSuccess({message:'Duplicated Course Successfully', notification: true})
      }, (responseError) => {
        this.appendError({error: responseError, notification: true})
      })
    },
    editCourse (course) {
      this.$router.push({ name: 'AdminCourseActivities', query: { courseId : course.id, templateId: course.he_course_template_id, name: course.name }})
    },
    deleteCourse (course) {
      this.$http.delete(`/api/admin/course_activities/templates/${course.he_course_template_id}`).then(() => {
        this.appendSuccess({message: 'Deleted Course successfully.', notification: true})
        this.listCourses()
      }, (responseError) => {
        this.appendError({error: responseError, notification: true})
      })
    },
    paginateData(meta){
        this.meta.currentPage = meta.currentPage
        this.listCourses()
    },
  }
}
</script>
