import client from '@repositories/client.js'

const BASE_URL = '/api/student/student_practice_tests'

export default new class {

  // async fetchCourseProgress (course_type) {
  //   const url = course_type ? `${BASE_URL}/score_course/${course_type}` : `${BASE_URL}/scores`
  //   const data = await client.get(url)
  //   return data.scoresSummary
  // }
  
  async fetchCourseProgress (schedule_id) {
    const url = schedule_id ? `${BASE_URL}/score_course/${schedule_id}` : `${BASE_URL}/scores`
    const data = await client.get(url)
    return data.scoresSummary
  }
}
