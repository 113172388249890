<!-- DEPRECATED: Use app/javascript/packs/components/modules/enrollment/student_table.vue insted-->
<template>
  <div v-if="students" class="row">
    <a id="export-list" style="display: none;"/>
    <update-student-modal
      v-if="selectedStudent"
      :initial-enrollment="selectedStudent"
      :schedules="availableSchedules"
      :trigger-delete-modal="openConfirmDeleteModal"
      @updated="update"/>
    <create-student-modal
      v-if="createMode"
      :schedule-options="createStudentSchedule"
      :initial-schedule-id="schedule.id"
      @created="acceptEnrollment"/>
    <add-student-modal
      v-if="showAddModal"
      :schedule-options="concurrentSchedule"
      :students="selectedStudentObjects"
      :enrollment-repository="enrollmentRepository"
      :schedule-repository="scheduleRepository"
      @created="addedStudent"/>
    <delete-student-modal
      :student="selectedStudent"
      @deleted="refreshSelectedStudent"/>
    <confirm-modal
      :cancel="closeConfirmTransferModal"
      body-header="Are you sure you want to transfer the selected students to a new schedule?"
      body-paragraph="Students can be transferred to different schedules at any time."
      confirm-btn-text="Yes, Transfer Students"
      confirmed-action="transfer"
      @confirmed="delegateConfirmed"/>
    <mass-import-modal v-if="canMassImport" :cancel="closeMassImportModal" :schedule-id="Number(schedule.id)"/>
    <div class="col-12 mt-3">
      <div class="card">
        <div class="bg-white card-header">
          <div class="d-flex enrollment-function-wrapper flex-row pull-left">
            <h1 class="enrollment-function-head">
              {{ schedule.name|ellipsize(25) }}
            </h1>
          </div>
          <div class="d-flex flex-row pull-right">
            <schedule-filter
              :initial-schedule-id="schedule.id"
              :initial-students="students"
              :schedule-options="scheduleFilterOptions"
              @filtered="filtered"/>
            <button
              v-if="canMassImport"
              class="btn btn-outline-secondary enrollment-function-button"
              @click="openMassImportModal">
              Mass Import Students
            </button>
            <button :disabled="!selectedStudentObjects.length"
              class="btn btn-outline-secondary enrollment-function-button btn-create-student ml-2"
              @click="openAddStudentModal">
              Add Concurrent Enrollment
            </button>
            <button
              class="btn btn-outline-secondary enrollment-function-button ml-2"
              @click="openCreateStudentModal">
              Create Student
            </button>
            <button
              class="btn btn-outline-secondary enrollment-function-button ml-2"
              @click="exportList">
              Export List
            </button>
          </div>
        </div>
        <div class="bg-primary card-header">
          <div class="pull-right">
            Transfer Students to:
            <select
              id="schedule-select"
              v-model="selectedScheduleId"
              class="btn btn-light"
              @change="unfocusScheduleSelect">
              <option :value="null">
                Select Schedule
              </option>
              <option
                v-for="(selectSchedule, idx) in schedulesForSelect"
                :key="idx"
                :value="selectSchedule.id">
                {{ selectSchedule.name }}
              </option>
            </select>
            <button
              class="btn btn-light enrollment-function-button"
              @click="openConfirmTransferModal">
              Transfer Students
            </button>
            <mass-delete-component
              :students="selectedStudentObjects"
              @deleted="refreshSelectedStudents"/>
          </div>
        </div>
        <div>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">
                  <div class="check">
                    <label class="checkbox-field">
                      <input v-model="toggleAllSelected" type="checkbox" class="checkbox-input" @click="toggleSelectAll">
                    </label>
                  </div>
                </th>
                <th scope="col" class="cursor-hand text-left" @click="toggleSort(LAST_NAME)">
                  Last Name <i :class="sortIconClass(LAST_NAME)"/>
                </th>
                <th scope="col" class="cursor-hand text-left" @click="toggleSort(FIRST_NAME)">
                  First Name <i :class="sortIconClass(FIRST_NAME)"/>
                </th>
                <th scope="col" class="cursor-hand text-left" @click="toggleSort(SCHEDULE_NAME)">
                  Schedule <i :class="sortIconClass(SCHEDULE_NAME)"/>
                </th>
                <th scope="col" class="cursor-hand text-left" @click="toggleSort(GRADE_LEVEL)">
                  Grade <i :class="sortIconClass(GRADE_LEVEL)"/>
                </th>
                <th scope="col" class="text-left">
                  Student ID
                </th>
                <th scope="col" class="cursor-hand text-left" @click="toggleSort(SIGNUP_DATE)">
                  Signup Date <i :class="sortIconClass(SIGNUP_DATE)"/>
                </th>
                <th scope="col">
                  Options
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(student, idx) in students"
                :key="idx"
                :data-enrollment-id="student.id"
                :class="rowParityClass(idx)">
                <th>
                  <div class="check">
                    <label class="checkbox-field">
                      <input
                        :id="'student_' + student.id"
                        v-model="selectedStudents"
                        type="checkbox"
                        class="checkbox-input"
                        :value="student.id">
                    </label>
                  </div>
                </th>
                <td class="fw-600 student-last-name text-left">
                  {{ student.user.lastName }}
                </td>
                <td class="fw-600 student-first-name text-left">
                  {{ student.user.firstName }}
                </td>
                <td class="schedule-name text-left">
                  {{ student.scheduleName }}
                </td>
                <td class="student-grade-level text-left">
                  {{ student.user.gradeLevel }}th
                </td>
                <td class="student-id-number text-left">
                  {{ student.user.studentIdNumber }}
                </td>
                <td class="text-left">
                  {{ student.signupOn|dateMMDDYY }}
                </td>
                <td>
                  <button
                    type="button"
                    class="btn btn-outline-primary enrollment-button"
                    @click="selectStudent(student)">
                    Edit Info
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-primary enrollment-button"
                    @click="selectAndOpenConfirmDeleteModal(student)">
                    Delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import CreateStudentModal from './create_student_modal'
import UpdateStudentModal from './update_student_modal'
import ConfirmModal from './confirm_modal'
import DeleteStudentModal from './delete_student_modal'
import ScheduleFilter from './schedule_filter'
import MassImportModal from './mass_import_modal'
import MassDeleteComponent from './mass_delete_component'
import Mixins from '@submodules/mixins/mixin.js'
import SortHelper from './student_table_sort_helper'

export default {
  components: {
    ConfirmModal,
    UpdateStudentModal,
    CreateStudentModal,
    DeleteStudentModal,
    MassDeleteComponent,
    MassImportModal,
    ScheduleFilter
  },
  mixins: [Mixins],
  props: {
    fetchStudents: { type: Function, required: true },
    scheduleIdFilter: {
      type: [String, Number],
      required: false,
      default: ''
    },
    studentList: {
      type: Array,
      required: true
    },
    isEnrollmentTable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      editSchedules: {},
      selectedScheduleId: null,
      scheduleFilterOptions: [],
      schedulesForSelect: [],
      selectedStudents: [],
      selectedStudent: null,
      schedule: {},
      availableSchedules: [],
      conccurentSchedules: [],
      createStudentSchedule: [],
      apiPath: `/api/staff/${this.isEnrollmentTable ? 'enrollments' : 'waiting_list_entries'}`,
      mode: 'IDLE',
      showAddModal: false,
      sortOptions: {},
      enrollStudent: [],
    }
  },
  computed: {
    canMassImport: {
      get () {
        if (this.scheduleIdFilter) { return this.isEnrollmentTable && this.scheduleIdFilter }
        return false
      }
    },
    students () {
      const studentsDisplay = this.sortHelper.sort(this.studentList)
      return studentsDisplay
    },
    toggleAllSelected: {
      get () {
        return this.students.length === this.selectedStudents.length
      },
      set () {

      }
    },
    createMode () {
      return this.mode === 'CREATE'
    },
    selectedStudentObjects () {
      return this.studentList.filter(student => this.selectedStudents.includes(student.id))

    }
  },
  created () {
    this.fetchSchedules()
    this.initializeSortHelper()
  },
  methods: {
    ...mapState(['schoolYear']),
    ...mapGetters(['userSchool']),

    initializeSortHelper () {
      this.LAST_NAME = SortHelper.fields.LAST_NAME
      this.FIRST_NAME = SortHelper.fields.FIRST_NAME
      this.SCHEDULE_NAME = SortHelper.fields.SCHEDULE_NAME
      this.GRADE_LEVEL = SortHelper.fields.GRADE_LEVEL
      this.SIGNUP_DATE = SortHelper.fields.SIGNUP_DATE

      this.sortHelper = new SortHelper()
      this.sortOptions = this.sortHelper.getOptions()
    },
    exportList () {
      this.createCsvFile().then( csvFile => {
        const downloadAnchor = $('#export-list')
        downloadAnchor.attr('href', csvFile)
        const date = new Date()
        const timeStamp = `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`
        const fileName = `${this.userSchool().name}_${timeStamp}.csv`
        downloadAnchor.attr('download', fileName)
        document.getElementById('export-list').click()
      })
    },
    createCsvFile () {
      return new Promise( (resolve, reject) => {
        try {
          const students = this.students
          const rows = [['Last Name', 'First Name', 'Schedule Name', 'Grade', 'Student ID', 'Signup Date']]
          for (let i=0; i < students.length; i++) { rows.push( this.createCsvRow(students[i]) ) }
          const fileData = 'data:text/csv;charset=utf-8,' + encodeURI( rows.map(e => e.join(',')).join('\n') )
          resolve(fileData)
        } catch (error) {
          reject(error)
        }
      })
    },
    createCsvRow (student) {
      return [
        student.user.firstName,
        student.user.lastName,
        student.scheduleName,
        '11th',
        student.user.studentIdNumber,
        student.signupOn
      ]
    },
    enrollStudentList () {
      const enrolledStudent = this.enrollmentRepository.list_all()
      const currentSched = this.availableSchedules[0]
      Promise.all([enrolledStudent]).then((values) => {
        const schedStudents = values[0].filter(function(item){
          const duplicateStudent = values[0].filter(e => e.userId == item.userId)
          if (duplicateStudent.length > 1) {
            const sameSched = duplicateStudent.filter(e => e.courseType == currentSched.courseType )
            if (!sameSched.length) { return item }
          } else { return item }
        })
        this.enrollStudent = _.uniqBy(schedStudents.filter(e => e.courseType != currentSched.courseType), function(v){ return v.userId; });
        this.enrollStudent.sort((a,b) => (a.email > b.email) ? 1 : ((b.email > a.email) ? -1 : 0))
      });
    },
    delegateConfirmed (actionName) {
      if (actionName === 'transfer') {
        this.transferSelectedStudents().then(() => {
          this.closeConfirmTransferModal()
        })
      }
    },
    createStudentScheduleOptions (){
      this.createStudentSchedule = this.scheduleFilterOptions.filter(e => e.id != 0)
    },
    concurrentScheduleOptions () {
      const conSchedule = this.scheduleFilterOptions.filter(e => e.courseType != this.schedule.courseType)
      this.concurrentSchedule = conSchedule.filter(e => e.id != 0)
    },
    openMassImportModal () {
      $('#mass-import-modal').modal('show')
    },
    closeMassImportModal () {
      $('#mass-import-modal').modal('hide')
    },
    openConfirmTransferModal () {
      if (!this.selectedScheduleId) {
        return this.focusScheduleSelect()
      }
      $('#transfer-students-modal').modal('show')
    },
    closeConfirmTransferModal () {
      $('#transfer-students-modal').modal('hide')
    },
    openConfirmDeleteModal () {
      $('#delete-students-modal').modal('show')
    },
    openCreateStudentModal () {
      this.createStudentScheduleOptions()
      this.mode = 'CREATE'
      this.$nextTick(() => { $('#create-student-modal').modal('show') })
    },
    closeCreateStudentModal () {
      this.mode = 'IDLE'
      $('#create-student-modal').modal('hide')
    },
    openAddStudentModal () {
      this.enrollStudentList()
      this.concurrentScheduleOptions()
      this.showAddModal = true
      this.$nextTick(() => { $('#add-student-modal').modal('show') })
    },
    closeAddStudentModal () {
      this.showAddModal = false
      $('#add-student-modal').modal('hide')
    },
    transferSelectedStudents () {
      return new Promise((resolve) => {
        const selectedStudents = this.selectedStudents
        for (let i = 0; i < selectedStudents.length; i++) {
          this.transferStudent(selectedStudents[i])
        }
        this.selectedStudents = []
        resolve()
      })
    },
    transferStudent (studentId) {
      this.changeStudentSchedule(studentId, this.selectedScheduleId)
    },
    selectAndOpenConfirmDeleteModal (student) {
      this.asyncSelectStudent(student).then(() => {
        this.openConfirmDeleteModal()
      })
    },
    toggleSelectAll () {
      if (this.toggleAllSelected) {
        this.selectedStudents = []
      } else {
        this.selectedStudents = this.students.map(student => student.id)
      }
    },
    async selectStudent (student) {
      this.selectedStudent = student
      await this.$nextTick()
      $('#update-student-modal').modal('show')
    },
    asyncSelectStudent (student) {
      return new Promise(resolve => {
        this.selectedStudent = student
        resolve()
      })
    },
    async fetchSchedules () {
      // TODO Refactor to be more readable and intuitive
      const url = '/api/staff/schedules'
      const {data} = await this.$http.get(url)
      const schedules = data.schedules
      this.availableSchedules = schedules.slice(0) // clone list of all schedules within school

      this.__populateScheduleFilterOptions(schedules)

      this.editSchedules.schedules = schedules
      if (this.scheduleIdFilter) {
        const idx = schedules.findIndex(schedule => Number(schedule.id) === Number(this.scheduleIdFilter))
        if (idx > -1) {
          this.assignCurrentScheduleData(schedules, idx)
        }
      } else {
        this.schedule = { id: 0, name: 'Entire School' }
      }
      // assign schedules as options for transferring students
      this.schedulesForSelect = schedules
    },
    focusScheduleSelect () {
      $('#schedule-select').css('border', '2px solid red')
    },
    unfocusScheduleSelect () {
      $('#schedule-select').css('border', 'none')
    },
    assignCurrentScheduleData (schedules, idx) {
      const currentSchedule = schedules.splice(idx, 1)[0]
      this.schedule = currentSchedule
    },
    indexParams () {
      if (this.schedule.id) {
        return { params: { filters: { schedule: this.schedule.id } } }
      }
      return {}
    },
    changeStudentSchedule (studentId, scheduleId) {
      const url = `${this.apiPath}/${studentId}`
      this.$http.put(url, this.studentParams(scheduleId)).then(() => {
        this.fetchStudents()
      })
    },
    studentParams (scheduleId) {
      const params = {}
      if (this.isEnrollmentTable) {
        params.enrollment = { scheduleId }
      } else {
        params.waitingListEntry = { scheduleId }
      }
      return params
    },
    update () {
      $('#update-student-modal').modal('hide')
      // NOTE: We may need to optimize this later when  there's
      //  a significant performance decline
      this.fetchStudents()
    },
    acceptEnrollment (enrollment) {
      this.students.push(enrollment)
      this.closeCreateStudentModal()
      this.$emit('created', enrollment)
    },
    addedStudent () {
      this.closeAddStudentModal()
      this.fetchStudents()
      this.$emit('created')
    },
    refreshSelectedStudent () {
      this.fetchStudents()
      this.selectedStudent = null
    },
    refreshSelectedStudents () {
      this.fetchStudents()
      this.selectedStudents = []
    },
    replaceUserAttributes (updateParams) {
      const user = Object.assign(this.selectedStudent.user)
      user.email = updateParams.email
      user.phoneNumber = updateParams.phone
      user.studentIdNumber = updateParams.idNumber
      user.firstName = updateParams.firstName
      user.lastName = updateParams.lastName
      return user
    },
    userParams (user) {
      const params = user
      delete params.lastLoginAt
      delete params.role
      delete params.deletedAt
      delete params.activatedAt
      return params
    },
    findIndexById (id, array) {
      return array.findIndex(arrayItem => Number(arrayItem.id) === Number(id))
    },
    replaceById (newObject, array) {
      const idx = this.findIndexById(newObject.id, array)
      array.splice(idx, 1, newObject)
      return array
    },
    rowParityClass (idx) {
      return (idx % 2 === 0) ? 'even' : 'odd'
    },
    __populateScheduleFilterOptions (schedules) {
      this.scheduleFilterOptions = schedules.map( schedule => ({
        id: schedule.id,
        name: schedule.name,
        courseType: schedule.courseType
      }))

      this.scheduleFilterOptions.push({
        id: 0,
        name: 'Entire School'
      })
    },
    filtered (enrollmentList, schedule) {
      this.schedule = schedule
      this.$emit('schedule-filtered', enrollmentList)
    },
    toggleSort (field) {
      this.sortHelper.toggleSort(field)
    },
    sortIconClass (field) {
      return this.sortHelper.sortIconClass(field)
    }
  }
}
</script>

<style lang="scss" scoped>
.enrollment-function-wrapper {
  height: 37px;
}

.enrollment-function-head {
  margin-top: 0px;
  margin-bottom: 0px;
  max-width: 300px;
  color: #345165;
  font-size: 20px;
  font-weight: 700;
  margin-top: auto;
  margin-bottom: auto;
}

.enrollment-function-button {
  height: 40px;
  font-size: 12px;
}

.enrollment-button {
  padding: 4px 6px;
  border: 1px solid #cecece;
  font-size: 10px;
  line-height: 14px;
  font-weight: 400;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  &:not(:hover) {
    background-color: #ffffff;
    color:  #696969;
  }
}

.checkbox-field {
  padding-left: 19px;
  align-items: center;
  display: flex;
  margin-bottom: 0px;
}

.checkbox-input {
  width: 18px;
  height: 18px;
}

thead {
  th {
    padding-left: 7px;
    padding-right: 7px;
  }
}

tbody {
  td,th {
    padding: 7px;
    vertical-align: middle;
  }
  tr {
    &.odd {
      background-color: #f7f7f7;
    }
    &.even {
      background-color: #ffffff;
    }
  }
}
</style>
