<template>
  <div class="breadcrumbs-container">
    <div
      v-for="(breadcrumb, idx) in breadcrumbList"
      :key="idx"
      class="breadcrumb-item d-inline">
      <a :class="{'horizon-link': !!breadcrumb.routerOptions}" @click="routeTo(idx)">
        {{ breadcrumb.text }}
      </a>
    </div>
  </div>
</template>

<script>
import Mixins from '@submodules/mixins/mixin.js'

export default {
  mixins: [Mixins],
  props: {
    breadcrumbs: {
      type: Array,
      required: false,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      breadcrumbList: []
    }
  },
  watch: {
    breadcrumbs: {
      deep: true,
      handler () {
        this.updateList()
      }
    }
  },
  mounted () {
    this.updateList()
  },
  methods: {
    updateList () {
      this.breadcrumbList = this.deepCloneObject(this.$route.meta.breadcrumb)
      if (this.breadcrumbs) {
        for (const breadcrumb of this.breadcrumbs) {
          this.breadcrumbList.push(breadcrumb)
        }
      }
    },
    routeTo (pRouteTo) {
      if (this.breadcrumbList[pRouteTo].routerOptions) { this.$router.push(this.breadcrumbList[pRouteTo].routerOptions) }
    }
  }
}
</script>
