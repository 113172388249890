<template>
    <horizon-modal
        id="create-section-modal"
        modal-title="Create New Section"
    >
        <template v-slot:content>
            <div class="form" role="form">
                <div class="horizon-form-row w-100">
                    <div class="col-5 text-uppercase">
                        <label for="section-name">Name</label>
                    </div>
                    <div class="col-7">
                        <input v-model="section.name" type="text">
                    </div>
                </div>
                <div class="horizon-form-row w-100">
                    <div class="col-5 text-uppercase">
                        <label for="section-memo">Memo</label>
                    </div>
                    <div class="col-7">
                        <input v-model="section.memo" type="text">
                    </div>
                </div>
            </div>
            <div class="col-12 d-flex justify-content-end pt-2">
                <button class="btn btn-primary" @click="createSection">
                    Create Section
                </button>
            </div>
        </template>
    </horizon-modal>
</template>
<script>
import HorizonModal from '@shared/horizon_modal'
import { required } from 'vuelidate/lib/validators'

export default {
    components: {
        HorizonModal
    },
    data () {
        return {
            section: {
                name: null,
                memo: null,
            }
        }
    },
    validations: {
        section: {
            name: { required },
            memo: { required },
        },
    },
    methods: {
        createSection () {
            this.$emit('newSection', this.section)
            $('#create-section-modal').modal('hide')
            this.section.name = ''
            this.section.memo = ''
        },
    },
}
</script>