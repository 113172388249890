<template>
  <div class="pane-section-wrapper pb-3">
    <h1>Course Progress</h1>
    <div class="p-2" style="height: 300px;">
      <line-chart
        v-if="shouldRender"
        chart-id="course_progress"
        :data-set="dataSet"
        :min-score-prop="minimumScaleValue"
        :max-score-prop="maximumScaleValue"/>
    </div>
  </div>
</template>

<script>
import LineChart from '@shared/charts/line_chart'

export default {
  components: { LineChart },
  props: {
    propertyProvider: {
      type: Object,
      required: true,
      default () { return {} }
    }
  },
  computed: {
    minimumScaleValue () {
      return this.propertyProvider.minimumScaleValue()
    },
    maximumScaleValue () {
      return this.propertyProvider.maximumScaleValue()
    },
    dataSet () {
      return _.filter(_.cloneDeep(this.propertyProvider.dataSet()), 
        (dataSet) => { 
          return dataSet.y !== 0
        }) 
    },
    shouldRender () {
      return !_.isEmpty(this.propertyProvider)
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@styles/variables.scss';

@media screen and (max-width: $mobileScreenMedium) {
  h1 {
    font-size: 20px;
  }
}

@media screen and (max-width: $mobileScreenSmall) {
  h1 {
    font-size: 16px;
  }
}

@media screen and (max-width: $mobileScreenExtraSmall) {
  h1 {
    font-size: 14px;
  }

  .pane-section-wrapper {
    padding: 0;
  }
}
</style>
