import client from '@repositories/client.js'

export default class {

  constructor (schoolId) {
    this.schoolId = schoolId
  }

  async list (scheduleId, meta = {}) {
    let url = scheduleId ? `/api/admin/schedules/${scheduleId}/enrollments` : `/api/admin/schools/${this.schoolId}/enrollments`
    if (!_.isEmpty(meta)) {
      url += `?page=${meta.currentPage}&per_page=${meta.perPage}&sort=${meta.sort}&order=${meta.order}`
    }
    const data = await client.get(url)
    if (_.isEmpty(meta)) {
      return data.enrollments
    } else {
      return data
    }
  }

  async list_all (scheduleId = null) {
    let url = `/api/admin/all_enrollments/${this.schoolId}`
    const data = await client.get(url)
    return data.enrollments
  }

  async create (enrollment) {
    const scheduleId = enrollment.scheduleId
    const userId = enrollment.userId
    const data = await client.post(`/api/admin/schedules/${scheduleId}/enrollments`, { enrollment: { userId }})
    return data.enrollment
  }

  async massImport (scheduleId, file) {
    const data = await client.post(`/api/admin/schedules/${scheduleId}/student_imports`, { import: file }, { emulateJSON: true })
    return data.import
  }

  async update (id, enrollment) {
    const body = _.pick(enrollment, ['scheduleId', 'originScheduleId'])
    const data = await client.put(`/api/admin/schedules/${enrollment.scheduleId}/enrollments/${id}`, { enrollment: body })
    return data.enrollment
  }

  async delete (enrollment) {
    const data = await client.delete(`/api/admin/schedules/${enrollment.scheduleId}/enrollments/${enrollment.id}`)
    return data
  }

}
