<template>
  <div class="bar-chart-wrapper">
    <div class="bar-chart-content">
      <div
        v-for="(barCat, bIndx) in categoryListProp"
        :key="bIndx"
        class="bar-row">
        <div class="w-60">
          <div class="bar-name">
            <span>{{ barCat.subsectionArea }}</span>
          </div>
          <div class="bar-specs">
            <span>{{ barCat.correct.toFixed(2) }} of {{ barCat.total }}</span>
            <span>{{ calcWidth(barCat) }}%</span>
          </div>
        </div>
        <div class="w-40">
          <div class="bar">
            <div :style="{ width: calcWidth(barCat)+ '%', backgroundColor: getChartColor(calcWidth(barCat)) }" class="bar completed"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    categoryListProp: {
      default: () => [],
      type: Array
    },
    subsectionName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  },
  methods: {
    calcWidth (barCat) {
      if (barCat.correct == 0 && barCat.total == 0) { return 0 }
      return Math.round((barCat.correct / barCat.total) * 100)
    },
    getChartColor(num) {
      if (num !=0) { return "#2cbc3b" }
      return "#F0F0F0"
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@styles/variables';

.bar-chart-wrapper {
  padding-left: 15px;
  border-left: 1px solid #e8e8e8;
  .bar-chart-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-self: center;
    .bar-row {
      display: flex;
      align-items: center;
      padding: 6px 0;
      > div {
        display: flex;
        justify-content: space-between;
      }
      :first-child {
        padding-right: 8px;
      }
    }
    .bar {
      height: 20px;
      background-color: $themeLtGray;
      width: 100%;
      position: relative;
      border-radius: 5px;
      &.completed {
        height: 20px;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    span {
      font-size: 12px;
    }

    .bar-specs {
      display: flex;
      justify-content: space-between;
      width: 30%;
      span:last-of-type {
        text-align: right;
        font-weight: 600;
      }
    }

    .bar-row {
      .w-60 {
        width: 70%;

        .bar-name {
          width: 60%;
        }
        .bar-specs {
          width: 40%;

          span {
            margin-top: auto;
            margin-bottom: auto;
          }
        }
      }
      .w-40 {
        width: 30%;
      }
    }

    @media screen and (max-width: $mobileScreenExtraLarge) {
      .bar-row {
        .w-60 {
          width: 65%;
        }
        .w-40 {
          width: 35%;
        }
      }
    }

    @media screen and (max-width: $mobileScreenMedium) {
      .bar-row {
        display: block;

        .w-60 {
          width: 100%;

          .bar-specs {
            span {
              margin-top: auto;
              margin-bottom: 0;
            }
          }
        }

        .w-40 {
          width: 100%;
        }
      }
    }
  }
}
</style>
