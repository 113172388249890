import client from '@repositories/client.js'

export default class {

  constructor (schoolId) {
    this.schoolId = schoolId
  }

  async importQuestions (questionsImport) {
    const url = `/api/admin/schools/${this.schoolId}/schedules/${questionsImport.scheduleId}/` +
			`practice_test_dates/${questionsImport.practiceTestDateId}/question_imports`
    const data = await client.post(url, { import: questionsImport.fileUpload }, { emulateJSON: true })

    return data.import
  }

  async importResults(resultsImport) {
		const url = `/api/admin/schools/${this.schoolId}/schedules/${resultsImport.scheduleId}/` +
			`practice_test_dates/${resultsImport.practiceTestDateId}/test_result_imports`
    const data = await client.post(url, { import: resultsImport.fileUpload }, { emulateJSON: true })

    return data.import
  }
}
