<template>
  <horizon-modal
    id="create-schedule-modal"
    modal-title="Create New Schedule"
    :img-src="imageSource">
    <template v-slot:content>
      <div class="form" role="form">
        <div class="horizon-form-row w-100">
          <div class="col-5 text-uppercase">
            <label for="schedule-name">Schedule Name</label>
          </div>
          <div class="col-7">
            <input
              id="schedule-name"
              v-model="schedule.name"
              type="text"
              :class="['horizon-input', { 'highlighter-red': $v.schedule.name.$error }]">
          </div>
        </div>

        <div class="horizon-form-row w-100">
          <div class="col-5 text-uppercase">
            <label for="assessment-course-id">Assessment Course </label>
          </div>
          <div class="col-7">
            <select
              id="assessment-course-id"
              v-model="schedule.assessmentCourseId"
              :class="['form-control dropdown horizon-dropdown', { 'highlighter-red': $v.schedule.assessmentCourseId.$error }]"
              placeholder="Select Assessment Course">
              <option value="null" disabled selected>
                Select Course
              </option>
              <option
                v-for="(assessmentCourse, idx) in assessmentCourses"
                :key="idx"
                :value="assessmentCourse.id"
                class="dropdown-item text-decoration-0 text-wrap">
                {{ assessmentCourse.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="horizon-form-row w-100">
          <div class="col-5 text-uppercase">
            <label for="schedule-type">Schedule Type</label>
          </div>
          <div class="col-7">
            <select
              id="schedule-type"
              v-model="schedule.scheduleType"
              :class="['form-control dropdown horizon-dropdown', { 'highlighter-red': $v.schedule.scheduleType.$error }]"
              placeholder="Select Schedule Type">
              <option value="null" disabled selected>
                Select Schedule Type
              </option>
              <option
                v-for="(scheduleType, idx) in computedScheduleTypeOptions"
                :key="idx"
                :value="scheduleType.value"
                class="dropdown-item text-decoration-0 text-wrap">
                {{ scheduleType.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-12 d-flex justify-content-end">
        <button
          class="btn btn-primary"
          @click="navigateToCreateScheduleForm">
          Next
        </button>
      </div>
    </template>
  </horizon-modal>
</template>

<script>
import HorizonModal from '@shared/horizon_modal'
import CommonDropdown from '@shared/common_dropdown'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    HorizonModal,
    CommonDropdown
  },
  props:{
    assessment: {
      type: Object,
      required: true
    },
    schoolId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      selectedAssessmentCourse: {},
      assessmentCourses: {},
      scheduleTypeOptions: {
        default: [
          { value: 'Inside', name: 'Inside School Day' },
          { value: 'Outside', name: 'Outside School Day' }
        ],
        ACT: [
          { value: 'PREACT 9 Assessment', name: 'PREACT 9 Assessment' },
          { value: 'PREACT 10 Assessment', name: 'PREACT 10 Assessment' },
        ],
        SAT: [
          { value: 'PSAT 8/9 Assessment', name: 'PSAT 8/9 Assessment' },
          { value: 'PSAT 10 Assessment', name: 'PSAT 10 Assessment' }
        ],
        PSAT10: [
          { value: 'PSAT 10 Assessment', name: 'PSAT 10 Assessment' }
        ],
        PSAT89: [
          { value: 'PSAT 8/9 Assessment', name: 'PSAT 8/9 Assessment' }
        ]
      },
      schedule: {
        name: null,
        assessmentCourseId: null,
        scheduleType: null,
        courseType: null
      },
      imageSource: '/images/course_schedules.png'
    }
  },
  created () {
    this.fetchAssessmentCourses()
    this.setSchoolId()
  },

  computed: {
    computedScheduleTypeOptions () {
      let additionalScheduleType = this.scheduleTypeOptions[this.assessment.assessment_type]
      if (typeof additionalScheduleType !== "undefined") {
        return [].concat(this.scheduleTypeOptions.default, additionalScheduleType)
      } else {
        return [].push(...this.scheduleTypeOptions.default)
      }
    }
  },
  validations: {
    schedule: {
      name: { required },
      assessmentCourseId: { required },
      scheduleType: { required }
    }
  },
  methods: {
    fetchAssessmentCourses () {
      this.$http.get(`/api/assessment_courses?school_id=${this.schoolId}`).then(response => {
        this.assessmentCourses = response.data.assessmentCourses;
      });
    },
    setSchoolId () {
      this.schedule.schoolId = this.schoolId
    },
    navigateToCreateScheduleForm () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      const assessmentCourse = this.assessmentCourses.find(e => e.id == this.schedule.assessmentCourseId)
      this.schedule.courseType = assessmentCourse.name
      $('#create-schedule-modal').modal('hide')
      this.$router.push({ name: 'AdminSchoolScheduleCreate', query: {...this.schedule} })
    },
  }
}
</script>
