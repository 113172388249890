import { render, staticRenderFns } from "./practice_tests_form.vue?vue&type=template&id=3b6c8d20&scoped=true&"
import script from "./practice_tests_form.vue?vue&type=script&lang=js&"
export * from "./practice_tests_form.vue?vue&type=script&lang=js&"
import style0 from "./practice_tests_form.vue?vue&type=style&index=0&id=3b6c8d20&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b6c8d20",
  null
  
)

export default component.exports