<template>
  <div class="section-pane" :id="id">
    <div class="accordion-trigger flex-md-row align-items-md-center" @click="triggerSelect">
      <div class="left-pane">
        <rotating-button :isActive="selected">
        </rotating-button>
        <div class="section-text">
          <h1>{{ section.name }}</h1>
          <h3>{{ section.memo }}</h3>
        </div>
      </div>
      <div class="right-pane ml-2 ml-md-0">
        <h2>
          <strong>{{ unlockedLessonCount }}</strong>
          Lessons Unlocked
        </h2>
      </div>
    </div>
    <div class="lesson-panes" :hidden="!selected">
      <div v-for="(lesson, index) in section.lessons" :key="'lesson'+index">
        <lesson
           v-if="!lesson.is_locked"
           :id="id+'-lesson-'+lesson.id"
           :lesson="lesson"
           :docVisibility="docVisibility"
           :sectionSelected="selected">
        </lesson>
      </div>
    </div>
  </div>
</template>
<script>
import RotatingButton from '@shared/rotating_button.vue'
import Lesson from './section/lesson.vue'
export default {
  components: {
    RotatingButton,
    Lesson
  },
  props: {
    id: {
      type: String,
      default: '',
      required: true,
    },
    section: {
      type: Object,
      default() { return {} }
    },
    docVisibility: {
      type: String,
      default: '',
    }
  },
  data () {
    return {
      selected: false,
    }
  },
  computed: {
    unlockedLessonCount() {
      return this.section.lessons.filter(less => less.is_locked === false).length
    }
  },
  methods: {
    triggerSelect() {
      this.selected = this.selected == true ? false : true
    }
  }
}
</script>
<style scoped lang="scss">
@import '~@styles/variables';
.accordion-trigger {
  display: flex;
  flex-direction: column;
  min-height: 70px;
  align-items: start;
  justify-content: space-between;
  cursor: pointer;
  .left-pane {
    display: flex;
    align-items: center;
    .section-text {
      margin-left: 15px;
    }
  }
  .right-pane {
    h2 {
      width: max-content;
      font-size: 24px;
      font-weight: 400;
      strong {
        color: #f4701f;
        font-weight: 700;
      }
    }
  }
}
.section-pane {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  cursor: auto;
  border-color: #c7c7c7;
  border-style: solid;
  border-width: 1px;
  border-radius: 14px;
  padding: 15px 25px 15px 25px;
  margin: 10px 0px 10px 0px;
  background-color: #f8f8f8;
  h3 {
    font-size: 16px;
    font-weight: 400;
  }
}
</style>
