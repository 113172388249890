import client from '@repositories/client.js'

const BASE_URL = '/api/admin/course_activities/templates'

export default class {

  async list () {
    const data = await client.get(BASE_URL)
    return data.courseTemplates
  }
}
