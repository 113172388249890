<template>
  <horizon-modal
    id="send-invite-modal"
    modal-title="Share Results"
    img-src="https://assets.website-files.com/5d6d56640b8020729636d3ce/5d6d56640b80206bae36d41c_Asset%204%402x.png">
    <template v-slot:content>
      <div v-if="!showThankYou">
        <label for="invite-first-name">First Name: </label>
        <input
          id="invite-first-name"
          v-model="firstName"
          type="text"
          class="col-md-12"
          :class="{ 'highlighter-red': $v.firstName.$error }">
        <p v-show="$v.firstName.$error" class="vuelidate-message">
          * Please enter the first name of the person you'd like to share results with.
        </p>

        <label for="invite-last-name">Last Name: </label>
        <input
          id="invite-last-name"
          v-model="lastName"
          type="text"
          class="col-md-12"
          :class="{ 'highlighter-red': $v.lastName.$error }">
        <p v-show="$v.lastName.$error" class="vuelidate-message">
          * Please enter the last name of the person you'd like to share results with.
        </p>

        <label for="invite-email">Email: </label>
        <input
        id="invite-email"
          v-model="email"
          type="text"
          class="col-md-12"
          :class="{ 'highlighter-red': $v.email.$error }">
        <p v-show="$v.email.$error" class="vuelidate-message">
          * Please enter the email of the person you'd like to share results with.
        </p>
        <div class="d-flex justify-content-end mt-2">
          <slot name="buttons"/>
          <button class="btn btn-primary" style="min-width: 110px;" @click="submit">
            Send Invitation
          </button>
        </div>
      </div>
      <div v-else>
        <p>
          Thank you! Your invite has been sent!
        </p>
      </div>
    </template>
  </horizon-modal>
</template>

<script>
import {required, email} from 'vuelidate/lib/validators'
import HorizonModal from '@shared/horizon_modal'

export default {
  components: {
    HorizonModal
  },
  props: {},
  data () {
    return {
      showThankYou: false,
      firstName: '',
      lastName: '',
      email: ''
    }
  },
  validations: {
    firstName: { required },
    lastName:  { required },
    email:     { required, email }
  },
  mounted () {
    const self = this
    $('#send-invite-modal').on('hide.bs.modal', function () {
      self.resetMessage()
    })
  },
  methods: {
    submit () {
      this.$v.$touch()
      if (this.$v.$invalid) { return }
      this.$http.post('/api/student/student_invites', this.formData()).then(() => {
        this.showThankYou = true
        this.$emit('refreshList')
      }, (responseError) => {
        this.appendError({error: responseError, formData: this.formData(), notification: true})
      })
    },
    formData () {
      return {
        studentInvite: {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email
        }
      }
    },
    resetMessage () {
      this.showThankYou = false
      this.$v.$reset()
      this.firstName = ''
      this.lastName = ''
      this.email = ''
    }
  }
}
</script>

<style scoped lang='scss'>

</style>
