<template>
  <horizon-modal
    id="send-email-modal"
    modal-title="Send Email Message to Students"
    img-src="https://assets.website-files.com/5d6d56640b8020729636d3ce/5d6d56640b80206bae36d41c_Asset%204%402x.png">
    <template v-slot:content>
      <div class="flex-column form m-2 row">
        <h2 class="d-block mb-2">
          Course Schedule: {{ schedule.name }}
        </h2>
        <div v-show="!showThankYou">
          <input
            id="schedule-email-subject"
            v-model="message.subject"
            type="text"
            placeholder="Subject"
            :class="{ 'highlighter-red': $v.message.subject.$error }"
            class="mb-2">
          <textarea
            id="schedule-email-body"
            v-model="message.message"
            class="col-md-12"
            :class="{ 'highlighter-red': $v.message.message.$error }"
            placeholder="Message"
            rows="6"/>
          <div class="d-flex justify-content-end mt-2">
            <slot name="buttons"/>
            <button
              class="btn btn-primary"
              style="min-width: 110px;"
              @click="submit">
              Send
            </button>
          </div>
        </div>
        <div v-show="showThankYou">
          <p>
            Thank you! Your submission has been received!
          </p>
        </div>
      </div>
    </template>
  </horizon-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import HorizonModal from '@shared/horizon_modal'

export default {
  components: {
    HorizonModal
  },
  props: {
    schedule: {
      type: Object,
      required: true
    },
    schoolLevel: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      showThankYou: false,
      message: {
        subject: '',
        message: '',
        sms: false
      }
    }
  },
  validations: {
    message: {
      subject: {required},
      message: {required}
    }
  },
  mounted () {
    const self = this
    $('#send-email-modal').on('hide.bs.modal', function () {
      self.resetMessage()
    })
  },
  methods: {
    submit () {
      this.$v.$touch()
      if (this.$v.$invalid) { return }
      if (this.schoolLevel) {
        // Post to endpoint which sends message to entire school (all schedules in a school)
      } else {
        this.$http.post(`/api/staff/schedules/${this.schedule.id}/email_messages`, this.formData()).then(() => {
          this.showThankYou = true
        }, (responseError) => {
          this.appendError({error: responseError, formData: this.formData(), notification: true })
        })
      }
    },
    formData () {
      return {
        message: {
          sms: false,
          message: this.message.message,
          subject: this.message.subject
        }
      }
    },
    resetMessage () {
      this.$v.$reset()
      this.showThankYou = false
      this.message = {
        sms: false,
        message: '',
        subject: ''
      }
    }
  }
}
</script>

<style scoped lang='scss'>

</style>
