<template>
  <horizon-modal id="create-student-modal" modal-title="Create Student">
    <template v-slot:content>
      <div class="form">
        <div class="form-row">
          <div class="col-md-6 col-xs-12 form-group student-form-group div-1">
            <input v-model="student.firstName" type="text" class="student-form-input">
            <label class="d-block">Student First Name</label>
          </div>
          <div class="col-md-6 col-xs-12 form-group student-form-group div-2">
            <input v-model="student.lastName" type="text" class="student-form-input">
            <label>Student Last Name</label>
          </div>
        </div>
        <div class="form-row">
          <div class="col-12 form-group student-form-group">
            <input v-model="student.email" type="text" class="student-form-input">
            <label>Student Email Address</label>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-6 col-xs-12 form-group student-form-group div-1">
            <input v-model="student.phoneNumber" type="text" class="student-form-input">
            <label>Student Phone Number</label>
          </div>
          <div class="col-md-6 col-xs-12 form-group student-form-group div-2">
            <input v-model="student.studentIdNumber" type="text" class="student-form-input">
            <label>Student ID Number</label>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-6 col-xs-12 form-group student-form-group div-1">
            <select v-model="scheduleId" class="student-form-input">
              <option
                v-for="schedule in scheduleOptions"
                :key="schedule.id"
                :value="schedule.id">
                {{ schedule.name }}
              </option>
            </select>
            <label>Course Schedule</label>
          </div>
          <div class="col-md-6 col-xs-12 form-group student-form-group div-2">
            <select v-model="student.gradeLevel" class="student-form-input">
              <option
                v-for="gradeLevel in gradeLevelOptions"
                :key="gradeLevel.value"
                :value="gradeLevel.value">
                {{ gradeLevel.name }}
              </option>
            </select>
            <label>Grade Level</label>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-end">
          <button class="btn btn-primary student-modal-button" @click="submit">
            Create Student
          </button>
        </div>
      </div>
    </template>
  </horizon-modal>
</template>

<script>
import HorizonModal from '@shared/horizon_modal'
import GradeLevel from '@utils/grade_levels'

export default {
  components: {
    HorizonModal
  },
  props: {
    scheduleOptions: {
      type: Array,
      default () { return [] },
      required: true
    },
    initialScheduleId: {
      type: Number,
      default () { return null },
      required: false
    }
  },
  computed: {
    gradeLevelOptions() {
      return GradeLevel.values()
    }
  },
  data () {
    return {
      student: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        studentIdNumber: '',
        gradeLevel: GradeLevel.GRADE_9.value
      },
      scheduleId: this.initialScheduleId,
    }
  },
  methods: {
    async submit () {
      const enrollment = await this.processEnrollment(this.student, this.scheduleId)
      this.$emit('created', enrollment)
      this.$nextTick(() => this.resetData())
    },
    async processEnrollment (studentParams, scheduleId) {
      try {
        const student = await this.createStudent(studentParams)
        const enrollment = await this.enrollStudent(student, scheduleId)
        return enrollment
      } catch (error) {
        // Possibly transfer error notifications on a single plac
        this.appendError({ error: error, notification: true })
        throw error
      }
    },
    async createStudent (params) {
      const url = '/api/staff/students'
      const { data } = await this.$http.post( url, { student: params } )
      return data.student
    },
    async enrollStudent (student, scheduleId) {
      const url = '/api/staff/enrollments'
      const params = {
        enrollment: { scheduleId, userId: student.id }
      }
      const { data } = await this.$http.post(url, params)

      return data.enrollment
    },
    resetData () {
      this.student = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        studentIdNumber: '',
        gradeLevel:  GradeLevel.GRADE_9.value
      }
      this.scheduleId = this.initialScheduleId
    }
  }
}
</script>

<style scoped lang='scss'>
@import "~@styles/shared/students/student-form.scss";
</style>
