<template>
  <div class="container">
    <div class="content-pane">
      <div class="pane-wrapper">
        <breadcrumbs v-if="!isHorizonAdmin" :breadcrumbs="breadcrumbs" class="breadcrumbs" />

        <div v-if="isHorizonAdmin" class="report-wrapper">
          <div class="tabs-row">
            <div v-for="(item, idx) in tabsAdmin"
                 :key='idx'
                 class="tab"
                 :class="{ selected: item.active }"
                 @click="tabClicked(item.component, item.active)">
              <div>{{ item.name }}</div>
            </div>
          </div>
          <div class="w-tab-menu">
            <div
                v-for="(item, rIndx) in tabsAdmin"
                :key="rIndx"
                class="w-tab-menu-item"
                :class="{ selected: item.active }"
                @click="tabClicked(item.component, item.active)">
              {{ item.name }}
            </div>
          </div>
        </div>

        <div v-else class="report-wrapper">
          <div class="tabs-row">
            <div v-for="(item, idx) in tabs"
                 :key='idx'
                 class="tab"
                 :class="{ selected: item.active }"
                 @click="tabClicked(item.component, item.active)">
              <div>{{ item.name }}</div>
            </div>
          </div>
          <div class="w-tab-menu">
            <div
                v-for="(item, rIndx) in tabs"
                :key="rIndx"
                class="w-tab-menu-item"
                :class="{ selected: item.active }"
                @click="tabClicked(item.component, item.active)">
              {{ item.name }}
            </div>
          </div>
        </div>

        <div class="card score-report-container">

          <div class="action-row">
            <div
                class="btn btn-outline-secondary">
              <a @click="exportCsv">CSV Export</a>
            </div>
          </div>

          <div class="chart_container">
            <div v-for="(chartOpt, idc) in chartArray"
                 :key='idc' :class="`chart-${chartArray.length}`">
              <ag-charts-vue :options="chartOpt"/>
            </div>
          </div>

          <ag-grid-vue
              style="width: 100%; height: 800px"
              class="ag-theme-alpine"
              :columnDefs="columnDefs"
              :rowData="rowData"
              :defaultColDef="defaultColDef"
              :sideBar="sideBar"
              :enableCharts="true"
              :enableRangeSelection="true"
              @grid-ready="onGridReady"
              @first-data-rendered="onFirstDataRendered"
          >
          </ag-grid-vue>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumbs from '@shared/breadcrumbs'
import { AgGridVue } from "ag-grid-vue";
import { AgChartsVue } from 'ag-charts-vue';
import StudentResultsMixin from "@submodules/mixins/student_results.js";

export default {
  components: {
    AgGridVue,
    AgChartsVue,
    Breadcrumbs
  },
  mixins: [
    StudentResultsMixin
  ],
  props: {
    isHorizonAdmin: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      rowData: null,
      columnDefs: null,
      assessmentData: null,
      defaultColDef: {
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        sortable: true,
        filter: 'agMultiColumnFilter'
      },
      meta: null,
      gridApi: null,
      gridColumnApi: null,
      sideBar: { toolPanels: ['filters'] },

      breadcrumbs: [],
      tabs: [
        {
          active: false,
          name: "Summary",
          component: "StaffStudentScoreReport",
        },
        {
          active: false,
          name: "Subsection Details",
          component: "StaffStudentScoreReportSubsectionDetails"
        },
        {
          active: true,
          name: "Advanced Reporting (Beta)",
          component: "StaffAdvancedReporting",
        }
      ],
      tabsAdmin: [
        {
          active: false,
          name: "Summary",
          component: "AdminStudentScoreReport",
        },
        {
          active: false,
          name: "Subsection Details",
          component: "AdminStudentScoreReportSubsectionDetails",
        },
        {
          active: true,
          name: "Advanced Reporting (Beta)",
          component: "StaffAdvancedReporting",
        }
      ],
      chartArray: [],
    }
  },
  async mounted() {
    await this.loadAdvancedReport()
    this.setUpColumns()
  },
  methods: {
    // AG Grid callbacks
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    onFirstDataRendered() {
      this.chartArray = [];
      var assessments = ["assessment_1", "assessment_2", "assessment_3"]
      let available_assessments = assessments.filter( (a) => this.checkAssessmentGroupExists(a));

      // create chart for available assessments
      available_assessments.forEach( (assessment, i) => {
        this.createHistChart(assessment, `${this.meta.assessment_type.toUpperCase()} #${i+1} Composite Score`)
      })

    },

    async loadAdvancedReport() {
      await this.loadScheduleAndAssessment()

      var url = ""
      if (this.activeSchedule.id == 0) {
        url = `/api/staff/school/advanced_reports?practice_test_set_id=${this.selectedAssessmentId}`
      } else {
        url = `/api/staff/school/advanced_reports?practice_test_date_id=${this.selectedAssessmentId}`
      }

      const response = await this.$http.get(url)
      this.assessmentData = response.data.data
      this.gridApi.setRowData(this.assessmentData)
      this.meta = response.data.meta
    },

    setUpColumns() {
      var defs = [
        { width: 140, field: 'last_name', pinned: 'left', headerName: "Last Name" },
        { width: 140, field: 'first_name', pinned: 'left', headerName: "First Name" },
        { width: 140, field: 'student_id_number', headerName: "Student ID" },
        { width: 90, field: 'grade_level', headerName: "Grade" },
        { width: 120, field: 'schedule', headerName: "Schedule" }
      ];

      var assessments = ["assessment_1", "assessment_2", "assessment_3"]
      assessments.forEach( (assessment, i) => {
        if (this.checkAssessmentGroupExists(assessment)) {
          defs.push(
              this.generateAssessmentColumns(
                  `${this.meta.assessment_type} #${i+1}`.toUpperCase(),
                  assessment,
                  this.meta.keys)
          )
        }
      })

      // generate comparisons
      var comparisonKeys = this.meta.keys.filter( (key) => key.search("score") > 0 )
      if (this.checkAssessmentGroupExists("assessment_1") && this.checkAssessmentGroupExists("assessment_2")) {
        defs.push(this.generateComparisons(`${this.meta.assessment_type.toUpperCase()} #1 to #2 Comparison`, "assessment_1", "assessment_2", comparisonKeys))
      }

      if (this.checkAssessmentGroupExists("assessment_2") && this.checkAssessmentGroupExists("assessment_3")) {
        defs.push(this.generateComparisons(`${this.meta.assessment_type.toUpperCase()} #2 to #3 Comparison`, "assessment_2", "assessment_3", comparisonKeys))
      }

      if (this.checkAssessmentGroupExists("assessment_1") && this.checkAssessmentGroupExists("assessment_3")) {
        defs.push(this.generateComparisons(`${this.meta.assessment_type.toUpperCase()} #1 to #3 Comparison`, "assessment_1", "assessment_3", comparisonKeys))
      }

      this.gridApi.setColumnDefs(defs)
    },

    generateAssessmentColumns(title, assessment, keys) {
      let children = keys.map( (key) => {
        let isStatusField = key.search("status") > 0

        if (isStatusField) {
          return {
            field: `${assessment}.${key}`,
            headerName: this.humanize(key),
            width: 120,
            columnGroupShow: "open",
            filter: null,
            valueFormatter: (params) => {
              if (params.value  == "completed") {
                return  "Completed"
              } else {
                return "Not Started"
              }
            }
          }
        } else {
          return {
            field: `${assessment}.${key}`,
            headerName: this.humanize(key),
            width: 95,
            filterParams: {
              filters: [
                { filter: 'agNumberColumnFilter' },
                { filter: 'agSetColumnFilter' },
              ],
            },
            chartDataType: 'series',
            valueFormatter: (params) => {
              if (params.value == null) {
                return "n/a"
              } else {
                return params.value
              }
            },
            valueGetter: (params) => {
              var val = null
              if (params.data.hasOwnProperty(assessment)) {
                val = params.data[assessment][key]
              }

              return val
            },
            cellClass: params => this.checkIfNotZero(params.value)  ? 'default-font' : 'blank-font'
          }
        }

      })

      return {
        headerName: title,
        children: children
      }
    },

    checkAssessmentGroupExists(key) {
      var ret = false
      this.assessmentData.some( (row) => {
        if (row.hasOwnProperty(key)) {
          ret = true
          return true
        }
      })

      return ret
    },

    generateComparisons(title, set1, set2, keys) {
      var comparisons = {};
      comparisons["headerName"] = title
      comparisons["children"] = [];

      let fields = keys

      fields.forEach( (field) => {
        comparisons["children"].push({
          headerName: this.humanize(field),
          width: 95,
          sortable: true,
          resizable: true,
          colId: `${title}_${field}`,
          filterParams: {
            filters: [
              { filter: 'agNumberColumnFilter' },
              { filter: 'agSetColumnFilter' },
            ],
          },
          valueGetter: (params) => {
            if (!(params.data.hasOwnProperty(set1) && params.data.hasOwnProperty(set2)) || (params.data[set1][field] == "" || params.data[set2][field] == "")) {
              return "n/a"
            } else {
              return params.data[set2][field] - params.data[set1][field]
            }
          },
          valueFormatter: (params) => {
            if (params.value > 0) {
              return "+"+params.value
            } else {
              return params.value
            }
          },
          cellClass: params => this.getComparisonCellClass(params),
        })
      })

      return comparisons
    },

    exportCsv() {
      this.gridApi.exportDataAsCsv({
        allColumns: true, // export even hidden columns
      });
    },

    createHistChart(assessmentProp, title){

      // filter out 0 scores and save them as objects in the scores array
      const scores  = this.assessmentData
          .filter(student => this.checkIfNotZero(student[assessmentProp]?.composite_score))
          .map(student => ({ compositeScore: student[assessmentProp].composite_score }));

      const chartOptions = {
        title: {
          text: title,
        },
        data: scores,
        series: [
          {
            bins: this.meta.segments,
            type: 'histogram',
            xKey: 'compositeScore',
            xName: 'Composite Score',
            tooltip: {
              renderer: (params) => {
                var bin = params.datum;
                var binSize = bin.frequency;
                return {
                  content: `Students: ${binSize}`
                };
              },
            },
          },
        ],
        legend: {
          enabled: false,
        },
      }
      this.chartArray.push(chartOptions)
    },

    tabClicked(component, active) {
      if (!active) {
        var params = {}
        if (this.activeSchedule.id == 0) {
          params = {
            assessmentId: this.selectedAssessmentId,
            scheduleId: this.activeSchedule.id
          }
        } else {
          params = {
            assessmentId: this.activePracticeTestDate.practiceTestSetId,
            scheduleId: this.activeSchedule.id
          }
        }

        if (this.isHorizonAdmin) {
          params.assessmentId = this.$route.params.assessmentId
          params.scheduleId = this.$route.params.scheduleId
        }
        this.$router.push({
          name: component,
          params: params
        })
      }
    },

    humanize(key) {
      return key.split("_").map( (word) => word.[0].toUpperCase() + word.substring(1) ).join(" ")
    },

    getComparisonCellClass(params) {
      if (params.value == "n/a") {
        return "blank-font"
      } else if (params.value < 0) {
        return "negative-font"
      } else if (params.value > 0) {
        return "positive-font"
      } else {
        return "default-font"
      }
    },

    checkIfNotZero(value){
      return !(value === undefined || value === null || value === 0)
    }

  }
}

</script>

<style lang="scss">
@use "~ag-grid-community/styles" as ag;
@include ag.grid-styles((
    theme: alpine,
    --ag-odd-row-background-color: #f9f9f9
));

.ag-theme-alpine {
  --ag-borders: none;
  --ag-font-size: 12px;
  --ag-font-family: "Open Sans", sans-serif;
}

.ag-theme-alpine .ag-header-cell {
  font-size: 12px;
  font-weight: bold;
}

.ag-header-group-cell, .ag-header-group-cell:not(.ag-column-resizing) + .ag-header-group-cell:not(.ag-column-hover):not(.ag-header-cell-moving):hover, .ag-header-group-cell:not(.ag-column-resizing) + .ag-header-group-cell:not(.ag-column-hover).ag-column-resizing {
  background-color: #345165;
  color: #fff;
}

.ag-header-group-cell .ag-header-icon {
  color: #fff;
}

.tabs-row {
  padding: 0px 1px;
}

.w-tab-menu {
  display: none;
}

.default-font {
  color: --ag-foreground-color;
  text-align: center;
}

.blank-font {
  color: #b3b7bb;
  text-align: center;
}

.positive-font {
  color: green;
  text-align: center;
}

.negative-font {
  color: red;
  text-align: center;
}

.chart_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chart-1 {
  width: 80%;
}

.chart-2 {
  width: 45%;
}

.chart-3 {
  width: 32%;
}

.action-row {
  margin: 10px;
  display: flex;
  flex-flow: row-reverse;
}
</style>