<template>
  <div>
    <table class="table table-primary w-100">
      <thead>
        <tr>
          <th class="flex-fill h2 text-align-left text-white">
            Assessment
          </th>
          <th class="h2 text-align-left text-white" scope="col">
            Date
          </th>
          <th class="h2 text-align-center text-white" scope="col">
            Start Time
          </th>
          <th class="h2 text-align-center text-white" scope="col">
            End Time
          </th>
          <th class="h2 text-align-center text-white" scope="col">
            Assessment Group
          </th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        <template v-for="(practiceTestDate, idx) in practiceTestDates">
          <tr :key="idx">
            <td class="fw-600">
              Practice Assessment #{{ idx + 1 }}
            </td>
            <td class="fw-400">
              <date-picker
                v-model="practiceTestDate.meetingOn"
                :config="datePickerConfig"
                :class="['practice-test-meeting-on', 'horizon-input', 'text-left', { 'highlighter-red': errors[idx].meetingOn.$error }]"/>
            </td>
            <td class="fw-400 text-align-center">
              <date-picker
                v-model="practiceTestDate.startAt"
                :config="timePickerConfig"
                :class="['practice-test-start-at', 'horizon-input', 'text-center', { 'highlighter-red': errors[idx].startAt.$error }]"/>
            </td>
            <td class="fw-400 text-align-center">
              <date-picker
                v-model="practiceTestDate.endAt"
                :config="timePickerConfig"
                :class="['practice-test-end-at', 'horizon-input', 'text-center', { 'highlighter-red': errors[idx].endAt.$error }]"/>
            </td>
            <td>
                <common-dropdown
                  :items="getDropdownPracticeTestSets(idx)"
                  :item-display-key="'name'"
                  :on-item-select="updatePracticeTestSet"
                  dropdown-class="d-flex dropdown horizon-input justify-content-between no-hover"
                  >
                  <span class="font-12 text-truncate">
                    {{practiceTestDate.practiceTestSetName}}
                  </span>
                </common-dropdown>
            </td>
            <td>
              <button
                type="button"
                class="btn btn-lg btn-outline-primary"
                @click="removeScheduleAt(idx)">
                <i class="fa fa-2x fa-trash"/>
              </button>
            </td>
          </tr>
        </template>
      </tbody>

      <tfoot>
        <tr>
          <th class="text-right" colspan="6">
            <button
              id="add-practice-test-date-button"
              class="btn btn-outline-secondary gray-hover"
              @click="showAddPracticeTestDateModal()">
              <i class="fa fa-lg fa-plus-circle"/><span class="font-12"> Add Assessment</span>
            </button>
          </th>
        </tr>
      </tfoot>
    </table>
    <practice-test-date-modal
      :id="modal.id"
      :schedule-type="modal.type"
      :selectedScheduleDateProp={}
      :practiceTestSets="practiceTestSets"
      @scheduleDateUpdated="addPracticeTestDate"
      @scheduleDateAdded="addPracticeTestDate"
    />
  </div>
</template>

<script>
import DatePicker from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import CommonDropdown from '@shared/common_dropdown'
import PracticeTestDateModal from "@components/modules/schedule/edit/practice_test_date_modal"

export default {
  components: {
    DatePicker,
    CommonDropdown,
    PracticeTestDateModal
  },
  props: {
    practiceTestDates: {
      type: Array,
      required: true,
      default() { return [] }
    },
    errors: {
      type: Object,
      required: false,
      default() { return []; }
    },
    scheduleProp: {
      type: Object,
      required: true
    },
    courseType: {
      type: String,
      required: true,
      default() { return ''; }
    },
  },
  data() {
    return {
      datePickerConfig: {
        dateFormat: 'l, F j, Y',
      },
      timePickerConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'h:i K',
        time_24hr: false,
        static: true
      },
      oelectedCourseType: '',
      assessmentTypes: {},
      practiceTestSets: [],
      modal: {
        id: "practice-assessment-date-modal",
        type: "Practice Assessment"
      },
    }
  },
  methods: {
    getDropdownPracticeTestSets(idx) {
      let sets = JSON.parse(JSON.stringify(this.practiceTestSets));
      for (let i=0; i<sets.length; i++) {
        sets[i].idx = idx
      }
      return sets
    },
    showAddPracticeTestDateModal() {
      $(`#${this.modal.id}`).modal("show");
    },
    appendNewTestDateRow(test_date) {
      const set = this.practiceTestSets.find(e => e.id == test_date.practiceTestSetId)
      this.practiceTestDates.push(
        {
          meetingOn: new Date(test_date.meetingOn),
          startAt: test_date.startAt,
          endAt: test_date.endAt,
          practiceTestSetId: test_date.practiceTestSetId,
          practiceTestSetName: set.name
        }
      )
    },
    removeScheduleAt(index) {
      this.practiceTestDates.splice(index, 1)
    },
    updatePracticeTestSet(value) {
      this.practiceTestDates[value.idx].practiceTestSetId = value.id
      this.practiceTestDates[value.idx].practiceTestSetName = value.name
    },
    addPracticeTestDate(practiceTestDate) {
      this.appendNewTestDateRow(practiceTestDate);
    },
    getPracticeTestSets() {
      const path = `/api/assessment_courses/${this.scheduleProp.assessmentCourseId}/practice_test_sets`
            this.$http.get(path).then(response => {
              this.practiceTestSets = response.data.practiceTestSets;
            });
    },
    initPracticeTestSets() {
      this.selectedCourseType = this.courseType;
      const path = '/api/resource_lists/assessments';
      this.$http.get(path).then(response => {
        this.assessmentTypes = response.data.assessments;
        this.getPracticeTestSets();
      });
    }
  },
  mounted() {
    this.initPracticeTestSets()
    this.$root.$on('assessmentCourseUpdated', data => {
      this.selectedCourseType = data;
      this.getPracticeTestSets();
    });
  }
}
</script>

<style lang="scss" scoped>
@import '~@styles/modules/schedule/schedule_dates_table.scss';
</style>
