<template>
  <table class="practice-test-dates table table-primary w-100">
    <thead>
      <tr>
        <th class="flex-fill h2 text-align-left text-white">
          Assessment
        </th>
        <th class="h2 text-align-left text-white date-time-separated" scope="col">
          Date
        </th>
        <th class="h2 text-align-center text-white date-time-separated" scope="col">
          Start Time
        </th>
        <th class="h2 text-align-center text-white date-time-separated" scope="col">
          End Time
        </th>
        <th class="h2 text-align-center text-white date-time-combined" scope="col">
          Date & Time
        </th>
      </tr>
    </thead>
    <tbody>
      <template v-for="(practiceAssessmentDate, index) in practiceAssessmentDates">
        <tr :key="index">
          <td class="fw-600">
            {{ practiceAssessmentDate.name }}
          </td>
          <td class="fw-400 date-time-separated">
            {{ practiceAssessmentDate.meetingOn| date('dddd, MMMM D, YYYY') }}
          </td>
          <td class="fw-400 text-align-center date-time-separated">
            {{ practiceAssessmentDate.startAt }}
          </td>
          <td class="fw-400 text-align-center date-time-separated">
            {{ practiceAssessmentDate.endAt }}
          </td>
          <td class="text-align-center date-time-combined">
            {{ practiceAssessmentDate.meetingOn| date('dddd, MMMM D, YYYY') }}<br />
            {{ practiceAssessmentDate.startAt }} - {{ practiceAssessmentDate.endAt }}
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    schedule: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    }
  },
  computed: {
    practiceAssessmentDates () {
      return this.schedule.practiceTestDates
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@styles/modules/schedule/schedule_dates_table.scss";
</style>
