import client from '@repositories/client.js'

const BASE_URL = '/api/staff/enrollments'

export default new class {

  async list (scheduleId = null, meta = {}) {
    let url = BASE_URL
    if (scheduleId) {
      url += `?filters[schedule]=${scheduleId}`
    }
    if (!_.isEmpty(meta)) {
      url += scheduleId ? `&` : '?'
      url += `page=${meta.currentPage}&per_page=${meta.perPage}&sort=${meta.sort}&order=${meta.order}`
    }
    const data = await client.get(url)
    if (_.isEmpty(meta)) {
      return data.enrollments
    } else {
      return data
    }
  }

  async list_all (scheduleId = null) {
    let url = '/api/staff/all_enrollments'
    const data = await client.get(url)
    return data.enrollments
  }

  async create (enrollment) {
    const body = _.pick(enrollment, ['scheduleId', 'userId'])
    const data = await client.post(BASE_URL, { enrollment: body })
    return data.enrollment
  }

  async massImport(scheduleId, file) {
    const data = await client.post(`/api/staff/schedules/${scheduleId}/imports`, { import: file }, { emulateJSON: true })
    return data.import
  }

  async update (id, enrollment) {
    const body = _.pick(enrollment, ['scheduleId', 'originScheduleId'])
    const data = await client.put(`${BASE_URL}/${id}`, {enrollment: body})
    return data.enrollment
  }

  async delete (enrollment) {
    const data = await client.delete(`${BASE_URL}/${enrollment.id}`)
    return data
  }

}
