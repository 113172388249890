<template>
  <div class="container">
    <div class="content-pane">
      <div class="pane-wrapper">
        <breadcrumbs :breadcrumbs="breadcrumbs" class="breadcrumbs" />
        <staff-student-results-pane v-if="satCourseEnabled"/>
        <br>
        <staff-psat-8-9-student-results-pane v-if="psat89CourseEnabled"/>
        <br>
        <staff-psat-10-student-results-pane v-if="psat10CourseEnabled"/>
        <br>
        <staff-act-student-results-pane v-if="actCourseEnabled"/>
        <br>
        <staff-pre-act-9-student-results-pane v-if="preact9CourseEnabled"/>
        <br>
        <staff-pre-act-10-student-results-pane v-if="preact10CourseEnabled"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumbs from '@shared/breadcrumbs'
import Mixins from '@submodules/mixins/mixin.js'
import StaffStudentResultsPane from '@staff/shared/student_results_pane'
import StaffPsat89StudentResultsPane from '@staff/shared/psat_8_9_student_results_pane'
import StaffPsat10StudentResultsPane from '@staff/shared/psat_10_student_results_pane'
import StaffActStudentResultsPane from '@staff/shared/act_student_results_pane'
import StaffPreAct9StudentResultsPane from '@staff/shared/preact9_student_results_pane'
import StaffPreAct10StudentResultsPane from '@staff/shared/preact10_student_results_pane'


export default {
  components: {
    Breadcrumbs,
    StaffStudentResultsPane,
    StaffPsat89StudentResultsPane,
    StaffPsat10StudentResultsPane,
    StaffActStudentResultsPane,
    StaffPreAct9StudentResultsPane,
    StaffPreAct10StudentResultsPane
  },
  mixins: [Mixins],
  data () {
    return {
      breadcrumbs: []
    }
  },
  methods: {
    ...mapGetters(['userSchool']),
  },
  computed: {
    satCourseEnabled() {
      return this.userSchool().availableCourses.includes('sat')
    },
    psat89CourseEnabled() {
      return this.userSchool().availableCourses.includes('psat89')
    },
    psat10CourseEnabled() {
      return this.userSchool().availableCourses.includes('psat10')
    },
    actCourseEnabled() {
      return this.userSchool().availableCourses.includes('act')
    },
    preact9CourseEnabled() {
      return this.userSchool().availableCourses.includes('preact9')
    },
    preact10CourseEnabled() {
      return this.userSchool().availableCourses.includes('preact10')
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@styles/variables';

@media screen and (max-width: $mobileScreenMedium) {
  .breadcrumbs {
    display: none;
  }
}

@media screen and (max-width: $mobileScreenSmall) {
  .container {
    padding: 0px;
    height: 100%;

    .content-pane {
      border-radius: 0px;
      height: 100%;

      .pane-wrapper {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
}
</style>
