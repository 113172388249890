<template>
  <div>
    <div class="section-header">
      <h1>Detailed Score Breakdown</h1>
    </div>
    <div class="no-gutters row score-breakdown-row">
      <div v-for="(scoreDetail, scoreDetailIndx) in scoreBreakdownDetails" :key="scoreDetailIndx" class="col-md-6">
        <div class="subject-wrapper">
          <div class="d-flex pb-1">
            <h2>{{ scoreDetail.name }}</h2>
            <h2 class="pl-2 text-orange">
              {{ scoreDetail.score }}
            </h2>
          </div>
          <div class="bar-chart-wrapper">
            <div class="bar-chart-content">
              <div v-for="(section, bIndx) in scoreDetail.subsections" :key="bIndx" class="section-container">
                <div class="section-first-level">
                  <div class="d-flex justify-content-between pr-2 w-60">
                    <div class="section-name">
                      <span>{{ section.name }}</span>
                    </div>
                    <div class="bar-specs">
                      <span>{{ section.correctCount }} of {{ section.totalCount }}</span>
                      <span>{{ calcWidth(section) }}%</span>
                    </div>
                  </div>
                  <div class="w-40">
                    <div class="bar">
                      <div :style="{ width: calcWidth(section)+ '%', backgroundColor: getCategoryColor(scoreDetail.name) }" class="bar completed"/>
                    </div>
                  </div>
                </div>
                <!-- <div v-for="(topic, topicIndx) in section.topics" :key="topicIndx" class="section-second-level">
                  <div class="d-flex justify-content-between pr-2 w-60">
                    <span class="pl-3">{{ topic.name }}</span>
                    <div class="bar-specs">
                      <span>{{ topic.correctCount }} of {{ topic.totalCount }}</span>
                      <span>{{ topic.correctPercent }}%</span>
                    </div>
                  </div>
                  <div class="w-40">
                    <div class="bar">
                      <div :style="{ width: topic.correctPercent+'%', backgroundColor: getCategoryColor(scoreDetail.name) }" class="bar completed"/>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    practiceTestId: { type: [Number, String], required: true },
    requestUrlBaseProp: { type: String, required: true }
  },
  data () {
    return {
      scoreBreakdownDetails: []
    }
  },
  watch: {
    practiceTestId: {
      immediate: true,
      handler () {
        this.getDetailedScoreData()
      }
    }
  },
  methods: {
    calcWidth (section) {
      if (section.correctCount == 0 && section.totalCount == 0) { return 0 }
      return Math.round((section.correctCount / section.totalCount) * 100)
    },
    getDetailedScoreData () {
      this.$http.get(`${this.requestUrlBaseProp}/score_breakdown_details`).then((response) => {
        this.scoreBreakdownDetails = response.data.scoreBreakdownDetails
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@styles/variables';

.bar-chart-wrapper {
  .bar-chart-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-self: center;
    .section-first-level, .section-second-level {
      display: flex;
      padding: 6px 0;
    }
    .bar {
      height: 20px;
      background-color: $themeLtGray;
      width: 100%;
      position: relative;
      border-radius: 5px;
      &.completed {
        height: 20px;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    span {
      font-size: 12px;
    }
    .section-name {
      width: 70%;
      padding-right: 8px;
    }
    .bar-specs {
      display: flex;
      justify-content: space-between;
      width: 30%;
      span:last-of-type {
        text-align: right;
        font-weight: 600;
      }
    }
  }
}
.score-breakdown-row {
  padding: 20px 0px 10px 20px;
  display: flex;
  .col-md-6 {
    padding-bottom: 1rem;
  }
  > .col-md-6:nth-child(odd) {
    padding-right: 12px;
  }
  > .col-md-6:nth-child(even) {
    padding-left: 12px;
    border-left: 1px solid #e8e8e8;
  }
}
.score-detail-row {
  margin: 10px 0;
  display: flex;
}

@media screen and (max-width: $mobileScreenLarge) {
  .score-breakdown-row {
    display: block;
    padding-right: 20px;

    .col-md-6 {
      max-width: 100%;

      .w-60 {
        width: 100%;
      }
    }
    .col-md-6:nth-child(odd) {
      padding-right: 0px;
    }
    .col-md-6:nth-child(even) {
      padding-left: 0px;
      border-left: 0;
    }
  }
}

@media screen and (max-width: $mobileScreenSmall) {
  .score-breakdown-row {
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media screen and (max-width: $mobileScreenExtraSmall) {
  .section-header {
    h1 {
      font-size: 18px !important;
    }
  }
  .score-breakdown-row {
    .section-container {
      .w-60 {
        width: 75%;
      }
      .w-40 {
        width: 25%;
      }
    }
  }
}
</style>
