<template>
  <div class="lesson-pane" :id="id">
    <div class="accordion-trigger flex-md-row">
      <div class="left-pane" @click.prevent="triggerSelect">
        <img v-if="dragging" src="/images/drag.png" class="drag-icon">
        <rotating-button :isActive="selected">
        </rotating-button>
        <div class="section-text">
          <h1>{{ lesson.name }}</h1>
          <h3 v-show="!editing">{{ lesson.memo }}</h3>
          <editable-text
            v-show="editing"
            :text-content="lesson.memo"
            :header-one="false"
            :identity="buildIdentity('memo')"
            @update="editableTextUpdate"></editable-text>
        </div>
      </div>
      <div class="right-pane d-flex mt-3 mt-md-0">
        <a class="btn btn-outline" @click="showEditLesson">
          <img class="btn-icon" src="/images/pencil.png">
          <div class="header-btn-text d-none d-md-block">Edit Lesson</div>
        </a>
        <a class="btn btn-outline" @click="deleteLesson(lesson)">
          <img class="btn-icon" src="/images/trashcan.png">
          <div class="header-btn-text d-none d-md-block">Delete Lesson</div>
        </a>
      </div>
    </div>
    <div class="lessons" :hidden="!selected">
      <text-detail
        v-if="hasTimeDetail()"
        :independent-practice-time="lesson.independent_practice_time"
        :instructional-time="lesson.instructional_time"
        :id="id+'-text-detail'"
        >
      </text-detail>
      <text-button-detail
        v-if="hasIndependentLessonActivity()"
        :id="id+'-independent-lesson'"
        :main_text="'Independent Lesson'"
        :btn_a="{text:'View Lesson',i_path:'/images/open-book.png'}"
        :btn_a_fn="launchIndependentLessonPreview"
        >
      </text-button-detail>
      <text-button-detail
        v-if="hasPracticeSetActivity()"
        :id="id+'-practice-set'"
        :main_text="'Practice Set'"
        :btn_a="{text:'View Practice Set',i_path:'/images/open-book.png'}"
        :btn_a_fn="launchPracticeSetPreview"
        >
      </text-button-detail>
      <div v-if="selected">
        <div v-for="(material, propName) in lesson.support_materials">
          <text-media-detail
            v-if="material.hasOwnProperty('url') && material.url"
            :id="propName+'-media-detail'"
            :main_text="material.name"
            :media_src="material.url"
            >
          </text-media-detail>
        </div>
      </div>
    </div>
    <activity-progress-modal
      :id="id+'-activity-progress-modal'"
    />
    <item-analysis-modal
      :id="id+'-item-analysis-modal'"
    />
  </div>
</template>
<script>
import RotatingButton from '@shared/rotating_button.vue'
import TextDetail from './details/text_detail.vue'
import TextButtonDetail from './details/text_button_detail.vue'
import TextMediaDetail from './details/text_media_detail.vue'
import ActivityProgressModal from './modals/activity_progress_modal.vue'
import ItemAnalysisModal from './modals/item_analysis_modal.vue'
import EditableText from '../show/editable_text.vue'

export default {
  components: {
    RotatingButton,
    TextDetail,
    TextButtonDetail,
    TextMediaDetail,
    ActivityProgressModal,
    ItemAnalysisModal,
    EditableText,
  },
  props: {
    id: {
      type: String,
      default: '',
      required: true,
    },
    section_id: {
      type: Number,
      default: null
    },
    section_order: {
      type: Number,
      default: null
    },
    lesson: {
      type: Object,
      default() { return {} }
    },
    editing: {
      type: Boolean,
      default: false
    },
    dragging: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      selected: false,
    }
  },
  methods: {
    buildIdentity(target) {
      return {
        'section_id': this.section_id,
        'section_order': this.section_order,
        'lesson_id': this.lesson.id,
        'lesson_order': this.lesson.order,
        'target': target
      }
    },
    editableTextUpdate(identity, text) {
      this.$emit('edit-text-update', identity, text)
    },
    triggerSelect() {
      this.selected = this.selected == true ? false : true
    },
    showActivityProgressModal() {
      $('#' + this.id + '-activity-progress-modal').modal('show')
    },
    showItemAnalysisModal() {
      $('#' + this.id + '-item-analysis-modal').modal('show')
    },
    showEditLesson(){
      this.formatEditLesson(this.lesson)
      this.$emit('get-lesson', this.lesson)
      $('#edit-lesson-modal').modal('show')
    },
    formatEditLesson(lesson){
      this.lesson.practice_set = lesson.learnosity_activity_banks.practice_set
      this.lesson.independent_lesson = lesson.learnosity_activity_banks.independent_lesson
      this.lesson.slide_presentation_link = lesson.support_materials.google.url
      this.lesson.support_video_link = lesson.support_materials.support_videos.url
    },
    formatUpdateLesson (lesson) {
      var support_materials = {
        google: {
          url: lesson.slide_presentation_link,
          name: 'Google Slides Presentation'
        },
        support_videos: {
          url: lesson.support_video_link,
          name: 'Support Video'
        }
      }
      var activity_banks = {
        independent_lesson: lesson.independent_lesson,
        practice_set: lesson.practice_set,
      }
      return {
        course_lesson_id: lesson.id,
        lesson_name: lesson.name,
        lesson_memo: lesson.memo,
        order: lesson.order,
        instructional_time: lesson.instructional_time,
        independent_practice_time: lesson.independent_practice_time,
        learnosity_activity_banks: activity_banks,
        support_materials: support_materials,
      }
    },
    editLesson(lesson){
      this.$http.put(`/api/admin/course_activities/${lesson.he_course_section_id}/lessons`,this.formatUpdateLesson(lesson)).then(() => {
        this.appendSuccess({message: 'Updated Lesson', notification: true})
      }, (responseError) => {
        this.appendError({error: responseError, notification: true})
      })
    },
    deleteLesson(lesson) {
      this.$http.delete(`/api/admin/course_activities/${lesson.he_course_section_id}/lessons/${lesson.id}`).then(() => {
        this.appendSuccess({message: 'Deleted Lesson', notification: true})
        this.$emit('lesson-update')
      }, (responseError) => {
        this.appendError({error: responseError, notification: true})
      })
    },
    launchIndependentLessonPreview() {
      window.open(`/course_activities/preview/0/0/0/${this.lesson.learnosity_activity_banks.independent_lesson}`)
    },
    launchPracticeSetPreview() {
      window.open(`/course_activities/preview/0/0/0/${this.lesson.learnosity_activity_banks.practice_set}`)
    },
    hasPracticeSetActivity() {
      return !!this.lesson.learnosity_activity_banks.practice_set
    },
    hasIndependentLessonActivity() {
      return !!this.lesson.learnosity_activity_banks.independent_lesson
    },
    hasTimeDetail() {
      return this.lesson.independent_practice_time || this.lesson.instructional_time
    }
  },
}
</script>
<style scoped lang="scss">
@import '~@styles/variables';
.lessons {
  width: 100%;
  margin-top: 9px;
  padding: 15px 65px 20px 65px;
  border-top: 1px solid #d8d8d8;
}

.accordion-trigger {
  display: flex;
  flex-direction: column;
  min-height: 70px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  .left-pane {
    display: flex;
    width: 100%;
    align-items: center;
    .section-text {
      margin-left: 15px;
    }
  }
  .right-pane {
    a {
      width: max-content;
      border-color: #c7c7c7;
      font-size: 12px;
      font-weight: 400;
      background-color: white;
      img {
        width: 18px;
        height: 18px;
        margin-right: 5px;
      }
    }
    a:hover {
      border-color: #345165;
      color: #345165;
    }
  }
}
.lesson-pane {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  cursor: auto;
  border-color: #c7c7c7;
  border-style: solid;
  border-width: 1px;
  border-radius: 14px;
  padding: 15px 25px 15px 25px;
  margin: 10px 0px 10px 0px;
  background-color: #fff;
  h1 {
    font-size: 18px;
    font-weight: 700;
  }
  h3 {
    font-size: 16px;
    font-weight: 400;
  }
}
.drag-icon {
  width: 25px;
  height: 25px;
  margin-left: -20px;
}

// Css xs => md
@media screen and (max-width: 769px) {
  .lessons {
    padding: 15px 20px 20px 20px;
  }
}

</style>
