<template>
  <staff-student-score-report-subsection-details
    :is-horizon-admin="true"/>
</template>

<script>
import StaffStudentScoreReportSubsectionDetails from '@staff/student_results/score_report/subsection_details.vue'

export default {
  components: {
    StaffStudentScoreReportSubsectionDetails,
  },
}
</script>
