<template>
  <horizon-modal id="import-instructors-modal" modal-title="Import Instructors" :on-close="resetImport">
    <template v-slot:content>
      <div>
        <h1>
          How to import instructors
        </h1>
        <br>
        <p>
          To import instructors, please place the instructors' account details inside a CSV (Comma Separated Values) file in the following format:
          <br><br>
          <em>First Name, Last Name, E-mail, Role</em>
          <br><br>
          Note the order of the values and the comma that separates each value. The value for the Account Type should either be "Teacher" or "Teacher Admin".
          <br><br>
          Once the list is uploaded, every instructor will have an account automatically created for them.
        </p>
        <div v-if="filePresent" class="d-flex flex-row justify-content-end text-right" style="margin-top: 10px;">
          <div class="d-flex flex-column my-auto">
            {{ fileUpload.fileName }}
          </div>
          <button class="btn btn-outline-primary" @click="removeFile">
            <span class="m-left-two-px">
              <i class="fa fa-trash" style="font-size:16px;"></i>
            </span>
          </button>
          <button class="btn btn-primary student-modal-button" @click="saveImport">
            <img src="/images/spreadsheet-ascending-order.png" width="19" alt="" class="image-4">
            <span class="m-left-two-px">Save Import</span>
          </button>
        </div>
        <div v-else class="d-flex flex-row justify-content-end" style="margin-top: 10px;">
          <button class="btn btn-primary student-modal-button" @click="downloadTemplate">
            <img src="/images/spreadsheet-ascending-order.png" width="19" alt="" class="image-4">
            <span class="m-left-two-px">Download Template</span>
          </button>
          <div class="file-input-wrapper">
            <input
              id="mass-import-file"
              type="file"
              ref="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              @change="onFileChange">
            <button class="btn btn-primary student-modal-button" @click="$refs.file.click()">
              <img src="/images/upload.png" width="19" alt="" class="image-4">
              <span class="m-left-two-px">Upload Instructors List</span>
            </button>
          </div>
        </div>
      </div>
    </template>
  </horizon-modal>
</template>
<script>
import HorizonModal from '@shared/horizon_modal'
export default {
  components: {
    HorizonModal
  },
  props: {
    instructorRepository: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      fileUpload: {
        file: '',
        fileName: ''
      },
      fileSizeInfo: '0 kbytes'
    }
  },
  computed: {
    filePresent () {
      return !this.isBlank(this.fileUpload.file)
    }
  },
  methods: {
    downloadTemplate () {
      window.location = '/downloads/instructor_uploads_sample.csv'
    },
    attachImage (file) {
      if (this.isBlank(this.fileUpload.fileName)) {
        this.fileUpload.fileName = file.name.replace(/\.[^/.]+$/, '')
      }
      const reader = new FileReader()
      const vm = this
      reader.onload = (e) => { vm.fileUpload.file = e.target.result }
      reader.readAsDataURL(file)
    },
    onFileChange (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) { return }
      this.fileSizeInfo = files[0].size/1000 + ' KB'
      this.attachImage(files[0])
    },
    removeFile (e) {
      this.resetImport()
    },
    resetImport () {
      this.fileUpload = { file: '', fileName: '' }
    },
    async saveImport () {
      const response = await this.instructorRepository.massImport(this.fileUpload)
      this.$emit('imported', response)
      this.resetImport()
    }
  }
}
</script>

<style scoped lang="scss">
  @import "~@styles/shared/students/student-form.scss";
  .file-input-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer !important;

    input[type=file] {
      font-size: 100px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      z-index: -1;
    }
  }
  .m-left-two-px {
    margin-left: 2px;
  }
</style>
