<template>
  <div class="col-md-12 report-wrapper">
    <div class="row">
      <div class="col">
        <schedule-view-pane :schedule="schedule" :edit-schedule-route="editScheduleRoute" />
      </div>
    </div>
  </div>
</template>

<script>
import ScheduleViewPane from "@components/modules/schedule/view/pane";

import RepositoryFactory from "@repositories/factory";

export default {
  components: {
    ScheduleViewPane
  },
  data() {
    return {
      schedule: {},
      scheduleRepository: RepositoryFactory.admin
        .school(this.$route.params.id)
        .get("schedule"),
      editScheduleRoute: {}
    };
  },
  props: {
    scheduleId() {
      return this.$route.params.scheduleId;
    }
  },
  created() {
    this.loadSchedule();
  },
  methods: {
    async loadSchedule() {
      const schoolId = this.$route.params.id;
      const scheduleId = this.$route.params.scheduleId;
      this.schedule = await this.scheduleRepository.get(scheduleId);

      this.editScheduleRoute = {
        name: "AdminSchoolScheduleEdit",
        params: { id: schoolId, scheduleId: scheduleId }
      };
    }
  }
};
</script>
