<template>
  <div>
    <div class="bg-primary card-header no-border-radius d-flex justify-content-between">
      <h1 class="text-white">
        Performance Comparison
      </h1>
    </div>
    <div style="margin: 20px;" class="bordered-card p-0">
      <div class="card-body">
        <div class="mb-3 row">
          <div class="col-12">
            <h2 class="d-inline mr-2">
              Compare Scores By:
            </h2>
             <span
              class="font-16 ml-3 mr-2 tab-header"
              :class="comparingByAssessment ? 'active' : ''"
              @click="swapComparisonView">Schedule</span>
             <span
              class="font-16 tab-header"
              :class="comparingByAssessment ? '' : 'active'"
              @click="swapComparisonView">Assessment</span>
          </div>
        </div>
        <div class="row">
          <template v-if="comparingByAssessment">
            <div v-if="loadingAssessmentComparison"
              class="col-12">
              <common-loading/>
            </div>
            <compare-by-assessment-table v-else
              :assessment-table-headers="assessmentTableHeaders"
              :assessment-data-prop="assessmentData"
              :active-schedule="activeSchedule"
              :active-assessment-id="activeAssessmentId"/>
          </template>
          <template v-else>
            <div v-if="loadingScheduleComparison"
              class="col-12">
              <common-loading/>
            </div>
            <compare-by-schedule-table v-else
              :schedule-table-headers="scheduleTableHeaders"
              :schedule-data-prop="scheduleData"
              :active-schedule="activeSchedule"
              :active-assessment-id="activeAssessmentId"/>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import CompareByAssessmentTable from './compare_by_assessment_table.vue'
import CompareByScheduleTable from './compare_by_schedule_table.vue'
import CommonLoading from '@shared/common_loading'

export default {
  components: {
    CompareByAssessmentTable,
    CompareByScheduleTable,
    CommonLoading
  },
  props: {
    assessmentTableHeaders: { type: Array },
    scheduleTableHeaders: { type: Array },
    activeSchedule: { type: Object },
    activeAssessmentId: { default: '', type: [String,Number] }

  },
  data () {
    return {
      loadingAssessmentComparison: false,
      loadingScheduleComparison: false,
      comparingByAssessment: true,
      scheduleComparison: [],
      scheduleData: [],
      assessmentData: []
    }
  },
  created () {
    this.initData()
  },
  methods: {
    swapComparisonView () {
      this.comparingByAssessment = !this.comparingByAssessment
    },
    initData () {
      this.fetchAssessmentComparison()
      this.fetchScheduleComparison()
    },
    fetchScheduleComparison () {
      this.loadingScheduleComparison = true
      this.$http.get('/api/staff/school/schedule_performance_comparison').then((result) => {
        this.assessmentData = result.data.schedulePerformanceComparison
        this.loadingScheduleComparison = false
      }, (responseError) => {
        this.appendError({error: responseError, notification: true})
        this.loadingScheduleComparison = true
      })
    },
    fetchAssessmentComparison () {
      this.loadingAssessmentComparison = true
      this.$http.get('/api/staff/school/schedule_performance_comparison2').then((result) => {
        this.scheduleData = result.data.schedulePerformanceComparison
        this.loadingAssessmentComparison = false
      }, (responseError) => {
        this.appendError({error: responseError, notification: true})
        this.loadingAssessmentComparison = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
	.tab-header.active {
		border-style: none none solid;
		border-width: 1px 1px 2px;
		border-color: #f4701f;
		background-color: transparent;
		color: #505050;
	}

	.tab-header {
		color: #949494;
		font-size: 16px;
		font-weight: 600;
	}

	.tab-header:hover {
		border-style: none none solid;
		border-width: 1px 1px 2px;
		border-bottom-color: #505050 !important;
		transition: border-color 200ms ease;
  }
  .no-border-radius {
    border-radius: 0;
  }
</style>
