import { render, staticRenderFns } from "./individual_score_report_score_section.vue?vue&type=template&id=d2ef5022&scoped=true&"
import script from "./individual_score_report_score_section.vue?vue&type=script&lang=js&"
export * from "./individual_score_report_score_section.vue?vue&type=script&lang=js&"
import style0 from "./individual_score_report_score_section.vue?vue&type=style&index=0&id=d2ef5022&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2ef5022",
  null
  
)

export default component.exports