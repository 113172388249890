<template>
  <div v-if="students" class="row">
    <a id="export-list" style="display: none;"/>
    <update-student-modal
      v-if="selectedStudent"
      :initial-enrollment="selectedStudent"
      :schedules="availableSchedules"
      :trigger-delete-modal="openConfirmDeleteModal"
      :student-repository="studentRepository"
      :enrollment-repository="enrollmentRepository"
      @updated="update"/>
    <create-student-modal
      v-if="showCreateModal"
      :schedule-options="createStudentSchedule"
      :initial-schedule-id="schedule.id"
      :student-repository="studentRepository"
      :enrollment-repository="enrollmentRepository"
      @created="acceptEnrollment"/>
    <add-student-modal
      v-if="showAddModal"
      :schedule-options="concurrentSchedules"
      :students="selectedStudentObjects"
      :enrollment-repository="enrollmentRepository"
      :schedule-repository="scheduleRepository"
      @removeStudent="removeSelectedStudent"
      @created="addedStudent"/>
    <unenroll-student-modal
      v-if="showUnenrollModal"
      :schedule-options="concurrentSchedules"
      :selected-schedule="schedule"
      :students="selectedStudentObjects"
      :enrollment-repository="enrollmentRepository"
      :schedule-repository="scheduleRepository"
      @removeStudent="removeSelectedStudent"
      @created="unenrollStudent"/>
    <delete-student-modal
      :v-if="selectedStudent"
      :enrollment="selectedStudent"
      :enrollment-repository="enrollmentRepository"
      @deleted="refreshSelectedStudent"/>
    <mass-import-modal
      v-if="canMassImport"
      :schedule-id="Number(schedule.id)"
      :enrollmentRepository="enrollmentRepository"
      @imported="imported"/>
    <div class="col-12 mt-3">
      <div class="card">
        <div class="bg-white card-header card-header-primary">
          <div class="d-flex enrollment-function-wrapper flex-row pull-left">
            <h1 class="enrollment-function-head">
              {{ schedule.name|ellipsize(25) }}
            </h1>
          </div>
          <div class="d-flex flex-row pull-right">
            <schedule-filter
              :initial-schedule-id="schedule.id"
              :initial-students="students"
              :schedule-options="scheduleFilterOptions"
              :meta="meta"
              :is-admin="isAdmin"
              @filtered="filtered"/>
            <button
              v-if="canCreateEnrollment"
              class="btn btn-outline-secondary enrollment-function-button btn-create-student ml-2"
              @click="openCreateStudentModal">
              Create Student
            </button>

            <button :disabled="!selectedStudentObjects.length || schedule.id == 0"
              class="btn btn-outline-secondary enrollment-function-button btn-create-student ml-2"
              @click="openAddStudentModal"
              v-if="canCreateEnrollment">
              Add Concurrent Enrollment
            </button>

            <button :disabled="!selectedStudentObjects.length || schedule.id == 0"
              class="btn btn-outline-secondary enrollment-function-button btn-create-student ml-2"
              @click="openUnenrollStudentModal"
              v-if="canCreateEnrollment">
              Unenroll Student
            </button>

            <button
              v-if="canExport"
              class="btn btn-outline-secondary enrollment-function-button btn-export-list ml-2"
              @click="exportList">
              Export List
            </button>
            <button
              v-if="canMassImport && canCreateEnrollment"
              class="btn btn-outline-secondary enrollment-function-button btn-mass-import-students"
              @click="navTo({
                        name: 'StaffNewMassImportTempStudentList',
                        params: {
                            id: schedule.id
                            }
                        })">
              Mass Import Students
            </button>
          </div>
        </div>
        <div class="bg-primary card-header card-header-secondary">
          <div class="pull-right">
            <mass-transfer-component
              :current-schedule="schedule"
              :schedules="availableSchedules"
              :students="selectedStudentObjects"
              :enrollment-repository="enrollmentRepository"
              @transferred="refreshSelectedStudents"
              class="mass-transfer"
              v-if="canTransfer() && canCreateEnrollment"
              />
            <mass-delete-component
              :students="selectedStudentObjects"
              :enrollmentRepository="enrollmentRepository"
              @deleted="refreshSelectedStudents"
              class="btn-mass-delete"
              v-if="canCreateEnrollment"
              />
          </div>
        </div>
        <div>
          <table class="table table-course-enrollment">
            <thead>
              <tr>
                <th scope="col" class="th-check">
                  <div class="check">
                    <label class="checkbox-field">
                      <input v-model="toggleAllSelected" type="checkbox" class="checkbox-input" @click="toggleSelectAll">
                    </label>
                  </div>
                </th>
                <th scope="col" class="cursor-hand text-left th-lastname" @click="toggleSort(LAST_NAME)">
                  Last Name <i :class="sortIconClass(LAST_NAME)"/>
                </th>
                <th scope="col" class="cursor-hand text-left th-firstname" @click="toggleSort(FIRST_NAME)">
                  First Name <i :class="sortIconClass(FIRST_NAME)"/>
                </th>
                <th scope="col" class="cursor-hand text-left th-schedule" @click="toggleSort(SCHEDULE_NAME)">
                  Schedule <i :class="sortIconClass(SCHEDULE_NAME)"/>
                </th>
                <th scope="col" class="cursor-hand text-left th-grade" @click="toggleSort(GRADE_LEVEL)">
                  Grade <i :class="sortIconClass(GRADE_LEVEL)"/>
                </th>
                <th scope="col" class="text-left th-student-id">
                  Student ID
                </th>
                <th scope="col" class="cursor-hand text-left th-signup-date" @click="toggleSort(SIGNUP_DATE)">
                  Signup Date <i :class="sortIconClass(SIGNUP_DATE)"/>
                </th>
                <th scope="col" class="cursor-hand text-left th-signup-date">
                  Current Enrollments
                </th>
                <th v-if="canUpdateStudent" scope="col" class="th-options">
                  Options
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(student, idx) in students"
                :key="student.id"
                :data-enrollment-id="student.id"
                :class="rowParityClass(idx)">
                <td class="td-check">
                  <div class="check">
                    <label class="checkbox-field">
                      <input
                        :id="'student_' + student.id"
                        v-model="selectedStudents"
                        type="checkbox"
                        class="checkbox-input"
                        :value="student.id">
                    </label>
                  </div>
                </td>
                <td class="fw-600 student-last-name text-left td-lastname">
                  {{ student.user.lastName }}
                </td>
                <td class="fw-600 student-first-name text-left td-firstname">
                  {{ student.user.firstName }}
                </td>
                <td class="schedule-name text-left td-schedule">
                  {{ student.scheduleName }}
                </td>
                <td class="student-grade-level text-left td-grade">
                  {{ student.user.gradeLevel }}th
                </td>
                <td class="student-id-number text-left td-student-id">
                  {{ student.user.studentIdNumber }}
                </td>
                <td class="text-left td-signup-date">
                  {{ student.signupOn|dateMMDDYY }}
                </td>
                <td class="text-left td-signup-date">
                  {{ courseNames(student) }}
                </td>
                <td v-if="canUpdateStudent" class="td-options">
                  <button
                    type="button"
                    class="btn btn-outline-primary enrollment-button"
                    @click="selectAndOpenUpdateModal(student)">
                    Edit Info
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-primary enrollment-button"
                    @click="selectAndOpenConfirmDeleteModal(student)">
                    Delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <common-table-pagination
            v-if="showPagination"
            :meta="meta"
            :state-change-callback="updateTable"
            @paginate-data="paginateData"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import CreateStudentModal from './modal/create_modal'
import UpdateStudentModal from './modal/update_modal'
import ConfirmModal from '@shared/students/confirm_modal'
import DeleteStudentModal from './modal/delete_modal'
import ScheduleFilter from '@shared/students/schedule_filter'
import MassTransferComponent from './modal/mass_transfer_component'
import MassDeleteComponent from './modal/mass_delete_component'
import MassImportModal from './modal/mass_import'
import Mixins from '@submodules/mixins/mixin.js'
import SortHelper from './student_table_sorter'
import CommonTablePagination from '@submodules/components/common_table/pagination'
import AddStudentModal from './modal/add_modal'
import UnenrollStudentModal from './modal/unenroll_modal'

export default {
  components: {
    ConfirmModal,
    UpdateStudentModal,
    CreateStudentModal,
    DeleteStudentModal,
    MassTransferComponent,
    MassDeleteComponent,
    MassImportModal,
    ScheduleFilter,
    CommonTablePagination,
    AddStudentModal,
    UnenrollStudentModal,
  },
  mixins: [Mixins],
  props: {
    fetchStudents: { type: Function, required: true },
    scheduleIdFilter: {
      type: [String, Number],
      required: false,
      default: ''
    },
    studentList: {
      type: Array,
      required: true
    },
    isEnrollmentTable: {
      type: Boolean,
      required: false,
      default: false
    },
    scheduleRepository: {
      type: Object,
      required: true
    },
    studentRepository: {
      type: Object,
      required: true
    },
    enrollmentRepository: {
      type: Object,
      required: true
    },
    showPagination: {
      type: Boolean,
      default: true
    },
    meta: {
      type: Object,
      default () {
        return {}
      }
    },
    stateChangeCallback: {
      type: Function,
      default: function () { return '' },
      required: false
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    schoolInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    canCreateEnrollment: {
      type: Boolean,
      default: false
    },
    canUpdateStudent: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      editSchedules: {},
      selectedScheduleId: null,
      scheduleFilterOptions: [],
      schedulesForSelect: [],
      selectedStudents: [],
      selectedStudent: null,
      allStudentList: [],
      schedule: {},
      availableSchedules: [],
      concurrentSchedules: [],
      createStudentSchedule: [],
      apiPath: `/api/staff/${this.isEnrollmentTable ? 'enrollments' : 'waiting_list_entries'}`,
      showCreateModal: false,
      showAddModal: false,
      showUnenrollModal: false,
      sortOptions: {},
      enrollStudent: [],
    }
  },
  computed: {
    canExport() {
      if(this.schedule.name == "Entire School") { return true }
    },
    canMassImport: {
      get () {
        if (this.schedule.name == "Entire School") { return false }
        if (this.scheduleIdFilter) { return this.isEnrollmentTable && this.scheduleIdFilter }
        return false
      }
    },
    showDownloadScantronButton () {
      return this.isEnrollmentTable && !this.isBlank(this.schedule.id)
    },
    students () {
      if (_.isEmpty(this.meta)) {
        const studentsDisplay = this.sortHelper.sort(this.studentList)
        return studentsDisplay
      } else {
        return this.studentList
      }
    },
    toggleAllSelected: {
      get () {
        return this.students.length === this.selectedStudents.length
      },
      set () {

      }
    },
    selectedStudentObjects () {
      return this.studentList.filter(student => this.selectedStudents.includes(student.id))

    }
  },
  created () {
    this.fetchSchedules()
    this.initializeSortHelper()
    this.fetchAllEnrollments()
  },
  methods: {
    ...mapState(['schoolYear']),
    ...mapGetters(['userSchool']),

    courseNames(student) {
      return student.user.courseNames.join(', ')
    },
    canTransfer() {
      if(this.schedule.name != "Entire School") { return true }
    },
    initializeSortHelper () {
      this.LAST_NAME = SortHelper.fields.LAST_NAME
      this.FIRST_NAME = SortHelper.fields.FIRST_NAME
      this.SCHEDULE_NAME = SortHelper.fields.SCHEDULE_NAME
      this.GRADE_LEVEL = SortHelper.fields.GRADE_LEVEL
      this.SIGNUP_DATE = SortHelper.fields.SIGNUP_DATE

      this.sortHelper = new SortHelper()
      this.sortOptions = this.sortHelper.getOptions()
    },
    navTo (routeOptions) {
      this.$router.push(routeOptions)
    },
    exportList () {
      this.createCsvFile().then( csvFile => {
        const downloadAnchor = $('#export-list')
        downloadAnchor.attr('href', csvFile)
        const date = new Date()
        const schoolName = this.isAdmin ? this.schoolInfo.name : this.userSchool().name
        const timeStamp = `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`
        const fileName = `${schoolName}_${timeStamp}.csv`
        downloadAnchor.attr('download', fileName)
        document.getElementById('export-list').click()
      })
    },
    async fetchAllEnrollments () {
      const enrollmentData = await this.enrollmentRepository.list_all()
      this.allStudentList = enrollmentData
    },
    createCsvFile () {
      return new Promise( (resolve, reject) => {
        try {
          const allStudents = this.allStudentList
          const rows = [['Last Name', 'First Name', 'Email', 'Schedule Name', 'Grade', 'Student ID', 'Signup Date']]
          for (let i=0; i < allStudents.length; i++) { rows.push( this.createCsvRow(allStudents[i]) ) }
          const fileData = 'data:text/csv;charset=utf-8,' + encodeURI( rows.map(e => e.join(',')).join('\n') )
          resolve(fileData)
        } catch (error) {
          reject(error)
        }
      })
    },
    createCsvRow (student) {
      return [
        student.lastName,
        student.firstName,
        student.email,
        student.scheduleName,
        student.gradeLevel,
        student.idNumber,
        student.signUpDate
      ]
    },
    createStudentScheduleOptions (){
      this.createStudentSchedule = this.scheduleFilterOptions.filter(e => e.id != 0)
    },
    concurrentScheduleOptions () {
      const conSchedule = this.scheduleFilterOptions.filter(e => e.courseType != this.schedule.courseType)
      this.concurrentSchedules = conSchedule.filter(e => e.id != 0)
    },
    openMassImportModal () {
      $('#mass-import-modal').modal('show')
    },
    imported() {
      this.appendSuccess({ message: 'Imported students successfully', notification: true })
      this.fetchStudents()
      this.closeMassImportModal()
    },
    closeMassImportModal () {
      $('#mass-import-modal').modal('hide')
    },
    openConfirmDeleteModal () {
      $('#delete-students-modal').modal('show')
    },
    openCreateStudentModal () {
      this.createStudentScheduleOptions()
      this.showCreateModal = true
      this.$nextTick(() => { $('#create-student-modal').modal('show') })
    },
    closeCreateStudentModal () {
      this.showCreateModal = false
      $('#create-student-modal').modal('hide')
    },
    openAddStudentModal () {
      this.concurrentScheduleOptions()
      this.showAddModal = true
      this.$nextTick(() => { $('#add-student-modal').modal('show') })
    },
    closeAddStudentModal () {
      this.showAddModal = false
      $('#add-student-modal').modal('hide')
    },
    openUnenrollStudentModal () {
      this.concurrentScheduleOptions()
      this.showUnenrollModal = true
      this.$nextTick(() => { $('#unenroll-student-modal').modal('show') })
    },
    closeUnenrollStudentModal () {
      this.showUnenrollModal = false
      $('#unenroll-student-modal').modal('hide')
    },
    selectAndOpenConfirmDeleteModal (student) {
      this.selectedStudent = student
      this.openConfirmDeleteModal()
    },
    toggleSelectAll () {
      if (this.toggleAllSelected) {
        this.selectedStudents = []
      } else {
        this.selectedStudents = this.students.map(student => student.id)
      }
    },
    async selectAndOpenUpdateModal(student) {
      this.selectedStudent = student
      await this.$nextTick()
      $('#update-student-modal').modal('show')
    },
    asyncSelectStudent (student) {
      return new Promise(resolve => {
        this.selectedStudent = student
        resolve()
      })
    },
    async fetchSchedules () {
      // TODO Refactor to be more readable and intuitive
      // const url = '/api/staff/schedules'
      // const {data} = await this.$http.get(url)
      const schedules = await this.scheduleRepository.list()

      this.__populateScheduleFilterOptions(schedules)

      this.editSchedules.schedules = schedules
      if (this.scheduleIdFilter) {
        const idx = schedules.findIndex(schedule => Number(schedule.id) === Number(this.scheduleIdFilter))
        if (idx > -1) {
          this.assignCurrentScheduleData(schedules, idx)
        }
      } else {
        this.schedule = { id: 0, name: 'Entire School' }
      }
      // assign schedules as options for transferring students
      this.schedulesForSelect = schedules
      this.availableSchedules = schedules.filter(selectedSchedule => {
          return selectedSchedule.courseType == this.schedule.courseType
              && selectedSchedule.id != this.schedule.id
              && selectedSchedule.practiceTestDates.length == this.schedule.practiceTestDates.length
      })
    },
    assignCurrentScheduleData (schedules, idx) {
      const currentSchedule = schedules.splice(idx, 1)[0]
      this.schedule = currentSchedule
    },
    indexParams () {
      if (this.schedule.id) {
        return { params: { filters: { schedule: this.schedule.id } } }
      }
      return {}
    },
    changeStudentSchedule (studentId, scheduleId) {
      const url = `${this.apiPath}/${studentId}`
      this.$http.put(url, this.studentParams(scheduleId)).then(() => {
        this.fetchStudents()
      })
    },
    studentParams (scheduleId) {
      const params = {}
      if (this.isEnrollmentTable) {
        params.enrollment = { scheduleId }
      } else {
        params.waitingListEntry = { scheduleId }
      }
      return params
    },
    update () {
      $('#update-student-modal').modal('hide')
      // NOTE: We may need to optimize this later when  there's
      //  a significant performance decline
      this.fetchStudents()
    },
    acceptEnrollment (enrollment) {
      this.closeCreateStudentModal()
      this.fetchStudents()
      this.$emit('created', enrollment)
    },
    removeSelectedStudent(studentId) {
      const indx = this.selectedStudents.findIndex(id => id === studentId)
      return this.selectedStudents.splice(indx, 1)
    },
    addedStudent () {
      this.closeAddStudentModal()
      this.fetchStudents()
      this.$emit('created')
    },
    unenrollStudent () {
      this.closeUnenrollStudentModal()
      this.fetchStudents()
      this.$emit('unenroll')
    },
    refreshSelectedStudent () {
      this.fetchStudents()
      this.selectedStudent = null
    },
    async refreshSelectedStudents () {
      console.log('REFRESH SELECTED STUDENTS...')
      this.closeAddStudentModal()
      this.fetchStudents()
      this.selectedStudents = []
    },
    replaceUserAttributes (updateParams) {
      const user = Object.assign(this.selectedStudent.user)
      user.email = updateParams.email
      user.phoneNumber = updateParams.phone
      user.studentIdNumber = updateParams.idNumber
      user.firstName = updateParams.firstName
      user.lastName = updateParams.lastName
      return user
    },
    userParams (user) {
      const params = user
      delete params.lastLoginAt
      delete params.role
      delete params.deletedAt
      delete params.activatedAt
      return params
    },
    findIndexById (id, array) {
      return array.findIndex(arrayItem => Number(arrayItem.id) === Number(id))
    },
    replaceById (newObject, array) {
      const idx = this.findIndexById(newObject.id, array)
      array.splice(idx, 1, newObject)
      return array
    },
    rowParityClass (idx) {
      return (idx % 2 === 0) ? 'even' : 'odd'
    },
    __populateScheduleFilterOptions (schedules) {
      this.scheduleFilterOptions = schedules.map( schedule => ({
        id: schedule.id,
        name: schedule.name,
        courseType: schedule.courseType
      }))

      this.scheduleFilterOptions.push({
        id: 0,
        name: 'Entire School'
      })
    },
    filtered (enrollmentList, schedule) {
      this.schedule = schedule
      this.$emit('schedule-filtered', enrollmentList, schedule)
      this.fetchSchedules()
    },
    toggleSort (field) {
      this.sortHelper.toggleSort(field)
      if (!_.isEmpty(this.meta)) {
        this.$emit('sort-data', this.sortOptions)
      }
    },
    sortIconClass (field) {
      return this.sortHelper.sortIconClass(field)
    },
    updateTable () {
      try {
        this.stateChangeCallback()
      } catch (e) {
        console.log('updateTable was called but stateChangeCallback was not set')
      }
    },
    paginateData(meta) {
      this.$emit('paginate-data', meta)
    },
    navToMassImport() {

    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@styles/variables';

.enrollment-function-wrapper {
  height: 37px;
}

.enrollment-function-head {
  margin-top: 0px;
  margin-bottom: 0px;
  max-width: 300px;
  color: #345165;
  font-size: 20px;
  font-weight: 700;
  margin-top: auto;
  margin-bottom: auto;
}

.enrollment-function-button {
  height: 40px;
  font-size: 12px;
}

.enrollment-button {
  padding: 4px 6px;
  border: 1px solid #cecece;
  font-size: 10px;
  line-height: 14px;
  font-weight: 400;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  &:not(:hover) {
    background-color: #ffffff;
    color:  #696969;
  }
}

.checkbox-field {
  padding-left: 8px !important;
  padding-right: 0px !important;
  align-items: center;
  display: flex;
  margin-bottom: 0px;
}

.checkbox-input {
  width: 18px;
  height: 18px;
}

.th-check, .td-check {
  width: 50px;
}

thead {
  th {
    padding-left: 7px;
    padding-right: 7px;
  }
}

tbody {
  td,th {
    padding: 7px;
    vertical-align: middle;
  }
  tr {
    &.odd {
      background-color: #f7f7f7;
    }
    &.even {
      background-color: #ffffff;
    }
  }
}

.table-course-enrollment {
  .th-options, .td-options {
    width: 150px;
  }
}

@media screen and (max-width: $mobileScreenExtraLarge) {
  .card-header-primary {
    .pull-left {
      display: none !important;
    }

    .pull-right {
      .btn-mass-import-students, .btn-export-list {
        display: none;
      }
    }
  }

  .table-course-enrollment {
    .th-grade, .th-signup-date,
    .td-grade, .td-signup-date {
      display: none;
    }
  }
}

@media screen and (max-width: $mobileScreenLarge) {
  .card-header-primary {
    .pull-right {
      display: block !important;
      text-align: left;
      float: left;

      .btn-create-student {
        margin-top: 5px;
        margin-left: 0px !important;
      }
    }
  }

  .card-header-secondary {
    .pull-right {
      display: block;
      text-align: left;
      float: left;
    }
  }

  .table-course-enrollment {
    .th-schedule, .td-schedule {
      display: none;
    }
  }
}

@media screen and (max-width: $mobileScreenMedium) {
  .table-course-enrollment {
    .th-student-id, .td-student-id {
      display: none;
    }
  }
}

@media screen and (max-width: $mobileScreenExtraSmall) {
  .table-course-enrollment {
    .th-options {
      width: 80px;
    }

    .td-options {
      display: block;
      width: 80px;

      button {
        width: 55px;
      }

      button:first-child {
        margin-bottom: 3px;
      }
    }
  }
}

</style>
