<template>
  <staff-student-score-report-individual
    :is-horizon-admin="true"/>
</template>

<script>
import StaffStudentScoreReportIndividual from '@staff/student_results/score_report/individual/show.vue'

export default {
  components: {
    StaffStudentScoreReportIndividual,
  },
}
</script>
