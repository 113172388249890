<template>
  <horizon-modal
    :id="id"
    :modal-title="`${actionVerbiage} ${scheduleType} Date`"
    img-src="https://assets.website-files.com/5d6d56640b8020729636d3ce/5d6d56640b80206bae36d41c_Asset%204%402x.png"
  >
    <template v-slot:content>
      <div class="flex-column form m-2 row">
        <h2 class="d-block mb-2">{{ scheduleType }} On:</h2>
        <div>
          <flat-pickr
            v-model="classDate.meetingOn"
            :class="{'highlighter-red': $v.classDate.meetingOn.$error }"
          />
          <div class="row">
            <div class="col-md-6">
              <label for="startTime">Start Time</label>
              <flat-pickr
                v-model="classDate.startAt"
                :config="flatPickrTimeConfig"
                :class="{'highlighter-red': $v.classDate.startAt.$error }"
              />
            </div>
            <div class="col-md-6">
              <label for="endTime">End Time</label>
              <flat-pickr
                v-model="classDate.endAt"
                :config="flatPickrTimeConfig"
                :class="{'highlighter-red': $v.classDate.endAt.$error }"
              />
            </div>
          </div>
          <div v-if="id == 'practice-assessment-date-modal'" class="row">
            <div class="col-md-6">
              <label>Assessment Group</label>
              <common-dropdown
                :items="practiceTestSets"
                :item-display-key="'name'"
                :on-item-select="updatePracticeTestSet"
                dropdown-class="d-flex dropdown horizon-input justify-content-between no-hover"
                >
                <span class="font-12 text-truncate">
                  {{ practiceTestSetName }}
                </span>
              </common-dropdown>
            </div>
          </div>
          <div class="d-flex justify-content-end mt-2">
            <slot name="buttons" />
            <button class="btn btn-primary" style="min-width: 110px;" @click="saveScheduleDate">Done</button>
          </div>
        </div>
      </div>
    </template>
  </horizon-modal>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Mixins from "@submodules/mixins/mixin.js";
import HorizonModal from "@shared/horizon_modal";
import { required } from "vuelidate/lib/validators";
import CommonDropdown from '@shared/common_dropdown'

export default {
  components: {
    flatPickr,
    HorizonModal,
    CommonDropdown
  },
  mixins: [Mixins],
  props: {
    id: {
      type: String,
      required: true
    },
    scheduleType: {
      type: String,
      required: true
    },
    selectedScheduleDateProp: {
      type: Object,
      default() {
        return {
          meetingOn: ""
        };
      }
    },
    practiceTestSets: {
      type: Array,
      default() {
        return []
      }
    },
  },
  data() {
    return {
      flatPickrTimeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "h:i K",
        time_24hr: false,
        static: true
      },
      classDate: {},
      practiceTestSet: null,
      practiceTestSetName: 'Select Practice Assessment Group'
    };
  },
  computed: {
    actionVerbiage() {
      return this.isBlank(this.selectedScheduleDateProp) ? "Create" : "Update";
    }
  },
  watch: {
    selectedScheduleDateProp: {
      immediate: true,
      handler() {
        this.classDate = this.deepCloneObject(this.selectedScheduleDateProp);
      }
    }
  },
  validations: {
    classDate: {
      meetingOn: { required },
      startAt: { required },
      endAt: { required },
      practiceTestSetId: { required }
    }
  },
  updated() {
    if (this.classDate.hasOwnProperty('practiceTestSetId')) {
      const practice_test_set = this.practiceTestSets.find( ({ id }) =>
        id === this.classDate.practiceTestSetId);
      this.practiceTestSet = practice_test_set;
      this.practiceTestSetName = practice_test_set.name;
    } else {
      this.practiceTestSet = null;
      this.practiceTestSetName = 'Select Practice Assessment Group';
    }
  },
  methods: {
    updateScheduleDate() {
      $(`#${this.id}`).modal("hide");
      if (this.actionVerbiage == "Update") {
        this.$emit("scheduleDateUpdated", this.classDate);
      } else {
        this.$emit("scheduleDateAdded", this.classDate);
      }
    },
    saveScheduleDate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.updateScheduleDate();
      this.$v.$reset();
    },
    updatePracticeTestSet(value) {
      this.practiceTestSet = value;
      this.classDate.practiceTestSetId = value.id;
      this.practiceTestSetName = value.name;
    }
  }
};
</script>

<style scoped lang="scss">
.horizon-input {
  height: 38px;
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  border-radius: 5px !important;
  color: #333;
  font-size: 12px;
  text-align: left;
  display: block;
  padding: 8px 12px;
  line-height: 1.42857143;
  vertical-align: middle;
  background-color: #ffffff;
  width: 240px;
}
</style>
