<template>
  <staff-pre-act-9-student-results-pane
    :is-horizon-admin="true"/>
</template>

<script>
import StaffPreAct9StudentResultsPane from '@staff/shared/preact9_student_results_pane'

export default {
  components: {
    StaffPreAct9StudentResultsPane,
  },
}
</script>