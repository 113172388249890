<template>
  <div class="container">
    <div class="content-pane">
      <div class="pane-wrapper">
        <breadcrumbs v-if="breadcrumbs.length > 0" :breadcrumbs="breadcrumbs"/>
        <div class="content-heading underlined">
          <div class="pull-left">
            <h1>Course Enrollment</h1>
          </div>
          <div class="pull-right">
            <h1 class="d-inline text-orange">
              {{ waitingListEntries.length }}
            </h1>
            <h1 class="d-inline fw-400">
              Students Waitlisted
            </h1>
          </div>
        </div>
        <student-table
          v-if="waitingListEntries.length > 0"
          :schedule-id-filter="scheduleId"
          :student-list="waitingListEntries"
          :is-enrollment-table="false"
          :fetch-students="fetchWaitingListEntries"/>

        <div v-else class="text-center" style="margin-top: 25%">
          No waiting list entries exist yet.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import StudentTable from '../../../shared/students/student_table'
import Breadcrumbs from '@shared/breadcrumbs'
import Mixins from '@submodules/mixins/mixin.js'

export default {
  components: {
    Breadcrumbs,
    StudentTable
  },
  mixins: [Mixins],
  data () {
    return {
      scheduleId: null,
      waitingListEntries: [],
      breadcrumbs: []
    }
  },
  created () {
    this.scheduleId = this.$route.params.id
    this.initData()
  },
  methods: {
    ...mapState(['schoolYear']),
    getScheduleName () {
      if (this.scheduleId) {
        this.$http.get(`/api/staff/schedules/${this.scheduleId}`).then(response => {
          this.breadcrumbs = [{ text: response.data.schedule.name }]
        }, (responseError) => {
          this.appendError({ error: responseError, notification: true })
        })
      } else {
        this.breadcrumbs = [{ text: 'Entire School' }]
      }
    },
    fetchWaitingListEntries () {
      this.getScheduleName()
      const url = '/api/staff/waiting_list_entries'
      this.$http.get(url, this.indexParams()).then(response => {
        this.waitingListEntries = response.data.waitingListEntries
      })
    },
    initData () {
      this.fetchWaitingListEntries()
    },
    indexParams () {
      if (this.scheduleId) {
        return { params: { filters: { schedule: this.scheduleId } } }
      }
      return {}
    }
  }
}
</script>

<style scoped lang="scss">

</style>
