<template>
  <div class="section-text-media-detail" :id="id">
    <div class="text-button-detail">
      <h3>{{ main_text }}</h3>
      <div class="detail-buttons">
        <a class="btn btn-outline" :href="media_src" target="_blank">
          <img class="btn-icon" src="/images/tab.png">
          <div class="header-btn-text d-none d-md-block">Open in New Tab</div>
        </a>
      </div>
    </div>
    <div v-if="media_src != ''" class="media-embed">
      <iframe
        :src="media_src"
        width="100%"
        height="600"
        allowfullscreen="true"
        >
      </iframe>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: String,
      default: '',
      required: true
    },
    main_text: {
      type: String,
      default: ''
    },
    media_src: {
      type: String,
      default: ''
    }
  }
}
</script>
<style scoped lang="scss">
@import '~@styles/variables';
.section-text-media-detail {
  .text-button-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0px 15px 0px;
    border-bottom: 1px solid #d8d8d8;
    color: #345165;
    h3 {
      font-size: 16px;
      font-weight: 600;
      line-height: 28px;
    }
    .detail-buttons {
      display: flex;
      a {
        border-color: #c7c7c7;
        font-size: 12px;
        font-weight: 400;
        background-color: white;
        height: 44px;
        width: auto;
        padding: 9px 13px 9px 13px;
        img {
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }
      }
      a:hover {
        border-color: #345165;
        color: #345165;
      }
    }
  }
}
</style>
