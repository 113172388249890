<template>
  <div>
    <div class="mobile-content">
      This feature is designed to be used by devices with bigger screens. We recommend using a desktop computer.
    </div>

    <div class="desktop-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss">
@media screen and (min-width: 769px) {
  .mobile-content {
    display: none;
  }
}

.mobile-content {
  font-style: italic;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
