<template>
  <div class="nav-menu-mobile-container">
    <router-link :to="{ name: 'AdminEnrollmentList' }" class="menu-link-mobile head">
      <div class="nav-menu-div">
        <div class="nav-menu-title-text">Enrollment</div>
      </div>
    </router-link>
    <router-link :to="{ name: 'AdminSchoolList' }" class="menu-link-mobile head">
      <div class="nav-menu-div">
        <div class="nav-menu-title-text">School</div>
      </div>
    </router-link>
    <router-link :to="{ name: 'AdminDistrictList' }" class="menu-link-mobile head">
      <div class="nav-menu-div">
        <div class="nav-menu-title-text">Districts</div>
      </div>
    </router-link>
    <router-link :to="{ name: 'AdminAssessmentList' }" class="menu-link-mobile head">
      <div class="nav-menu-div">
        <div class="nav-menu-title-text">Assessments</div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang='scss'>
  @import '~@styles/nav-mobile.scss';
</style>
