<template>
  <div class="col-md-12 report-wrapper">
    <div class="row">
      <div class="col">
        <div class="content-heading underlined">
          <h1>Create Schedule</h1>
        </div>

        <div class="bordered-card">
          <create-inside-schedule-pane
            v-if="schedule.scheduleType == 'Inside'"
            :schedule="schedule"
            :errors="errors"
            :instructor-repository="instructorRepository"
            :target-assessment-date-repository="targetAssessmentDateRepository"
            :school-repository="schoolRepository"
          />
          <create-psat-schedule-pane
            v-else-if="schedule.scheduleType === 'PSAT 8/9 Assessment' || schedule.scheduleType === 'PSAT 10 Assessment' || schedule.scheduleType === 'PREACT 9 Assessment' || schedule.scheduleType === 'PREACT 10 Assessment' "
            :schedule="schedule"
            :errors="errors"
            :instructor-repository="instructorRepository"
            :target-assessment-date-repository="targetAssessmentDateRepository"
            :school-repository="schoolRepository"
          />
          <create-outside-schedule-pane
            v-else
            :schedule="schedule"
            :errors="errors"
            :instructor-repository="instructorRepository"
            :target-assessment-date-repository="targetAssessmentDateRepository"
            :school-repository="schoolRepository"
          />
        </div>

        <div class="row">
          <div class="col-12 d-flex justify-content-end">
            <div>
              <button
                class="btn btn-outline-secondary font-14 fw-400 gray-hover settings-btn"
                @click="goToSchoolScheduleList"
              >
                <span class="font-14">Cancel</span>
              </button>

              <button
                id="create-schedule"
                class="btn btn-primary float-right"
                @click="saveSchedule"
              >
                <i class="fa fa-2x fa-plus-circle mr-1 text-orange v-align-middle" />
                <span class="font-14 v-align-middle">Create New Schedule</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreateInsideSchedulePane from "@components/modules/schedule/create_inside_schedule_pane.vue";
import CreateOutsideSchedulePane from "@components/modules/schedule/create_outside_schedule_pane.vue";
import CreatePsatSchedulePane from "@components/modules/schedule/create_psat_schedule_pane.vue";

import RepositoryFactory from "@repositories/factory";
import { required } from 'vuelidate/lib/validators'

import _ from 'lodash';

export default {
  components: {
    CreateInsideSchedulePane,
    CreateOutsideSchedulePane,
    CreatePsatSchedulePane
  },
  data() {
    const name = this.$route.query.name ? this.$route.query.name : null;
    const scheduleType = this.$route.query.scheduleType
      ? this.$route.query.scheduleType
      : null;
    const schoolId = this.$route.query.schoolId
      ? this.$route.query.schoolId
      : null;
    const assessmentCourseId = this.$route.query.assessmentCourseId
      ? this.$route.query.assessmentCourseId
      : null;
     const courseType = this.$route.query.courseType
      ? this.$route.query.courseType
      : null;

    const schoolRepository = RepositoryFactory.admin
        .school(this.$route.params.id);

    return {
      schedule: {
        schoolId:           schoolId,
        assessmentCourseId: assessmentCourseId,
        name: name,
        scheduleType: scheduleType,
        courseType: courseType,
        schoolAssessment: null,
        enrollmentCap: 0,
        primaryTeacher: null,
        secondaryTeacher: null,
        enableParentNight: false,
        enableLiveEnrollment: false,
        enableWaitlist: false,
        enableClassSmsReminders: false,
        enableClassEmailReminders: false,
        enableAssessmentSmsReminders: false,
        enableAssessmentEmailReminders: false,
        classLocation: '',
        assessmentLocation: null,
        onlineAssessmentEnabled: false,
        targetAssessmentDate: null,
        zoomUrl: '',
        courseTemplate: null,

        classes: [],
        practiceTestDates: [],
        parentNightDates: [],
      },
      instructorRepository: schoolRepository.get("instructor"),
      scheduleRepository: schoolRepository.get("schedule"),
      targetAssessmentDateRepository: RepositoryFactory.admin.get(
        "target_assessment_date"
      ),
      schoolRepository
    };
  },
  validations: {
    schedule: {
      assessmentCourseId: { required },
      name: { required },
      courseType: { required },
      scheduleType: { required },
      classes: {
        $each: {
          meetingOn: { required },
          startAt: { required },
          endAt: { required },
        }
      },
      practiceTestDates: {
        $each: {
          meetingOn: { required },
          startAt: { required },
          endAt: { required },
          practiceTestSetId: { required },
        }
      },
      parentNightDates: {
        $each: {
          meetingOn: { required },
          startAt: { required },
          endAt: { required },
        }
      },
    }
  },
  computed: {
    schoolId() {
      return this.$route.params.id;
    },
    formData() {
      const insideSchoolFormData =  {
        schoolId:                    this.schedule.schoolId,
        assessmentCourseId:          this.schedule.assessmentCourseId,
        name:                        this.schedule.name,
        courseType:                  this.schedule.courseType,
        scheduleType:                this.$options.filters.capitalize(this.schedule.scheduleType),
        targetAssessmentDateId:      this.schedule.targetAssessmentDate ? this.schedule.targetAssessmentDate.id : null,
        primaryTeacherId:            this.schedule.primaryTeacher ? this.schedule.primaryTeacher.id : null,
        secondaryTeacherId:          this.schedule.secondaryTeacher ? this.schedule.secondaryTeacher.id : null,
        assessmentLocation:          this.schedule.assessmentLocation,
        parentNight:                 this.schedule.enableParentNight,
        enrollmentLive:              this.schedule.enableLiveEnrollment,
        maxEnrollment:               this.schedule.enrollmentCap,
        waitingListEnabled:          this.schedule.enableWaitlist,
        assessmentSmsReminders:      this.schedule.enableAssessmentSmsReminders,
        assessmentEmailReminders:    this.schedule.enableAssessmentEmailReminders,
        onlineAssessmentEnabled:     this.schedule.onlineAssessmentEnabled,
        scheduleDatesAttributes:     this.schedule.classes,
        practiceTestDatesAttributes: this.schedule.practiceTestDates,
        parentNightDatesAttributes:  this.schedule.parentNightDates,
        untimedLearnosityAssessment: this.schedule.untimedLearnosityAssessment,
        zoomUrl:                     this.schedule.zoomUrl,
        courseTemplateId:            this.schedule.courseTemplate ? this.schedule.courseTemplate.he_course_template_id : null
      }

      if(this.schedule.scheduleType == 'Inside') {
        return insideSchoolFormData;
      } else if(
        this.schedule.scheduleType === 'PSAT 8/9 Assessment' ||
        this.schedule.scheduleType === 'PSAT 10 Assessment' ||
        this.schedule.scheduleType === 'PREACT 9 Assessment' ||
        this.schedule.scheduleType === 'PREACT 10 Assessment') {
        return {
          schoolId:                    this.schedule.schoolId,
          assessmentCourseId:          this.schedule.assessmentCourseId,
          untimedLearnosityAssessment: this.schedule.untimedLearnosityAssessment,
          courseType:                  this.schedule.courseType,
          name:                        this.schedule.name,
          scheduleType:                this.schedule.scheduleType,
          primaryTeacherId:            this.schedule.primaryTeacher ? this.schedule.primaryTeacher.id : null,
          secondaryTeacherId:          this.schedule.secondaryTeacher ? this.schedule.secondaryTeacher.id : null,
          enrollmentLive:              this.schedule.enableLiveEnrollment,
          maxEnrollment:               this.schedule.enrollmentCap,
          waitingListEnabled:          this.schedule.enableWaitlist,
          onlineAssessmentEnabled:     this.schedule.onlineAssessmentEnabled,
          practiceTestDatesAttributes: this.schedule.practiceTestDates,
          zoomUrl:                     this.schedule.zoomUrl, 
          courseTemplateId:            this.schedule.courseTemplate ? this.schedule.courseTemplate.he_course_template_id : null
        }
      } else {
        return {
          ...insideSchoolFormData,
          classLocation:        this.schedule.classLocation,
          classEmailReminders:  this.schedule.enableClassEmailReminders,
          classSmsReminders:    this.schedule.enableClassSmsReminders
        }
      }
    },
    errors() {
      return {
        schedule: {
          name: this.$v.schedule.name.$error,
        },
        classes: this.$v.schedule.classes.$each,
        practiceTestDates: this.$v.schedule.practiceTestDates.$each,
        parentNightDates: this.$v.schedule.parentNightDates.$each,
      }
    }
  },
  async created() {
    const school = await this.schoolRepository.info();
    this.schedule.schoolAssessment = school.assessment;
  },
  methods: {
    async saveSchedule() {
      this.$v.$touch();
      if(this.$v.$invalid) {
        return;
      }
      await this.scheduleRepository.create(this.formData);
      this.goToSchoolScheduleList()
    },
    goToSchoolScheduleList() {
      this.$router.push({ name: 'AdminSchoolScheduleList' });
    }
  }
};
</script>
