<template>
  <horizon-modal :id="id" :modal-title="'Assessment Progress by Student'">
    <template v-slot:content>
      <div>
        Testing Modal
        {{ id }}
      </div>
    </template>
  </horizon-modal>
</template>
<script>
import HorizonModal from '@shared/horizon_modal'
export default {
  components: {
    HorizonModal,
  },
  props: {
    id: {
      type: String,
      default: '',
      required: true,
    },
  },
}
</script>
<style scoped lang='scss'>
@import '~@styles/variables';
</style>
