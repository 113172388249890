<template>
  <div class="section-text-button-detail" :id="id">
    <h3>{{ title }}</h3>
    <div v-if="locked" class="detail-buttons">
      <a class="btn btn-outline" href="">
        <img class="btn-icon" src="/images/lock.png">
        <div class="header-btn-text d-none d-md-block">Activity Locked</div>
      </a>
    </div>
    <div v-if="completed && !locked" class="detail-buttons">
      <h2>
        <strong> {{ percentCorrect }}% </strong>
        Correct
      </h2>
      <a class="btn btn-outline" @click.prevent="activityCompleteFn">
        <img class="btn-icon" src="/images/release_button.png">
        <div class="header-btn-text d-none d-md-block">Activity Complete</div>
      </a>
      <a class="btn btn-outline" @click.prevent="reviewQuestionsFn">
        <img class="btn-icon" src="/images/checklist.png">
        <div class="header-btn-text d-none d-md-block">Review Questions</div>
      </a>
    </div>
    <div v-if="!completed && !locked" class="detail-buttons">
      <a class="btn btn-outline" @click.prevent="launchActivityFn">
        <img class="btn-icon" src="/images/tab.png">
        <div class="header-btn-text d-none d-md-block">Launch Activity</div>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: String,
      default: '',
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    locked: {
      type: Boolean,
      default: true
    },
    completed: {
      type: Boolean,
      default: false
    },
    percentCorrect: {
      type: Number,
      default: 0
    },
    activityCompleteFn: {
      type: Function,
      default: () => {}
    },
    reviewQuestionsFn: {
      type: Function,
      default: () => {}
    },
    launchActivityFn: {
      type: Function,
      default: () => {}
    },
  }
}
</script>
<style scoped lang="scss">
@import '~@styles/variables';
.section-text-button-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px 15px 0px;
  border-bottom: 1px solid #d8d8d8;
  color: #345165;
  h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
  }
  .detail-buttons {
    display: flex;
    align-items: center;
    h2 {
      width: max-content;
      font-size: 16px;
      font-weight: 400;
      strong {
        color: #f4701f;
        font-weight: 700;
      }
    }
    a {
      border-color: #c7c7c7;
      font-size: 12px;
      font-weight: 400;
      background-color: white;
      height: 44px;
      width: auto;
      padding: 9px 13px 9px 13px;
      img {
        width: 18px;
        height: 18px;
        margin-right: 5px;
      }
    }
    a:hover {
      border-color: #345165;
      color: #345165;
    }
  }
}
</style>
