import { render, staticRenderFns } from "./preact10_student_results_pane.vue?vue&type=template&id=4016e76e&scoped=true&"
import script from "./preact10_student_results_pane.vue?vue&type=script&lang=js&"
export * from "./preact10_student_results_pane.vue?vue&type=script&lang=js&"
import style0 from "./preact10_student_results_pane.vue?vue&type=style&index=0&id=4016e76e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4016e76e",
  null
  
)

export default component.exports