<template>
  <div class="col-md-12 report-wrapper">
    <div class="row">
      <div class="col">
        <schedule-edit-pane
          :schedule="schedule"
          :school-repository="schoolRepository"
          :schedule-repository="scheduleRepository"
          :instructor-repository="instructorRepository"
          :target-assessment-date-repository="targetAssessmentDateRepository"
          :practice-test-repository="practiceTestRepository"
          :view-schedule-route="viewScheduleRoute"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ScheduleEditPane from "@components/modules/schedule/edit/pane";

import RepositoryFactory from "@repositories/factory";

export default {
  components: {
    ScheduleEditPane
  },
  data() {
    const schoolRepository = RepositoryFactory.admin.school(
      this.$route.params.id
    );
    return {
      schedule: {},
      schoolRepository: schoolRepository,
      instructorRepository: schoolRepository.get("instructor"),
      scheduleRepository: schoolRepository.get("schedule"),
      targetAssessmentDateRepository: RepositoryFactory.admin.get(
        "target_assessment_date"
      ),
      practiceTestRepository: schoolRepository.get("practice_test"),
      viewScheduleRoute: {}
    };
  },
  async created() {
    await this.loadSchedule();
  },
  methods: {
    async loadSchedule() {
      const scheduleId = this.$route.params.scheduleId;
      const schedule = await this.scheduleRepository.get(scheduleId);

      const school = await this.schoolRepository.info();
      schedule.schoolAssessment = school.assessment;
      this.schedule = schedule;

      this.viewScheduleRoute = {
        name: "AdminSchoolScheduleView",
        params: { id: school.id, scheduleId: scheduleId }
      };
    }
  }
};
</script>
