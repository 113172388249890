<template>
  <div class="section-pane" :id="id">
    <div class="accordion-trigger flex-md-row">
      <div class="left-pane" @click="triggerSelect">
        <img v-if="dragging" src="/images/drag.png" class="drag-icon">
        <rotating-button :isActive="selected">
        </rotating-button>
        <div v-show="!editing" class="section-text">
          <h1>{{ section.name }}</h1>
          <h3>{{ section.memo }}</h3>
        </div>
        <div v-show="editing" class="section-text section-text-editing">
          <editable-text
            :text-content="section.name"
            :header-one="true"
            :identity="buildIdentity('name')"
            @update="editableTextUpdate"></editable-text>
          <editable-text
            :text-content="section.memo"
            :header-one="false"
            :identity="buildIdentity('memo')"
            @update="editableTextUpdate"></editable-text>
        </div>
      </div>
      <div class="right-pane mt-1 mt-md-0">
        <a class="btn btn-outline" @click="deleteSection(section)">
          <img class="btn-icon" src="/images/trashcan.png">
          <div class="header-btn-text d-none d-md-block">Delete Section</div>
        </a>
      </div>
    </div>
    <div class="lesson-panes" :hidden="!selected">
      <draggable :id="id" :list="section.he_course_lesson" class="lesson-group" draggable=".item" group="section-course" v-bind="dragOptions">
          <div v-for="(lesson,index) in section.he_course_lesson" :key="'lesson'+index" class="lesson-group-item item">
              <lesson-pane
                :id="id+'-lesson-'+lesson.id"
                :lesson="lesson"
                :section_id="section.id"
                :section_order="section.order"
                :editing="editing"
                :dragging="dragging"
                @edit-text-update="editableTextUpdate"
                @lesson-update="$emit('section-update')"
                @get-lesson="$emit('get-lesson',$event)"></lesson-pane>
          </div>
        <create-lesson-pane slot="footer" :course-section="section" @load-course="$emit('section-update')" @get-section="$emit('get-section',$event)"></create-lesson-pane>
      </draggable>
    </div>
  </div>
</template>
<script>
import RotatingButton from '@shared/rotating_button.vue'
import LessonPane from './section/lesson.vue'
import EditableText from './show/editable_text.vue'
import CreateLessonPane from './section/new_lesson.vue'
import Draggable from 'vuedraggable'

export default {
  components: {
    RotatingButton,
    LessonPane,
    EditableText,
    CreateLessonPane,
    Draggable
  },
  props: {
    id: {
      type: String,
      default: '',
      required: true,
    },
    courseId: {
      type: Number,
      default: null
    },
    section: {
      type: Object,
      default() { return {} }
    },
    editing: {
      type: Boolean,
      default: false
    },
    dragging: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      selected: false,
    }
  },
  methods: {
    buildIdentity(target) {
      return {
        'section_id': this.section.id,
        'section_order': this.section.order,
        'target': target
      }
    },
    editableTextUpdate(identity, text) {
      this.$emit('edit-text-update', identity, text)
    },
    triggerSelect() {
      this.selected = this.selected == true ? false : true
    },
    deleteSection(section) {
      this.$http.delete(`/api/admin/course_activities/${section.he_course_id}/sections/${section.id}`).then(() => {
        this.appendSuccess({message: 'Deleted Section', notification: true})
        this.$emit('section-update')
      }, (responseError) => {
        this.appendError({error: responseError, notification: true})
      })
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 60,
        group: "sections",
        disabled: !this.dragging,
        swap: true,
        swapThreshold: 0.25
      }
    }
  },
}
</script>
<style scoped lang="scss">
@import '~@styles/variables';
.accordion-trigger {
  display: flex;
  flex-direction: column;
  min-height: 70px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  .left-pane {
    display: flex;
    width: 100%;
    align-items: center;
    .section-text {
      margin-left: 15px;
    }
  }
  .right-pane {
    a {
      width: max-content;
      border-color: #c7c7c7;
      font-size: 12px;
      font-weight: 400;
      background-color: white;
      img {
        width: 18px;
        height: 18px;
        margin-right: 5px;
      }
    }
    a:hover {
      border-color: #345165;
      color: #345165;
    }
  }
}
.section-pane {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  cursor: auto;
  border-color: #c7c7c7;
  border-style: solid;
  border-width: 1px;
  border-radius: 14px;
  padding: 15px 25px 15px 25px;
  margin: 10px 0px 10px 0px;
  background-color: #f8f8f8;
  h3 {
    font-size: 16px;
    font-weight: 400;
  }
}
.drag-icon {
  width: 25px;
  height: 25px;
  margin-left: -20px;
}
</style>
