<template>
  <div class="container">
    <div class="content-pane">
      <div class="pane-wrapper">
        <breadcrumbs/>
        <div class="pt-2 row">
          <div class="col-md-3">
            <div class="content-pane no-shadow">
              <course-schedule
                :user-type-prop="userType"/>
            </div>
          </div>
          <div class="col-md-9">
            <course-list/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@shared/breadcrumbs'
import CourseSchedule from '@shared/parent_student/course_schedule'
import CourseList from './list'

export default {
  components: {
    Breadcrumbs,
    CourseSchedule,
    CourseList
  },
  data () {
    return {
      breadcrumbs: [],
      userType: 'parent'
    }
  },
  created () {
    // GET DATA
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.dashboard-content-wrapper {
  padding: 20px 30px 30px 30px;
}
.col-9 {
  padding-left: 10px;
}
.col-3 {
  padding-right: 10px;
}
</style>
