<template>
  <div class="container">
    <div class="content-pane">
      <div class="pane-wrapper">
        <breadcrumbs v-if="breadcrumbs.length > 0" :breadcrumbs="breadcrumbs" class="breadcrumbs"/>
        <schedule-view-pane
          :schedule="schedule"
          :edit-schedule-route="editScheduleRoute"/>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@shared/breadcrumbs'
import Mixins from '@submodules/mixins/mixin.js'

import ScheduleViewPane from '@components/modules/schedule/view/pane'

export default {
  components: {
    Breadcrumbs,
    ScheduleViewPane
  },
  mixins: [Mixins],
  data () {
    return {
      breadcrumbs: [],
      schedule: {},
      editScheduleRoute: {}
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      const scheduleId = this.$route.params.id
      this.$http.get(`/api/staff/schedules/${scheduleId}`).then(response => {
        this.schedule = response.data.schedule
        this.breadcrumbs = [{ text: response.data.schedule.name }]
      })

      this.editScheduleRoute={
        name: "StaffScheduleEdit",
        params: { id: scheduleId }
      };
    },
  }
}
</script>

<style scoped lang="scss">
@import '~@styles/variables';

@media screen and (max-width: $mobileScreenMedium) {
  .container {
    .breadcrumbs {
      display: none;
    }
  }
}

@media screen and (max-width: $mobileScreenSmall) {
  .container {
    padding: 0px;
    height: 100%;

    .content-pane {
      border-radius: 0px;
      height: 100%;

      .pane-wrapper {
        padding: 15px;
      }
    }
  }
}
</style>
