<template>
  <horizon-modal id="upload-assessment-modal" modal-title="Import Test Results" :on-close="resetImport">
    <template v-slot:content>
      <div class="d-flex flex-column justify-content-end">
        <h5 class="model-para-heading">
          This form allows you to import assessment results to the Horizon Education Portal
        </h5>
        <h5 class="modal-para">
          <em>
            *Note*
            <br>Please ensure students you are uploading are enrolled on this schedule.
            <br>Please ensure Question and answer keys are uploaded first before uploading the results.
          </em>
        </h5>
        <br>
        <br>

        <div v-if="filePresent" class="d-flex flex-row justify-content-end text-right" style="margin-top: 10px;">
          <div class="d-flex flex-column my-auto">
            {{ fileUpload.fileName }}
          </div>
          <button class="btn btn-outline-primary mx-2" @click="removeFile">
            <span class="m-left-two-px">
              <i class="fa fa-trash"/>
            </span>
          </button>
          <button class="btn btn-primary" @click="saveImport">
            <img src="https://assets.website-files.com/5d6d56640b8020729636d3ce/5d6d56640b80204eac36d41e_001-spreadsheet-ascending-order.png" width="19" alt="" class="image-4">
            <span class="m-left-two-px font-14">Upload Results</span>
          </button>
        </div>

        <div v-else class="d-flex flex-row justify-content-around" style="margin-top: 5px;">
          <button class="btn btn-outline-secondary" @click="downloadTemplate('act')" v-if="courseTypeProp == 'ACT'">
            <img src="https://assets.website-files.com/5d6d56640b8020729636d3ce/5d6d56640b80204eac36d41e_001-spreadsheet-ascending-order.png" width="19" alt="" class="image-4">
            <span class="m-left-two-px">ACT Template</span>
          </button>
          <button class="btn btn-outline-secondary" @click="downloadTemplate('preact9')" v-if="courseTypeProp == 'ACT'">
            <img src="https://assets.website-files.com/5d6d56640b8020729636d3ce/5d6d56640b80204eac36d41e_001-spreadsheet-ascending-order.png" width="19" alt="" class="image-4">
            <span class="m-left-two-px">PreACT9 Template</span>
          </button>
          <button class="btn btn-outline-secondary" @click="downloadTemplate('preact10')" v-if="courseTypeProp == 'ACT'">
            <img src="https://assets.website-files.com/5d6d56640b8020729636d3ce/5d6d56640b80204eac36d41e_001-spreadsheet-ascending-order.png" width="19" alt="" class="image-4">
            <span class="m-left-two-px">PreACT10 Template</span>
          </button>
          <button class="btn btn-outline-secondary" @click="downloadTemplate('sat')" v-if="courseTypeProp == 'SAT'">
            <img src="https://assets.website-files.com/5d6d56640b8020729636d3ce/5d6d56640b80204eac36d41e_001-spreadsheet-ascending-order.png" width="19" alt="" class="image-4">
            <span class="m-left-two-px">SAT Template</span>
          </button>
          <button class="btn btn-outline-secondary" @click="downloadTemplate('psat89')" v-if="courseTypeProp == 'SAT'">
            <img src="https://assets.website-files.com/5d6d56640b8020729636d3ce/5d6d56640b80204eac36d41e_001-spreadsheet-ascending-order.png" width="19" alt="" class="image-4">
            <span class="m-left-two-px">PSAT89 Template</span>
          </button>
          <button class="btn btn-outline-secondary" @click="downloadTemplate('psat10')" v-if="courseTypeProp == 'SAT'">
            <img src="https://assets.website-files.com/5d6d56640b8020729636d3ce/5d6d56640b80204eac36d41e_001-spreadsheet-ascending-order.png" width="19" alt="" class="image-4">
            <span class="m-left-two-px">PSAT10 Template</span>
          </button>
          <div class="file-input-wrapper">
            <button class="btn btn-primary cursor-hand">
              <img src="https://assets.website-files.com/5d6d56640b8020729636d3ce/5d6d56640b802048cb36d41f_002-upload.png" width="19" alt="" class="image-4">
              <span class="m-left-two-px">Select Assessment Result</span>
            </button>
            <input
            id="mass-import-file"
              type="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              @change="onFileChange">
          </div>
        </div>
      </div>
    </template>
  </horizon-modal>
</template>

<script>
import HorizonModal from '@shared/horizon_modal'

export default {
  components: {
    HorizonModal
  },
  props: {
    cancel: {
      type: Function,
      required: true
    },
    practiceTestSetIdProp: { type: [String,Number], required: true },
    assessmentCourseIdProp: { type: [Number], required: false, default: null},
    schoolIdProp: { type: [String,Number], required: true },
    courseTypeProp: { type: [String,Number], required: true }
  },
  data () {
    return {
      fileUpload: {
        file: '',
        fileName: ''
      },
      fileSizeInfo: '0 kbytes'
    }
  },
  computed: {
    filePresent () {
      return !this.isBlank(this.fileUpload.file)
    }
  },
  methods: {
    attachImage (file) {
      // const image = new Image() // eslint-disable-line no-unused-vars
      if (this.isBlank(this.fileUpload.fileName)) {
        this.fileUpload.fileName = file.name.replace(/\.[^/.]+$/, '')
      }
      const reader = new FileReader()
      const vm = this
      reader.onload = (e) => { vm.fileUpload.file = e.target.result }
      reader.readAsDataURL(file)
    },
    downloadTemplate (courseType) {
      window.location = '/downloads/'+ courseType +'_result.xlsx'
    },
    onFileChange (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) { return }
      this.fileSizeInfo = files[0].size/1000 + ' KB'
      this.attachImage(files[0])

    },

    removeFile (e) { // eslint-disable-line no-unused-vars
      this.resetImport()
    },

    resetImport () {
      this.fileUpload = { file: '', fileName: '' }
    },

    saveImport () {
      this.$http.post(`/api/admin/schools/${this.schoolIdProp}/practice_test_sets/${this.practiceTestSetIdProp}/test_result_imports`, { 
        import: this.fileUpload,
        assessment_course_id: this.assessmentCourseIdProp
      }, { emulateJSON: true }).then(() => {
        this.appendSuccess({ message: 'Created import successfully', notification: true })
        this.resetImport()
        this.cancel()
      }, (responseError) => {
        this.appendError({error: responseError.body.error, notification: true})
      })
    }
  }
}
</script>

<style scoped lang="scss">
  @import '~@styles/variables.scss';
  h5 {
    font-size: 12px;
    line-height: 150%;
  }
  .model-para-heading {
    margin-top: 0px;
    margin-bottom: 6px;
    font-family: 'Open Sans', sans-serif;
    color: #345165;
    font-weight: 700;
  }
  .model-para {
    margin-top: 0px;
    margin-bottom: 0px;
    color: #505050;
    font-weight: 400;
  }
  .file-input-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;

    input[type=file] {
      font-size: 100px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
    }
  }
  .m-left-two-px {
    margin-left: 2px;
  }
</style>
