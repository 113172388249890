<template>
  <div class="container">
    <div class="content-pane">
      <div class="pane-wrapper">
        <breadcrumbs class="breadcrumbs"/>
        <div class="pt-2 row">
          <div class="col-md-3">
            <div class="content-pane no-shadow">
              <course-schedule :user-type-prop="userType"/>
            </div>
          </div>
          <div class="col-md-9">
            <course-list/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@shared/breadcrumbs'
import CourseSchedule from '@shared/parent_student/course_schedule'
import CourseList from './list'

export default {
  components: {
    Breadcrumbs,
    CourseSchedule,
    CourseList
  },
  data () {
    return {
      breadcrumbs: [],
      userType: 'student'
    }
  },
  created () {
    // GET DATA
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
@import '~@styles/variables.scss';

.dashboard-content-wrapper {
  padding: 20px 30px 30px 30px;
}

.col-9 {
  padding-left: 10px;
}

.col-3 {
  padding-right: 10px;
}

.breadcrumbs {
  display: block;
}

@media screen and (max-width: $mobileScreenLarge) {
  .pane-wrapper {
    padding: 10px !important;

    .col-md-3 {
      padding-right: 0px;
    }
  }  
}

@media screen and (max-width: $mobileScreenMedium) {
  .breadcrumbs {
    display: none;
  }

  .content-pane .no-shadow {
    border: 0;
  }

  .pane-wrapper {
    padding: 20px 30px 30px 30px !important;

    .col-md-3 {
      padding-right: 0px;
      padding-left: 0px;
    }

    .col-md-9 {
      padding-right: 0px;
      padding-left: 0px;
    }
  }  

  .calendar-container {
    padding: 10px 0px 15px 0px;
  }
}

@media screen and (max-width: $mobileScreenSmall) {
  .container {
    padding: 0;
  }

  .content-pane {
    border-radius: 0;
  }

  .pane-wrapper {
    padding: 10px !important;

    .col-md-3 {
      padding-right: 15px;
      padding-left: 15px;
    }

    .col-md-9 {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}
</style>
