<template>
  <div class="nav-menu-mobile-container">
    <router-link :to="{ name: 'StudentDashboard' }" class="menu-link-mobile head">
      <div class="nav-menu-div">
        <div class="nav-menu-title-text">Dashboard</div>
      </div>
    </router-link>
    <router-link :to="{ name: 'StudentAssessmentResults' }" class="menu-link-mobile head">
      <div class="nav-menu-div">
        <div class="nav-menu-title-text">Assessment Results</div>
      </div>
    </router-link>
    <router-link :to="{ name: 'StudentPracticeAssessments' }" class="menu-link-mobile head">
      <div class="nav-menu-div">
        <div class="nav-menu-title-text">Practice Assessments</div>
      </div>
    </router-link>
    <router-link :to="{ name: 'StudentCourseActivities' }" class="menu-link-mobile head" v-if="showCoursesLink()" >
      <div class="nav-menu-div">
        <div class="nav-menu-title-text">Course Activities</div>
      </div>
    </router-link>
    <router-link :to="{ name: 'StudentCourseSchedule' }" class="menu-link-mobile head">
      <div class="nav-menu-div">
        <div class="nav-menu-title-text">Course Schedule</div>
      </div>
    </router-link>
    <a :href="courseResourcesUrl" target="_blank" class="menu-link-mobile head">
      <div class="nav-menu-div">
        <div class="nav-menu-title-text">Course Resources</div>
      </div>
    </a>
    <router-link :to="{ name: 'StudentShareResults' }" class="menu-link-mobile head">
      <div class="nav-menu-div">
        <div class="nav-menu-title-text">Share My Results</div>
      </div>
    </router-link>
  </div>
</template>

<script>
import ExternalRoutes from '../routes/external_links'

export default {
  computed: {
    courseResourcesUrl() {
      return ExternalRoutes.STUDENT.COURSE_RESOURCES
    }
  },
  methods: {
    showCoursesLink() {
      //LMS GATEKEEPER: REMOVE AT RELEASE
      if (this.$route.query.showCourses == 1) return true
      return false
    }
  }
}

</script>

<style scoped lang='scss'>
  @import '~@styles/nav.scss';
</style>
