<template>
  <div>
    <div class="content-heading underlined">
      <div class="pull-left">
        <h1>Course Enrollment</h1>
      </div>
      <div class="pull-right total-student-number">
        <span class="d-inline student-total-number text-orange">
          {{ totalEnrollmentCount }}
        </span>
        <span class="d-inline fw-400">
          Students Enrolled
        </span>
      </div>
    </div>
    <student-table
      :schedule-id-filter="scheduleId"
      :student-list="enrollments"
      :is-enrollment-table="true"
      :fetch-students="fetchEnrollments"
      :schedule-repository="scheduleRepository"
      :student-repository="studentRepository"
      :enrollment-repository="enrollmentRepository"
      :show-pagination="showPagination"
      :meta="meta"
      :state-change-callback="updateTable"
      :is-admin="isAdmin"
      :school-info="schoolInfo"
      :can-create-enrollment="canCreateEnrollment()"
      :can-update-student="canUpdateStudent()"
      @created="updateEnrollmentCount"
      @schedule-filtered="changeEnrollmentList"
      @paginate-data="paginateData"
      @sort-data="sortData"/>

    <div v-if="enrollments.length == 0" class="text-center" style="margin-top: 25%">
      No enrollments exist yet.
    </div>
  </div>
</template>

<script>
// import { mapState } from 'vuex'
import StudentTable from './student_table'
import Mixins from '@submodules/mixins/mixin.js'

export default {
  components: {
    StudentTable
  },
  mixins: [Mixins],
  props: {
    scheduleRepository: {
      type: Object,
      required: true
    },
    studentRepository: {
      type: Object,
      required: true
    },
    enrollmentRepository: {
      type: Object,
      required: true
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    schoolInfo: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data () {
    return {
      scheduleId: null,
      enrollments: [],
      totalEnrollmentCount: 0,
      breadcrumbs: [],
      showPagination: false,
      meta: {
        page: 1,
        currentPage: 1,
        perPage: 100,
        totalCount: 0,
        totalPages: 0,
        sort: 'lastName',
        order: 'ASC'
      }
    }
  },
  created () {
    this.scheduleId = this.$route.params.scheduleId
    this.fetchEnrollments()
  },
  methods: {
    updateEnrollmentCount () {
      this.appendSuccess({ message: 'Enrolled new student successfully', notification: true })
    },
    getScheduleName () {
      if (this.scheduleId) {
        // this.$http.get(`/api/staff/schedules/${this.scheduleId}`).then(response => {
        //   this.breadcrumbs = [{ text: response.data.schedule.name }]
        // }, (responseError) => {
        //   this.appendError({ error: responseError, notification: true })
        // })
      } else {
        this.breadcrumbs = [{ text: 'Entire School' }]
      }
    },
    async fetchEnrollments () {
      console.log('FETCH ENROLLMENTS...')
      this.getScheduleName()
      const enrollmentData = await this.enrollmentRepository.list(this.scheduleId, this.meta)
      this.enrollments = enrollmentData.enrollments
      this.meta = enrollmentData.meta
      this.totalEnrollmentCount = this.meta.totalCount
      this.showPagination = true

    },
    indexParams () {
      if (this.scheduleId) {
        return { params: { filters: { schedule: this.scheduleId } } }
      }
      return {}
    },
    changeEnrollmentList (enrollments, schedule) {
      this.enrollments = enrollments.enrollments
      this.meta = enrollments.meta
      this.totalEnrollmentCount = enrollments.meta.totalCount
      this.scheduleId = schedule.id
    },
    updateTable() {

    },
    paginateData(meta) {
      this.meta.currentPage = meta.currentPage
      this.fetchEnrollments()
    },
    sortData(sortOptions) {
      this.meta.sort = sortOptions.field
      this.meta.order = sortOptions.direction
      this.fetchEnrollments()
    },
    canCreateEnrollment() {
      return this.$store.getters.userPermit({controller: 'Enrollment', action: 'create'})
    },
    canUpdateStudent() {
      return this.$store.getters.userPermit({controller: 'Student', action: 'update'})
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@styles/variables';

.total-student-number {
  display: inline-block;
  margin: 5px 0px 5px 6px;
  clear: none;
  color: #333;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
}

.student-total-number {
  display: inline-block;
  color: #f4701f;
  font-size: 28px;
  font-weight: 700;
}

@media screen and (max-width: $mobileScreenMedium) {
  .total-student-number {
    font-size: 20px;
  }

  .student-total-number {
    font-size: 22px;
  }

  .content-heading {
    display: block;

    .pull-left {
      width: 100%;
    }

    .pull-right {
      width: 100%;
      float: left;
      text-align: left;
      margin-left: 0px;
    }
  }

  .underlined {
    border-bottom: 0px;
  }
}
</style>
