<template>
  <button
    role="button"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
    :class="dropdownClass">
    <slot/>

    <i class="dropdown-icon fa fa-chevron-down text-orange" aria-hidden="true"/>

    <div
      v-if="items.length > 0"
      class="dropdown-menu text-left">
      <a
        v-for="(option, idx) in items"
        :key="idx"
        class="dropdown-item text-decoration-0 text-wrap"
        href="#"
        @click="menuItemClicked(option)">
        <slot name="option" :item="option">
          <h3>{{ itemPrefix }}{{ option[itemDisplayKey] }}</h3>
        </slot>
      </a>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    itemDisplayKey: {
      type: String,
      required: false
    },
    itemPrefix: {
      default: '',
      type: String,
      required: false
    },
    onItemSelect: {
      type: Function,
      required: true
    },
    dropdownClass: {
      type: String,
      required: false,
      default() { return 'align-items-center btn btn-outline d-flex d-inline dropdown horizon-dropdown horizon-input justify-content-between mr-2 no-hover' }
    }
  },
  methods: {
    menuItemClicked (item) {
      this.onItemSelect(item)
    }
  }
}
</script>
