<template>
  <div class="container">
    <div class="content-pane">
      <div class="pane-wrapper">
        <breadcrumbs class="breadcrumbs"/>
        <desktop-only-view>
          <div class="course-pane">
            <div class="course-header">
              <header-course
                :course-activity="courseActivity"
                :editing="editMode"
                @edit-text-update="editTextUpdate"
                @section-update="loadCourse()"></header-course>
              <header-buttons
                :editing="editMode"
                :locked="courseActivity.is_locked"
                :dragging="dragMode"
                @startEdit="startEdit"
                @cancelEdit="cancelEdit"
                @saveEdit="saveEdit"
                @startReorder="startReorder"
                @cancelReorder="cancelReorder"
                @saveReorder="saveReorder"
                @lock="lock(...arguments)"></header-buttons>
            </div>
            <draggable v-model="courseActivity.he_course_section" class="sections-container" v-bind="dragOptions">
              <transition-group tag="div">
                <div v-for="(section,index) in courseActivity.he_course_section" :key="'section'+index">
                  <section-pane
                    :id="'section-'+section.id"
                    :section="section"
                    :course-id="courseActivity.id"
                    :editing="editMode"
                    :dragging="dragMode"
                    @edit-text-update="editTextUpdate"
                    @section-update="loadCourse()"
                    @get-section="getSection($event)"
                    @get-lesson="getLesson($event)"></section-pane>
                </div>
              </transition-group>
            </draggable>
            <create-lesson-modal :section="setSection" @newLesson="createLesson($event)"></create-lesson-modal>
            <edit-lesson-modal :lesson="setLesson" @editLesson="editLesson($event)"></edit-lesson-modal>
            <create-section-pane :course-activity="courseActivity" @load-course="loadCourse()" ></create-section-pane>
          </div>
        </desktop-only-view>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@shared/breadcrumbs'
import SectionPane from './section.vue'
import HeaderButtons from './show/header_buttons.vue'
import CreateSectionPane from './section/new_section.vue'
import HeaderCourse from './show/header_course.vue'
import CreateLessonModal from './section/modals/create_lesson_modal.vue'
import EditLessonModal from './section/modals/edit_lesson_modal.vue'
import DesktopOnlyView from '@shared/desktop_only'
import Draggable from 'vuedraggable'

export default {
  components: {
    Breadcrumbs,
    SectionPane,
    HeaderButtons,
    CreateSectionPane,
    HeaderCourse,
    CreateLessonModal,
    EditLessonModal,
    DesktopOnlyView,
    Draggable
  },
  data() {
    return {
      courseActivity: {},
      editMode: false,
      editableTextEdits: {},
      schedule_id: null,
      course_id: null,
      template_id: null,
      course: {},
      setSection: {},
      dragMode: false,
      tempSections: {},
      tempLessons: {},
      courseOrderEdits: {},
      setLesson: {
        name: null,
        memo: null,
        instructional_time: null,
        independent_practice_time: null,
        learnosity_activity_banks: {
          practice_set: null,
          independent_lesson: null,
        },
        support_materials: {
          google: {
            name: "Google Slides Presentation",
            url: null,
          },
          support_videos: {
            name: "Support Video",
            url: null,
          }
        },
        slide_presentation_link: null,
        support_video_link: null,
      },
    }
  },
  methods: {
    editTextUpdate(identity, text_edit) {
      if (identity.course_id){
        this.course[identity.field] = text_edit
      } else {
        let section = this.editableTextEdits[identity.section_id]

        if (section === undefined ) {
          section = {
            section_id: identity.section_id,
            name: '',
            memo: '',
            section_order: identity.section_order,
            lessons: []
          }
        }

        if (identity.hasOwnProperty('lesson_id')) {
          let lesson = {
            lesson_id: identity.lesson_id,
            lesson_order: identity.lesson_order,
            [identity.target]: text_edit
          }
          let lesson_index = section.lessons.findIndex(e => e.lesson_id == identity.lesson_id)
          if (lesson_index < 0) {
            section.lessons.push(lesson)
          } else {
            section.lessons[lesson_index] = lesson
          }
        } else {
          section[identity.target] = text_edit
          section.section_order = identity.section_order
        }

        this.editableTextEdits[identity.section_id] = section
      }
    },
    startEdit() {
      this.appendSuccess({message: 'Editing started.', notification: true})
      this.editMode = true
    },
    cancelEdit() {
      this.appendSuccess({message: 'Editing canceled.', notification: true})
      this.editableTextEdits = {}
      this.editMode = false
    },
    saveEdit() {
      if (Object.keys(this.editableTextEdits).length > 0 || Object.keys(this.course).length > 0 ) {
        this.updateCourse()
        this.editableTextEdits = {}
      }
      this.editMode = false
    },
    updateCourse() {
      let data = {
        course_id: this.course_id,
        template_id: this.template_id,
        text_updates: this.editableTextEdits,
        course: this.course,
      }

      this.$http.put(`/api/admin/course_activities/${this.course_id}`,{updates: data}).then((res) => {
        this.loadCourse()
      }, (responseError) => {
        this.appendError({error: responseError, notification: true})
      })
    },
    loadCourse() {
      this.$http.get(`/api/admin/course_activities/templates/${this.template_id}}`).then((result) => {
        this.courseActivity = result.data
      }, (responseError) => {
         this.appendError({error: responseError, notification: true})
      })
    },
    getSection(section){
      this.setSection = section
    },
    newLessonFormatData(newLesson){
      var support_materials = {
        google: {
          url: newLesson.slide_presentation_link,
          name: 'Google Slides Presentation'
        },
        support_videos: {
          url: newLesson.support_video_link,
          name: 'Support Video'
        }
      }
      var activity_banks = {
        independent_lesson: newLesson.independent_lesson,
        practice_set: newLesson.practice_set,
      }
      return {
        course_section_id: this.setSection.id,
        lesson_name: newLesson.name,
        lesson_memo: newLesson.memo,
        order: this.setSection.he_course_lesson.length++,
        instructional_time: newLesson.instructional_time,
        independent_practice_time: newLesson.independent_practice_time,
        learnosity_activity_banks: activity_banks,
        support_materials: support_materials,
      }
    },
    createLesson: function(newLesson){
      this.$http.post(`/api/admin/course_activities/${this.setSection.id}/lessons`, this.newLessonFormatData(newLesson)).then((result) => {
        this.loadCourse()
        this.appendSuccess({ message: 'Lesson Created', notification: true })
      }), (responseError) => {
        this.appendError({error: responseError.body.error, notification: true})
      }
    },
    editLesson(lesson){
      this.$http.put(`/api/admin/course_activities/${lesson.he_course_section_id}/lessons`,lesson).then(() => {
        this.loadCourse()
        this.appendSuccess({message: 'Lesson Updated', notification: true})
      }, (responseError) => {
        this.appendError({error: responseError, notification: true})
      })
    },
    getLesson(lesson){
      this.setLesson = lesson
    },
    startReorder(){
      this.appendSuccess({message: 'Reordering started.', notification: true})
      this.dragMode = true
      this.copyCourseOrder()
    },
    cancelReorder(){
      this.appendSuccess({message: 'Reordering canceled.', notification: true})
      this.dragMode = false
      this.restoreCourseOrder()
    },
    copyCourseOrder() {
        this.originalCourseActivity = JSON.parse(JSON.stringify(this.courseActivity))
      },
    restoreCourseOrder() {
      this.courseActivity = JSON.parse(JSON.stringify(this.originalCourseActivity))
    },
    saveReorder() {
      this.dragMode = false
      this.updateCourseOrder()
      this.originalCourseActivity = {}
    },
    updateCourseOrder() {
      this.courseOrderEdits["sections"] = this.courseActivity.he_course_section.map( (section, index) => {
        return {
          "section_id": section.id,
          "order": index
        }
      })
      this.courseOrderEdits["lessons"] = this.courseActivity.he_course_section.map( (section) => { 
        return section.he_course_lesson.map( (lesson, index) => {
          return {
            "lesson_id": lesson.id,
            "section_id": section.id,
            "order": index
          }
        })
      }).flat()
      let data = {
        // schedule_id: this.schedule_id,
        course_id: this.course_id,
        template_id: this.template_id,
        order_updates: this.courseOrderEdits
      }
      console.log("REORDER SAVE: ", data)
      this.$http.put(`/api/admin/course_activities/${this.courseActivity.he_course_template_id}`,
        {updates: data}).then((res) => {
          this.loadCourse()
        }, (responseError) => {
          this.appendError({error: responseError, notification: true})
        })
    },
  },
  created () {
    this.courseActivity = this.$route.query,
    this.course_id = this.$route.query.courseId,
    this.template_id =this.$route.query.templateId,
    this.loadCourse()
  },
  computed: {
    dragOptions() {
      return {
        animation: 60,
        group: "sections",
        disabled: !this.dragMode,
        swap: true,
        swapThreshold: 0.25
      }
    }
  }
}
</script>
<style scoped lang="scss">
  @import '~@styles/variables';

  .sections-container {
  }

  .course-header {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    margin: 0px 15px 0px 15px;
    margin-top: 0px;
    padding: 0px 0px 20px 0px;
    flex-direction: column;

    h2 {
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 36px;
      font-weight: 700;
      line-height: 150%;
      text-align: left;
    }

  }

  .course-pane {
    border-color: #c7c7c7;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    padding: 10px 15px 15px;
  }

</style>
