import client from '@repositories/client.js'

const BASE_URL = '/api/student_signups'

export default new class {

  async register(enrollment) {
    const data = await client.post(BASE_URL, {enrollment: enrollment})
    return data.schools
  }

  async fetchSchools() {
    const data = await client.get(`${BASE_URL}/schools`)
    return data.schools
  }

  async fetchLiveEnrollmentSchedules() {
    const data = await client.get(`${BASE_URL}/schedules`)
    return data.schedules
  }

}
