<template>
  <div class="col-md-12">
    <div class="admin-school content-heading">
      <h1>Session Overview Dashboard</h1>
    </div>
    <div class="bordered-card pt-0">
      <div class="primary-row">
        <h2>SCHEDULES</h2>
        <div class="primary-row-action">
          <div class="flex-y-center">
            <h1 class="pr-2 text-orange">
              {{ school.studentsEnrolled }}
            </h1>
            <div>
              Students Enrolled
            </div>
            <button
              class="btn btn-outline"
              @click="sendSmsModal">
              Send SMS
            </button>
            <button
              class="btn btn-outline"
              @click="sendEmailModal">
              Send Email
            </button>
          </div>
        </div>
      </div>
      <app-schedules-list
        :schedules="schedules"
        :staff-view="false"/>
    </div>
    <div class="card-row">
      <div class="bordered-card py-0">
        <div class="primary-row">
          <h2>SESSION INFORMATION</h2>
        </div>
        <div class="primary-row-container">
          <div class="primary-row">
            <div class="label">
              Course Type
            </div>
            <h4 class="font-12 text-primary">
              {{ school.courseType }}
            </h4>
          </div>
          <div class="primary-row">
            <div class="label">
              Seats Purchased
            </div>
            <h4 class="font-12 text-primary">
              {{ school.seatsPurchased }}
            </h4>
          </div>
          <div class="primary-row">
            <div class="label">
              Enrollment Cap
            </div>
            <h4 class="font-12 text-primary">
              {{ school.maxEnrollment }}
            </h4>
          </div>
          <div v-for="(user, uIndx) in school.users" :key="uIndx" class="primary-row">
            <div class="label">
              Administrator #{{ uIndx + 1 }}
            </div>
            <h4 class="font-12 text-primary">
              {{ user.firstName }} {{ user.lastName }}
            </h4>
          </div>
        </div>
      </div>
      <div class="bordered-card py-0">
        <div class="no-underline primary-row">
          <h2>TEST DATES</h2>
        </div>
        <div v-for="(date, dIndx) in testDates" :key="dIndx">
          <div class="test-date">
            {{ date.date }}
          </div>
          <div v-for="(schedule, sIndx) in date.schedules" :key="dIndx + '-' + sIndx" class="primary-row">
            <div class="schedule-row-column">
              <div class="fw-600 text-primary">
                {{ schedule.practiceTestName }}
              </div>
              <div>
                {{ schedule.testTime }}
              </div>
            </div>
            <div class="schedule-row-column">
              <div class="fw-600 text-primary">
                {{ schedule.schoolName }}
              </div>
              <div>
                {{ schedule.scheduleName }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-email-modal
      v-if="school"
      id="school-email-modal"
      :schedule="school"
      :school-level="true"/>
    <app-sms-modal
      v-if="school"
      id="school-sms-modal"
      :schedule="school"
      :school-level="true"/>
  </div>
</template>

<script>
import Mixins from '@submodules/mixins/mixin.js'
import SchedulesList from '@shared/schedules_list.vue'
import EmailModal from '@shared/send_email_modal.vue'
import SmsModal from '@shared/send_sms_modal.vue'

export default {
  components: {
    'app-schedules-list': SchedulesList,
    'app-email-modal': EmailModal,
    'app-sms-modal': SmsModal
  },
  mixins: [Mixins],
  data () {
    return {
      schedules: [],
      testDates: [],
      school: {}
    }
  },
  created () {

    this.loadSchool()
    this.loadSchedules()
    this.loadTestDates()
  },
  computed: {
    schoolId() {
      return this.$route.params.id
    }
  },
  methods: {
    async loadSchool () {
      const response = await this.$http.get(`/api/admin/schools/${this.schoolId}`)
      this.school = response.data.school
    },
    loadSchedules () {
      this.$http.get(`/api/admin/schools/${this.schoolId}/schedules`).then(response => {
        this.schedules = response.data.schedules
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    },
    loadTestDates () {
      this.$http.get(`/api/admin/schools/${this.schoolId}/practice_test_dates_summary`).then(response => {
        this.testDates = response.data.testDates
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    },
    sendEmailModal () {
      $('#school-email-modal').modal('show')
    },
    sendSmsModal () {
      $('#school-sms-modal').modal('show')
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@styles/variables.scss';

.card-row {
  display: flex;
  justify-content: space-between;
  > .bordered-card {
    margin-bottom: auto;
    width: calc(50% - 8px);
  }
}
.primary-row {
  height: 70px;
}

.test-date {
  text-transform: uppercase;
  color: $themeOrange;
  font-size: 14px;
  font-weight: 700;
  border-bottom: 1px solid #F0F0F0;
  padding: 10px;
}

.schedule-row-column {
  display: flex;
  flex-direction: column;
  width: 50%;
  font-size: 12px;
}

</style>
