<template>
  <div class="signup-page">
    <div class="nav-outer">
      <div class="navigation-home">
        <div class="nav-contain">
          <a href="#" class="nav-link-logo w-inline-block">
            <img
              src="https://uploads-ssl.webflow.com/5b3e7638a5f0ce22e43072d6/5b3fb4742cfda0096da0b28c_HorizonEducation_Logo.png"
              alt
              class="link-logo"
            />
          </a>
          <slot name="header-nav-button"></slot>
        </div>
      </div>
    </div>
    <div class="mx-auto page-contain">
      <div class="login-out-div">
        <div class="hero">
          <div class="hero-left-side-2">
            <slot name="content"></slot>
          </div>
          <div class="hero-right-side">
            <img
              src="/images/hero-update.png"
              srcset="images/hero-update-p-500.png 500w, images/hero-update-p-800.png 800w, images/hero-update.png 1068w"
              sizes="(max-width: 991px) 100vw, 47vw"
              alt
              class="image-16"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
@import "~@styles/session/webflow.scss";
@import "~@styles/session/normalize.scss";
@import "~@styles/session/sign_up.scss";

.signup-page {
  background-color: #ffffff;
  min-height: 100vh;
  height: 100%;
}
</style>
