<template>
  <staff-student-results-pane
    :is-horizon-admin="true"
    :admin-assessment-type="'SAT'"/>
</template>

<script>
import StaffStudentResultsPane from '@staff/shared/student_results_pane.vue'

export default {
  components: {
    StaffStudentResultsPane,
  },
}
</script>