<template>
  <session-base-layout>

    <template v-if="false" v-slot:header-nav-button>
      <div class="nav-links-right">
        <router-link :to="{ name: 'signUp' }" class="nav-button">Student Enrollment</router-link>
      </div>
    </template>

    <template v-slot:content>
      <div class="enrollment login-outer">
        <div class="login-center-div">
          <app-common-messages :messages="appMessages" class="col-md-5 mx-auto" @resetMessages="resetAppMessages"/>
          <google-signin
            :params="googleParams"
            @success="onSignInSuccess"
            @error="onSignInError">
            <div>
              <svg width="25" height="25" viewBox="0 0 256 262" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid"><path d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027" fill="#4285F4"/><path d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1" fill="#34A853"/><path d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782" fill="#FBBC05"/><path d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251" fill="#EB4335"/></svg>
            </div> Sign in with Google
          </google-signin>
          <hr class="grey-line">
          <div class="sign-in-form w-form">
            <form id="login-form" class="sign-in-form" @submit.prevent>
              <div class="sign-in-field">
                <label for="email" class="sign-in-form-label">Email</label>
                <input
                  id="email"
                  v-model="login.email"
                  type="text"
                  :class="['sign-in-form-field', 'w-input', {'highlighter-red': $v.login.email.$error }]"
                  autocomplete="off"
                  maxlength="256"
                  name="email"
                  data-name="email"
                  required
                />
              </div>
              <div class="sign-in-field">
                <label for="password" class="sign-in-form-label">Password</label>
                <input
                  id="password"
                  type="password"
                  :class="['sign-in-form-field', 'w-input', {'highlighter-red': $v.login.password.$error }]"
                  autocomplete="off"
                  maxlength="256"
                  name="password"
                  data-name="password"
                  v-model="login.password"
                  required
                />
              </div>
              <div class="w-100">
                <vue-recaptcha
                  ref="recaptcha"
                  sitekey="6LcJre0aAAAAAKobMw1zIGWpcUxxvy000wsW7_Yk"
                  @verify="recaptchaVerify">
                  <button type="button"
                    class="sign-in-button"
                    @click="sendLogin">
                    Sign In
                  </button>
                </vue-recaptcha>
              </div>
            </form>
          </div>
          <div class="sign-up-reg-text mb-3">
            <router-link
              :to="{ name: 'forgotPassword' }"
              class="rign-up-link">
              Forgot Password?
            </router-link>
          </div>

          <div v-if="false" class="sign-up-reg-text mb-3">
            Don&#x27;t have an account?
            <router-link :to="{ name: 'signUp' }" class="rign-up-link">Sign Up</router-link>
          </div>
          <br v-else>
          <div class="sign-up-reg-text mb-3">
            <a href="https://horizoneducation.com/student-faq"
              target="_blank"
              class="rign-up-link">
              Student: Frequently Asked Questions
            </a>
          </div>
          <div class="sign-up-reg-text">
            <a href="https://horizoneducation.com/test-proctor-faq"
              target="_blank"
              class="rign-up-link">
              Test Proctor: Frequently Asked Questions
            </a>
          </div>
        </div>
      </div>
    </template>
  </session-base-layout>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import SessionBaseLayout from '@components/session/base.vue'
import { required, email } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
import CommonMessages from "@submodules/components/common_messages.vue";
import Mixins from "@submodules/mixins/mixin.js";
import HubspotMixin from "@submodules/mixins/hubspot.js";
import { userRoles } from "@utils/userRoles";

export default {
  components: {
    appCommonMessages: CommonMessages,
    SessionBaseLayout,
    VueRecaptcha
  },
  mixins: [Mixins, HubspotMixin],
  validations: {
    login: {
      email: { required, email },
      password: { required }
    }
  },
  data() {
    return {
      login: {
        email: "",
        password: ""
      },
      googleParams: {
        cookiepolicy: 'single_host_origin',
        scope: 'profile email',
        prompt: 'select_account',
      },
      recaptchaVerified: false,
    };
  },
  created() {
    if (this.$route.query.url != undefined) {
      this.$router.push(this.$route.query.url);
    }
    if (this.$route.query.unauthorized) {
      this.appendError({ error: "Unauthorized Request" });
    }
  },
  methods: {
    ...mapActions(["loadUserSession"]),
    ...mapGetters(["userRole","userSchedules"]),
    sendLogin() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (this.recaptchaVerified) {
        this.$http
          .post("/api/login", { login: this.login })
          .then(() => {
            this.loadUserSession().then(() => {
              this.redirect()
            });
          })
          .catch(({ response }) => {
            this.appendError({ error: response.data.error.message.toString() });
          });
      }
    },
    onSignInSuccess (googleUser) {
      this.$http
        .post("/api/google/verify", {
          token: googleUser.getAuthResponse().id_token,
          email: googleUser.getBasicProfile().getEmail()
        })
        .then(() => {
          this.loadUserSession().then(() => {
            this.redirect()
          });
        })
        .catch(({ response }) => {
          this.appendError({ error: response });
        });
    },
    onSignInError (error) {
      console.log(error)
    },
    recaptchaVerify (response) {
      this.recaptchaVerified = true
      this.sendLogin()
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset()
    },
    redirect() {
      const role = this.userRole();
      const dashboards = {}
      dashboards[`${userRoles.ROLE_TEACHER_STAFF}`] = "StaffDashboard"
      dashboards[`${userRoles.ROLE_TEACHER_ADMIN}`] = "StaffDashboard"
      dashboards[`${userRoles.ROLE_HORIZON_ADMIN}`] = "AdminDashboard"
      dashboards[`${userRoles.ROLE_PARENT}`] = "ParentDashboard"
      dashboards[`${userRoles.ROLE_STUDENT}`] = "StudentCourses"
      dashboards[`${userRoles.ROLE_DISTRICT_ADMIN}`] = "DistrictAdminDashboard"

      const roleMap = {}
      roleMap["staff"] = [userRoles.ROLE_TEACHER_STAFF, userRoles.ROLE_TEACHER_ADMIN]
      roleMap["admin"] = [userRoles.ROLE_HORIZON_ADMIN]
      roleMap["parent"] = [userRoles.ROLE_PARENT]
      roleMap["student"] = [userRoles.ROLE_STUDENT]
      roleMap["district_admin"] = [userRoles.ROLE_DISTRICT_ADMIN]

      var fullPath = this.$route.query.redirect
      if (fullPath) {
        var role_path = fullPath.split("/")[1]
        // Prevent users from directly accessing a dashboard that doesn't match their role
        if (roleMap[role_path].includes(role)) {
          this.$router.push(this.$route.query.redirect)
        } else {
          this.$router.push({ name: dashboards[role] });
        }
      } else {
        this.$router.push({ name: dashboards[role] });
      }
    }
  }
};
</script>
<style scoped lang="scss">
.sign-in-button {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 15px;
  padding-top: 14px;
  padding-bottom: 14px;
  border-radius: 5px;
  background-color: #f4701f;
  border-color: #f4701f;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
}
.google-signin {
  display: inline-block;
  padding: 6px 19px 6px 6px;
  border-radius: 5px;
  background-color: #4284F4;
  color: #fff;
  font-weight: 700;
  margin: 10px 0 15px;
  cursor: pointer;
  font-size: 23px;
  font-family: Roboto, sans-serif;

  &:focus, &:hover {
    background: #5A93D6;
    border: 0;
    outline: 0;
  }

  & > div {
    display: inline-block;
    background: #FFFFFF;
    padding: 14px;
    border-radius: 3px;
    margin-right: 7px;
    border: 1px solid #4285F4;
    box-shadow: 0px 1px 2px #333;
  }
}
.grey-line {
  width: 100%;
  border-top: 1px solid #dcdcdd;
}
.sign-in-form {
  padding: 8px 10px;

  &.w-form {
    margin-bottom: 0;
  }
}
</style>
