const MAXIMUM_VALUE_OFFSET = 2
const MINIMUM_VALUE_OFFSET = 2

export default class {

  constructor (studentPracticeTests) {
    this.studentPracticeTests = studentPracticeTests
  }

  dataSet () {
    return _.map(this.studentPracticeTests, (assessment) => ({ x: assessment.name, y: assessment.score }))
  }

  minimumScaleValue () {
    const minimumScore = _.min(_.map(this.studentPracticeTests, 'score'))
    return minimumScore - MINIMUM_VALUE_OFFSET
  }

  maximumScaleValue () {
    const maximumScore = _.max(_.map(this.studentPracticeTests, 'score'))
    return maximumScore + MAXIMUM_VALUE_OFFSET
  }

}
