<template>

  <div v-if="isDefault" class="header-buttons">
    <a class="btn btn-outline header-btn mb-1" @click="$emit('startEdit')">
      <img class="btn-icon header-btn-icon" src="/images/pencil.png">
      <div class="header-btn-text">Edit Course</div>
    </a>
    <a class="btn btn-outline header-btn mb-1" @click="$emit('startReorder')">
      <img class="btn-icon header-btn-icon" src="/images/pencil.png">
      <div class="header-btn-text">Reorder Activities</div>
    </a>
    <a v-if="hasGradebook" class="btn btn-outline header-btn mb-1" @click="$emit('gradebookRedirect')">
      <img class="btn-icon header-btn-icon" src="/images/grade.png">
      <div class="header-btn-text">Course Gradebook</div>
    </a>
    <a v-if="isLocked()" class="btn btn-outline header-btn mb-1" @click="$emit('lock','unlock')">
      <img class="btn-icon header-btn-icon" src="/images/lock.png">
      <div class="header-btn-text">Unlock Lessons for Entire Course</div>
    </a>
    <a v-else class="btn btn-outline header-btn mb-1" @click="$emit('lock','lock')">
      <img class="btn-icon header-btn-icon" src="/images/release_button.png">
      <div class="header-btn-text">Relock Lessons for Entire Course</div>
    </a>
  </div>

  <div v-else-if="editing" class="header-buttons">
    <a class="btn btn-outline header-btn" @click="$emit('cancelEdit')">
      <img class="btn-icon header-btn-icon" src="/images/pencil.png">
      <div class="header-btn-text">Cancel Edits</div>
    </a>
    <a class="btn btn-outline header-btn" @click="$emit('saveEdit')">
      <img class="btn-icon header-btn-icon" src="/images/release_button.png">
      <div class="header-btn-text">Save Course</div>
    </a>
  </div>

  <div v-else class="header-buttons">
    <a class="btn btn-outline header-btn" @click="$emit('cancelReorder')">
      <img class="btn-icon header-btn-icon" src="/images/pencil.png">
      <div class="header-btn-text">Cancel Reordering</div>
    </a>
    <a class="btn btn-outline header-btn" @click="$emit('saveReorder')">
      <img class="btn-icon header-btn-icon" src="/images/release_button.png">
      <div class="header-btn-text">Save Arrangement</div>
    </a>
  </div>

</template>
<script>
export default {
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    dragging: {
      type: Boolean,
      default: false
    },
    locked: {
      type: Boolean,
      default: false
    },
    hasGradebook: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    isLocked() {
      return this.locked == true || this.locked == null
    },
  },
  computed: {
    isDefault() {
      return this.editing == false && this.dragging == false
    }
  }
}
</script>
<style scoped lang="scss">
  .header-buttons {
    display: flex;
    flex-wrap: wrap;
    a {
      border-color: #c7c7c7;
      background-color: white;
    }
    a:hover {
      border-color: #345165;
      color: #345165;
    }
  }

  .header-btn-text {
    font-size: 12px;
    font-weight: 400;
  }

  .header-btn-icon {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }

 .header-btn-text {
    font-size: 12px;
    font-weight: 400;
  }

  .header-btn-icon {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }


</style>
