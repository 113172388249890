<template>
  <div
    class="fade hide horizon-modal modal"
    role="dialog"
    tabindex="-1"
    aria-labelledby="confirm">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div
          class="close-modal"
          data-dismiss="modal"
          data-ix="close-message"
          style="transition: background-color 0.25s ease 0s;"
          @click="closeModal">
          <div class="close-x">
            X
          </div>
        </div>
        <div class="modal-header">
          <h1 class="modal-heading">
            {{ data.question }}
          </h1>
        </div>
        <div class="modal-body">
          <div class="modal-container">
            <div class="modal-left">
              <div class="question-container">
                <div class="question-item">
                  <div>
                    Section:
                  </div>
                  <div>
                    {{ section }}
                  </div>
                </div>
                <div class="question-item">
                  <div>
                    Sub Section:
                  </div>
                  <div>
                    {{ data.subsectionAreaName }}
                  </div>
                </div>
                <div class="question-item">
                  <div>
                    Area:
                  </div>
                  <div>
                    {{ data.topicAreaName }}
                  </div>
                </div>
                <div class="question-item">
                  <div>
                    Correct Answer:
                  </div>
                  <div>
                    {{ data.correctAnswer }}
                  </div>
                </div>
                <div class="question-item">
                  <div>
                    Your Answer:
                  </div>
                  <div :class="{ 'wrong-answer': !data.studentAnswerCorrect }">
                    {{ data.studentAnswer }}
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-right">
              <img :src="data.imageUrl">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    section: {
      type: String,
      default: ''
    },
    onClose: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    closeModal () {
      this.onClose()
    }
  }
}
</script>

<style scoped lang='scss'>
@import '~@styles/variables.scss';
.question-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 15px;
  .question-item {
    font-size: 12px;
    > div:first-child {
      font-weight: 700;
      color: $themeOrange;
    }
    .wrong-answer {
      color: $themeRed;
      font-weight: 700;
    }
  }
}
</style>
