import StudentDashboard from '@student/dashboard/show.vue'
import StudentAssessmentResults from '@student/assessment_results/show.vue'
import StudentPracticeAssessments from '@student/practice_assessments/show.vue'
import StudentPracticeAssessmentSection from '@student/practice_assessments/assessment_section.vue'
import StudentCourseSchedule from '@student/course_schedule/show.vue'
import StudentShareResults from '@student/share_results/show.vue'
import StudentScoreReport from '@student/score_report/show.vue'
import StudentCourses from '@student/dashboard/courses.vue'
import StudentCourseActivities from '@student/course_activities/show.vue'

export default [
  {
    path: '/student',
    component: StudentDashboard,
    name: 'StudentDashboard',
    meta: { guestView: false }
  },
  {
    path: '/student/courses',
    component: StudentCourses,
    name: 'StudentCourses',
    meta: { guestView: false }
  },
  {
    path: '/student/assessment_results',
    component: StudentAssessmentResults,
    name: 'StudentAssessmentResults',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Dashboard', routerOptions: { name: 'StudentDashboard' } },
        { text: 'Assessment Results' }
      ]
    }
  },
  {
    path: '/student/practice_assessments',
    component: StudentPracticeAssessments,
    name: 'StudentPracticeAssessments',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Dashboard', routerOptions: { name: 'StudentDashboard' } },
        { text: 'Practice Assessments' }
      ]
    }
  },
  {
    path: '/student/practice_assessments/assessment',
    component: StudentPracticeAssessmentSection,
    name: 'StudentPracticeAssessmentSection',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Dashboard', routerOptions: { name: 'StudentDashboard' } },
        { text: 'Practice Assessments', routerOptions: { name: 'StudentPracticeAssessments' } },
        { text: 'Assessment Section' }
      ]
    }
  },
  {
    path: '/student/course_schedule',
    component: StudentCourseSchedule,
    name: 'StudentCourseSchedule',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Dashboard', routerOptions: { name: 'StudentDashboard' } },
        { text: 'Course Schedule' }
      ] }
  },
  {
    path: '/student/score_report/:studentPracticeTestId',
    component: StudentScoreReport,
    name: 'StudentScoreReport',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Dashboard', routerOptions: { name: 'StudentDashboard' } },
        { text: 'AssessmentResults', routerOptions: { name: 'StudentAssessmentResults' } },
        { text: 'Score Report' }
      ]
    }
  },
  {
    path: '/student/share_results',
    component: StudentShareResults,
    name: 'StudentShareResults',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Dashboard', routerOptions: { name: 'StudentDashboard' } },
        { text: 'Share Results' }
      ] }
  },
  {
    path: '/student/course_activities/',
    component: StudentCourseActivities,
    name: 'StudentCourseActivities',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Dashboard', routerOptions: { name: 'StudentDashboard' } },
        { text: 'Course Activities' }
      ]
    }
  }
]
