<template>
  <horizon-modal id="update-administrator-modal" modal-title="Update Administrator">
    <template v-slot:content>
      <div class="form">
        <div class="form-row">
          <div class="col-md-6 col-xs-12 form-group div-1 student-form-group">
            <label for="administrator-first-name" class="d-block">First Name</label>

            <input
            id="administrator-first-name"
            v-model="administratorEdits.firstName"
            :class="['student-form-input', { 'highlighter-red': $v.administratorEdits.firstName.$error }]">
          </div>
          <div class="col-md-6 col-xs-12 form-group div-1 student-form-group">
            <label for="administrator-last-name" class="d-block">Last Name</label>
            <input
            id="administrator-last-name"
            v-model="administratorEdits.lastName"
            type="text"
            :class="['student-form-input', { 'highlighter-red': $v.administratorEdits.lastName.$error }]">
          </div>
          <div class="col-md-12 col-xs-12 form-group div-1 student-form-group">
            <label for="administrator-email-address" class="d-block">Email Address</label>
            <input
            id="administrator-email-address"
            v-model="administratorEdits.email"
            type="text"
            :class="['student-form-input', { 'highlighter-red': $v.administratorEdits.email.$error }]">
          </div>
        </div>
        <div class="col-12 d-flex justify-content-end">
          <button class="btn btn-primary student-modal-button" @click="updateAdministrator">
            Update Administrator
          </button>
        </div>
      </div>
    </template>
  </horizon-modal>
</template>

<script>
import HorizonModal from '@shared/horizon_modal'
import { required, email } from 'vuelidate/lib/validators'
export default {
  components: {
    HorizonModal,
  },
  props: {
    selectedAdministrator: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  watch: {
    selectedAdministrator: {
      immediate: true,
      handler(val, oldVal) {
        this.administratorEdits = this.selectedAdministrator
      }
    },
  },
  data() {
    return {
      administratorEdits: {
        firstName: '',
        lastName: '',
        email: '',
        role: 'District Admin',
      },
    }
  },
  validations: {
    administratorEdits: {
      firstName: { required },
      lastName: { required },
      email: { required, email },
    }
  },
  methods: {
    updateAdministrator () {
      this.$v.$touch()
      if(this.$v.$invalid) { return }

      $('#update-administrator-modal').modal('hide')
      this.$emit('administrator-updated', this.administratorEdits)
    },
  }
}
</script>

<style scoped lang='scss'>
@import "~@styles/shared/students/student-form.scss";
</style>
