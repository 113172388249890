import StudentRepository from '@repositories/admin/student'
import SchoolEnrollmentRepository from '@repositories/admin/enrollment'
import ScheduleRepository from '@repositories/admin/schedule'
import InstructorRepository from '@repositories/admin/instructor'
import PracticeTestRepository from '@repositories/admin/practice_test'
import CourseTemplateRepository from '@repositories/admin/course_template'

import TargetAssessmentDateRepository from '@repositories/admin/assessment_date'

import client from '@repositories/client.js'
class SchoolRepositoryFactory {

  constructor (schoolId) {
    this.schoolId = schoolId
  }

  async info () {
    const data = await client.get(`/api/admin/schools/${this.schoolId}`)
    return data.school
  }

  get (name) {
    switch (name) {
    case 'enrollment':
      return new SchoolEnrollmentRepository(this.schoolId)
    case 'student':
      return new StudentRepository(this.schoolId)
    case 'instructor':
      return new InstructorRepository(this.schoolId)
    case 'schedule':
      return new ScheduleRepository(this.schoolId)
    case 'practice_test':
      return new PracticeTestRepository(this.schoolId)
    }
  }
}

export default {
  student: StudentRepository, // TODO: remove?
  schedule: ScheduleRepository, // TODO: remove?
  target_assessment_date: new TargetAssessmentDateRepository(),
  course_template: new CourseTemplateRepository(),

  school (schoolId) {
    return new SchoolRepositoryFactory(schoolId)
  }
}
