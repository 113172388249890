<template>
  <div class="container">
    <div class="content-pane">
      <div class="pane-wrapper">
        <breadcrumbs class="breadcrumbs"/>

        <desktop-only-view>
          <div class="course-pane">
            <div class="course-header">
              <h2> {{ courseActivity.name }}</h2>
              <header-buttons
                :editing="editMode"
                :dragging="dragMode"
                :locked="courseActivity.is_locked"
                :has-gradebook="hasGradebook"
                @startEdit="startEdit"
                @cancelEdit="cancelEdit"
                @saveEdit="saveEdit"
                @lock="lock(...arguments)"
                @startReorder="startReorder"
                @cancelReorder="cancelReorder"
                @saveReorder="saveReorder"
                @gradebookRedirect="gradebookRedirect"></header-buttons>
            </div>
            <div class="sections-container">
              <draggable v-model="courseActivity.sections" v-bind="dragOptions">
                <transition-group tag="div">
                  <div v-for="(section, index) in courseActivity.sections" :key="'section'+index">
                    <section-pane
                      :id="'section-'+section.id"
                      :section="section"
                      :course-id="courseActivity.id"
                      :editing="editMode"
                      :dragging="dragMode"
                      @edit-text-update="editTextUpdate"
                      @section-update="loadCourse()"></section-pane>
                  </div>
                </transition-group>
              </draggable>
            </div>
          </div>
        </desktop-only-view>

      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumbs from '@shared/breadcrumbs'
import SectionPane from './section.vue'
import HeaderButtons from './show/header_buttons.vue'
import DesktopOnlyView from '@shared/desktop_only'
import draggable from 'vuedraggable'

export default {
  components: {
    Breadcrumbs,
    SectionPane,
    HeaderButtons,
    DesktopOnlyView,
    draggable,
  },
  data() {
    return {
      courseActivity: {},
      originalCourseActivity: {},
      editMode: false,
      editableTextEdits: {},
      schedule_id: null,
      course_id: null,
      template_id: null,
      dragMode: false,
      courseOrderEdits: {},
      hasGradebook: false,
    }
  },
  methods: {
    gradebookRedirect() {
      this.$router.push({
        name: 'StaffGradebookShow',
        query: {
          course_name: this.courseActivity.name,
          course_id: this.course_id,
          schedule_id: this.schedule_id,
          template_id: this.template_id
        }
      })
    },
    editTextUpdate(identity, text_edit) {
      let section = this.editableTextEdits[identity.section_id]

      if (section === undefined ) {
        section = {
          section_id: identity.section_id,
          name: '',
          memo: '',
          section_order: identity.section_order,
          lessons: []
        }
      }

      if (identity.hasOwnProperty('lesson_id')) {
        let lesson = {
          lesson_id: identity.lesson_id,
          lesson_order: identity.lesson_order,
          [identity.target]: text_edit
        }
        let lesson_index = section.lessons.findIndex(e => e.lesson_id == identity.lesson_id)
        if (lesson_index < 0) {
          section.lessons.push(lesson)
        } else {
          section.lessons[lesson_index] = lesson
        }
      } else {
        section[identity.target] = text_edit
        section.section_order = identity.section_order
      }

      this.editableTextEdits[identity.section_id] = section
    },
    startEdit() {
      this.appendSuccess({message: 'Editing started.', notification: true})
      this.editMode = true
    },
    cancelEdit() {
      this.appendSuccess({message: 'Editing canceled.', notification: true})
      this.editableTextEdits = {}
      this.editMode = false
    },
    saveEdit() {
      if (Object.keys(this.editableTextEdits).length > 0) {
        this.updateCourse()
        this.editableTextEdits = {}
      }
      this.editMode = false
    },
    updateCourse() {
      let data = {
        schedule_id: this.schedule_id,
        course_id: this.course_id,
        template_id: this.template_id,
        text_updates: this.editableTextEdits,
      }
      this.$http.put(`/api/staff/course_activities/${this.courseActivity.id}`,
        {updates: data}).then((res) => {
          this.loadCourse()
        }, (responseError) => {
          this.appendError({error: responseError, notification: true})
        })
    },
    startReorder() {
      this.appendSuccess({message: 'Reordering started.', notification: true})
      this.dragMode = true
      this.copyCourseOrder()
    },
    cancelReorder() {
      this.appendSuccess({message: 'Reordering canceled.', notification: true})
      this.dragMode = false
      this.restoreCourseOrder()

    },
    copyCourseOrder() {
      this.originalCourseActivity = JSON.parse(JSON.stringify(this.courseActivity))
    },
    restoreCourseOrder() {
      this.courseActivity = JSON.parse(JSON.stringify(this.originalCourseActivity))
    },
    saveReorder() {
      this.dragMode = false
      this.updateCourseOrder()
      this.originalCourseActivity = {}
    },
    updateCourseOrder() {
      this.courseOrderEdits["sections"] = this.courseActivity.sections
        .map( (section, index) => {
          return {
            "section_id": section.id,
            "order": index
          }
        })

      this.courseOrderEdits["lessons"] = this.changedLessons()

      let data = {
        schedule_id: this.schedule_id,
        course_id: this.course_id,
        template_id: this.template_id,
        order_updates: this.courseOrderEdits
      }

      this.$http.put(`/api/staff/course_activities/${this.courseActivity.id}/reorder`,
        {updates: data}).then((res) => {
          this.loadCourse()
        }, (responseError) => {
          this.appendError({error: responseError, notification: true})
        })
    },
    changedLessons() {
      let originalLessons = this.originalCourseActivity.sections
        .flatMap( (section) => section.lessons )
        .reduce( (map, lesson) => {
          map[lesson.id] = {
            "section_id": lesson.he_course_section_id,
            "order": lesson.order
          }
          return map
        }, {})

      var changedLessons = []

      for (let i = 0; i < this.courseActivity.sections.length; i++) {
        let section = this.courseActivity.sections[i]

        for (let j = 0; j < section.lessons.length; j++) {
          let lesson = section.lessons[j]
          let originalLesson = originalLessons[lesson.id]

          if (!(originalLesson.section_id == section.id && originalLesson.order == j)) {
            changedLessons.push({
              "lesson_id": lesson.id,
              "section_id": section.id,
              "order": j
            })
          }

        }
      }

      return changedLessons
    },
    loadCourse() {
      this.$http.get(`/api/staff/course_activities/${this.course_id}/${this.schedule_id}`).then((res) => {
        this.courseActivity = res.data.course_activity
      }, (responseError) => {
        this.appendError({error: responseError, notification: true})
      })
    },
    lock(mode) {
      let params = {
        schedule_id: this.schedule_id,
        lock_mode: mode
      }
      this.$http.put(`/api/staff/course_activities/${this.courseActivity.id}/lock`, params)
        .then((res) => {
          this.appendSuccess({message: 'Lock operation success.', notification: true})
          this.loadCourse()
        }, (responseError) => {
          this.appendError({error: responseError, notification: true})
        })
    }
  },
  created () {
    this.schedule_id = this.$route.query.scheduleId,
    this.course_id = this.$route.query.courseId,
    this.template_id = this.$route.query.templateId,
    this.loadCourse()

    this.$http.get(`/api/staff/course_activities/${this.course_id}/gradebook/data_ready/${this.schedule_id}`).then((res) => {
      this.hasGradebook = res.data.has_data
    }, (responseError) => {
      this.appendError({error: responseError, notification: true})
    })
  },
  mounted() {
    let learnosityScript = document.createElement('script')
    learnosityScript.setAttribute('src', 'https://reports.learnosity.com/')
    document.head.appendChild(learnosityScript)
  },
  computed: {
    dragOptions() {
      return {
        animation: 60,
        group: "sections",
        disabled: !this.dragMode,
        swap: true,
        swapThreshold: 0.25
      }
    }
  }
}
</script>
<style scoped lang="scss">
  @import '~@styles/variables';

  .sections-container {
  }

  .course-header {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    margin: 0px 15px 0px 15px;
    margin-top: 0px;
    padding: 0px 0px 20px 0px;
    flex-direction: column;

    h2 {
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 36px;
      font-weight: 700;
      line-height: 150%;
      text-align: left;
    }

  }

  .course-pane {
    border-color: #c7c7c7;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    padding: 10px 15px 15px;
  }

</style>
