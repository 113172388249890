<template>
  <div>
    <h2 class="full-width primary-row py-3">
      SUBSECTION AREAS
    </h2>
    <topic-area-form-modal
      :category-group-prop="categoryGroup"
      :subsection-area-prop="selectedSubsectionArea"
      :topic-area-prop="selectedTopicArea"
      @topicAreaAdded="topicAreaAdded"
      @topicAreaUpdated="topicAreaUpdated"/>

    <app-common-confirm
      :confirm-box-text="''"
      :confirm-callback="removeTopicArea"
      :confirm-message="removeTopicAreaMessage"
      :confirm-modal-id="'confirm-delete-topic-area'"
      :cancel-button-text="'Cancel'"
      :confirm-button-text="'Remove Assessment Date'"/>

    <div
      v-for="(subsection, sIndx) in categoryGroup.subsectionAreas"
      :key="sIndx">
      <table class="mt-3 table">
        <thead>
          <tr>
            <th scope="col">
              {{ subsection.name }}
            </th>
            <th scope="col"/>
            <th scope="col"/>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(topicArea, topicIndx) in subsectionTopicAreasFiltered(subsection)" :key="topicIndx">
            <td class="fw-600 text-left">
              {{ topicArea.name }}
            </td>
            <td class="text-left">
              {{ topicArea.letterCode }}
            </td>
            <td class="text-right">
              <button class="btn btn-lg btn-outline-primary mx-2" @click="editTopicArea(subsection, topicArea)">
                <i class="fa fa-edit"/>
              </button>
              <button class="btn btn-lg btn-outline-primary mx-2" @click="removeTopicAreaModal(topicArea)">
                <i class="fa fa-trash"/>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="table-footer">
        <button class="btn btn-lg btn-outline-secondary mx-2" @click="addTopicArea(subsection)">
          <i class="fa fa-plus-circle left"/>  Add Topic Area
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import topicAreaFormModal from './topic_area_form_modal.vue'
import CommonConfirm from '@submodules/components/common_confirm.vue'

export default {
  components: {
    'app-common-confirm': CommonConfirm,
    topicAreaFormModal
  },
  props: {
    categoryGroupProp: {
      default: () => ({
        subsectionAreas: []
      }),
      type: Object
    }
  },
  data () {
    return {
      categoryGroup: {},
      selectedSubsectionArea: {},
      selectedTopicArea: {}
    }
  },
  watch: {
    categoryGroupProp: {
      immediate: true,
      deep: true,
      handler () {
        this.categoryGroup = this.deepCloneObject(this.categoryGroupProp)
      }
    }
  },
  methods: {
    subsectionTopicAreasFiltered (subsection) {
      return subsection.topicAreas.filter(x => x._destroy != true)
    },
    addTopicArea (subsection) {
      this.selectedSubsectionArea = subsection
      this.selectedTopicArea = {}
      $('#topic-area-form-modal').modal('show')
    },
    editTopicArea (subsection, topicArea) {
      this.selectedSubsectionArea = subsection
      this.selectedTopicArea = topicArea
      $('#topic-area-form-modal').modal('show')
    },
    deleteTopicArea (topicArea) {
      console.log('Topic Area', topicArea)
    },

    topicAreaUpdated (topicAreaToUpdate) {
      // categoryGroupProp.subsectionAreas
      const subsectionIndex = this.categoryGroup.subsectionAreas.findIndex(x => x.id == topicAreaToUpdate.subsectionAreaId)
      const topicAreaId = this.categoryGroup.subsectionAreas[subsectionIndex].topicAreas.findIndex(x => x.id == topicAreaToUpdate.id)
      this.categoryGroup.subsectionAreas[subsectionIndex].topicAreas.splice(topicAreaId, 1, topicAreaToUpdate)
      this.$emit('updateCategoryGroup', this.categoryGroup)
    },

    topicAreaAdded (topicAreaToAdd) {
      const subsectionIndex = this.categoryGroup.subsectionAreas.findIndex(x => x.id == topicAreaToAdd.subsectionAreaId)
      this.categoryGroup.subsectionAreas[subsectionIndex].topicAreas.push(topicAreaToAdd)
      this.$emit('updateCategoryGroup', this.categoryGroup)
    },

    removeTopicArea () {
      this.selectedTopicArea._destroy = true
      const subsectionIndex = this.categoryGroup.subsectionAreas.findIndex(x => x.id == this.selectedTopicArea.subsectionAreaId)
      this.categoryGroup.subsectionAreas[subsectionIndex].topicAreas.splice(subsectionIndex, 1, this.selectedTopicArea)
      this.$emit('updateCategoryGroup', this.categoryGroup)
    },

    removeTopicAreaMessage () {
      return `Are you sure you'd like to delete the Topic Area for <b>${this.selectedTopicArea.name}?</b>`
    },

    removeTopicAreaModal (topicArea) {
      this.selectedTopicArea = topicArea
      $('#confirm-delete-topic-area').modal('show')
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@styles/table_2.scss';
// .table thead th {
//   font-size: 16px;
//   padding: 20px 15px !important;
// }
// .table tbody th {
//   margin-left: 0px;
//   color: #333;
//   font-size: 12px;
//   font-weight: 600;
// }
</style>
