import client from '@repositories/client.js'

const BASE_URL = '/api/staff/school'

export default new class {

  async info() {
    const data = await client.get(BASE_URL)
    return data.school
  }
}
