<template>
  <div v-if="scheduleDates.length > 0">
    <div class="dashboard-pane">
      <div class="content-heading">
        <div class="pull-left">
          <h1>Attendance</h1>
        </div>
        <div class="pull-right">
          <h1 class="d-inline fw-400">
            <span class="fw-700 text-orange">{{scheduleDatesCount }}</span> Class Today
          </h1>
        </div>
      </div>
      <div v-for="(scheduleDate, idx) in scheduleDates"
        :key="idx">
        <div class="primary-row underline-bottom-gray">
          <div class="course-info">
            <h4 class="font-16 lh-16 text-primary">
              {{ scheduleDate.name }}
            </h4>
            <h4 class="font-weight-bold lh-16">
              {{ scheduleDate.scheduleType }} School Day
            </h4>
            <h4 class="font-weight-bold lh-16">
             Target Date: {{ scheduleDate.targetAssessmentDate }}
            </h4>
            <h4 class="lh-16 text-orange">
              {{ scheduleDate.primaryTeacherName }}
            </h4>
            <h4 class="lh-16">
              {{ scheduleDate.secondaryTeacherName }}
            </h4>
          </div>
          <div class="primary-row-action">
            <router-link
              :to="{ name: 'StaffAttendanceForm', params: {scheduleId: scheduleDate.scheduleId, scheduleDateId: scheduleDate.id} }">
              <button class="btn btn-outline-primary">
                Take Attendance <i class="fa fa-arrow-right text-orange"/>
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Repository from "@repositories/factory"
const DashboardClassAttedanceRepository = Repository.staff.get('dashboard_class_attendance')

export default {
  components: {
   },
  props: {
  },
  data () {
    return {
      scheduleDates: []
    }
  },
  created () {
    this.getScheduleDates()
  },
  methods: {
    async getScheduleDates () {
      try { const scheduleDates  = await DashboardClassAttedanceRepository.get()
        this.scheduleDates = scheduleDates
      } catch (responseError) {
        this.appendError({ error: responseError, notification: true })
      }
    }
  },
  computed: {
    scheduleDatesCount: function () {
      return this.scheduleDates.length
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@styles/variables.scss";
.underline-bottom-gray {
  border-bottom: 1px solid $themeLtGray;
}

@media screen and (max-width: $mobileScreenExtraLarge) {
  .content-heading {
    display: block;

    .pull-left {
      float: left;
      width: 100%;
    }

    .pull-right {
      float: left;
      width: 100%;

      h1 {
        font-size: 18px;
      }
    }
  }
}

@media screen and (max-width: $mobileScreenLarge) {
  .dashboard-pane {
    display: none;
  }
}

@media screen and (max-width: $mobileScreenMedium) {
  .primary-row {
    display: block;

    .course-info {
      width: 100%;
    }

    .primary-row-action {
      margin-top: 10px;
      width: 100%;
    }
  }
}

@media screen and (max-width: $mobileScreenSmall) {
  .dashboard-pane {
    border-radius: 0px;
  }
}

@media screen and (max-width: $mobileScreenExtraSmall) {
  .content-heading {
    padding-left: 0px;
    padding-right: 0px;
  }
}
</style>
