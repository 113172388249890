import axios from 'axios'

export default new class {

  constructor () {
    this.client = axios.create({
      headers: {
        'Content-type': 'application/json'
      }
    })
  }
  async get (url) {
    const { data } = await this.client.get(url)
    return data
  }

  async post (url, requestData) {
    const { data } = await this.client.post(url, requestData)
    return data
  }

  async put (url, requestData) {
    const { data } = await this.client.put(url, requestData)
    return data
  }

  async delete (url) {
    const { data } = await this.client.delete(url)
    return data
  }

}
