<template>
  <div>
    <div class="report-title bg-primary no-border-radius section-header subsection-score-details-header">
      <h1 class="text-white">Subsection Score Details</h1>
    </div>
    <div class="subsection-details-wrapper">
      <div class="instructions">
        Click on each tab to view individual subsection details.
      </div>
      <div class="report-wrapper">
        <div class="tabs-row">
          <div
            v-for="(section, sIndx) in sectionTabs"
            :key="sIndx"
            class="tab"
            :class="{ 'selected': section.selected, 'disabled': isLoading }"
            @click="updateSection(section)">
            <div>{{ section.sectionName }}</div>
          </div>
          <div class="w-tab-menu">
            <div
              v-for="(section, sIndx) in sectionTabs"
              :key="sIndx"
              class="w-tab-menu-item"
              :class="{ 'selected': section.selected, 'disabled': isLoading }"
              @click="updateSection(section)">
              {{ section.sectionName }}
            </div>
          </div>
        </div>
      </div>
      <div class="full tab-pane">
        <div class="d-flex subsection-details-wrapper">
          <template v-if="isLoadingSubsectionDetails">
            <div class="donut-chart-wrapper">
              <div style="width:100%">
                <common-loading/>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="donut-chart-wrapper">
              <div class="donut-chart">
                <donut-chart
                  v-if="selectedSectionCorrect()"
                  :chart-id="'subsection-donut'"
                  :chart-title="'Correct'"
                  chart-title-font-size="28px"
                  :chart-range="false"
                  :display-percentage="true"
                  :average-score="averageScore(selectedSection.sectionDetails)"
                  :min-score="1"
                  :max-score="100"
                  :color="'#f4701f'"/>
              </div>
              <div class="donut-chart-content">
                <div
                  v-if="!isBlank(selectedSection.sectionDetails)"
                  class="donut-detail">
                  <h1>{{ selectedSection.sectionDetails.totalQuestions }}</h1>
                  <span>Total Questions</span>
                </div>
                <div
                  v-if="!isBlank(selectedSection.sectionDetails)"
                  class="donut-detail">
                  <h1>{{ selectedSection.sectionDetails.correctAnswers }}</h1>
                  <span>Correct Answers</span>
                </div>
                <div
                  v-if="!isBlank(selectedSection.sectionDetails)"
                  class="donut-detail">
                  <h1>{{ selectedSection.sectionDetails.incorrectAnswers }}</h1>
                  <span>Incorrect Answers</span>
                </div>
                <div
                  v-if="!isBlank(selectedSection.sectionDetails)"
                  class="donut-detail">
                  <h1>{{ selectedSection.sectionDetails.blankAnswers }}</h1>
                  <span>Blank Answers</span>
                </div>
              </div>
            </div>
          </template>
          <template v-if="isLoadingSubsectionDetails">
            <div class="bar-chart-wrapper">
              <common-loading/>
            </div>
          </template>
          <template v-else>
            <category-bars-percentage
              v-if="!isBlank(selectedSection.sectionDetails)"
              :category-list-prop="categoryList"
              :subsection-name="selectedSection.sectionName"/>
          </template>
        </div>

          <template v-if="isLoadingSubsectionDetails">
            <div class="subsection-loader-container">
              <p>Subsections</p>
            </div>
            <div>
              <common-loading/>
            </div>
          </template>
          <template v-else>
            <horizon-multilevel-table
              v-if="!isBlank(selectedSection.sectionDetails)"
              :columns="subsectionColumns"
              :data="categoryList"
              :sub-table-key="'details'"
              :small="true"
              :table-setup="subsectionTableSetup"
              class="web-only"
            />
          </template>
        <template v-if="(individualView && selectedSection.sectionDetails.showQuestionAndAnswer)">
          <div class="table-header">
            <div class="table-title">
              Question & Answer List
            </div>
            <!-- <div class="table-instruction">
              Hover and click on specific question to open an answer explanation
            </div> -->
            <router-link v-if="practiceTestId !== null"
                class="btn btn-outline btn-report"
                :to="'/reports/learnosity_reports/session_detail_by_item/'
                    + practiceTestId + '/' + selectedSection.sectionName"
                target="_blank">
              View Assessment Questions & Correct Answers
              <img class="btn-icon" src="/images/right-arrow.png">
            </router-link>
          </div>
          <template v-if="isLoadingSubsectionDetails">
            <common-loading/>
          </template>
          <template v-else>
            <horizon-table
              v-if="!isBlank(selectedSection.sectionDetails)"
              :columns="answerListColumns"
              :clickable="false"
              :data="answerList"
              :section="selectedSection.sectionName"
              :small="true"
              class="web-only"
            />
          </template>
        </template>
        <template v-if="!individualView">
          <div class="table-header">
            <div class="table-title">
              STANDARD ITEM ANALYSIS
            </div>
              <router-link v-if="practiceTestDateId !== null"
                  class="btn btn-outline btn-report"
                  :to="'/reports/learnosity_reports/response_analysis_by_item/' + practiceTestId + '/'
                    + practiceTestDateId + '/' + selectedSection.sectionName + '/0'"
                  target="_blank">
                View Question & Answer Analysis
                <img class="btn-icon" src="/images/right-arrow.png">
              </router-link>
          </div>
          <template v-if="isLoadingStandardItemAnalysis">
            <common-loading/>
          </template>
          <template v-else>
            <horizon-table
              :columns="standardItemAnalysisColumns"
              :data="standardItemAnalysisData"
              :small="true"
              class="web-only"
            />
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DonutChart from '@shared/charts/donut_chart'
import HorizonMultilevelTable from '@shared/horizon_multilevel_table'
import HorizonTable from '@shared/horizon_table'
import CategoryBarsPercentage from '@shared/score_report/category_bars_percentage.vue'
import CommonLoading from '@shared/common_loading'

export default {
  components: {
    CategoryBarsPercentage,
    DonutChart,
    HorizonTable,
    HorizonMultilevelTable,
    CommonLoading
  },
  props: {
    practiceTestId: { type: [Number, String], required: true },
    practiceTestDateId: { default: null, type: [Number, String] },
    sectionsDataProp: { type: Array, required: true },
    scheduleId: { default: null, type: [Number, String] },
    individualView: { type: Boolean, required: true },
    requestUrlBaseProp: { type: String, required: true },
    isHorizonAdmin: {
      type: Boolean,
      default: false
    },
    assessmentType: {
      type: String,
      default: ""
    }
  },
  /* eslint-disable max-lines-per-function */
  data () {
    return {
      loadingSubsectionDetails: true,
      loadingStandardItemAnalysis: true,
      pageFirstLoad: true,
      sectionsData: [],
      subsectionColumns: [
        { column: 'subsectionArea', display: 'Subsections', width: 40, title: true, secondaryDisplay: 'topicArea' },
        { column: 'percent', display: 'Correct (%)', width: 15, formatter: 'decimalToPercent' },
        { column: 'correct', display: 'Correct (#)', width: 15, formatter: 'intToDecimal' },
        { column: 'incorrect', display: 'Incorrect (#)', width: 15, formatter: 'intToDecimal' },
        { column: 'blank', display: 'Blank (#)', width: 15, formatter: 'intToDecimal' }
      ],
      subsectionTableSetup: {
        headerColumns: true,
        header: true
      },
      answerListColumns: [],
      standardItemAnalysisColumns: [],
      selectedSection: {
        sectionDetails: {}
      },
      standardItemAnalysisNum: null,
      standardItemAnalysisData: [],
      sectionTabs: [],
      subsectionUrl: ""
    }
  },
  computed: {

    isLoading () {
      if (this.individualView) { return false }
      if (this.loadingSubsectionDetails === false &&
        this.loadingStandardItemAnalysis === false) {
        return false
      }
      return true
    },
    isLoadingSubsectionDetails () {
      if (this.loadingSubsectionDetails === false) {
        return false
      }
      return true
    },
    isLoadingStandardItemAnalysis () {
      if (this.loadingStandardItemAnalysis === false) {
        return false
      }
      return true
    },
    categoryList () {
      if (this.isBlank(this.selectedSection.sectionDetails)) {
        return []
      }
      return _.map(
        _.filter(_.cloneDeep(this.selectedSection.sectionDetails.categoryList),
            (categoryList) => {
              return categoryList.total !== 0
            }),
        (categoryList) => {
          categoryList.details = _.filter(categoryList.details,
            (categoryListDetail) => {
              return categoryListDetail.total !== 0
            })
          return categoryList
        }
      )
    },
    answerList () {
      if (this.isBlank(this.selectedSection.sectionDetails)) {
        return []
      }
      return _.map(_.cloneDeep(this.selectedSection.sectionDetails.answerList),
        (answerList) => {
          if (answerList.studentAnswer === null) {
            answerList.studentAnswer = ""
          }
          return answerList
        }
      )
    },
  },
  created () {
    this.initSectionTabs()
    this.initAnsweredListColumns()
  },
  /* eslint-enable max-lines-per-function */
  methods: {
    ...mapGetters(['userSchool']),
    averageScore (sectionDetails) {
      return(((sectionDetails.correctAnswers / sectionDetails.totalQuestions) * 100).toFixed(1))

    },

    initAnsweredListColumns () {
      this.answerListColumns = [
        { column: 'question', display: 'Question #' },
        { column: 'studentAnswer', display: 'Your Answer', columnFn: this.displayAnswerState },
        { column: 'correctAnswer', display: 'Correct Answer' },
        { column: 'subsectionAreaName', display: 'Subsection' },
        { column: 'topicAreaName', display: 'Area' }
      ]
    },

    selectedSectionCorrect () {
      return !this.isBlank(this.selectedSection.sectionDetails.correctAnswers)
    },

    initSectionTabs () {
      // NOTE:  Consider the possibility of NOT passing the this.sectionsData on this component
      //        but rather the assessment only. We only require the assessment on this case
      // const isSat = this.sectionsData.some((info) => info.sectionName == 'Evidence-Based Reading and Writing')
      let satRelated = false
      let actRelated = false
      if (this.isHorizonAdmin) {
        if (this.assessmentType === "SAT" || this.assessmentType === "PSAT89" || this.assessmentType === "PSAT10") {
          satRelated = true
        } else if (this.assessmentType === "ACT") {
          actRelated = true
        }
      } else {
        if (this.userSchool().satRelated) {
          satRelated = true
        } else if(this.userSchool().actRelated) {
          actRelated = true
        }
      }

      if (satRelated) {
        this.sectionTabs = [
          { sectionName: 'Reading', key: 'reading', selected: true, sectionDetails: {} },
          { sectionName: 'Writing', key: 'writing', selected: false, sectionDetails: {} },
          { sectionName: 'Math Calc', key: 'math_calc', selected: false, sectionDetails: {} },
          { sectionName: 'Math No Calc', key: 'math_no_calc', selected: false, sectionDetails: {} },

        ]
      } else if (actRelated) {
        this.sectionTabs = [
          { sectionName: 'English', key: 'english', selected: false, sectionDetails: {} },
          { sectionName: 'Math', key: 'math', selected: true, sectionDetails: {} },
          { sectionName: 'Reading', key: 'reading', selected: false, sectionDetails: {} },
          { sectionName: 'Science', key: 'science', selected: false, sectionDetails: {} }

        ]
      }
      this.selectedSection = this.sectionTabs[0]
      this.updateSection(this.sectionTabs[0])
    },
    buildStandardItemAnalysisTable () {
      const rowOne = this.standardItemAnalysisData[0].answers.answered
      const rowTwo = this.standardItemAnalysisData[1].answers.answered
      const methodPointers = [
        this.formatResponseOne,
        this.formatResponseTwo,
        this.formatResponseThree,
        this.formatResponseFour,
        this.formatResponseFive,
        this.formatResponseSix,
        this.formatResponseSeven,
        this.formatResponseEight,
        this.formatResponseNine,
        this.formatResponseTen,


      ]
      this.standardItemAnalysisColumns = [
        { column: 'question', display: 'Question' },
        { column: 'sectionAreaName', display: 'Subsection' },
        { column: 'topicAreaName', display: 'Area' }
      ]
      // Minus 1 to exclude blank column
      for (let i=0; i<this.standardItemAnalysisNum - 1; i++) {
        const columnSet = Array.from(new Set([rowOne[i].option, rowTwo[i].option])).join('/')
        this.standardItemAnalysisColumns.push({
          column: 'answers',
          display: `Response ${columnSet}`,
          columnFn: methodPointers[i]
        })
      }
      this.standardItemAnalysisColumns.push({ column: 'answers', display: 'Blank', columnFn: this.formatResponseBlank })
    },
    displayAnswerState (answer) {
      return answer.studentAnswerCorrect ? `<span class="text-success font-weight-bold">${answer.studentAnswer}</b></span>` : `<span class="text-danger font-weight-bold">${answer.studentAnswer}</span>`
    },
    fetchSubsectionDetails (section) {
      if (!this.isBlank(section.sectionDetails)) {
        section.selected = true
        this.selectedSection = section
        if (!this.individualView) { this.fetchStandardItemAnalysis() }
        return
      }
      this.loadingSubsectionDetails = true
      if (!this.individualView) {
        this.loadingStandardItemAnalysis = true
      }
      if (this.scheduleId == 0) {
        this.subsectionUrl = `${this.requestUrlBaseProp}/assessment_course_subsection_details/${section.key}`
      } else {
        this.subsectionUrl = `${this.requestUrlBaseProp}/subsection_details/${section.key}`
      }
      this.$http.get(this.subsectionUrl, {
        params: {
          include_answer_list: this.individualView ? "yes" : "no",
        }
      }).then((response) => {
        section.sectionDetails = response.data.sectionDetails
        section.selected = true
        this.selectedSection = section
        this.loadingSubsectionDetails = false
        if (!this.individualView) { this.fetchStandardItemAnalysis() }
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
        this.loadingSubsectionDetails = false
      })
    },
    fetchStandardItemAnalysis () {
      this.loadingStandardItemAnalysis = true
      this.$http.get(`${this.requestUrlBaseProp}/standard_item_analysis/${this.selectedSection.key}`).then(response => {
        this.standardItemAnalysisData = response.data.standardItemAnalysis
        this.standardItemAnalysisNum = this.standardItemAnalysisData[0].answers.answered.length
        this.buildStandardItemAnalysisTable()
        this.loadingStandardItemAnalysis = false
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
        this.loadingStandardItemAnalysis = false
      })
    },
    styleCorrectAnswer (entry) {
      if (entry.option == 'blank') { return `${entry.percent}%` }
      if (!entry.correct) { return `<span class="pr-1">${entry.option}</span>(${entry.percent}%)` }
      return `<span class="text-orange fw-600"><span class="pr-1">${entry.option}</span>(${entry.percent}%)</span>`
    },
    formatResponseOne (entry) {
      return this.styleCorrectAnswer(entry.answers.answered[0])
    },
    formatResponseTwo (entry) {
      return this.styleCorrectAnswer(entry.answers.answered[1])
    },
    formatResponseThree (entry) {
      return this.styleCorrectAnswer(entry.answers.answered[2])
    },
    formatResponseFour (entry) {
      return this.styleCorrectAnswer(entry.answers.answered[3])
    },
    formatResponseFive (entry) {
      return this.styleCorrectAnswer(entry.answers.answered[4])
    },
    formatResponseSix (entry) {
      return this.styleCorrectAnswer(entry.answers.answered[5])
    },
    formatResponseSeven (entry) {
      return this.styleCorrectAnswer(entry.answers.answered[6])
    },
    formatResponseEight (entry) {
      return this.styleCorrectAnswer(entry.answers.answered[7])
    },
    formatResponseNine (entry) {
      return this.styleCorrectAnswer(entry.answers.answered[8])
    },
    formatResponseTen (entry) {
      return this.styleCorrectAnswer(entry.answers.answered[9])
    },
    formatResponseBlank (entry) {
      return this.styleCorrectAnswer(entry.answers.answered[this.standardItemAnalysisNum - 1])
    },
    updateSection (section) {
      if (this.isLoading && !this.pageFirstLoad) {
        return
      }
      this.pageFirstLoad = false
      this.sectionTabs.forEach(function (sectionIndx) {
        sectionIndx.selected = false
      })
      this.fetchSubsectionDetails(section)
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@styles/variables';

.btn-report {
  background-color: white;
  color: #345165;
  font-size: 12px;
  &:hover {
    background-color: #345165;
    color: white;
  }
}

.instructions {
  font-size: 16px;
  color: #6b6b6b;
  padding-bottom: 14px;
}
.tab {
  font-size: 18px;
  &.disabled {
    cursor: not-allowed;
  }
}
.subsection-details-wrapper {
  padding: 20px;
}
.bar-chart-wrapper {
  padding-left: 15px;
  border-left: 1px solid #e8e8e8;
  .bar-chart-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-self: center;
    .bar-row {
      display: flex;
      align-items: center;
      padding: 6px 0;
      > div {
        display: flex;
        justify-content: space-between;
      }
      :first-child {
        padding-right: 8px;
      }
    }
    .bar {
      height: 20px;
      background-color: $themeLtGray;
      width: 100%;
      position: relative;
      border-radius: 5px;
      &.completed {
        height: 20px;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    span {
      font-size: 12px;
    }
    .bar-specs {
      display: flex;
      justify-content: space-between;
      width: 30%;
      span:last-of-type {
        text-align: right;
        font-weight: 600;
      }
    }
  }
}
.donut-chart-wrapper {
  padding: 10px 0;
  display: flex;
  align-items: center;
  .donut-chart-content {
    padding-left: 20px;
  }
  .donut-detail {
    display: flex;
    align-items: center;
    padding: 5px;
    h1 {
      margin-bottom: 3px;
      line-height: 32px;
      width: 30%;
      text-align: right;
      padding-right: 8px;
    }
    span {
      margin-left: 5px;
    }
  }
}
.table-header {
  background-color: #F3F3F3;
  height: 70px;
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  .table-title {
    font-weight: 900;
    color: #f4701f;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 2px;
  }
  .table-instruction {
    margin-left: 20px;
    color: #6b6b6b;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }
}

.web-only {
  display: block;
}

.w-tab-menu {
  display: none;
}

.subsection-details-wrapper {
  .donut-chart-wrapper {
    width: 45%;

    .donut-chart {
      width: 50%;
    }

    .donut-chart-content {
      width: 50%;
      padding-left: 0px;
    }
  }
  .bar-chart-wrapper {
    width: 55%;
  }
}

@media screen and (max-width: $mobileScreenExtraLarge) {
  .subsection-details-wrapper {
    .donut-chart-wrapper {
      width: 50%;
    }

    .bar-chart-wrapper {
      width: 50%;
    }
  }
}

@media screen and (max-width: $mobileScreenLarge) {
  .subsection-details-wrapper {
    display: block !important;

    .donut-chart-wrapper {
      width: 100%;
    }
    .bar-chart-wrapper {
      width: 100%;
      padding-left: 0;
      border-left: 0;
      padding-top: 15px;
    }
  }
}

@media screen and (max-width: $mobileScreenMedium) {
  .web-only {
    display: none;
  }

  .report-title {
    h1 {
      font-size: 18px;
    }
  }

  .subsection-details-wrapper {
    padding-left: 8px;
    padding-right: 8px;

    .tabs-row {
      .tab {
        padding: 10px 13px;
        font-size: 14px;
      }
    }

    .donut-chart-wrapper {
      .donut-chart {
        width: 40%;
      }
      .donut-chart-content {
        width: 60%;
      }
    }
  }
}

@media screen and (max-width: $mobileScreenSmall) {
  .tab {
    display: none;
  }

  .w-tab-menu {
    display: block;
    width: 100%;

    .w-tab-menu-item {
      width: 100%;
      transition: .2s;
      cursor: pointer;
      color: $mutedText;
      padding: 13px 25px;
      text-align: center;
      border-radius: 7px;
      &.selected {
        background-color: $primaryColor;
        color: #fff;
      }
      &:not(.selected):hover {
        color: $themeGray;
      }
    }
  }
}

@media screen and (max-width: $mobileScreenExtraSmall) {
  .subsection-details-wrapper {
    .donut-chart-wrapper {
      display: block;

      .donut-chart {
        width: 100%;
      }
      .donut-chart-content {
        padding-top: 15px;
        width: 100%;
      }
    }
  }
}
</style>

<style lang="scss">
@import '~@styles/variables';

.subsection-loader-container {
  height: 60px;
  background: #F3F3F3;
  border-top: 1px solid #DEE2E6;
  border-bottom: 1px solid #DEE2E6;

  & > p {
    font-weight: 900;
    color: #f4701f;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 2px;
    padding-left: 20px;
    margin: 0;
    line-height: 60px;
  }
}

.subsection-details-wrapper {
  .donut {
    .inner-donut-text {
      font-size: 42px;
    }
  }
}

@media screen and (max-width: $mobileScreenLarge) {
  .subsection-details-wrapper {
    .donut {
      .inner-donut-text {
        font-size: 56px;
      }
    }
  }
}

@media screen and (max-width: $mobileScreenMedium) {
  .subsection-details-wrapper {
    .donut {
      .inner-donut-text {
        font-size: 38px;
      }
    }
  }
}

@media screen and (max-width: 549px) {
  .subsection-details-wrapper {
    .donut {
      .inner-donut-text {
        font-size: 32px;
      }
    }
  }
}

@media screen and (max-width: 429px) {
  .subsection-details-wrapper {
    .donut {
      .inner-donut-text {
        font-size: 28px;
      }
    }
  }
}

@media screen and (max-width: $mobileScreenExtraSmall) {
  .subsection-details-wrapper {
    .donut {
      .inner-donut-text {
        font-size: 56px;
      }
    }
  }
}

@media screen and (max-width: 329px) {
  .subsection-details-wrapper {
    .donut {
      .inner-donut-text {
        font-size: 42px;
      }
    }
  }
}
</style>
