<template>
  <div>
    <schedule-information-form
      :schedule="schedule"
      :errors="errors"
      :instructor-repository="instructorRepository"
      :target-assessment-date-repository="targetAssessmentDateRepository"
      :school-repository="schoolRepository">

      <template v-slot:class-settings-form-group>
        <div class="horizon-form-row">
          <label>Class SMS Reminders</label>
          <div>
            <label id="schedule-class-sms-reminders-display" class="m-2 switch">
              <input
              type="checkbox"
              v-model="schedule.enableClassSmsReminders"
              class="default" />
              <span class="round slider" />
            </label>
          </div>
        </div>

        <div class="horizon-form-row">
          <label>Class Email Reminders</label>
          <div>
            <label id="schedule-class-email-reminders-display" class="m-2 switch">
              <input
              type="checkbox"
              v-model="schedule.enableClassEmailReminders"
              class="default" />
              <span class="round slider" />
            </label>
          </div>
        </div>

        <div class="horizon-form-row">
          <label for="class-location">Class Location</label>
          <input
            id="class-location"
            v-model="schedule.classLocation"
            type="text"
            class="horizon-input" placeholder />
        </div>
      </template>

    </schedule-information-form>

    <div class="primary-row">
      <h2 class="text-uppercase">Schedule Dates</h2>
    </div>

    <div class="mt-3 row">
      <div class="col-12">
        <classes-table-form
          :classes="schedule.classes"
          :errors="errors.classes"
          />
        <practice-assessments-table-form
          :scheduleProp="schedule"
          :courseType="schedule.courseType"
          :practiceTestDates="schedule.practiceTestDates"
          :errors="errors.practiceTestDates" />
        <parent-nights-table-form
          :parentNightDates="schedule.parentNightDates"
          :errors="errors.parentNightDates"/>
      </div>
    </div>
  </div>
</template>

<script>
import ScheduleInformationForm from "@components/modules/schedule/schedule_info_form";
import ClassesTableForm from "@components/modules/schedule/outside_schedule_classes_table_form";
import PracticeAssessmentsTableForm from "@components/modules/schedule/schedule_practice_assessments_table_form";
import ParentNightsTableForm from "@components/modules/schedule/schedule_parent_nights_table_form";

export default {
  components: {
    ScheduleInformationForm,
    ClassesTableForm,
    PracticeAssessmentsTableForm,
    ParentNightsTableForm
  },
  props: {
    schedule: {
      type: Object,
      required: false,
      default () { return {} }
    },
    errors: {
      type: Object,
      required: false,
      default () { return {} }
    },
    instructorRepository: {
      type: Object,
      required: true
    },
    targetAssessmentDateRepository: {
      type: Object,
      required: true
    },
    schoolRepository: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.schedule-section-header {
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 18px;
}
</style>
