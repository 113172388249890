<template>
  <div class="create-lesson-pane lesson-pane">
    <div class="card card-body add-lesson-body">
      <button class="btn action-btn" @click="showCreateLessonModal()">
        <img class="btn-icon" src="/images/round-add-button.png"><span class="add-lesson ml-2">Add Lesson</span>
      </button>
    </div>
  </div>
</template>
<script>
import CreateLessonModal from './modals/create_lesson_modal.vue'

export default {
  components: {
    CreateLessonModal,
  },
  props: {
    courseSection: Object,
  },
  methods: {
    showCreateLessonModal(){
      this.$emit('get-section',this.courseSection)
      $('#create-lesson-modal').modal('show')
    },
  },
}
</script>
<style lang="scss" scope>
.add-lesson {
  font-size: 24px;
  font-weight: 700;
  color: #345165;
  margin-bottom: 0;
  vertical-align: middle;
}
.add-lesson-body {
  border-color: #c7c7c7;
  border-style: solid;
  border-width: 1px;
  border-radius: 14px;
}
</style>
