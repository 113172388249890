<template>
  <div class="col-md-12">
    <div class="no-gutters row">
      <div class="col-md-12 p-0">
        <h1>Course Enrollment</h1>
        <hr>
      </div>
      <div class="col-md-12">
        <enrollment-card-list
          :schedules="scheduleCardData"
          :buttons-configuration="buttonsConfiguration"/>
      </div>
    </div>
  </div>
</template>

<script>
import EnrollmentCardList from '@components/modules/enrollment/enrollment_card_list'
import RepositoryFactory from '@repositories/factory'

export default {
  components: {
    EnrollmentCardList
  },
  data () {
    return {
      schedules: [],
      buttonsConfiguration: [
        {
          onClick: (params) => { this.$router.push({name: 'StaffWaitingListEntriesShow', params: params }) },
          color: 'secondary',
          text: 'Wait List'
        },
        {
          onClick: (params) => { this.$router.push({name: 'SchoolEnrollmentShow', params: {id: this.schoolId, scheduleId: params.schedule.id}}) },
          color: 'secondary',
          text: 'Enrollment'
        }
      ],
      scheduleRepository: RepositoryFactory.admin.school(this.$route.params.id).get('schedule')
    }
  },
  computed: {
    schoolId () {
      return this.$route.params.id
    },
    scheduleCardData () {
      return this.schedules.map((schedule) => {
        const primaryTeacherName = schedule.primaryTeacher ? `${schedule.primaryTeacher.firstName} ${schedule.primaryTeacher.lastName}` : null
        const secondaryTeacherName = schedule.secondaryTeacher ? `${schedule.secondaryTeacher.firstName} ${schedule.secondaryTeacher.lastName}` : null

        return {
          ...schedule,
          primaryTeacherName,
          secondaryTeacherName
        }
      })
    }
  },
  created () {
    this.fetchSchedules()
  },
  methods: {
    async fetchSchedules () {
      this.schedules = await this.scheduleRepository.list()
    }
  }
}
</script>
