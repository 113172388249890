<template>
  <div class="container">
    <div class="content-pane">
      <div class="pane-wrapper">
        <breadcrumbs v-if="breadcrumbs.length > 0" :breadcrumbs="breadcrumbs" class="breadcrumbs"/>
        <enrollment-pane
          :schedule-repository="scheduleRepository"
          :student-repository="studentRepository"
          :enrollment-repository="enrollmentRepository" />
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@shared/breadcrumbs'
import Mixins from '@submodules/mixins/mixin.js'

import EnrollmentPane from '@components/modules/enrollment/pane'
import RepositoryFactory from '@repositories/factory'

export default {
  components: {
    Breadcrumbs,
    EnrollmentPane
  },
  mixins: [Mixins],
  data () {
    return {
      breadcrumbs: [],
      scheduleRepository: RepositoryFactory.staff.get('schedule'),
      studentRepository: RepositoryFactory.staff.get('student'),
      enrollmentRepository: RepositoryFactory.staff.get('enrollment')
    }
  },
  created () {
    this.populateBreadcrumbs()
  },
  methods: {
    async populateBreadcrumbs() {
      const scheduleId = this.$route.params.scheduleId
      if(scheduleId) {
        const schedule = await this.scheduleRepository.fetch(scheduleId)
        this.breadcrumbs = [{ text: schedule.name }]
      } else {
        this.breadcrumbs = [{ text: 'Entire School'}]
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@styles/variables';

.total-student-number {
  display: inline-block;
  margin: 5px 0px 5px 6px;
  clear: none;
  color: #333;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
}

.student-total-number {
  display: inline-block;
  color: #f4701f;
  font-size: 28px;
  font-weight: 700;
}

@media screen and (max-width: $mobileScreenMedium) {
  .breadcrumbs {
    display: none;
  }
}

@media screen and (max-width: $mobileScreenSmall) {
  .container {
    padding: 0px;
    height: 100%;

    .content-pane {
      border-radius: 0px;
      height: 100%;

      .pane-wrapper {
        padding: 15px;
      }
    }
  }
}
</style>
