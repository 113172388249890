import AdminSchoolList from '@admin/school/list.vue'
import AdminSchoolForm from '@admin/school/form.vue'
import AdminSchoolDashboard from '@admin/school/school_dashboard/show.vue'
import AdminEnrollmentList from '@admin/enrollment/list.vue'
import AdminDistrictList from '@admin/districts/list.vue'
import AdminDistrictForm from '@admin/districts/form.vue'
import AdminAssessmentList from '@admin/assessments/list.vue'
import AdminAssessmentSettings from '@admin/assessments/settings/form.vue'
import AdminAssessmentAnswerKeyList from '@admin/assessments/answer_keys_list.vue'
import AdminAssessmentAnswerKeyShow from '@admin/assessments/answer_keys/form.vue'
import AdminSchoolAttendanceShow from '@admin/school/school_dashboard/attendance/show.vue'
import AdminCourseActivitiesShow from '@admin/course_activities/show.vue'
import AdminCourseActivities from '@admin/course_activities/course/show.vue'

import SchoolSessionOverview from '@admin/school/school_dashboard/session_overview'
import SchoolScheduleList from '@admin/school/school_dashboard/schedules'
import SchoolScheduleCreate from '@admin/school/school_dashboard/schedules/create'
import SchoolScheduleView from '@admin/school/school_dashboard/schedules/show'
import SchoolScheduleEdit from '@admin/school/school_dashboard/schedules/edit'

import SchoolEnrollmentList from '@admin/school/school_dashboard/enrollment/list'
import SchoolEnrollmentShow from '@admin/school/school_dashboard/enrollment/show'
import SchoolAttendanceList from '@admin/school/school_dashboard/attendance'
import SchoolAttendanceForm from '@admin/school/school_dashboard/attendance/form.vue'
import SchoolStudentResults from '@admin/school/school_dashboard/student_results/show'
import AdminStudentScoreReport from '@admin/school/school_dashboard/student_results/shared/score_report/show'
import AdminStudentScoreReportSubsectionDetails from '@admin/school/school_dashboard/student_results/shared/score_report/subsection_details'
import AdminAssessmentCourseScoreReportSubsectionDetails from '@admin/school/school_dashboard/student_results/shared/score_report/assessment_course_subsection_details'
import AdminStudentScoreReportIndividual from '@admin/school/school_dashboard/student_results/shared/score_report/individual/show'
import SchoolSettings from '@admin/school/school_dashboard/school_settings'
import GlobalSearch from '@admin/global_search/search'


export default [
  {
    path: '/admin',
    component: AdminSchoolList,
    name: 'AdminSchoolListBasic',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Schools', routerOptions: { name: 'AdminSchoolList' } }
      ]
    }
  },{
    path: '/admin/school',
    component: AdminSchoolList,
    name: 'AdminSchoolList',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Schools', routerOptions: { name: 'AdminSchoolList' } }
      ]
    }
  },
  {
    path: '/admin/school/:id',
    name: 'AdminSchoolDashboard',
    component: AdminSchoolDashboard,
    redirect: '/admin/school/:id/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'AdminSchoolSessionOverview',
        component: SchoolSessionOverview,
        meta: {
          guestView: false,
          breadcrumb: [
            { text: 'School', routerOptions: { name: 'AdminSchoolList' } },
            { text: 'School Dashboard' }
          ]
        }
      },
      {
        path: 'schedules/create',
        name: 'AdminSchoolScheduleCreate',
        component: SchoolScheduleCreate,
        meta: {
          guestView: false,
          breadcrumb: [
            { text: 'School', routerOptions: { name: 'AdminSchoolList' } },
            { text: 'School Dashboard' }
          ]
        }
      },
      {
        path: 'schedules/list',
        name: 'AdminSchoolScheduleList',
        component: SchoolScheduleList,
        meta: {
          guestView: false,
          breadcrumb: [
            { text: 'School', routerOptions: { name: 'AdminSchoolList' } },
            { text: 'School Dashboard' }
          ]
        }
      },
      {
        path: 'schedules/:scheduleId',
        name: 'AdminSchoolScheduleView',
        component: SchoolScheduleView,
        meta: {
          guestView: false,
          breadcrumb: [
            { text: 'School', routerOptions: { name: 'AdminSchoolList' } },
            { text: 'School Dashboard' }
          ]
        }
      },
      {
        path: 'schedules/:scheduleId/edit',
        name: 'AdminSchoolScheduleEdit',
        component: SchoolScheduleEdit,
        meta: {
          guestView: false,
          breadcrumb: [
            { text: 'School', routerOptions: { name: 'AdminSchoolList' } },
            { text: 'School Dashboard' }
          ]
        }
      },
      {
        path: 'enrollments/list',
        name: 'AdminSchoolEnrollmentList',
        component: SchoolEnrollmentList,
        meta: {
          guestView: false,
          breadcrumb: [
            { text: 'School', routerOptions: { name: 'AdminSchoolList' } },
            { text: 'School Dashboard' }
          ]
        }
      },
      {
        path: 'enrollments/:scheduleId?',
        component: SchoolEnrollmentShow,
        name: 'SchoolEnrollmentShow',
        meta: {
          guestView: false,
          breadcrumb: [
            { text: 'School', routerOptions: { name: 'AdminSchoolList' } },
            { text: 'School Dashboard' }
          ]
        }
      },
      {
        path: 'attendance/list',
        name: 'AdminSchoolAttendanceList',
        component: SchoolAttendanceList,
        meta: {
          guestView: false,
          breadcrumb: [
            { text: 'School', routerOptions: { name: 'AdminSchoolList' } },
            { text: 'School Dashboard' }
          ]
        }
      },
      {
        path: 'student_results',
        name: 'AdminSchoolStudenResults',
        component: SchoolStudentResults,
        meta: {
          guestView: false,
          breadcrumb: [
            { text: 'School', routerOptions: { name: 'AdminSchoolList' } },
            { text: 'School Dashboard' }
          ]
        }
      },
      {
        path: 'student_results/score_report/:scheduleId/:assessmentId',
        component: AdminStudentScoreReport,
        name: 'AdminStudentScoreReport',
        meta: {
          guestView: false,
          breadcrumb: [
            { text: 'School', routerOptions: { name: 'AdminSchoolList' } },
            { text: 'School Dashboard'}
          ]
        }
      },
      {
        path: 'student_results/score_report/:scheduleId/:assessmentId/subsection_details',
        component: AdminStudentScoreReportSubsectionDetails,
        name: 'AdminStudentScoreReportSubsectionDetails',
        meta: {
          guestView: false,
          breadcrumb: [
            { text: 'School', routerOptions: { name: 'AdminSchoolList' } },
            { text: 'School Dashboard'}
          ]
        }
      },
      {
        path: 'student_results/score_report/:scheduleId/:assessmentId/assessment_course_subsection_details',
        component: AdminAssessmentCourseScoreReportSubsectionDetails,
        name: 'AdminAssessmentCourseScoreReportSubsectionDetails',
        meta: {
          guestView: false,
          breadcrumb: [
            { text: 'School', routerOptions: { name: 'AdminSchoolList' } },
            { text: 'School Dashboard'}
          ]
        }
      },
      {
        path: 'student_results/score_report/:assessmentId/student/:studentId',
        component: AdminStudentScoreReportIndividual,
        name: 'AdminStudentScoreReportIndividual',
        meta: {
          guestView: false,
          breadcrumb: [
            { text: 'School', routerOptions: { name: 'AdminSchoolList' } },
            { text: 'School Dashboard'}
          ]
        }
      },
      {
        path: 'settings',
        name: 'AdminSchoolSettings',
        component: SchoolSettings,
        meta: {
          guestView: false,
          breadcrumb: [
            { text: 'School', routerOptions: { name: 'AdminSchoolList' } },
            { text: 'School Dashboard' }
          ]
        }
      }
    ]
  },
  {
    path: '/admin/school/:schoolId/attendance/:scheduleId',
    component: AdminSchoolAttendanceShow,
    props: true,
    name: 'AdminSchoolAttendanceShow',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'School', routerOptions: { name: 'AdminSchoolList' } },
        { text: 'School Dashboard'},
        { text: 'Attendance' }
      ]
    }
  },
  {
    path: '/admin/school/:schoolId/attendance/:scheduleId/:scheduleDateId',
    component: SchoolAttendanceForm,
    props: true,
    name: 'AdminSchoolAttendanceForm',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'School Dashboard' },
        { text: 'Attendance' }
      ]
    }
  },
  {
    path: '/admin/school/new',
    component: AdminSchoolForm,
    name: 'AdminSchoolNew',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Schools', routerOptions: { name: 'AdminSchoolList' } },
        { text: 'Create School' }
      ]
    }
  },{
    path: '/admin/enrollment',
    component: AdminEnrollmentList,
    name: 'AdminEnrollmentList',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Enrollment', routerOptions: { name: 'AdminEnrollmentList' } }
      ]
    }
  },{
    path: '/admin/districts',
    component: AdminDistrictList,
    name: 'AdminDistrictList',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Districts', routerOptions: { name: 'AdminDistrictList' } }
      ]
    }
  },{
    path: '/admin/districts/new',
    component: AdminDistrictForm,
    name: 'AdminDistrictNew',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Districts', routerOptions: { name: 'AdminDistrictList' } },
        { text: 'View Settings' }
      ]
    }
  },{
    path: '/admin/districts/:id/edit',
    component: AdminDistrictForm,
    name: 'AdminDistrictEdit',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Districts', routerOptions: { name: 'AdminDistrictList' } },
        { text: 'View Settings' }
      ]
    }
  },{
    path: '/admin/assessments',
    component: AdminAssessmentList,
    name: 'AdminAssessmentList',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Assessments', routerOptions: { name: 'AdminAssessmentList' } }
      ]
    }
  },{
    path: '/admin/assessments/:id/settings',
    component: AdminAssessmentSettings,
    name: 'AdminAssessmentSettings',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Assessments', routerOptions: { name: 'AdminAssessmentList' } },
        { text: 'Assessment Settings' }
      ]
    }
  },{
    path: '/admin/assessments/:id/answer_keys',
    component: AdminAssessmentAnswerKeyList,
    name: 'AdminAssessmentAnswerKeyList',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Assessments', routerOptions: { name: 'AdminAssessmentList' } }
      ]
    }
  },{
    path: '/admin/assessments/:assessmentId/answer_keys/:answerKeyId',
    component: AdminAssessmentAnswerKeyShow,
    name: 'AdminAssessmentAnswerKeyEdit',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Assessments', routerOptions: { name: 'AdminAssessmentList' } }
      ]
    }
  },{
    path: '/admin/assessments/:assessmentId/answer_key/new',
    component: AdminAssessmentAnswerKeyShow,
    name: 'AdminAssessmentAnswerKeyNew',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Assessments', routerOptions: { name: 'AdminAssessmentList' } }
      ]
    }
  },{
    path: '/admin/course-activities',
    component: AdminCourseActivitiesShow,
    name: 'AdminCourseActivitiesShow',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Dashboard', routerOptions: { name: 'AdminCourseActivitiesShow' } }
      ]
    }
  },{
    path: '/admin/course-activities/view',
    component: AdminCourseActivities,
    name: 'AdminCourseActivities',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'Dashboard', routerOptions: { name: 'AdminCourseActivitiesShow' } },
        { text: 'Course Activities' }
      ]
    }
  },{
    path: '/admin/global-search',
    component: GlobalSearch,
    name: 'GlobalSearch',
    meta: {
      guestView: false,
      breadcrumb: [
        { text: 'User Search', routerOptions: { name: 'GlobalSearch'}},
      ]
    }
  }
]
