<template>
  <div>
    <schedule-information-form
      :schedule="schedule"
      :errors="errors"
      :target-assessment-date-repository="targetAssessmentDateRepository"
      :school-repository="schoolRepository"
      :instructor-repository="instructorRepository">

      <template v-slot:class-settings-form-group>

        <div class="horizon-form-row">
          <label for="schedule-enable-waitlist">Waitlist Available</label>
          <div>
            <label class="m-2 switch">
              <input
                id="schedule-enable-waitlist"
                v-model="schedule.enableWaitlist"
                type="checkbox"
                class="default">
              <span class="round slider"/>
            </label>
          </div>
        </div>

      </template>

    </schedule-information-form>

    <div class="primary-row">
      <h2 class="text-uppercase">
        Practice Test Dates
      </h2>
    </div>

    <div class="mt-3 row">
      <div class="col-12">
        <practice-assessments-table-form
          :scheduleProp="schedule"
          :courseType="schedule.courseType"
          :practiceTestDates="schedule.practiceTestDates"
          :errors="errors.practiceTestDates"/>
      </div>
    </div>

  </div>
</template>

<script>
import ScheduleInformationForm from '@components/modules/schedule/schedule_info_form'
import PracticeAssessmentsTableForm from '@components/modules/schedule/schedule_practice_assessments_table_form'

export default {
  components: {
    ScheduleInformationForm,
    PracticeAssessmentsTableForm
  },
  props: {
    schedule: {
      type: Object,
      required: false,
      default () { return {} }
    },
    errors: {
      type: Object,
      required: false,
      default () { return {} }
    },
    instructorRepository: {
      type: Object,
      required: true
    },
    targetAssessmentDateRepository: {
      type: Object,
      required: true
    },
    schoolRepository: {
      type: Object,
      required: true
    }
  },
  methods: {
    changeScheduleAttribute(name, value) {
      this.$emit('change-attribute', name, value);
    }
  }
}
</script>

<style lang="scss" scoped>
.schedule-section-header {
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 18px;
}
</style>
