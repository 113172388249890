<template>
  <div class="container">
    <div class="content-pane">
      <div class="pane-wrapper">
        <breadcrumbs/>
        <div class="content-heading underlined">
          <h1>Assessments</h1>
        </div>
        <div class="row">
          <div
            v-for="assessment in assessments"
            :key="assessment.id"
            class="col-md-4 col-xs-12 mt-3"
            :v-if="assessments.length > 0">
            <slotted-card>
              <template v-slot:header>
                <h1>{{ assessment.assessmentType }}</h1>
              </template>
              <template v-slot:body>
                <div class="d-flex flex-column justify-content-center pb-4">
                  <h2 class="text-center text-orange">
                    {{ assessment.answerKeyCount }}
                  </h2>
                  <div class="font-12">
                    Assessments
                  </div>
                </div>
              </template>
              <template v-slot:footer>
                <div class="float-right text-right">
                  <button class="btn btn-outline-secondary" @click="goToSettings(assessment)">
                    Settings
                  </button>
                  <button class="btn btn-outline-secondary" @click="goToAnswerKey(assessment)">
                    Answer Keys
                  </button>
                </div>
              </template>
            </slotted-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SlottedCard from '@shared/slotted_card'
import Breadcrumbs from '@shared/breadcrumbs'
import { mapGetters } from 'vuex'

export default {
  components: {
    Breadcrumbs,
    SlottedCard
  },
  data () {
    return {
      assessments: []
    }
  },
  created () {
    this.initData()
  },
  methods: {
    ...mapGetters(['activeSchoolYear']),
    fetchAssessments () {
      this.$http.get('/api/admin/assessments').then(response => {
        this.assessments = response.data.assessments
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    },
    initData () {
      this.fetchAssessments()
    },
    goToSettings (assessment) {
      this.$router.push({name: 'AdminAssessmentSettings', params: { id: assessment.id } })
    },
    goToAnswerKey (assessment) {
      this.$router.push({name: 'AdminAssessmentAnswerKeyList', params: { id: assessment.id } })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
