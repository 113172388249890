<template>
  <div class="table-responsive">
    <horizon-table
      id="meeting-dates-table"
      :columns="columns"
      :data="meetingDates">
      <template v-slot:column:type="{ value }">
        <span class="fw-600">
          {{ value }}
        </span>
      </template>

      <template v-slot:column:meetingOn="{ value }">
        <span class="short-date">
          {{ value|date('ddd, MMM D, YYYY') }}
        </span>
        <span class="long-date">
          {{ value|date('dddd, MMMM D, YYYY') }}
        </span>
      </template>

      <template v-slot:column:startAt="{ value }">
        <div class="text-center">
          {{ value }}
        </div>
      </template>

      <template v-slot:column:endAt="{ value }">
        <div class="text-center">
          {{ value }}
        </div>
      </template>

      <template v-slot:column:attended="{ value }">
        <div :class="['fw-600', 'text-center', attendanceClass(value)]">
          {{ value }}
        </div>
      </template>
    </horizon-table>
  </div>
</template>

<script>
import HorizonTable from './slotted_table'
import RepositoryFactory from '@repositories/factory'
const ScheduleDateRepository = RepositoryFactory.student.get('schedule_date')

export default {
  components: { HorizonTable },
  data () {
    return {
      columns: [
        { name: 'type', display: 'Type' },
        { name: 'meetingOn', display: 'Date' },
        { name: 'startAt', display: 'Start Time', class: 'text-center' },
        { name: 'endAt', display: 'End Time', class: 'text-center' },
        { name: 'attended', display: 'Attendance' }
      ],
      meetingDates: []
    }
  },
  created () {
    this.initData()
  },
  methods: {
    async initData () {
      try {
        const meetingDates = await ScheduleDateRepository.fetchMeetingDates()
        this.meetingDates = formatData(meetingDates)
      } catch (responseError) {
        this.appendError({ error: responseError, notification: true })
      }
    },
    attendanceClass (value) {
      if (value === 'Present') { return 'text-success' }
      if (value === 'Absent') { return 'text-danger' }
      if (value === 'Tardy') { return 'text-dark' }
    }
  }
}

function formatData (scheduleDates) {
  const schedTypeFormatter = new ScheduleTypeFormatter()
  const result = scheduleDates.map( (scheduleDate) => ({
    ...scheduleDate,
    type: schedTypeFormatter.format(scheduleDate.type)
  }))
  return result
}

// NOTE: export as service if needed in other places
class ScheduleTypeFormatter {
  constructor () {
    this.classCount = 1
    this.practiceAssessmentCount = 1
    this.parentNightCount = 1
  }

  format (type) {
    switch (type) {
    case 'ScheduleDate':
      return `Class #${this.classCount++}`
    case 'PracticeTestDate':
      return `Practice Assessment #${this.practiceAssessmentCount++}`
    case 'ParentNightDate':
      return `Parent Night #${this.parentNightCount++}`
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@styles/variables.scss';

#meeting-dates-table {
  .long-date {
    display: block;
  }

  .short-date {
    display: none;
  }
}

@media screen and (max-width: $mobileScreenLarge) {
  #meeting-dates-table {
    .long-date {
      display: none;
    }

    .short-date {
      display: block;
    }
  }
}
</style>
