<template>
  <button type="button" :class="classList" @click="onClick">
    {{ buttonText }}
  </button>
</template>

<script>
export default {
  props: {
    classList: {
      type: String,
      required: false,
      default: ''
    },
    buttonText: {
      type: String,
      required: true
    },
    onClick: {
      type: Function,
      required: true
    }
  }
}
</script>
