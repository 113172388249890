<template>
  <staff-psat-8-9-student-results-pane
    :is-horizon-admin="true"/>
</template>

<script>
import StaffPsat89StudentResultsPane from '@staff/shared/psat_8_9_student_results_pane'

export default {
  components: {
    StaffPsat89StudentResultsPane,
  },
}
</script>