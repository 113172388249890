<template>
  <div class="container">
    <div class="content-pane">
      <div class="pane-wrapper">
        <breadcrumbs/>
        <content-search
          @searchClicked="fetchUsers"
        />
        <search-result
          :users="users"
         />
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumbs from '@shared/breadcrumbs'
import ContentSearch from '@shared/global_search/user_search'
import SearchResult from '@shared/global_search/search_result'

export default {
  components: {
    Breadcrumbs,
    ContentSearch,
    SearchResult,
  },
  data() {
    return {
      users: null
    }
  },
  methods: {
    fetchUsers (query) {
      this.$http.get('/api/admin/users/search', { params: { query: query } }).then(response => {
        this.users = response.data.users
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    }
  },
}
</script>
<style scoped lang="scss">
  @import '~@styles/variables.scss'
</style>