import client from '@repositories/client.js'

export default new class {

  async list(assessment) {
    const data = await client.get(`/api/staff/assessments/${assessment.id}/target_assessment_dates`);
    return data.targetAssessmentDates;
  }

}
