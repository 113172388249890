// Store in here routes that redirect to another page (e.g. Thinkific)
export default Object.freeze({
	STAFF: {
		COURSE_TRAINING: '/api/staff/learning_resources/course_training',
		COURSE_RESOURCES: '/api/staff/learning_resources/course_resources'
	},
	STUDENT: {
		COURSE_RESOURCES: '/api/student/learning_resources/course_resources',
		STUDENT_FAQ: 'https://horizoneducation.com/student-faq'
	},
	PARENT: {
		COURSE_RESOURCES: '/api/parent/learning_resources/course_resources'
	}
})
