<template>
  <session-base-layout>

    <template v-slot:header-nav-button>
      <div class="nav-links-right">
        <router-link :to="{ name: 'login' }" class="nav-button">Sign In</router-link>
      </div>
    </template>

    <template v-slot:content>
      <div class="enrollment login-outer">
        <div class="login-center-div">
          <h1 class="sign-in-heading">Forgot Password</h1>
          <div class="sign-in-form w-form">
            <div v-show="emailForReset" class="alert alert-success" role="alert">
              <p>
                <i class="fa fa-check" />
                <strong>Password reset.</strong>
              </p>
              <p>
                An email has been sent to
                <strong>{{ emailForReset }}</strong>
                with instructions on how reset your password.
              </p>
              <router-link class="text-right" :to="{name: 'login'}">Back to sign in page</router-link>
            </div>
            <form id="forgot-password-form" class="sign-in-form" @submit.prevent>
              <div class="sign-in-field">
                <label for="email" class="sign-in-form-label">Email</label>
                <input
                  id="email"
                  v-model="email"
                  type="text"
                  :class="['sign-in-form-field', 'w-input', {'highlighter-red': $v.email.$error || serverErrors.email }]"
                  autocomplete="off"
                  maxlength="256"
                  name="email"
                  data-name="email"
                  required
                />
                <small
                  v-if="$v.email.$error && !$v.email.required"
                  class="form-text text-danger"
                >Email is required.</small>
                <small
                  v-else-if="$v.email.$error && !$v.email.email"
                  class="form-text text-danger"
                >Invalid email format.</small>
                <small v-if="serverErrors.email" class="text-danger form-text">
                  The entered email is not registered within the system.
                  <router-link :to="{ name: 'signup' }">Sign up</router-link> to register!
                </small>
              </div>
              <div class="w-100">
                <button
                  v-if="!sendingRequest"
                  type="button"
                  class="sign-in-button"
                  @click="sendResetPassword"
                >Reset Password</button>
                <button v-else type="button" class="disabled fw-400 sign-in-button" disabled>
                  <span
                    class="mb-1 mr-2 spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                  Processing...
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </template>
  </session-base-layout>
</template>

<script>
import SessionBaseLayout from "@components/session/base.vue";
import { required, email } from "vuelidate/lib/validators";
import RepositoryFactory from "@repositories/factory";

const AccountRepository = RepositoryFactory.guest.get("account");

export default {
  components: { SessionBaseLayout },
  data() {
    return {
      email: "",
      emailForReset: "",
      sendingRequest: false,
      serverErrors: {}
    };
  },
  validations: {
    email: { required, email }
  },
  methods: {
    async sendResetPassword() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        this.sendingRequest = true;
        await AccountRepository.resetPassword(this.email.toLowerCase().trim());
        this.emailForReset = this.email.toLowerCase().trim();
      } catch (error) {
        const errorData = error.response.data;
        if (error.response.status == 404) {
          this.serverErrors.email = [
            {
              error: "EMAIL_NOT_FOUND",
              message: "The entered email is not registered"
            }
          ];
        } else {
          this.appendError({ error, notification: true });
        }
      } finally {
        this.sendingRequest = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
div input#email {
  text-transform: lowercase
}
</style>