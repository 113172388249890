<template>
  <div>
    <div class="align-items-center card-header d-flex pt-3">
      <div class="col-sm-3">
        <p>
          {{ (meta.perPage * (meta.currentPage - 1)) + 1 }}
          to
          <span v-if="meta.currentPage == meta.totalPages" class="span_no_styles">{{ meta.totalCount }}</span>
          <span v-else class="span_no_styles">{{ meta.perPage * meta.currentPage }}</span>
          of {{ meta.totalCount }}
        </p>
      </div>
      <div class="col-sm-9">
        <nav aria-label="Page navigation example">
          <ul class="justify-content-end pagination pagination-sm">
            <li :class="{'disabled': (meta.currentPage == 1) }" class="page-item">
              <a class="page-link" href="javascript:void(0)" @click="previousPage">
                <span aria-hidden="true">&laquo;</span>
                <span class="sr-only">Previous</span>
              </a>
            </li>
            <li v-for="pageNum in meta.pageNumbers" :key="pageNum" :class="{'active': (meta.currentPage == pageNum) }" class="page-item">
              <a class="page-link" href="javascript:void(0)" @click="gotoPage(pageNum)">{{ pageNum }}</a>
            </li>
            <li :class="{'disabled': (meta.currentPage == meta.totalPages) }" class="page-item">
              <a class="page-link" href="javascript:void(0)" @click="nextPage">
                <span aria-hidden="true">&raquo;</span>
                <span class="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    stateChangeCallback: {
      default: function () {
        console.log('stateChangeCallback is not defined')
      },
      type: Function
    },
    meta: {
      type: Object,
      default () {
        return {
          page: 1,
          currentPage: 1,
          perPage: 25,
          totalCount: 25,
          totalPages: 0,
          search: '',
          sort: '',
          order: 'ASC'
        }
      }
    }
  },
  methods: {
    updateTable (meta = {}) {
      this.$emit('paginate-data', meta)
      try {
        this.stateChangeCallback()
      } catch (e) {
        console.log('updateTable was called but stateChangeCallback was not set')
      }
    },
    nextPage () {
      this.meta.page = this.meta.currentPage + 1
      let emitMeta = _.clone(this.meta)
      emitMeta.page = this.meta.currentPage + 1
      emitMeta.currentPage = emitMeta.page
      this.updateTable(emitMeta)
    },
    previousPage () {
      this.meta.page = this.meta.currentPage - 1
      let emitMeta = _.clone(this.meta)
      emitMeta.page = this.meta.currentPage - 1
      emitMeta.currentPage = emitMeta.page
      this.updateTable(emitMeta)
    },
    gotoPage (page) {
      this.meta.page = page
      let emitMeta = _.clone(this.meta)
      emitMeta.page = page
      emitMeta.currentPage = emitMeta.page
      this.updateTable(emitMeta)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@styles/variables';

.span_no_styles {
  font-size: 1em;
}

@media screen and (max-width: $mobileScreenMedium) {
  .d-flex {
    display: block !important;
  }

  .col-sm-3 {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;

    p {
      margin-bottom: 5px;
    }
  }

  .col-sm-9 {
    flex: 0 0 100%;
    max-width: 100%;

    .justify-content-end {
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }

    ul {
      margin-bottom: 0px;
    }
  }
}
</style>
