<template>
  <div class="container">
    <div class="content-pane">
      <div class="pane-wrapper">
        <breadcrumbs/>
        <div class="content-heading underlined">
          <h1>Course Attendance</h1>
        </div>
        <div class="row">
          <div v-for="(schedule, idx) in schedules" :key="idx" :class="scheduleClassName(schedule)">
            <div v-show="schedule.scheduleType == 'Outside' ">
              <schedule-card
                :schedule="schedule"
                :card-title="schedule.name"
                :buttons="buttons"
                :show-wait-listed="true">
                <h4>{{ schedule.scheduleType }} School Day

                </h4>
                <h5>{{ schedule.primaryTeacherName }}</h5>
                <h5>{{ schedule.secondaryTeacherName }}</h5>
              </schedule-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ScheduleCard from '@shared/schedule_card'
import Breadcrumbs from '@shared/breadcrumbs'

export default {
  components: {
    Breadcrumbs,
    ScheduleCard
  },
  data () {
    return {
      schedules: [],
      buttons: [
        {
          color: 'secondary',
          onClick: (params) => {
            this.navTo({ name: 'StaffAttendanceShow', params: {scheduleId: params.schedule.id} })
          },
          text: 'View Attendance'
        }
      ]
    }
  },
  created () {
    this.initData()
  },
  methods: {
    scheduleClassName (schedule) {
      if (schedule.scheduleType == 'Outside') {
         return 'col-md-4 col-xs-12 mt-3'
      }
    },

    initData () {
      this.$http.get('/api/staff/schedules').then(response => {
        this.schedules = response.data.schedules
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    },
    navTo (routeOptions) {
      this.$router.push(routeOptions)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
