<template>
  <div class="container">
    <div class="content-pane">
      <div class="pane-wrapper">
        <breadcrumbs v-if="!isHorizonAdmin" :breadcrumbs="breadcrumbs" class="breadcrumbs" />
        <div class="content-heading underlined result-content-heading">
          <h1 class="pull-left">
            {{ activeSchedule.name }}
          </h1>
          <div class="pull-right">
            <h1 class="d-inline text-orange">
              {{ activePracticeTestDate.studentEnrollmentsCount }}
            </h1>
            <h1 class="d-inline fw-400">
              Students Enrolled
            </h1>
          </div>
        </div>
        <div v-if="isHorizonAdmin" class="report-wrapper">
          <div class="tabs-row">
            <div v-for="(item, idx) in tabsAdmin"
              :key='idx'
              class="tab"
              :class="{ selected: item.active }"
              @click="tabClicked(item.component, item.active)">
              <div>{{ item.name }}</div>
            </div>
          </div>
          <div class="w-tab-menu">
            <div
              v-for="(item, rIndx) in tabsAdmin"
              :key="rIndx"
              class="w-tab-menu-item"
              :class="{ selected: item.active }"
              @click="tabClicked(item.component, item.active)">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div v-else class="report-wrapper">
          <div class="tabs-row">
            <div v-for="(item, idx) in tabs"
              :key='idx'
              class="tab"
              :class="{ selected: item.active }"
              @click="tabClicked(item.component, item.active)">
              <div>{{ item.name }}</div>
            </div>
          </div>
          <div class="w-tab-menu">
            <div
              v-for="(item, rIndx) in tabs"
              :key="rIndx"
              class="w-tab-menu-item"
              :class="{ selected: item.active }"
              @click="tabClicked(item.component, item.active)">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div v-if="activePracticeTestDate" class="card score-report-container">
          <subsection-analysis
            v-if="!isBlank(subsectionAnalysisData.sections)"
            :request-url-base-prop="requestUrlBase()"
            :practice-test-id="activePracticeTestDate.id"
            :practice-test-date-id="getReportPracticeTestDateId()"
            :schedule-id="activeSchedule.id"
            :individual-view="false"
            :sections-data-prop="subsectionAnalysisData.sections"
            :is-horizon-admin="true"
            :assessment-type="assessmentType"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumbs from '@shared/breadcrumbs'
import Mixins from '@submodules/mixins/mixin.js'
import SubsectionAnalysis from '@shared/score_report/subsection_score_details.vue'
import CommonLoading from '@shared/common_loading'

export default {
  components: {
    Breadcrumbs,
    SubsectionAnalysis,
    CommonLoading
  },
  mixins: [Mixins],
  props: {
    isHorizonAdmin: {
      type: Boolean,
      default: false
    },
    adminAssessmentType: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
      scheduleTableHeaders: [],
      assessmentId: '',
      breadcrumbs: [],
      practiceTestDates: [],
      activeSchedule: {
        targetAssessmentDate: {}
      },
      activePracticeTestDate: {},
      subsectionAnalysisData: {},
      loadingSectionData: false,
      tabs: [
        {
          active: false,
          name: "Summary",
          component: "StaffStudentScoreReport",
        },
        {
          active: true,
          name: "Subsection Details",
          component: "StaffStudentScoreReportSubsectionDetails",
        },
        {
          active: false,
          name: "Advanced Reporting (Beta)",
          component: "StaffAdvancedReporting",
        }
      ],
      tabsAdmin: [
        {
          active: false,
          name: "Summary",
          component: "AdminStudentScoreReport",
        },
        {
          active: true,
          name: "Subsection Details",
          component: "AdminStudentScoreReportSubsectionDetails",
        },
        {
          active: false,
          name: "Advanced Reporting (Beta)",
          component: "StaffAdvancedReporting",
        }
      ]
    }
  },
  created () {
    this.selectedAssessmentId = this.$route.params.assessmentId
    this.activeSchedule.id = this.$route.params.scheduleId
    this.initData()
  },
  computed:{
    assessmentType () {
      if (this.isHorizonAdmin) {
        return this.adminAssessmentType;
      } else {
        return this.userSchool().courseType;
      }
    },
    entireSchool () {
      return { id: 0, name: 'Entire School', courseType: this.assessmentType }
    }
  },
  methods: {
    ...mapGetters(['userSchool']),
    initData () {
      // this.loadSchedules()
      this.loadSchedule(this.activeSchedule)
    },
    getReportPracticeTestDateId() {
      // NOTE:
      //   - activeSchedule.id is zero if "Entire School" selected
      //   - activePracticeTestDate.id is only valid if NOT Entire School is selected
      if (this.activeSchedule.id == 0) return 0
      return this.activePracticeTestDate.id
    },
    async loadSchedule (schedule) {
      if(schedule.id == 0) {
        this.activeSchedule = this.entireSchool
        this.loadSchoolPracticeTestSets()
        this.loadSubsectionAnalysis()
      } else {
        let endpointURL = `/api/staff/schedules/${schedule.id}`
        if (this.isHorizonAdmin) {
          endpointURL = `/api/admin/schools/${this.$route.params.id}/schedules/${schedule.id}`
        }
        this.$http.get(endpointURL).then(response => {
          this.activeSchedule = response.data.schedule
          this.loadPracticeTests()
        }, (responseError) => {
          this.appendError({ error: responseError, notification: true })
        })
      }
    },
    async loadSchoolPracticeTestSets() {
      let endpointURL = `/api/staff/school/practice_test_sets/${this.selectedAssessmentId}`
      if (this.isHorizonAdmin) {
        endpointURL = `/api/admin/schools/${this.$route.params.id}/practice_test_sets/${this.selectedAssessmentId}`
      }
      const response = await this.$http.get(endpointURL)
      this.practiceTestDates = response.data.practiceTestSets
      this.activePracticeTestDate = this.practiceTestDates.find(e => e.id == this.$route.params.assessmentId);
      this.breadcrumbs =[{text: this.activePracticeTestDate.assessmentName}]
    },
    loadPracticeTests () {
      let endpointURL = `/api/staff/schedules/${this.activeSchedule.id}/practice_test_dates`
      if (this.isHorizonAdmin) {
        endpointURL = `/api/admin/schools/${this.$route.params.id}/schedules/${this.activeSchedule.id}/practice_test_dates`
      }
      this.$http.get(endpointURL).then(response => {
        this.practiceTestDates = response.data.practiceTestDates
        this.activePracticeTestDate = this.practiceTestDates.find(practiceTest => practiceTest.id == this.selectedAssessmentId)
        if (this.isBlank(this.activePracticeTestDate)) {
          this.activePracticeTestDate = this.practiceTestDates[0]
          this.selectedAssessmentId = this.activePracticeTestDate.id
        }
        this.breadcrumbs =[{text: this.activePracticeTestDate.assessmentName}]
        this.loadSubsectionAnalysis()
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    },
    async loadSubsectionAnalysis () {
      const response = await this.$http.get(`${this.requestUrlBase()}/score_details`)
      this.subsectionAnalysisData = response.data.scoreDetails
    },
    requestUrlBase () {
      if(this.activeSchedule.id == 0)  {
        if (this.isHorizonAdmin) {
          return `/api/admin/schools/${this.$route.params.id}/practice_test_dates/${this.selectedAssessmentId}`
        } else {
          return `/api/staff/school/practice_test_dates/${this.selectedAssessmentId}`
        }
      } else {
        if (this.isHorizonAdmin) {
          return `/api/admin/schools/${this.$route.params.id}/schedules/practice_test_dates/${this.selectedAssessmentId}`
        } else {
          return `/api/staff/schedules/${this.activeSchedule.id}/practice_test_dates/${this.selectedAssessmentId}`
        }
      }
    },
    tabClicked(component, active) {
      if (!active) {
        let params = {
          assessmentId: this.selectedAssessmentId,
          scheduleId: this.activeSchedule.id
        }
        if (this.isHorizonAdmin) {
          params.assessmentId = this.$route.params.assessmentId
          params.scheduleId = this.$route.params.scheduleId
        }
        this.$router.push({
          name: component,
          params: params
        })
      }
    },
  }
}
</script>

<style scoped lang="scss">
  @import '~@styles/variables';

  .underlined {
    border-bottom: 0px;
  }

  .score-report-heading {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .result-content-heading {
    padding-bottom: 30px;
  }

  .tabs-row {
    padding: 0px 1px;
  }

  .w-tab-menu {
    display: none;
  }

  @media screen and (max-width: $mobileScreenLarge) {
    .content-heading {
      display: block;

      .pull-left {
        width: 100%;
      }

      .pull-right {
        width: 100%;
        float: left;
        text-align: left;
        margin-left: 0px;
        margin-bottom: 20px;
      }
    }

    .underlined {
      border-bottom: 0px;
    }
  }

  @media screen and (max-width: $mobileScreenMedium) {
    .container {
      .content-pane {
        .pane-wrapper {
          padding: 20px 10px;
        }
      }
    }

    .breadcrumbs {
      display: none;
    }

    .tab {
      padding: 13px 20px;
      font-size: 16px;
    }

    .score-report-heading {
      display: block;
      text-align: center;

      h1 {
        font-size: 18px;
      }

      h2 {
        margin-top: 5px;
        font-size: 14px;
      }
    }

    .mt-3 {
      margin-top: 0px !important;
    }
  }

  @media screen and (max-width: $mobileScreenSmall) {
    .container {
      padding: 0px;
      height: 100%;

      .content-pane {
        border-radius: 0px;
        height: 100%;
      }
    }

    .tab {
      display: none;
    }

    .w-tab-menu {
      display: block;
      width: 100%;

      .w-tab-menu-item {
        width: 100%;
        transition: .2s;
        cursor: pointer;
        color: $mutedText;
        padding: 13px 25px;
        text-align: center;
        border-radius: 7px;
        &.selected {
          background-color: $primaryColor;
          color: #fff;
        }
        &:not(.selected):hover {
          color: $themeGray;
        }
      }
    }
  }
</style>
