<template>
  <div class='col-12 mt-3'>
     <update-student-modal
      v-if="selectedStudent"
      :initial-enrollment="selectedStudent"
      :schedule-id-prop="scheduleId"

      @updated-student="updatedStudent"/>
    <div class="card">
      <div class="bg-white card-header card-header-primary">
            <div class="row pt-3 pb-3">
              <div class="col-md-12 pb-4">
                <h1 class="enrollment-function-head">
                  {{ schedule.name }}
                </h1>
              </div>
              <div class="col-md-2">
                <span class="text-gray fw-500">VALID</span>
                <br>
                <span class="font-16 fw-600 text-success">
                  {{ totalValidStudents }}
                </span>
              </div>
              <div class="col-md-2">
                INVALID
                <br>
                <span class="font-16 fw-600 text-danger">
                  {{ totalInvalidStudents }}
                </span>
              </div>
              <div class="col-md-2">
                PENDING
                <br>
                <span class="font-16 fw-600">
                  {{ totalPendingStudents }}
                </span>
              </div>
              <div class="col-md-2">
                EXISTING
                <br>
                <span class="font-16 fw-600">
                  {{ totalExistingStudents }}
                </span>
              </div>
              <div class="col-md-2">
                TOTAL
                <br>
                <span class="font-16 fw-600">
                  {{ totalStudents }}
                </span>
              </div>

        </div>
      </div>
      <table class="table table-course-enrollment">
            <thead class="bg-primary">
              <tr>
                <th scope="col" class="cursor-hand text-left th-lastname">
                  Last Name
                </th>
                <th scope="col" class="cursor-hand text-left th-firstname">
                  First Name
                </th>
                <th scope="col" class="cursor-hand text-left th-firstname">
                  Email
                </th>
                <th scope="col" class="cursor-hand text-left th-firstname">
                  Phone Number
                </th>
                <th scope="col" class="cursor-hand text-left th-grade">
                  Grade Level
                </th>
                <th scope="col" class="text-left th-student-id">
                  Student ID
                </th>
                 <th scope="col" class="text-left th-existing-student">
                  Existing Student
                </th>

                <th scope="col" class="text-left th-student-errors">
                  Errors
                </th>
                <th scope="col">
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(student, idx) in tempStudents"
                :key="idx">
                <td class="fw-600 student-last-name text-left td-lastname">
                  {{ student.lastName }}
                </td>
                <td class="fw-600 student-last-name text-left td-lastname">
                  {{ student.firstName }}
                </td>
                <td class="fw-600 student-last-name text-left td-lastname">
                  {{ student.email }}
                </td>
                <td class="fw-600 student-last-name text-left td-lastname">
                  {{ student.phoneNumber }}
                </td>
                <td class="fw-600 student-last-name text-left td-lastname">
                  {{ student.gradeLevel }}
                </td>
                <td class="fw-600 student-last-name text-left td-lastname">
                  {{ student.studentIdNumber }}
                </td>
                 <td class="fw-600 student-last-name text-left td-lastname">
                  {{ student.existing }}
                </td>

                <td class="fw-600 student-last-name text-left td-lastname">
                  <span v-if="(student.validationStatus == 'valid_student')" class="fa fa-check text-success"></span>
                   <span v-else-if="(student.validationStatus == 'pending')" class="badge bg-warning">Pending Validation</span>
                  <span v-else-if="(student.dataErrors.length > 0)">{{ student.dataErrors }}</span>
                </td>
                <td>
                  <button
                class="btn btn-outline-secondary gray-hover action-btn"
                 @click="selectAndOpenUpdateModal(student)">

                <i class="fa fa-lg fa-fw fa-pencil-alt" />
              </button>
              <button
                class="btn btn-outline-secondary gray-hover action-btn"
                @click="deleteStudent(student)">

                <i class="fa fa-lg fa-trash" />
              </button>
                </td>
              </tr>
            </tbody>
          </table>
          <hr>
          <div class="primary-row-action text-right pb-3 pt-3 pr-3">
            <button class="btn btn-lg btn-outline-secondary gray-hover font-14 fw-400 settings-btn" @click="cancelUpload">
              <span class="font-14 text-decoration-0">Cancel</span>
            </button>
            <button class="btn btn-primary btn-lg settings-btn font-14 fw-400" @click="importStudents">
              <span class="font-14">Upload Students</span>
            </button>
          </div>
          <common-table-pagination
            v-if="showPagination"
            :meta="meta"
            :state-change-callback="updateTable"
            @paginate-data="paginateData"/>


    </div>
  </div>
</template>
<script>
import CommonTablePagination from '@submodules/components/common_table/pagination'
import UpdateStudentModal from './modal/update_modal'
import { mapGetters } from 'vuex'

export default {
  components: {
    CommonTablePagination,
    UpdateStudentModal
  },
  data () {
    return {
      totalStudents: 0,
      totalInvalidStudents: 0,
      totalValidStudents: 0,
      totalPendingStudents: 0,
      totalExistingStudents: 0,
      importId: '',
      scheduleId: '',
      schoolId: '',
      tempStudents: [],
      showPagination: false,
      schedule: {},
      selectedStudent: {},
      meta: {
        page: 1,
        currentPage: 1,
        perPage: 100,
        totalCount: 0,
        totalPages: 0,
        sort: null,
        order: null,
      }
    }
},
created () {
  if (this.$route.params.id != undefined && this.$route.params.importId != undefined ) {
    this.scheduleId = this.$route.params.id
    this.importId   = this.$route.params.importId
    this.fetchSchedule()
    this.fetchTempStudents()
    this.fetchSummary()
  }
},
methods: {
  ...mapGetters(['userRole']),

  cancelUpload() {
    this.$http.delete(`/api/staff/temp_student_imports/${this.importId}`).then(() => {
      let endpointURL = { name: 'StaffEnrollmentsShow', params: {scheduleId: this.scheduleId}}
      if (this.userRole() == 'Horizon Admin') {
        endpointURL = { name: 'SchoolEnrollmentShow', params: { id: this.schoolId, scheduleId: this.scheduleId} }
      }
      this.$router.push(endpointURL)
      this.appendSuccess({message: 'Upload cancelled successfully.', notification: true})
    }, (responseError) => {
      this.appendError({error: responseError, notification: true})
    })
  },

  deleteStudent(student) {
    this.$http.delete(`/api/staff/schedules/${this.scheduleId}/temp_students/${student.id}`).then(() => {
      this.fetchTempStudents()
      this.fetchSummary()
    }, (responseError) => {
      this.appendError({error: responseError, notification: true})
    })
  },

  importStudents () {
    let endpointURL = {name: 'StaffEnrollmentsShow', params: {scheduleId: this.scheduleId}}
    if (this.userRole() == 'Horizon Admin') {
      endpointURL = { name: 'SchoolEnrollmentShow', params: { id: this.schoolId, scheduleId: this.scheduleId} }
    }
    this.$http.post(`/api/staff/temp_student_imports/${this.importId}/imports/`).then(() => {
    this.$router.push(endpointURL)
      this.fetchTempStudents()
      this.fetchSummary()
    }, (responseError) => {
      this.appendError({error: responseError, notification: true})
    })
  },

  updatedStudent () {
    $('#update-student-modal').modal('hide')
    // NOTE: We may need to optimize this later when  there's
    //  a significant performance decline
    this.fetchTempStudents()
    this.fetchSummary()
  },

  async selectAndOpenUpdateModal(student) {
    this.selectedStudent = student
    await this.$nextTick()
    $('#update-student-modal').modal('show')
  },

  paginateData(meta) {
    this.meta.currentPage = meta.currentPage
    this.fetchTempStudents()
    this.fetchSummary()
  },

  updateTable () {
    try {
      this.stateChangeCallback()
    } catch (e) {
      console.log('updateTable was called but stateChangeCallback was not set')
    }
  },

  fetchSummary() {
    this.$http.get(`/api/staff/schedules/${this.scheduleId}/temp_student_summaries/${this.importId}`).then(response => {
      this.totalStudents        = response.data.totalTempStudents
      this.totalInvalidStudents = response.data.totalInvalidStudents
      this.totalExistingStudents = response.data.totalExistingStudents
      this.totalValidStudents   = response.data.totalValidStudents
      this.totalPendingStudents = response.data.totalPendingStudents
      this.showPagination = true
    }, (responseError) => {
      this.appendError({ error: responseError, notification: true })
    })
  },

  fetchSchedule() {
    let endpointURL = `/api/staff/schedules/${this.scheduleId}`
    if (this.userRole() == 'Horizon Admin') {
      endpointURL = `/api/admin/schedules/${this.scheduleId}`
    }
    this.$http.get(endpointURL).then(response => {
      this.schedule = response.data.schedule
      this.schoolId = this.schedule.schoolId
      this.breadcrumbs = [{text: this.schedule.name}]
    }, (responseError) => {
      this.appendError({ error: responseError, notification: true })
    })
  },

  fetchTempStudents () {
    this.$http.get(`/api/staff/schedules/${this.scheduleId}/temp_students/${this.importId}?page=${this.meta.currentPage}&per_page=${this.meta.perPage}&sort=${this.meta.sort}&order=${this.meta.order}`).then(response => {
      this.tempStudents = response.data.tempStudents
      this.meta = response.data.meta
      this.showPagination = true
    }, (responseError) => {
      this.appendError({ error: responseError, notification: true })
    })
  },
}
}
</script>
<style lang="scss" scoped>
.action-btn {
  font-size: 12px !important;
}
</style>
