<template>
  <div class="container">
    <div class="row">
      <div class="col-9">
        <staff-schedules-pane/>
        <staff-attendance-pane style="margin-top: 15px;"/>
        <staff-student-results-pane style="margin-top: 15px;" class="content-pane dashboard-pane" v-if="satCourseEnabled"/>
        <staff-act-student-results-pane style="margin-top: 15px;" class="content-pane dashboard-pane" v-if="actCourseEnabled"/>
        <staff-psat-8-9-student-results-pane style="margin-top: 15px;" class="content-pane dashboard-pane" v-if="psat89CourseEnabled"/>
        <staff-psat-10-student-results-pane style="margin-top: 15px;" class="content-pane dashboard-pane" v-if="psat10CourseEnabled"/>
        <staff-pre-act-9-student-results-pane style="margin-top: 15px;" class="content-pane dashboard-pane" v-if="preact9CourseEnabled"/>
        <staff-pre-act-10-student-results-pane style="margin-top: 15px;" class="content-pane dashboard-pane" v-if="preact10CourseEnabled"/>
      </div>
      <div class="col-3">
        <a v-if="hasThinkificBundleId" :href="courseResourcesUrl" target="_blank" class="text-decoration-none">
          <div class="cursor-hand dashboard-pane h-col-mb small-card text-center">
            <img
              src="/images/teacher_resources.png"
              class="small-card-img">
            <div>
              <h2 class="d-inline">
                Teacher Resources
              </h2>
              <span class="text-orange"><i class="fa fa-arrow-right"/></span>
            </div>
            <div class="font-10">
              Course Syllabus, Documents, Presentations
            </div>
          </div>
        </a>

        <div v-show="false"
          class="dashboard-pane h-col-mb small-card text-center">
          <a :href="courseTrainingUrl" target="_blank" class="text-decoration-none">
            <img
              src="/images/teacher_training.jpeg"
              class="small-card-img"/>
            <div>
              <h2 class="d-inline">
                Teacher Training
              </h2>
              <span class="text-orange"><i class="fa fa-arrow-right"/></span>
            </div>
            <div class="font-10">
              Online Professional Development Course
            </div>
          </a>
        </div>

        <a href="https://horizoneducation.com/support-articles/introduction"
          target="_blank"
          class="cursor-hand dashboard-pane h-col-mb small-card text-center text-decoration-none">
          <img
            src="/images/teacher_training.jpeg"
            class="small-card-img">
          <div>
            <h2 class="d-inline">
              Support Center
            </h2>
            <span class="text-orange">
              <i class="fa fa-arrow-right"/>
            </span>
          </div>
          <div class="font-10">
            Help Documentation
          </div>
        </a>

        <router-link :to="{ name: 'StaffMessageList' }" tag="div" class="cursor-hand dashboard-pane h-col-mb small-card text-center">
          <img
            src="/images/message_students.png"
            class="small-card-img">
          <div>
            <h2 class="d-inline">
              Message Students
            </h2>
            <span class="text-orange"><i class="fa fa-arrow-right"/></span>
          </div>
          <div class="font-10">
            Send Custom Messages to Students
          </div>
        </router-link>

        <router-link :to="{ name: 'StaffScheduleList' }" tag="div" class="cursor-hand dashboard-pane h-col-mb small-card text-center">
          <img
            src="/images/course_schedules.png"
            class="small-card-img">
          <div>
            <h2 class="d-inline">
              Course Schedules
            </h2>
            <span class="text-orange"><i class="fa fa-arrow-right"/></span>
          </div>
          <div class="font-10">
            View & Modify Course Schedules
          </div>
        </router-link>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import StaffAttendancePane from './attendance_pane'
import StaffSchedulesPane from './schedules_pane'
import StaffStudentResultsPane from '@staff/shared/student_results_pane'
import StaffActStudentResultsPane from '@staff/shared/act_student_results_pane'

import StaffPsat89StudentResultsPane from '@staff/shared/psat_8_9_student_results_pane'
import StaffPsat10StudentResultsPane from '@staff/shared/psat_10_student_results_pane'

import StaffPreAct9StudentResultsPane from '@staff/shared/preact9_student_results_pane'
import StaffPreAct10StudentResultsPane from '@staff/shared/preact10_student_results_pane'

import ExternalRoutes from '../../../routes/external_links'
import OnRamp from '@shared/onramp.js'

export default {
  components: {
    StaffAttendancePane,
    StaffSchedulesPane,
    StaffStudentResultsPane,
    StaffPsat89StudentResultsPane,
    StaffPsat10StudentResultsPane,
    StaffActStudentResultsPane,
    StaffPreAct9StudentResultsPane,
    StaffPreAct10StudentResultsPane,
    OnRamp
  },
  data () {
    return {}
  },
  methods: {
    ...mapGetters(['userSchool','userEmail'])
  },
  beforeMount(){
    _onramp_widget({ 
      organization: "8f2fb251-0533-4800-9350-1433fc4ed22d",
      user: this.userEmail(),
      scheme: 'plaintext'});
  },
  computed: {
    courseTrainingUrl() {
      return ExternalRoutes.STAFF.COURSE_TRAINING
    },
    courseResourcesUrl() {
      return ExternalRoutes.STAFF.COURSE_RESOURCES
    },

    satCourseEnabled() {
      return this.userSchool().availableCourses.includes('sat')
    },
    actCourseEnabled() {
      return this.userSchool().availableCourses.includes('act')
    },

    psat89CourseEnabled() {
      return this.userSchool().availableCourses.includes('psat89')
    },

    psat10CourseEnabled() {
      return this.userSchool().availableCourses.includes('psat10')
    },

    preact9CourseEnabled() {
      return this.userSchool().availableCourses.includes('preact9')
    },

    preact10CourseEnabled() {
      return this.userSchool().availableCourses.includes('preact10')
    },

    schoolPsat10Available () {
      this.userSchool().psat10Available
    },

    hasThinkificBundleId() {
      return !this.userSchool().thinkificBundleId == ""
    }

  }
}
</script>

<style scoped lang="scss">
@import '~@styles/variables';

.small-card {
	padding: 15px;
  min-height: 190px;
  align-items: center;
  justify-content: center;
  transition: box-shadow 200ms ease, background-color 200ms ease;
  &:hover {
    box-shadow: 0 1px 3px 3px #bbb;
  }
}

.small-card-img {
  max-height: 100px;
  max-width: 140px;
	height: auto;
  padding-bottom: 10px;
	margin-top: 5px;
}

@media screen and (max-width: $mobileScreenMedium) {
  .container {
    .row {
      display: block;

      .col-9 {
        width: 100%;
        max-width: none;
      }

      .col-3 {
        width: 100%;
        max-width: none;
        margin-top: 15px;
      }

      .dashboard-pane {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: $mobileScreenSmall) {
  .container {
    .row {
      .col-9 {
        padding: 0px;
      }

      .col-3 {
        padding: 0px;
      }

      .dashboard-pane {
        border-radius: 0px;
      }
    }
  }
}
</style>
