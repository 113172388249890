<template>
  <div>
    <div class="primary-row schedule-section-header">
      <div>
        <h2 class="text-uppercase">
          Schedule Settings
        </h2>
      </div>
      <div>
        <slot name="schedule-header-buttons"/>
      </div>
    </div>
    <div class="form-group">
      <div class="horizon-form-column">
        <div class="horizon-form-row">
          <div class="label">
            <label for="schedule-name">Schedule Name</label>
          </div>
          <div class="input">
            <input
              id="schedule-name"
              v-model="schedule.name"
              type="text"
              :class="['horizon-input', {'highlighter-red': this.errors.schedule.name}]"
              placeholder="">
          </div>
        </div>

        <div class="horizon-form-row">
          <div class="label">
            <label for="course-type">Assessment Course</label>
          </div>
          <div>
            <span class="font-12 margin-bottom-auto margin-top-auto text-truncate">
              {{ schedule.courseType.assessment_type }}
            </span>
          </div>
        </div>

<!--         <div class="horizon-form-row">
          <div class="label">
            <label for="schedule-type">Schedule Type</label>
          </div>
          <div class="input">
            <common-dropdown
              id="schedule-type"
              :items="computedScheduleTypeOptions"
              :item-display-key="'name'"
              :on-item-select="updateScheduleType"
              dropdown-class="btn btn-outline-secondary d-flex dropdown horizon-input justify-content-between no-hover">
              <span class="font-12 margin-bottom-auto margin-top-auto text-truncate">
                {{ scheduleType }}
              </span>
            </common-dropdown>
          </div>
        </div> -->

        <div class="horizon-form-row">
          <div class="label">
            <label for="schedule-type">Schedule Type</label>
          </div>
          <div class="input">
            <span class="font-12 margin-bottom-auto margin-top-auto text-truncate">
              {{ scheduleType }}
            </span>
          </div>
        </div>

        <div v-if="(computedHideOnPSAT && computedHideOnPREACT)" class="horizon-form-row">
          <div class="label">
            <label for="schedule-target-assessment-date">Target Assessment Date</label>
          </div>
          <div class="input">
            <common-dropdown
              id="schedule-target-assessment-date"
              :items="targetAssessmentDateOptions"
              :item-display-key="'name'"
              :on-item-select="updateTargetAssessmentDate"
              dropdown-class="btn btn-outline-secondary d-flex dropdown horizon-input justify-content-between no-hover">
              <span class="font-12 margin-bottom-auto margin-top-auto text-truncate">
                {{ targetAssessmentDateName }}
              </span>
            </common-dropdown>
          </div>
        </div>

        <div class="horizon-form-row">
          <div class="label">
            <label for="schedule-primary-teacher">Instructor #1</label>
          </div>
          <div class="input">
            <common-dropdown
              id="schedule-primary-teacher"
              :items="instructorOptions"
              :on-item-select="updatePrimaryTeacher"
              dropdown-class="btn btn-outline-secondary d-flex dropdown horizon-input justify-content-between no-hover">
              <span class="font-12 margin-bottom-auto margin-top-auto text-truncate">
                {{ instructorFullName(schedule.primaryTeacher) }}
              </span>

              <template v-slot:option="{ item: instructor }">
                <span class="font-12 margin-bottom-auto margin-top-auto text-truncate">
                  {{ instructorFullName(instructor) }}
                </span>
              </template>
            </common-dropdown>
          </div>
        </div>

        <div class="horizon-form-row">
          <div class="label">
            <label for="schedule-secondary-teacher">Instructor #2</label>
          </div>
          <div class="input">
            <common-dropdown
              id="schedule-secondary-teacher"
              :items="instructorOptions"
              :on-item-select="updateSecondaryTeacher"
              dropdown-class="btn btn-outline-secondary d-flex dropdown horizon-input justify-content-between no-hover">
              <span class="font-12 margin-bottom-auto margin-top-auto text-truncate">
                {{ instructorFullName(schedule.secondaryTeacher) }}
              </span>

              <template v-slot:option="{ item: instructor }">
                <span class="font-12 margin-bottom-auto margin-top-auto text-truncate">
                  {{ instructorFullName(instructor) }}
                </span>
              </template>
            </common-dropdown>
          </div>
        </div>

        <div v-if="(computedHideOnPSAT && computedHideOnPREACT)" class="horizon-form-row">
          <div class="label">
            <label for="schedule-enable-parent-night">Parent Night</label>
          </div>
          <div class="input">
            <label id="schedule-enable-parent-night-display" class="m-2 switch">
              <input
                id="schedule-enable-parent-night"
                v-model="schedule.enableParentNight"
                type="checkbox"
                class="default">
              <span class="round slider"/>
            </label>
          </div>
        </div>

        <div class="horizon-form-row">
          <div class="label">
            <label for="schedule-untimed-learnosity-assessment">Untimed Learnosity Assessment</label>
          </div>
          <div class="input">
            <label id="schedule-untimed-learnosity-assessment-display" class="m-2 switch">
              <input
                id="schedule-untimed-learnosity-assessment"
                v-model="schedule.untimedLearnosityAssessment"
                type="checkbox"
                class="default">
              <span class="round slider"/>
            </label>
          </div>
        </div>
      </div>
      <div class="horizon-form-column">
        <div class="horizon-form-row">
          <div class="label">
            <label for="schedule-enrollment-cap">Enrollment Cap</label>
          </div>
          <div class="input">
            <input
              id="schedule-enrollment-cap"
              v-model="schedule.enrollmentCap"
              type="number"
              class="d-inline-block horizon-input"
              placeholder="">
          </div>
        </div>

        <!-- <div class="horizon-form-row">
          <label for="schedule-enable-waitlist">Waitlist Available</label>
          <div>
            <label class="m-2 switch">
              <input
                id="schedule-enable-waitlist"
                v-model="schedule.enableWaitlist"
                type="checkbox"
                class="default">
              <span class="round slider"/>
            </label>
          </div>
        </div> -->

        <!-- NOTE: additional fields for outside type -->
        <slot name="class-settings-form-group"/>

        <div v-if="(computedHideOnPSAT && computedHideOnPREACT)" class="horizon-form-row">
          <div class="label">
            <label for="schedule-assessment-sms-reminders">Assessment SMS Reminders</label>
          </div>
          <div class="input">
            <label id="schedule-assessment-sms-reminders-display" class="m-2 switch">
              <input
                id="schedule-assessment-sms-reminders"
                v-model="schedule.enableAssessmentSmsReminders"
                type="checkbox"
                class="default">
              <span class="round slider"/>
            </label>
          </div>
        </div>

        <div v-if="(computedHideOnPSAT && computedHideOnPREACT)" class="horizon-form-row">
          <div class="label">
            <label for="schedule-assessment-email-reminders">Assessment Email Reminders</label>
          </div>
          <div class="input">
            <label id="schedule-assessment-email-reminders-display" class="m-2 switch">
              <input
                id="schedule-assessment-email-reminders"
                v-model="schedule.enableAssessmentEmailReminders"
                type="checkbox"
                class="default">
              <span class="round slider"/>
            </label>
          </div>
        </div>

        <div v-if="(computedHideOnPSAT && computedHideOnPREACT)" class="horizon-form-row">
          <div class="label">
            <label for="assessmentLocation">Assessment Location</label>
          </div>
          <div class="input">
            <input
              id="assessmentLocation"
              v-model="schedule.assessmentLocation"
              type="text"
              class="horizon-input"
              placeholder="">
          </div>
        </div>

        <div v-if="(computedHideOnPSAT && computedHideOnPREACT)" class="horizon-form-row">
          <div class="label">
            <label for="zoomUrl">Zoom URL</label>
          </div>
          <div class="input">
            <input
              id="zoomUrl"
              v-model="schedule.zoomUrl"
              type="text"
              class="horizon-input"
              placeholder="">
          </div>
        </div>

        <div class="horizon-form-row">
          <div class="label">
            <label for="schedule-online-assessment-available">Online Assessment Available</label>
          </div>
          <div class="input">
            <label id="schedule-online-assessment-available-display" class="m-2 switch">
              <input
                id="schedule-online-assessment-available"
                v-model="schedule.onlineAssessmentEnabled"
                type="checkbox"
                class="default">
              <span class="round slider"/>
            </label>
          </div>
        </div>

        <div class="horizon-form-row">
          <div class="label">
            <label for="schedule-course-template">Course Template</label>
          </div>
          <div class="input">
            <common-dropdown
              id="schedule-course-template"
              :items="courseTemplateOptions"
              :on-item-select="updateCourseTemplate"
              dropdown-class="btn btn-outline-secondary d-flex dropdown horizon-input justify-content-between no-hover">
              <span class="font-12 margin-bottom-auto margin-top-auto text-truncate">
                {{ courseName(schedule.courseTemplate) }}
              </span>

              <template v-slot:option="{ item: courseTemplate }">
                <span class="font-12 margin-bottom-auto margin-top-auto text-truncate">
                  {{ courseTemplate.name }}
                </span>
              </template>
            </common-dropdown>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import CommonDropdown from '@shared/common_dropdown'
import RepositoryFactory from "@repositories/factory";

export default {
  components: {
    CommonDropdown
  },
  props: {
    schedule: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: false,
      default () { return {} }
    },
    validations:{
      type: Object,
      required: false,
      default () { return {} }
    },
    instructorRepository: {
      type: Object,
      required: true
    },
    targetAssessmentDateRepository: {
      type: Object,
      required: true
    },
    schoolRepository: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      courseTypeOptions: {
        ACT: [
          { value: 'ACT', name: 'ACT'},
          { value: 'PREACT9', name: 'PREACT9'},
          { value: 'PREACT10', name: 'PREACT10'},
        ],
        SAT: [
          { value: 'SAT', name: 'SAT'},
          { value: 'PSAT89', name: 'PSAT89'},
          { value: 'PSAT10', name: 'PSAT10'},
        ],
      },
      scheduleTypeOptions: {
        default: [
          { value: 'Inside', name: 'Inside School Day' },
          { value: 'Outside', name: 'Outside School Day' }
        ],
        ACT: [
          { value: 'PREACT 9 Assessment', name: 'PREACT 9 Assessment' },
          { value: 'PREACT 10 Assessment', name: 'PREACT 10 Assessment' },
        ],
        PREACT9: [
          { value: 'PREACT 9 Assessment', name: 'PREACT 9 Assessment' }
        ],

        PREACT10: [
          { value: 'PREACT 10 Assessment', name: 'PREACT 10 Assessment' }
        ],

        SAT: [
          { value: 'PSAT 8/9 Assessment', name: 'PSAT 8/9 Assessment' },
          { value: 'PSAT 10 Assessment', name: 'PSAT 10 Assessment' }
        ]
      },
      schoolAssessment: null,
      instructorOptions: [],
      targetAssessmentDateOptions: [],
      satOnlineAvailable: false,
      courseTemplateOptions: []
    }
  },
  computed: {
    computedScheduleTypeOptions () {
      let additionalScheduleType = this.scheduleTypeOptions[this.courseType]
      if (typeof additionalScheduleType !== "undefined") {
        return [].concat(this.scheduleTypeOptions.default, additionalScheduleType)
      } else {
        return [].concat(this.scheduleTypeOptions.default)
      }
    },
    computedHideOnPSAT () {
      let checkIfAdditionalPSATOption   = _.find(this.scheduleTypeOptions.SAT, { 'name': this.scheduleType})
      let checkIfAdditionalPREACTOption = _.find(this.scheduleTypeOptions.ACT, { 'name': this.scheduleType})

     if (typeof checkIfAdditionalPSATOption === "undefined" || checkIfAdditionalPREACTOption === "undefined") {
        return true
      } else {
        return false
      }
    },
    computedHideOnPREACT () {
      let checkIfAdditionalPREACTOption = _.find(this.scheduleTypeOptions.ACT, { 'name': this.scheduleType})
      if (typeof checkIfAdditionalPREACTOption === "undefined") {
        return true
      } else {
        return false
      }
    },
    scheduleType () {
      return this.schedule.scheduleType;
    },
    targetAssessmentDateName () {
      return this.schedule.targetAssessmentDate ?
        this.schedule.targetAssessmentDate.name :
        'Select target assessment date'
    },
    computedCourseTypeOptions () {
      this.searchTypeOptions();
      if(this.schedule.schoolAssessment){
        return this.courseTypeOptions[this.schedule.schoolAssessment.assessment_type];
      }
      return this.courseTypeOptions.SAT;
    },
    courseType() {
      return this.schedule.courseType;
    },
  },
  mounted () {
    const school = this.schoolRepository.info();
    this.schedule.schoolAssessment = school.assessment;
  },
  created () {
    this.loadInstructors()
    this.loadTargetAssessmentDates()
    this.loadSchoolSatOnlineAvailability()
    this.loadCourseTemplates()
  },
  methods: {
    async loadInstructors () {
      this.instructorOptions = await this.instructorRepository.list()
    },
    async loadTargetAssessmentDates() {
        const school = await this.schoolRepository.info();
        const schoolCourseType = school.assessment;
        this.targetAssessmentDateOptions = await this.targetAssessmentDateRepository.list(schoolCourseType)
    },
    async loadSchoolSatOnlineAvailability() {
        const school = await this.schoolRepository.info()
        this.satOnlineAvailable = school.satOnlineAvailable
    },
    async loadCourseTemplates () {
      this.courseTemplateOptions = await RepositoryFactory.admin.get('course_template').list()
    },
    searchTypeOptions (){
      let t = '';
      let sub = '';
      for(let t in this.courseTypeOptions){
        for(let sub in this.courseTypeOptions[t]){
          if(this.courseTypeOptions[t][sub].value === this.schedule.courseType){
            return t;
          }}}
    },
    instructorFullName (instructor) {
      if (instructor) {
        return `${instructor.firstName} ${instructor.lastName}`
      }
      return 'Select instructor'
    },
    courseName (course) {
      if (course) {
        return course.name
      }
      return 'Select Course'
    },
    updateScheduleType (type) {
      this.schedule.scheduleType = type.value
    },
    updateCourseType (type) {
      this.$root.$emit('courseTypeUpdated', type.value)
      this.schedule.courseType = type.value
    },
    updatePrimaryTeacher (instructor) {
      this.schedule.primaryTeacher = instructor
    },
    updateSecondaryTeacher (instructor) {
      this.schedule.secondaryTeacher = instructor
    },
    updateTargetAssessmentDate (value) {
      this.schedule.targetAssessmentDate = value
    },
    updateCourseTemplate (value) {
      this.schedule.courseTemplate = value
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~@styles/variables';

  .schedule-section-header {
    padding-top: 0px;
    padding-bottom: 10px;
    font-size: 18px;
  }

  .horizon-form-row {
    .label {
      width: 40%;
    }
    .input {
      width: 60%;
      text-align: right;
      align-items: right;

      input {
        margin-left: auto;
      }

      input[type=text] {
        width: 100%;
      }

      .dropdown {
        margin-left: auto;
        width: 100%;
      }
    }
  }

  @media screen and (max-width: $mobileScreenLarge) {
    .schedule-section-header {
      padding-left: 0px;
    }

    .form-group {
      display: block;

      .horizon-form-column {
        width: 100%;

        .horizon-form-row {
          padding: 0px;
        }
      }
    }
  }

  @media screen and (max-width: 550px) {
    .schedule-section-header {
      display: block !important;
    }
  }
</style>
