<template>
  <div class="container">
    <div class="content-pane">
      <div class="pane-wrapper">
        <breadcrumbs/>
        <div class="content-heading underlined">
          <div class="pull-left">
            <h1 v-if="activeSchedule">
              Take Attendance - {{ activeSchedule.name }}


            </h1>
          </div>
        </div>
        <div class="card mt-3">
          <div class="bg-primary card-header">
            <div class="row">
              <div class="col-9">
                <h1 v-if="activeScheduleDate" class='text-white' style="padding-top: 3px;"> {{ activeScheduleDate.meetingOn }} </h1>
              </div>
              <div class="col-3  d-flex justify-content-end">
                <div>
                  <button class="btn btn-light float-right" @click="backToAttendancesList">
                    Cancel
                  </button>
                  <button class="btn btn-light" @click="saveAttendance">
                    Save Attendance
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <table v-if="attendances" class="table take-attendance-table">
              <thead class="take-attendance-header">
                <tr>
                  <th style="border-top: none;">
                    Last Name <i class="fa fa-chevron-down text-orange" style="text-align: left;"/>
                  </th>
                  <th style="border-top: none;">
                    First Name <i class="fa fa-chevron-down" style="text-align: left;"/>
                  </th>
                  <th scope="col"  style="border-top: none; text-align: right;">
                    Record Attendance
                  </th>
                  <th scope="col"  style="border-top: none;"/>
                  <th scope="col"  style="border-top: none;"/>
                  <th scope="col"  style="border-top: none;"/>

                </tr>
              </thead>
              <tbody>
                <tr v-for="(attendance, idx) in attendances" :key="idx">
                  <td class="fw-600" style="text-align: left; width: 200px;">
                    {{ attendance.user.lastName }}
                  </td>
                  <td class="fw-600" style="text-align: left; width: 200px;">
                    {{ attendance.user.firstName }}
                  </td>
                  <td style="width: 150px;">
                    <label class="fw-600 present-text">Present &nbsp; &nbsp;</label>
                    <label class="switch">
                      <input
                        v-model="attendance.attended"
                        type="checkbox"
                        class="present"
                        @click="resetAttendance(attendance, 'present')">
                      <span class="round slider"/>
                    </label>
                  </td>
                  <td style="width: 150px;">
                    <label class="fw-600 absent-text">Absent &nbsp; &nbsp;</label>
                    <label class="switch">
                      <input
                        v-model="attendance.absent"
                        type="checkbox"
                        class="absent"
                        @click="resetAttendance(attendance, 'absent')">
                      <span class="round slider"></span>
                    </label>
                  </td>
                  <td style="width: 150px">
                    <label class="fw-600 muted-text">Tardy &nbsp; &nbsp;</label>
                    <label class="switch">
                      <input
                        v-model="attendance.tardy"
                        type="checkbox"
                        class="tardy"
                        @click="resetAttendance(attendance, 'tardy')">
                      <span class="round slider"/>
                    </label>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="bg-primary card-footer d-flex justify-content-end">
            <div>
              <button class="btn btn-light" @click="backToAttendancesList">
                Cancel
              </button>
              <button class="btn btn-light" @click="saveAttendance">
                Save Attendance
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Breadcrumbs from '@shared/breadcrumbs'
import Mixins from '@submodules/mixins/mixin.js'

export default {
  components: {
    Breadcrumbs
  },
  mixins: [Mixins],
  data () {
    return {
      scheduleDateId: null,
      scheduleId: null,
      activeSchedule: {},
      activeScheduleDate: {},
      attendances: null,
      breadcrumbs: []
    }
  },
  computed: {},
  created () {
    this.scheduleId = this.$route.params.scheduleId
    this.scheduleDateId = this.$route.params.scheduleDateId
    this.initData()
  },
  methods: {
    ...mapGetters(['userSchool']),
    backToAttendancesList () {
      this.$router.push({ name: 'AdminSchoolAttendanceShow', params: {id: this.scheduleDateId} })
    },
    saveAttendance () {
      const url = `/api/admin/schedules/${this.scheduleId}/schedule_dates/${this.scheduleDateId}`
      this.$http.put(url, this.formData()).then(() => {
        this.appendSuccess({ message: 'Attendance entries updated', notification: true })
        this.backToAttendancesList()
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    },
    loadScheduleDate () {
      this.$http.get(`/api/admin/schedules/${this.scheduleId}/schedule_dates/${this.scheduleDateId}`).then(response => {
        this.activeScheduleDate = response.data.scheduleDate
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    },
    fetchAllData () {
      this.loadScheduleDate()

      this.$http.get(`/api/admin/schedules/${this.scheduleId}`).then(response => {
        this.activeSchedule = response.data.schedule
        this.breadcrumbs = [{text: this.activeSchedule.name}]
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })

      this.$http.get(`/api/admin/schedules/${this.scheduleId}/schedule_dates/${this.scheduleDateId}/attendances`).then(response => {
        this.attendances = response.data.attendances
        this.setAbsentFlags()
      }, (responseError) => {
        this.appendError({ error: responseError, notification: true })
      })
    },
    formData () {
      return {
        scheduleDate: {
          attendancesAttributes: this.formDataAttendancesAttributes()
        }
      }
    },
    formDataAttendancesAttributes () {
      const attendancesAttributes = []
      this.attendances.forEach((attendance) => {
        attendancesAttributes.push({
          id: this.toId(attendance.id),
          attended: attendance.attended,
          tardy: attendance.tardy
        })
      })
      return attendancesAttributes
    },
    initData () {
      this.fetchAllData()
    },
    indexParams () {
      return {}
    },
    setAbsentFlags () {
      this.attendances.forEach((attendance) => {
        if (attendance.attended == true) { return }
        attendance.absent = true
      })
    },
    resetAttendance (item, type) {
      if (type == 'absent' && !item.absent) {
        item.attended = false
        item.tardy = false
      } else if (type == 'present' && item.absent) {
        item.absent = false
      } else if (type == 'tardy') {
        item.absent = false
        item.attended = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .take-attendance-header {
    text-align: left;
    line-height: 24px;
  }
  .take-attendance-header th {

  }
  .take-attendance-table td {
    padding-top: 8px;
    padding-left: 8px;
    padding-bottom: 0px;
    padding-right: 8px;
    line-height: 26px;
  }
  .take-attendance-table {
  tr {
    td:nth-child(1) {
      padding-left: 15px;
    }
    td:nth-child(3), td:nth-child(4), td:nth-child(5) {
      text-align: right;
    }

  }
}
</style>
