 <template>
  <div>
    <schedule-date-modal
      :id="modal.id"
      :schedule-type="modal.type"
      :selectedScheduleDateProp="selectedClassDate"
      @scheduleDateUpdated="updateClassDate"
    />
    <table id="class-dates-table" class="table table-primary">
      <thead>
        <tr>
          <th class="flex-fill h2 text-align-left text-white">Classes</th>
          <th class="h2 text-align-left text-white date-time-separated" scope="col">Date</th>
          <th class="h2 text-align-center text-white date-time-separated" scope="col">Start Time</th>
          <th class="h2 text-align-center text-white date-time-separated" scope="col">End Time</th>
          <th class="h2 text-align-center text-white date-time-combined" scope="col">Date & Time</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="fw-600 truncate">Classes Start</td>
          <td class="fw-400 date-time-separated">{{ classStart.meetingOn| date('dddd, MMMM D, YYYY') }}</td>
          <td class="fw-400 text-align-center date-time-separated">{{ classStart.startAt }}</td>
          <td class="fw-400 text-align-center date-time-separated">{{ classStart.endAt }}</td>
          <td class="text-align-center date-time-combined">
            {{ classStart.meetingOn| date('dddd, MMMM D, YYYY') }}<br />
            {{ classStart.startAt }} - {{ classStart.endAt }}
          </td>
          <td class="action-column">
            <button
              class="btn btn-outline-secondary gray-hover action-btn"
              @click="openEditClassDateModal(classStart)"
            >
              <i class="fa fa-lg fa-pencil-alt" />
            </button>
          </td>
        </tr>

        <tr>
          <td class="fw-600">Classes End</td>
          <td class="fw-400 date-time-separated">{{ classEnd.meetingOn| date('dddd, MMMM D, YYYY') }}</td>
          <td class="fw-400 text-align-center date-time-separated">{{ classEnd.startAt }}</td>
          <td class="fw-400 text-align-center date-time-separated">{{ classEnd.endAt }}</td>
          <td class="text-align-center date-time-combined">
            {{ classEnd.meetingOn| date('dddd, MMMM D, YYYY') }}<br />
            {{ classEnd.startAt }} - {{ classEnd.endAt }}
          </td>
          <td class="action-column">
            <button
              class="btn btn-outline-secondary gray-hover action-btn"
              @click="openEditClassDateModal(classEnd)"
            >
              <i class="fa fa-lg fa-pencil-alt" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from "moment";
import ScheduleDateModal from "@components/modules/schedule/edit/schedule_date_modal";

export default {
  components: { ScheduleDateModal },
  props: {
    classes: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      selectedClassDate: {},
      modal: {
        id: "class-date-modal",
        type: "Class"
      }
    };
  },
  computed: {
    classStart() {
      return this.classes[0];
    },
    classEnd() {
      return this.classes[1];
    }
  },
  created() {
    if (this.classes.length > 2) {
      const classesToDelete = this.classes.slice(2);
      classesToDelete.forEach(classDate => (classDate._destroy = true));
      return;
    }

    switch (this.classes.length) {
      case 0:
        this.classes.push({
          meetingOn: "",
          startAt: "",
          endAt: ""
        });
      case 1:
        this.classes.push({
          meetingOn: "",
          startAt: "",
          endAt: ""
        });
    }
  },
  methods: {
    openEditClassDateModal(classDate) {
      this.selectedClassDate = classDate;
      $(`#${this.modal.id}`).modal("show");
    },
    updateClassDate(updatedClassDate) {
      this.selectedClassDate.meetingOn = updatedClassDate.meetingOn;
      this.selectedClassDate.startAt = updatedClassDate.startAt;
      this.selectedClassDate.endAt = updatedClassDate.endAt;
      this.selectedClassDate = {};
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@styles/modules/schedule/edit_schedule_dates_table.scss";
</style>
