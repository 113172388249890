<template>
  <div class="container">
    <ul class="justify-content-start nav pt-1">
      <li class="nav-item">
        <router-link :to="{ name: 'StudentDashboard' }" class="logout logout nav-link text-primary">
          Dashboard
        </router-link>
      </li>

      <li class="nav-item">
        <router-link :to="{ name: 'StudentAssessmentResults' }" class="logout logout nav-link text-primary">
          Assessment Results
        </router-link>
      </li>

      <li class="nav-item">
        <router-link :to="{ name: 'StudentPracticeAssessments' }" class="logout logout nav-link text-primary">
          Practice Assessments
        </router-link>
      </li>

      <router-link :to="{ name: 'StudentCourseActivities' }" class="logout logout nav-link text-primary" v-if="showCourseActivities" >
        Course Activities
      </router-link>

      <li class="nav-item">
        <router-link :to="{ name: 'StudentCourseSchedule' }" class="logout logout nav-link text-primary">
          Course Schedule
        </router-link>
      </li>

      <li class="nav-item">
        <a v-if="hasThinkificBundleId" :href="courseResourcesUrl" target="_blank" class="logout logout nav-link text-primary">
          Course Resources
        </a>
      </li>

      <li class="nav-item">
        <router-link :to="{ name: 'StudentShareResults' }" class="logout logout nav-link text-primary">
          Share My Results
        </router-link>
      </li>

      <li class="nav-item">
        <router-link :to="{ name: 'StudentCourses' }" class="logout logout nav-link text-primary">
          Active Course {{ this.$store.state.user.activeCourse }}
        </router-link>
      </li>

    </ul>
  </div>
</template>

<script>
import ExternalRoutes from '../routes/external_links'
import { mapGetters, mapActions, mapState } from "vuex"

export default {
  props: {
    hasThinkificBundleId: false
  },
  computed: {
    ...mapActions(["updateActiveCourse"]),
    ...mapGetters(["userSchedules","activeCourse"] ),
    ...mapState(['showCourseActivities']),
    courseResourcesUrl() {
      return ExternalRoutes.STUDENT.COURSE_RESOURCES
    },
    subHeaderActiveCourse () {
      return this.activeCourse == ''
    },
  },
}

</script>

<style scoped lang='scss'>
  @import '~@styles/nav.scss';
</style>
