<template>
  <div class="container login-padding-top">
    <app-common-messages :messages="appMessages" class="col-md-5 mx-auto" @resetMessages="resetAppMessages"/>

    <div v-if="showResetForm" class="no-gutters row">
      <div class="bg-white card col-md-5 mx-auto session--card__border text-left">
        <div class="card-body">
          <div class="no-gutters row">
            <div class="col-md-12 pb-3">
              <h1 class="text-center">
                Update Password
              </h1>
            </div>
            <div class="col-md-12">
              <div class="input-group mb-3">
                <input
                  id="new-password"
                  v-model="newPassword"
                  :class="{ 'highlighter-red': $v.newPassword.$error }"
                  type="password"
                  class="form-control"
                  placeholder="New Password"
                  aria-label="Password"
                  name="newPassword"
                  required
                  aria-describedby="basic-addon2"
                  @blur="$v.newPassword.$touch() && $v.newPasswordConfirmation.$touch()">
                <div class="input-group-append">
                  <span id="basic-addon2" class="input-group-text">
                    <i class="fa fa-lock"/>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="input-group mb-3">
                <input
                  id="new-password-confirmation"
                  v-model="newPasswordConfirmation"
                  :class="{ 'highlighter-red': $v.newPasswordConfirmation.$error }"
                  type="password"
                  class="form-control"
                  placeholder="Confirm New Password"
                  aria-label="Password"
                  name="newPasswordConfirmation"
                  required
                  aria-describedby="basic-addon2"
                  @change="$v.newPasswordConfirmation.$touch">
                <div class="input-group-append">
                  <span id="basic-addon2" class="input-group-text">
                    <i class="fa fa-lock"/>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 mx-auto no-gutters p-0 row">
            <button class="btn btn-theme col-md-12 mx-auto" type="submit" @click="updatePassword">
              Update Password
            </button>
          </div>
          <div class="pt-2 text-center w-row">
            <a href="/login" class="forgot-pass">Login?</a>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="no-gutters row">
      <div class="bg-white card col-md-5 mx-auto session--card__border text-left">
        <div class="card-body">
          <div>Your password has been updated</div>
          <a href="/login" class="float-right forgot-pass">Go to Login</a>
        </div>
      </div>
    </div>
    <div class="no-gutters row">
      <div class="card col-md-5 mt-3 mx-auto">
        <div class="card-body">
          <p id="requirements-text" class="card-heading">
            Your password must meet all requirements to continue.
          </p>
          <hr>
          <ul class="password-requirement-list">
            <li>
              <i v-if="!$v.newPassword.required" class="fa fa-times px-1 text-danger" aria-hidden="true"/>
              <i v-else class="fa fa-check px-1 text-success" aria-hidden="true"/>Is required
            </li>
            <li>
              <i v-if="!$v.newPassword.numberMatch" class="fa fa-times px-1 text-danger" aria-hidden="true"/>
              <i v-else class="fa fa-check px-1 text-success" aria-hidden="true"/>At least one number
            </li>
            <li>
              <i v-if="!$v.newPassword.lowerCaseMatch" class="fa fa-times px-1 text-danger" aria-hidden="true"/>
              <i v-else class="fa fa-check px-1 text-success" aria-hidden="true"/>At least one lowercase letter
            </li>
            <li>
              <i v-if="!$v.newPassword.upperCaseMatch" class="fa fa-times px-1 text-danger" aria-hidden="true"/>
              <i v-else class="fa fa-check px-1 text-success" aria-hidden="true"/>At least one uppercase letter
            </li>
            <li>
              <i v-if="!$v.newPassword.lengthMatch" class="fa fa-times px-1 text-danger" aria-hidden="true"/>
              <i v-else class="fa fa-check px-1 text-success" aria-hidden="true"/>At least 8 characters
            </li>
            <li>
              <i v-if="!$v.newPasswordConfirmation.sameAsPassword" class="fa fa-times px-1 text-danger" aria-hidden="true"/>
              <i v-else class="fa fa-check px-1 text-success" aria-hidden="true"/>Need to match
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, sameAs } from 'vuelidate/lib/validators'
import CommonMessages from '@submodules/components/common_messages.vue'
import Mixins from '@submodules/mixins/mixin.js'

export default {
  components: {
    appCommonMessages: CommonMessages
  },
  mixins: [Mixins],
  data () {
    return {
      token: '',
      newPassword: '',
      newPasswordConfirmation: '',
      error: false,
      showResetForm: true
    }
  },
  validations: {
    newPassword: { required,
      numberMatch: val => /.*\d/.test(val),
      lowerCaseMatch: val => /.*[a-z]/.test(val),
      upperCaseMatch: val => /.*[A-Z]/.test(val),
      lengthMatch: val => /.{8,}/.test(val)
    },
    newPasswordConfirmation: {sameAsPassword: sameAs('newPassword')}
  },

  created () {
    this.token = this.$route.params.token
  },
  methods: {
    updatePassword () {
      const params = {
        token: this.token,
        password: this.newPassword,
        passwordConfirmation: this.newPasswordConfirmation
      }
      this.$http.put('/api/forgot_password', params).then(() => {
        this.showResetForm = false
      }).catch((responseError) => {
        this.showResetForm = true
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@styles/variables.scss';

.logoMaxWidth {
  max-width: 80%;
}
.login-padding-top {
  padding-top: 10rem;
}
.highlighter-red {
  border: 2px solid red !important;
}
.session--card__border {
  border-radius: 5px 0px;
}

.btn-theme {
  background-color: $themeOrange;
  color: #fff;
}

.primaryHighlight {
  background-color: $themeOrange;
  border-radius: 5px 5px 0px 0px;
  color: white;
}
</style>
