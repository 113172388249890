<template>
  <div class="container">
    <div class="content-pane">
      <div class="pane-wrapper">
        <breadcrumbs :breadcrumbs="breadcrumbs"/>
        <div class="gradebook-pane">
          <div class="gradebook-header">
            <div class="header-text"><h2>{{ courseName }}</h2></div>
            <header-buttons @course-redirect="courseRedirect" @gradebook-export="gradebookExport"/>
          </div>
          <common-loading v-if="loadingTableData"/>
          <gradebook-table v-else :gradebookTableData="gradebookTableData" @table-update="loadTableData"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumbs from '@shared/breadcrumbs'
import HeaderButtons from './show/header_buttons.vue'
import GradebookTable from './show/gradebook_table.vue'
import CommonLoading from '@shared/common_loading'

export default {
  components: {
    Breadcrumbs,
    HeaderButtons,
    CommonLoading,
    GradebookTable
  },
  data () {
    return {
      courseId: 0,
      scheduleId: 0,
      templateId: 0,
      courseName: '',
      breadcrumbs: [],
      loadingTableData: true,
      gradebookTableData: {}
    }
  },
  created() {
    this.courseId = parseInt(this.$route.query.course_id)
    this.scheduleId = parseInt(this.$route.query.schedule_id)
    this.templateId = parseInt(this.$route.query.template_id)
    this.courseName = this.$route.query.course_name
    this.breadcrumbs = [{text: this.courseName}]
    this.loadTableData()
  },
  methods: {
    courseRedirect() {
      this.$router.push({
        name: 'StaffCourseActivitiesCourse',
        query: {
          courseId: this.courseId,
          scheduleId: this.scheduleId,
          templateId: this.templateId
        }
      })
    },
    gradebookExport() {
      window.open(`/api/staff/course_activities/${this.courseId}/gradebook/export/${this.scheduleId}`)
    },
    loadTableData() {
      this.loadingTableData = true
      this.$http.get(`/api/staff/course_activities/${this.courseId}/gradebook/table_data/${this.scheduleId}`).then((res) => {
        this.gradebookTableData = res.data.gradebook_table
        this.loadingTableData = false
      }, (responseError) => {
        this.appendError({error: responseError, notification: true})
      })
    }
  }
}
</script>
<style scoped lang="scss">
  @import '~@styles/variables';

  .gradebook-pane {
    border-color: #c7c7c7;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    padding: 10px 15px 15px;

    .gradebook-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #e7e7e7;
      padding-bottom: 12px;
      margin-bottom: 12px;

      .header-text {
        display: flex;

        h2 {
          font-size: 36px;
        }
      }
    }

  }

</style>
