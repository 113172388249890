<template>
  <div :class="containerClass" class="svg-container">
    <div
      style="position: relative; width: 140px;height: 80px;border: 1px solid #cccccc;color: #333333;pointer-events: none; background-color: white;"
      :style="{ top: toolY, left: toolX, opacity: tooltipVisible }">
      <div style="text-align: center; padding-top: 10px;">
        <p style="margin-bottom: 0; font-weight: 700">
          {{ toolValue }}
        </p>
        <p style="font-size: .75rem; color:#777777;margin-bottom: 0;">
          <i>{{ toolLabel }}</i>
        </p>
      </div>
    </div>

    <svg
      :id="chartId"
      style="margin-top: -80px;"
      class="horizontal-line-chart"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:svg="http://www.w3.org/2000/svg"/>
  </div>
</template>

<script>
import * as d3 from 'd3'

export default {
  props: {
    minScoreProp: { type: Number, default: 950 },
    maxScoreProp: { type: Number, default: 1300 },
    chartId: {
      type: String,
      required: true
    },
    mute: {
      type: Boolean,
      required: false,
      default: false
    },
    dataSet: {
      type: Array,
      required: true
    },
    gridLineColor: {
      type: String,
      default: '#E4E4E4'
    },
    tickRotation: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      properties: {},
      width: 0,
      height: 0,
      tooltipVisible: 0,
      toolX: '1px',
      toolY: '1px',
      toolValue: '',
      toolLabel: '',
      margin: {top: 20, right: 20, bottom: 30, left: 50}
    }
  },
  computed: {
    containerClass () {
      return this.chartId + 'Container'
    }
  },
  watch: {
    dataSet: {
      handler: function () {
        this.smartRender()
      },
      deep: true
    }
  },
  mounted () {
    this.smartRender()
    window.addEventListener('resize', this.onWindowResized)
  },
  destroyed () {
    window.removeEventListener('resize', this.onWindowResized)
  },
  methods: {
    onWindowResized () {
      this.smartRender()
    },

    smartRender () {
      this.setupDimensions()
      this.render(this.dataSet)
    },

    setupDimensions () {
      const chartContainer = d3.select('.' + this.containerClass)
      const containerWidth = chartContainer.node() ? chartContainer.node().getBoundingClientRect().width : false
      const containerHeight = chartContainer.node() ? chartContainer.node().getBoundingClientRect().height : false

      this.properties.containerWidth = containerWidth
      this.properties.containerHeight = containerHeight

      this.width = containerWidth - this.margin.left - this.margin.right
      this.height = containerHeight - this.margin.top - this.margin.bottom
      this.properties.width = this.width
      this.properties.height = this.height
    },

    setXScale () {
      this.xScale = d3.scalePoint()
        .range([0, this.width])
        .domain(this.dataSet.map(function (d) {
          return d.x
        }))
        .padding(.3)
    },
    setYScale () {
      this.yScale = d3.scaleLinear()
        .domain([this.minScoreProp, this.maxScoreProp])
        .range([this.height, 0])
    },
    buildLine () {
      this.line = d3.line()
        .x((d) => this.xScale(d.x))
        .y((d) => this.yScale(d.y))
    },

    render (data) {
      data = this.dataSet

      this.properties.count = data.length
      this.setupDimensions()

      const svg = d3.select('#' + this.chartId).html('')
        .attr('width', this.width + this.margin.left + this.margin.right)
        .attr('height', this.height + this.margin.top + this.margin.bottom)

      this.setXScale()
      this.setYScale()
      this.buildLine()

      this.container = svg.append('g')
        .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')')

      this.renderHorizontalAxis()
      this.renderVerticalAxis()
      this.renderBackgroundLines()
      this.drawLine()
      this.drawDots()
      this.drawDotText()
    },
    drawDots () {
      this.container.selectAll('.dot')
        .data(this.dataSet)
        .enter().append('circle')
        .attr('class', 'dot')
        .attr('cx', (d) => this.xScale(d.x))
        .attr('cy', (d) => this.yScale(d.y))
        .attr('r', 5)
    },
    drawDotText () {
      this.container.selectAll('.dot-text')
        .data(this.dataSet)
        .enter().append('text')
        .attr('class', 'dot-text')
        .attr('y', (d) => this.yScale(d.y) + 30)
        .attr('x', (d) => this.xScale(d.x) - 19)
        .text((d) => d.y)
    },
    drawLine () {
      this.container.append('g')
        .attr('class', 'chart-line')
        .append('path')
        .datum(this.dataSet)
        .attr('fill', 'none')
        .attr('class', 'line')
        .attr('d', this.line)
    },

    renderHorizontalAxis () {
      const xAxis = this.container.append('g')
        .attr('class', 'axis axis-x')
        .attr('transform', 'translate(0,' + this.height + ')')
        .call(d3.axisBottom(this.xScale))

      const tickRotation = this.tickRotation
      xAxis.selectAll('.tick text')
        .attr('transform', function () {
          return `translate(${-this.getBBox().height + ((90 - tickRotation) / 90) * (this.getBBox().height)},${this.getBBox().height / (90 / tickRotation)}) rotate(-${tickRotation})`
        })
    },

    renderVerticalAxis () {
      this.container.append('g')
        .attr('class', 'axis axis-y')
        .call(d3.axisLeft(this.yScale))

    },
    renderBackgroundLines () {
      this.container.append('g')
        .attr('class', 'grid')
        .call( d3.axisLeft(this.yScale)
          .ticks(5)
          .tickSize(-this.width)
          .tickFormat('')
        )
    },

    renderBarLabels (x, y) {
      this.container.append('text')
        .attr('class', 'chart-label')
        .attr('y', function (d) {
          return y(d.name) + y.bandwidth() / 2 + 4
        })
        .attr('x', function (d) {
          return x(d.value) + 10
        })
        .style('font-weight', 'bold')
        .text(function (d) {
          return d.name
        })
    }
  }
}
</script>

<style lang="scss">
</style>
