<template>
  <div>
    <!-- Composite Scores -->
    <div class="section-header">
      <h1>Composite Score</h1>
    </div>
    <div class="composite-score report-section">
      <div class="score-breakdown-row">
        <div class="donut-chart-wrapper w-35">
          <donut-chart
            :chart-id="'composite-score'"
            :chart-title="'Composite Score'"
            :average-score="compositeData.studentScore"
            :is-average-score="false"
            :min-score="compositeData.minScore"
            :max-score="compositeData.maxScore"
            :color="'#f4701f'"/>
        </div>
        <div class="composite-stat-wrapper my-auto">
          <div class="composite-stats">
            <div class="stat-text-wrapper">
              <div class="stat-lg">
                {{ compositeData.nationalPercentile }}<span class="superscript-lg">{{ getOrdinalSuffix(compositeData.nationalPercentile) }}</span>
              </div>
              <div class="stat-sub-text">
                Nationally Representative Sample Percentile
              </div>
            </div>
            <div v-if="evalAssessmentType" class="stat-text-wrapper">
              <div class="stat-lg">
                {{ compositeData.userPercentile }}<span class="superscript-lg">{{ getOrdinalSuffix(compositeData.userPercentile) }}</span>
              </div>
              <div class="stat-sub-text">
                SAT User Percentile
              </div>
            </div>
          </div>
          <div v-if="compositeDescription" class="composite-score-description">
            <template v-if="evalAssessmentType">
              <div class="composite-description-header">
                How do my scores compare?
              </div>
              <!-- eslint-disable max-len -->
              <div class="composite-description-body sm">
                A percentile shows how you scored, compared to other students. It’s a number between 1 and 99 and represents the percentage of students whose scores are equal to or below yours. For example, if your Math percentile is 57, that means 57% of test takers have Math scores equal to or below yours.
              </div>
              <div class="composite-description-body sm">
                <span class="highlight">The Nationally Representative Sample Percentile</span> compares your score to the scores of typical U.S. students.
              </div>
              <div class="composite-description-body sm">
                <span class="highlight">SAT® User Percentile</span> compares your score to the scores of students who typically take the test.
              </div>
            </template>
            <template v-else>
              <div class="composite-description-header">
                How do my scores compare?
              </div>
              <div class="composite-description-body">
                Your ranks tell you the approximate percentages of recent high school graduates in the US who took the ACT® test and received scores that are the same as or lower than your scores. For example, a rank of 56 for your Composite score means 56% of students earned that Composite score or below.
              </div>
              <!-- eslint-enable max-len -->
            </template>
          </div>
        </div>
      </div>
    </div>

    <!-- Section Scores -->
    <div class="section-header">
      <h1>Section Scores</h1>
    </div>
    <div class="section-scores report-section">
      <div class="no-gutters row score-breakdown-row">
        <!-- ACT: 'English', 'Math', 'Reading', 'Science'
        {{assessmentTypeProp}}: 'Reading & Writing' 'Math' -->
        <div v-for="(section, sIndx) in sectionData" :key="sIndx" class="col-6">
          <div class="section-header-sm">
            <h2>{{ section.sectionName }} Score</h2>
            <div class="muted-text">
              Benchmark Score: {{ section.benchmarkScore }}
            </div>
          </div>
          <div class="stat-row">
            <div class="donut-chart-wrapper w-50">
              <donut-chart
                :chart-id="section.sectionName.replaceAll(' ','-')+'-score'"
                chart-title-font-size="58px"
                :average-score="section.studentScore"
                :is-average-score="false"
                :min-score="section.minScore"
                :max-score="section.maxScore"
                :color="getCategoryColor(section.sectionName)"/>
            </div>
            <div class="stat-wrapper w-50">
              <div class="benchmark-reached-wrapper">
                <span class="benchmark-reached"
                  v-show="benchmarkReached(section)">
                  You've Reached the Benchmark Score!
                </span>
              </div>
              <div class="stats">
                <div class="stat-text-wrapper">
                  <div class="stat">
                    {{ section.nationalPercentile }}<span class="superscript">{{ getOrdinalSuffix(section.nationalPercentile) }}</span>
                  </div>
                  <div class="sub-text">
                    Nationally Representative Sample Percentile
                  </div>
                </div>
                <div v-if="evalAssessmentType" class="stat-text-wrapper">
                  <div class="stat">
                    {{ section.userPercentile }}<span class="superscript">{{ getOrdinalSuffix(section.userPercentile) }}</span>
                  </div>
                  <div class="sub-text">
                    SAT User Percentile
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DonutChart from '@shared/charts/donut_chart'

export default {
  components: {
    DonutChart
  },
  props: {
    assessmentTypeProp: { type: String, required: true },
    compositeDescription: { type: Boolean, default: false },
    compositeData: {
      type: Object,
      required: true
    },
    sectionData: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      data: {}
    }
  },
  computed: {
    evalAssessmentType: function () {
      return this.assessmentTypeProp == 'SAT'
    }
  },
  methods: {
    benchmarkReached (section) {
      return section.studentScore >= section.benchmarkScore
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@styles/variables';
.report-section {
  padding-bottom: 10px;
}
.score-breakdown-row {
  padding: 20px 0 10px 20px;
  display: flex;
  > .col-6:nth-child(even) {
    padding-left: 20px;
    border-left: 1px solid #e8e8e8;
  }
  > .col-6:nth-child(odd) {
    padding-right: 20px;
  }
}
.w-35 {
  width: 35%
}
.w-65 {
  width: 65%
}
.w-50 {
  width: 50%
}
.composite-score-description {
  padding-top: 10px;
  .composite-description-header {
    font-size: 14px;
    font-weight: 700;
    color: $primaryColor;
  }
  .composite-description-body {
    font-size: 12px;
    line-height: 18px;
    padding-top: 10px;
    color: $themeGray;
    &.sm {
      font-size: 10px;
      line-height: 16px;
    }
    .highlight {
      color: $primaryColor;
      font-weight: 700;
    }
  }
}

.benchmark-reached {
  color: $themeOrange;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
}

// override from statistics.scss
.stat-wrapper {
  margin-left: 30px;
  padding-left: inherit;
}

.stat-wrapper .stats {
  margin-top: 15px;
  padding: 0;
}

.composite-score {
  .score-breakdown-row {
    display: flex;

    .inner-donut-text {
      font-size: 72px;
    }

    .donut-chart-wrapper {
      width: 40%;
    }

    .composite-stat-wrapper {
      width: 65%;
      padding: 0px 0px 0px 40px;
      margin: 0px;

      .stat-text-wrapper {
        width: 50%;
        padding: 0px;
        margin: 0px;
        text-align: left;
      }
    }
  }
}

@media screen and (max-width: $mobileScreenLarge) {
  .composite-score {
    .score-breakdown-row {
      padding-left: 0px;

      .inner-donut-text {
        font-size: 50px !important;
        color: red !important;
      }

      .composite-stat-wrapper {
        padding-left: 20px;

        .stat-text-wrapper {
          .stat-lg {
            font-size: 50px;
            line-height: 57px;
          }
        }
      }
    }
  }

  .section-scores {
    .score-breakdown-row {
      padding-left: 0px;
    }

    .score-breakdown-row {
      .col-6:nth-child(even) {
        padding-left: 10px;
      }
      .col-6:nth-child(odd) {
        padding-right: 0px;
      }
      .stat-wrapper {
        margin-left: 15px;

        .stat-text-wrapper {
          padding-left: 5px;
        }
      }
    }
  }
}

@media screen and (max-width: $mobileScreenMedium) {
  .composite-score {
    .score-breakdown-row {
      display: block;

      .donut-chart-wrapper {
        width: 100%;
        margin-bottom: 20px;
      }

      .composite-stat-wrapper {
        width: 100%;
        padding: 0px;

        .stat-text-wrapper {
          .stat-lg {
            font-size: 80px;
            line-height: 87px;
          }
        }
      }
    }
  }

  .section-scores {
    .score-breakdown-row {
      display: block;

      .col-6 {
        display: block;
        width: 100%;
        max-width: 100%;
        border: 0;
        padding: 0 !important;
      }

      .stat-wrapper {
        margin-left: 15px;

        .stat-text-wrapper {
          padding-right: 8px;
        }
      }
    }
  }
}

@media screen and (max-width: $mobileScreenExtraSmall) {
  .section-header {
    h1 {
      font-size: 18px !important;
    }
  }
}
</style>

<style lang="scss">
@import '~@styles/variables';

.section-scores {
  .donut {


    @media screen and (max-width: $mobileScreenExtraLarge) {
      .inner-donut-text {
        font-size: 48px;
      }
    }

    @media screen and (max-width: $mobileScreenLarge) {
      .inner-donut-text {
        font-size: 48px;
      }
    }

    @media screen and (max-width: $mobileScreenMedium) {
      .inner-donut-text {
        font-size: 56px;
      }
    }

    @media screen and (max-width: $mobileScreenSmall) {
      .inner-donut-text {
        font-size: 48px;
      }
    }

    @media screen and (max-width: $mobileScreenExtraSmall) {
      .inner-donut-text {
        font-size: 42px;
      }
    }
  }
}
</style>
