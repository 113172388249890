<template>
  <div id="identity" class="editable-text-content">
    <div v-show="!isEditing" class="text-content">
      <h1 v-if="headerOne">{{ editableText }}</h1>
      <h3 v-else="headerOne">{{ editableText }}</h3>
    </div>
    <div v-show="isEditing" class="text-content">
      <input
        type="text"
        ref="editableTextInput"
        v-model="editableText"
        v-on:blur="toggleEditing()"
        :class="{'large-size-input': headerOne, 'mid-size-input': !headerOne}">
    </div>
    <div class="edit-content-btn" @click.stop="toggleEditing()">
      <img class="btn-icon btn-edit-pencil" src="/images/pencil.png">
    </div>
  </div>
</template>
<script>
export default {
  props: {
    textContent: {
      type: String,
      default: ""
    },
    headerOne: {
      type: Boolean,
      default: true
    },
    identity: {
      type: Object,
      default(){ return {} }
    }
  },
  data () {
    return {
      isEditing: false,
      editableText: "",
    }
  },
  methods: {
    toggleEditing() {
      this.isEditing = !this.isEditing
      if (this.isEditing) {
        this.$nextTick(() => {
          this.$refs.editableTextInput.focus()
        })
      } else {
        if (this.editableText != this.textContent) {
          this.$emit('update', this.identity, this.editableText)
        }
      }
    },
  },
  created () {
    this.editableText = this.textContent
  }
}
</script>
<style scoped lang="scss">
  .editable-text-content {
    display: flex;

    .text-content {
      h3 {
        font-size: 16px;
        font-weight: 400;
      }

      input {
        border-width: 1px;
        border-radius: 5px;
        border-color: #c7c7c7;
        color: #345165;

        &.large-size-input {
          font-size: 24px;
          font-weight: 600;
        }

        &.mid-size-input {
          font-size: 16px;
          font-weight: 400;
        }
      }
    }

    .edit-content-btn {
      border-color: #c7c7c7;
      border-width: 1px;
      border-style: solid;
      border-radius: 20px;
      background-color: white;
      width: 25px;
      height: 25px;
      cursor: pointer;
      display: flex;
      align-self: center;
      align-items: center;
      justify-content: center;
      margin-left: 10px;

      &:hover {
        border-color: #345165;
      }

      img.btn-edit-pencil {
        height: 12px;
      }
    }
  }


</style>
