<template>
  <div class="user-search">
    <div class="content-heading no-gutters row">
      <div class="col-md-12 mb-3 pb-0">
        <h1>User Search</h1>
      </div>
      <br/>
    </div>
    <div class="card card-body mb-3 pb-0">
      <div class="row pt-4">
        <div class="col-md-6 pr-3 row underlined search-box">
          <div class="col-md-6">
            <label class="form-label mt-3 search-text">First Name</label>
          </div>
          <div class="col-md-6">
            <div class="input-group mb-3 pt-2">
              <input 
                v-model="userSearch.first_name"
                class="form-control"
                >
            </div>
          </div>
        </div>
        <div class="col-md-6 pr-3 row underlined search-box">
          <div class="col-md-6">
            <label class="form-label mt-3 search-text">Last Name</label>
          </div>
          <div class="col-md-6">
            <div class="input-group mb-3 pt-2">
              <input 
                v-model="userSearch.last_name"
                class="form-control"
                >
            </div>
          </div>
        </div>
      </div>
      <div class="row pt-4">
        <div class="col-md-6 pr-3 row underlined search-box">
          <div class="col-md-6 pt-2">
            <label class="form-label mt-3 search-text">Email Address</label>
          </div>
          <div class="col-md-6">
            <div class="input-group mb-3">
              <input 
                v-model="userSearch.email"
                class="form-control"
                >
            </div>
          </div>
        </div>


        <div class="col-md-6 pr-3 row underlined search-box">
          <div class="col-md-6 pt-2">
            <label class="form-label mt-3 search-text">User Type</label>
          </div>
          <div class="col-md-6">
            <div class="input-group mb-3">
              <button
                id=""
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                class="btn btn-outline-secondary ml-0 d-flex dropdown form-control horizon-input justify-content-between">
                <!-- ml-0 d-flex dropdown form-control horizon-input justify-content-between no-hover courseTypeSelect -->
                <span class="margin-top-auto margin-bottom-auto font-12">{{ userSearch.role }}</span>
                <i class="dropdown-icon fa fa-chevron-down text-orange margin-top-auto margin-bottom-auto" aria-hidden="true"/>
                <div
                    class="dropdown-menu text-left">
                    <span
                      v-for="(userType, userTypeIndx) in userTypes"
                      :key="userTypeIndx"
                      :value="userType"
                      @click="changeRoleType(userType)"
                      class="dropdown-item text-decoration-0">
                      <a href="#" class="font-12 text-decoration-0">{{ userType }}</a>
                    </span>
                  </div>
              </button>
            </div>
          </div>
        </div>

      </div>

      <div class="row pt-4">
        <div class="col-md-6 pr-3 row underlined search-box" v-if="displaySchoolSearch">
          <div class="col-md-6">
            <label class="form-label mt-3 search-text">School</label>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <common-search 
                ref="schoolSearch"
                :searchIconVisible="false"
                :options="schools"
                @selected="selectedSchool"
                @cleared="userSearch.school_id = null"
                @filter="schoolAutocomplete"
                placeholder=""
              />
            </div>
          </div>
        </div>

        <div class="col-md-6 pr-3 row underlined search-box" v-if="displayStudentId">
          <div class="col-md-6">
            <label class="form-label mt-3 search-text">Student ID</label>
          </div>
          <div class="col-md-6">
            <div class="input-group mb-3">
              <input 
                v-model="userSearch.student_id_number"
                class="form-control"
                >
            </div>
          </div>
        </div>
      </div>

      <div class="row pt-4 search-row">
        <div class="col-md-12">
          <button class="btn btn-primary float-right" @click="search">
            <!-- <i class="fa fa-x2 fa-search mr-1 v-align-middle"/> -->
            <img src="/images/search.png" width="15" alt class="image-4">
            <span class="font-14 v-align-middle">Search</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from '@submodules/mixins/mixin.js'
import CommonSearch from '@shared/common_search'

export default {
  mixins: [Mixins],
  components: {
    CommonSearch
  },
  data: function(){
    return {
      userTypes: [
        'Student',
        'Teacher',
        'Teacher Admin',
        'District Admin'
      ],
      userSearch: {
        first_name: null,
        last_name: null,
        email: null,
        student_id_number: null,
        school_id: null,
        role: null,
      },
      schools: [],
      schoolQuery: null,
      meta: {
        page: 1,
        currentPage: 1,
        perPage: 100,
        totalCount: 0,
        totalPages: 0,
        sort: null,
        order: null,
      }
    }
  },
  computed: {
    displaySchoolSearch() {
      if (["Student", "Teacher Admin", "Teacher"].includes(this.userSearch.role)) {
        return true
      }
      return false
    },
    displayStudentId() {
      if (["Student"].includes(this.userSearch.role)) {
        return true
      }
      return false
    }
  },
  methods: {
    schoolAutocomplete(query) {
      // this.meta.search = query
      this.schoolQuery = query
      this.listSchools()
    },

    selectedSchool(school) {
      this.userSearch.school_id = school.id
    },

    listSchools () {
      this.$http.get('/api/admin/schools', { params: { search: this.schoolQuery } }).then((result) => {
        this.schools = result.data.schools
        this.meta = result.data.meta
        this.showPagination = true
      }, (responseError) => {
        this.appendError({error: responseError, notification: true})
        this.showPagination = false
      })
    },

    changeRoleType(userType) {
      // clear previous search filters
      this.userSearch.school_id = null
      this.userSearch.student_id_number = null
      if (this.$refs.schoolSearch)  
        this.$refs.schoolSearch.clearQuery()

      // change role
      this.userSearch.role = userType
    },

    search() {
      if (!this.isNullObject(this.userSearch)) {
        this.$emit('searchClicked', this.userSearch)
      }
    }
  },
}
</script>
<style scoped lang="scss">
  @import '~@styles/variables.scss';
  .underlined {
    border-bottom: 1px solid $themeLtGray;
  }
  .search-text {
    color: #505050;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  .search-text-box {
    padding-top: 10px
  }
  .search-box {
    margin-left: 0px;
  }
  .search-row {
    background-color: #f8f8f8;
    border-color: #f0f0f0;
    border-width: 1px;
    border-style: none solid solid;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    .btn-primary {
      margin-bottom: 20px;
    }
  }
  .image-4 {
    margin-right: 5px
  }
</style>