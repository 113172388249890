<template>
  <horizon-modal id="confirm-relock-test-section-modal" modal-title="Relock Test Section" :close="close">
    <template v-slot:content>
      <p><b>Important!</b></p>
      <span class="text-block font-12">
        <b class='fw-700'>The following irreversible tasks will be run when you relock this test section.</b><br />
       1. Relocking the assessment section will change the status for students showing ‘Not Started’ to ‘Locked’.<br />
       2. For students with status ‘In Progress’ or ‘Completed’, their assessment section will not be relocked. <br>
       If you wish for those students sections to be reset and answer choice data erased, please click on the red trash can icon next to each individuals student’s assessment section. <br />
      </span>
      <br>
      <div class="form-row">
          <div class="col-md-6 col-xs-12 form-group student-form-group div-1">
            <span> Test Section </span>
          </div>
          <div class="col-md-6 col-xs-12 form-group student-form-group div-2">
            <b> {{ selectedTestSectionProp.name }} </b>
          </div>

           <div class="col-md-6 col-xs-12 form-group student-form-group div-1">
            <span> Status </span>
          </div>
          <div class="col-md-6 col-xs-12 form-group student-form-group div-2">
            <b> {{ selectedTestSectionProp.status }} </b>
          </div>
        </div>
      <hr>
          <div class="col-12 d-flex justify-content-end">
          <button class="btn btn-danger student-modal-button" @click="relockTestSection">
            <span class="font-14"> Relock {{ selectedTestSectionProp.name }} </span>
          </button>
        </div>
    </template>
  </horizon-modal>
</template>

<script>
import HorizonModal from '@shared/horizon_modal'

export default {
  components: {
    HorizonModal
  },
  props: {
    selectedTestSectionProp: {
      type: Object
    }
  },
  methods: {
    async relockTestSection () {
      this.$emit('relockedTestSection', this.selectedTestSectionProp)
      this.close()
    },
    close () {
      $('#confirm-relock-test-section-modal').modal('hide')
    }
  }
}
</script>

<style scoped lang='scss'>
</style>
