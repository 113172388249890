import client from '@repositories/client.js'

const BASE_URL = '/api/parent/student_practice_tests'

export default new class {

  async fetchCourseProgress () {
    const data = await client.get(`${BASE_URL}/scores`)
    return data.scoresSummary
  }

}
